<!--
	DOCS:
		Check server's bREST_StripeUtils for info on how it works + list of test CC numbers
-->
<template>
	<v-card :loading="card_isSpinningAndDisabled" :disabled="card_isSpinningAndDisabled">
		
		<!-- WHEN IT'S ONGOING -->
		<template v-if="!isSuccessful">
			<v-card-text style="min-height:375px">
				<v-skeleton-loader v-if="!componentIsReallyReady" type="article" />
				<template v-if="componentIsReallyReady">
					<v-row>
						<v-col cols="12">
							<h6 class="text-h6">{{ _loc("description") }}</h6>
							{{ paymentIntentInfo.description }}
						</v-col>
					</v-row>
					
					<slot name="default" v-if="defaultSlot_isDefined" />
					
					<v-row justify="end">
						<v-col cols="auto" class="text-h6">
							<strong class="mr-4">{{ _loc("amount") }} :</strong>
							<strong>{{ amount_formatted }}</strong>
						</v-col>
					</v-row>
				</template>
				<v-row>
					<v-col cols="12">
						<!-- NOTE: Glitchy... Each time something changes (ex card is spinning, it starts to bounce around and call on_ready() twice), even if v-bind isn't re-evaluated, nor :key -->
						<stripe-element-payment v-if="paymentIntentInfo"
						                        ref="creditCardForm"
												:key="`cc_${hack_forceComponentRebuild_key}`"
												v-bind="stripeProps"
												@element-ready="on_ready"
												@loading="on_loading"
												@error="on_error"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pa-4"> <!-- WARNING: Don't ever hide btns or the stripe component will glitch like an asshole -->
				<v-row justify="space-around">
					<v-col cols="auto"> <v-btn large color="secondary" @click="on_cancel_click"> {{ _loc("actions.cancel.btn") }} </v-btn> </v-col>
					<v-col cols="auto"> <v-btn large color="primary"   @click="on_confirm_click">{{ _loc("actions.confirm.btn") }}</v-btn> </v-col>
				</v-row>
			</v-card-actions>
		</template>
		
		<!-- WHEN ALL IS DONE OK -->
		<template v-else>
			<v-card-title class="justify-center py-5 pb-0 ma-0">
				<v-icon color="success" style="font-size:12rem">mdi-check</v-icon>
			</v-card-title>
			<v-card-text class="text-center text-h6">{{ successMsg }}</v-card-text>
			<v-card-actions class="pa-4">
				<v-btn large color="primary" class="mx-auto" @click="on_done_click">{{ _loc("actions.done.btn") }}</v-btn>
			</v-card-actions>
		</template>
		
	</v-card>
</template>

<script>
	
	import { B_REST_API }           from "../../../../classes";
	import { StripeElementPayment } from '@vue-stripe/vue-stripe'; //WARNING: Don't import component like "()=>import('@vue-stripe/vue-stripe')" because it contains multiple components...
	
	const locBasePath = "vuetifyStripe.paymentIntentCreditCardIFrame";
	
	
	
	export default {
		props: {
			merchantPublicKey: {type:String,  required:true},                 //Check server's bREST_StripeUtils for info on how it works + list of test CC numbers. Ex "pk_test_51KTtvsFYHYkci........1Vcpamt6JIhOMAO9hllxTcyINtO4LppfU4nT2ykfYvbZz........NHh7JrsZVk93EC00QWlGZPju"
			paymentHash:       {type:String,  required:true},                 //Payment intent hash from server's bREST_StripeUtils::paymentIntent_create(). Ex "pi_3KobKN.....ciMPt1fVT5OtS_secret_ZSafxcB.....rYvhVgBIikWff"
			lang:              {type:String,  required:true},                 //Ex "fr". NOTE: No way to set that when we create the payment intent in server, unless we'd use the "session" API https://stripe.com/docs/api/checkout/sessions/object
			redirectUrl:       {type:String,  required:false, default:null},  //If specified, will receive ?payment_intent_client_secret=...&redirect_status=... (ex "succeeded")
			noSecurity:        {type:Boolean, required:false, default:false}, //Bypass !https and localhost warnings
			theme:             {type:String,  required:false, default:"stripe", validator(val){ return ["stripe","night","flat","none"].includes(val); }}, //Careful if we put night, we should put the control in a dark bg or we won't see the fields labels
		},
		data()
		{
			return {
				paymentIntentInfo:              null,  //{amount, currency, description, status, dashboardTransactionId}. We could also have more from server though, like billing & shipping.. Maybe add later
				isProcessingPayment:            false, //While we're waiting for payment to complete
				gotErrWhileProcessing:          false,
				hack_componentLoadedState:      null,  //{not|almost|fully}. Check on_ready()
				hack_forceComponentRebuild_key: 0,     //Just in case the paymentHash changes
			};
		},
		watch: {
			paymentHash() { this._resetState(); },
		},
		created()
		{
			this._resetState();
		},
		computed: {
			defaultSlot_isDefined()      { return !!this.$slots.default; },
			card_isSpinningAndDisabled() { return !this.isSuccessful && (!this.componentIsReallyReady || this.isProcessingPayment); },
			componentIsReallyReady()     { return this.hack_componentLoadedState==="fully"; },
			isSuccessful()               { return this.paymentIntentInfo?.status==="succeeded"; },
			dashboardTransactionId()     { return this.paymentIntentInfo?.dashboardTransactionId ?? null; },
			successMsg()                 { return this._loc("successMsg",{number:this.dashboardTransactionId}); },
			amount_formatted()
			{
				const currency                 = this.paymentIntentInfo.currency;
				const currencyAmountMultiplier = this._getCurrencyAmountMultiplier(currency);
				
				let amount = this.paymentIntentInfo.amount;
				if (currencyAmountMultiplier===100) { amount=amount.toFixed(2); }
				
				return `${currency.toUpperCase()} ${amount}`;
			},
			// https://docs.vuestripe.com/vue-stripe/stripe-elements/payment#props
			stripeProps()
			{
				const props = {
					pk: this.merchantPublicKey,
					testMode: this.noSecurity,
					"elements-options": {
						clientSecret: this.paymentHash,
						loader: "always", //Show a skeleton loader while booting the component
						locale: this.lang,
						//Appearance options https://stripe.com/docs/stripe-js/appearance-api
						appearance: {
							theme: this.theme,
							labels: "floating", //One of {floating|above}
							variables: { },     //We have stuff like colorPrimary, colorText, colorDanger...
						},
					},
					redirect: this.redirectUrl ? "always" : "if_required",
					// https://stripe.com/docs/js/payment_intents/confirm_payment#confirm_payment_intent-options-confirmParams
					"confirm-params": {
						/*
						We must set a return_url if redirect isn't "if_required"
						We also have payment_method_data & shipping nodes but they don't seem to do nothing
						*/
					},
				};
				
				if (this.redirectUrl) { props['confirm-params'].return_url = this.redirectUrl; } //Can be NULL, as long as redirect = "if_required"
				
				return props;
			},
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_core(`${locBasePath}.${tag}`,details); },
			async _resetState()
			{
				if (!this.paymentHash) { return; }
				
				this.paymentIntentInfo = null;
				
				this.hack_componentLoadedState = "not";
				this.hack_forceComponentRebuild_key++;
				
				const couldUpdate = await this._updatePaymentIntentInfo();
				if (!couldUpdate) { /*Show a banner ?*/ }
				
				//Now, we should be waiting for the on_ready() call to fire
			},
				//Rets bool if it could get/update. Catches all errs
				async _updatePaymentIntentInfo()
				{
					/*
					NOTE:
						We could also do the following, but it wouldn't work until the Stripe component is fully loaded, which is not good UX:
							await this.$refs.creditCardForm._data.stripe.retrievePaymentIntent(this.paymentHash)
						Otherwise, taking a look at /vue-stripe-master/src/elements/Payment.vue::mounted(), we see that the call originates either from:
							this.stripe.elements()
							this.elements.create()
							... but can't figure out where to retrieve the payment intent info that the app already holds...
					WARNING: Check server's bREST_StripeUtils warnings for what to improve for when webhook fails but Stripe says success
					*/
					try
					{	
						const url           = `https://api.stripe.com/v1/elements/sessions?key=${this.merchantPublicKey}&type=payment_intent&locale=${this.lang}&client_secret=${this.paymentHash}&expand[0]=payment_method_preference.payment_intent.payment_method`;
						const response      = await B_REST_API.call_external("GET", url);
						const responseData  = response.data;
						const paymentIntent = responseData.payment_method_preference?.payment_intent ?? null;
						
						//WARNING: Check server's bREST_StripeUtils warnings for what to improve for when webhook fails but Stripe says success
						
						if (paymentIntent)
						{
							const currency                 = paymentIntent.currency;
							const currencyAmountMultiplier = this._getCurrencyAmountMultiplier(currency);
							const amount                   = paymentIntent.amount / currencyAmountMultiplier;  //Because we get 1.23$ as 123
							const description              = paymentIntent.description;
							const status                   = paymentIntent.status;
							const dashboardTransactionId   = paymentIntent.payment_method?.id ?? null;
							
							this.paymentIntentInfo = {amount, currency, description, status, dashboardTransactionId};
							
							return true;
						}
						else { this.$bREST.utils.console_error(`Stripe API didn't ret the expected struct`,responseData); }
					}
					catch ({code, msg, responseData})
					{
						this.$bREST.utils.console_error(`Got Stripe API err ${code}: ${msg}`,responseData);
					}
					
					this._toaster(this._loc("errorMsg"), false);
					
					return false;
				},
				//For CAD, USD etc, have x100 so 123$ becomes 12300. For things like JPY, 123 remains 123.
				_getCurrencyAmountMultiplier(currency)
				{
					switch (currency.toLowerCase())
					{
						case 'jpy': return 1;
						default:    return 100;
					}
				},
			//NOTE: Sometimes it fires twice in a row, and it appears to do so when any sibling DOM changes
			async on_ready()
			{
				this.hack_componentLoadedState = "half";
				
				await this.$bREST.utils.sleep(500); //Required, because it says it's ready when it's not. Also, since it sometimes fire twice, make sure we're really supposed to become fully ready
				if (this.hack_componentLoadedState==="half") { this.hack_componentLoadedState="fully"; }
			},
			on_confirm_click()
			{
				//NOTE: ENTER doesn't submit form, so we should be OK
				
				this.isProcessingPayment   = true;
				this.gotErrWhileProcessing = false;
				
				this.$refs.creditCardForm.submit(); //Sadly, rets nothing, so we can't rely on a promise -_-
			},
			//Happens only during paying
			async on_loading(loading)
			{
				this.isProcessingPayment = loading;
				
				//If we get here, it means it worked
				if (!this.isProcessingPayment && !this.gotErrWhileProcessing)
				{
					await this._updatePaymentIntentInfo();
						//WARNING: Check server's bREST_StripeUtils warnings for what to improve for when webhook fails but Stripe says success
					
					//Maybe we have to fire a call to get the payment intent info again
					this._toaster(this.successMsg, true);
					this._emitAction("success");
				}
			},
			//Happens during loading & paying
			on_error({code, type, message})
			{
				//WARNING: Check server's bREST_StripeUtils warnings for what to improve for when webhook fails but Stripe says success
				
				this.gotErrWhileProcessing = true;
				
				this.$bREST.utils.console_error(`Got Stripe error ${code} - ${type}: ${message}`);
				this._toaster(message, false);
				this._emitAction("error");
			},
			_toaster(msg, isSuccess)
			{
				this.$bREST.notifs_tmp({msg, color:isSuccess?"success":"error"});
			},
			on_cancel_click() { this._emitAction("canceled"); },
			on_done_click()   { this._emitAction("done");     },
				//One of {canceled|error|success|done}
				_emitAction(which)
				{
					this.$emit("action", {which,paymentIntent:this.paymentIntentInfo});
				},
		},
		components: {
			StripeElementPayment,
		},
	};
	
</script>