
import B_REST_Utils          from "../B_REST_Utils.js";
import {B_REST_Request_base} from "../api/B_REST_Request.js";
import B_REST_Descriptor     from "../descriptors/B_REST_Descriptor.js";



//For info about all this, check backend's ModelOptions_Load class. Doesn't handle the filters, sort etc. Check B_REST_Model_Load_SearchOptions instead
export default class B_REST_Model_Load_RequiredFields
{
	//Consts defined in server's ModelOptions_base::REQUIRED_FIELDS_ALL_FLAG_x
	static get REQUIRED_FIELDS_ALL_FLAG_NOT_SET()           { return null;               } //Equivalent to <dbOnly> but will lose its val as soon as we add req fields
	static get REQUIRED_FIELDS_ALL_FLAG_OFF()               { return "<off>";            } //Indicates we specify required fields with required fields & groups manually
	static get REQUIRED_FIELDS_ALL_FLAG_DB_ONLY()           { return "<dbOnly>";         } //Indicates we require all fields, but only DB ones ones. We can then specify extra fields that aren't DB ones though
	static get REQUIRED_FIELDS_ALL_FLAG_DB_AND_SINGLE_SUB() { return "<dbAndSingleSub>"; } //Also adding single sub models
	static get REQUIRED_FIELDS_ALL_FLAG_DB_AND_BOTH_SUBS()  { return "<dbAndBothSubs>";  } //Also adding sub model lists
	static get REQUIRED_FIELDS_ALL_FLAG_ALL()               { return "<all>";            } //Indicates we require all fields. NOTE: as soon as we use requiredFields_x, it'll flip to REQUIRED_FIELDS_ALL_FLAG_OFF
	
	static get REQUIRED_FIELDS_ALL_FLAG_CONSTS()
	{
		return [
			B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_OFF,
			B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_DB_ONLY,
			B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_DB_AND_SINGLE_SUB,
			B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_DB_AND_BOTH_SUBS,
			B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_ALL,
		];
	}
	
	
	_descriptor             = null; //Instance of B_REST_Descriptor (for filters validation)
	_requiredFields_allFlag = B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_NOT_SET; //One of REQUIRED_FIELDS_ALL_FLAG_x
	_requiredFields         = [];   //Arr of field name paths like "firstName" or "coords.address"
	_requiredFieldGroups    = [];   //Arr of field groups like "l_base", "e_misc", as per server's Descriptor_base::_fieldGroup_assign()
	_extraData              = null; //Stuff passed to calls
	
	
	constructor(descriptor)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Descriptor, descriptor);
		
		this._descriptor = descriptor;
	}
		//Creates an instance, from a common B_REST_Descriptor (by name). We can also pass req fields now too
		static commonDefs_make(name, pipedFieldNamePaths=null)
		{
			const descriptor     = B_REST_Descriptor.commonDefs_get(name);
			const requiredFields = new B_REST_Model_Load_RequiredFields(descriptor);
			
			if (pipedFieldNamePaths) { requiredFields.requiredFields_addFields(pipedFieldNamePaths); }
			
			return requiredFields;
		}
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(`B_REST_Model_Load_RequiredFields: ${msg}`,details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`B_REST_Model_Load_RequiredFields<${this._descriptor.name}>: ${msg}`,details); }
	
	
	get descriptor() { return this._descriptor; }
	
	
	validateAgainstDescriptor_todo()
	{
		B_REST_Utils.console_todo([
			"Maybe we'd like to validate possibilities, dot notation through all sub models, with their own filter names defs. However, it adds a huge overhead, so maybe just let the server crash instead",
		]);
	}
	
	
	//All flag - Getter
		get requiredFields_allFlag() { return this._requiredFields_allFlag; }
	//All flag - Setters
		requiredFields_allFlag_off()            { this._requiredFields_allFlag = B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_OFF;               }
		requiredFields_allFlag_dbOnly()         { this._requiredFields_allFlag = B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_DB_ONLY;           }
		requiredFields_allFlag_dbAndSingleSub() { this._requiredFields_allFlag = B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_DB_AND_SINGLE_SUB; }
		requiredFields_allFlag_dbAndBothSubs()  { this._requiredFields_allFlag = B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_DB_AND_BOTH_SUBS;  }
		requiredFields_allFlag_all()            { this._requiredFields_allFlag = B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_ALL;               }
	
	get requiredFields()                   { return this._requiredFields;                         }
	get requiredFields_has()               { return this._requiredFields.length>0;                }
	requiredFields_includes(fieldNamePath) { return this._requiredFields.includes(fieldNamePath); }
	//NOTE: Server currently support things like "a.b.<dbOnly>" too. Check its ModelOptions_base::requiredFields_addFields() docs
	requiredFields_addFields(pipedFieldNamePaths)
	{
		if (this._requiredFields_allFlag===B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_NOT_SET) { this.requiredFields_allFlag_off(); }
		
		const fieldNamePaths = B_REST_Utils.splitPipedFieldNamePaths(pipedFieldNamePaths);
		
		for (const loop_fieldNamePath of fieldNamePaths)
		{
			//Check if it's something special like "<dbOnly>" on the root lvl
			if (loop_fieldNamePath.indexOf("<")===0)
			{
				if (!B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_CONSTS.includes(loop_fieldNamePath)) { B_REST_Model_Load_RequiredFields._throwEx(`Got unknown req field all flag directive "${loop_fieldNamePath}"`); }
				
				this._requiredFields_allFlag = loop_fieldNamePath;
			}
			else if (!this._requiredFields.includes(loop_fieldNamePath))
			{
				this._requiredFields.push(loop_fieldNamePath);
			}
		}
	}
	
	get requiredFieldGroups()                { return this._requiredFieldGroups;                      }
	get requiredFieldGroups_has()            { return this._requiredFieldGroups.length>0;             }
	requiredFieldGroups_includes(fieldGroup) { return this._requiredFieldGroups.includes(fieldGroup); }
	requiredFieldGroups_addFieldGroups(pipedFieldGroups)
	{
		if (this._requiredFields_allFlag===B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_NOT_SET) { this.requiredFields_allFlag_off(); }
		
		for (const loop_fieldGroup of pipedFieldGroups.split("|"))
		{
			if (!this._requiredFieldGroups.includes(loop_fieldGroup)) { this._requiredFieldGroups.push(loop_fieldGroup); }
		}
	}
	
	
	
	get extraData()    { return this._extraData; }
	set extraData(val) { this._extraData=val;    }
	
	
	/*
	Ret NULL, or only filled props as:
		{
			requiredFields_allFlag,
			requiredFields,
			requiredFieldGroups,
			extraData,
		}
	*/
	toObj()
	{
		const obj = {};
		
		if (this._requiredFields_allFlag!==B_REST_Model_Load_RequiredFields.REQUIRED_FIELDS_ALL_FLAG_NOT_SET) { obj.requiredFields_allFlag=this._requiredFields_allFlag; }
		
		if (this._requiredFields.length>0)      { obj.requiredFields      = this._requiredFields;      }
		if (this._requiredFieldGroups.length>0) { obj.requiredFieldGroups = this._requiredFieldGroups; }
		
		if (this._extraData!==null) { obj.extraData = this._extraData; }
		
		return B_REST_Utils.object_isEmpty(obj) ? null : obj;
	}
	
	/*
	If we prefer generating as "?rf_fields=..." instead of as toObj() in for POST data for ex, pass it directly to a B_REST_Request_base's QSA
	WARNING:
		Shouldn't use because:
			-Servers usually limit to 8k chars in URL
			-Causes hell if we want to use special chars like ",|~:[]"
		-> However, think that B_REST_ModelFileField_Control::savedModel_load() needs that to be able to get the file specs via a GET instead of POST
	*/
	toQSA(request)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
		
		//For the all flag, for now we always send it
		request.qsa_add("rf_all", this._requiredFields_allFlag);
		
		if (this._requiredFields.length>0)      { request.qsa_add("rf_fields",    this._requiredFields.join("|"));            }
		if (this._requiredFieldGroups.length>0) { request.qsa_add("rf_groups",    this._requiredFieldGroups.join("|"));       }
		if (this._extraData!==null)             { request.qsa_add("rf_extraData", B_REST_Utils.json_encode(this._extraData)); }
	}
	
	clone()
	{
		const cloned = new B_REST_Model_Load_RequiredFields(this._descriptor);
		
		cloned._requiredFields_allFlag = this._requiredFields_allFlag;
		cloned._requiredFields         = this._requiredFields;
		cloned._requiredFieldGroups    = this._requiredFieldGroups;
		cloned._extraData              = this._extraData;
		
		return cloned;
	}
};
