<!--
	Check BrGenericListBase docs for usage ex
-->
<template>
	<v-card v-bind="attrs_card">
		
		<v-card-title v-if="showTitle" v-text="listComponent.title" />
		
		<v-card-text>
			
			<!-- NOTE: ctrlF3 appears in the list + filters panel -->
			<v-text-field v-if="listComponent.final_ctrlF3_show" v-model="listComponent.ctrlF3_val" :label="listComponent.t_alt('ctrlF3.label')" clearable append-icon="mdi-magnify" />
			<v-divider    v-if="listComponent.final_ctrlF3_show" class="mt-2 mb-12" horizontal />
				
			<v-expansion-panels v-model="openedPanels" accordion multiple v-bind="attrs_filters">
				
				<v-expansion-panel v-for="(loop_filter,loop_idx) in listComponent.filters" :key="loop_idx">
					<v-expansion-panel-header :disable-icon-rotate="loop_filter.isSet">
						{{ loop_filter.label }}
						<template #actions v-if="loop_filter.isSet"> <v-icon color="success">mdi-check</v-icon> </template>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						
						<!--
							If we define something custom like this in our usage:
								<template #filter.coords_country_region_fk="{ filter }">
									<custom-thing v-model="filter.modelField.val" />
								</template>
						-->
						<template v-if="filter_definesSlot(loop_filter)">
							<slot :name="loop_filter.slotName" v-bind="{ filter:loop_filter }" />
						</template>
						
						<!-- Default for betweens -->
						<template v-else-if="loop_filter.op_is_between">
							<v-row>
								<v-col cols="12" sm="6"> <br-field-db :field="loop_filter.modelField_x" :label="between_label_from(loop_filter)" v-bind="loop_filter.componentAttrs" /> </v-col>
								<v-col cols="12" sm="6"> <br-field-db :field="loop_filter.modelField_y" :label="between_label_to(loop_filter)"   v-bind="loop_filter.componentAttrs" /> </v-col>
							</v-row>
						</template>
						
						<!-- Default for all other types -->
						<template v-else>
							<br-field-db :field="loop_filter.modelField" no-label v-bind="loop_filter.componentAttrs" />
						</template>
						
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>
		
		<v-card-actions>
			<v-spacer />
			<v-btn @click="on_apply_click" v-text="t_alt('actions.apply')" v-bind="attrs_actions" />
			<v-btn @click="on_reset_click" v-text="t_alt('actions.reset')" v-bind="attrs_actions" />
		</v-card-actions>
		
	</v-card>
</template>

<script>
	
	import { B_REST_Utils, B_REST_FieldDescriptors }  from "../../../../../classes";
	import B_REST_VueApp_CreateCoreMixin              from "../../../B_REST_VueApp_CreateCoreMixin.js";
	
	
	
	B_REST_Utils.console_todo([
		`Need link w ctrlF3 flat search`,
		`Not full height`,
		`Not related to here, but components still have some w beforeMount() vs created()`,
	]);
	
	
	
	const AUTO_OPEN_PANELS = false;
	
	const BETWEEN_LABELS_DT_DB_TYPES = [
		B_REST_FieldDescriptors.DB.TYPE_DT,
		B_REST_FieldDescriptors.DB.TYPE_D,
		B_REST_FieldDescriptors.DB.TYPE_C_STAMP,
		B_REST_FieldDescriptors.DB.TYPE_U_STAMP,
	];
	
	
	
	export default {
		name: "BrGenericListBaseFilters",
		mixins: [
			//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: "app.components.BrGenericListBase.filters",
			}),
		],
		props: {
			listComponent: {type:undefined, required:true}, //Instance of BrGenericListBase der
			showTitle:     {type:Boolean,   default:true},
			cardColor:     {type:String,    default:null},
			cardTheme:     {type:String,    default:null}, //light|dark
			filtersTheme:  {type:String,    default:null}, //light|dark
		},
		data()
		{
			const openedPanels = AUTO_OPEN_PANELS ? Array.from(Array(this.listComponent.filters.length).keys()) : [];
			
			return {
				openedPanels, //Arr of panel idxs
			};
		},
		computed: {
			attrs_card()
			{
				const attrs = {};
					if (this.cardColor)           { attrs.color = this.cardColor; }
					if (this.cardTheme==="dark")  { attrs.dark  = true;           }
					if (this.cardTheme==="light") { attrs.light = true;           }
				return attrs;
			},
			attrs_filters()
			{
				const attrs = {};
					if (this.filtersTheme==="dark")  { attrs.dark  = true; }
					if (this.filtersTheme==="light") { attrs.light = true; }
				return attrs;
			},
			attrs_actions()
			{
				const attrs = {};
					if (this.cardTheme==="dark")  { attrs.light = true; } //NOTE: We flip this opp. to attrs_card, otherwise it's dark btns on dark bg prolly
					if (this.cardTheme==="light") { attrs.dark  = true; }
				return attrs;
			},
		},
		methods: {
			filter_definesSlot(filter) { return !!this.$scopedSlots[filter.slotName]; },
			between_label_from(filter) { return this._between_label_x(filter,"from"); },
			between_label_to(filter)   { return this._between_label_x(filter,"to");   },
				_between_label_x(filter, which)
				{
					const fieldDescriptor = filter.fieldDescriptor;
					const locTagSuffix    = BETWEEN_LABELS_DT_DB_TYPES.includes(fieldDescriptor.type) ? "dt" : "other";
					
					return this.t_alt(`btwLabels.${which}_${locTagSuffix}`); //Ex from_dt vs from_other
				},
			on_apply_click()
			{
				this.listComponent.filters_apply(); //Async
			},
			on_reset_click()
			{
				//Close all panels, since we've just removed all filters anyways
				this.openedPanels = [];
				
				this.listComponent.filters_reset(); //Async if we have to reload
			},
		},
	};
	
</script>