<!--
	This component must be used in an "App.vue"
	Will display a spinner until the B_REST_VueApp_base::boot_await() call is over, or an err msg
	We can override the default slot to display another way
	Usage ex:
		logo:            require("@/custom/assets/logo-icon.svg")
		backgroundImage: require("@/custom/assets/jungle-bg--vertical.png")
		<br-app-booter text-color="white" background-color="#0b3d46" :logo="logo" :logo-width="200" :background-image="backgroundImage" />
-->

<template>
	<div :style="$bREST.globalCSSVars">
		<v-fade-transition mode="out-in">
			
			<router-view v-if="isAppUsable" />
				<!--
					NOTES:
						-If we get probs while trying to reload the app after clearing user (ex breaking "switch (user.type)"),
							we could maybe try to add an if !$bREST.boot_isUnbooting here
						-To avoid white flash while router-view is booting, we could do window.document.body.style.backgroundColor=this.backgroundColor,
							but then hard to debug where the change happened
				-->
			
			<v-app v-else>
				<v-main>
					<slot name="default" v-bind="{isSpinning, errorMsg, reboot}">
						<v-container fluid class="fill-height" :style="{backgroundColor,backgroundSize:'cover',backgroundImage:'url('+backgroundImage+')'}">
							<div style="min-width:300px; width:calc(100% - 200px);" class="mx-auto fill-height">
								<v-container v-if="logo" fluid class="fill-height" style="height:50%">
									<v-col> <v-img :src="logo" :width="logoWidth" class="mx-auto br-app-booter--logo" /> </v-col>
								</v-container>
								<div style="height: 50%"> <v-progress-linear v-if="isSpinning" style="width:100%" :color="progressbarColor" indeterminate></v-progress-linear> </div>
							</div>
						</v-container>
							
						<v-banner v-if="errorMsg" color="error" dark style="position:fixed; bottom:0">
							<v-icon slot="icon" size="36">mdi-wifi-strength-2-alert</v-icon>
							{{ errorMsg }}
							<template #actions> <v-btn text @click="reboot">{{ $bREST.t_core("app.booter.reload") }}</v-btn> </template>
						</v-banner>
					</slot>
				</v-main>
			</v-app>
			
		</v-fade-transition>
	</div>
</template>

<script>
	
	export default {
		props: {
			//Props used if we don't override the default display. Otherwise we can define the default slot like <template #default="{isSpinning, errorMsg, reboot}">...</template>
			logo:             {type:undefined, default:null}, //Ex pass a const logo=require("@/custom/assets/logo.png")
			logoWidth:        {type:Number,    default:null},
			backgroundImage:  {type:undefined, default:null}, //Ex pass a const backgroundImg=require("@/custom/assets/bg.png")
			backgroundColor:  {type:String,    default:"#FFF"},
			progressbarColor: {type:String,    default:"primary"},
		},
		data()
		{
			return {
				errorMsg: null,
			};
		},
		async created()
		{
			try
			{
				await this.$bREST.boot_await(); //Check B_REST_VueApp_base::boot_await() docs
			}
			catch (e)
			{
				this.errorMsg = e.message || e;
			}
		},
		computed: {
			isAppUsable() { return this.$bREST.boot_isBooted && !this.$bREST.boot_isUnbooting; },
			isSpinning()  { return !this.isAppUsable && !this.errorMsg; },
		},
		methods: {
			reboot() { this.$bREST.reboot(); },
		},
	};
	
</script>

<style>
	
	@import '../styles.css';
	
</style>
<style scoped>
	
	.br-app-booter--logo { 
		background: initial; /* Vuetify applies var(--v-dark-base) */
	}
	
</style>