
//Check docs in BrRightDrawer.vue



import { B_REST_Utils } from "@/bREST/core/classes";
import B_REST_Vuetify_RightDrawer from "./B_REST_Vuetify_RightDrawer.js";



export default class B_REST_Vuetify_RightDrawerContent
{
	_rightDrawer = null;  //Ptr on the B_REST_Vuetify_RightDrawer singleton
	_title       = null;  //Translated
	
	
	constructor(title)
	{
		this._rightDrawer = B_REST_Vuetify_RightDrawer.instance;
		this._title       = title;
	}
	
	
	get rightDrawer() { return this._rightDrawer; }
	
	get title()    { return this._title; }
	set title(val) { this._title=val;    }
	
	get isActive() { return this===this._rightDrawer.activeContent; }
	
	get aboveAll() { return this._rightDrawer.aboveAll; }
	
	//Shortcuts for the same in B_REST_Vuetify_RightDrawer
		async open_side()  { return this._rightDrawer.open_side(this);  }
		async open_modal() { return this._rightDrawer.open_modal(this); }
		async close(closeData=null)
		{
			this._close_x_assertActive();
			this._rightDrawer.close(closeData);
		}
		async waitClose()
		{
			this._close_x_assertActive();
			this._rightDrawer.waitClose();
		}
			_close_x_assertActive()
			{
				if (!this.isActive) { B_REST_Utils.throwEx(`Can't close right drawer content because it's not the active one`,this); }
			}
};
