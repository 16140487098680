<template>
	<v-card :elevation="2" class="mx-2 my-4">
		<v-card-title>Output</v-card-title>
		<v-card-text>
			
			<g-code-snippet :file="file_sql" />
			
			<g-code-snippet :file="file_phpModel" />
			
			<g-code-snippet v-for="(loop_file_serverLoc,loop_idx) in file_serverLocs" :key="loop_idx" :file="loop_file_serverLoc" />
			
			<g-code-snippet :file="file_phpCustom" />
			
			<g-code-snippet :file="file_phpRouter" />
			
		</v-card-text>
	</v-card>
</template>

<script>
	
	import B_REST_VueApp_base from "../../../../B_REST_VueApp_base.js";
	
	import GModel from "../GModel.js";
	
	import GFile_SQL        from "./GFile_SQL.js";
	import GFile_PHPModel   from "./GFile_PHPModel.js";
	import GFile_PHPCustom  from "./GFile_PHPCustom.js";
	import GFile_PHPRouter  from "./GFile_PHPRouter.js";
	import GFile_ServerLoc  from "./GFile_ServerLoc.js";
	
	
	
	export default {
		props: {
			model: {type:GModel, required:true},
		},
    	data()
		{
			const file_serverLoc_langs = []; //Arr of GFile_ServerLoc instances
			for (const loop_lang of B_REST_VueApp_base.instance.appLangs) { file_serverLoc_langs.push(new GFile_ServerLoc(this.model,loop_lang)); }
			
			return {
				file_sql:        new GFile_SQL(this.model),
				file_phpModel:   new GFile_PHPModel(this.model),
				file_phpCustom:  new GFile_PHPCustom(this.model),
				file_phpRouter:  new GFile_PHPRouter(this.model),
				file_serverLocs: file_serverLoc_langs,
			};
		},
		components: {
			GCodeSnippet: () => import("../../utils/GCodeSnippet.vue"),
		},
	};
	
</script>