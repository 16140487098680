
import { sha512 }   from "./vendor/sha-x.js";
import B_REST_Error from "./B_REST_Error.js";



export default class B_REST_Utils
{
	static get LOCAL_STORAGE_PREFIX()    { return "bREST:";      }
	static get LOCAL_STORAGE_UNDEFINED() { return "<undefined>"; }
	static get LOCAL_STORAGE_NULL()      { return "<null>";      }
	static get LOCAL_STORAGE_TRUE()      { return "<true>";      }
	static get LOCAL_STORAGE_FALSE()     { return "<false>";     }
	
	static get PWD_FRONTEND_TAG() { return "<intermediate>"; } //Must match server's CryptoUtils::PWD_FRONTEND_TAG
	
	static get CONTENT_TYPE_ANYTHING()  { return "<anything>";          }
	static get CONTENT_TYPE_EMPTY()     { return "<empty>";             } //For #204
	static get CONTENT_TYPE_TEXT()      { return "text/plain";          }
	static get CONTENT_TYPE_HTML()      { return "text/html";           }
	static get CONTENT_TYPE_JSON()      { return "application/json";    }
	static get CONTENT_TYPE_IMAGE()     { return "image/*";             }
	static get CONTENT_TYPE_FORM_DATA() { return "multipart/form-data"; }
	
	static get FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER()  { return null;     }
	static get FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ONCE()   { return "once";   }
	static get FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ALWAYS() { return "always"; }
	
	
	//Flags - IMPORTANT: Must start at false, in case some happens before app config is read (in prod)
	static flags_onErr_breakpoint      = false;                                              //Adds a "debugger" in throwEx()
	static flags_onErr_showNativeAlert = B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER;  //One of FLAGS_ON_ERR_SHOW_NATIVE_ALERT_x. If throwEx() should dump the err in a native alert(). Shouldn't do that in prod
	static flags_console_todo          = false;                                              //For console_todo()
	static flags_console_info          = false;                                              //For console_info()
	static flags_console_warn          = false;                                              //For console_warn()
	static flags_console_error         = false;                                              //For console_error()
	
	//Static vars
	static _past_todos         = [];     //Arr of strings for console_todo()
	static _throwEx_isBubbling = false;  //Prevent multiple error listeners to do endless loops of throwing errs
	static _throwEx_count      = 0;      //Nb of errs so far
	
	
	
	
	/*
	Throws a B_REST_Error instance. We can verify that an err is such w B_REST_Error::isBRESTError()
	Prevents infinite error throwing loops w throwEx_isBubbling() etc
	Against flags, can:
		-Show an alert(), so we know to open console
		-Force a breakpoint when the console is opened
	Last var is especially for B_REST_VueApp_base's Vue.config.warnHandler()
	*/
	static throwEx(msg, details=null, onlyForUINotifs=false)
	{
		if (B_REST_Utils._throwEx_isBubbling)
		{
			B_REST_Utils.console_error(msg, details);
			B_REST_Utils.throwEx_doneBubbling();
			return;
		}
		B_REST_Utils._throwEx_isBubbling = true; //Prevent endless loops
		
		try
		{
			B_REST_Utils._throwEx_count++;
			
			if (B_REST_Utils.flags_onErr_breakpoint) { debugger; }
			
			if (B_REST_Utils.flags_onErr_showNativeAlert===B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ALWAYS || (B_REST_Utils.flags_onErr_showNativeAlert===B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ONCE && B_REST_Utils._throwEx_count===1))
			{
				alert("bREST: Check console for errors");
			}
			
			B_REST_Utils.console_error(msg, details);
		}
		catch (e) { /* Prevent endless loops */ }
		
		if (!onlyForUINotifs) { throw new B_REST_Error(msg); }
	}
		static get throwEx_isBubbling() { return B_REST_Utils._throwEx_isBubbling; }
		static get throwEx_count()      { return B_REST_Utils._throwEx_count;      }
		static throwEx_doneBubbling()   { B_REST_Utils._throwEx_isBubbling=false;  }
		static throwEx_setupGlobalErrorHandlers()
		{
			//WARNING: Not actual try catch, so even if we "catch" the errs w these listeners, they'll still show up in the console https://developer.mozilla.org/en-US/docs/Web/API/Window/error_event
			
			window.addEventListener("error", (e) => //ErrorEvent instance
			{
				e = e.error; //Gets the exception behind the ErrorEvent
				
				if (B_REST_Utils._throwEx_ignoreErrs) { B_REST_Utils.throwEx_doneBubbling();return; }
				if (B_REST_Error.isBRESTError(e))     {                                     return; } //Assume that if we went in B_REST_Utils::throwEx(), it means we fired all req handlers and such, so this handler should have nothing more to do
				
				B_REST_Utils.throwEx(`Got a globally unhandled err: ${e.toString()}`);
			});
			window.addEventListener("unhandledrejection", (e) => //PromiseRejectionEvent instance
			{
				e = e.reason; //Gets the exception behind the PromiseRejectionEvent
				
				if (B_REST_Utils._throwEx_ignoreErrs) { B_REST_Utils.throwEx_doneBubbling();return; }
				if (B_REST_Error.isBRESTError(e))     {                                     return; } //Assume that if we went in B_REST_Utils::throwEx(), it means we fired all req handlers and such, so this handler should have nothing more to do
				
				B_REST_Utils.throwEx(`Got a globally unhandled Promise(?) err: ${e.toString()}`);
			});
		}
	
	/*
	Helper to keep track of TODOs in all files
	To have it auto highlight in VS Code, install https://marketplace.visualstudio.com/items?itemName=fabiospampinato.vscode-highlight and put in settings.json:
		"highlight.regexes": {
			"(IMPORTANT|NOTE)([^\\\n]+)": {
				"filterFileRegex": ".*(js|vue|php)$",
				"regexFlags": "g",
				"decorations": [
					{"color":"#00bb00", "backgroundColor":"transparent", "fontWeight":"bold"},
					{"color":"#00bb00", "backgroundColor":"transparent"},
				]
			},
			"(WARNING)([^\\\n]+)": {
				"filterFileRegex": ".*(js|vue|php)$",
				"regexFlags": "g",
				"decorations": [
					{"color":"#ff0000", "backgroundColor":"transparent", "fontWeight": "bold"},
					{"color":"#ff0000", "backgroundColor":"transparent"},
				]
			},
			"(console_todo|GenUtils::todos_add)([\\s\\S]+?(?=\\);)\\);)": {
				"filterFileRegex": ".*(js|vue|php)$",
				"regexFlags": "g",
				"decorations": [
					{"color":"#000000", "backgroundColor":"#ffaa00", "fontWeight":"bold", "overviewRulerColor":"#ffaa00"},
					{"color":"#000000", "backgroundColor":"#ffaa00"},
				]
			}
		}
	*/
	static console_todo(msgs)
	{
		B_REST_Utils.array_assert(msgs);
		
		const bulletPoints = `\n\t◌ ${msgs.join("\n\t◌ ")}`;
		
		//Only output todos once
		if (B_REST_Utils._past_todos.includes(bulletPoints)) { return; }
		B_REST_Utils._past_todos.push(bulletPoints);
		
		B_REST_Utils._console_x("flags_console_todo", "todo", bulletPoints);
	}
	static console_info( msg, details=null) { B_REST_Utils._console_x("flags_console_info",  "info",    msg, details); }
	static console_warn( msg, details=null) { B_REST_Utils._console_x("flags_console_warn",  "warning", msg, details); }
	static console_error(msg, details=null) { B_REST_Utils._console_x("flags_console_error", "error",   msg, details); }
		static _console_x(flagVarName, prefix, msg, details=null)
		{
			try
			{
				if (B_REST_Utils[flagVarName])
				{
					console.warn(`B_REST_Utils ${prefix}>: ${msg}\nTo view stack trace, toggle console.info visibility in browser:\n`);
					console.trace();
					if (details!==null) { console.warn(details); }
				}
			}
			catch (e) { /* Prevent endless loops */ }
		}
	
	
	static get documentBody() { return globalThis.window.document.body; }
	
	
	static assert_formData_support()
	{
		if (!globalThis.window.FormData) { B_REST_Utils.throwEx("FormData class not supported by browser; probably using IE ?! Will cause probs with API calls"); }
	}
	
	
	
	static int_is(val)    { return val!==null && !isNaN(val) && parseInt(val)==parseFloat(val); }
	static number_is(val) { return val!==null && !isNaN(val); }
	static number_assert(val) { if(!B_REST_Utils.number_is(val)){B_REST_Utils.throwEx(`Expected number`);} }
	static number_round(val, decimals)
	{
		B_REST_Utils.number_assert(val);
		B_REST_Utils.number_assert(decimals);
		
		const pow = Math.pow(10,decimals);
		return Math.round((val+Number.EPSILON) * pow) / pow; //As per https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
	}
	/*
	Formats a number, by default to the user's locale format, but can be overriden
	Usage ex:
		number_format(123456.789, 2)
			-> 123,456.79
		number_format(123456.789, 2, ".", " ")
			-> 123 456.79
		number_format(123456.789, 2, undefined, undefined, "EUR")
			-> €123,456.79
		number_format(123456.789, 2, undefined, undefined, "CAD")
			-> CA$123,456.79
	NOTE: For currency stuff, we have more params we could handle to control display: https://tc39.es/ecma402/#conformance
	*/
	static number_format(val, decimals=undefined, decimalSep=undefined, thousands=undefined, currency=undefined)
	{
		B_REST_Utils.number_assert(val);
		if (decimals!==undefined) { B_REST_Utils.number_assert(decimals); }
		
		const options = {minimumFractionDigits:decimals, maximumFractionDigits:decimals};
		if (currency)
		{
			options.style    = "currency";
			options.currency = currency;
		}
		
		//If we want to control the separator and thousands, then we have to force it to be parsed as "en-CA" to get "123,456.789"
		const localeTag = decimalSep===undefined && thousands===undefined ? undefined : "en-CA";
		var   formatted = val.toLocaleString(localeTag, options);
		
		if (localeTag)
		{
			formatted = formatted.replaceAll(",", thousands);
			formatted = formatted.replaceAll(".", decimalSep);
		}
		
		return formatted;
	}
	/*
	Same as number_format(), just sorting params another way
	Note that we could handle more currency display options: https://tc39.es/ecma402/#conformance
	WARNING:
		We shouldn't set the 3 last params, as for ex, JPY doesn't have decimals but we do
	*/
	static number_currency(val, currency, decimals=undefined, decimalSep=undefined, thousands=undefined)
	{
		return B_REST_Utils.number_format(val, decimals, decimalSep, thousands, currency);
	}
	
	
	
	static string_is(val) { return typeof(val)==="string"; }
	static string_lcFirst(val) { return B_REST_Utils._string_xcFirst(val,"toLowerCase"); }
	static string_ucFirst(val) { return B_REST_Utils._string_xcFirst(val,"toUpperCase"); }
		static _string_xcFirst(val,methodName) { return val.charAt(0)[methodName]() + val.slice(1); }
	static string_kebab(val) { return val.replaceAll(/([^^])([A-Z])/g,"$1-$2").toLowerCase(); } //Converts "SomeSuperThing" into "some-super-thing"
	static string_ellipsis(text, maxLength)
	{
		return text.length<=maxLength ? text : (text.substring(0,maxLength-3)+"...");
	}
	//Takes a string w optional \n\t and <> and converts it to html
	static string_toHTML(val)
	{
		const HTML_NL  = "<br />";
		const HTML_TAB = "&#160;".repeat(8);
		const HTML_LT  = "&lt;";
		const HTML_GT  = "&gt;";
		
		return val.replace(/\</g,HTML_LT).replace(/\>/g,HTML_GT).replace(/\n/g,HTML_NL).replace(/\t/g,HTML_TAB);
	}
	//Converts "/some(stuff[to*put+in?regex" into "\/some\(stuff\[to\*put\+in\?regex"
	static string_escapeRegex(val) { return val.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'); }
	
	/*
	Parses a string like the following:
		cieName|coords.<dbOnly>|abc.def(title|firstName|lastName|coords(email|phone|fax|phone2))|ghi.jkl(coords(email|phone|fax|phone2)|title|firstName|lastName)|cientType.name|priceList(name|percent)|invoices(number|date|total)
	And ret an arr like:
		cieName
		coords.<dbOnly>
		abc.def.title
		abc.def.firstName
		abc.def.lastName
		abc.def.title
		abc.def.coords.email
		abc.def.coords.phone
		abc.def.coords.fax
		abc.def.coords.phone2
		ghi.jkl.coords.email
		ghi.jkl.coords.phone
		ghi.jkl.coords.fax
		ghi.jkl.coords.phone2
		ghi.jkl.title
		ghi.jkl.firstName
		ghi.jkl.lastName
		clientType.name
		priceList.name
		priceList.percent
		invoices.number
		invoices.date
		invoices.total
	NOTE:
		We also have the same in backend: GenUtils::splitPipedFieldNamePaths()
	WARNING:
		We support also things like "a(b+c)" and "(a+(b|c)+d)" but output is buggy & it's not clear what we're trying to do (for when we want to simulate a CONCAT(x," ",y))
		Same for bob[3].xyz
	*/
	static splitPipedFieldNamePaths(pipedFieldNamePaths)
	{
		//If we've got nothing (more) to do
		if (pipedFieldNamePaths.indexOf("(")===-1) { return pipedFieldNamePaths.split("|"); }
		
		//First make sure it's balanced, or regex will die
		{
			const length = pipedFieldNamePaths.length;
			let nest = 0;
			for (let i=0; i<length; i++)
			{
				switch (pipedFieldNamePaths[i])
				{
					case "(":
						nest++;
					break;
					case ")":
						nest--;
						if (nest<0) { B_REST_Utils.throwEx(`Nested parens prob at char #${i}, for piped field name path expr "${pipedFieldNamePaths}"`); }
					break;
				}
			}
			if (nest!==0) { B_REST_Utils.throwEx(`Nested parens prob at end of piped field name path expr "${pipedFieldNamePaths}"`); }
		}
		
		//Replace all deepest nested () we find. So if we have (()), we'll have to call this func twice
		{
			const results      = pipedFieldNamePaths.matchAll(/([\w<>\[\]+\.]+)\(([^()]+)\)/g);
			let   loop_current = results.next();
			while (!loop_current.done)
			{
				const loop_area       = loop_current.value[0];                                       //Ex "coords(email+phone|fax|phone2)"
				const loop_prefix     = loop_current.value[1] + ".";                                 //Ex "coords."
				const loop_fields     = loop_current.value[2];                                       //Ex "email+phone|fax|phone2"
				const loop_conversion = loop_prefix + loop_fields.replaceAll("|",`|${loop_prefix}`); //Ex "coords.email+phone|coords.fax|coords.phone2"
				
				pipedFieldNamePaths = pipedFieldNamePaths.replaceAll(loop_area, loop_conversion);
				
				loop_current = results.next();
			}
		}
		
		return B_REST_Utils.splitPipedFieldNamePaths(pipedFieldNamePaths);
	}
	
	
	
	
	
	
	static array_is(val) { return Array.isArray(val); }
	static array_isOfClassInstances(ExpectedClass, val, ifNotThrow=false)
	{
		B_REST_Utils.array_assert(val);
		val.forEach((loop_item,loop_idx) =>
		{
			if (!(loop_item instanceof ExpectedClass))
			{
				if (ifNotThrow) { B_REST_Utils.throwEx(`Didn't find an instance of ${B_REST_Utils.class_name(ExpectedClass)} at arr pos #${loop_idx}`); }
				return false;
			}
		});
		return true;
	}
	static array_isOfObjects(val, ifNotThrow=false)
	{
		B_REST_Utils.array_assert(val);
		val.forEach((loop_item,loop_idx) =>
		{
			if (!B_REST_Utils.object_is(loop_item))
			{
				if (ifNotThrow) { B_REST_Utils.throwEx(`Didn't find an object at arr pos #${loop_idx}`); }
				return false;
			}
		});
		return true;
	}
	static array_assert(val)
	{
		if (!B_REST_Utils.array_is(val)) { B_REST_Utils.throwEx("Expected arr"); }
	}
	static array_isOfClassInstances_assert(ExpectedClass, val)
	{
		B_REST_Utils.array_isOfClassInstances(ExpectedClass,val, /*ifNotThrow*/true);
	}
	static array_isOfObjects_assert(val)
	{
		B_REST_Utils.array_isOfObjects(val, /*ifNotThrow*/true);
	}
	//Removes 1 item. NOTE: If it occurs multiple times in the arr, we should use Array::filter() instead
	static array_remove_byVal(arr, val)
	{
		B_REST_Utils.array_assert(arr);
		
		const idx = arr.indexOf(val);
		if (idx===-1) { return; }
		
		arr.splice(idx, 1);
	}
	static array_remove_byIdx(arr, idx)
	{
		B_REST_Utils.array_assert(arr);
		if (idx>=arr.length) { B_REST_Utils.throwEx(`Trying to remove out of arr's bounds: ${idx} / ${arr.length}`); }
		
		arr.splice(idx, 1);
	}
	//Updates actual arr
	static array_unique(arr)
	{
		B_REST_Utils.array_assert(arr);
		const uniques = [];
		for (let i=0; i<arr.length; i++)
		{
			const loop_current = arr[i];
			if (uniques.includes(loop_current))
			{
				arr.splice(i,1);
				i--;
			}
			else { uniques.push(loop_current); }
		}
	}
	
	
	
	static object_is(val)
	{
		return val instanceof Object && val.constructor.name==="Object"; //NOTE: Class/function instances show as objects too, but their constructor's name won't match to {}
	}
	static object_assert(val)
	{
		if (!B_REST_Utils.object_is(val)) { B_REST_Utils.throwEx("Expected obj"); }
	}
	static object_isEmpty(oObj)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.keys(oObj).length===0;
	}
	/*
	Rets the obj, or an err msg if props aren't valid
	Usage ex:
		object_hasValidStruct(obj, {
			firstName: {accept:[String],         required:true},
			lastName:  {accept:[String,"",null], required:true},
			stuff:     {accept:[Boolean],        required:true},
			other:     {accept:undefined,        default:true},
		});
	Accept types:
		null:      Can be NULL
		"":		   Can be an empty string
		String:	   Can be a non empty string
		Array:     Can be an array, even empty
		Boolean:   Can be true/false
		true:      Can be true
		false:     Can be false
		Number:    Can be int/float
		Object:    Can be a {}, no matter its contained props (for now)
		Function:  Can be a func or async func
		<Class>:   Can be an instance of X (don't specify the class name as string, as when compiled it won't work)
	If we pass no accept types, then it doesn't validate
	If we set a default val, it's used only when undefined (so NULL is considered defined)
	onUseDefaultValsDoShallowCopy:
		In case we must set default vals, we can either alter the received obj or ret another one
	*/
	static object_hasValidStruct(oObj, oStruct, suffix="", onUseDefaultValsDoShallowCopy=true)
	{
		if (suffix) { suffix=`, for ${suffix}`; }
		
		if (!B_REST_Utils.object_is(oStruct)) { return `Struct must be an obj${suffix}`; }
		if (!B_REST_Utils.object_is(oObj))    { return `Didn't get an obj${suffix}`;     }
		
		const invalidPropMsgs = [];
		let   didShallowCopy  = false; //For default vals
		
		outerLoop: //IMPORTANT: Don't delete; helps doing the equivalent of a "continue 2" in PHP
		for (const loop_propName in oStruct)
		{
			const loop_propConfig = oStruct[loop_propName];
			if (!B_REST_Utils.object_is(loop_propConfig)) { return `Struct for "${loop_propName}" must be an obj w props like {accept,required,default}`; }
			
			const loop_foundVal          = oObj[loop_propName];
			const loop_propAcceptedTypes = oStruct[loop_propName].accept;
			const loop_propIsRequired    = oStruct[loop_propName].required;
			const loop_propDefaultVal    = oStruct[loop_propName].default;
			
			if (loop_foundVal===undefined)
			{
				if (loop_propIsRequired) { invalidPropMsgs.push(`${loop_propName}: Required`); }
				else if (loop_propDefaultVal!==undefined)
				{
					if (onUseDefaultValsDoShallowCopy && !didShallowCopy)
					{
						oObj           = B_REST_Utils.object_copy(oObj, /*bDeep*/false);
						didShallowCopy = true;
					}
					
					oObj[loop_propName] = loop_propDefaultVal;
				}
				
				continue outerLoop;
			}
			
			//Check if we don't care whether it's set or not
			if (loop_propAcceptedTypes===undefined) { continue outerLoop; }
			
			//Else we have to have received a non empty arr
			if (!B_REST_Utils.array_is(loop_propAcceptedTypes) || loop_propAcceptedTypes.length===0) { return `Struct for "${loop_propName}" didn't receive a non-empty arr of accepted types`; }
			
			const loop_possibilities = [];
			
			innerLoop: //IMPORTANT: Don't delete; check "outerLoop" docs above
			for (const loop_propAcceptedType of loop_propAcceptedTypes)
			{
				switch (loop_propAcceptedType)
				{
					case null:
						if (loop_foundVal===null) { continue outerLoop; }
						loop_possibilities.push("NULL");
					break;
					case "":
						if (loop_foundVal==="") { continue outerLoop; }
						loop_possibilities.push("Empty string");
					break;
					case String:
						if (B_REST_Utils.string_is(loop_foundVal) && loop_foundVal.length>0) { continue outerLoop; }
						loop_possibilities.push("Non empty string");
					break;
					case Array:
						if (B_REST_Utils.array_is(loop_foundVal)) { continue outerLoop; }
						loop_possibilities.push("Arr of any size");
					break;
					case Boolean:
						if (loop_foundVal===true||loop_foundVal===false) { continue outerLoop; }
						loop_possibilities.push("Bool");
					break;
					case true:
						if (loop_foundVal===true) { continue outerLoop; }
						loop_possibilities.push("true");
					break;
					case false:
						if (loop_foundVal===false) { continue outerLoop; }
						loop_possibilities.push("false");
					break;
					case Number:
						if (B_REST_Utils.number_is(loop_foundVal)) { continue outerLoop; }
						loop_possibilities.push("Number");
					break;
					case Object:
						if (B_REST_Utils.object_is(loop_foundVal)) { continue outerLoop; }
						loop_possibilities.push("Obj");
					break;
					case Function:
						if (B_REST_Utils.function_is(loop_foundVal)) { continue outerLoop; }
						loop_possibilities.push("Func/async func");
					break;
					case undefined:
						B_REST_Utils.throwEx(`Don't include [undefined] in object_hasValidStruct()`); //Doesn't make sense to do "something:[undefined]", should just do "something:undefined"
					break;
					//Cases for classes
					default:
						if (loop_foundVal instanceof loop_propAcceptedType) { continue outerLoop; }
						loop_possibilities.push(B_REST_Utils.class_name(loop_propAcceptedType));
					break;
				}
			}
			
			//If we get here, that means this prop isn't valid
			invalidPropMsgs.push(`${loop_propName}: Must be one of {${loop_possibilities.join("|")}}${loop_foundVal===null?". Got NULL; if this is a setting, you should undefine it or comment it out. Otherwise, consider adding [..,null] in the allowed types for that prop":""}`);
		}
		
		if (invalidPropMsgs.length===0) { return oObj; }
		
		return `Got invalid props${suffix}:\n\t${invalidPropMsgs.join("\n\t")}`;
	}
	static object_hasValidStruct_assert(oObj, oStruct, suffix, onUseDefaultValsDoShallowCopy=true)
	{
		if (!suffix) { B_REST_Utils.throwEx(`Specify struct suffix`); }
		const result = B_REST_Utils.object_hasValidStruct(oObj, oStruct, suffix, onUseDefaultValsDoShallowCopy);
		
		if (B_REST_Utils.string_is(result)) { B_REST_Utils.throwEx(result, {expected:oStruct,received:oObj}); }
		return result; //Original obj or shallow copied one
	}
	static object_hasPropName(oObj, sPropName)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.keys(oObj).includes(sPropName);
	}
	static object_hasPropVal(oObj, val)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.values(oObj).includes(val);
	}
	/*
	Adds / overwrites props to oObj, from another obj. Should be used with primitive vals
	Usage ex:
		const oObj = {a:1, b:2};
		B_REST_Utils.object_addProps(oObj, {c:3});
		 -> {a:1, b:2, c:3}
	Also rets the final obj (only useful if we did something like B_REST_Utils.object_addProps({}, ...)
	*/
	static object_addProps(oObj, oOtherProps)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.assign(oObj, oOtherProps);
	}
	//Copies an obj in a shallow or deep manner. Deep can throw exceptions with circular refs
	static object_copy(oObj, bDeep)
	{
		B_REST_Utils.object_assert(oObj);
		
		if (bDeep)
		{
			try
			{
				//NOTE: Might fail if there's circular refs
				return B_REST_Utils.json_decode(B_REST_Utils.json_encode(oObj));
			}
			catch (e)
			{
				B_REST_Utils.throwEx(`Got error while copying with json_x(): "${e}"`);
			}
		}
		//Shallow copy
		else
		{
			return B_REST_Utils.object_addProps({}, oObj);
		}
	}
	
	
	
	/*
	Works with:
		function(){}
		async function(){}
		()=>{}
		async()=>{}
	*/
	static function_is(val)
	{
		val = val?.constructor?.name;
		return val==="Function" || val==="AsyncFunction";
	}
	
	
	
	static class_name(ClassPtr) { return ClassPtr.prototype.constructor.name; }
	/*
	Usage ex:
		class Base
		{
			static baseFunc()
			{
				const DerClass = B_REST_Utils.class_ptr_fromBaseStaticFunc(this);
			}
		};
		
		class Der extends Base {};
		
		Der.baseFunc();
	*/
	static class_ptr_fromBaseStaticFunc(staticThis) { return staticThis.prototype.constructor; }
	
	
	
	static instance_is(val)
	{
		return val instanceof Object && val.constructor.name!=="Object" && !val.prototype; //NOTE: Class/function instances show as objects too, but their constructor's name won't match to {}
	}
	static instance_assert(val)
	{
		if (!B_REST_Utils.instance_is(val)) { B_REST_Utils.throwEx("Expected an instance of some class"); }
	}
	//NOTE: Don't have a instance_isOfClass() func, just use the instanceof op and be careful about neg like "!(x instanceof ClassName)"
	static instance_isOfClass_assert(ExpectedClass, val)
	{
		if (!(val instanceof ExpectedClass)) { B_REST_Utils.throwEx(`Expected an instance of ${B_REST_Utils.class_name(ExpectedClass)}`); }
	}
	static instance_className(val)
	{
		B_REST_Utils.instance_assert(val);
		return val.constructor.name;
	}
	
	
	
	static dt_is(dt) { return dt instanceof Date; }
		static dt_assert(dt)
		{
			if (!(dt instanceof Date)) { B_REST_Utils.throwEx("Expected an instance of Date"); }
		}
	//It's possible to do new Date("bob"), so we must make sure it's working
	static dt_isValid(dt)
	{
		B_REST_Utils.dt_assert(dt);
		return !isNaN(dt.getTime());
	}
		static dt_assert_isValid(dt)
		{
			if (!B_REST_Utils.dt_isValid(dt)) { B_REST_Utils.throwEx("Expected an instance of Date + being valid"); }
		}
	static dt_asString_isValid(val, wDate, wTime)
	{
		if (!B_REST_Utils.string_is(val)) { B_REST_Utils.throwEx(`Expected an instance of Date + being valid`); }
		
		if (wDate&&wTime)
		{
			return val.match(/^\d{4}-\d{2}-\d{2}([T ]\d{2}:\d{2}(:\d{2}.*)?)?$/); //Ex "2022-01-18T13:35:57.083Z"
		}
		else if (wDate)
		{
			return val.match(/^\d{4}-\d{2}-\d{2}$/); //Ex "13:35:57"
		}
		
		return val.match(/^\d{2}:\d{2}(:\d{2})?$/); //Ex "2022-01-18"
	}
		static dt_assert_asString_isValid(val, wDate, wTime)
		{
			if (!B_REST_Utils.dt_asString_isValid(val)) { B_REST_Utils.throwEx("Expected a valid string representation of a date/time"); }
		}
	//Rets a Date instance
	static dt_now() { return new Date(); }
	static dt_format(dt, wDate, wTime, wSeconds=false, dtSeparator=" ") //Sep can be "T" as well
	{
		B_REST_Utils.dt_assert_isValid(dt);
		
		let ret = "";
		
		if (wDate)
		{
			const m = dt.getMonth()+1;
			const d = dt.getDate();
			
			ret += `${dt.getFullYear()}-${m<10?"0"+m:m}-${d<10?"0"+d:d}`;
		}
		
		if (wTime)
		{
			if (ret!=="") { ret+=dtSeparator; }
			
			const h = dt.getHours();
			const i = dt.getMinutes();
			
			ret += `${h<10?"0"+h:h}:${i<10?"0"+i:i}`;
			
			if (wSeconds)
			{
				const s = dt.getSeconds();
				
				ret += `:${s<10?"0"+s:s}`;
			}
		}
		
		return ret;
	}
	//Rets as Unix timestamp, but without milliseconds (like PHP)
	static dt_u(dt)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		return Math.floor(dt.getTime() / 1000);
	}
	//Ex rets -4 for GMT-4
	static get dt_timeZone() { return -new Date().getTimezoneOffset()/60; }
	
	
	
	/*
	We do [raw pwd] > [frontend to backend encryption] > [db encryption]
	So API calls never show raw pwd
	Ex "pwd" -> "<intermediate>6a4b49f07b599056dc1dc08d2c68afd8c2dd49af1c346fb51c7d8d56576354a6e2608e8e161151cb92886e4fbde45ac9e4c1a69bbcf0566cce108abc0200e60a"
	For more info, check server's CryptoUtils
	Expects an algo like "sha512"
	NOTE:
		There's also a new native API, but the prob is that it's async: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
	*/
	static pwd_raw_toFrontendHash(pwd_raw, algo, salt)
	{
		if (!pwd_raw)        { B_REST_Utils.throwEx(`Received empty pwd`);                             } //NOTE: Anyways, we don't want someone's whose pwd is just '0'
		if (algo!=="sha512") { B_REST_Utils.throwEx(`For now, we only support sha512. Got "${algo}"`); }
		
		const hashed = sha512(`${pwd_raw}${salt}`);
		
		return `${B_REST_Utils.PWD_FRONTEND_TAG}${hashed}`;
	}
	
	
	static contentType_evalFromData(data)
	{
		if (data===null)                  { return B_REST_Utils.CONTENT_TYPE_EMPTY;     }
		if (typeof(data) === "string")    { return B_REST_Utils.CONTENT_TYPE_TEXT;      }
		if (data instanceof FormData)     { return B_REST_Utils.CONTENT_TYPE_FORM_DATA; }
		if (B_REST_Utils.object_is(data)) { return B_REST_Utils.CONTENT_TYPE_JSON;      }
		if (B_REST_Utils.array_is(data))  { return B_REST_Utils.CONTENT_TYPE_JSON;      }
		
		B_REST_Utils.throwEx("Unexpected data content type",data);
	}
	//Ex checks if it matches "application/pdf,image/*"
	static contentType_matches(expected, received)
	{
		expected = expected.toLowerCase();
		received = received.toLowerCase();
		
		if (expected===B_REST_Utils.CONTENT_TYPE_ANYTHING || expected===received) { return true; } //NOTE: Also works when we expect B_REST_Utils.CONTENT_TYPE_EMPTY
		
		const expected_parts = expected.split(",");
		
		for (let i=0; i<expected_parts.length; i++)
		{
			const loop_expected_part = expected_parts[i];
			
			//Ex "application/pdf"
			if (received===loop_expected_part) { return true; }
			//Ex "image/*"
			else if (loop_expected_part.indexOf("/*")!==-1)
			{
				if (received.indexOf(loop_expected_part.replace("*",""))===0) { return true; }
			}
		}
		
		return false;
	}
	
	
	
	static async sleep(msecs)
	{
		return new Promise((resolve,reject) =>
		{
			setTimeout(resolve, msecs);
		});
	}
	
	
	
	/*
	Usage ex:
		makeUID(32, "bob-")
			-> "bob-74d56e569b0d3d952f019e39380c"
	*/
	static makeUID(length, prefix="")
	{
		//Yields an arr of vals like 1762511923
		const randomVals = globalThis.window.crypto.getRandomValues(new Uint32Array(length)); //NOTE: We should have enough with about 1/8 of the length, but just in case we get small numbers like 0, x times
		
		//Converts to hex
		const uid = randomVals.reduce((acc,loop_val) => acc+loop_val.toString(16), prefix);
		
		return uid.substr(0,length);
	}
	
	
	
	/*
	Ex we got:
		const a = Symbol("bob")
		Yields "bob"
	*/
	static symbolVal(symbol) { return symbol.description; }
	
	
	
	/*
	Check backend's Model_base::_field_parseFieldNamePath() docs
	For B_REST_Request::data_set() & B_REST_Response::data_getFieldData()
	Usage ex:
		const {self_fieldName, atIdx, target_fieldNameOrExpr} = parseFieldNamePath("favorites[123].product.name");
			self_fieldName:         "favorites"
			atIdx:                  123
			target_fieldNameOrExpr: "product.name"
	*/
	static parseFieldNamePath(fieldNameExpr)
	{
		if (!B_REST_Utils.string_is(fieldNameExpr)) { B_REST_Utils.throwEx(`Expected string`); }
		
		const match = fieldNameExpr.match(/^(([^\.\[]+)(\[(\d+)\])?)(\.(.+))?$/);
		if (!match) { B_REST_Utils.throwEx(`Got no field name expr`); }
		
		return {
			self_fieldName:         match[2]!==undefined                  ? match[2]           : null, //Ex "favorites"
			atIdx:                  match[4]!==undefined && match[4]!=='' ? parseInt(match[4]) : null, //Ex 123. Can be null
			target_fieldNameOrExpr: match[6]!==undefined                  ? match[6]           : null, //Ex "product.name"
		};
	}
				
	
	
	//FILES STUFF
		static get FILE_SIZE_KB() { return 1000;    }
		static get FILE_SIZE_MB() { return 1000000; }
		static files_humanReadableSize(byteSize)
		{
			if (byteSize >= B_REST_Utils.FILE_SIZE_MB) { return (byteSize/B_REST_Utils.FILE_SIZE_MB).toFixed(2) + " mb"; }
			if (byteSize >= B_REST_Utils.FILE_SIZE_KB) { return (byteSize/B_REST_Utils.FILE_SIZE_KB).toFixed(2) + " kb"; }
			return `${byteSize} bytes`;
		}
		
		static get FILES_MIME_PATTERNS_DANGEROUS() { return ".php,.php1,.php2,.php3,.php4,.php5,.php6,.php7,.php8,.php9,.pht,.phtml,.shtml,.asa,.cer,.asax,.swf,.xap,.sh,.bin,.htaccess,.exe"; }
		static get FILES_MIME_PATTERNS_IMG()       { return "image/*"; }
		static get FILES_MIME_PATTERNS_PDF()       { return "application/pdf,.pdf"; }
		static get FILES_MIME_PATTERNS_WORD()      { return "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx"; }
		static get FILES_MIME_PATTERNS_PDF_WORD()  { return `${B_REST_Utils.FILES_MIME_PATTERNS_PDF},${B_REST_Utils.FILES_MIME_PATTERNS_WORD}`; }
		static get FILES_MIME_PATTERNS_EXCEL()     { return "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx"; }
		static get FILES_MIME_PATTERNS_ANY()       { return "*.*"; }
		
		/*
		Ex checks if it matches "application/pdf,.docx,image/*". Can use common string like B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD
		Case insensitive
		*/
		static files_mime_matchesPattern(mimeOrExt, pattern)
		{
			if (!pattern || pattern===B_REST_Utils.FILES_MIME_PATTERNS_ANY) { return true; }
			
			if (!mimeOrExt) { B_REST_Utils.throwEx(`Got no mime or ext`); }
			mimeOrExt = mimeOrExt.toLowerCase();
			
			const patternParts = pattern.split(",");
			
			for (let i=0; i<patternParts.length; i++)
			{
				const loop_patternPart = patternParts[i];
				
				//Ex "application/pdf"
				if (mimeOrExt===loop_patternPart) { return true; }
				//Ex ".pdf"
				else if (loop_patternPart.indexOf(".")===0)
				{
					if (mimeOrExt===loop_patternPart.replace(".","")) { return true; }
				}
				//Ex "image/*"
				else if (loop_patternPart.indexOf("/*")!==-1)
				{
					if (mimeOrExt.indexOf(loop_patternPart.replace("*",""))===0) { return true; }
				}
			}
			
			return false;
		}
			static files_mime_isDangerous(mimeOrExt) { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_DANGEROUS); }
			static files_mime_isImg(mimeOrExt)       { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_IMG);       }
			static files_mime_isPdf(mimeOrExt)       { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_PDF);       }
			static files_mime_isWord(mimeOrExt)      { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_WORD);      }
			static files_mime_isPdfOrWord(mimeOrExt) { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD);  }
			static files_mime_isExcel(mimeOrExt)     { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_EXCEL);     }
		//Funcs to take a full file name w ext, and either get the name or ext alone
		static files_baseNameToName(baseNameWExt) { return baseNameWExt ? baseNameWExt.split(".")[0]                  : null; }
		static files_baseNameToExt(baseNameWExt)  { return baseNameWExt ? baseNameWExt.split(".").pop().toLowerCase() : null; }
		//Rets NULL if we can't figure it out. Case insensitive
		static files_extToMime(ext)
		{
			if (!ext) { return null; }
			
			switch (ext.toLowerCase())
			{
				case "bmp":                           return "image/bmp";
				case "gif":                           return "image/gif";
				case "jpeg": case "jpg": case "jfif": return "image/jpeg";
				case "png":                           return "image/png";
				case "svg":                           return "image/svg+xml";
				case "pdf":                           return "application/pdf";
				case "doc":                           return "application/msword";
				case "docx":                          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
				case "xls":                           return "application/vnd.ms-excel";
				case "xlsx":                          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
			}
			
			return null;
		}
		//Rets NULL if we can't figure it out, or throws if we get nothing. Case insensitive
		static files_mimeToExt(mime)
		{
			if (!mime) { B_REST_Utils.throwEx(`Got no mime`); }
			
			switch (mime.toLowerCase())
			{
				case "image/bmp":                                                               return "bmp";
				case "image/gif":                                                               return "gif";
				case "image/jpeg": case "image/jpg": case "image/jfif":                         return "jpeg";
				case "image/png":                                                               return "png";
				case "image/svg+xml":                                                           return "svg";
				case "application/pdf":                                                         return "pdf";
				case "application/msword":                                                      return "doc";
				case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return "docx";
				case "application/vnd.ms-excel":                                                return "xls";
				case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":       return "xlsx";
			}
			
			return null;
		}
		/*
		Usage ex:
			const objectURL = files_objectURL_create(response.data, response.data_contentType);
		WARNING:
			After the objectURL is used, we must use files_objectURL_revoke() to release memory
		*/
		static files_objectURL_create(data, contentType=null)
		{
			return globalThis.window.URL.createObjectURL(data, {type:contentType});
		}
		//Can be called multiple times
		static files_objectURL_revoke(objectURL)
		{
			globalThis.window.URL.revokeObjectURL(objectURL);
		}
	
	
	
	//LOCAL STORAGE RELATED
		/*
		Returns if we have permissions, but doesn't indicate if there is really still space remaining
		https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
		*/
		static localStorage_isAvailable()
		{
			if (!!globalThis.localStorage) { return false; }
			
			try
			{
				var x = "__localStorage_test__";
				globalThis.localStorage.setItem(x, x);
				globalThis.localStorage.removeItem(x);
				return true;
			}
			catch (e)
			{
				//Check _localStorage_isQuotaExceededError() docs for WTF
				
				if (B_REST_Utils._localStorage_isQuotaExceededError(e))
				{
					B_REST_Utils._localStorage_logError(e);
					return false;
				}
				
				return true;
			}
		}
			static _localStorage_assertAvailable()
			{
				if (!B_REST_Utils.localStorage_isAvailable) { B_REST_Utils.throwEx(`Local storage not available`); }
			}
			static _localStorage_parseError(error, reThrow=true)
			{
				let type = null;
				
				if      (B_REST_Utils._localStorage_isSecurityError(error))      { type="Security";   }
				else if (B_REST_Utils._localStorage_isQuotaExceededError(error)) { type="Quota";      }
				else                                                             { type="Unexpected"; }
				
				B_REST_Utils._localStorage_logError(error);
				
				const msg = `Local storage error: ${type}`;
				
				if (reThrow) { B_REST_Utils.throwEx(msg); }
				return msg;
			}
				static _localStorage_logError(error)
				{
					B_REST_Utils.throwEx(`Local storage not available`,error);
				}
				//As per https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
				static _localStorage_isQuotaExceededError(error)
				{
					if (error instanceof DOMException)
					{
						//NOTE: Now browsers don't use the "code" prop anymore, and use the "name" instead
						const isLikeQuotaError = (error.code===22 || error.code===1014 || error.name==="QuotaExceededError" || error.name==="NS_ERROR_DOM_QUOTA_REACHED");
						// acknowledge QuotaExceededError only if there's something already stored
						return isLikeQuotaError && globalThis.localStorage.length>0;
					}
					
					return false;
				}
				static _localStorage_isSecurityError(error)
				{
					return error instanceof DOMException && error.name==="SecurityError";
					//NOTE: Probably has more cases, but we don't know yet
				}
		/*
		NOTE: For now, this isn't accurate, because webpack(?) auto injects the following:
			<appName>_pwa_banner_canceled = 1
			logleve:webpack-dev-server    = SILENT
		*/
		static localStorage_isEmpty()
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			B_REST_Utils.throwEx(`Must refactor to make sense of this. Check docs`);
			return globalThis.localStorage.length===0;
		}
		static localStorage_get(key, throwIfNull=true)
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			let data = null;
			
			try       { data = globalThis.localStorage.getItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX}${key}`); }
			catch (e) { B_REST_Utils._localStorage_parseError(e, /*reThrow*/true);                            }
			
			if (data===null && throwIfNull) { B_REST_Utils.throwEx(`Storage key "${key}" not found`); }
			
			switch (data)
			{
				case B_REST_Utils.LOCAL_STORAGE_UNDEFINED: data=undefined; break;
				case B_REST_Utils.LOCAL_STORAGE_NULL:      data=null;      break;
				case B_REST_Utils.LOCAL_STORAGE_TRUE:      data=true;      break;
				case B_REST_Utils.LOCAL_STORAGE_FALSE:     data=false;     break;
			}
			
			return data;
		}
		static localStorage_has(key)
		{
			const data = B_REST_Utils.localStorage_get(key, /*throwIfNull*/false); //Throws if LS not available though
			return data!==null;
		}
		static localStorage_set(key, data)
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			switch (data)
			{
				case undefined: data=B_REST_Utils.LOCAL_STORAGE_UNDEFINED; break;
				case null:      data=B_REST_Utils.LOCAL_STORAGE_NULL;      break;
				case true:      data=B_REST_Utils.LOCAL_STORAGE_TRUE;      break;
				case false:     data=B_REST_Utils.LOCAL_STORAGE_FALSE;     break;
			}
			
			try       { globalThis.localStorage.setItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX}${key}`,data); }
			catch (e) { B_REST_Utils._localStorage_parseError(e, /*reThrow*/true);                          }
		}
		static localStorage_remove(key)
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			try       { globalThis.localStorage.removeItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX}${key}`); }
			catch (e) { B_REST_Utils._localStorage_parseError(e, /*reThrow*/true);                        }
		}
		static localStorage_clear()
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			globalThis.localStorage.clear();
		}
	
	
	
	//URL RELATED
		//Where "https://<domainName>/a/b/123?bob=456" yields "/a/b/123", optionally adding ?bob=456 if wQSA=true
		static url_current_getAbsPath(wQSA) { return `${window.location.pathname}${wQSA?window.location.search:''}`; }
		//Say we where on an external URL that lead here. If we use Vue-router, will stay the same no matter how we navigate inside the app, as long as we don't F5
		static get url_referrer() { return Document.referrer ?? null; }
		/*
		For a path on the app. Rets as {path, qsa:{}, hashtag:null}
		Works even if received path was like "/leads/{pkTag}"
		*/
		static url_getInfo(fullPath)
		{
			const urlInfo = new URL(fullPath, `https://${window.location.hostname}`);
			const qsa     = Object.fromEntries(urlInfo.searchParams);
			const hashTag = urlInfo.hash.replace("#","") || null;
			const path    = urlInfo.pathname.replaceAll("%7B","{").replaceAll("%7D","}");
			
			return {path, qsa, hashTag};
		}
		//Ex if we were on "/login?_sh=a8s90dg0a", we'd set which to "_sh" to get "a8s90dg0a"
		static url_current_getQSA(which)
		{
			const qsa = new URLSearchParams(location.search);
			return qsa.has(which) ? qsa.get(which) : null;
		}
		//Ex if we were on "/login?_sh=a8s90dg0a", we could call this twice to end up w just "/login"
		static url_current_removeQSA(which)
		{
			let qsa = new URLSearchParams(location.search);
			qsa.delete(which);
			qsa = qsa.toString();
			if (qsa!=="") { qsa=`?${qsa}`; }
			
			history.replaceState(null, '', `${location.pathname}${qsa}${location.hash}`);
		}
	
	
	
	//CLIPBOARD RELATED
		static async clipboard_write(contents) { await navigator.clipboard.writeText(contents); }
	
	
	
	//JSON RELATED
		//Skips circular refs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#circular_references
		static json_encode(objOrArrOrNull, prettify=false)
		{
			try
			{
				const includedObjList = []; //NOTE: Snippet indicates to use a WeakSet instead of an arr
				return JSON.stringify(objOrArrOrNull, (loop_key,loop_val) =>
				{
					if (loop_val!==null && typeof loop_val==="object")
					{
						if (includedObjList.includes(loop_val)) { return; }
						includedObjList.push(loop_val);
					}
					return loop_val;
				}, prettify?"\t":null);
			}
			catch (e) { B_REST_Utils.throwEx(`Caught err while trying to encode json: ${e}`,objOrArrOrNull); }
		}
		static json_decode(json)
		{
			try       { return JSON.parse(json);                                                   }
			catch (e) { B_REST_Utils.throwEx(`Caught err while trying to decode json: ${e}`,json); }
		}
};
