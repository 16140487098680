
import B_REST_Utils     from "../B_REST_Utils.js";
import B_REST_ModelList from "../models/B_REST_ModelList.js";



export default class B_REST_App_SharedList
{
	static get SHARED_LIST_TYPES_MODEL_LIST()   { return 'modelList'; }
	static get SHARED_LIST_TYPES_CUSTOM()       { return 'custom';    }
	
	_tag            = null;  //Ex 'activitySectors'
	_type           = null;  //One of SHARED_LIST_TYPES_x
	_modelClassName = null;  //Ex "ActivitySector", only if type is SHARED_LIST_TYPES_MODEL_LIST
	_src            = null;  //Either a B_REST_ModelList instance or map arr
	
	
	constructor(tag, type, items, modelClassName=null)
	{
		this._tag            = tag;
		this._type           = type;
		this._modelClassName = modelClassName;
		
		switch (this._type)
		{
			case B_REST_App_SharedList.SHARED_LIST_TYPES_MODEL_LIST:
				this._src = B_REST_ModelList.commonDefs_make(this._modelClassName, /*useForLoading*/false, /*useCachedShare*/false); //WARNING: Not sure about optional params
				break;
			case B_REST_App_SharedList.SHARED_LIST_TYPES_CUSTOM:
				this._src = [];
				break;
			default:
				B_REST_Utils.throwEx(`Unhandled sharedList type "${this._type}"`, this);
				break;
		}
		
		this.updateData(items);
	}
	
	get tag()              { return this._tag;                                                       }
	get type()             { return this._type;                                                      }
	get type_isModelList() { return this._type===B_REST_App_SharedList.SHARED_LIST_TYPES_MODEL_LIST; }
	get type_isCustom()    { return this._type===B_REST_App_SharedList.SHARED_LIST_TYPES_CUSTOM;     }
	get modelClassName()   { return this._modelClassName;                                            }
	get src()              { return this._src;                                                       }
	//Rets the models behind the B_REST_ModelList, or the custom arr
	get items()
	{
		switch (this._type)
		{
			case B_REST_App_SharedList.SHARED_LIST_TYPES_MODEL_LIST:
				return this._src.models;
			case B_REST_App_SharedList.SHARED_LIST_TYPES_CUSTOM:
				return this._src;
			default:
				B_REST_Utils.throwEx(`Unhandled sharedList type "${this._type}"`, this);
				break;
		}
	}
	
	//Replace data, trying to keep existing items ptr. Expects raw obj list like an API call would ret
	updateData(items)
	{
		B_REST_Utils.console_todo([
			`Only overwrite req changes - keep existing items ptrs`,
		]);
		
		switch (this._type)
		{
			case B_REST_App_SharedList.SHARED_LIST_TYPES_MODEL_LIST:
				this._src.fromObj(items, /*destroyOldModels*/false, /*skipIllegalChanges*/false);
				break;
			case B_REST_App_SharedList.SHARED_LIST_TYPES_CUSTOM:
				this._src = items;
				break;
			default:
				B_REST_Utils.throwEx(`Unhandled sharedList type "${this._type}"`, this);
				break;
		}
	}
};
