
import B_REST_Utils                from "../../classes/B_REST_Utils.js";
import B_REST_App_base             from "../../classes/app/B_REST_App_base.js";
import B_REST_VueApp_RouteDef      from "./B_REST_VueApp_RouteDef.js";
import B_REST_VueApp_RouteInfo     from "./B_REST_VueApp_RouteInfo.js";
import B_REST_Vuetify_PickerDef    from "./vuetifyComponents/picker/B_REST_Vuetify_PickerDef.js";
import B_REST_Vuetify_PickerHandle from "./vuetifyComponents/picker/B_REST_Vuetify_PickerHandle.js";

import Vue from "vue";

import Router from "vue-router";
Vue.use(Router);

//Vuetify related; could remove all this if we don't want to use that framework anymore
	import WebFontLoader from "webfontloader";
	WebFontLoader.load({
		google: {families: ["Roboto:100,300,400,500,700,900&display=swap"]},
	});
	import Vuetify, {VBtn, VTextField } from "vuetify/lib";
	import vuetify_lang_fr from "vuetify/es5/locale/fr";
	import vuetify_lang_en from "vuetify/es5/locale/en";
	import vuetify_lang_es from "vuetify/es5/locale/es";
	Vue.use(Vuetify, {
		components: {VBtn,VTextField} // Global stuff, only required if we do <component :is="..."> and stuff (?)
	});
	
	import appGeneratorRoutes from "@/bREST/core/implementations/vue/vuetifyComponents/appGenerator/routes.js";
	import "@/bREST/core/implementations/vue/vuetifyComponents/_autoload.js"; //NOTE: Req in <br-app-booter>

//Portal-vue related - wouldn't be req if we would use Vue 3's <teleport> instead
	import PortalVue from 'portal-vue';
	Vue.use(PortalVue, {
		portalName:       'portal-vue-item',
		portalTargetName: 'portal-vue-dest',
	});




export default class B_REST_VueApp_base extends B_REST_App_base
{	
	_appDOMSelector                         = null;   //Ex "#app"
	_appComponent                           = null;   //Ex ()=>import("@/App.vue"). IMPORTANT: Must contain a <br-app-booter>
	_vue                                    = null;   //Vue instance
	_router                                 = null;   //Vue Router instance
	_router_isIgnoringReplaceEvents         = false;  //Check helper_router_silentRouteUpdate() docs
	_vuetify                                = null;   //Vuetify instance
	_routes_define_x_currentLayoutComponent = null;   //Used only in constructor, for _routes_define_x()
	_globalCSSVars                          = null;   //To be used at the topmost place we can put in our _appComponent / BrAppBooter.vue, in a style prop like "<div :style='$bREST.globalCSSVars'>", so we can do "var(--b-rest-xxx)" everywhere in CSS
	_pickers_defs                           = {};     //Map of pickerName => B_REST_Vuetify_PickerDef
	_pickers_handles                        = [];     //Arr of B_REST_Vuetify_PickerHandle instances
	
	
	
	constructor(options={})
	{
		super(options); //Throws if singleton already instantiated. Will also have loaded stuff from local storage via session_load_general()
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			appComponent:                        {accept:[Object],  required:true},  //Ex ()=>import("@/App.vue")
			appDOMSelector:                      {accept:[String],  default:"#app"}, //Ex "#app"
			routes_useAppGeneratorRoutesInstead: {accept:[Boolean], default:false},
			globalCSSVars:                       {accept:[Object],  required:true},  //Check docs below
			vuetifyThemeOptions:                 {accept:[Object],  required:true},  //Ex {dark, themes:{light:{primary,secondary}, ...}}
			pickerDefs:                          {accept:[Object],  required:true},  //Check docs below
		}, "Vue app");
		
		this._appComponent   = options.appComponent;
		this._appDOMSelector = options.appDOMSelector;
		
		//For CSS vars, we need to define core ones, but we can add more to reuse elsewhere. We could also define a global css file and define them there too
		this._globalCSSVars = B_REST_Utils.object_hasValidStruct_assert(options.globalCSSVars, {
			"--bREST-BrFieldDb_isDirty_color": {accept:[String], required:true}, //Ex "red"
		}, "Global CSS vars");
		
		//Setup picker defs
		for (const loop_pickerDefName in options.pickerDefs)
		{
			if (this._pickers_defs[loop_pickerDefName]) { this.throwEx(`Picker "${loop_pickerDefName}" already defined`); }
			
			const loop_pickerDefOptions = options.pickerDefs[loop_pickerDefName];
			
			this._pickers_defs[loop_pickerDefName] = new B_REST_Vuetify_PickerDef(loop_pickerDefName, loop_pickerDefOptions);
		}
		
		//Setup Vue router
		{
			const vueRoutes = [];
			for (const loop_routeDefName in this._routeDefs)
			{
				const loop_routeDef = this._routeDefs[loop_routeDefName];
				const loop_vueRoute = loop_routeDef.convertToVueRouteDefObj(this._locale_lang);
				vueRoutes.push(loop_vueRoute);
			}
			
			this._router = new Router({
				mode: "history",
				base: process.env.BASE_URL, //NOTE: Reads from .env.xxx
				scrollBehavior: (to, from, savedPosition) =>
				{
					if (this._router_isIgnoringReplaceEvents) { return false; }
					
					if (to.hash)       { return {selector:to.hash}; }
					if (savedPosition) { return savedPosition;      }
					
					return {x:0, y:0};
				},
				routes: options.routes_useAppGeneratorRoutesInstead ? appGeneratorRoutes : vueRoutes,
			});
			
			if (!options.routes_useAppGeneratorRoutesInstead) { this._routes_setupBeforeAfterEachHook(); }
		}
		
		//Vuetify related; could remove all this if we don't want to use that framework anymore
		this._vuetify = new Vuetify({
			lang: {
				locales: {fr:vuetify_lang_fr, en:vuetify_lang_en, es:vuetify_lang_es},
				current: this._locale_lang,
			},
			breakpoint: {
				mobileBreakpoint: "sm", //By default it's "md" ?! https://vuetifyjs.com/en/features/breakpoints/#mobile-breakpoints
			},
			theme: options.vuetifyThemeOptions,
		});
		
		//Start Vue
		{
			Vue.config.productionTip = false; //If we want to output a console.log() when we boot, about dev vs build mode
			
			/*
			Catches wrong templates + unhandled exceptions. Trace is a component stack string and vm is the component's obj
			WARNING: It's possible that if we put to prod, the stack trace won't be able to tell the real component names anymore
			NOTE:
				We also have errorHandler(err,vm,info), but it doesn't tell from which component the err comes,
				so it's better to use the warnHandler alone, which catches the errs anyways
					Vue.config.errorHandler = (err,vm,info) => { this.throwEx(`Vue caught a component error:\n${err.toString()}`,{vm,info}); };
			*/
			Vue.config.warnHandler = (msg,vm,trace,d) =>
			{
				if (B_REST_Utils.throwEx_isBubbling) { B_REST_Utils.throwEx_doneBubbling();return; }
				
				this.throwEx(`Vue caught a component warning:\n${msg}${trace}`, vm, /*onlyForUINotifs*/true);
			};
			
			this._vue = new Vue({
				router:  this._router,
				vuetify: this._vuetify,
				render:  h => h(this._appComponent)
			});
			
			/*
			Shortcut to be able to use it in Vue components this way:
				this.$bREST.xxx
			Instead of having to do:
				import { B_REST_App_base } from "@/bREST/core/classes";
				B_REST_App_base.instance.xxx
			Note that we can refer to it globally via window.bRESTApp too
			IMPORTANT:
				Must do so, if we want to use singleton in computed stuff etc, as by default, Vue doesn't support reactivity for:
					-Static accessors dealing with static class vars
					-External stuff used in computed / methods, that's not directly set to a props or in data()
				Once plugin is installed, we can then use the static funcs just fine too (don't need to refer via singleton by ourselves)
			*/
			Vue.use({
				install: (Vue,options) => { Vue.prototype.$bREST=Vue.observable(this); },
			}, {}); //NOTE: If we add stuff in the {}, we can access "options.xxx" in the global Vue.$bREST
			
			//Only do this once $bREST plugin is installed
			this._vue.$mount(this._appDOMSelector);
		}
		
		//Now, Vue will be accessible, but app won't be officially ready until we boot_await() it (to fetch model defs, logged user etc)
	}
	
	
	
	get appComponent()                   { return this._appComponent;                   }
	get appDOMSelector()                 { return this._appDOMSelector;                 }
	get vue()                            { return this._vue;                            }
	get router()                         { return this._router;                         }
	get router_isIgnoringReplaceEvents() { return this._router_isIgnoringReplaceEvents; }
	get vuetify_instance()               { return this._vuetify.framework;              }
	get globalCSSVars()                  { return this._globalCSSVars;                  }
	
	
	
	_abstract_onLangChange()
	{
		this.vuetify_instance.lang.current = this._locale_lang;
	}
	
	
	
	//VUE & VUETIFY RELATED
		get uiBreakpoint()    { return this.vuetify_instance.breakpoint;               }
		get uiTheme()         { return this.vuetify_instance.light ? "light" : "dark"; }
		get uiTheme_isLight() { return this.vuetify_instance.light;                    }
		get uiTheme_isDark()  { return this.vuetify_instance.dark;                     }
		set uiTheme(val)      { this.vuetify_instance.theme.dark = val==="dark";       }
		
		scrollTo_offset(offset, duration=400, easing="easeInOutCubic")               { this.vuetify_instance.goTo(offset,  {duration,easing});        }
		scrollTo_selector(selector, offset=0, duration=400, easing="easeInOutCubic") { this.vuetify_instance.goTo(selector,{duration,easing,offset}); }
		
		//Leaves the DOM tree as-is and stops reactivity
		_vue_destroy()
		{
			this._vue.$destroy(); //NOTE: In Vue3, it's $unmount() instead of $destroy()
		}
	
	
	
	//ROUTES RELATED
		/*
		Helper funcs for definining routes, to use only while in _abstract_routes_defineRoutes()
		For modules with a list/form, where users need to be authenticated (so we have access token, etc), use _routes_define_genericListFormModule()
		Usage ex:
			_abstract_routes_defineRoutes()
			{
				this._routes_define_x_setCurrentLayoutComponent(()=>import("@/layouts/splashscreen/Index.vue"));
					this._routes_define_landpage(            {fr:"/fr/",      en:"/en/"},       ()=>import("@/views/Landpage.vue"));
					this._routes_define_login(               {fr:"/logine/",  en:"/login/"},    ()=>import("@/views/Login.vue"));
					this._routes_define_404(                 {fr:"/tombe/",   en:"/fallback/"}, ()=>import("@/views/Fallback.vue"));
					this._routes_define_public("publicTest", {fr:"/publique/",en:"/public/"},   ()=>import("@/views/Public.vue"));
				
				this._routes_define_x_setCurrentLayoutComponent(()=>import("@/layouts/modules/Index.vue"));
					this._routes_define_genericListFormModule("citizen",      {fr:"/citoyens/",     en:"/en/citizens/"},      ()=>import("@/views/CitizenList.vue"),      ()=>import("@/views/CitizenForm.vue"));
					this._routes_define_genericListFormModule("intervention", {fr:"/interventions/",en:"/en/interventions/"}, ()=>import("@/views/InterventionList.vue"), ()=>import("@/views/InterventionForm.vue"));
			}
		NOTE:
			If route is the same in all langs, instead of passing an obj like {fr,en} for langUrls, we can just pass a single string
		IMPORTANT:
			Must call _routes_define_x_setCurrentLayoutComponent() at least once at the beginning, otherwise we can't group the routes under a given layout
			We expect to end up with the following template:
				<BrAppBooter>
					<router-view />
						<SomeLayoutComponent>
							<component :is="routeTransition_componentName" mode="out-in" origin="">
								<router-view />
									<Forms etc>
		*/
			_routes_define_x_setCurrentLayoutComponent(layoutComponent)
			{
				this._routes_define_x_assertCan();
				this._routes_define_x_currentLayoutComponent = layoutComponent;
			}
				_routes_define_x_assertLayoutComponent() { if(!this._routes_define_x_currentLayoutComponent){this.throwEx(`Must call _routes_define_x_setCurrentLayoutComponent() first`);} }
			//Since in _routes_define_x() we accept either objs like {fr,en,es} and single strings, always convert to obj to KISS
			_routes_define_langUrlsToObj(langUrlsOrSingleString)
			{
				if (B_REST_Utils.object_is(langUrlsOrSingleString)) { return langUrlsOrSingleString; }
				if (B_REST_Utils.string_is(langUrlsOrSingleString))
				{
					const langUrls = {};
					for (const loop_langUrl of this._appLangs) { langUrls[loop_langUrl]=langUrlsOrSingleString; }
					return langUrls;
				}
				
				B_REST_VueApp_base.throwEx(`lang urls must be an obj or single string`);
			}
			_routes_define_genericListFormModule(moduleTag, langUrlsOrSingleString, listComponent, formComponent, listMeta={}, formMeta={}, needsAuth=true)
			{
				const langUrls = this._routes_define_langUrlsToObj(langUrlsOrSingleString);
				this._routes_define_x_assertCan();
				this._routes_define_x_assertLayoutComponent();
				
				//List mode
				{
					const listLangUrls = B_REST_Utils.object_copy(langUrls, /*bDeep*/false);
					
					this._routes_define(new B_REST_VueApp_RouteDef({
						name:          `${moduleTag}-list`, //WARNING: If we change this, will have impact in routes_go_moduleList()
						langUrls:      listLangUrls,
						viewComponent: listComponent,
						meta:          listMeta,
						needsAuth,
						layoutComponent: this._routes_define_x_currentLayoutComponent,
						viewTransferProps(vueRouterObj)
						{
							const routeInfo = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(vueRouterObj);
							
							return {
								showTitle: true,
								//Used for BrGenericListBase.vue::fromLoader (check its docs)
								fromLoader: {
									apiBaseUrl: null,
									reloader: null,
									routeInfo,
								},
							};
						},
					}));
				}
				
				//Form mode
				{
					const formLangUrls = {};
					for (const loop_lang in langUrls)
					{
						let loop_langUrl = langUrls[loop_lang];
						if (loop_langUrl[loop_langUrl.length-1]!=="/") { loop_langUrl+="/"; }
						
						formLangUrls[loop_lang] = `${loop_langUrl}:${B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG}(\\*|[\\w-]+)/`; //Allows ex for new records "/citizens/*" and existing "/citizens/123-fr"
							//WARNING: If we change this, will have impact in routes_go_moduleForm_x()
					}
					
					this._routes_define(new B_REST_VueApp_RouteDef({
						name:          `${moduleTag}-form`, //WARNING: If we change this, will have impact in routes_go_moduleForm_x()
						langUrls:      formLangUrls,
						viewComponent: formComponent,
						meta:          formMeta,
						needsAuth,
						layoutComponent: this._routes_define_x_currentLayoutComponent,
						viewTransferProps(vueRouterObj)
						{
							const routeInfo = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(vueRouterObj);
							
							return {
								fromRouteInfo: routeInfo, //Used for BrGenericFormBase.vue::fromRouteInfo (check its docs). Where a pkTag pathVar could contain something like "*" (NEW), 123 or fr-123
								showTitle: true,
							};
						},
					}));
				}
			}
			_routes_define_auth(name, langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined)
			{
				const langUrls = this._routes_define_langUrlsToObj(langUrlsOrSingleString);
				this._routes_define_x_assertCan();
				this._routes_define_x_assertLayoutComponent();
					
				this._routes_define(new B_REST_VueApp_RouteDef({
					name,
					langUrls,
					viewComponent,
					meta,
					viewTransferProps,
					needsAuth: true,
					layoutComponent: this._routes_define_x_currentLayoutComponent,
				}));
			}
			_routes_define_public(name, langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined)
			{
				const langUrls = this._routes_define_langUrlsToObj(langUrlsOrSingleString);
				
				this._routes_define_x_assertCan();
				this._routes_define_x_assertLayoutComponent();
				this._routes_define(new B_REST_VueApp_RouteDef({
					name,
					layoutComponent: this._routes_define_x_currentLayoutComponent,
					viewComponent,
					langUrls,
					meta,
					viewTransferProps,
					needsAuth: false
				}));
			}
			_routes_define_landpage(langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_LANDPAGE, langUrlsOrSingleString,viewComponent,meta,viewTransferProps); }
			_routes_define_login(   langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_LOGIN,    langUrlsOrSingleString,viewComponent,meta,viewTransferProps); }
			_routes_define_resetPwd(langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_RESET_PWD,langUrlsOrSingleString,viewComponent,meta,viewTransferProps); }
			_routes_define_profile( langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined) { this._routes_define_auth(  B_REST_VueApp_RouteDef.NAME_PROFILE,  langUrlsOrSingleString,viewComponent,meta,viewTransferProps); }
			_routes_define_404(     langUrlsOrSingleString, viewComponent, meta={}, viewTransferProps=undefined) { this._routes_define_public(B_REST_VueApp_RouteDef.NAME_404,      langUrlsOrSingleString,viewComponent,meta,viewTransferProps); }
		_abstract_routes_getRouteInfo_fromPath_retFound(fullPath,routeDef=null,pathVars=null,qsa=null,hashTag=null,lang=null) { return new B_REST_VueApp_RouteInfo(fullPath,routeDef,pathVars,qsa,hashTag,lang); }
		//Funcs to replace the URL wo triggering page reload
			_abstract_routes_go_x_replace_back()
			{
				this._router.back().catch(B_REST_VueApp_base._routes_go_x_replace_errorHandler);
			}
			_abstract_routes_go_x_replace_path(path)
			{
				this._router.push(path).catch(B_REST_VueApp_base._routes_go_x_replace_errorHandler);
			}
				static _routes_go_x_replace_errorHandler(e)
				{
					if (Router.isNavigationFailure(e)) { B_REST_Utils.console_info( `Silencing Vue Router navigation failure`,       e); } //Ignore Vue Router throwing errs when we do next(false) on purpose
					else                               { B_REST_Utils.console_error(`Vue Router beforeEach() caught a general error`,e); }
				}
		/*
		Setups checks to do between each navigation (from boot and after), to make sure we have perms and allow redirecting, etc
		NOTES:
			-When we boot, no matter the URL (and existing or not), Vue Router's "from" will always be {fullPath:"/", matched:[], name:null, query, hash}
			-The following always apply to "to", and to "from" after boot:
				-If it matches a Vue route obj, we can get its {name, meta:{bREST_routeDef}}
					We'll then convert all of it into a B_REST_App_RouteInfo_base der instance, using its meta.bREST_routeDef
				-Otherwise, we'll get {fullPath:<actual URL>, matched:[], name:null, query, hash}
					We'll also make a B_REST_App_RouteInfo_base der instance, but leaving no link to a routeDef
		*/
		_routes_setupBeforeAfterEachHook()
		{
			this._router.beforeEach(async(to,from,next) =>
			{
				//If we're trying to reboot, stop here wo calling next(), to prevent async stuff from happening when it shouldn't. Check _boot_setUnbooting() docs
				if (this._boot_isUnbooting) { return; }
				
				//Make sure app is loaded before doing anything
				if (this.boot_isBooting) { await this._boot_promiseInfo.promise; } //Can throw
				
				try
				{
					/*
					Get B_REST_VueApp_RouteInfo instances about where we come from and want to go to
					Yields NULL when Vue Router obj don't match our route defs
					IMPORTANT:
						-If Vue Router objs don't point to a known route, we'll still ret a B_REST_VueApp_RouteInfo, but the routeDef prop will be NULL,
							even if we have a catch-all routeDef (B_REST_VueApp_RouteDef::NAME_404)
							We can use B_REST_VueApp_RouteInfo::isUn/Known() to figure out
						-On boot, routeInfo_from will be NULL
						-In B_REST_VueApp_RouteDef::convertToVueRouteDefObj(), we add alias for NAME_LANDPAGE & NAME_404, for either "/" or "*"
					WARNING:
						<v-btn> and such "to" prop matches a path by default but not a route name, so we should do:
							<v-btn :to="{name:'someRouteName'}" />
					*/
					let   routeInfo_from   = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(from);
					let   routeInfo_to     = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(to);
					const intendedFullPath = routeInfo_to.fullPath;
					
					//KISS by putting from to NULL on boot
					if (routeInfo_from?.isUnknown) { routeInfo_from=null; }
					
					//Check if we must figure out we'd have to go to a catch-all page
					if (routeInfo_to.isUnknown && this.routeDefs_404) { routeInfo_to=this.routeDefs_404.toRouteInfo(); } //We also have pathVars, qsa, hashTag & lang params here
					
					//User code - Check _abstract_routes_beforeNavigationChange() docs
					const boolOrRouteInfo = await this._abstract_routes_beforeNavigationChange(routeInfo_to, routeInfo_from);
					
					//Here we'll check for perms
					if      (boolOrRouteInfo===true)                             { await this._routes_beforeEach_finalize(routeInfo_to,   next,intendedFullPath,routeInfo_from); }
					else if (boolOrRouteInfo instanceof B_REST_VueApp_RouteInfo) { await this._routes_beforeEach_finalize(boolOrRouteInfo,next,intendedFullPath,routeInfo_from); }
					else if (boolOrRouteInfo===false)                            { next(false); }
					else                                                         { this.throwEx(`_abstract_routes_beforeNavigationChange() had to ret bool or instance of B_REST_VueApp_RouteInfo`,boolOrRouteInfo); }
				}
				catch (e)
				{
					B_REST_Utils.console_error(`Got error while switching routes`,e);
					next(false);
				}
			});
			
			/*
			NOTE: Not called if in beforeEach():
				-We end with next(false)
				-We throw an err
				... so for now, not sure how the failure param could be filled
			*/
			this._router.afterEach((to,from,failure) =>
			{
				if (failure!==undefined)
				{
					this.throwEx("TODO: In case of failure, will 'to' point to where we wanted to go, or where we end up being ? Also check against NavigationFailureType");
				}
				
				this._routes_current_info = B_REST_VueApp_RouteInfo.fromVueRouterObj(to);
			});
		}
			/*
			Redirect to the wanted route, unless user didn't fully validate perms
			Handles a case where we don't have perms to proceed, but doing next(false) would cause a prob at boot
			Can throw
			*/
			async _routes_beforeEach_finalize(routeInfo_to, next, intendedFullPath, routeInfo_from=null)
			{
				let tryAlternative = false;
				
				if (routeInfo_to.isKnown)
				{
					if (await this.routes_hasPerms(routeInfo_to))
					{
						//All OK - will just need to run common code at the end
					}
					//If we were somewhere valid before, then check to go back to it, but only if we still have perms
					else if (routeInfo_from && await this.routes_hasPerms(routeInfo_from))
					{
						B_REST_Utils.console_warn(`Can't go to wanted route; staying on previous route - we still have perms for that last route`,{routeInfo_to,routeInfo_from});
						next(false);
						return;
					}
					else { tryAlternative=true; }
				}
				//If we're on "/" but that route doesn't exist, check to go to 
				else if (routeInfo_to.isRoot) { tryAlternative = true; }
				else { this.throwEx(`Can't go to a route that isn't linked to a B_REST_App_RouteDef_base der instance`,routeInfo_to); }
				
				//Otherwise check to go to profile, login or landpage, if any
				if (tryAlternative)
				{
					const routeDef_alt = this.routeDefs_fallback;
					
					if (routeDef_alt)
					{
						const routeInfo_alt = routeDef_alt.toRouteInfo();
						const logDetails    = {routeInfo_alt, routeInfo_to, routeInfo_from};
						
						if (await this.routes_hasPerms(routeInfo_alt))
						{
							B_REST_Utils.console_warn(`Can't go to wanted route; but fallbacking to profile > login > landpage`,logDetails);
							routeInfo_to = routeInfo_alt;
						}
						else { this.throwEx(`Can't go to wanted route and can't even fallback to profile > login > landpage`,logDetails); }
					}
				}
				
				//We get here if either it was OK from the beginning, or if we tried to fallback to profile, login or landpage
				{
					const intendedRouteChanged = routeInfo_to.fullPath!==intendedFullPath;
					
					//NOTE: The following 2 could throw + we must run these even if !intendedRouteChanged, because we're still going to a new place when we do next(undefined -> confirm)
					this._routes_updateCurrentTravelDirection(  routeInfo_to,routeInfo_from);
					this._abstract_routes_afterNavigationChange(routeInfo_to,routeInfo_from);
					
					next(intendedRouteChanged ? routeInfo_to.fullPath : undefined); //Prevent endless loops - if in the end nothing changes w where we wanted to go, don't let Vue Router think we have to re-run the nav guard
				}
			}
		/*
		Converts Vue Router route info into a uniform instance of B_REST_VueApp_RouteInfo (check its docs)
		Rets NULL if no match
		IMPORTANT:
			-If it doesn't point to a known route, we'll still ret a B_REST_VueApp_RouteInfo, but leave the routeDef prop NULL,
				even if we have a catch-all routeDef (B_REST_VueApp_RouteDef::NAME_404)
				We can use B_REST_VueApp_RouteInfo::isUn/Known() to figure out
		*/
		static routes_getRouteInfo_fromVueRouterObj(vueRouterObj) { return B_REST_VueApp_RouteInfo.fromVueRouterObj(vueRouterObj); }
		//Can ret NULL. Check routes_getRouteInfo_fromVueRouterObj() docs
		routes_getRouteInfo_fromVueRouterObj_currentRoute() { return B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(this._router.currentRoute); }
		/*
		Wrapper for Vue Router's replace() method
		When we call _router.replace() (ex because we've just created a record and want to go from "/citizens/*" to "/citizens/123"),
		it does 2 things we don't want, and there's no way to go around this wo hacking Vue Router:
			-Call beforeRouteUpdate() hooks
			-Call scrollBehavior() hook
		Wrap it here so we can indicate everywhere that we want to ignore such events
		*/
		async routes_silentRouteUpdate(options)
		{
			this._router_isIgnoringReplaceEvents = true;
			
			try
			{
				await this._router.replace(options);
				//WARNING: If we figure out this never ends, it's prolly because we forgot to call next() in our beforeRouteEnter() / beforeRouteUpdate() / beforeRouteLeave() hooks
			}
			catch (e)
			{
				if (e.name==="NavigationDuplicated") { B_REST_Utils.console_warn(`Got a NavigationDuplicated warning from Vue Router`,e);                                            }
				else                                 { B_REST_Utils.console_warn(`Got an error in Vue Router. Prolly ok if it's because we've just done "next(false)" in a hook`,e); }
			}
			
			this._router_isIgnoringReplaceEvents = false;
		}
	
	
	
	//PICKER POPUPS RELATED
		get pickers_defs()    { return this._pickers_defs;    }
		get pickers_handles() { return this._pickers_handles; }
		pickers_getDef(name)  { return this._pickers_defs[name] || this.throwEx(`Unknown picker def "${name}"`); }
		/*
		Prepares an rets an instance of B_REST_Vuetify_PickerHandle, without making it appear yet in the UI
		Check B_REST_Vuetify_PickerHandle docs for possible options
		Throws if picker is unknown
		*/
		pickers_getHandle(name, options={})
		{
			const pickerDef = this.pickers_getDef(name);
			
			let pickerHandle = null;
			switch (pickerDef.reuseMode)
			{
				//In this mode, we always want to have exactly 1 allocated instance
				case B_REST_Vuetify_PickerDef.REUSE_MODE_CANCEL_PREVIOUS:
					pickerHandle = this._pickers_handles.find(loop_pickerHandle => loop_pickerHandle.def===pickerDef);
				break;
				//In this mode, always use distinct instances
				case B_REST_Vuetify_PickerDef.REUSE_MODE_OFF:
					pickerHandle = null;
				break;
				//Here, depends on the nb of handles we have for that def; keep at least one allocated
				case B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_USED:
					pickerHandle = this._pickers_handles.find(loop_pickerHandle => loop_pickerHandle.def===pickerDef);
					if (pickerHandle?.isPrompting) { pickerHandle=null; }
				break;
				default:
					this.throwEx(`Unexpected picker handle reuseMode`,pickerHandle);
				break;
			}
			
			if (pickerHandle) { pickerHandle.updateOptions(options); }
			else
			{
				pickerHandle = new B_REST_Vuetify_PickerHandle(pickerDef, options);
				this._pickers_handles.push(pickerHandle);
			}
			
			return pickerHandle;
		}
		/*
		Like pickers_getHandle(), but instead of returning the B_REST_Vuetify_PickerHandle instance,
		it opens it and awaits the result of selecting / closing the picker, returning either:
			<anything>
			[<anything>]
			NULL
		*/
		async pickers_prompt_single(  name,options={}) { return this._pickers_prompt_x(name,options,false); }
		async pickers_prompt_multiple(name,options={}) { return this._pickers_prompt_x(name,options,true);  }
			async _pickers_prompt_x(name, options, isMultiple)
			{
				B_REST_Utils.object_assert(options);
				
				const pickerHandle = this.pickers_getHandle(name, {...options,isMultiple}); //Throws
				return pickerHandle.prompt();
			}
		//Called by B_REST_Vuetify_PickerHandle::_select(), to check if we should rem them from the handles arr
		pickers_checkRelease(pickerHandle)
		{
			let release = null;
			
			switch (pickerHandle.def.reuseMode)
			{
				//In this mode, we always want to have exactly 1 allocated instance, so never release it
				case B_REST_Vuetify_PickerDef.REUSE_MODE_CANCEL_PREVIOUS:
					release = false;
				break;
				//In this mode, always release after usage
				case B_REST_Vuetify_PickerDef.REUSE_MODE_OFF:
					release = true;
				break;
				//Here, depends on the nb of handles we have for that def; keep at least one allocated
				case B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_USED:
					release = this._pickers_handles.filter(loop_pickerHandle => loop_pickerHandle.def===pickerHandle.def).length>1;
				break;
				default:
					this.throwEx(`Unexpected picker handle reuseMode`,pickerHandle.def);
				break;
			}
			
			if (release) { B_REST_Utils.array_remove_byVal(this._pickers_handles,pickerHandle); }
		}
	
	
	
	//HELPERS
		/*
		Vue :class props accept strings, arr and objs
		Makes it easier to add more item, to a prev val we might not have control on
		Usage ex:
			computed: {
				myClasses()
				{
					let classes = <something that gen a string, arr, obj..>
					
					if (...) { x=classProp_addTag(classes,"thing--is-highlighted"); }
					if (...) { x=classProp_addTag(classes,"thing--is-del");         }
					if (...) { x=classProp_addTag(classes,"thing--etc");            }
					
					return classes
				},
			}
		*/
		classProp_addTag(currVal, className)
		{
			if (!currVal)                             { currVal=className;        }
			else if (B_REST_Utils.string_is(currVal)) { currVal+=` ${className}`; }
			else if (B_REST_Utils.array_is( currVal)) { currVal.push(className);  }
			else if (B_REST_Utils.object_is(currVal)) { currVal[className]=true;  }
			else { B_REST_Utils.throwEx(`Expected a string, arr or obj`,currVal); }
			
			return currVal;
		}
		/*
		Same as classProp_addTag(), but for styles, where we must separate what's before and after the =
		Ex:
			styleProp_addTag(style, "backgroundColor","red")
		WARNING: For now, not implementing diffs for kebab-case vs camelCase
		*/
		styleProp_addTag(currVal, propName,propVal)
		{
			if (B_REST_Utils.object_is(currVal)) { currVal[propName]=propVal; }
			else
			{
				const stringified = `${propName}=${propVal}`;
				
				if (!currVal)                             { currVal=stringified;        }
				else if (B_REST_Utils.string_is(currVal)) { currVal+=` ${stringified}`; }
				else if (B_REST_Utils.array_is( currVal)) { currVal.push(stringified);  }
				else { B_REST_Utils.throwEx(`Expected a string, arr or obj`,currVal);   }
			}
			
			return currVal;
		}
		/*
		Rets the closest (self included) theme definition on a DOM element, ex ".theme--light" vs ".theme--dark", otherwise app's theme
		NOTE: Rets as "light" and not "theme--light"
		*/
		dom_getClosestTheme(element)
		{
			var loop_element = element;
			
			while (loop_element)
			{
				if (loop_element.classList.contains("theme--light")) { return "light"; }
				if (loop_element.classList.contains("theme--dark"))  { return "dark";  }
				
				loop_element = loop_element.parentElement;
			}
			
			return this.uiTheme;
		}
		/*
		Check dom_getClosestTheme_component() docs; To be used on a Vue component, also checking for self "light" & "dark" props
		WARNING: Mustn't be used before rendered, otherwise $el will be undefined
		*/
		dom_getClosestTheme_component(component)
		{
			if (B_REST_Utils.object_hasPropName(component.$attrs,"light")) { return "light"; }
			if (B_REST_Utils.object_hasPropName(component.$attrs,"dark"))  { return "dark";  }
			
			if (!component.$el) { this.throwEx(`Using dom_getClosestTheme_component() too early`,component); }
			return this.dom_getClosestTheme(component.$el);
		}
};
