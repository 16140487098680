
export default class B_REST_FieldDescriptor_DB_EnumMember
{
	_tag       = null; //Ex "f" for a gender enum
	_label     = null; //Translated label
	_extraData = null;
	
	
	constructor(tag, extraData=null)
	{
		this._tag       = tag;
		this._extraData = extraData;
	}
	
	
	get tag()       { return this._tag;       }
	get extraData() { return this._extraData; }
	
	get label()    { return this._label; }
	set label(val) { this._label = val;  }
};
