
import { B_REST_Utils, B_REST_Descriptor, B_REST_FieldDescriptors } from "../../../../../classes";
import { GForm_BrFieldDbField, GForm_SubModelListField, GForm_FileField, GForm_OtherField } from "./GForm_XFields.js";



export default class GForm
{
	static get FLAG_ACTIONS_BEHAVIOR_CARD_DOES_THEN_EMITS_AFTER() { return "cardDoesThenEmitsAfter"; }
	static get FLAG_ACTIONS_BEHAVIOR_CARD_EMITS_THEN_INDEX_DOES() { return "cardEmitsThenIndexDoes"; }
	static get FLAG_ACTIONS_BEHAVIOR_INDEX_SLOT()                 { return "indexSlot";              }
	static get FLAG_ACTIONS_BEHAVIORS() { return [GForm.FLAG_ACTIONS_BEHAVIOR_CARD_DOES_THEN_EMITS_AFTER, GForm.FLAG_ACTIONS_BEHAVIOR_CARD_EMITS_THEN_INDEX_DOES, GForm.FLAG_ACTIONS_BEHAVIOR_INDEX_SLOT]; }
	
	static get FLAG_CUSTOM_VALIDATION_DEF_CARD()  { return "card";  }
	static get FLAG_CUSTOM_VALIDATION_DEF_INDEX() { return "index"; }
	static get FLAG_CUSTOM_VALIDATION_DEFS() { return [GForm.FLAG_CUSTOM_VALIDATION_DEF_CARD, GForm.FLAG_CUSTOM_VALIDATION_DEF_INDEX]; }
	
	static get POSSIBLE_FIELDS_RECURSE_LIMIT() { return 2; }
	
	
	_descriptor = null;  //Instance of B_REST_Descriptor
	
	_routerModuleName            = null;  //Ex "citizen"
	_routerPathLang              = null;  //Ex "/citoyens/". WARNING: Should be split to a lang field so we fill it 3+ times
	_componentsDirPath           = null;  //Ex "/views/modules/citizen/"
	_componentFileNameWOExt_list = null;  //Ex "CitizenList"
	_componentFileNameWOExt_form = null;  //Ex "CitizenForm"
	_formName                    = null;  //Ex "citizenForm"
	_apiBaseUrl                  = null;  //Ex "/citizens/"
	_autoSave                    = false;
	_reqFields                   = null; //Ex "<all>|subStuff.abc"
	
	_possibleFields        = [];    //Adapted struct for <v-treeview>, like [{id,name,children, <extra stuff>}], where extra stuff is like fieldDescriptor, selectFieldNamePath, isReadOnly...
	_possibleFields_nextId = null;  //To help assigning unique ids to the tree view nodes
	
	_fields_brFieldDbFields    = [];  //Arr of GForm_BrFieldDbField
	_fields_subModelListFields = [];  //Arr of GForm_SubModelListField
	_fields_fileFields         = [];  //Arr of GForm_FileField
	_fields_otherFields        = [];  //Arr of GForm_OtherField
	
	_cols_xs = null; //If specified, from 1-12 or "auto"
	_cols_sm = null; //If specified, from 1-12 or "auto"
	_cols_md = null; //If specified, from 1-12 or "auto"
	_cols_lg = null; //If specified, from 1-12 or "auto"
	_cols_xl = null; //If specified, from 1-12 or "auto"
	
	
	constructor()
	{
		this._reqFields = "<all>";
		
		this._cols_xs = 12;
		this._cols_md = 6;
	}
	
	
	get descriptor() { return this._descriptor; }
	set descriptor(val)
	{
		this._descriptor = val;
		
		const lowerModelClassName = B_REST_Utils.string_lcFirst(this.modelClassName);
		
		
		this._routerModuleName            = lowerModelClassName;
		this._routerPathLang              = `/${lowerModelClassName}s/`;
		this._componentsDirPath           = `/views/modules/${lowerModelClassName}/`;
		this._componentFileNameWOExt_list = `${this._descriptor.name}List`;
		this._componentFileNameWOExt_form = `${this._descriptor.name}Form`;
		this._formName                    = `${lowerModelClassName}Form`;
		this._modelClassName              = this._descriptor.name;
		this._apiBaseUrl                  = `/${lowerModelClassName}s/`;
		
		this._possibleFields_recalc();
		
		this._fields_brFieldDbFields    = [];
		this._fields_subModelListFields = [];
		this._fields_fileFields         = [];
		this._fields_otherFields        = [];
	}
	get modelClassName() { return this._descriptor?.name ?? null; }
	
	get routerModuleName()    { return this._routerModuleName; }
	set routerModuleName(val) { this._routerModuleName=val;    }
	
	get routerPathLang()    { return this._routerPathLang; }
	set routerPathLang(val) { this._routerPathLang=val;    }
	
	get componentsDirPath()    { return this._componentsDirPath; }
	set componentsDirPath(val) { this._componentsDirPath=val;    }
	
	get componentFileNameWOExt_list()    { return this._componentFileNameWOExt_list; }
	set componentFileNameWOExt_list(val) { this._componentFileNameWOExt_list=val;    }
	
	get componentFileNameWOExt_form()    { return this._componentFileNameWOExt_form; }
	set componentFileNameWOExt_form(val) { this._componentFileNameWOExt_form=val;    }
	
	get formName()             { return this._formName;                 }
	set formName(val)          { this._formName=val;                    }
	get formName_locBasePath() { return `components.${this._formName}`; }
	
	get apiBaseUrl()    { return this._apiBaseUrl; }
	set apiBaseUrl(val) { this._apiBaseUrl=val;    }
	
	get autoSave()    { return this._autoSave; }
	set autoSave(val) { this._autoSave=val;    }
	
	get reqFields()    { return this._reqFields; }
	set reqFields(val) { this._reqFields=val;    }
	
	get componentFilePath_list() { return `@${this._componentsDirPath}${this._componentFileNameWOExt_list}.vue`; }
	get componentFilePath_form() { return `@${this._componentsDirPath}${this._componentFileNameWOExt_form}.vue`; }
	
	
	get possibleFields() { return this._possibleFields; }
	_possibleFields_recalc()
	{
		const foundDescriptors = []; //To prevent endless loops
		
		this._possibleFields_nextId = 0;
		const node = this._possibleFields_recalc_nest(foundDescriptors, this._descriptor, "Root", /*fieldNamePathPrefix*/"", /*allReadOnly*/false);
		
		this._possibleFields = node.children; //Or [node], if we want to carry the "Root" node itself
	}
		_possibleFields_recalc_nest(foundDescriptors, descriptor, nodeName, fieldNamePathPrefix, allReadOnly)
		{
			const descriptorParentOccurencesCount = foundDescriptors.reduce((acc,loop_descriptor) => acc+(loop_descriptor===descriptor?1:0), 0);
			if (descriptorParentOccurencesCount>GForm.POSSIBLE_FIELDS_RECURSE_LIMIT) { return; }
			
			foundDescriptors = [...foundDescriptors]; //Before adding this descriptor, make a copy of the received arr, so when the parent caller loops its fields, we don't leak stuff between iterations
			foundDescriptors.push(descriptor);
			
			const node = {
				id:       this._possibleFields_nextId++,
				name:     `${nodeName} (${descriptor.name} model)`,
				children: [],
			};
			const nodeChildren = node.children;
			
			//Group special fields in a sub node
			{
				const midNodeChildren = [];
				
				for (const loop_field of descriptor.pks)
				{
					midNodeChildren.push({
						id:                  this._possibleFields_nextId++,
						name:                loop_field.name,
						fieldDescriptor:     loop_field,
						selectFieldNamePath: `${fieldNamePathPrefix}${loop_field.name}`,
						isReadOnly:          true,
					});
				}
				
				if (descriptor.cDT)
				{
					midNodeChildren.push({
						id:                  this._possibleFields_nextId++,
						name:                descriptor.cDT.name,
						fieldDescriptor:     descriptor.cDT,
						selectFieldNamePath: `${fieldNamePathPrefix}${descriptor.cDT.name}`,
						isReadOnly:          true,
					});
				}
				
				if (descriptor.uDT)
				{
					midNodeChildren.push({
						id:                  this._possibleFields_nextId++,
						name:                descriptor.uDT.name,
						fieldDescriptor:     descriptor.uDT,
						selectFieldNamePath: `${fieldNamePathPrefix}${descriptor.uDT.name}`,
						isReadOnly:          true,
					});
				}
				
				if (midNodeChildren.length>0)
				{
					nodeChildren.push({
						id:       this._possibleFields_nextId++,
						name:     "<Special DB fields>",
						children: midNodeChildren,
					});
				}
			}
			
			//Group DB fields in a sub node, except FKs of lookups
			{
				const midNodeChildren = [];
				
				for (const loop_fieldName in descriptor.dbFields)
				{
					const loop_field = descriptor.dbFields[loop_fieldName];
					
					if (loop_field.lookup_is) { continue; } //Only make sense for B_REST_FieldDescriptor_DB
					
					midNodeChildren.push({
						id:                  this._possibleFields_nextId++,
						name:                `${loop_field.name} (${loop_field.type})`,
						fieldDescriptor:     loop_field,
						selectFieldNamePath: `${fieldNamePathPrefix}${loop_field.name}`,
						isReadOnly:          allReadOnly,
					});
				}
				
				if (midNodeChildren.length>0)
				{
					nodeChildren.push({
						id:       this._possibleFields_nextId++,
						name:     "<Normal DB fields>",
						children: midNodeChildren,
					});
				}
			}
			
			//Group self lookup fields in a sub node
			{
				const midNodeChildren = [];
				
				for (const loop_fieldName in descriptor.modelLookupRefFields)
				{
					const loop_field = descriptor.modelLookupRefFields[loop_fieldName];
					if (loop_field.isShared) { continue; }
					
					const loop_subDescriptor = B_REST_Descriptor._commonDefs[loop_field.modelClassName];
					
					const loop_subModelNode = this._possibleFields_recalc_nest(foundDescriptors, loop_subDescriptor, loop_fieldName, `${fieldNamePathPrefix}${loop_field.name}.`, /*allReadOnly*/false);
					if (loop_subModelNode) { midNodeChildren.push(loop_subModelNode); }
				}
				
				if (midNodeChildren.length>0)
				{
					nodeChildren.push({
						id:       this._possibleFields_nextId++,
						name:     "<Self lookup fields>",
						children: midNodeChildren,
					});
				}
			}
			
			//Group shared lookup fields in a sub node
			{
				const midNodeChildren = [];
				
				for (const loop_fieldName in descriptor.modelLookupRefFields)
				{
					const loop_field = descriptor.modelLookupRefFields[loop_fieldName];
					if (!loop_field.isShared) { continue; }
					
					//Add the FK itself, if we want to use it as a picker
					const loop_fkField = descriptor.dbFields_find(loop_field.fk_dbFieldName);
					midNodeChildren.push({
						id:                  this._possibleFields_nextId++,
						name:                `${loop_fieldName} (FK)`,
						fieldDescriptor:     loop_fkField,
						selectFieldNamePath: `${fieldNamePathPrefix}${loop_fkField.name}`,
						isReadOnly:          allReadOnly,
					});
					
					//Add its sub fields, in case we want to display all that's related to what we selected
					const loop_subDescriptor = B_REST_Descriptor._commonDefs[loop_field.modelClassName];
					const loop_subModelNode  = this._possibleFields_recalc_nest(foundDescriptors, loop_subDescriptor, loop_fieldName, `${fieldNamePathPrefix}${loop_field.name}.`, /*allReadOnly*/false);
					if (loop_subModelNode) { midNodeChildren.push(loop_subModelNode); }
				}
				
				if (midNodeChildren.length>0)
				{
					nodeChildren.push({
						id:       this._possibleFields_nextId++,
						name:     "<Shared lookup fields>",
						children: midNodeChildren,
					});
				}
			}
			
			//Group sub model fields in a sub node
			{
				const midNodeChildren = [];
				
				for (const loop_fieldName in descriptor.subModelFields)
				{
					const loop_field         = descriptor.subModelFields[loop_fieldName];
					const loop_subDescriptor = B_REST_Descriptor._commonDefs[loop_field.modelClassName];
					
					const loop_subModelNode = this._possibleFields_recalc_nest(foundDescriptors, loop_subDescriptor, loop_fieldName, `${fieldNamePathPrefix}${loop_field.name}.`, /*allReadOnly*/false);
					if (loop_subModelNode) { midNodeChildren.push(loop_subModelNode); }
				}
				
				if (midNodeChildren.length>0)
				{
					nodeChildren.push({
						id:       this._possibleFields_nextId++,
						name:     "<Single sub model fields>",
						children: midNodeChildren,
					});
				}
			}
			
			//Group sub model list fields in a sub node
			this._possibleFields_recalc_oneGroup(nodeChildren, descriptor.subModelListFields, "Sub model list fields", fieldNamePathPrefix, allReadOnly);
			
			//Group file fields in a sub node
			this._possibleFields_recalc_oneGroup(nodeChildren, descriptor.fileFields, "File fields", fieldNamePathPrefix, allReadOnly);
			
			//Group other fields in a sub node
			this._possibleFields_recalc_oneGroup(nodeChildren, descriptor.otherFields, "Other fields", fieldNamePathPrefix, allReadOnly);
			
			return node;
		}
			_possibleFields_recalc_oneGroup(parentNodeChildren, xFields, groupName, fieldNamePathPrefix, allReadOnly)
			{
				const groupChildren = [];
				
				for (const loop_fieldName in xFields)
				{
					const loop_field = xFields[loop_fieldName];
					
					groupChildren.push({
						id:                  this._possibleFields_nextId++,
						name:                loop_field.name,
						fieldDescriptor:     loop_field,
						selectFieldNamePath: `${fieldNamePathPrefix}${loop_field.name}`,
						isReadOnly:          allReadOnly,
					});
				}
				
				if (groupChildren.length>0)
				{
					parentNodeChildren.push({
						id:       this._possibleFields_nextId++,
						name:     `<${groupName}>`,
						children: groupChildren,
					});
				}
			}
	
	fields_addX(possibleField)
	{
		if      (possibleField.fieldDescriptor instanceof B_REST_FieldDescriptors.SubModelList) { this._fields_addX("_fields_subModelListFields",GForm_SubModelListField,possibleField); }
		else if (possibleField.fieldDescriptor instanceof B_REST_FieldDescriptors.File)         { this._fields_addX("_fields_fileFields",        GForm_FileField,        possibleField); }
		else if (possibleField.fieldDescriptor instanceof B_REST_FieldDescriptors.Other)        { this._fields_addX("_fields_otherFields",       GForm_OtherField,       possibleField); }
		else                                                                                    { this._fields_addX("_fields_brFieldDbFields",   GForm_BrFieldDbField,   possibleField); }
	}
		_fields_addX(which, GForm_XFieldClass, possibleField)
		{
			const field = new GForm_XFieldClass(this, possibleField.fieldDescriptor, possibleField.selectFieldNamePath, possibleField.isReadOnly);
			
			this[which].push(field);
		}
	
	get fields_brFieldDbFields()     { return this._fields_brFieldDbFields;                }
	fields_brFieldDbFields_del(line) { this._fields_x_del("_fields_brFieldDbFields",line); }
	
	get fields_subModelListFields()     { return this._fields_subModelListFields;                }
	fields_subModelListFields_del(line) { this._fields_x_del("_fields_subModelListFields",line); }
	
	get fields_fileFields()     { return this._fields_fileFields;                }
	fields_fileFields_del(line) { this._fields_x_del("_fields_fileFields",line); }
	
	get fields_otherFields()     { return this._fields_otherFields;                }
	fields_otherFields_del(line) { this._fields_x_del("_fields_otherFields",line); }
	
	_fields_x_del(which, line) { this[which] = this[which].filter(loop_line => loop_line!==line); }
	
	get cols_xs()    { return this._cols_xs; }
	set cols_xs(val) { this._cols_xs=val;    }
	
	get cols_sm()    { return this._cols_sm; }
	set cols_sm(val) { this._cols_sm=val;    }
	
	get cols_md()    { return this._cols_md; }
	set cols_md(val) { this._cols_md=val;    }
	
	get cols_lg()    { return this._cols_lg; }
	set cols_lg(val) { this._cols_lg=val;    }
	
	get cols_xl()    { return this._cols_xl; }
	set cols_xl(val) { this._cols_xl=val;    }
};
