
import { B_REST_Utils } from "../../../../classes";

B_REST_Utils.console_todo([
	"Check how to implement displaying it's loading (maybe in dialog top + disable action buttons",
	"Allow setupping possible actions (colors, on click do stuff. Should maybe do slot with a {selectAction}",
	`Should maybe check if interceptor fails, to display why, if we received something, or if the user should handle it himself
		-> Think it makes more sense that we handle it here, since dialog is started in JS and not Vue`,
]);



export class B_REST_Vuetify_Prompt
{
	_isModal  = null;
	_title    = null;
	_body     = null;
	_actions  = []; //Arr of B_REST_Vuetify_Prompt_Action instances or null (for spacers)
	_isActive = false;
	_color    = null;
	
	//Vars only set when shown
	_shownPromise_resolver = null;
	_shownPromise_rejecter = null;
	_interceptor           = null; //User func as (selectedAction, resolve, reject), optionally called when we choose an action not being NULL
	_isIntercepting        = false; //Whether or not we've just called the above interceptor and are waiting for a resolve / reject
	_interceptor_retDialog = null;
	
	
	
	constructor(title, isModal)
	{
		this._isModal = isModal;
		this._title   = title;
	}
	
	static _throwEx(sMsg) { B_REST_Utils.throwEx(`B_REST_Vuetify_Prompt: ${sMsg}`); }
	
	
	get isModal() { return this._isModal; }
	set isModal(val) { this._isModal=val; }
	
	get title() { return this._title; }
	set title(val) { this._title=val; }
	
	get body() { return this._body; }
	set body(val) { this._body=val; }
	
	get actions() { return this._actions; }
	set actions(val) { this._actions = val; }
	
	get isActive() { return this._isActive; }
	
	get color() { return this._color; }
	set color(val) { this._color = val; }
	
	get isIntercepting() { return this._isIntercepting; }
	get interceptor_retDialog() { return this._interceptor_retDialog; }
	
	
	
	async show(interceptor=null)
	{
		if (this.isActive) { B_REST_Vuetify_Prompt._throwEx(`Trying to show when already active. Finish action first`); }
		if (interceptor!==null && typeof interceptor!=="function") { B_REST_Vuetify_Prompt._throwEx(`Interceptor must be as f(selectedAction,resolve,reject)`); }
		
		this._interceptor = interceptor;
		
		return new Promise((s,f) =>
		{
			this._shownPromise_resolver = s;
			this._shownPromise_rejecter = f;
			
			this._isActive = true;
		});
	}
	
	//Where NULL = clicked outside / did ESC
	async selectAction(selectedActionOrNull)
	{
		//Just some validation, shouldn't happen though
		if (!this.isActive)       { B_REST_Vuetify_Prompt._throwEx(`Trying to perform action when dialog is inactive`);                      }
		if (this._isIntercepting) { B_REST_Vuetify_Prompt._throwEx(`Already intercepting; can't try to perform 2 actions at the same time`); }
		
		/*
		If we have to wait for the user to do something about the action, that could either take time or fail, mention that we're waiting for an external response.
		Then, only "finish" the action if all was right. If user code decided that the action can't be performed or got an err, we'll just leave the dialog opened, to let the user try another action.
		In the interceptor, the user could also call .cancel() to just stop all.
		*/
		if (this._interceptor && selectedActionOrNull!==null)
		{
			this._isIntercepting = true;
			const bUserOK = await this._doInterception(selectedActionOrNull);
			this._isIntercepting = false;
			
			if (bUserOK) { this._done(selectedActionOrNull); }
		}
		else { this._done(selectedActionOrNull); }
	}
		async _doInterception(selectedAction)
		{
			return new Promise((outer_s,outer_f) =>
			{
				const innerPromise = new Promise((inner_s,inner_f) =>
				{
					this._interceptor(selectedAction,inner_s,inner_f);
				});
				
				innerPromise
					.then(retMsgOrNull  => { return {success:true, retMsgOrNull}; })
					.catch(retMsgOrNull => { return {success:false,retMsgOrNull}; })
					.then(async (infos) =>
					{
						if (infos.retMsgOrNull!==null)
						{
							this._interceptor_retDialog = new B_REST_Vuetify_Prompt("",false);
							this._interceptor_retDialog.body = infos.retMsgOrNull;
							this._interceptor_retDialog.actions = [
								new B_REST_Vuetify_Prompt_Action(null, "OK", null),
							];
							this._interceptor_retDialog.color = infos.success ? "success" : "error";
							await this._interceptor_retDialog.show();
							this._interceptor_retDialog = null;
						}
						
						outer_s(infos.success);
					});
			});
		}
		_done(data, bResolve=true)
		{
			this._isActive = false;
			
			if (bResolve) { this._shownPromise_resolver(data); }
			else          { this._shownPromise_rejecter(data); }
			
			this._shownPromise_resolver = null;
			this._shownPromise_rejecter = null;
			this._interceptor           = null;
		}
	
	//Can be called anytime to force closing of the dialog, either because we used a certain NULL action / pressed ESC, or just because external code decided to forget about it
	async cancel() { await this.selectAction(null); }
};



export class B_REST_Vuetify_Prompt_Action
{
	_tag     = null; //Can be something like NULL | "OK" | "cancel" | "saveStuff"...
	_label   = null;
	_color   = null;
	_enabled = true;
	
	
	constructor(tag, label, color)
	{
		this._tag   = tag;
		this._label = label;
		this._color = color;
	}
	
	
	get tag()     { return this._tag;     }
	get label()   { return this._label;   }
	get color()   { return this._color;   }
	get enabled() { return this._enabled; }
	
	set enabled(val) { this._enabled = val; }
};
