<template>
	
	<v-container class="fill-height mx-auto" style="max-width: 600px">
		
		<v-skeleton-loader v-if="!matchDetails" type="article@3" class="col col-12" />
		
		<v-row v-if="matchDetails">
			
			<!-- TOKEN REMAINING -->
			<v-col cols="12">
				<v-spacer />
				<kb-credit-token-remaining />
			</v-col>
			
			<!-- TYPE INFO -->
			<v-row class="mb-3">
				<v-col cols="12">
					<v-alert type="info" border="left" elevation="2">
						<h5 class="text-h5">{{ brand_status_short }}</h5>
						<p class="text-body-1">{{ brand_status_long }}</p>
					</v-alert>
				</v-col>
			</v-row>
			
			<!-- MUST DECIDE BEFORE BANNER -->
			<v-row class="mb-3">
				<v-col v-if="match_mustDecideBefore_text" cols="12">
					<v-alert type="warning" border="left" elevation="2" prominent>
						<v-row>
							<v-col cols="12">{{ match_mustDecideBefore_text }}</v-col>
							<v-col v-if="match_canPostpone" cols="auto" class="ml-auto"><v-btn @click="on_postpone_click">{{ _loc("actions.postpone.btn") }}</v-btn></v-col>
						</v-row>
					</v-alert>
				</v-col>
			</v-row>
			
			<!-- PERSON -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("person.title") }}</v-card-title>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr> <th>{{ _loc("person.fields.title") }}</th>    <td>{{ matchDetails.person.title }}</td>    </tr>
								<tr> <th>{{ _loc("person.fields.fullName") }}</th> <td>{{ matchDetails.person.fullName }}</td> </tr>
								<tr> <th>{{ _loc("person.fields.address") }}</th>  <td>{{ matchDetails.person.address }}</td>  </tr>
								<tr> <th>{{ _loc("person.fields.phone") }}</th>    <td>{{ matchDetails.person.phone }}</td>    </tr>
								<tr> <th>{{ _loc("person.fields.email") }}</th>    <td>{{ matchDetails.person.email }}</td>    </tr>
								<tr>
									<th>{{ _loc("person.fields.cv.title") }}</th>
									<td v-if="person_cv_downloadLink"><a :href="person_cv_downloadLink" target="_blank">{{ person_cv }}</a></td>
									<td v-else>{{ person_cv }}</td>
								</tr>
								<tr> <th>{{ _loc("person.fields.in") }}</th> <td>{{ matchDetails.person.in }}</td> </tr>
								<tr> <th>{{ _loc("person.fields.fb") }}</th> <td>{{ matchDetails.person.fb }}</td> </tr>
								<tr> <th>{{ _loc("person.fields.tw") }}</th> <td>{{ matchDetails.person.tw }}</td> </tr>
								<tr v-if="matchDetails.person.customCriteria">
									<th>{{ _loc("person.fields.customCriteria") }}</th>
									<td>{{ matchDetails.person.customCriteria }}</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
					<v-card-actions v-if="showBuyBtn">
						<v-row justify="end">
							<v-col cols="auto">
								<v-btn color="primary" @click="on_buy_click">
									{{ _loc("actions.buy.btn") }}
									<kb-credit-token-icon :value="match_tokenCost" class="ml-4" />
								</v-btn>
							</v-col>
						</v-row>
					</v-card-actions>
					<v-card-actions v-else-if="!isAnonymous">
						<v-btn-toggle v-model="currentAction" @change="on_currentAction_change" mandatory group color="accent" class="justify-space-between" style="width:100%">
							<v-btn value="rejected"> <v-icon>mdi-thumb-down</v-icon>{{ rejectBtn_loc }} </v-btn>
							<v-btn value="ongoing">  <v-icon>mdi-av-timer</v-icon>  {{ _loc("actions.ongoing.btn") }}  </v-btn>
							<v-btn value="signed">   <v-icon>mdi-thumb-up</v-icon>  {{ signBtn_loc }} </v-btn>
						</v-btn-toggle>
					</v-card-actions>
				</v-card>
			</v-col>
			
			<!-- COMPAT -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("match.title") }}</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" class="text-center">
								<h4 class="text-h6">{{ _loc("match.avg") }}</h4>
								<kb-compat-circle tag="avg" :value="matchDetails.summary.avg" />
							</v-col>
							<v-col cols="12">
								<v-divider />
								<h4 class="text-h6">{{ _loc("match.topics") }}</h4>
								<kb-compat-details :value="match_topicsPercents" />
							</v-col>
							<v-col cols="12">
								<v-divider />
								<v-alert type="info" outlined> <kb-credit-token-legend /> </v-alert>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- SUPPORT -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("support.title") }}</v-card-title>
					<v-card-subtitle>{{ _loc("support.subtitle") }}</v-card-subtitle>
					<v-card-text>
						<v-row no-gutters>
							<v-col v-for="(_,loop_tag) in matchDetails.support" :key="loop_tag" cols="12" md="6">
								<v-checkbox :label="support_getLoc(loop_tag)" :input-value="support_getVal(loop_tag)" readonly />
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- LEGEND -->
			<v-col cols="12" class="mt-4">
				<v-alert type="info" outlined class="ml-auto" style="max-width:300px">
					<h4 class="text-h5">{{ _loc("legend.title") }}</h4>
					<div class="success white--text pa-1 pl-4">{{ _loc("legend.perfect") }}</div>
					<div class="warning white--text pa-1 pl-4">{{ _loc("legend.good") }}</div>
					<div class="error white--text pa-1 pl-4">  {{ _loc("legend.bad") }}</div>
				</v-alert>
			</v-col>
			
			<!-- SKILLS -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("skills.title") }}</v-card-title>
					<v-card-subtitle>{{ _loc("skills.sectors.subtitle_mine") }}</v-card-subtitle>
					<v-card-text>
						<v-simple-table v-if="matchDetails.skills.sectors.mine.length>0">
							<template v-for="(loop_sector,loop_sector_idx) in matchDetails.skills.sectors.mine">
								<thead :key="`sector_thead_${loop_sector_idx}`">
									<tr>
										<th>{{ loop_sector.name }}</th>
										<th>{{ _loc("skills.sectors.experience.title") }}</th>
										<th>{{ _loc("skills.sectors.scholarity.title") }}</th>
									</tr>
								</thead>
								<tbody :key="`sector_tbody_${loop_sector_idx}`">
									<tr v-for="(loop_subSector,loop_subSector_idx) in loop_sector.subSectors" :key="`subSector_${loop_sector_idx}_${loop_subSector_idx}`">
										<td>{{ loop_subSector.name }}</td>
										<td :class="subSector_getColor_experience(loop_subSector)" />
										<td :class="subSector_getColor_scholarity(loop_subSector)" />
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<div v-else class="text-body-2 ml-5">{{ _loc("skills.sectors.none_mine") }}</div>
					</v-card-text>
					<v-card-subtitle>{{ _loc("skills.sectors.subtitle_theirs") }}</v-card-subtitle>
					<v-card-text>
						<v-simple-table v-if="matchDetails.skills.sectors.theirs.length>0">
							<template v-for="(loop_sector,loop_sector_idx) in matchDetails.skills.sectors.theirs">
								<thead :key="`sector_thead_${loop_sector_idx}`">
									<tr>
										<th>{{ loop_sector.name }}</th>
										<th>{{ _loc("skills.sectors.experience.title") }}</th>
										<th>{{ _loc("skills.sectors.scholarity.title") }}</th>
									</tr>
								</thead>
								<tbody :key="`sector_tbody_${loop_sector_idx}`">
									<tr v-for="(loop_subSector,loop_subSector_idx) in loop_sector.subSectors" :key="`subSector_${loop_sector_idx}_${loop_subSector_idx}`">
										<td>{{ loop_subSector.name }}</td>
										<td>{{ subSector_theirs_experience(loop_subSector) }}</td>
										<td>{{ subSector_theirs_scholarity(loop_subSector) }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<div v-else class="text-body-2 ml-5">{{ _loc("skills.sectors.none_theirs") }}</div>
					</v-card-text>
					<v-card-subtitle>{{ _loc("skills.rate.subtitle") }}</v-card-subtitle>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr v-for="(_,loop_tag) in matchDetails.skills.rate" :key="loop_tag">
									<td>{{ skillRate_getLoc(loop_tag) }}</td>
									<td :class="skillRate_getColor(loop_tag)" />
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- AFFINITIES -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("affinity.title") }}</v-card-title>
					<v-card-text>
						<v-simple-table>
							<thead>
								<tr>
									<th>{{ _loc("affinity.morality") }}</th>
									<th :class="affinityMorality_getColor()" />
								</tr>
								<tr>
									<th colspan="2">{{ _loc("affinity.bi") }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(_,loop_tag) in matchDetails.affinity.bi" :key="loop_tag">
									<td>{{ affinityBi_getLoc(loop_tag) }}</td>
									<td :class="affinityBi_getColor(loop_tag)" />
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- TERRITORIES -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("territories.title") }}</v-card-title>
					
					<v-card-text v-if="matchDetails.territories">
						<v-list>
							<v-list-item v-for="(loop_territory,loop_idx) in filteredTerritories" :key="loop_idx">
								<v-list-item-icon><v-icon>{{ getTerritoryIcon(loop_territory) }}</v-icon></v-list-item-icon>
								<v-list-item-content>{{ loop_territory.name }}</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
					
					<v-card-text v-else>{{ _loc("territories.none") }}</v-card-text>
				</v-card>
			</v-col>
			
			<!-- PROFILE -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("profile.title") }}</v-card-title>
					<v-card-subtitle>{{ _loc("profile.subtitle") }}</v-card-subtitle>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr v-for="(_,loop_tag) in matchDetails.profileRate" :key="loop_tag">
									<td>{{ profileRate_getLoc(loop_tag) }}</td>
									<td :class="profileRate_getColor(loop_tag)" />
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- SCHEDULE -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("timeSchedule.title") }}</v-card-title>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr v-for="(_,loop_tag) in matchDetails.timeSchedule" :key="loop_tag">
									<td>{{ timeSchedule_getLoc(loop_tag) }}</td>
									<td>{{ timeSchedule_getVal(loop_tag) }}</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- YEARN -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("yearn.title") }}</v-card-title>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr v-for="(_,loop_tag) in matchDetails.yearn" :key="loop_tag">
									<td>{{ yearn_getLoc(loop_tag) }}</td>
									<td :class="yearn_getColor(loop_tag)">{{ yearn_getCheckbox(loop_tag) }}</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			
			<!-- BUDGET -->
			<v-col cols="12">
				<v-card>
					<v-card-title>{{ _loc("budget.title") }}</v-card-title>
					<v-card-text>
						<v-simple-table>
							<tbody>
								<tr> <td>{{ _loc("budget.revenue") }}       </td> <td :class="budget_getColor('revenue')"  /> </tr>
								<tr> <td>{{ _loc("budget.invest") }}        </td> <td :class="budget_getColor('invest')"   /> </tr>
								<tr> <td>{{ _loc("budget.netWorth") }}      </td> <td :class="budget_getColor('netWorth')" /> </tr>
								<tr> <td>{{ _loc("budget.bankrupt.title") }}</td> <td>{{ budget_getBankrupt_val() }}</td>     </tr>
							</tbody>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
			
		</v-row>
		
		<br-prompt v-if="confirmPrompt" :instance="confirmPrompt" />
		
	</v-container>
	
</template>
<script>
	
	import { B_REST_ModelFields } from "@/bREST/core/classes";
	import { COMPAT_DETAILED_TOPICS } from "@/custom/components/modules/KbCompatCircle.vue";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action } from "@/bREST/core/implementations/vue/vuetifyComponents/prompt/B_REST_Vuetify_Prompt.js";
	
	const locBasePath = "views.matchHeatmap";

	const CONFIRM_PROMPT_ACTIONS_CANCEL  = "cancel";
	const CONFIRM_PROMPT_ACTIONS_PROCEED = "proceed";
	
	
	
	export default {
		components: {
			KbCreditTokenRemaining: () => import("@/custom/components/modules/KbCreditTokenRemaining.vue"),
			KbCreditTokenIcon: () => import("@/custom/components/modules/KbCreditTokenIcon.vue"),
			KbCreditTokenLegend: () => import("@/custom/components/modules/KbCreditTokenLegend.vue"),
			KbCompatCircle: () => import("@/custom/components/modules/KbCompatCircle.vue"),
			KbCompatDetails: () => import("@/custom/components/modules/KbCompatDetails.vue"),
		},
		data()
		{
			const confirmPrompt = new B_REST_Vuetify_Prompt(this._loc("confirmModal.title"), false/*isModal*/);
			confirmPrompt.actions = [
				new B_REST_Vuetify_Prompt_Action(CONFIRM_PROMPT_ACTIONS_CANCEL, this._loc("confirmModal.cancel"), null),
				null,
				new B_REST_Vuetify_Prompt_Action(CONFIRM_PROMPT_ACTIONS_PROCEED, this._loc("confirmModal.proceed"), "error"),
			];
			
			return {
				matchDetails: null, //For struct, check API's RouteParser_Profile::_getBrandOpportunityHeatmap() docs
				currentAction: "ongoing", //rejected|ongoing|signed
				confirmPrompt,
				oldAction: "ongoing",
			};	
		},
		async created()
		{
			const brand = this.$bREST.routes_current_qsa.b;
			const lead  = this.$bREST.routes_current_qsa.l;
			const hash  = this.$bREST.routes_current_qsa.h;
			
			try
			{
				const request  = new this.$bREST.GET("/profile/opportunities/brand/{brand}/heatmap/{lead}", {brand,lead});
				request.qsa_add("h",hash);
				const response = await this.$bREST.call(request);
				
				this._setFormFromCallResponse(response);
			}
			catch (e)
			{
				this.$bREST.utils.console_error(`Got err getting match data`,e);
				this.$bREST.notifs_tmp({msg:this._loc("toaster.error"), color:"error"});
			}
		},
		computed: {
			isAnonymous() { return this.matchDetails.isAnonymous; },
			person_cv()
			{
				if (this.isAnonymous) { return "?"; }
				return this.matchDetails.person.cv ? this._loc("person.fields.cv.y") : this._loc("person.fields.cv.n");
			},
			person_cv_downloadLink()
			{
				return this.matchDetails.person.cv ? B_REST_ModelFields.File.tmpFromObj_apiUrlToWholeUrl(this.matchDetails.person.cv) : null;
			},
			match_topicsPercents()
			{
				const compatTopics = [];
				for (const loop_tag in COMPAT_DETAILED_TOPICS)
				{
					const loop_percent = this.matchDetails.summary[`p_${loop_tag}`]; //Ex compat_p_skills
					compatTopics.push({tag:loop_tag, percent:loop_percent});
				}
				
				return compatTopics;
			},
			match_mustDecideBefore_text()
			{
				const date = this.matchDetails.summary.expirationDate;
				return date ? this._loc("mustDecideBefore.text",{date}) : null;
			},
			brand_status() { return this.matchDetails.summary.brand_status; },
			brand_status_short() { return this._loc(`brand_status.${this.brand_status}.short`); },
			brand_status_long() { return this._loc(`brand_status.${this.brand_status}.long`); },
			privatePublicTag() { return this.matchDetails.summary.isPrivateLeadToSiblings ? "private" : "public"; },
			match_canPostpone() { return this.matchDetails.summary.canPostpone; },
			match_tokenCost() { return this.matchDetails.summary.tokenCost; }, //NOTE: false if we can't buy because it's too low
			showBuyBtn() { return this.isAnonymous && this.match_tokenCost; },
			rejectBtn_loc() { return this.currentAction==="rejected" ? this._loc("actions.rejected.btn_past") : this._loc("actions.rejected.btn_future"); },
			signBtn_loc()   { return this.currentAction==="signed"   ? this._loc("actions.signed.btn_past")   : this._loc("actions.signed.btn_future");   },
			filteredTerritories()
			{
				return this.matchDetails.territories.filter(loop_territory => loop_territory.wasSelected);
			},
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			_regLoc(tag)           { return this.$bREST.t_custom(`views.registrationForm.${tag}`); },
			_getColor(val)
			{
				switch (val)
				{
					case "^": return "success";
					case "~": return "warning";
					case "v": return "error";
					default:  return "error";
				}
			},
			getTerritoryIcon(territory)
			{
				switch (territory.type)
				{
					case "C": return "mdi-earth";
					case "S": return "mdi-map-legend";
					case "A": return "mdi-map-marker";
					case "L": return "mdi-city-variant-outline";
					default: this.$bREST.utils.throwEx(`Unknown ${territory.type}`);
				}
			},
			support_getLoc(tag)                      { return this._regLoc(`support.items.${tag}`); },
			support_getVal(tag)                      { return this.matchDetails.support[tag];      },
			subSector_getColor_experience(subSector) { return this._getColor(subSector.experience); },
			subSector_getColor_scholarity(subSector) { return this._getColor(subSector.scholarity); },
			subSector_theirs_experience(subSector)
			{
				if (subSector.experience===null) { return this._loc("skills.sectors.experience.none"); }
				return this._loc(`skills.sectors.experience.xYears`, {years:subSector.experience});
			},
			subSector_theirs_scholarity(subSector) { return subSector.scholarity===null ? this._loc("skills.sectors.scholarity.none") : subSector.scholarity; },
			skillRate_getLoc(tag)                  { return this._regLoc(`skillRate.Brand.items.${tag}`);                                                     },
			skillRate_getColor(tag)                { return this._getColor(this.matchDetails.skills.rate[tag]);                                               },
			affinityMorality_getColor()            { return this._getColor(this.matchDetails.affinity.moralitySort);                                          },
			affinityBi_getLoc(tag)                 { return this._regLoc(`affinityBiPolar.items.${tag}.Brand.desc_left`);                                     },
			affinityBi_getColor(tag)               { return this._getColor(this.matchDetails.affinity.bi[tag]);                                               },
			profileRate_getLoc(tag)                { return this._regLoc(`profileRate.Brand.items.${tag}`);                                                   },
			profileRate_getColor(tag)              { return this._getColor(this.matchDetails.profileRate[tag]);                                               },
			timeSchedule_getLoc(tag)               { return this._regLoc(`timeSchedule.daysOfWeek.${tag}`);                                                   },
			timeSchedule_getVal(tag)
			{
				const weekDayInfo = this.matchDetails.timeSchedule[tag];
				const vals        = [];
				
				if (weekDayInfo)
				{
					for (const loop_timeOfDayTag of weekDayInfo) { vals.push(this._loc(`timeSchedule.${loop_timeOfDayTag}`)); }
				}
				
				return vals.length>0 ? vals.join(", ") : this._loc("timeSchedule.notAvailable");
			},
			yearn_getLoc(tag)        { return this._regLoc(`yearn.Brand.items.${tag}`);                                                            },
			yearn_getCheckbox(tag)   { return this.matchDetails.yearn[tag] ? "✔" : "✖";                                                           },
			yearn_getColor(tag)      { return this.matchDetails.yearn[tag] ? "success--text" : "error--text";                                      },
			budget_getColor(tag)     { return this._getColor(this.matchDetails.budget[tag]);                                                       },
			budget_getBankrupt_val() { return this.matchDetails.budget.bankrupt ? this._loc("budget.bankrupt.y") : this._loc("budget.bankrupt.n"); },
			on_postpone_click()
			{
				const successLocMsg = this._loc("actions.postpone.success");
				
				this._callAPI(/*confirmLocMsg*/null, "postpone", /*expectsNoResponse*/false, successLocMsg, /*annoyLocMsg*/null, (response)=>
				{
					this.matchDetails.summary.expirationDate = response.data.newDate;
					this.matchDetails.summary.canPostpone    = false;
				}, ()=>{}, ()=>{});
			},
			on_buy_click()
			{
				const creditTokenCount = this.$bREST.creditTokenCount;
				
				const confirmLocMsg = this._loc("actions.buy.confirm", {tokenCount:this.match_tokenCost});
				const successLocMsg = this._loc("actions.buy.success", {tokenCount:this.match_tokenCost});
				const poorLocMsg    = this._loc("actions.buy.poor",    {tokenCount:this.match_tokenCost,current:creditTokenCount});
				
				if (creditTokenCount < this.match_tokenCost)
				{
					this.$bREST.notifs_tmp({msg:poorLocMsg, color:"error"});
					return;
				}
				
				this._callAPI(confirmLocMsg, "buy", /*expectsNoResponse*/false, successLocMsg, /*annoyLocMsg*/null, (response)=>
				{
					this._setFormFromCallResponse(response);
					
					this.matchDetails.summary.brand_status = "publicUnlocked_privateExpired";
					this.$bREST.creditTokenCount -= this.match_tokenCost;
				}, ()=>{}, ()=>{});
			},
			on_currentAction_change()
			{
				const willDo = this.currentAction;
				
				const confirmLocMsg = this._loc(`actions.${willDo}.confirm_${this.privatePublicTag}`);
				const successLocMsg = this._loc(`actions.${willDo}.success_${this.privatePublicTag}`);
				const annoyLocMsg   = this._loc(`actions.${willDo}.annoy_${this.privatePublicTag}`);
				
				const onErrorOrCancel = () => { this.currentAction=this.oldAction; };
				
				this._callAPI(confirmLocMsg, willDo, /*expectsNoResponse*/true, successLocMsg, annoyLocMsg, (response)=>
				{
					this.oldAction = this.currentAction;
					
					switch (willDo)
					{
						case "signed":
							this.matchDetails.summary.brand_status   = this.matchDetails.summary.isPrivateLeadToSiblings ? "privateSigned" : "publicSigned";
							this.matchDetails.summary.expirationDate = null;
						break;
						case "rejected":
							this.matchDetails.summary.brand_status            = "publicRejected_privateRejected";
							this.matchDetails.summary.isPrivateLeadToSiblings = false;
							this.matchDetails.summary.expirationDate          = null;
						break;
						case "ongoing":
							this.matchDetails.summary.brand_status = this.matchDetails.summary.isPrivateLeadToSiblings ? "privateWExpirationSeen" : "publicUnlocked_privateExpired";
						break;
						default:
							this.$bREST.throwEx(`Unexpected action "${willDo}"`);
						break;
					}
				}, onErrorOrCancel, onErrorOrCancel);
			},
			async _callAPI(confirmLocMsgOrNULL, action, expectsNoResponse, successLocMsg, annoyLocMsgOrNULL, onSuccess, onError, onCancel)
			{
				const brand = this.$bREST.routes_current_qsa.b;
				const lead  = this.$bREST.routes_current_qsa.l;
				const hash  = this.$bREST.routes_current_qsa.h;
				
				try
				{
					if (confirmLocMsgOrNULL)
					{
						this.confirmPrompt.body = confirmLocMsgOrNULL;
						
						const selectedActionOrNull = await this.confirmPrompt.show();
						if (selectedActionOrNull!==CONFIRM_PROMPT_ACTIONS_PROCEED) { onCancel(); return; }
					}
					
					const request = new this.$bREST.PUT("/profile/opportunities/brand/{brand}/heatmap/{lead}", {brand,lead});
						request.data_set("action", action);
						request.qsa_add("h",hash);
						if (expectsNoResponse) { request.expectsContentType_empty(); }
					const response = await this.$bREST.call(request);
					
					this.$bREST.notifs_tmp({msg:successLocMsg, color:"success"});
					
					this.$bREST.utils.console_todo([
						`Show annoyLocMsgOrNULL msg with a promp, however since success msg is a toaster, it's not  so intrusive so maybe we don't need that question...`
					]);
					
					onSuccess(response);
				}
				catch (e)
				{
					this.$bREST.utils.console_error(e);
					this.$bREST.notifs_tmp({msg:this._loc("toaster.error"), color:"error"});
					
					onError();
				}
			},
				_setFormFromCallResponse(response)
				{
					this.matchDetails = response.data;
					
					switch (this.brand_status)
					{
						case "preparing_hidden":               this.currentAction="ongoing";  break;
						case "privateWExpirationNew":          this.currentAction="ongoing";  break;
						case "privateWExpirationSeen":         this.currentAction="ongoing";  break;
						case "privateSigned":                  this.currentAction="signed";   break;
						case "publicAnonymousNew":             this.currentAction="ongoing";  break;
						case "publicAnonymousSeen":            this.currentAction="ongoing";  break;
						case "publicUnlocked_privateExpired":  this.currentAction="ongoing";  break;
						case "publicSigned":                   this.currentAction="signed";   break;
						case "publicRejected_privateRejected": this.currentAction="rejected"; break;
						default: this.$bREST.throwEx(`Unexpected status "${this.brand_status}"`);
					}
					this.oldAction = this.currentAction;
				},
		},
	};
	
</script>

<style scoped>
	
	td.success, th.success,
	td.warning, th.warning,
	td.error,   th.error {
		border-color:rgba(0,0,0,0.12) !important;
	}
	
</style>