
import B_REST_Utils       from "../B_REST_Utils.js";
import B_REST_Model       from "./B_REST_Model.js";
import B_REST_ModelFields from "./B_REST_ModelFields.js";



export default class B_REST_Model_ValidationError
{
	target = null; //A B_REST_ModelFields.x or B_REST_Model instance
	msg    = null;
	
	
	constructor(target, msg)
	{
		if (!(target instanceof B_REST_ModelFields.base || target instanceof B_REST_Model)) { B_REST_Utils.throwEx(`Expected a B_REST_ModelFields.x or B_REST_Model instance`); }
		
		this.target = target;
		this.msg    = msg;
	}
};
