
import axios from "axios";

import B_REST_Utils      from "../B_REST_Utils.js";
import B_REST_App_base   from "../app/B_REST_App_base.js";
import B_REST_DOMFilePtr from "../files/B_REST_DOMFilePtr.js";
import B_REST_CallStats  from "./B_REST_CallStats.js";
import B_REST_Response   from "./B_REST_Response.js";
import {B_REST_Request_base, B_REST_Request_GET,B_REST_Request_GET_File,B_REST_Request_POST,B_REST_Request_POST_Multipart,B_REST_Request_PUT,B_REST_Request_PUT_Multipart,B_REST_Request_PATCH,B_REST_Request_PATCH_Multipart,B_REST_Request_DELETE} from "./B_REST_Request.js";

/*
**************************************
******** CORS RELATED - START ********
**************************************
	As long as we put this in PHP:
		header("Access-Control-Allow-Origin: *");
		header("Access-Control-Allow-Headers: Authorization, Cache-Control, Accept, Content-Type, Accept-Language");
		header("Access-Control-Expose-Headers: *");
		+ When PHP receives a preflight "OPTIONS" method, add this:
			header("Access-Control-Allow-Methods: GET,POST,PUT,PATCH,DELETE,OPTIONS");
	... we shouldn't need to use these in vue.config.js:
			module.exports = {
				devServer: {
					disableHostCheck: true,
					proxy: "https://flagfranchise-dev.keybook.com", -> or actually, should put something in the ENV file
				},
			};
************************************
******** CORS RELATED - END ********
************************************

Constructor options:
	{
		baseURL:                        Ex "https://flag-dev.keybook.com/api". Don't put trailing "/" at the end
		wifi_onChange_handler:          Optional func as (has). When we do API calls (and optionally in the wifi check interval below), a check is made to see if we have wifi. This gets triggered when it -changes-
		wifi_checkInterval_secs:        If set, nb of seconds at which we want to update whether or not we have an internet connection. Result is in B_REST_API.wifi_has
		rejectUnsuccessfulCalls:(true)  Whether call() should resolve or reject, when !B_REST_Response.isSuccess
		log_handler:                    Optional func as (msg, isError, details=null), called in various places
		tweakRequest_async_handler:     Optional async func as (request), ex to add more data / QSA / headers to the request. Call won't get fired until the handler returns
		mockCalls_async_handler:        Optional async func as (request), receiving a B_REST_Request_base derived instance, that must resolve to a B_REST_Response, or null to skip. NOTE: To simulate no wifi though, use browser's network tab
		mockCalls_enabled:(false)       Whether or not the above is enabled
		afterCall_general_handler:      Optional func as (response), called at the end of call(), no matter the B_REST_Response instance is successful or not
		tweakResponse_async_handler:    Optional async func as (response), called right before call() either resolves / rejects, to either tweak or do other async things right at that time. Must complete in order to finish the call
		timeout_msecs:                  Optional, and should prolly not be set
	}

Order of events for calls:
	0) check if we should wait when we have too many parallel calls
	1) wifi_onChange_handler
	2) tweakRequest_async_handler
	3) mockCalls_async_handler
	4) tweakResponse_async_handler
	5) resolve / reject
	6) afterCall_general_handler
	7) updating ongoing parallel calls
	-> log_handler is also called at lots of places

Callback ex for async mockCalls_async_handler(response):
	{
		let mockPromise = null;
		
		switch (request.path_raw)
		{
			case "/brands/{brand}/compats/{lead}/action":
				if (request.path_vars.brand===123 && request.path_vars.lead===456)
				{
					mockPromise = B_REST_Response.from_mockJsonFile( import("test.json") );
				}
				//Else ignore and let handle normally
			break;
			case "/user/login":  mockPromise = B_REST_Response.from_err(401,"Bad access token bro"); break;
			case "/testBrands/": mockPromise = B_REST_Response.from_mockJsonFile( import("test-brands-for-data-table.json") ); break;
		}
		
		if (mockPromise)
		{
			await B_REST_Utils.sleep(200);
			
			return await mockPromise;
		}
		
		return null;
	}
*/



export default class B_REST_API
{
	static get CALL_STATS_FALLBACK_SHORT_NAME() { return "_fallback_"; }
	
	static get DEFAULT_MAX_PARALLEL_CONNECTIONS() { return 4; }
	
	static get HEADERS_B_REST_IS()                      { return "x-b-rest-is";              }
	static get HEADERS_B_REST_TIMEZONE()                { return "x-b-rest-tz";              }
	static get HEADERS_B_REST_LAST_SUCCESSFUL_CALL_DT() { return "x-b-rest-last-success-dt"; }
		//IMPORTANT: If we add stuff here, we must also add them in server's HttpUtils::_B_REST_CORS_HEADERS
	
	
	//Config stuff
		_baseURL                     = null;  //Ex "https://flag-dev.keybook.com/api". Don't put trailing "/" at the end
		_wifi_has                    = null;  //Whether or not we have wifi, as from the last time _wifi_check() was called
		_wifi_worker_pid             = null;  //Ptr to a setInterval
		_wifi_onChange_handler       = null;  //Optional func as (has). When we do API calls (and optionally in the wifi check interval below), a check is made to see if we have wifi. This gets triggered when it -changes-
		_callStats_byShortName       = {};    //Map of request's shortName => instance of B_REST_CallStats, for calls where B_REST_Request::shortName is set
		_callStats_summary           = null;  //Instance of B_REST_CallStats
		_rejectUnsuccessfulCalls     = true;  //Whether call() should resolve or reject, when !B_REST_Response.isSuccess
		_log_handler                 = null;  //Optional func as (msg, isError, details=null), called in various places
		_tweakRequest_async_handler  = null;  //Optional async func as (request), ex to add more data / QSA / headers to the request. Call won't get fired until the handler returns
		_mockCalls_async_handler     = null;  //Optional async func as (request), receiving a B_REST_Request_base derived instance, that must resolve to a B_REST_Response, or null to skip. NOTE: To simulate no wifi though, use browser's network tab
		_mockCalls_enabled           = false; //Whether or not the above is enabled
		_tweakResponse_async_handler = null;  //Optional async func as (response), called right before call() either resolves / rejects, to either tweak or do other async things right at that time. Must complete in order to finish the call
		_afterCall_general_handler   = null;  //Optional func as (response), called at the end of call(), no matter the B_REST_Response instance is successful or not
		_timeout_msecs               = null;  //Optional, and should prolly not be set
		_maxParallelConnections      = null;  //NULL = no limit, otherwise queue when we've fired more than X parallel calls to Axios that haven't succeeded/failed yet. Check "PARALLEL CONNECTIONS RELATED" section
	//Client stuff
		_accessToken_public  = null;
		_accessToken_private = null;  //Usually, only serves when refreshing access tokens (if implemented)
		_lang                = null;
		_timeZone            = null;
	//Logic stuff
		_parallelConnections_ongoingCount   = 0;    //Nb of those that we did let go and that they haven't completed yet
		_parallelConnections_queuedRequests = [];   //Arr of {request, payloadSize, weight, start(Promise resolver)}
		_lastSuccessfulCall_dt              = null; //Date instance
	
	
	constructor(options={})
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			baseURL:                     {accept:[String],   required:true},
			wifi_onChange_handler:       {accept:[Function], default:null},
			wifi_checkInterval_secs:     {accept:[Number],   default:B_REST_App_base.WIFI_CHECK_INTERVAL_SECS},
			rejectUnsuccessfulCalls:     {accept:[Boolean],  default:true},
			log_handler:                 {accept:[Function], default:null},
			tweakRequest_async_handler:  {accept:[Function], default:null},
			mockCalls_async_handler:     {accept:[Function], default:null},
			mockCalls_enabled:           {accept:[Boolean],  default:false},
			tweakResponse_async_handler: {accept:[Function], default:null},
			afterCall_general_handler:   {accept:[Function], default:null},
			timeout_msecs:               {accept:[Number],   default:null},
			maxParallelConnections:      {accept:[Number],   default:B_REST_API.DEFAULT_MAX_PARALLEL_CONNECTIONS},
		}, "API");
		
		B_REST_Utils.assert_formData_support();
		if (!options.baseURL)                                              { B_REST_Utils.throwEx("baseURL empty. Check class docs for constructor options"); }
		if (options.mockCalls_enabled && !options.mockCalls_async_handler) { B_REST_Utils.throwEx("Needs mockCalls_async_handler when mockCalls_enabled");    }
		
		this._baseURL                     = options.baseURL;
		this._wifi_onChange_handler       = options.wifi_onChange_handler;
		this._wifi_checkInterval_secs     = options.wifi_checkInterval_secs;
		this._rejectUnsuccessfulCalls     = options.rejectUnsuccessfulCalls;
		this._log_handler                 = options.log_handler;
		this._tweakRequest_async_handler  = options.tweakRequest_async_handler;
		this._mockCalls_async_handler     = options.mockCalls_async_handler;
		this._mockCalls_enabled           = options.mockCalls_enabled;
		this._tweakResponse_async_handler = options.tweakResponse_async_handler;
		this._afterCall_general_handler   = options.afterCall_general_handler;
		this._timeout_msecs               = options.timeout_msecs;
		this._maxParallelConnections      = options.maxParallelConnections;
		
		this._callStats_summary     = new B_REST_CallStats();
		this._callStats_byShortName = {};
		if (options.wifi_checkInterval_secs) { this._wifi_worker_start(options.wifi_checkInterval_secs); }
	}
		destroy()
		{
			if (this._wifi_worker_pid) { this._wifi_worker_stop(); }
		}
	
	
	
	//WIFI STATUS
		get wifi_has() { return this._wifi_has; }
			_wifi_check()
			{
				if (this._wifi_has!==navigator.onLine)
				{
					this._wifi_has = navigator.onLine;
					
					this._fireHandler("_wifi_onChange_handler", this._wifi_has);
				}
			}
			_wifi_worker_start(interval_secs)
			{
				this._wifi_check();
				
				this._wifi_worker_pid = setInterval(() => this._wifi_check(), interval_secs*1000);
			}
			_wifi_worker_stop()
			{
				clearInterval(this._wifi_worker_pid);
				this._wifi_worker_pid = null;
			}
	
	
	
	//LOGS
		_log(msg, isError, details=null)
		{
			this._fireHandler("_log_handler", msg,isError,details);
		}
	
	
	
	//CALL STATS
		get callStats_summary_pending_has() { return this._callStats_summary.pending_has; }
		get callStats_summary_debug()       { return this._callStats_summary.debug;       }
		get callStats_summary_debugAll()    { console.table(this._callStats_byShortName); }
		callStats_byShortName_pending_has(shortName) { return this._callStats_byShortName[shortName]?.pending_has ?? false; }
	
	
	
	//CLIENT STUFF
		accessToken_set(accessToken_public, accessToken_private)
		{
			this._accessToken_public  = accessToken_public;
			this._accessToken_private = accessToken_private;
		}
		accessToken_clear()
		{
			this._accessToken_public  = null;
			this._accessToken_private = null;
		}
		get accessToken_public()  { return this._accessToken_public;   }
		get accessToken_isValid() { return !!this._accessToken_public; }
		get lang()                { return this._lang;                 }
		set lang(val)             { this._lang=val;                    }
		get timeZone()            { return this._timeZone;             }
		set timeZone(val)         { this._timeZone=val;                }
	
	
	
	//OTHER GETTERS
		get baseURL()                 { return this._baseURL;                 }
		get rejectUnsuccessfulCalls() { return this._rejectUnsuccessfulCalls; }
		get mockCalls_enabled()       { return this._mockCalls_enabled;       }
		get timeout_msecs()           { return this._timeout_msecs;           }
		get maxParallelConnections()  { return this._maxParallelConnections;  }
	
	
	
	//x_HANDLER RELATED
		/*
		Since user can define custom handler for several things, if they throw exceptions, then the flow of B_REST_API will completely get broken, so this isolates the errs
		Rets NULL if the handler isn't defined
		Usage ex:
			this._fireHandler("_wifi_onChange_handler",       this._wifi_has)
			this._fireHandler("_log_handler",                 msg,isError,details)
			this._fireHandler("_tweakRequest_async_handler",  request)
			this._fireHandler("_mockCalls_async_handler",     request)
			this._fireHandler("_tweakResponse_async_handler", response)
			this._fireHandler("_afterCall_general_handler",   response)
		Note for mockCalls_async_handler:
			It's supposed to be async and could break, so if this happens, we'll just return NULL and it won't screw up the flow
		WARNING: Eats errors and converts them into B_REST_Utils::console_error() logs, so code execution continues anyways
		*/
		async _fireHandler()
		{
			const handlerVarName = arguments[0];
			
			//For logs, make sure we always know about errs, even if the user doesn't define the handle. NOTE: Params aren't shifted yet
			if (handlerVarName==="_log_handler" && arguments[2]) { B_REST_Utils.console_error(`Logging B_REST_API err: "${arguments[1]}"`,arguments[3]); } //WARNING: Don't switch to throwEx()
			
			if (!this[handlerVarName]) { return null; }
			
			try
			{
				const remainingArgs = [...arguments];
				remainingArgs.shift();
				
				return await this[handlerVarName].apply(this, remainingArgs);
			}
			catch (e)
			{
				B_REST_Utils.console_error(`B_REST_API: User defined handler for ${handlerVarName} threw an exception:`, e); //WARNING: Don't switch to throwEx()
			}
			
			return null;
		}
	
	
	
	//CALLS
		/*
		The following are just shortcuts, to help reducing need of importing stuff
		NOTE:
			Can also use call_getObjectUrl() to get imgs and such
		*/
		get GET()             { return B_REST_Request_GET;             }
		get GET_File()        { return B_REST_Request_GET_File;        }
		get POST()            { return B_REST_Request_POST;            }
		get POST_Multipart()  { return B_REST_Request_POST_Multipart;  }
		get PUT()             { return B_REST_Request_PUT;             }
		get PUT_Multipart()   { return B_REST_Request_PUT_Multipart;   }
		get PATCH()           { return B_REST_Request_PATCH;           }
		get PATCH_Multipart() { return B_REST_Request_PATCH_Multipart; }
		get DELETE()          { return B_REST_Request_DELETE;          }
		/*
		Always yield an instance of B_REST_Response. Rets via resolver or rejecter, depending on its B_REST_Response::isSuccess prop
		Expects a derived instance of B_REST_Request_base
		requestOptions:
			{
				uploadProgressCallback:   Callback as (totalBytes,transferredBytes,percent) that is only fired if the call actually starts (without premature errs)
				downloadProgressCallback: Callback as (totalBytes,transferredBytes,percent) that is only fired when the server starts sending a positive or negative response
				cache:
				{
					key: Ex "cachedResponses-bootCall" or "client-123"
				}
			}
		About caching (successful responses):
			If we pass a cache obj, then will check in local storage for an encoded JSON call
			For now, has no notion of expiration, but we could later add more props to the cache obj, ex {key,expiration},
				and prolly store the expiration flag inside the cacheObj (not in another key)
			Not caching calls that yield an err
			If we want to cache stuff that have the same path but diff method, then we should think of a scheme like "client-123-get" and "client-123-post" or something
		*/
		async call(request, requestOptions=null)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
			
			const axiosErrHelpMsg = `\n${"*".repeat(100)}\nMost likely a syntax err in PHP; try opening the API call in a new tab\nIf a CORS prob, first thing, put in PHP:\n\theader('Access-Control-Allow-Origin: *');\n\theader('Access-Control-Allow-Headers: Authorization, Cache-Control, Accept, Content-Type, Accept-Language');\n\theader("Access-Control-Expose-Headers: *");\n\t + make sure the preflight "OPTIONS" method is supported, with "header('Access-Control-Allow-Methods: GET,POST,PUT,PATCH,DELETE,OPTIONS');".\n\tThen, if it persists, could have to play with vue.config.js's devServer.disableHostCheck or devServer.proxy props, but it shouldn't be necessary.`;
			const log_callInfo    = request.url_debug;
			const cacheKey        = requestOptions?.cache?.key ?? null;
			
			return new Promise(async(resolve, reject) => //Putting this async will cause ESLint "no-async-promise-executor" warning, so disable it in eslintrc.js (if included in proj)
			{
				//Setup a main branch to call when we've got (or made up) our B_REST_Response instance
				const finalize = async(response) =>
				{
					response.request = request;
					
					//NOTE: Check "order of events for calls" note at top of file
					
					//NOTE: Might change a successful response to err, ex if it contained debug dump or expected content type doesn't match
					response.finalize();
					
					//Check if we wanted to cache the call
					if (cacheKey && response.isSuccess)
					{
						const cacheObj  = response.cache_to();
						const cacheJSON = B_REST_Utils.json_encode(cacheObj);
						
						B_REST_Utils.localStorage_set(cacheKey, cacheJSON);
					}
					
					//Update call stats + do some general logs
					{
						if (response.isSuccess)
						{
							this._lastSuccessfulCall_dt = B_REST_Utils.dt_now();
							
							this._log(`${log_callInfo} - Success`, false, response);
							this._call_updateStats(request, "success");
						}
						else
						{
							this._log(`${log_callInfo} - Got error: ${response.errorMsg}`, true, response);
							this._call_updateStats(request, "error");
						}
					}
					
					//4) Check to tweak response, or do something else w it
					await this._fireHandler("_tweakResponse_async_handler", response); //NOTE: Waits for handler to complete. Even if it throws, it won't break the "await" here
					
					//5) Resolve or reject, depending on isSuccess + if we want to reject or resolve errs
					if (response.isSuccess || !this._rejectUnsuccessfulCalls) { resolve(response); } else { reject(response); }
					
					//6) indicate it's done. Could intercept errs here like if errorType is a bad login or access token expired...
					this._fireHandler("_afterCall_general_handler", response); //NOTE: Could throw but it won't affect flow
					
					/*
					7) If we had parallel connections limit, indicate we've got one more request done, in case we wanted to start another one.
					NOTE: Resolving / rejecting doesn't call what was awaiting for it (.then()) right away, so this will be ran right after the resolving / rejecting above
					*/
					if (this._maxParallelConnections) { this._maxParallelConnections_doneRequest(); }
					
					if (response.debug_isDump) { response.debug_replaceAppHTML(); }
				};
				
				try
				{
					if (!(request instanceof B_REST_Request_base))                        { B_REST_Utils.throwEx("Expected derived instance of B_REST_Request_base"); }
					if (requestOptions!==null && !B_REST_Utils.object_is(requestOptions)) { B_REST_Utils.throwEx("requestOptions must be an obj");                    }
					
					this._log(`${log_callInfo} - Starting`, false, request);
					
					//Update stats to say something is going on
					this._call_updateStats(request, "initiated");
					
					/*
					If we want to limit the nb of Axios calls at once (to prevent having too many client connections on our server), wait until it's ok to continue
					We give details about the current request, in case we'd want to prioritize their order based on its post size or known script duration
					*/
					if (this._maxParallelConnections) { await this._maxParallelConnections_scheduleRequest(request); }
					
					//First thing, check if we need an access token and don't have
					if (request.needsAccessToken===true && !this.accessToken_isValid) //NOTE: needsAccessToken is either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
					{
						finalize( B_REST_Response.from_err(401,"Valid access token required. If the request doesn't need one, consider setting request.needsAccessToken = false") ); return; //IMPORTANT: Don't remove the return
					}
					
					//1) Then, check if we have wifi (no matter we want to mock calls or not. NOTE: To simulate no wifi though, use browser's network tab
					this._wifi_check();
					if (!this._wifi_has)
					{
						finalize( B_REST_Response.from_err(400,"No wifi") ); return; //IMPORTANT: Don't remove the return
					}
					
					//2) Check to pimp the request
					await this._fireHandler("_tweakRequest_async_handler", request);
					
					//3) If we have mocks enabled, check if the handler -wants- to return an instance of B_REST_Response. Otherwise, move on
					if (this._mockCalls_enabled)
					{
						const response_mocked = await this._fireHandler("_mockCalls_async_handler", request); //if it threw, will actually return NULL
						
						if (response_mocked)
						{
							B_REST_Utils.console_warn(`B_REST_API: Is mocking call ${log_callInfo}`);
							finalize(response_mocked);
							return; //IMPORTANT: Don't remove the return
						}
					}
					
					//If we must check for a cached version of the call's response
					if (cacheKey && B_REST_Utils.localStorage_has(cacheKey))
					{
						const cacheJSON       = B_REST_Utils.localStorage_get(cacheKey);
						const cacheObj        = B_REST_Utils.json_decode(cacheJSON);
						const response_cached = B_REST_Response.from_cache(cacheObj);
						
						B_REST_Utils.console_info(`B_REST_API: Is using cached call ${log_callInfo}`);
						finalize(response_cached);
						return; //IMPORTANT: Don't remove the return
					}
					
					//Then we have to do it for real
					{
						/*
						NOTE:
							-Axios prefers headers in lower case + returns them so
							-If we want to add new headers, we have to change the "Access-Control-Allow-Headers" header in server to list them too
						WARNING:
							If we want to add more headers, we must specify them in server's HttpUtils::outputCORSHeaders()
						*/
						const requestHeaders = {
							accept:          request.expectsContentType,
							"cache-control": "no-cache",
						};
						if (this._lang) { requestHeaders["accept-language"]=this._lang; }
						
						//To prove we're bREST
						requestHeaders[B_REST_API.HEADERS_B_REST_IS] = 1;
						
						//Could be the device's time zone, or later changed by the user
						if (this._timeZone) { requestHeaders[B_REST_API.HEADERS_B_REST_TIMEZONE]=this._timeZone; }
						
						//Req to help know if server should send back shared lists that might have been updated since the last call
						requestHeaders[B_REST_API.HEADERS_B_REST_LAST_SUCCESSFUL_CALL_DT] = this._lastSuccessfulCall_dt ? B_REST_Utils.dt_u(this._lastSuccessfulCall_dt) : "";
						
						if (request.data_has) { requestHeaders["content-type"] = request.data_contentType; }
						
						//Pass the access token, even when request.needsAccessToken===false, so for free data, we still know which user wanted it for logs
						if (this._accessToken_public && request.needsAccessToken!==B_REST_Request_base.NEEDS_ACCESS_TOKEN_DONT) //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
						{
							requestHeaders.authorization = `Bearer ${this._accessToken_public}`;
						}
						
						const axiosConfig = {
							method: request.method.toLowerCase(),
							url: `${this._baseURL}${request.url_parsed}`,
							data: request.data,
							headers: requestHeaders,
							timeout: this._timeout_msecs,
							responseType: request.fetchAsBlob ? "blob" : "text", //WARNING: "text" correctly handles text/html & application/json (auto parses too). However if we set it to "json", text/html will return NULL
							validateStatus(status)
							{
								return true; //NOTE: Always return true, so no matter what the server returns we don't reject, leaving rejections only for Axios core errs. https://www.npmjs.com/package/axios#handling-errors
							},
						};
						
						if (requestOptions)
						{
							if (requestOptions.uploadProgressCallback)
							{
								axiosConfig.onUploadProgress = function(progressEvent) //ProgressEvent {timetamp, eventPhase:0, lengthComputable:bool, loaded:int,total:int}
								{
									const percent = progressEvent.total===0 ? 0 : Math.round(progressEvent.loaded*100/progressEvent.total);
									try
									{
										requestOptions.uploadProgressCallback(progressEvent.total, progressEvent.loaded, percent);
									}
									catch (e) { B_REST_Utils.throwEx(`uploadProgressCallback hook failed, for ${log_callInfo}`); }
								};
							}
							
							if (requestOptions.downloadProgressCallback)
							{
								axiosConfig.onDownloadProgress = function(progressEvent) //ProgressEvent {timetamp, eventPhase:0, lengthComputable:bool, loaded:int,total:int}
								{
									const percent = progressEvent.total===0 ? 0 : Math.round(progressEvent.loaded*100/progressEvent.total);
									try
									{
										requestOptions.downloadProgressCallback(progressEvent.total, progressEvent.loaded, percent);
									}
									catch (e) { B_REST_Utils.throwEx(`downloadProgressCallback hook failed, for ${log_callInfo}`); }
								};
							}
						}
						
						axios(axiosConfig).then(
							axios_result =>
							{
								const response = B_REST_Response.from_axios_result(axios_result);
								if (!response.isSuccess)
								{
									this._log("We'll maybe get an error in the console, in xhr.js, ex saying we got a 400 bad request. There's no way to make that err disappear, because it comes from a native JS err from within Axios. Just ignore it");
								}
								
							    finalize(response);
							},
							axios_error =>
							{
								//https://www.npmjs.com/package/axios#handling-errors
								const isErr        = axios_error instanceof Error;
								const axiosErrCode = isErr ? 400                 : (axios_error?.response?.status ?? 400);
								const axiosErrMsg  = isErr ? axios_error.message : (axios_error?.response?.data   ?? "Unknown");
								
								/*
								WARNING:
									There's no way to catch msg like "Access to XMLHttpRequest at ... has been blocked by CORS policy"
									It's not an Axios prob
									https://github.com/axios/axios/issues/838
								*/
							    finalize( B_REST_Response.from_err(axiosErrCode,`Got axios error: ${axiosErrMsg}.${axiosErrHelpMsg}`) );
							}
						);
					}
				}
				catch (e)
				{
					finalize( B_REST_Response.from_err(400,`Fell in B_REST_API::call()'s try/catch: ${e}.${axiosErrHelpMsg}`) );
				}
			});
		}
			//To either update the "initiated", "success" or "error" stats
			_call_updateStats(request, which)
			{
				this._callStats_summary[which]++;
				
				const shortName = request.shortName || B_REST_API.CALL_STATS_FALLBACK_SHORT_NAME;
				if (!this._callStats_byShortName[shortName]) { this._callStats_byShortName[shortName] = new B_REST_CallStats(); }
				this._callStats_byShortName[shortName][which]++;
			}
		/*
		Performs a call (usually a GET) and converts the response into an object URL (something like "blob:null/as8df098as9d8f0", usually for <img src> or for downloads)
		Resolves as {objectURL, response}
		Rejects as {errorMsg, response:null}, unless we didn't pass a request
		Usage ex:
			const request = new B_REST_Request_GET_File("/brands/123/logo?h=asdg7g9");
			request.expectsContentType_image();
			request.needsAccessToken = false;
			const { objectURL } = await call_getObjectUrl(request);
			img.src    = objectURL;
			img.onload = () => B_REST_Utils.files_objectURL_revoke(objectURL);
		WARNING:
			After the objectURL is used, we must do B_REST_Utils.files_objectURL_revoke(objectURL) to prevent bloating memory
		*/
		async call_getObjectUrl(request)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
			
			return new Promise(async(s,f) => //Putting this async will cause ESLint "no-async-promise-executor" warning, so disable it in eslintrc.js (if included in proj)
			{
				let response = null; //Instance of B_REST_Response
				let errorMsg = null;
				
				try
				{
					response = await this.call(request);
					if (response.isSuccess)
					{
						const objectURL = B_REST_Utils.files_objectURL_create(response.data, response.data_contentType);
						s({objectURL, response});
						return;
					}
					
					errorMsg = `Response not successful`;
				}
				catch (e) { errorMsg=`Got exception while doing call: ${e}`; }
				
				f({errorMsg, response});
			});
		}
		/*
		Call wrapper to download a resource at a given apiUrl
		If the call is successful, then a prompt will allow the user to save the file, and then will resolve with the B_REST_Response as {baseNameWExt, response}
		If the call fails, or for some reason the browser doesn't allow converting the file to a <a download>, then rejects as {errorMsg, response}
		Usage ex:
			1)
				const request = new B_REST_Request_GET_File("/brands/123/logo?h=asdg7g9");
				request.needsAccessToken = false;
				const { response } = call_download(request);
			2)
				const request = new B_REST_Request_GET_File("/brands/123/docs?h=89cdb0caf3b4c0f51b1330ddd0c4961e");
				request.needsAccessToken = true;
				const { response } = call_download(request, "documents.zip");
		baseNameWExt:
			Optional file name to use, if we want to override what's (maybe) returned by the call() in headers
			Depends on which of backend's methods were used:
				HttpUtils::output_file_fromPath()
				HttpUtils::output_file_zippedDir()
				HttpUtils::output_file_fromContents()
			... but they all should yield any of:
					Content-Disposition: attachment; filename="..."
					Content-Disposition: inline; filename="..."
		WARNING: May cause infinite loop if the fake <a> click() bubbles back to the elem that started call_download()
		*/
		async call_download(request, baseNameWExt=null, domContainer=null)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
			
			return new Promise((s,f) =>
			{
				this.call_getObjectUrl(request).then(async({objectURL,response}) =>
				{
					try
					{
						//If we've got no file name, try to figure out based on "Content-Disposition". Note that frontend headers are always changed to lowercase by Axios
						if (!baseNameWExt)
						{
							if (response.headers["content-disposition"])
							{
								const tmpMatch = response.headers["content-disposition"].match(/; ?filename="(.+)"$/i);
								if (tmpMatch) { baseNameWExt=tmpMatch[1]; }
							}
							
							if (!baseNameWExt) { baseNameWExt="data"; }
						}
						
						const tmpDOMFilePtr = new B_REST_DOMFilePtr(objectURL);
							await tmpDOMFilePtr.download(baseNameWExt, domContainer); //Might throw
						tmpDOMFilePtr.objectURL_revoke();
						
						s({baseNameWExt, response});
					}
					catch (e)
					{
						f({errorMsg:`Got exception while downloading: ${e}`, response});
					}
				}).catch(({errorMsg,response}) =>
				{
					f({errorMsg, response});
				});
			});
		}
	
	
	
	//PARALLEL CONNECTIONS RELATED
		/*
		The following is only used when _maxParallelConnections is > 0, and especially relevant with file uploads:
			We can stack multiple files in the same POST, or send them in diff calls
			If we send them all in the same POST:
				+Uses only 1 "client connection"
				+Useful if we want everything to fail when any file doesn't match XYZ criteria
				-Takes more mem on the server
				-Might lead to error 500 for timeouts, max post size, upload time...
				-If one file is invalid, all files fail
				-If we had a 1kb file and a 1gb file, it will appear as if the 1kb file takes forever to upload
			If we send them separately:
				-Uses multiple "client connections", so other users might hang while doing actions
				+OK if we don't care whether one or another file fails
				+Takes less mem on server
				+Less chances of getting timeouts
				+Small files can finish first and not get slowed down by huge files
		It's also useful with multiple GETs for diff data sources, and if we expect calls to be ran in a given order, then we should just do await ... await ... await ... await
		We only add to _parallelConnections_queuedRequests WHEN we already reached the max nb of ongoing calls, and we sort them by weight, so ex if we have small and big files
			queued to call(), then all the small ones should go first so we end up with a smaller nb of huge files at the end
		*/
		async _maxParallelConnections_scheduleRequest(request)
		{
			B_REST_Utils.console_todo([
				`Could implement weight to sort connections, like adding "hints" in B_REST_Request for avgScriptDuration, priority, data size (don't use JSON.stringify, but Blob.size if possible)`,
			]);
			
			return new Promise(start =>
			{
				//Can we do it now ?
				if (this._parallelConnections_ongoingCount < this._maxParallelConnections)
				{
					this._parallelConnections_ongoingCount++;
					start();
				}
				//Else store it for later, placing by priority with anything that's already waiting
				else
				{
					const payloadSize    = request.data_has ? request.data_calculateSize() : 0;
					const weight         = payloadSize;
					const connectionInfo = {request, payloadSize, weight, start};
					
					this._parallelConnections_queuedRequests.push(connectionInfo);
					
					//Now sort all queued ones to decide which next should go first
					this._parallelConnections_queuedRequests.sort((connectionInfo_a, connectionInfo_b) =>
					{
						const weight_a = connectionInfo_a.weight;
						const weight_b = connectionInfo_b.weight;
						
						//Equals lower weight first
						if (weight_a===weight_b) { return 0; }
						return weight_a<weight_b ? -1 : 1;
					});
				}
			});
		}
		//When we're done with a call, check to run the next most important one in the queue (being sorted in advance)
		_maxParallelConnections_doneRequest()
		{
			//Check if we have things in the queue
			if (this._parallelConnections_queuedRequests.length>0)
			{
				const connectionInfo = this._parallelConnections_queuedRequests.shift();
				connectionInfo.start();
			}
			//Otherwise, indicate that we have one less nb of ongoing request. We don't do -- ++ above, since it leads to no change
			else { this._parallelConnections_ongoingCount--; }
		}
	
	
	
	//EXTERNAL APIS CALL
		/*
		Usage ex:
			try
			{
				const response = await B_REST_API.call_external("GET", "https://api.stripe.com/v1/elements/sessions?key=....");
					-> Contains {data, headers, status, statusText}
			}
			catch ({code, msg, responseData})
			{
				
			}
		*/
		static async call_external(method, url, data=null, headers={}, resolveErrors=false) //Do we want errs like 400 & 500 to resolve anyways or reject ?
		{
			return new Promise((s,f) =>
			{
				const axiosConfig = {
					method: method.toLowerCase(),
					url,
					data,
					headers,
				};
				
				if (resolveErrors) { axiosConfig.validateStatus = (status)=>{return true}; }
				
				axios(axiosConfig).then(
					axios_result =>
					{
						s(axios_result);
					},
					axios_error =>
					{
						//https://www.npmjs.com/package/axios#handling-errors
						const isErr        = axios_error instanceof Error;
						const axiosErrCode = isErr ? 400                 : (axios_error?.response?.status ?? 400);
						const axiosErrMsg  = isErr ? axios_error.message : (axios_error?.response?.data   ?? "Unknown");
						
						f({code:axiosErrCode, msg:axiosErrMsg, responseData:axios_error.response.data});
					}
				);
			});
		}
};
