<template>
	
	<div>
		<v-progress-circular :value="value" :color="color_circle" :size="100" :width="15">
			<div class="d-flex flex-column align-center">
				<span :style="{color:color_text}">{{ value }} %</span>
				<v-icon v-if="icon" v-text="icon" />
			</div>
		</v-progress-circular>
		<div v-if="!isAvg" class="mt-2 accent--text">{{ label }}</div>
	</div>
		
</template>

<script>
	
	const locBasePath = "views.compatCircle";
	
	export const COMPAT_DETAILED_TOPICS = {
		skills:       {icon:"mdi-head-cog-outline"},
		affinities:   {icon:"mdi-puzzle-check"},
		territory:    {icon:"mdi-earth"},
		profile:      {icon:"mdi-bullseye-arrow"},
		time:         {icon:"mdi-calendar-clock"},
		expectations: {icon:"mdi-star-outline"},
		budget:       {icon:"mdi-currency-usd"},
	};
	
	
	export default
	{
		props: {
			tag:   {type:String, required:true},
			value: {type:Number, required:true},
		},
		computed: {
			isAvg() { return this.tag==="avg"; },
			label() { return this._loc(`tags.${this.tag}`); },
			icon()  { return this.isAvg ? null : COMPAT_DETAILED_TOPICS[this.tag].icon; },
			color_circle()
			{
				if (this.value >= 90) { return "success"; }
				if (this.value >= 80) { return "yellow";  }
				if (this.value >= 70) { return "warning"; }
				return "error";
			},
			color_text()
			{
				return "black";
					/*
						NOTE: No longer having a color for text, otherwise yellow text is hard to read, and also having only that one turning black is weird
						if (this.value >= 90) { return "success"; }
						if (this.value >= 80) { return "black";   }
						if (this.value >= 70) { return "warning"; }
						return "error";
					*/
			},
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
		},
	};
	
</script>