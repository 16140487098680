<template>
	
	<kb-registration-flow :pages="pages" />
	
</template>

<script>

	import { KbRegistrationFlow_Page } from "@/custom/components/modules/KbRegistrationFlow.vue";
	
	
	const pages = [
		new KbRegistrationFlow_Page("general", "mdi-account", ["kb-franchisor-form"]), //<kb-franchisor-form>: Must specify reason prop in KbRegistrationFlow.ifQuestionComponentIskbEntityBaseForm_specifyReason()
	];
	
	export default {
		data()
		{
			return {
				pages,
			};
		},
		components: {
			KbRegistrationFlow: () => import("@/custom/components/modules/KbRegistrationFlow.vue"),
		},
	};
	
</script>