<template>
	<v-card elevation="16" color="white" max-width="500" :disabled="any_isLoading" :loading="any_isLoading ? 'primary' : null">
		
		<!-- Banner img -->
		<v-card-text v-if="banner">
			<v-row justify="center">
				<v-col cols="12"> <v-img :src="banner" /> </v-col>
			</v-row>
		</v-card-text>
		
		<!-- If for login & forgot pwd -->
		<template v-if="purpose_isLoginAndForgotPwd">
			<!-- Login section -->
			<v-card-text>
				<v-row justify="center" @keyup.enter="login_isBtnEnabled ? login_submit() : undefined">
					<v-col cols="12" class="pb-0"> <br-field-db :field="login.modelField_userName" autofocus /> </v-col>
					<v-col cols="12" class="pt-0"> <br-field-db :field="login.modelField_pwd" /> </v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pb-6">
				<v-row justify="center">
					<!-- Login btn -->
					<v-col cols="12" class="text-center">
						<v-btn color="primary" :loading="login.isLoading" :disabled="!login_isBtnEnabled" @click="login_submit"> <v-icon>mdi-login</v-icon> {{ t_alt("login.submit") }} </v-btn>
					</v-col>
					<!-- Forgot pwd btn + section -->
					<v-scroll-y-transition hide-on-leave>
						<v-col v-if="!forgotPwd.show" key="forgotPwd--off" cols="12" class="text-center">
							<v-btn color="accent" outlined small @click="forgotPwd.show=true"> <v-icon class="mr-1">mdi-email</v-icon> {{ t_alt("forgotPwd.activator") }} </v-btn>
						</v-col>
						<v-col v-else key="forgotPwd--on" cols="12" class="text-center">
							<v-row class="mt-2" @keyup.enter="forgotPwd_isBtnEnabled ? forgotPwd_sendEmail() : undefined">
								<v-col cols="12"> <v-divider /> </v-col>
								<v-col cols="12">
									<br-field-db :hint="t_alt('forgotPwd.explanation')" persistent-hint prepend-icon="mdi-email" :field="forgotPwd.modelField_recoveryEmail" />
								</v-col>
								<v-col cols="12" class="text-center">
									<v-btn color="primary" :loading="forgotPwd.isLoading" :disabled="!forgotPwd_isBtnEnabled" @click="forgotPwd_sendEmail"> <v-icon>mdi-email</v-icon> {{ t_alt("forgotPwd.sendEmail") }} </v-btn>
								</v-col>
							</v-row>
						</v-col>
					</v-scroll-y-transition>
				</v-row>
			</v-card-actions>
		</template>
		
		<!-- Reset pwd section -->
		<template v-else-if="purpose_isResetPwd">
			<v-card-text>
				<v-row justify="center" @keyup.enter="resetPwd_isBtnEnabled ? resetPwd_submit() : undefined">
					<v-col cols="12"> <br-field-db :field="resetPwd.modelField_main"    @change="resetPwd_onFieldChange" /> </v-col>
					<v-col cols="12"> <br-field-db :field="resetPwd.modelField_confirm" @change="resetPwd_onFieldChange" /> </v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pb-6">
				<v-row justify="center">
					<v-col cols="12" class="text-center">
						<v-btn color="primary" :loading="resetPwd.isLoading" :disabled="!resetPwd_isBtnEnabled" @click="resetPwd_submit"> <v-icon>mdi-login</v-icon> {{ t_alt("resetPwd.submit") }} </v-btn>
					</v-col>
				</v-row>
			</v-card-actions>
		</template>
		
		
	</v-card>
</template>

<script>
	
	import { B_REST_Model, B_REST_FieldDescriptors } from "@/bREST/core/classes";
	import B_REST_VueApp_base                        from "../../B_REST_VueApp_base.js";
	import B_REST_VueApp_CreateCoreMixin             from "../../B_REST_VueApp_CreateCoreMixin.js";
	
	const COMPONENT_NAME         = "BrAuthPrompts";
	const CORE_ALT_BASE_LOC_PATH = `app.components.${COMPONENT_NAME}`;
	
	const PURPOSE_LOGIN_AND_FORGOT_PWD = "loginAndForgotPwd";
	const PURPOSE_RESET_PWD            = "resetPwd";
	
	
	
	
	export default {
		name: COMPONENT_NAME,
		//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
		mixins: [
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
		],
		props: {
			purpose:        {type:String,    required:true}, //One of PURPOSE_x
			banner:         {type:undefined, default:null},
			resetPwdHash:   {type:String,    default:null}, //If PURPOSE_RESET_PWD, usually $bREST.boot_sudoHash, as per server's RouteParser_base::_coreCalls_resetPwd_sendEmail()
		},
		data()
		{
			switch (this.purpose)
			{
				case PURPOSE_LOGIN_AND_FORGOT_PWD:
					const modelField_userName = B_REST_FieldDescriptors.DB.create_type_string("userName", {
						loc:        B_REST_VueApp_base.instance.t_core_subTree(`${CORE_ALT_BASE_LOC_PATH}.login.fields.userName`),
						isRequired: true,
					}).factory_modelField();
					
					const modelField_recoveryEmail = B_REST_FieldDescriptors.DB.create_type_email("recoveryEmail", {
						loc:        B_REST_VueApp_base.instance.t_core_subTree(`${CORE_ALT_BASE_LOC_PATH}.login.fields.recoveryEmail`),
						isRequired: true,
					}).factory_modelField();
					
					return {
						login: {
							modelField_userName,
							modelField_pwd: makePwdModelField("login","pwd",/*showMinMax*/false,/*showStrength*/false),
							isLoading:      false,
						},
						forgotPwd: {
							modelField_recoveryEmail,
							show:      false,
							isLoading: false,
						},
					};
				case PURPOSE_RESET_PWD:
					if (!this.resetPwdHash) { this.$bREST.throwEx(`Got no user info for pwd reset`); }
					
					return {
						resetPwd: {
							modelField_main:    makePwdModelField("resetPwd","main",   /*showMinMax*/true,/*showStrength*/true),
							modelField_confirm: makePwdModelField("resetPwd","confirm",/*showMinMax*/true,/*showStrength*/false),
							isLoading:          false,
						},
					};
				default:
					this.$bREST.throwEx(`Unknown purpose "${this.purpose}"`);
				break;
			}
		},
		computed: {
			purpose_isLoginAndForgotPwd() { return this.purpose===PURPOSE_LOGIN_AND_FORGOT_PWD; },
			purpose_isResetPwd()          { return this.purpose===PURPOSE_RESET_PWD;            },
			any_isLoading()               { return this.login?.isLoading || this.forgotPwd?.isLoading ||this.resetPwd?.isLoading || false; },
			login_isBtnEnabled()          { return !this.login.isLoading     && this._isFieldValid("login",    "userName")       && this._isFieldValid("login","pwd");        },
			forgotPwd_isBtnEnabled()      { return !this.forgotPwd.isLoading && this._isFieldValid("forgotPwd","recoveryEmail");                                              },
			resetPwd_isBtnEnabled()       { return !this.resetPwd.isLoading  && this._isFieldValid("resetPwd", "main")           && this._isFieldValid("resetPwd","confirm"); },
		},
		methods: {
			_isFieldValid(formName,fieldName) { return this[formName][`modelField_${fieldName}`].isNotEmpty_andValid; },
			//LOGIN RELATED
				async login_submit()
				{
					this.login.isLoading = true;
					
					//NOTE: Always resolve (even if err). For success, automatically redirects
					const response = await this.$bREST.login(this.login.modelField_userName.val, this.login.modelField_pwd.val); 
					
					if (!response.isSuccess)
					{
						this.$bREST.utils.console_error(`Got err after login`,response);
						
						let errorType = null;
						
						if      (response.errorType_isAuth_resettingPwd) { errorType="resettingPwd"; }
						else if (response.errorType_isAuth_disabledUser) { errorType="disabledUser"; }
						else if (response.isBadAuth)                     { errorType="badAuth";      }
						else                                             { errorType="otherError";   }
						
						this.$bREST.notifs_tmp({msg:this.t_alt(`login.msgs.${errorType}`),color:"error"});
						
						this.login.isLoading = false; //NOTE: Don't set to false when successful, as we want to keep it like this until redirected
					}
				},
			//FORGOT PWD RELATED
				async forgotPwd_sendEmail()
				{
					this.forgotPwd.isLoading = true;
					
					//NOTE: Always resolve (even if err)
					const response = await this.$bREST.resetPwd_sendEmail(this.forgotPwd.modelField_recoveryEmail.val); 
					
					if (response.isSuccess)
					{
						this.$bREST.notifs_tmp({msg:this.t_alt("forgotPwd.msgs.success"), color:"success"});
					}
					else
					{
						this.$bREST.utils.console_error(`Got err after saying forgot pwd`,response);
						
						let errorType = null;
						
						if      (response.errorType_isAuth_recoveryEmail) { errorType="recoveryEmail"; }
						else if (response.errorType_isAuth_disabledUser)  { errorType="disabledUser";  }
						else                                              { errorType="otherError";    }
						
						this.$bREST.notifs_tmp({msg:this.t_alt(`forgotPwd.msgs.${errorType}`),color:"error"});
					}
					
					this.forgotPwd.isLoading = false;
				},
			//RESET PWD RELATED
				resetPwd_onFieldChange()
				{
					const warnNotMatching = this.resetPwd.modelField_main.isSet && this.resetPwd.modelField_confirm.isSet && this.resetPwd.modelField_main.val!==this.resetPwd.modelField_confirm.val;
					
					this.resetPwd.modelField_confirm.validation_custom_errorMsg = warnNotMatching ? this.t_alt("resetPwd.msgs.notMatching") : null;
				},
				async resetPwd_submit()
				{
					this.resetPwd.isLoading = true;
					
					//NOTE: Always resolve (even if err)
					const response = await this.$bREST.resetPwd_save(this.resetPwdHash, this.resetPwd.modelField_main.val);
					
					if (response.isSuccess)
					{
						this.$bREST.notifs_tmp({msg:this.t_alt("resetPwd.msgs.success"), color:"success"});
					}
					else
					{
						this.$bREST.utils.console_error(`Got err after resetting pwd`,response);
						
						let errorType = null;
						
						if      (response.errorType_isAuth_disabledUser)  { errorType="disabledUser";  }
						else if (response.isBadAuth)                      { errorType="badAuth";       } //NOTE: This could happen if at that time, the user is not in the resetting pwd status anymore
						else                                              { errorType="otherError";    }
						
						this.$bREST.notifs_tmp({msg:this.t_alt(`resetPwd.msgs.${errorType}`),color:"error"});
					}
					
					this.resetPwd.isLoading = false;
				},
		},
	};
	
	
	
	function makePwdModelField(formName, fieldName, showMinMax, showStrength)
	{
		const options = {
			loc:              B_REST_VueApp_base.instance.t_core_subTree(`${CORE_ALT_BASE_LOC_PATH}.${formName}.fields.${fieldName}`),
			isRequired:       true,
			min:              showMinMax ? 0  : undefined,
			max:              showMinMax ? 50 : undefined,
			pwd_evalStrength: showStrength,
		};
		
		return B_REST_FieldDescriptors.DB.create_type_pwd(fieldName,options).factory_modelField();
	}
	
</script>