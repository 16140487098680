<template>
	<v-app>
		<v-main>
			
			<v-app-bar app dense>
				<v-row justify="end">
					<v-col cols="auto">Output: <strong class="mx-2">Bottom</strong> <v-switch v-model="outputSide" color="grey" class="d-inline-block mr-2" /> <strong>Side</strong></v-col>
				</v-row>
			</v-app-bar>
			
			<v-row :class="outputSide ? 'scrollable-row' : null">
				<v-col cols="12" :md="outputSide ? 6 : 12" :class="outputSide ? 'scrollable-col' : null" class="py-8">
					<slot name="editor" />
				</v-col>
				<v-col cols="12" :md="outputSide ? 6 : 12" :class="outputSide ? 'scrollable-col' : null" class="py-8">
					<slot name="output" />
				</v-col>
			</v-row>
			
			<v-dialog v-model="showAutoSaveWarning" width="500">
				<v-card>
					<v-card-title class="text-h5 red white--text lighten-2 text-center d-block">Warning on saving</v-card-title>
					<v-card-text class="pt-4">Saving .js &amp; .vue files in localhost cause the app to reload and form to empty, so wait</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn color="primary" text @click="showAutoSaveWarning=false">OK</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			
		</v-main>
	</v-app>
</template>

<script>
	
	export default {
		props: {
			warnAutoSave: {type:Boolean, required:false, default:false},
		},
		data()
		{
			return {
				outputSide: false,
				showAutoSaveWarning: this.warnAutoSave,
			};
		},
		created()
		{
			window.addEventListener('beforeunload', (event) =>
			{
				event.preventDefault();
  				return event.returnValue = "Are you sure you want to exit?";
			});
		},
	};
	
</script>

<style>
	
	html {
		overflow-y: initial;
	}
	
</style>

<style scoped>
	
	.scrollable-row {
		max-height: calc(100vh - 36px);
		overflow-y: hidden;
	}
		.scrollable-col {
			max-height: 100vh;
			overflow-y: scroll;
		}
			.scrollable-col::-webkit-scrollbar {
				width: 0;
			}
			.scrollable-col:first-child {
				border-right: 5px solid grey;
			}
	
</style>