<template>
	<br-generic-list-base :derived-component="_self">
		<!-- <template #filter.regions="{ filter }">
			<custom-filter-regions />
		</template> -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericListBase_createMixin, B_REST_Vuetify_GenericList_Col, B_REST_Vuetify_GenericList_GlobalAction } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/list/BrGenericListBase.vue";
	
	
	
	export default {
		name: "leadList",
		components: {
			CustomFilterRegions: ()=>import("@/custom/components/general/regionTree/CustomFilterRegions.vue"),
		},
		mixins: B_REST_Vuetify_GenericListBase_createMixin({
			modelName: "Lead",
			icon: "mdi-account",
			fromLoader: {
				apiBaseUrl: "/leads/",
			},
			cols: {
				"firstName":     {fieldNamePaths:"user.firstName",    style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"lastName":      {fieldNamePaths:"user.lastName",     style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"recoveryEmail": {fieldNamePaths:"user.recoveryEmail",style:{fromBreakpoint:"sm", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"created_dt":    {fieldNamePaths:"created_dt",        style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
				"lastLogin_dt":  {fieldNamePaths:"user.lastLogin_dt", style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
				"isActive":      {fieldNamePaths:"isActive",          style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
				"calc_nbSent":   {fieldNamePaths:"calc_nbSent",       style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_RIGHT}},
			},
			globalActions: {
				add: {
					click: {
						async hook(listComponent,action,selectedModels)
						{
							listComponent.$bREST.routes_go_moduleForm_new("leadForm"); //NOTE: In Flag we defined modules like "leadForm-form" & "leadForm-list" so don't blindly think we have to do "<moduleName>Form" here
						},
					},
					icon: "mdi-plus",
					selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0,
				},
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					edit: {
						click: {
							async hook(listComponent,action,model)
							{
								listComponent.$bREST.routes_go_moduleForm_pkTag("leadForm",model.pk); //NOTE: In Flag we defined modules like "leadForm-form" & "leadForm-list" so don't blindly think we have to do "<moduleName>Form" here
							},
						},
						icon: "mdi-pencil",
					},
					sudo: {
						click: {
							async hook(listComponent,action,model)
							{
								listComponent.$bREST.sudoIn(model.select("user.sudoHash").data);
							},
							isEnabled(listComponent,action,model)
							{
								const self_idUser     = listComponent.$bREST.user_pk;
								const target_idUser   = model.select("user").pk;
								const target_sudoHash = model.select("user.sudoHash").data;
								
								return target_sudoHash && self_idUser!==target_idUser;
							},
						},
						icon: "mdi-key-arrow-right",
					},
				},
			},
			filters: {
			  //regions:            {op:"eq_in", multiple:true},
				uniqueCode:         {},
				created_dt:         {},
				campaign_fk:        {multiple:true, componentAttrs:{items:"campaignList"}},
				currency:           {multiple:true, componentAttrs:{items:"currencyList"}},
				calc_likeSearch:    {},
				coords_address:     {},
				dues_referring_fk:  {multiple:true, componentAttrs:{picker:"consultantList"}},
				isProfileCompleted: {componentAttrs:{as:"select"}},
				gender:             {},
			},
		}),
	};
			
</script>