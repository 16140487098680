<!-- Intended for BrAppLayoutCoreFunctionalities.vue only -->
<template>
	<v-navigation-drawer app
	                     right
	                     :value="instance.visible"
	                     :temporary="instance.aboveAll"
	                     :width="width"
	                     class="br-right-drawer"
	                     :class="instance.aboveAll ? 'br-right-drawer--above-all' : null"
	                     @input="on_input"
	                     dark
	                     disable-resize-watcher
	                     color="secondary"
	>
						<!-- WARNING: If we alter theme here, has impacts in <BrGenericListBase> having an inline <v-navigation-drawer> when it's filters_uses_inline -->
		<v-toolbar flat color="primary" dark>
			<v-btn icon @click="on_close_click"><v-icon>mdi-close</v-icon></v-btn>
			<v-toolbar-title>{{ title }}</v-toolbar-title>
		</v-toolbar>
		
		<v-fade-transition mode="out-in">
			<portal-vue-dest name="rightDrawerPortal" :transition="VFadeTransition" tag="span" />
		</v-fade-transition>
		
	</v-navigation-drawer>
</template>

<script>
	
	import B_REST_Vuetify_RightDrawer        from "./B_REST_Vuetify_RightDrawer.js";
	import B_REST_Vuetify_RightDrawerContent from "./B_REST_Vuetify_RightDrawerContent.js";
	import { VFadeTransition } from "vuetify/lib";
	
	export { B_REST_Vuetify_RightDrawer, B_REST_Vuetify_RightDrawerContent };
	
	export const DESKTOP_WIDTH = 700; //NOTE: Used in <BrGenericListBase>
	
	B_REST_Vuetify_RightDrawer.init();
	
	
	
	export default {
		data()
		{
			return {
				instance: B_REST_Vuetify_RightDrawer.instance,
				VFadeTransition,
			};
		},
		computed: {
			width() { return this.$bREST.uiBreakpoint.mobile ? "100%" : DESKTOP_WIDTH; },
			title() { return this.instance.activeContent?.title ?? null; },
		},
		methods: {
			on_input(visible)
			{
				if (!visible)
				{
					this.instance.close({coreReason:B_REST_Vuetify_RightDrawer.CLOSE_REASON_CLICK_OUTSIDE});
				}
			},
			on_close_click()
			{
				this.instance.close({coreReason:B_REST_Vuetify_RightDrawer.CLOSE_REASON_CLICK_CLOSE});
			},
		},
	};
	
</script>

<style scoped>
	
	.br-right-drawer { }
		.br-right-drawer--above-all {
			position: fixed !important;
			z-index: 5 !important; /* This is req as otherwise the <v-app-bar> will appear above it. But don't go too high, or <v-select> dropdowns and popups will be hidden under */
		}
	
</style>