
import { B_REST_Utils, B_REST_Model } from "../../../../../classes";
import B_REST_VueApp_base             from "../../../B_REST_VueApp_base.js";



class B_REST_Vuetify_GenericList_Action_base
{
	_listComponent   = null;   //BrGenericListBase der this belongs to
	_name            = null;   //Unique X action name
	_click_isEnabled = null;   //Either bool or func. Signature depends on the derived class
	_click_hook      = null;   //Async func. Signature depends on the derived class. Data table will freeze until it resolves / rejects. Must ret bool
	_mustConfirm     = false;  //If we want an automatic dialog to prompt for confirmation. Loc path will be "<locBasePath>.confirm", and will contain extraData: {count, modelsString}. Ex "Do you want to del the {count} following records: {modelsString} ?"
	_displayResult   = false;  //If after the hook, we want msgs "<locBasePath>.success" or "<locBasePath>.failure" to appear automatically, both again with {count, modelsString}
	_icon            = null;   //Like "mdi-xxx"
	_style           = null;   //If we want a btn shape over the icon, or just an icon w/o btn borders. Vuetify prop like "tile", "rounded" etc
	_color           = null;   //Color of the icon or btn
	_extraData       = null;   //Optional obj
	//WARNING: If we add stuff here, also add in constructor() + clone()
	
	
	//For options struct, check deriveds
	constructor(listComponent, name, options)
	{
		this._listComponent = listComponent;
		this._name          = name;
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			click:         {accept:[Object],  required:true},
			mustConfirm:   {accept:[Boolean], default:null},
			displayResult: {accept:[Boolean], default:null},
			icon:          {accept:[String],  default:null},
			style:         {accept:[String],  default:null}, //For now a string, but maybe wrong
			color:         {accept:[String],  default:null},
			extraData:     {accept:[Object],  default:null},
		}, "Generic list action");
		
		//Click related
		{
			const clickOptions = B_REST_Utils.object_hasValidStruct_assert(options.click, {
				isEnabled: {accept:[Boolean,Function], default:true},
				hook:      {accept:undefined,          required:true},
			}, "Generic list action click");
			
			this._click_isEnabled = clickOptions.isEnabled;
			this._click_hook      = clickOptions.hook;
		}
		
		this._mustConfirm   = options.mustConfirm;
		this._displayResult = options.displayResult;
		this._icon          = options.icon;
		this._style         = options.style;
		this._color         = options.color;
		this._extraData     = options.extraData;
	}
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg, details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`, details); }
	
	
	get listComponent() { return this._listComponent; }
	
	get name()          { return this._name;                                              }
	get debugName()     { return `B_REST_Vuetify_GenericList_Action_base<${this._name}>`; }
	get mustConfirm()   { return this._mustConfirm;                                       }
	get displayResult() { return this._displayResult;                                     }
	get icon()          { return this._icon;                                              }
	get style()         { return this._style;                                             }
	get color()         { return this._color;                                             }
	get extraData()     { return this._extraData;                                         }
	
	
	getBtnAttrs(isClickable) { return this._abstract_getBtnAttrs(isClickable); }
		_abstract_getBtnAttrs(isClickable) { B_REST_Vuetify_GenericList_Action_base._throwEx(`Must override in der`); }
	
	//Props we allow changing later
	set extraData(val) { this._extraData=val; }
	
	//Prefix ex "contactPicker.<whichActions>.<name>" to stuff like ".label", etc
	get locBasePath() { return this._abstract_locBasePath; }
	get label()       { return this._loc("label"); }
		get _abstract_locBasePath() { B_REST_Vuetify_GenericList_Action_base._throwEx(`Must override in der`); }
		_loc(subLocPath,details=null) { return B_REST_VueApp_base.instance.t_custom(`${this.locBasePath}.${subLocPath}`,details); }
	
	//For automatic prompts
	getMsg_confirm(count,modelsString) { return this._loc("confirm",{count,modelsString}); }
	getMsg_success(count,modelsString) { return this._loc("success",{count,modelsString}); }
	getMsg_failure(count,modelsString) { return this._loc("failure",{count,modelsString}); }
	
	_perform_click_isEnabled(modelOrModelListOrNULL=null)
	{
		if (!this._click_isEnabled)       { return false; }
		if (this._click_isEnabled===true) { return true;  }
		
		try       { return this._click_isEnabled(this._listComponent,this,modelOrModelListOrNULL);  }
		catch (e) { B_REST_Utils.throwEx(`click_isEnabled hook failed, for ${this.debugName}: ${e}`); }
		return false;
	}
	//Must ret if hook went well
	async _perform_click_hook(modelOrModelListOrNULL=null)
	{
		if (!this._click_hook) { return false; }
		
		try       { return await this._click_hook(this._listComponent,this,modelOrModelListOrNULL); }
		catch (e) { B_REST_Utils.throwEx(`click_hook failed, for ${this.debugName}: ${e}`);           }
		return false;
	}
};
	
	
	
	
	
	
	export class B_REST_Vuetify_GenericList_GlobalAction extends B_REST_Vuetify_GenericList_Action_base
	{
		static get SELECTION_TYPE_0()   { return "none";       } //Ex for an add btn, we don't need to display checkboxes to be able to do the action
		static get SELECTION_TYPE_1()   { return "exactlyOne"; } //For an action that requires exactly 1 checked row
		static get SELECTION_TYPE_0_N() { return "optional";   } //For an action that we can either do on all rows, or only the checked ones
		static get SELECTION_TYPE_1_N() { return "required";   } //For an action that requires checked rows
		
		_selectionType = B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N;
		/*
		Options as
			{
				click: {
					isEnabled: bool | (<BrGenericListBase der>listComponent,action<B_REST_Vuetify_GenericList_GlobalAction>,selectedModels<B_REST_Model>=null)
					hook:      async(<BrGenericListBase der>listComponent,action<B_REST_Vuetify_GenericList_GlobalAction>,selectedModels<B_REST_Model>=null) //Must ret bool
				},
				icon,
				style,
				color,
				selectionType, //One of B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_x
				extraData,
			}
		*/
		constructor(listComponent, name, options)
		{
			super(listComponent, name, options);
			
			if (B_REST_Utils.object_hasPropName(options,"selectionType")) { this._selectionType=options.selectionType; }
		}
		
		
		get _abstract_locBasePath() { return `${this._listComponent.t_baseLocPath}.globalActions.${this._name}`; }
		
		get selectionType()        { return this._selectionType; }
		get selectionType_is_0()   { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0;   }
		get selectionType_is_1()   { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1;   }
		get selectionType_is_0_N() { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N; }
		get selectionType_is_1_N() { return this._selectionType===B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1_N; }
		
		_abstract_getBtnAttrs(isClickable)
		{
			const WIDTH = 36; //NOTE: If we change that, will maybe have impacts in BrGenericListBase.vue::ROW_ACTIONS_BTN_REQ_SIZE
			
			const style = this._style || null;
			const color = this._color || "primary";
			
			return {
				color:       isClickable ? color : null,
				dark:        isClickable && !!this._color,
				icon:        style==="icon",
				tile:        style==="tile",
				rounded:     style==="rounded",
			};
		}
		
		click_isEnabled(selectedModels=null) { return this._perform_click_isEnabled(selectedModels); }
		//Must ret if hook went well
		async click_hook(selectedModels=null) { return this._perform_click_hook(selectedModels); }
	};
	
	
	
	
	
	
	export class B_REST_Vuetify_GenericList_RowAction extends B_REST_Vuetify_GenericList_Action_base
	{
		/*
		Options as
			{
				click: {
					isEnabled: bool | (<BrGenericListBase der>listComponent,action<B_REST_Vuetify_GenericList_RowAction>,model<B_REST_Model>)
					hook:      async(<BrGenericListBase der>listComponent,action<B_REST_Vuetify_GenericList_RowAction>,model<B_REST_Model>) //Must ret bool
				},
				icon,
				style,
				color,
				extraData,
			}
		*/
		constructor(listComponent, name, options)
		{
			super(listComponent, name, options);
		}
		
		
		get _abstract_locBasePath() { return `${this._listComponent.t_baseLocPath}.rowActions.${this._name}`; }
		
		
		
		click_isEnabled(model)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
			return this._perform_click_isEnabled(model);
		}
		//Must ret if hook went well
		async click_hook(model)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
			return this._perform_click_hook(model);
		}
		
		_abstract_getBtnAttrs(isClickable)
		{
			const WIDTH = 36; //NOTE: If we change that, will maybe have impacts in B_REST_Vuetify_GenericList::ROW_ACTIONS_BTN_REQ_SIZE
			
			const style = this._style || "icon";
			const color = this._color || null;
			
			return {
				color:       isClickable ? color : null,
				dark:        isClickable && !!this._color,
				icon:        style==="icon",
				tile:        style==="tile",
				rounded:     style==="rounded",
				width:       WIDTH,
				"min-width": WIDTH,
			};
		}
	};
