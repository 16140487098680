<template>
	<br-generic-list-base :derived-component="_self">
		<!-- <template #filter.regions="{ filter }">
			<custom-filter-regions />
		</template> -->
	</br-generic-list-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericListBase_createMixin, B_REST_Vuetify_GenericList_Col, B_REST_Vuetify_GenericList_GlobalAction } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/list/BrGenericListBase.vue";
	
	
	
	export default {
		name: "entityXList",
		components: {
			CustomFilterRegions: ()=>import("@/custom/components/general/regionTree/CustomFilterRegions.vue"),
		},
		mixins: B_REST_Vuetify_GenericListBase_createMixin({
			modelName: "Entity_base",
			icon: "mdi-account",
			fromLoader: {
				apiBaseUrl: "/entityX/",
			},
			cols: {
				"firstName":     {fieldNamePaths:"user.firstName",     style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"lastName":      {fieldNamePaths:"user.lastName",      style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"recoveryEmail": {fieldNamePaths:"user.recoveryEmail", style:{fromBreakpoint:"sm", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"isActive":      {fieldNamePaths:"isActive",           style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
			},
			filters: {
			  //regions:            {op:"eq_in", multiple:true},
				uniqueCode:         {},
				created_dt:         {},
				campaign_fk:        {multiple:true, componentAttrs:{items:"campaignList"}},
				currency:           {multiple:true, componentAttrs:{items:"currencyList"}},
				calc_likeSearch:    {},
				coords_address:     {},
				dues_referring_fk:  {multiple:true, componentAttrs:{picker:"consultantList"}},
				isProfileCompleted: {componentAttrs:{as:"select"}},
				gender:             {},
			},
		}),
	};
			
</script>