<template>
	
	<v-container class="fill-height" fluid>
		
		<v-overlay :value="isLoading"> <v-progress-circular indeterminate /> </v-overlay>
		
		<v-card class="mb-8" :elevation="1">
			<v-card-title>{{ _loc("filters.title") }}</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="12" md="auto"> <v-text-field v-model="dateFrom" type="date" :label="_loc('filters.dateFrom')" /> </v-col>
					<v-col cols="12" md="auto"> <v-text-field v-model="dateTo"   type="date" :label="_loc('filters.dateTo')"   /> </v-col>
					<v-col cols="12" md="auto"> <v-btn :disabled="isLoading" @click="refetchCommissions">{{ _loc("filters.submit") }}</v-btn> </v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- If it gets too huge w tons of records, use a <v-virtual-scroller> -->
		<v-data-table v-if="items" :headers="headers" :items="items" :items-per-page="itemsPerPage" :footer-props="footerProps" class="elevation-1" />
		
		<v-row v-if="!isAdminAllInfo && currencyTotalList" class="mt-8 text-left" justify="end">
			<v-col cols="auto">
				<h6 class="text-h6 text-center">{{ _loc("footer.title") }}</h6>
				<table>
					<tbody>
						<tr v-for="(loop_amount,loop_currency) in currencyTotalList" :key="loop_currency">
							<th class="pr-4">{{ loop_currency }}:</th> <td class="text-right">{{ _refetchCommissions_moneyFormat(loop_amount,loop_currency) }}</td>
						</tr>
					</tbody>
				</table>
			</v-col>
		</v-row>
		
	</v-container>
	
</template>

<script>
	
	const locBasePath = "views.commissionReport";
	
	
	
	export default {
		name: "commissionReport",
		data()
		{
			return {
				dateFrom:          null,
				dateTo:            null,
				isLoading:         false,
				itemsPerPage:      100,
				items:             null, //Arr, but null = before initial call
				currencyTotalList: null, //A map of <currency>:<total>
			};
		},
		created()
		{
			/*
			Commented out because if we've got 100k records, we should prolly set up filters before loading initially
				this.refetchCommissions();
			*/
		},
		computed: {
			isAdminAllInfo() { return this.$bREST.user_type_is("admin"); },
			footerProps() {
				return {
					"items-per-page-options": [this.itemsPerPage, -1],
				};
			},
			headers()
			{
				if (this.isAdminAllInfo)
				{
					return [
						{value:'date',           text:this._loc("headers.date.label"),                        align:'start', sortable:true},
						{value:'type',           text:this._loc("headers.type.label"),                        align:'start', sortable:true},
						{value:'from',           text:this._loc("headers.from.label"),                        align:'start', sortable:true},
						{value:'sharedAmount',   text:this._loc("headers.sharedAmount.label"),                align:'end',   sortable:true},
						{value:'invoice',        text:this._loc("headers.invoice.label"),                     align:'start', sortable:true},
						{value:'sysCie',         text:this._loc("headers.adminAllInfo.sysCie.label"),         align:'start', sortable:false},
						{value:'regionManager',  text:this._loc("headers.adminAllInfo.regionManager.label"),  align:'start', sortable:false},
						{value:'mainConsultant', text:this._loc("headers.adminAllInfo.mainConsultant.label"), align:'start', sortable:false},
						{value:'leadConsultant', text:this._loc("headers.adminAllInfo.leadConsultant.label"), align:'start', sortable:false},
						{value:'partner',        text:this._loc("headers.adminAllInfo.partner.label"),        align:'start', sortable:false},
					];
				}
				else
				{
					return [
						{value:'date',         text:this._loc("headers.date.label"),                align:'start',  sortable:true},
						{value:'type',         text:this._loc("headers.type.label"),                align:'start',  sortable:true},
						{value:'from',         text:this._loc("headers.from.label"),                align:'start',  sortable:true},
						{value:'sharedAmount', text:this._loc("headers.sharedAmount.label"),        align:'end',    sortable:true},
						{value:'invoice',      text:this._loc("headers.invoice.label"),             align:'start',  sortable:true},
						{value:'percent',      text:this._loc("headers.forEntity.percent.label"),   align:'center', sortable:true},
						{value:'ownAmount',    text:this._loc("headers.forEntity.ownAmount.label"), align:'end',    sortable:true},
					];
				}
			},
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			/*
			What we get depends on user type:
				Admin:
					{
						commissions: [{
							pk, d:<date>, t:<TYPE_x>, f:<FROM_x>, c:<currency>, s:<sharedAmount>, i:<Invoice number|NULL>,
							sc: {p:%, own:$},              //sysCie
							rm: {p:%, own:$, [l:<label>]}, //regionManager
							mc: {p:%, own:$, [l:<label>]}, //membershipConsultant
							lc: {p:%, own:$, [l:<label>]}, //leadConsultant
							p:  {p:%, own:$, [l:<label>]}, //partner
						}],
					}
				Others:
					{
						commissions:       [{pk, d:<date>, t:<TYPE_x>, f:<FROM_x>, c:<currency>, s:<sharedAmount>, i:<Invoice number|NULL>, p:%, own:$}],
						currencyTotalList: {CAD,EUR,MXN,USD}
					}
			WARNING: If it gets too huge w tons of records, use a <v-virtual-scroller> instead
			*/
			async refetchCommissions()
			{
				this.isLoading = true;
				
				try
				{
					const request  = new this.$bREST.GET("commissions/query");
					if (this.dateFrom) { request.qsa_add("dateFrom",this.dateFrom); }
					if (this.dateTo)   { request.qsa_add("dateTo",  this.dateTo);   }
					const response = await this.$bREST.call(request);
					const responseData = response.data;
					const items = [];
					
					if (this.isAdminAllInfo)
					{
						for (const loop_commissionInfo of responseData.commissions)
						{
							items.push({
								date:           loop_commissionInfo.d,
								type:           this._loc(`headers.type.enum.${loop_commissionInfo.t}`),
								from:           this._loc(`headers.from.enum.${loop_commissionInfo.f}`),
								sharedAmount:   this._refetchCommissions_moneyFormat(loop_commissionInfo.s,loop_commissionInfo.c),
								invoice:        loop_commissionInfo.i, //Can be NULL
								sysCie:         this._refetchCommissions_adminAllInfo_makePersonCell(loop_commissionInfo,"sc"),
								regionManager:  this._refetchCommissions_adminAllInfo_makePersonCell(loop_commissionInfo,"rm"),
								mainConsultant: this._refetchCommissions_adminAllInfo_makePersonCell(loop_commissionInfo,"mc"),
								leadConsultant: this._refetchCommissions_adminAllInfo_makePersonCell(loop_commissionInfo,"lc"),
								partner:        this._refetchCommissions_adminAllInfo_makePersonCell(loop_commissionInfo,"p"),
							});
						}
					}
					//Ex try w entity #6994: "C-13Y-G-C"
					else
					{
						for (const loop_commissionInfo of responseData.commissions)
						{
							items.push({
								date:         loop_commissionInfo.d,
								type:         this._loc(`headers.type.enum.${loop_commissionInfo.t}`),
								from:         this._loc(`headers.from.enum.${loop_commissionInfo.f}`),
								sharedAmount: this._refetchCommissions_moneyFormat(loop_commissionInfo.s,loop_commissionInfo.c),
								invoice:      loop_commissionInfo.i, //Can be NULL
								percent:      `${loop_commissionInfo.p} %`,
								ownAmount:    this._refetchCommissions_moneyFormat(loop_commissionInfo.own,loop_commissionInfo.c),
							});
						}
						
						this.currencyTotalList = responseData.currencyTotalList;
					}
					
					this.items = items;
				}
				catch (e)
				{
					this.$bREST.utils.console_error(e);
					this.$bREST.notifs_tmp({msg:this._loc("fetchError"), color:"error"});
				}
				
				this.isLoading = false;
			},
				_refetchCommissions_adminAllInfo_makePersonCell(commissionInfo, which)
				{
					const personInfo = commissionInfo[which];
					if (!personInfo.p && !personInfo.l) { return null; }
					
					const label          = which==="sc" ? "FlagFranchise" : personInfo.l??"???";
					const moneyFormatted = this._refetchCommissions_moneyFormat(personInfo.own, commissionInfo.c);
					
					return `${label}: @ ${personInfo.p}% = ${moneyFormatted}`;
				},
				_refetchCommissions_moneyFormat(amount, currency)
				{
					amount = this.$bREST.utils.number_format(amount, /*decimals*/2, /*decimalSep*/".", /*thousands*/" ");
					
					return `${amount} ${currency}`;
				},
		},
	};
			
</script>