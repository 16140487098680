<template>
	<g-edit-output-panels-app :warn-auto-save="true">
		<template #editor> <g-form  :form="form" /> </template>
		<template #output> <g-output :form="form" /> </template>
	</g-edit-output-panels-app>
</template>

<script>
	
	import GForm from "./GForm.js";
	
	
	
	export default {
		data()
		{
			return {
				form: null, //Instance of GForm
			};
		},
		async created()
		{
			this.form = new GForm();
		},
		components: {
			GEditOutputPanelsApp: () => import("../utils/GEditOutputPanelsApp.vue"),
			GForm: () => import("./GForm.vue"),
			GOutput: () => import("./output/GOutput.vue"),
		},
	};
	
</script>