
/*
NOTES:
	-Used in B_REST_App_base to concat this to app's custom version. Check B_REST_App_base::_v_frontend() docs
	-Core version control:
		-Frontend: in /bREST/core/version.js
		-Server:   in /bREST/bREST_base::VERSION & /bREST/version.info
	-Custom version control:
		-Frontend: in App.js constructor props
		-Server:   in bREST_Custom::CUSTOM_VERSION
History:
	0.1 (2021-05-13) (Flag Franchise)
	0.2 (2021-06-10) (Flag Franchise)
	0.3 (2022-05-05) (Used in Flag, made appGenerator, starting to use in SPAD)
	0.4 (2022-09-18) (Random stuff for Flag, CPA & SPAD)
	0.5 (2022-09-18) (Random stuff for Flag, CPA & SPAD)
	0.6 (2022-09-23) (Random stuff for Flag)
	0.7 (2022-09-28) (Fixes for SPAD)
*/

export default "0.7";
