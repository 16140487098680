
import B_REST_Utils from "../B_REST_Utils.js";



export default class B_REST_Response
{
	static get CODES_OK_NON_EMPTY()       { return 200; }
	static get CODES_OK_EMPTY()           { return 204; }
	static get CODES_BAD_REQUEST()        { return 400; } //Body, header, request unknown, etc
	static get CODES_BAD_AUTH()           { return 401; } //Bad login, sisabled user, wrong token / expired, not logged
	static get CODES_BAD_PERMS()          { return 403; } //Business logic permissions on resources
	static get CODES_NOT_FOUND()          { return 404; }
	static get CODES_METHOD_NOT_ALLOWED() { return 405; } //Ex trying to DELETE something we can't
	static get CODES_SERVER_ERROR()       { return 500; }
	
	//From server's HttpUtils::die_x()
	static get ERR_TYPE_SERVER_UNHANDLED_EXCEPTION()    { return 'server_unhandledException';   }
	static get ERR_TYPE_SERVER_CORE_ERROR()             { return 'server_coreError';            }
	static get ERR_TYPE_REQUEST_UNKNOWN()               { return 'request_unknown';             }
	static get ERR_TYPE_REQUEST_PATH_VARS()             { return 'request_pathVars';            }
	static get ERR_TYPE_REQUEST_HEADERS()               { return 'request_headers';             }
	static get ERR_TYPE_REQUEST_CONTENT_TYPE()          { return 'request_contentType';         }
	static get ERR_TYPE_REQUEST_BODY()                  { return 'request_body';                }
	static get ERR_TYPE_AUTH_WRONG_FORMAT()             { return 'auth_wrongFormat';            }
	static get ERR_TYPE_AUTH_NOT_LOGGED()               { return 'auth_notLogged';              }
	static get ERR_TYPE_AUTH_WRONG_LOGIN()              { return 'auth_wrongLogin';             }
	static get ERR_TYPE_AUTH_RECOVERY_EMAIL()           { return 'auth_recoveryEmail';          }
	static get ERR_TYPE_AUTH_USER_NOT_FOUND()           { return 'auth_userNotFound';           }
	static get ERR_TYPE_AUTH_DISABLED_USER()            { return 'auth_disabledUser';           }
	static get ERR_TYPE_AUTH_RESETTING_PWD()            { return 'auth_resettingPwd';           }
	static get ERR_TYPE_AUTH_TOKEN_NOT_FOUND()          { return 'auth_tokenNotFound';          }
	static get ERR_TYPE_AUTH_TOKEN_EXPIRED()            { return 'auth_tokenExpired';           }
	static get ERR_TYPE_RES_PERMS()                     { return 'res_perms';                   }
	static get ERR_TYPE_RES_DOWNLOAD_HASH()             { return 'res_downloadHash';            }
	static get ERR_TYPE_RES_NOT_FOUND()                 { return 'res_notFound';                }
	static get ERR_TYPE_RES_NO_USER()                   { return 'res_noUser';                  }
	static get ERR_TYPE_RES_NO_ENTITY()                 { return 'res_noEntity';                }
	static get ERR_TYPE_RES_METHOD_NOT_ALLOWED_DELETE() { return 'res_methodNotAllowed_delete'; }
	static get ERR_TYPE_RES_METHOD_NOT_ALLOWED_OTHER()  { return 'res_methodNotAllowed_other';  }
		//IMPORTANT: If we add new, also do in errorType_isX() helpers here + backend's HttpUtils
	
	static get HEADERS_B_REST_ERROR_MSG() { return "x-b-rest-error-msg"; }
	static get HEADERS_B_REST_IS_DUMP()   { return "x-b-rest-is-dump";   }
	
	
	//General
		_request          = null; //The original B_REST_Request_base
		_code             = null;
		_headers          = null; //All received headers, including already used ones
		_data             = null; //NOTE: Filled when #200, or any server-side err (frontend errs won't show up here). Contains either an obj (application/json), string (text/[plain|html]) or blob (B_REST_Response.fetchAsBlob)
		_data_contentType = null; //Only filled if we have data
		_lang             = null; //Content-Language header, if any
		_errorMsg         = null; //When !isSuccess, content of server-side data, or some other custom error msg
		_errorType        = null; //When !isSuccess, const of ERR_TYPE_x
	//bREST core headers in PHP
		_debug_errorMsg = null; //For "X-B-REST-Error-Msg". Check _parseCustomHeader_Error_Msg() for details on output. As string
		_debug_isDump   = null; //For "X-B-REST-Is-Dump".   Check _parseCustomHeader_Is_Dump() for details on output. As bool
	
	
	constructor(code, headers, data)
	{
		this._code    = code;
		this._headers = headers;
		this._data    = data;
		
		//Check for bREST core headers in PHP. NOTE: For these to always show up, requires header("Access-Control-Expose-Headers: *"); in PHP
		{
			if (this._headers[B_REST_Response.HEADERS_B_REST_ERROR_MSG]) { this._debug_errorMsg = B_REST_Response._parseCustomHeader_Error_Msg(this._headers[B_REST_Response.HEADERS_B_REST_ERROR_MSG]); }
			if (this._headers[B_REST_Response.HEADERS_B_REST_IS_DUMP])   { this._debug_isDump   = B_REST_Response._parseCustomHeader_Is_Dump(this._headers[B_REST_Response.HEADERS_B_REST_IS_DUMP]);     }
		}
		
		this._data_contentType = this._headers["content-type"]     ? this._headers["content-type"].split(";")[0] : B_REST_Utils.CONTENT_TYPE_EMPTY; //Drop charset, ex "text/plain; charset=utf-8"
		this._lang             = this._headers["content-language"] ? this._headers["content-language"]           : null;						
		
		//If we were supposed to get JSON, make sure we didn't get something like "E_NOTICE: stuff happened\n<actual JSON>"
		if (this.data_contentType_is_badJSON) { this._code=B_REST_Response.CODES_SERVER_ERROR; }
		
		//IMPORTANT: Must then call finalize() later before trying to mess w the error
	}
		static from_axios_result(axios_result) { return new B_REST_Response(axios_result.status, axios_result.headers, axios_result.data); }
		static from_err(code, msg)             { return new B_REST_Response(code,                {},                   msg);               }
		/*
		Fetches a JSON file. Expect to receive a import(jsonFile) as the 1st arg, where we can await receiving it and get its root node (.default)
		Ex:
			from_mockJsonFile( import("someMockDir/test.json") )
			from_mockJsonFile( import("someMockDir/subStuff/test.json") )
				-> yields an obj
		NOTE:
			We can't really fix the method to just receive a path, because webpack can't import stuff if it doesn't have a known dir to "listen to"
				import(/ * webpackMode: "eager" * / "../someRealPath/"+subPath)) -> OK
				import(/ * webpackMode: "eager" * / fullPath))                   -> File not found
					(Remove the spaces between "/ *" and "* /")
		*/
		static async from_mockJsonFile(jsonFileImport, code=200, headers={"content-type":B_REST_Utils.CONTENT_TYPE_JSON})
		{
			let data = null;
			
			try
			{
				data = (await jsonFileImport).default;
			}
			catch (e)
			{
				B_REST_Utils.throwEx(`Got err using mock json file. If it says "Cannot find module ...", then JSON is prolly invalid (ex trailing ","): ${e}`);
			}
			
			return new B_REST_Response(code, headers, data);
		}
		//Alias of cache_from(). Check its docs
		static from_cache(cacheObj) { return B_REST_Response.cache_from(cacheObj); }
	
	
	
	//Checks to convert successful response in err under certain situations + fill errorMsg prop if required
	finalize()
	{
		if (!this.isSuccess)
		{
			if      (this.data_contentType_is_badJSON)                   { this._errorMsg = `<${B_REST_Response.ERR_TYPE_REQUEST_CONTENT_TYPE}>\nResponse didn't ret JSON:\n${this._data}`; }
			else if (this._data!==null && !(this._data instanceof Blob)) { this._errorMsg = this._data.toString().replace(/\\n/g,"\n");                                                     } //NOTE: .toString(), because ex if we do "echo 123;" then it'll automatically be casted as number... Also works with bools
			else if (this._debug_errorMsg!==null)                        { this._errorMsg = this._debug_errorMsg;                                                                           }
			else                                                         { this._errorMsg = `<${B_REST_Response.ERR_TYPE_SERVER_CORE_ERROR}>Unknown error`;                                 }
			
			const errorTypeRegexResult = this._errorMsg.match(/^<([^>]+)>/);
			this._errorType = errorTypeRegexResult ? errorTypeRegexResult[1] : B_REST_Response.ERR_TYPE_SERVER_CORE_ERROR;
		}
		else if (this._debug_isDump)
		{
			//We could switch the code to B_REST_Response.CODES_BAD_REQUEST, but since we have debug_isDump(), then B_REST_API can correctly handle this
		}
		else if (this._request.expectsContentType===B_REST_Utils.CONTENT_TYPE_EMPTY && this.isSuccess_empty)
		{
			//Do this mainly to prevent falling in the branch below, since 204 has a "text/plain" content-type instead of fake "<empty>"
		}
		else if (!B_REST_Utils.contentType_matches(this._request.expectsContentType,this._data_contentType))
		{
			this._code      = B_REST_Response.CODES_BAD_REQUEST;
			this._errorType = B_REST_Response.ERR_TYPE_REQUEST_CONTENT_TYPE;
			this._errorMsg  = `<${this._errorType}>\nExpected "${this._request.expectsContentType}", got "${this._data_contentType}".\nIf making a #204 request, do request.expectsContentType_empty()`;
		}
	}
	
	
	
	set request(val) { this._request=val;    } //NOTE: Could have been in constructor, but doing like this makes integration simpler with from_x() factory funcs
	get request()    { return this._request; }
	
	get code() { return this._code; }
		//NOTE: Also check errorType_isX() for detailed errs
		get isSuccess()          { return this._code===B_REST_Response.CODES_OK_NON_EMPTY || this._code===B_REST_Response.CODES_OK_EMPTY; }
		get isSuccess_nonEmpty() { return this._code===B_REST_Response.CODES_OK_NON_EMPTY;       }
		get isSuccess_empty()    { return this._code===B_REST_Response.CODES_OK_EMPTY;           }
		get isBadRequest()       { return this._code===B_REST_Response.CODES_BAD_REQUEST;        } //Body, header, request unknown, etc
		get isBadAuth()          { return this._code===B_REST_Response.CODES_BAD_AUTH;           } //Bad login, disabled user, wrong token / expired, not logged
		get isBadPerms()         { return this._code===B_REST_Response.CODES_BAD_PERMS;          } //Business logic permissions on resources
		get isNotFound()         { return this._code===B_REST_Response.CODES_NOT_FOUND;          }
		get isMethodNotAllowed() { return this._code===B_REST_Response.CODES_METHOD_NOT_ALLOWED; } //Ex trying to DELETE something we can't
		get isServerError()      { return this._code >=B_REST_Response.CODES_SERVER_ERROR;       }
	
	get headers() { return this._headers; }
	
	get data() { return this._data; }
	
	get data_contentType() { return this._data_contentType; }
		get data_contentType_is_empty()   { return this._data_contentType===B_REST_Utils.CONTENT_TYPE_EMPTY; }
		get data_contentType_is_text()    { return this._data_contentType===B_REST_Utils.CONTENT_TYPE_TEXT;  }
		get data_contentType_is_HTML()    { return this._data_contentType===B_REST_Utils.CONTENT_TYPE_HTML;  }
		get data_contentType_is_JSON()    { return this._data_contentType===B_REST_Utils.CONTENT_TYPE_JSON;  }
		get data_contentType_is_badJSON() { return this.data_contentType_is_JSON && !(this._data===null||B_REST_Utils.array_is(this._data)||B_REST_Utils.object_is(this._data)); }
		get data_contentType_is_file()    { return ![B_REST_Utils.CONTENT_TYPE_EMPTY,B_REST_Utils.CONTENT_TYPE_TEXT,B_REST_Utils.CONTENT_TYPE_HTML,B_REST_Utils.CONTENT_TYPE_JSON].includes(this._data_contentType); } //Not accurate but...
	
	get lang() { return this._lang; }
	
	get errorMsg()  { return this._errorMsg; }
	
	get errorType() { return this._errorType; }
	get errorType_isServer_unhandledException()   { return this._errorType===B_REST_Response.ERR_TYPE_SERVER_UNHANDLED_EXCEPTION;    }
	get errorType_isServer_coreError()            { return this._errorType===B_REST_Response.ERR_TYPE_SERVER_CORE_ERROR;             }
	get errorType_isRequest_unknown()             { return this._errorType===B_REST_Response.ERR_TYPE_REQUEST_UNKNOWN;               }
	get errorType_isRequest_pathVars()            { return this._errorType===B_REST_Response.ERR_TYPE_REQUEST_PATH_VARS;             }
	get errorType_isRequest_headers()             { return this._errorType===B_REST_Response.ERR_TYPE_REQUEST_HEADERS;               }
	get errorType_isRequest_contentType()         { return this._errorType===B_REST_Response.ERR_TYPE_REQUEST_CONTENT_TYPE;          }
	get errorType_isRequest_body()                { return this._errorType===B_REST_Response.ERR_TYPE_REQUEST_BODY;                  }
	get errorType_isAuth_wrongFormat()            { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_WRONG_FORMAT;             }
	get errorType_isAuth_notLogged()              { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_NOT_LOGGED;               }
	get errorType_isAuth_wrongLogin()             { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_WRONG_LOGIN;              }
	get errorType_isAuth_recoveryEmail()          { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_RECOVERY_EMAIL;           }
	get errorType_isAuth_userNotFound()           { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_USER_NOT_FOUND;           }
	get errorType_isAuth_disabledUser()           { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_DISABLED_USER;            }
	get errorType_isAuth_resettingPwd()           { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_RESETTING_PWD;            }
	get errorType_isAuth_tokenNotFound()          { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_TOKEN_NOT_FOUND;          }
	get errorType_isAuth_tokenExpired()           { return this._errorType===B_REST_Response.ERR_TYPE_AUTH_TOKEN_EXPIRED;            }
	get errorType_isRes_perms()                   { return this._errorType===B_REST_Response.ERR_TYPE_RES_PERMS;                     }
	get errorType_isRes_downloadHash()            { return this._errorType===B_REST_Response.ERR_TYPE_RES_DOWNLOAD_HASH;             }
	get errorType_isRes_notFound()                { return this._errorType===B_REST_Response.ERR_TYPE_RES_NOT_FOUND;                 }
	get errorType_isRes_noUser()                  { return this._errorType===B_REST_Response.ERR_TYPE_RES_NO_USER;                   }
	get errorType_isRes_noEntity()                { return this._errorType===B_REST_Response.ERR_TYPE_RES_NO_ENTITY;                 }
	get errorType_isRes_methodNotAllowed_delete() { return this._errorType===B_REST_Response.ERR_TYPE_RES_METHOD_NOT_ALLOWED_DELETE; }
	get errorType_isRes_methodNotAllowed_other()  { return this._errorType===B_REST_Response.ERR_TYPE_RES_METHOD_NOT_ALLOWED_OTHER;  }
	
	//NOTE: The following are NOT set if we didn't reach endpoint
	get debug_errorMsg() { return this._debug_errorMsg; }
	get debug_isDump()   { return this._debug_isDump;   }
	debug_replaceAppHTML()
	{
		B_REST_Utils.documentBody.innerHTML = `<div style="overflow-x:scroll; margin:16px; padding:16px; border:1px solid grey;">
			                                       <h1 class="text-h1">Dump for call ${this.request.url_debug}</h1>
			                                       ${this.data}
		                                       </div>`;
	}
	
	//Helpers ex to use in B_REST_API's afterCall_debug_handler() callback hook
	get logMsg_data()
	{
		return this.isSuccess ? `OK for ${this._request.url_debug}` : `Got err #${this._code} for ${this._request.url_debug}:\n${this._errorMsg}`;
	}
	get logMsg_debug_errorMsg()
	{
		return `Got err #${this._code} for ${this._request.url_debug}:\n${this._debug_errorMsg}`;
	}
	
	/*
	Ex "cv", "docs" or "subThing.logo", for a model like:
		{
			firstName,
			lastName,
			cv:{...},
			docs:[{...}],
			subThing: {
				logo:{...}
			}
		}
	Check B_REST_Utils.parseFieldNamePath() & server side Model_base::_field_parseFieldNamePath() for dot notation
	Rets UNDEFINED if not set
	Throws if we get something that doesn't make sense against the response struct (ex trying to access arr pos when it's not an arr)
	skipFirstItemNode:
		Because most calls returning a single ent are like {item:{}, _sharedLists_:{}}, so do we want to step to the right sub node or not
	*/
	data_getFieldData(fieldNamePath, skipFirstItemNode=true)
	{
		B_REST_Utils.object_assert(this._data); //Might start as an arr as well; don't support these cases
		
		return B_REST_Response._data_getFieldData_nest(skipFirstItemNode?this._data.item:this._data, fieldNamePath);
	}
		static _data_getFieldData_nest(objLvl, fieldNamePath)
		{
			const {self_fieldName, atIdx, target_fieldNameOrExpr} = B_REST_Utils.parseFieldNamePath(fieldNamePath);
			
			if (!B_REST_Utils.object_hasPropName(objLvl,self_fieldName)) { return undefined; }
			let selfFieldVal = objLvl[self_fieldName];
			
			if (atIdx!==null)
			{
				B_REST_Utils.array_assert(selfFieldVal);
				selfFieldVal = selfFieldVal[atIdx];
			}
			
			if (target_fieldNameOrExpr) { return B_REST_Response._data_getFieldData_nest(selfFieldVal,target_fieldNameOrExpr); }
			return selfFieldVal;
		}
	
	
	/*
	Custom "X-B-REST-Error-Msg" header in bREST (PHP)
	Something like "Unhandled exception / core error: \\nDescriptor_Entity_base: Load options couldn't find any required match:\\nthrowEx details:\\nModelList_LoadResult Object..."
	Yields:
		"Unhandled exception / core error: 
		 Descriptor_Entity_base: Load options couldn't find any required match:
		 throwEx details:
		 ModelList_LoadResult Object..."
	*/
	static _parseCustomHeader_Error_Msg(val)
	{
		if (!val) { return null; }
		return B_REST_Response._parseCustomHeader_removeDoubleBackslashes(val);
	}
	y/*
	Custom "X-B-REST-Is-Dump" header in bREST (PHP)
	For now, always "html".
	When present, states that we explicitely ended the script with a Model_base::dump(), Model_base::dump_list() or ModelList::dumpList(),
		so that it contains text/html instead of a usual application/json or file.
	We can use that for ex to dump the html directly in the app
	*/
	static _parseCustomHeader_Is_Dump(val)
	{
		if (val!=="html") { B_REST_Utils.throwEx(`For X-B-REST-Is-Dump, expected "html", got "${val}"`); }
		return true;
	}
		//Since headers can't have multiple lines, we over-backslashed \n & \t, so now put them back to normal
		static _parseCustomHeader_removeDoubleBackslashes(val) { return val.replace(/\\n/g, "\n").replace(/\\t/g, "\t"); }
	
	
	
	//CACHE RELATED
		/*
		For cache_from()
		IMPORTANT:
			-Doesn't preserve the original B_REST_Request_base instance
			-For now, doesn't work with Blob data
			-Will throw if we yield a circular JSON, but shouldn't happen if it comes from server
		NOTES:
			-Works even if request isn't successful
			-No need to include data_contentType, lang & debug_x, because they are comprised in headers
		*/
		cache_to()
		{
			if (this._data instanceof Blob) { B_REST_Utils.throwEx(`Can't cache a Blob response`,this); }
			
			return {
				code:     this._code,
				headers:  this._headers,
				data:     this._data,
				errorMsg: this._errorMsg,
			};
		}
		//Check cache_to() docs. Also have a from_cache() alias
		static cache_from(cacheObj)
		{
			const response = new B_REST_Response(cacheObj.code, cacheObj.headers, cacheObj.data);
			response._errorMsg = cacheObj.errorMsg;
			response._request  = null;
			
			return response;
		}
};
