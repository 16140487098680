
import { B_REST_Utils } from "../../../../classes";


export class B_REST_Vuetify_ToasterManager
{
	static get DELAY_BETWEEN() { return 250; }
	
	_queue            = [];
	_isShowing        = false;
	_current          = null;
	_alsoLogToConsole = false;
	
	
	constructor(alsoLogToConsole)
	{
		this._alsoLogToConsole = alsoLogToConsole;
	}
	
	
	push(msg, color, pos)
	{
		if (this._alsoLogToConsole) { B_REST_Utils.console_info(`B_REST_Vuetify_ToasterManager: ${msg}`); }
		
		this._queue.push(new B_REST_Vuetify_ToasterManager_Item(msg,color,pos));
		if (!this._isShowing) { this.next(false); }
	}
	
	get isShowing() { return this._isShowing; }
	set isShowing(val)
	{
		this._isShowing = val;
		if (!this._isShowing) { this.next(true); }
	}
	
	get current() { return this._current; }
	
	async next(delayed)
	{
		if (this._queue.length > 0)
		{
			if (delayed) { await B_REST_Utils.sleep(B_REST_Vuetify_ToasterManager.DELAY_BETWEEN); } //Necessary, even 0msec, to let Vue repaint changing _isShowing to true->false->true
			
			this._current   = this._queue.shift();
			this._isShowing = true;
		}
		else
		{
			//NOTE: Don't set this._current NULL, as while it's disappearing with !this._isShowing, we'd lose its msg, color and pos info, so fading out box will flicker in screen
			this._isShowing = false;
		}
	}
};



export class B_REST_Vuetify_ToasterManager_Item
{
	static get POS_TOP()    { return "top";    }
	static get POS_RIGHT()  { return "right";  }
	static get POS_BOTTOM() { return "bottom"; }
	static get POS_LEFT()   { return "left";   }
	
	static get DURATION_EVAL()          { return null; }
	static get DURATION_EVAL_MIN()      { return 2500; }
	static get DURATION_EVAL_PER_CHAR() { return 75;   }
	
	msg      = null;
	color    = null;
	pos      = null; //One of B_REST_Vuetify_ToasterManager_Item.POS_x
	duration = null; //In msec
	
	constructor(msg, color, pos=B_REST_Vuetify_ToasterManager_Item.POS_BOTTOM, duration=B_REST_Vuetify_ToasterManager_Item.DURATION_EVAL)
	{
		if (duration === B_REST_Vuetify_ToasterManager_Item.DURATION_EVAL)
		{
			duration = msg.length * B_REST_Vuetify_ToasterManager_Item.DURATION_EVAL_PER_CHAR; //About 50 msecs per char
			if (duration < B_REST_Vuetify_ToasterManager_Item.DURATION_EVAL_MIN) { duration = B_REST_Vuetify_ToasterManager_Item.DURATION_EVAL_MIN; }
		}
		
		this.msg      = msg;
		this.color    = color;
		this.pos      = pos;
		this.duration = duration;
	}
};
