
import { B_REST_Utils } from "../../../../classes";



export default class B_REST_Vuetify_PickerDef
{
	/*
	For a given picker def intended to use between multiple B_REST_Vuetify_PickerHandle:
		REUSE_MODE_OFF:             Always use independent handles and release them when we're done (best for <BrGenericList> that aren't used in pickers). We will end up with 0 or more active handles for that def at the same time
		REUSE_MODE_IF_NOT_USED:     If we find a handle that's not being used, use it, otherwise temporarily create a second one (best for <BrGenericList> that are used in pickers). We'll end up with 1 or more at the same time. Extra ones are released after
		REUSE_MODE_CANCEL_PREVIOUS: If we find a handle, use it no matter what we were doing with it, canceling prev action (whether canceling previous usage be a good thing or not depends on the situation). Here, we'll always have exactly 1 allocated
	WARNING:
		By "used in pickers", means in <br-field-db picker="SomePickerDefName" /> and also in generic lists filters like:
			export default {
				name: "TestLeadList",
				mixins: B_REST_Vuetify_GenericListBase_createMixin({
					...
					filters: {
						some_fk: {componentAttrs:{picker:"SomePickerDefName"}},
					},
				}),
			};
	*/
	static get REUSE_MODE_OFF()             { return "off";            }
	static get REUSE_MODE_IF_NOT_USED()     { return "ifNotUsed";      }
	static get REUSE_MODE_CANCEL_PREVIOUS() { return "cancelPrevious"; }
		static _REUSE_MODES = [B_REST_Vuetify_PickerDef.REUSE_MODE_OFF, B_REST_Vuetify_PickerDef.REUSE_MODE_IF_NOT_USED, B_REST_Vuetify_PickerDef.REUSE_MODE_CANCEL_PREVIOUS];
	
	
	_name      = null;
	_component = null;  //What should be inside the <v-modal>, so a ()=>import(vue component)
	_reuseMode = null;  //One of REUSE_MODE_x
	//Things that can get overriden in B_REST_Vuetify_pickerHandle that use it
	_default_isModal   = false; //If we are allowed to close it by clicking out of the popup, or if we must make a choice first
	_default_maxWidth  = null;  //Leave NULL to let Vuetify open it the right dimensions (close to 100%, or 100% in mobile mode)
	_default_vBind     = null;  //Stuff to pass as props to the component
	_default_vOn       = null;  //Events to pass to the component
	
	
	constructor(name, options)
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			component:        {accept:undefined, required:true}, //As ()=>import(vue component)
			reuseMode:        {accept:[String],  required:true}, //One of REUSE_MODE_x
			default_isModal:  {accept:[Boolean], default:false},
			default_maxWidth: {accept:[Number],  default:null},
			default_vBind:    {accept:[Object],  default:null},
			default_vOn:      {accept:[Object],  default:null},
		}, "Picker def");
		
		if (!B_REST_Vuetify_PickerDef._REUSE_MODES.includes(options.reuseMode)) { B_REST_Utils.throwEx(`Reuse mode must be one of REUSE_MODE_x`); }
		
		this._name             = name;
		this._component        = options.component;
		this._reuseMode        = options.reuseMode;
		this._default_isModal  = options.default_isModal;
		this._default_maxWidth = options.default_maxWidth;
		this._default_vBind    = options.default_vBind;
		this._default_vOn      = options.default_vOn;
	}
	
	
	get name()             { return this._name;             }
	get component()        { return this._component;        }
	get reuseMode()        { return this._reuseMode;        }
	get default_isModal()  { return this._default_isModal;  }
	get default_maxWidth() { return this._default_maxWidth; }
	get default_vBind()    { return this._default_vBind;    }
	get default_vOn()      { return this._default_vOn;      }
};
