
import { B_REST_Utils } from "../../../../../../classes";

import GFile_base from "../../utils/GFile_base.js";
import { GModel_XField_base, GModel_DBField, GModel_LookupField, GModel_SubModelField, GModel_FileField, GModel_OtherField } from "../GModel_XFields.js";



function empty(val) { return val===null||val===''; }



export default class GFile_PHPModel extends GFile_base
{
	static get TABBED_PROPS() { return `\n\t\t        `; }
	static get TABBED_FUNC()  { return `\n\t`;           }
	
	
	_model = null;
	
	constructor(model)
	{
		super();
		
		this._model = model;
	}
	
	
	get _abstract_title()    { return `PHP Model`; }
	get _abstract_filePath() { return `SERVER/custom/<projName>/models/${this._model.modelClassName}.php`; }
	
	
	
	outputPKs()
	{
		if (this._model.pks_isFallbackAutoIncPK) { return ''; }
		
		const pkArrayContents = this._model.pks.map(loop_pk => `"${loop_pk.fieldName}"=>FieldDescriptor_DB::TYPE_${loop_pk.type.toUpperCase()}`).join(",");
		
		let output = `, $pkFields=array(${pkArrayContents})`;
		
		if (!this._model.isAutoInc) { output+=`, $isAutoInc=false`; }
		
		return output;
	}
	
	static _outputXField_comments(xField) { return !empty(xField.comments) ? `//${xField.comments}\n\t\t` : ""; }
	
	outputField_db(dbField)
	{
		const methodName = `_def_${dbField.type}_${dbField.reqType_isReq?'req':'opt'}${dbField.setOnce?'_fixed':''}`; //Ex _def_string_req(), _def_string_opt(), _def_string_req_fixed() or _def_string_opt_fixed()
		
		let nextParams = "";
		let isNumeric = false;
		switch (dbField.type)
		{
			case GModel_DBField.TYPE_STRING: nextParams=GFile_PHPModel._outputField_db_2ndParams_max_defaultVal(dbField,'"'); break;
			case GModel_DBField.TYPE_INT:
				isNumeric  = true;
				nextParams = GFile_PHPModel._outputField_db_2ndParams_defaultVal_only(dbField,'');
			break;
			case GModel_DBField.TYPE_DECIMAL:
				isNumeric = true;
				const decimals = !empty(dbField.decimals) && dbField.decimals!=2 ? dbField.decimals : null;
				if (!empty(dbField.optionalVal) || decimals) { nextParams += `, ${dbField.optionalVal!==null?dbField.optionalVal:'null'}`; }
				if (decimals)                                { nextParams += `, ${decimals}`;                                              }
			break;
			case GModel_DBField.TYPE_BOOL:
				if (!empty(dbField.optionalVal)) { nextParams += `, ${dbField.optionalVal?'true':'false'}`; }
			break;
			case GModel_DBField.TYPE_JSON: nextParams=GFile_PHPModel._outputField_db_2ndParams_defaultVal_only(dbField,'');  break;
			case GModel_DBField.TYPE_DT:   nextParams=GFile_PHPModel._outputField_db_2ndParams_defaultVal_only(dbField,'"'); break;
			case GModel_DBField.TYPE_D:    nextParams=GFile_PHPModel._outputField_db_2ndParams_defaultVal_only(dbField,'"'); break;
			case GModel_DBField.TYPE_T:    nextParams=GFile_PHPModel._outputField_db_2ndParams_defaultVal_only(dbField,'"'); break;
			case GModel_DBField.TYPE_ENUM:
				const enumPrefix    = `${this._model.Model_ClassName}::${dbField.fieldName.toUpperCase()}_`; //Ex Model_Contact::TYPE_
				const arrayContents = dbField.enumVals.map(loop_enumMember => this._outputField_db_getEnumMemberConstName(dbField,loop_enumMember,true)).join(",");
				nextParams = `, array(${arrayContents})`;
				if (dbField.optionalVal) { nextParams += `, ${this._outputField_db_getEnumMemberConstName(dbField,dbField.optionalVal,true)}`; }
			break;
			case GModel_DBField.TYPE_PHONE:  nextParams=GFile_PHPModel._outputField_db_2ndParams_max_defaultVal(dbField,'"');  break;
			case GModel_DBField.TYPE_EMAIL:  nextParams=GFile_PHPModel._outputField_db_2ndParams_max_defaultVal(dbField,'"');  break;
			case GModel_DBField.TYPE_PWD:    nextParams=GFile_PHPModel._outputField_db_2ndParams_max_defaultVal(dbField,'"');  break;
			case GModel_DBField.TYPE_CUSTOM: nextParams=GFile_PHPModel._outputField_db_2ndParams_defaultVal_only(dbField,'"'); break;
			default: B_REST_Utils.throwEx(`Unknown type "${dbField.type}"`); break;
		}
		
		let definition = `$this->${methodName}('${dbField.fieldName}'${nextParams})`;
		
		if (!empty(dbField.min))              { definition += `->min(${dbField.min})`; }
		if (!empty(dbField.max) && isNumeric) { definition += `->max(${dbField.max})`; } //NOTE: We should accept for dates too
		
		switch (dbField.wCustomSetter)
		{
			case GModel_XField_base.W_CUSTOM_SETTER_OFF:
				//Do nothing
			break;
			case GModel_XField_base.W_CUSTOM_SETTER_TWEAK_VAL:
				definition += `${GFile_PHPModel.TABBED_PROPS}->wCustomSetter_tweakVal(function(model,nonNullVal){ /* Ex format received val before applying it */ })`;
			break;
			case GModel_XField_base.W_CUSTOM_SETTER_BLOCK_VAL:
				definition += `->wCustomSetter_blockVal()`;
			break;
			default: B_REST_Utils.throwEx(`Unknown wCustomSetter "${dbField.wCustomSetter}"`); break;
		}
		
		if (dbField.onChanged) { definition += `${GFile_PHPModel.TABBED_PROPS}->func_onChanged_add(function(Model_base $model,$fieldName,$oldVal,$newVal){ /* Ex recalc a sum */ })`; }
		
		if (dbField.type===GModel_DBField.TYPE_CUSTOM)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->custom_func_validity(function($nonNullVal)         { /* Ex: return is_array($nonNullVal) ? true : "Must be an arr"; */ })`;
			definition += `${GFile_PHPModel.TABBED_PROPS}->custom_func_fromDB(function($nonNullDBVal)         { /* Ex: return explode('|',$nonNullDBVal));                     */ })`;
			definition += `${GFile_PHPModel.TABBED_PROPS}->custom_func_toDB(function($nonNullVal)             { /* Ex: return implode('|',$nonNullVal);                        */ })`;
			definition += `${GFile_PHPModel.TABBED_PROPS}->custom_func_toObj(function($nonNullVal)            { /* Ex: return $nonNullVal;                                     */ })`;
			definition += `${GFile_PHPModel.TABBED_PROPS}->custom_func_fromObj(function($nonNullValidatedVal) { /* Ex: return $nonNullValidatedVal;                            */ })`;
		}
		
		definition += `;`;
		
		return GFile_PHPModel._outputXField_comments(dbField) + definition;
	}
		_outputField_db_getEnumMemberConstName(dbField, enumMember, withClassPrefix)
		{
			const classPrefix = withClassPrefix ? `${this._model.Model_ClassName}::` : "";
			
			return `${classPrefix}${dbField.fieldName.toUpperCase()}_${enumMember.tag?.toUpperCase()??''}`; //Ex Model_Contact::TYPE_BOB
		}
		static _outputField_db_2ndParams_defaultVal_only(dbField, delimiter)
		{
			return dbField.optionalVal!==null ? `, ${delimiter}${dbField.optionalVal}${delimiter}` : '';
		}
		static _outputField_db_2ndParams_max_defaultVal(dbField, delimiter)
		{
			let nextParams = '';
			
			if (!empty(dbField.max) || dbField.optionalVal!==null) { nextParams += `, ${!empty(dbField.max)?dbField.max:'null'}`;      }
			if (dbField.optionalVal!==null)                        { nextParams += `, ${delimiter}${dbField.optionalVal}${delimiter}`; }
			
			return nextParams;
		}
	outputField_lookup(lookupField)
	{
		const reqSuffix = lookupField.reqType_isReq ? "req" : "opt";
		let methodName  = null;
		
		switch (lookupField.type)
		{
			case GModel_LookupField.TYPE_PRIVATE:         methodName=`_def_privLookup_${reqSuffix}`;         break;
			case GModel_LookupField.TYPE_SHARED:          methodName=`_def_sharedLookup_${reqSuffix}`;       break;
			case GModel_LookupField.TYPE_SHARED_SET_ONCE: methodName=`_def_sharedLookup_${reqSuffix}_fixed`; break;
			default: B_REST_Utils.throwEx(`Unknown type "${lookupField.type}"`); break;
		}
		
		return GFile_PHPModel._outputXField_comments(lookupField) + `$this->${methodName}('${lookupField.fieldName}', 'Model_${lookupField.modelClassName}', '${lookupField.fkFieldName}');`;
	}
	outputField_subModel(subModelField)
	{
		let methodName = null;
		
		switch (subModelField.type)
		{
			case GModel_SubModelField.TYPE_SINGLE_REQ: methodName="_def_subModel_req"; break;
			case GModel_SubModelField.TYPE_SINGLE_OPT: methodName="_def_subModel_opt"; break;
			case GModel_SubModelField.TYPE_LIST_OPT:   methodName="_def_subModelList"; break;
			default: B_REST_Utils.throwEx(`Unknown type "${subModelField.type}"`); break;
		}
		
		return GFile_PHPModel._outputXField_comments(subModelField) + `$this->${methodName}('${subModelField.fieldName}', 'Model_${subModelField.subModel_info?.modelClassName}', '${subModelField.subModel_fkFieldName}');`;
	}
	outputField_file(fileField)
	{
		let methodName = null;
		
		switch (fileField.type)
		{
			case GModel_FileField.TYPE_SINGLE_REQ: methodName="_def_file_req"; break;
			case GModel_FileField.TYPE_SINGLE_OPT: methodName="_def_file_opt"; break;
			case GModel_FileField.TYPE_LIST:       methodName="_def_fileList"; break;
			default: B_REST_Utils.throwEx(`Unknown type "${fileField.type}"`); break;
		}
		
		let definition = `$this->${methodName}('${fileField.fieldName}')->maxFileSize_mb(${fileField.maxFileSize})`;
		
		if (fileField.type===GModel_FileField.TYPE_LIST)
		{
			definition += fileField.maxFileCount ? `->maxFileCount(${fileField.maxFileCount})` : `->maxFileCount_noLimit()`;
		}
		
		if (fileField.allowedTypes)          { definition += `->allowedTypes_${fileField.allowedTypes}()`; }
		if (fileField.softDelete)            { definition += `->softDelete()`;                             }
		if (fileField.customDisplayNameFunc) { definition += `${GFile_PHPModel.TABBED_PROPS}->displayNameWOExt_func(function(Model_base $model,$ext,$lang){ /* Ex return $lang==='en' ? "Invoice.{$ext}" : "Facture.{$ext}"; */ })`; }
		
		definition += `;`;
		
		return GFile_PHPModel._outputXField_comments(fileField) + definition;
	}
	outputField_other(otherField)
	{
		let definition = `$this->_def_other('${otherField.fieldName}')`;
		
		if (otherField.func_load===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_load_set(function(Model_base $model,$fieldName,ModelOptions_Load $loadOptions)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        /* Probably do some DB query or other calc */`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        $model->field_set('${otherField.fieldName}', 'something');`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		if (otherField.func_save===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_save_set(function(Model_base $model,$fieldName, ModelOptions_Save $saveOptions)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        return false; //Must ret if we saved something`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		if (otherField.func_toObj===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_toObj_set(function(Model_base $model,$fieldName,&$obj,ModelOptions_ToObj $toObjOptions)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        $obj['${otherField.fieldName}'] = $model->field_get('${otherField.fieldName}');`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		if (otherField.func_fromObj===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_fromObj_set(function(Model_base $model,$fieldName,$obj)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        $model->field_set('${otherField.fieldName}', $obj['${otherField.fieldName}']);`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		if (otherField.func_unsavedChanges_has===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_unsavedChanges_has_set(function(Model_base $model,$fieldName)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        return false;`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		if (otherField.func_delete_can_orMsg===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_delete_can_orMsg_set(function(Model_base $model,$fieldName)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        return "Can't delete because..."; //Ret true or an err msg`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		if (otherField.func_delete===GModel_OtherField.FUNC_TYPE_FUNC)
		{
			definition += `${GFile_PHPModel.TABBED_PROPS}->func_delete_set(function(Model_base $model,$fieldName)`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    {`;
			definition += `${GFile_PHPModel.TABBED_PROPS}        /* Delete stuff */`;
			definition += `${GFile_PHPModel.TABBED_PROPS}    })`;
		}
		
		definition += `;`;
		
		return GFile_PHPModel._outputXField_comments(otherField) + definition;
	}
	outputIndex(index, offset)
	{
		const fieldCount = index.fields.length;
		if (fieldCount===0) { return; }
		
		const methodName          = index.isUnique ? "_def_uniqueKey" : "_def_index";
		const fields              = index.fields.map(loop_field=>`'${loop_field.fkFieldName||loop_field.fieldName}'`).join(",");
		const singleOrArrContents = fieldCount=== 1 ? fields : `array(${fields})`;
		
		return `$this->${methodName}(${singleOrArrContents}, ${offset});`;
	}
	outputConsts()
	{
		const lines = [];
		
		for (const loop_dbField of this._model.dbFields)
		{
			if (!loop_dbField.type_isEnum) { continue; }
			if (lines.length>0) { lines.push(""); }
			
			for (const loop_enumMember of loop_dbField.enumVals)
			{
				const loop_constName = this._outputField_db_getEnumMemberConstName(loop_dbField,loop_enumMember,false);
				
				lines.push(`const ${loop_constName} = "${loop_enumMember.tag}";`);
			}
		}
		
		return lines.length>0 ? `${lines.join("\n\t")}\n\t` : "";
	}
	outputHooks()
	{
		const lines = [];
		
		if (this._model.hook_load_after)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_load");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_load_after(ModelOptions_Load $loadOptions=null)`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    //Load more stuff${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_save_before)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_save");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_save_before(ModelOptions_Save $saveOptions=null)`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    return false; //Must ret if we've just saved something${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_save_after)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_save");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_save_after($wasNew, $savedSomething, ModelOptions_Save $saveOptions=null)`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    return false; //Must ret if we've just saved something${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_delete_can_orMsg)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_delete_can_orMsg");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_delete_can_orMsg()`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    return true; //Must ret true or an err msg${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_delete_before)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_delete");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_delete_before()`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    //Check to del stuff prior to del the main record (exluding files)${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_delete_after)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_delete");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_delete_after()`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    //Check to del stuff after having deleted the main record (exluding files)${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_fromObj_before)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_fromObj");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_fromObj_before(&$obj, ModelOptions_FromObj $fromObjOptions=null)`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    //Usually for tweaking the received obj${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_fromObj_after)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_fromObj");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_fromObj_after($obj, ModelOptions_FromObj $fromObjOptions=null)`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    //Set more stuff after all main fields are set${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		if (this._model.hook_toObj_after)
		{
			const manualFieldNamesComment = this._model.otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput("func_toObj");
			
			let definition = `${GFile_PHPModel.TABBED_FUNC}protected function _hook_toObj_after(&$obj, ModelOptions_ToObj $toObjOptions=null)`;
			definition += `${GFile_PHPModel.TABBED_FUNC}{`;
			definition += `${GFile_PHPModel.TABBED_FUNC}    //Tweak the $obj after main stuff got added${manualFieldNamesComment}`;
			definition += `${GFile_PHPModel.TABBED_FUNC}})`;
			lines.push(definition);
		}
		
		return lines.length>0 ? `${lines.join("\n\t")}\n\t` : "";
	}
	
	outputRequiredModels()
	{
		const modelNames = [];
		
		for (const loop_field of this._model.lookupFields)   { modelNames.push(loop_field.modelClassName);                        }
		for (const loop_field of this._model.subModelFields) { modelNames.push(loop_field.subModel_info?.modelClassName ?? null); }
		
		B_REST_Utils.array_unique(modelNames);
		
		const lines = [];
		for (const loop_modelName of modelNames) { lines.push(`bREST_Custom::model_include_once("${loop_modelName}.php");`); }
		return lines.length>0 ? lines.join("\n") : "";
	}
	
	_abstract_output()
	{
		const requiredIncludes = this.outputRequiredModels();
		const Descriptor_Name  = `Descriptor_${this._model.modelClassName}`;
		
		const pksAndAutoInc = this.outputPKs();
		const consts        = this.outputConsts();
		const hooks         = this.outputHooks();
		
		const options = [];
			if (this._model.apiBaseUrl_list)        { options.push(`$this->_def_apiBaseUrl('${this._model.apiBaseUrl_list}', ${this._model.apiBaseUrl_needsAccessToken?'true':'false'});`); }
			if (this._model.toLabel_fieldNamePaths) { options.push(`$this->_def_toLabel_fieldNamePaths('${this._model.toLabel_fieldNamePaths}');`);                                         }
			if (this._model.hasSoftDel)             { options.push(`$this->_def_softDeleteDT("\`deletedDT\`");`);                                                                           }
			if (this._model.hasCreatedDT)           { options.push(`$this->_def_createdDT('createdDT', $inDB_is_DEFAULT_CURRENT_TIMESTAMP=true);`);                                         }
			if (this._model.hasUpdatedDT)           { options.push(`$this->_def_updatedDT('updatedDT', $inDB_is_ON_UPDATE_CURRENT_TIMESTAMP=false);`);                                      }
		
		const dbFields       = this._model.dbFields.map(loop_field       => this.outputField_db(loop_field));
		const lookupFields   = this._model.lookupFields.map(loop_field   => this.outputField_lookup(loop_field));
		const subModelFields = this._model.subModelFields.map(loop_field => this.outputField_subModel(loop_field));
		const fileFields     = this._model.fileFields.map(loop_field     => this.outputField_file(loop_field));
		const otherFields    = this._model.otherFields.map(loop_field    => this.outputField_other(loop_field));
		const indexes        = this._model.indexes.map((loop_index,i)    => this.outputIndex(loop_index,i));
		
		const constructorLines = [...options, ...dbFields, ...lookupFields, ...subModelFields, ...fileFields, ...otherFields];
		
		return `<?

${requiredIncludes}

class ${Descriptor_Name} extends Descriptor_base
{
	public function __construct()
	{
		parent::__construct('\`${this._model.tableName}\`', '${this._model.Model_ClassName}'${pksAndAutoInc});
		
		${constructorLines.join("\n\t\t")}
		
		${indexes.join("\n\t\t")}
		
		$this->__construct__finalize();
	}
};



class ${this._model.Model_ClassName} extends Model_base
{
	protected static $_descriptor = null; //NOTE: Check Model_base's docs for this

	${consts}
	public function __construct()
	{
		parent::__construct();
	}
	${hooks}
}; ${this._model.Model_ClassName}::descriptor_init(new ${Descriptor_Name}());

`;
	}
};
