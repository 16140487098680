<template>
	<v-card :elevation="2" class="mx-2 my-4">
		<v-card-title>Output</v-card-title>
		<v-card-text>
			
			<g-code-snippet :file="file_form" />
			<g-code-snippet :file="file_list" />
			<g-code-snippet :file="file_custom" />
			<g-code-snippet v-for="(loop_file_uiLoc,loop_idx) in file_uiLocs" :key="loop_idx" :file="loop_file_uiLoc" />
			
		</v-card-text>
	</v-card>
</template>

<script>
	
	import B_REST_VueApp_base from "../../../../B_REST_VueApp_base.js";
	
	import GForm from "../GForm.js";
	
	import GFile_Custom from "./GFile_Custom.js";
	import GFile_Form   from "./GFile_Form.js";
	import GFile_List   from "./GFile_List.js";
	import GFile_UILoc  from "./GFile_UILoc.js";
	
	
	
	export default {
		props: {
			form: {type:GForm, required:true},
		},
    	data()
		{
			const file_uiLoc_langs = []; //Arr of GFile_UILoc instances
			for (const loop_lang of B_REST_VueApp_base.instance.appLangs) { file_uiLoc_langs.push(new GFile_UILoc(this.form,loop_lang)); }
			
			return {
				file_form:   new GFile_Form(this.form),
				file_list:   new GFile_List(this.form),
				file_custom: new GFile_Custom(this.form),
				file_uiLocs: file_uiLoc_langs,
			};
		},
		components: {
			GCodeSnippet: () => import("../../utils/GCodeSnippet.vue"),
		},
	};
	
</script>