
import B_REST_Utils    from "../B_REST_Utils.js";
import B_REST_App_base from "../../classes/app/B_REST_App_base.js";



export default class B_REST_App_RouteDef_base
{
	static get NAME_LANDPAGE()  { return "_landpage_"; } //Usually "/", but could be diff for each lang
	static get NAME_LOGIN()     { return "_login_";    }
	static get NAME_RESET_PWD() { return "_resetPwd_"; }
	static get NAME_PROFILE()   { return "_profile_";  }
	static get NAME_404()       { return "_404_";      } //To catch all and redirect somewhere else (in that case, we can leave langUrls to NULL)
		/*
		IMPORTANT:
			If we add new ones, also add B_REST_App_base::routeDefs_x() & B_REST_App_base::routes_go_x() + maybe alias in B_REST_VueApp_RouteDef::convertToVueRouteDefObj()
			+ also in server's RouteParser_base::UI_ROUTE_NAMES_x & RouteParser_base::output_json_injectCore_redirect_x()
		*/
	static _SPECIAL_NAMES = [
		B_REST_App_RouteDef_base.NAME_LANDPAGE,
		B_REST_App_RouteDef_base.NAME_LOGIN,
		B_REST_App_RouteDef_base.NAME_RESET_PWD,
		B_REST_App_RouteDef_base.NAME_PROFILE,
		B_REST_App_RouteDef_base.NAME_404,
	];
	
	_name            = null;  //Unique route name throughout all route defs. Could be a const of NAME_x
	_langUrls        = null;  //Map of <lang>:url, ex {fr:"/some/fr/clients/:idClient/factures/:idInvoice/stuff"}
	_layoutComponent = null;  //Depending on used framework, either an import or something else for the layout (component) around the view/form/sheet that is that route
	_viewComponent   = null;  //Same as for the layout, but for the actual component of that route
	_needsAuth       = null;  //Whether we need an access token to go to that route or not
	
	
	constructor(options)
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			name:            {accept:[String],  required:true}, //Name, or const of NAME_x
			langUrls:        {accept:[Object],  required:true},
			layoutComponent: {accept:undefined, required:true},
			viewComponent:   {accept:undefined, required:true},
			needsAuth:       {accept:[Boolean], default:false},
		}, "Route def");
		
		/*
		Make sure we have at least an URL in 1 lang, and that all routes start with "/".
		We don't allow "*" either (if we want a catch-all route, use NAME_404 for ex)
		*/
		{
			if (B_REST_Utils.object_isEmpty(options.langUrls)) { B_REST_Utils.throwEx(`Must have at least one lang URL`,options); }
			
			for (const loop_lang in options.langUrls)
			{
				const loop_url = options.langUrls[loop_lang];
				if (!loop_url || loop_url[0]!=="/") { B_REST_Utils.throwEx(`Lang URLs must start with "/"`,options.langUrls); }
			}
		}
		
		this._name            = options.name;
		this._langUrls        = options.langUrls;
		this._layoutComponent = options.layoutComponent;
		this._viewComponent   = options.viewComponent;
		this._needsAuth       = options.needsAuth;
	}
	
	
	
	get name()            { return this._name;            }
	get langUrls()        { return this._langUrls;        }
	get layoutComponent() { return this._layoutComponent; }
	get viewComponent()   { return this._viewComponent;   }
	get needsAuth()       { return this._needsAuth;       }
	
	get type_isLandpage() { return this._name===B_REST_App_RouteDef_base.NAME_LANDPAGE;           }
	get type_isLogin()    { return this._name===B_REST_App_RouteDef_base.NAME_LOGIN;              }
	get type_isResetPwd() { return this._name===B_REST_App_RouteDef_base.NAME_RESET_PWD;          }
	get type_isProfile()  { return this._name===B_REST_App_RouteDef_base.NAME_PROFILE;            }
	get type_is404()      { return this._name===B_REST_App_RouteDef_base.NAME_404;                }
	get type_isOther()    { return !B_REST_App_RouteDef_base._SPECIAL_NAMES.includes(this._name); }
	
	
	//Yields a full URL (from app's base dir)
	toFullPath(pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		if (!lang) { lang=B_REST_App_base.instance.locale_lang; }
		
		if (!B_REST_Utils.object_hasPropName(this._langUrls,lang)) { B_REST_Utils.throwEx(`Not defining langUrl for lang "${lang}"`); }
		
		const splittedPath = B_REST_App_RouteDef_base.splitPath(this._langUrls[lang]);
		
		for (let i=0; i<splittedPath.length; i++)
		{
			const loop_currentPart = splittedPath[i];
			
			//Check to replace path vars
			if (loop_currentPart[0]===":")
			{
				const loop_paramName = loop_currentPart.match(/^:(\w+)/)[1];
				
				splittedPath[i] = pathVars[loop_paramName];
			}
		}
		
		let fullPath = "/" + splittedPath.join("/");
		
		//Check to add QSA
		{
			const qsaObj = new URLSearchParams();
			for (const loop_qsaParamName in qsa)
			{
				qsaObj.append(loop_qsaParamName, qsa[loop_qsaParamName]);
			}
			const qsaString = qsaObj.toString();
			
			if (qsaString!=='') { fullPath+=`?${qsaString}`; }
		}
		
		//Check to add #hashTag
		if (hashTag!==null) { fullPath+=`#${hashTag}`; }
		
		return fullPath;
	}
	
	//Converts to a B_REST_RouteInfo_base der instance
	toRouteInfo(pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		if (!lang) { lang=B_REST_App_base.instance.locale_lang; }
		
		return this._abstract_toRouteInfo(pathVars, qsa, hashTag, lang);
	}
		//Must ret a B_REST_RouteInfo_base der instance
		_abstract_toRouteInfo(pathVars={}, qsa={}, hashTag=null, lang=null) { B_REST_Utils.throwEx(`Must override base method`); }
	
	/*
	Rets {lang,pathVars} or false if no lang matches, receiving either a path, or the result of a previous call to splitPath()
	Usage ex:
		routeDef = new B_REST_App_RouteDef_base("test", {
			fr:"/some/fr/clients/:idClient/factures/:idInvoice/stuff",
			en:"/some/en/clients/:idClient/invoices/:idInvoice/stuff",
		});
		
		const splittedPath = B_REST_App_RouteDef_base.splitPath("/some/fr/clients/123/factures/456/stuff");
		
		routeDef.checkPathMatch(splittedPath);
			-> {lang:"fr", pathVars:{idClient:123,idInvoice:456}}
	IMPORTANT:
		If multiple langs have the same URL, then lang will equal NULL
	*/
	checkPathMatch(pathOrSplittedPath)
	{
		const splittedPath = B_REST_Utils.array_is(pathOrSplittedPath) ? pathOrSplittedPath : B_REST_App_RouteDef_base.splitPath(pathOrSplittedPath);
		const partsCount   = splittedPath.length;
		
		for (let loop_lang in this._langUrls)
		{
			const loop_langUrl       = this._langUrls[loop_lang];
			const loop_langUrl_parts = B_REST_App_RouteDef_base.splitPath(loop_langUrl);
			
			if (loop_langUrl_parts.length!==partsCount) { break; }
			
			const loop_pathVars = {};
			let loop_langMatching = true;
			for (let i=0; i<partsCount; i++)
			{
				const loop_currentPart_langUrl      = loop_langUrl_parts[i];
				const loop_currentPart_splittedPath = splittedPath[i];
				
				//Check if the part was a dynamic param, ex ":id(\\*|[\\w-]+)" in "/someModule/:id(\\*|[\\w-]+)"
				if (loop_currentPart_langUrl[0]===":")
				{
					const loop_paramName = loop_currentPart_langUrl.match(/^:(\w+)/)[1];
					
					loop_pathVars[loop_paramName] = loop_currentPart_splittedPath;
				}
				//Continue checking more parts as long as they remain equal
				else if (loop_currentPart_langUrl!==loop_currentPart_splittedPath)
				{
					loop_langMatching = false;
					break;
				}
			}
			
			if (loop_langMatching)
			{
				//Before indicating in which lang it matches, check if that lang's URL is identical to those in other langs
				for (const loop_otherLangs in this._langUrls)
				{
					if (loop_otherLangs!==loop_lang && this._langUrls[loop_otherLangs]===loop_langUrl)
					{
						loop_lang = null;
						break;
					}
				}
				
				return {lang:loop_lang, pathVars:loop_pathVars};
			}
		}
		
		return false;
	}
	
	/*
	Helper for checkPathMatch() & B_REST_App_base::routes_getRouteInfo_from_x()
	Expects path wo QSA
	Usage ex:
		"/a/b/c/" -> ["a","b","c"]
		"a/b"     -> ["a","b"]
	*/
	static splitPath(path)
	{
		return path.replaceAll(/^\/|\/$/g,"").split("/");
	}
};
