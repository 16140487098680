<template>
	
	<v-container class="fill-height" style="max-width: 1000px">
		
		<v-row>
			<v-col cols="auto"> <br-field-db :field="currentCountry" :items="dataSets.countries" items-flat-search-name="name" @change="on_country_change" /> </v-col>
		</v-row>
			
		<v-row>
			<v-col cols="12">
				<v-card :loading="isLoadingOrSaving" :disabled="isLoadingOrSaving">
					<v-tabs slider-color="primary" slider-size="4" background-color="secondary" right fixed-tabs show-arrows dark>
						
						<v-tab key="franchisorInvoice" v-text="loc_shortTitle('franchisorInvoice')" />
						<v-tab-item key="franchisorInvoice">
							<v-container fluid>
								<v-alert type="info" dense text border="left">
									<h3 class="text-subtitle-1" v-text="loc_longTitle('franchisorInvoice')" />
									<div class="text-caption"   v-text="loc_desc('franchisorInvoice')"      />
								</v-alert>
								<v-data-table :headers="franchisorInvoice.headers" :items="franchisorInvoice.items" hide-default-footer>
									<template #item.sysCie="{ item:loop_item }">        <edit-cell :item="loop_item" field-name="sysCie" />        </template>
									<template #item.regionManager="{ item:loop_item }"> <edit-cell :item="loop_item" field-name="regionManager" /> </template>
									<template #item.membershipConsultant="{ item:loop_item }">
										<edit-cell v-if="loop_item._tag_==='franchisorInvoice_fromAgency'" :item="loop_item" field-name="membershipConsultant" />
										<span v-else>{{ loop_item.membershipConsultant }}</span>
									</template>
									<template #item.partner="{ item:loop_item }"> <edit-cell :item="loop_item" field-name="partner" /> </template>
									<template #item._total_="{ item:loop_item }">
										<span :class="franchisorInvoice_calcTotal(loop_item)===100 ? null : 'red--text'">{{ franchisorInvoice_calcTotal(loop_item) }}</span>
									</template>
								</v-data-table>
							</v-container>
						</v-tab-item>
						
						<v-tab key="consultantInvoice" v-text="loc_shortTitle('consultantInvoice')" />
						<v-tab-item key="consultantInvoice">
							<v-container fluid>
								<v-alert type="info" dense text border="left">
									<h3 class="text-subtitle-1" v-text="loc_longTitle('consultantInvoice')" />
									<div class="text-caption"   v-text="loc_desc('consultantInvoice')"      />
								</v-alert>
								<v-data-table :headers="consultantInvoice.headers" :items="consultantInvoice.items" hide-default-footer>
									<template #item.sysCie="{ item:loop_item }">               <edit-cell :item="loop_item" field-name="sysCie" />        </template>
									<template #item.regionManager="{ item:loop_item }">        <edit-cell :item="loop_item" field-name="regionManager" /> </template>
									<template #item.membershipConsultant="{ item:loop_item }"> {{ loop_item.membershipConsultant }}                       </template>
									<template #item.partner="{ item:loop_item }">              <edit-cell :item="loop_item" field-name="partner" />       </template>
									<template #item._total_="{ item:loop_item }">
										<span :class="consultantInvoice_calcTotal(loop_item)===100 ? null : 'red--text'">{{ consultantInvoice_calcTotal(loop_item) }}</span>
									</template>
								</v-data-table>
							</v-container>
						</v-tab-item>
						
						<v-tab key="leadCreditToken" v-text="loc_shortTitle('leadCreditToken')" />
						<v-tab-item key="leadCreditToken">
							<v-container fluid>
								<v-alert type="warning" dense text border="left">
									<h3 class="text-subtitle-1" v-text="loc_longTitle('leadCreditToken')" />
									<div class="text-caption"   v-text="loc_desc('leadCreditToken')"      />
								</v-alert>
								<v-data-table :headers="leadCreditToken.headers" :items="leadCreditToken.items" hide-default-footer>
									<template #item.sysCie="{ item:loop_item }">        <edit-cell :item="loop_item" field-name="sysCie" />        </template>
									<template #item.regionManager="{ item:loop_item }"> <edit-cell :item="loop_item" field-name="regionManager" /> </template>
									<template #item.membershipConsultant="{ item:loop_item }">
										<edit-cell v-if="loop_item._tag_==='leadCreditToken_fromConsultant'" :item="loop_item" field-name="membershipConsultant" />
										<span v-else>N/A</span>
									</template>
									<template #item.leadConsultant="{ item:loop_item }"> <edit-cell :item="loop_item" field-name="leadConsultant" /> </template>
									<template #item.partner="{ item:loop_item }">        <edit-cell :item="loop_item" field-name="partner" />        </template>
									<template #item._total_="{ item:loop_item }">
										<span :class="leadCreditToken_calcTotal(loop_item)===100 ? null : 'red--text'">{{ leadCreditToken_calcTotal(loop_item) }}</span>
									</template>
								</v-data-table>
							</v-container>
						</v-tab-item>
					</v-tabs>
				</v-card>
			</v-col>
			
			<v-col cols="12" class="text-right">
				<v-btn color="primary" @click="on_save_click">{{ _loc("actions.save.label") }}<v-icon>mdi-content-save</v-icon></v-btn>
			</v-col>
		</v-row>
		
	</v-container>
	
</template>
<script>
	
	import { B_REST_Descriptor, B_REST_FieldDescriptors, B_REST_Model } from "@/bREST/core/classes";
	const locBasePath = "views.commissionRepartitions";
	
	
	
	export default {
		components: {
			EditCell: () => import("./EditCell.vue"),
		},
		data()
		{
			//Helper vars for declaring objs down below
			const sysCie               = null;
			const membershipConsultant = null;
			const leadConsultant       = null;
			const regionManager        = null;
			const partner              = null;
			
			//For loc
			const descriptor = B_REST_Descriptor.commonDefs_get("RegionCommissionRepartition");
			
			return {
				currentCountry: B_REST_FieldDescriptors.DB.create_type_int("currentCountry", {locBasePath:`${locBasePath}.currentCountry`, isRequired:true}).factory_modelField(),
				isLoadingOrSaving: false,
				dataSets: {
					countries: this.$bREST.sharedLists_getSrc("countryAndSubRegionList"), //Instance of B_REST_ModelList
				},
				model: null,
				franchisorInvoice: {
					headers: [
						{value:"_provenance_",         text:this._loc_type_header_x("franchisorInvoice","_provenance_"),         sortable:false, align:"left"},
						{value:"sysCie",               text:this._loc_type_header_x("franchisorInvoice","sysCie"),               sortable:false, align:"center"},
						{value:"regionManager",        text:this._loc_type_header_x("franchisorInvoice","regionManager"),        sortable:false, align:"center"},
						{value:"membershipConsultant", text:this._loc_type_header_x("franchisorInvoice","membershipConsultant"), sortable:false, align:"center"},
						{value:"partner",              text:this._loc_type_header_x("franchisorInvoice","partner"),              sortable:false, align:"center"},
						{value:"_total_",              text:this._loc_type_header_x("franchisorInvoice","_total_"),              sortable:false, align:"center"},
					],
					items: [
						{_tag_:"franchisorInvoice_fromSelf",              _provenance_:descriptor.field_loc_label("franchisorInvoice_fromSelf"),              sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"franchisorInvoice_fromSysCie",            _provenance_:descriptor.field_loc_label("franchisorInvoice_fromSysCie"),            sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"franchisorInvoice_fromMainDistributor",   _provenance_:descriptor.field_loc_label("franchisorInvoice_fromMainDistributor"),   sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"franchisorInvoice_fromAgency",            _provenance_:descriptor.field_loc_label("franchisorInvoice_fromAgency"),            sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"franchisorInvoice_fromReferentOrPartner", _provenance_:descriptor.field_loc_label("franchisorInvoice_fromReferentOrPartner"), sysCie,regionManager,membershipConsultant,partner},
					],
				},
				consultantInvoice:
				{
					headers: [
						{value:"_provenance_",         text:this._loc_type_header_x("consultantInvoice","_provenance_"),         sortable:false, align:"left"},
						{value:"sysCie",               text:this._loc_type_header_x("consultantInvoice","sysCie"),               sortable:false, align:"center"},
						{value:"regionManager",        text:this._loc_type_header_x("consultantInvoice","regionManager"),        sortable:false, align:"center"},
						{value:"membershipConsultant", text:this._loc_type_header_x("consultantInvoice","membershipConsultant"), sortable:false, align:"center"},
						{value:"partner",              text:this._loc_type_header_x("consultantInvoice","partner"),              sortable:false, align:"center"},
						{value:"_total_",              text:this._loc_type_header_x("consultantInvoice","_total_"),              sortable:false, align:"center"},
					],
					items: [
						{_tag_:"consultantInvoice_fromSelf",              _provenance_:descriptor.field_loc_label("consultantInvoice_fromSelf"),              sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"consultantInvoice_fromSysCie",            _provenance_:descriptor.field_loc_label("consultantInvoice_fromSysCie"),            sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"consultantInvoice_fromMainDistributor",   _provenance_:descriptor.field_loc_label("consultantInvoice_fromMainDistributor"),   sysCie,regionManager,membershipConsultant,partner},
						{_tag_:"consultantInvoice_fromReferentOrPartner", _provenance_:descriptor.field_loc_label("consultantInvoice_fromReferentOrPartner"), sysCie,regionManager,membershipConsultant,partner},
					],
				},
				leadCreditToken:
				{
					headers: [
						{value:"_provenance_",         text:this._loc_type_header_x("leadCreditToken","_provenance_"),         sortable:false, align:"left"},
						{value:"sysCie",               text:this._loc_type_header_x("leadCreditToken","sysCie"),               sortable:false, align:"center"},
						{value:"regionManager",        text:this._loc_type_header_x("leadCreditToken","regionManager"),        sortable:false, align:"center"},
						{value:"membershipConsultant", text:this._loc_type_header_x("leadCreditToken","membershipConsultant"), sortable:false, align:"center"},
						{value:"leadConsultant",       text:this._loc_type_header_x("leadCreditToken","leadConsultant"),       sortable:false, align:"center"},
						{value:"partner",              text:this._loc_type_header_x("leadCreditToken","partner"),              sortable:false, align:"center"},
						{value:"_total_",              text:this._loc_type_header_x("leadCreditToken","_total_"),              sortable:false, align:"center"},
					],
					items: [
						{_tag_:"leadCreditToken_fromSelf",              _provenance_:descriptor.field_loc_label("leadCreditToken_fromSelf"),              sysCie,regionManager,membershipConsultant,leadConsultant,partner},
						{_tag_:"leadCreditToken_fromSysCie",            _provenance_:descriptor.field_loc_label("leadCreditToken_fromSysCie"),            sysCie,regionManager,membershipConsultant,leadConsultant,partner},
						{_tag_:"leadCreditToken_fromMainDistributor",   _provenance_:descriptor.field_loc_label("leadCreditToken_fromMainDistributor"),   sysCie,regionManager,membershipConsultant,leadConsultant,partner},
						{_tag_:"leadCreditToken_fromConsultant",        _provenance_:descriptor.field_loc_label("leadCreditToken_fromConsultant"),        sysCie,regionManager,membershipConsultant,leadConsultant,partner},
						{_tag_:"leadCreditToken_fromReferentOrPartner", _provenance_:descriptor.field_loc_label("leadCreditToken_fromReferentOrPartner"), sysCie,regionManager,membershipConsultant,leadConsultant,partner},
					],
				},
			};
		},
		created()
		{
			this.currentCountry.val = this.dataSets.countries.first.pk;
			this.reLoadCountry();
		},
		methods: {
			_loc(tag,details=null)              { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details);                                 },
			_loc_type_x(type,subTag)            { return this._loc(`types.${type}.${subTag}`);                                                       },
			_loc_type_header_x(type,subTag)     { return this._loc_type_x(type,`headers.${subTag}`);                                                 },
			loc_shortTitle(type)                { return this._loc_type_x(type,"shortTitle");                                                        },
			loc_longTitle(type)                 { return this._loc_type_x(type,"longTitle");                                                         },
			loc_desc(type)                      { return this._loc_type_x(type,"desc");                                                              },
			franchisorInvoice_calcTotal(item)   { return objSum(item, ["sysCie","regionManager","membershipConsultant","partner"]);                  },
			consultantInvoice_calcTotal(item)   { return objSum(item, ["sysCie","regionManager","membershipConsultant","partner"]);                  },
			leadCreditToken_calcTotal(item)     { return objSum(item, ["sysCie","regionManager","membershipConsultant","partner","leadConsultant"]); },
			on_country_change()
			{
				this.reLoadCountry();
			},
			async reLoadCountry()
			{
				this.isLoadingOrSaving = true;
				
				const country_region_fk = this.currentCountry.val;
				
				try
				{
					this.model = await B_REST_Model.commonDefs_load_one("RegionCommissionRepartition", {
						apiBaseUrl:           "/config/regions/{region}/commissionRepartitions/",
						apiBaseUrl_path_vars: {region:country_region_fk},
						throwOnNotFound:      false,
					});
					if (!this.model) { this.$bREST.utils.throwEx(`Couldn't find commission repartitions for country #${country_region_fk}`); }
					
					//Franchisor invoice related
					{
						const fieldNames = ["sysCie","regionManager","membershipConsultant","partner"];
						
						const franchisorInvoice_fromSelf = this.model.select("franchisorInvoice_fromSelf").val || {};
							for (const loop_fieldName of fieldNames) { this.franchisorInvoice.items[0][loop_fieldName] = franchisorInvoice_fromSelf[loop_fieldName] || 0; }
						
						const franchisorInvoice_fromSysCie = this.model.select("franchisorInvoice_fromSysCie").val || {};
							for (const loop_fieldName of fieldNames) { this.franchisorInvoice.items[1][loop_fieldName] = franchisorInvoice_fromSysCie[loop_fieldName] || 0; }
						
						const franchisorInvoice_fromMainDistributor = this.model.select("franchisorInvoice_fromMainDistributor").val || {};
							for (const loop_fieldName of fieldNames) { this.franchisorInvoice.items[2][loop_fieldName] = franchisorInvoice_fromMainDistributor[loop_fieldName] || 0; }
						
						const franchisorInvoice_fromAgency = this.model.select("franchisorInvoice_fromAgency").val || {};
							for (const loop_fieldName of fieldNames) { this.franchisorInvoice.items[3][loop_fieldName] = franchisorInvoice_fromAgency[loop_fieldName] || 0; }
						
						const franchisorInvoice_fromReferentOrPartner = this.model.select("franchisorInvoice_fromReferentOrPartner").val || {};
							for (const loop_fieldName of fieldNames) { this.franchisorInvoice.items[4][loop_fieldName] = franchisorInvoice_fromReferentOrPartner[loop_fieldName] || 0; }
					}
					
					//Consultant invoice related
					{
						const fieldNames = ["sysCie","regionManager","membershipConsultant","partner"];
						
						const consultantInvoice_fromSelf = this.model.select("consultantInvoice_fromSelf").val || {};
							for (const loop_fieldName of fieldNames) { this.consultantInvoice.items[0][loop_fieldName] = consultantInvoice_fromSelf[loop_fieldName] || 0; }
						
						const consultantInvoice_fromSysCie = this.model.select("consultantInvoice_fromSysCie").val || {};
							for (const loop_fieldName of fieldNames) { this.consultantInvoice.items[1][loop_fieldName] = consultantInvoice_fromSysCie[loop_fieldName] || 0; }
						
						const consultantInvoice_fromMainDistributor = this.model.select("consultantInvoice_fromMainDistributor").val || {};
							for (const loop_fieldName of fieldNames) { this.consultantInvoice.items[2][loop_fieldName] = consultantInvoice_fromMainDistributor[loop_fieldName] || 0; }
						
						const consultantInvoice_fromReferentOrPartner = this.model.select("consultantInvoice_fromReferentOrPartner").val || {};
							for (const loop_fieldName of fieldNames) { this.consultantInvoice.items[3][loop_fieldName] = consultantInvoice_fromReferentOrPartner[loop_fieldName] || 0; }
					}
					
					//Lead credit token related
					{
						const fieldNames = ["sysCie","regionManager","membershipConsultant","leadConsultant","partner"];
						
						const leadCreditToken_fromSelf = this.model.select("leadCreditToken_fromSelf").val || {};
							for (const loop_fieldName of fieldNames) { this.leadCreditToken.items[0][loop_fieldName] = leadCreditToken_fromSelf[loop_fieldName] || 0; }
						
						const leadCreditToken_fromSysCie = this.model.select("leadCreditToken_fromSysCie").val || {};
							for (const loop_fieldName of fieldNames) { this.leadCreditToken.items[1][loop_fieldName] = leadCreditToken_fromSysCie[loop_fieldName] || 0; }
						
						const leadCreditToken_fromMainDistributor = this.model.select("leadCreditToken_fromMainDistributor").val || {};
							for (const loop_fieldName of fieldNames) { this.leadCreditToken.items[2][loop_fieldName] = leadCreditToken_fromMainDistributor[loop_fieldName] || 0; }
						
						const leadCreditToken_fromConsultant = this.model.select("leadCreditToken_fromConsultant").val || {};
							for (const loop_fieldName of fieldNames) { this.leadCreditToken.items[3][loop_fieldName] = leadCreditToken_fromConsultant[loop_fieldName] || 0; }
						
						const leadCreditToken_fromReferentOrPartner = this.model.select("leadCreditToken_fromReferentOrPartner").val || {};
							for (const loop_fieldName of fieldNames) { this.leadCreditToken.items[4][loop_fieldName] = leadCreditToken_fromReferentOrPartner[loop_fieldName] || 0; }
					}
				}
				catch (e)
				{
					this.$bREST.utils.console_error(`Got err while loading country: ${e}`);
				}
				
				this.isLoadingOrSaving = false;
			},
			async on_save_click()
			{
				this.isLoadingOrSaving = true;
				
				const country_region_fk = this.currentCountry.val;
				
				try
				{
					//Franchisor invoice related
					{
						const fieldNames = ["sysCie","regionManager","membershipConsultant","partner"];
						
						const franchisorInvoice_fromSelf = this.model.select("franchisorInvoice_fromSelf").val = {};
							for (const loop_fieldName of fieldNames) { franchisorInvoice_fromSelf[loop_fieldName] = this.franchisorInvoice.items[0][loop_fieldName]; }
						
						const franchisorInvoice_fromSysCie = this.model.select("franchisorInvoice_fromSysCie").val = {};
							for (const loop_fieldName of fieldNames) { franchisorInvoice_fromSysCie[loop_fieldName] = this.franchisorInvoice.items[1][loop_fieldName]; }
						
						const franchisorInvoice_fromMainDistributor = this.model.select("franchisorInvoice_fromMainDistributor").val = {};
							for (const loop_fieldName of fieldNames) { franchisorInvoice_fromMainDistributor[loop_fieldName] = this.franchisorInvoice.items[2][loop_fieldName]; }
						
						const franchisorInvoice_fromAgency = this.model.select("franchisorInvoice_fromAgency").val = {};
							for (const loop_fieldName of fieldNames) { franchisorInvoice_fromAgency[loop_fieldName] = this.franchisorInvoice.items[3][loop_fieldName]; }
						
						const franchisorInvoice_fromReferentOrPartner = this.model.select("franchisorInvoice_fromReferentOrPartner").val = {};
							for (const loop_fieldName of fieldNames) { franchisorInvoice_fromReferentOrPartner[loop_fieldName] = this.franchisorInvoice.items[4][loop_fieldName]; }
					}
					
					//Consultant invoice related
					{
						const fieldNames = ["sysCie","regionManager","membershipConsultant","partner"];
						
						const consultantInvoice_fromSelf = this.model.select("consultantInvoice_fromSelf").val = {};
							for (const loop_fieldName of fieldNames) { consultantInvoice_fromSelf[loop_fieldName] = this.consultantInvoice.items[0][loop_fieldName]; }
						
						const consultantInvoice_fromSysCie = this.model.select("consultantInvoice_fromSysCie").val = {};
							for (const loop_fieldName of fieldNames) { consultantInvoice_fromSysCie[loop_fieldName] = this.consultantInvoice.items[1][loop_fieldName]; }
						
						const consultantInvoice_fromMainDistributor = this.model.select("consultantInvoice_fromMainDistributor").val = {};
							for (const loop_fieldName of fieldNames) { consultantInvoice_fromMainDistributor[loop_fieldName] = this.consultantInvoice.items[2][loop_fieldName]; }
						
						const consultantInvoice_fromReferentOrPartner = this.model.select("consultantInvoice_fromReferentOrPartner").val = {};
							for (const loop_fieldName of fieldNames) { consultantInvoice_fromReferentOrPartner[loop_fieldName] = this.consultantInvoice.items[3][loop_fieldName]; }
					}
					
					//Lead credit token related
					{
						const fieldNames = ["sysCie","regionManager","membershipConsultant","leadConsultant","partner"];
						
						const leadCreditToken_fromSelf = this.model.select("leadCreditToken_fromSelf").val = {};
							for (const loop_fieldName of fieldNames) { leadCreditToken_fromSelf[loop_fieldName] = this.leadCreditToken.items[0][loop_fieldName]; }
						
						const leadCreditToken_fromSysCie = this.model.select("leadCreditToken_fromSysCie").val = {};
							for (const loop_fieldName of fieldNames) { leadCreditToken_fromSysCie[loop_fieldName] = this.leadCreditToken.items[1][loop_fieldName]; }
						
						const leadCreditToken_fromMainDistributor = this.model.select("leadCreditToken_fromMainDistributor").val = {};
							for (const loop_fieldName of fieldNames) { leadCreditToken_fromMainDistributor[loop_fieldName] = this.leadCreditToken.items[2][loop_fieldName]; }
						
						const leadCreditToken_fromConsultant = this.model.select("leadCreditToken_fromConsultant").val = {};
							for (const loop_fieldName of fieldNames) { leadCreditToken_fromConsultant[loop_fieldName] = this.leadCreditToken.items[3][loop_fieldName]; }
						
						const leadCreditToken_fromReferentOrPartner = this.model.select("leadCreditToken_fromReferentOrPartner").val = {};
							for (const loop_fieldName of fieldNames) { leadCreditToken_fromReferentOrPartner[loop_fieldName] = this.leadCreditToken.items[4][loop_fieldName]; }
					}
					
					await this.model.save({
						apiBaseUrl:            "/config/regions/{region}/commissionRepartitions/",
						apiBaseUrl_path_vars:  {region:country_region_fk},
						methodHint_isCreating: false,
					});
					
					this.model.userTouch_toggleAllFields(false);
				}
				catch (e)
				{
					this.$bREST.utils.console_error(`Got err while loading country: ${e}`);
				}
				
				this.isLoadingOrSaving = false;
			},
		},
	};
	
	function objSum(obj, keys)
	{
		return keys.reduce((sum,loop_key) => sum + parseInt(obj[loop_key]??0), 0); //Null coalesce because of "N/A"
	}
	
</script>