<template>
	
	<v-container class="fill-height mx-auto" style="max-width: 600px">
		
		<template v-if="isProfileCompleted">
			<!-- HELP -->
			<v-row>
				<v-slide-y-transition>
					<v-col v-if="showHelp" cols="12" class="mb-8">
						<v-alert type="info" dense text border="left">
							<p class="text-caption" v-text="_loc('help.forMoreInfo')" />
							<v-divider class="info mb-4" style="opacity:0.22" />
							<v-row>
								<v-spacer />
								<v-col cols="auto"> <v-btn color="info" outlined @click="on_hide_help" v-text="_loc('help.hide')" /> </v-col>
							</v-row>
						</v-alert>
					</v-col>
				</v-slide-y-transition>
			</v-row>
		
			<!-- FILTERS -->
			<v-row>
				<v-toolbar flat class="mb-8">
					<v-spacer />
					
					<v-btn color="secondary" class="mr-2" @click="on_filter">
						<span class="hidden-sm-and-down" v-text="_loc('filter.btn')" />
						<v-icon>mdi-filter-menu</v-icon>
					</v-btn>
				</v-toolbar>
			</v-row>
		
			<!-- OPPORTUNITIES  -->
			<template v-for="(loop_model,loop_idx) in modelList.models">
				<v-card :key="'model_'+loop_model.pk" class="pt-4" width="100%">
					
					<v-row :style="{height:$bREST.uiBreakpoint.xs ? null : '150px'}">
						<v-col cols="12" sm="3"> <v-img :src="loop_model.extraData_ui.mainImg" contain max-height="200" class="ml-4" /> </v-col>
						<v-col cols="12" sm="6" class="text-center text-sm-left">
							<div>
								<div v-if="loop_model.extraData_ui.isNew" class="red--text font-weight-light">{{ _loc('card.infoPanel.new') }}</div>
								<span class="text-h5 text-uppercase">{{ loop_model.extraData_ui.cieName }}</span>
								<div class="grey--text font-weight-light">{{ loop_model.extraData_ui.sectorName }}</div>
							</div>
						</v-col>
						<v-col cols="12" sm="3" class="text-center text-sm-left pb-8 pb-sm-0">
							<kb-compat-circle tag="avg" :value="loop_model.extraData_ui.avg" />
						</v-col>
					</v-row>
					<v-divider />
					<v-expansion-panels accordion multiple>
						<v-expansion-panel>
							<v-expansion-panel-header v-text="_loc('card.infoPanel.title')" />
							<v-expansion-panel-content>
								<em v-if="loop_model.extraData_ui.desc" class="text-caption" v-html="loop_model.extraData_ui.desc" />
								<em v-else                              class="text-caption" v-text="_loc('card.infoPanel.noDescInThisLang')" />
								
								<v-container v-if="loop_model.extraData_ui.altImgs.length>0" class="pa-0 pt-4" style="margin: -4px 0">
									<v-row>
										<v-col v-for="(loop_altImgInfo,loop_altImg_idx) of loop_model.extraData_ui.altImgs" :key="'altImg_'+loop_altImg_idx" class="d-flex" :cols="loop_altImgInfo.cols">
											<v-img :src="loop_altImgInfo.src" />
										</v-col>
									</v-row>
								</v-container>
							</v-expansion-panel-content>
						</v-expansion-panel>
						<v-expansion-panel>
							<v-expansion-panel-header v-text="_loc('card.detailsPanel.title')" />
							<v-expansion-panel-content>
								<kb-compat-details :value="model_getCompatTopicsPercents(loop_model)" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<v-divider />
					<v-card-actions>
						<v-btn color="error" text @click="on_lead_hide_click(loop_model)" v-text="_loc('card.actions.hide.title')" />
						<v-spacer />
						<v-rating v-model="loop_model.select('lead_stars').val" length="5" color="primary" @input="on_rating_change(loop_model)" />
					</v-card-actions>
					
				</v-card>
				<v-row :key="'btns_'+loop_idx" justify="end" class="mb-16 pt-4">
				
					<!-- Action per status -->
					<v-col cols="auto">
						<v-tooltip bottom max-width="200">
							{{ _loc('card.actions.contactRequest.tooLow') }} <!-- NOTE: Only displayed if !compatOK -->
							<template #activator="{ on, attrs }">
								<span v-bind="attrs" v-on="!loop_model.extraData_ui.contactRequestState.compatOK ? on : {}">
									<v-btn color="primary" :disabled="!loop_model.extraData_ui.contactRequestState.canDoContactRequest" @click="on_action(loop_model,'contactRequest')">
										<v-icon v-if="loop_model.extraData_ui.contactRequestState.icon">{{ loop_model.extraData_ui.contactRequestState.icon }}</v-icon>
										{{ _loc(loop_model.extraData_ui.contactRequestState.loc) }}
									</v-btn>
								</span>
							</template>
						</v-tooltip>
					</v-col>
					
					<!-- View franchisee -->
					<v-col cols="auto">
						<v-tooltip bottom max-width="200">
							{{ _loc('card.actions.view.tooLow') }}
							<template #activator="{ on, attrs }">
								<span v-bind="attrs" v-on="!loop_model.extraData_ui.contactRequestState.compatOK ? on : {}">
									<v-btn color="primary" :disabled="!loop_model.extraData_ui.contactRequestState.compatOK" @click="on_action(loop_model,'view')" v-text="_loc('card.actions.view.title')" />
								</span>
							</template>
						</v-tooltip>
					</v-col>
					
				</v-row>
			</template>
			
			<!-- HAS MORE BTN  -->
			<v-row justify="center" class="mb-16">
				<v-col cols="auto">
					<v-btn v-if="hasMore" @click="loadMore" color="primary">{{ _loc('loadMore') }}</v-btn>
				</v-col>
			</v-row>
		</template>
		
		<template v-else>
			<v-alert prominent type="warning">
				<v-row align="center">
					<v-col class="grow">{{ _loc("profileNotCompleted.msg") }}</v-col>
					<v-col class="shrink"> <v-btn to="/registration/lead">{{ _loc("profileNotCompleted.btn") }}</v-btn> </v-col>
				</v-row>
			</v-alert>
		</template>
			
		<v-dialog :value="contactRequestDialog_visible" persistent @input="contactRequestDialog_visible=false" max-width="500px">
			<v-card v-if="activeModel">
				<v-card-title class="text-h5" v-text="_loc('brandCustomCriteria.headline')" />
				<v-card-text>
					<p>{{ activeModel_customCriteria }}</p>
					<br-field-db :model="activeModel" field="lead_q_customCriteria" as="textarea" outlined no-label />
				</v-card-text>
				<v-card-actions>
					<v-row justify="end" class="mb-1">
						<v-btn color="secondary" text @click="on_contactRequestDialog_action('cancel')" v-text="_loc('brandCustomCriteria.actions.cancel')" />
						<v-spacer />
						<v-btn color="primary" text @click="on_contactRequestDialog_action('contactRequest')" v-text="_loc('brandCustomCriteria.actions.contactRequest')" />
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<v-dialog :value="view_visible" @input="view_visible=false" max-width="500px">
			<v-card v-if="activeModel">
				<v-card-title v-text="_loc('view.title')" />
				<!-- <v-card-subtitle v-text="activeModel_cieName" /> -->
				<v-card-text>
					<table>
						<tbody>
							<tr v-if="activeModel_yearFounded" no-gutters>
								<th class="text-left text-subtitle-1 font-weight-medium pr-4 py-1">{{ _loc("view.yearFounded") }}</th>
								<td>{{ activeModel_yearFounded }}</td>
							</tr>
							<tr v-if="activeModel_royaltyFeesText" no-gutters>
								<th class="text-left text-subtitle-1 font-weight-medium pr-4 py-1">{{ _loc("view.royaltyFees") }}</th>
								<td>{{ activeModel_royaltyFeesText }}</td>
							</tr>
						</tbody>
					</table>
					<template v-if="activeModel_posInfos">
						<h4 class="text-subtitle-1 font-weight-medium text-uppercase mt-3">{{ _loc("view.posInfos.label") }}</h4>
						<v-simple-table>
							<tbody>
								<tr v-for="(loop_posInfos,loop_idx) in activeModel_posInfos" :key="loop_idx">
									<td>{{ loop_posInfos.label }}</td>
									<td>{{ loop_posInfos.val }}</td>
								</tr>
							</tbody>
						</v-simple-table>
					</template>
					<template v-if="activeModel_urls">
						<h4 class="text-subtitle-1 font-weight-medium text-uppercase mt-3">{{ _loc("view.urls") }}</h4>
						<v-list dense>
							<v-list-item v-for="(loop_activeModel_url,loop_idx) in activeModel_urls" :key="loop_idx" :href="loop_activeModel_url.url" class="blue--text" target="_blank">
								{{ loop_activeModel_url.text }}
							</v-list-item>
						</v-list>
					</template>
					<template v-if="activeModel_docs">
						<h4 class="text-subtitle-1 font-weight-medium text-uppercase mt-3">{{ _loc("view.docs") }}</h4>
						<v-list dense>
							<v-list-item v-for="(loop_activeModel_doc,loop_idx) in activeModel_docs" :key="loop_idx" :href="loop_activeModel_doc.url" class="blue--text" target="_blank">
								{{ loop_activeModel_doc.baseNameWExt }}
							</v-list-item>
						</v-list>
					</template>
				</v-card-text>
				<v-card-actions>
					<v-row justify="center" class="mb-1">
						<v-btn color="primary" @click="view_visible=false">{{ _loc("view.actions.close") }}</v-btn>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
		<br-toaster-manager :instance="bRestToasterManager_instance" />
		
		<br-right-drawer-content v-if="filters_rightDrawerContent" :content="filters_rightDrawerContent">
			<v-card>	
				<v-card-title v-text="filters_rightDrawerContent.title" />
				<v-card-text>
					<h5 class="text-h5">{{ _loc('filter.fields.avg.label') }}</h5>
					<v-row>
						<v-col cols="6"> <br-field-db :field="filters_avg_modelFields[0]" :label="$bREST.t_core('app.components.BrGenericListBase.filters.btwLabels.from_other')" :min="0" :max="100" /> </v-col>
						<v-col cols="6"> <br-field-db :field="filters_avg_modelFields[1]" :label="$bREST.t_core('app.components.BrGenericListBase.filters.btwLabels.to_other')"   :min="0" :max="100" /> </v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn @click="filters_apply" v-text="_loc('filter.apply')" />
					<v-btn @click="filters_reset" v-text="_loc('filter.reset')" />
				</v-card-actions>
			</v-card>
		</br-right-drawer-content>
		
		<v-overlay :value="isLoading">
			<v-progress-circular indeterminate size="64" />
		</v-overlay>
			
		
	</v-container>
	
</template>
<script>
	
	import { B_REST_ModelList, B_REST_ModelFields, B_REST_FieldDescriptors, B_REST_Model_Load_SearchOptions_Filters } from "@/bREST/core/classes";
	import { B_REST_Vuetify_ToasterManager, B_REST_Vuetify_ToasterManager_Item } from "@/bREST/core/implementations/vue/vuetifyComponents/toasterManager/B_REST_Vuetify_ToasterManager.js";
	import { COMPAT_DETAILED_TOPICS } from "@/custom/components/modules/KbCompatCircle.vue";
	import { B_REST_Vuetify_RightDrawerContent } from "@/bREST/core/implementations/vue/vuetifyComponents/rightDrawer/BrRightDrawer.vue";
	
	const locBasePath = "views.leadOpportunities";
	const ALT_IMG_COLS = [null, 7,5, 5,7, 7,5, 5,7, 7,5, 5,7];
	
	const RIGHT_DRAWER_OPEN_SIDE_BREAKPOINTS = ["lg","xl"];
	const RIGHT_DRAWER_ACTION_TAGS_APPLY     = "apply";
	const RIGHT_DRAWER_ACTION_TAGS_RESET     = "reset";
	const RIGHT_DRAWER_ACTION_TAGS_CANCEL    = "cancel";
		
	
	
	export default {
		components: {
			KbCompatCircle: () => import("@/custom/components/modules/KbCompatCircle.vue"),
			KbCompatDetails: () => import("@/custom/components/modules/KbCompatDetails.vue"),
		},
		data()
		{
			return {
				isLoading: false,
				showHelp: !this.$bREST.utils.localStorage_get("opportunities.hideHelp",/*throwIfNull*/false),
				contactRequestDialog_visible: false,
				view_visible: false,
				bRestToasterManager_instance: new B_REST_Vuetify_ToasterManager(),
				modelList: null,
				activeModel: null,
				isProfileCompleted: true,
				filters_rightDrawerContent: null, //Instance of B_REST_Vuetify_RightDrawerContent
				filters_avg_modelFields: null, //Arr of 2 B_REST_ModelField_DB instances
			};
		},
		beforeMount()
		{
			const modelList = B_REST_ModelList.commonDefs_make("Match");
			modelList.apiBaseUrl = "/profile/opportunities/lead";
			modelList.requiredFields.requiredFields_addFields("brand.compatQuestions.q_customCriteria|brand.compatQuestions.common.q_skills_activity_sectors_flat|<dbOnly>");
			modelList.requiredFields.requiredFields_addFields("brand.cieName|brand.pics|brand.loc|brand.yearFounded|brand.currency|brand.royaltyFees_amount|brand.royaltyFees_type|brand.royaltyFees_frequency|brand.posInfos|brand.urls|brand.docs");
			
			/*
			Setup filters
			WARNING:
				Shitty code; if ever we need more filters, we should use a <BrGenericListBase> instead,
				and pass the <template #data-table-area> slot so we don't end up w a <v-data-table>
			*/
			{
				
				//AVG
				{
					const filters_avg_fieldDescriptor = new B_REST_FieldDescriptors.DB("avg", B_REST_FieldDescriptors.DB.TYPE_INT, {
						isRequired:    false,
						isNullable:    true,
						wCustomSetter: false,
						setOnce:       B_REST_FieldDescriptors.DB.SET_ONCE_OFF,
						loc:           this.$bREST.t_custom_subTree(`${locBasePath}.filter.fields.avg`),
						optionalVal:   null,
					});
					
					this.filters_avg_modelFields = [new B_REST_ModelFields.DB(filters_avg_fieldDescriptor), new B_REST_ModelFields.DB(filters_avg_fieldDescriptor)];
					
					const soFilter = modelList.searchOptions.f_specifyOp("avg", B_REST_Model_Load_SearchOptions_Filters.base.OP_BTW);
					soFilter.modelFieldOrArr_reassign(this.filters_avg_modelFields, /*keepPreviousVals*/false);
				}
				
				//WARNING: Read warning above
			}
			
			modelList.hook_afterLoad = (response,models) =>
			{
				this.isProfileCompleted = response.data.isProfileCompleted;
				
				//Set stuff to ease template's code
				
				for (const loop_model of models)
				{
					const loop_avg        = loop_model.select("avg").val;
					let   loop_sectorName = null;
					let   loop_mainImg    = null;
					const loop_altImgs    = []; //Arr of {src,cols}
					
					//Calc sector name
					{
						const loop_sector_pks = loop_model.select("brand.compatQuestions.common.q_skills_activity_sectors_flat").val; //Arr of sectors & sub sectors it belongs to, with the parent sectors first
						const loop_sector     = loop_sector_pks?.length>0 ? this.$bREST.sharedLists_getSrc("activitySectorAndSubSectorList").get_byPK(loop_sector_pks[0]) : null;
						
						loop_sectorName = loop_sector?.toLabel() || null;
					}
					
					//Main and alt imgs
					{
						const loop_pics_list = loop_model.select_isUsed("brand.pics") ? loop_model.select("brand.pics").tmpFromObj.list : [];
						
						if (loop_pics_list.length>0)
						{
							loop_mainImg = B_REST_ModelFields.File.tmpFromObj_apiUrlToWholeUrl(loop_pics_list[0].apiUrl); //Ex "https://flagfranchise-dev.keybook.com/api" + "/brands/1291/pics/LOGO_201_C2_B0_20EAU_20FITNESS_202018.jpg?h=401df0ba5310743533e19f4384a28d3e"
							
							//For alt imgs, alter row styles like 7-5 vs 5-7 cols
							for (let i=1; i<loop_pics_list.length; i++)
							{
								loop_altImgs.push({
									src:  B_REST_ModelFields.File.tmpFromObj_apiUrlToWholeUrl(loop_pics_list[i].apiUrl),
									cols: ALT_IMG_COLS[i]
								});
							}
						}
					}
					
					loop_model.extraData_ui = {
						isNew:               loop_model.select("lead_status").val==="new",
						cieName:             loop_model.select("brand.cieName").val,
						desc:                loop_model.select("brand").localizedField_get("services_longDesc"),
						avg:                 loop_avg,
						sectorName:          loop_sectorName,
						contactRequestState: this.calcModelContactRequestState(loop_model), //As {tag, compatOK, canDoContactRequest, icon, loc}
						mainImg:             loop_mainImg,
						altImgs:             loop_altImgs,
					};
				}
				
				this.$bREST.newCompatCount = response.data.calc_newCompatCount;
			};
			
			this.modelList = modelList;
			
			this.reloadList();
		},
		computed: {
			hasMore() { return !this.modelList.searchOptions.paging_isLastPage; },
			activeModel_customCriteria() { return this.activeModel ? this.activeModel.select("brand.compatQuestions.q_customCriteria").val : null; },
			activeModel_cieName() { return this.activeModel ? this.activeModel.select("brand.cieName").val : null; },
			activeModel_yearFounded() { return this.activeModel ? this.activeModel.select("brand.yearFounded").val : null; },
			//Ex "10%, monthly" or "CA$123.45, weekly"
			activeModel_royaltyFeesText()
			{
				if (!this.activeModel) { return null; }
				
				const frequency = this.activeModel.select("brand.royaltyFees_frequency"); //NOTE: Don't do .val here
				const type      = this.activeModel.select("brand.royaltyFees_type").val;
				const amount    = this.activeModel.select("brand.royaltyFees_amount").val;
				const currency  = this.activeModel.select("brand.currency").val;
				
				if (!frequency.isSet || !type || !amount || !currency) { return null; }
				
				switch (type)
				{
					case "m":
						const amount_formatted = this.$bREST.utils.number_currency(amount, currency);
						return `${amount_formatted}, ${frequency.enum_label}`;
					case "p":
						return `${amount}%, ${frequency.enum_label}`; //Int percents
					default: this.$bREST.throwEx(`Unhandled royalty fees type "${type}"`);
				}
			},
			activeModel_posInfos()
			{
				if (!this.activeModel) { return null; }
				
				const posInfos = this.activeModel.select("brand.posInfos").val;
				if (!posInfos) { return null; }
				
				//WARNING: If we change these, has impact in KbBrandForm.vue
				return [
					{tag:"northAmerica", val:posInfos.northAmerica??0, label:this._loc("view.posInfos.northAmerica")},
					{tag:"ca",           val:posInfos.ca??0,           label:this._loc("view.posInfos.ca")},
					{tag:"us",           val:posInfos.us??0,           label:this._loc("view.posInfos.us")},
					{tag:"europe",       val:posInfos.europe??0,       label:this._loc("view.posInfos.europe")},
					{tag:"other",        val:posInfos.other??0,        label:this._loc("view.posInfos.other")},
				];
			},
			//Rets an arr of {url,text} or NULL
			activeModel_urls()
			{
				if (!this.activeModel) { return null; }
				
				const rawUrls = this.activeModel.select('brand.urls').val;
				if (!rawUrls || rawUrls.length===0) { return null; }
				
				const lang = this.$bREST.locale_lang;
				
				const finalUrls = [];
				for (const loop_url of rawUrls)
				{
					const loop_text = this.$bREST.utils.object_hasPropName(loop_url.loc,lang) ? loop_url.loc[lang] : null;
					finalUrls.push({url:loop_url.url, text:loop_text??loop_url.url});
				}
				
				return finalUrls;
			},
			//Rets an arr of {baseNameWExt,url} or NULL
			activeModel_docs()
			{
				if (!this.activeModel) { return null; }
				
				const rawDocs = this.activeModel.select("brand.docs").tmpFromObj.list;
				if (!rawDocs || rawDocs.length===0) { return null; }
				
				const finalDocs = [];
				for (const loop_doc of rawDocs)
				{
					finalDocs.push({
						baseNameWExt: loop_doc.baseNameWExt,
						url: B_REST_ModelFields.File.tmpFromObj_apiUrlToWholeUrl(loop_doc.apiUrl), //Ex "https://flagfranchise-dev.keybook.com/api" + "/brands/1291/docs/info.docx?h=401df0ba5310743533e19f4384a28d3e"
					});
				}
				return finalDocs;
			},
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			async reloadList()
			{
				this.isLoading = true;
				this.modelList.searchOptions.paging_first();
				await this.modelList.reload();
				this.isLoading = false;
			},
			async loadMore()
			{
				this.isLoading = true;
				await this.modelList.loadMore();
				this.isLoading = false;
			},
			model_getCompatTopicsPercents(model)
			{
				const details = model.select("details").val; //Map like {skills, affinities, territory, profile, time, expectations, budget}
				
				const compatTopics = [];
				for (const loop_tag in COMPAT_DETAILED_TOPICS)
				{
					compatTopics.push({tag:loop_tag, percent:details[loop_tag]});
				}
				
				return compatTopics;
			},
			async _doCall(match_pk, formData, then)
			{
				this.isLoading = true;
				
				try
				{
					const request = new this.$bREST.PUT("/profile/opportunities/lead/{match}", {match:match_pk});
						request.data = formData;
					const response = await this.$bREST.call(request);
					
					then();
				}
				catch (e)
				{
					this.$bREST.utils.console_error(e);
					this.bRestToasterManager_instance.push(this._loc("toaster.failed"), "error", B_REST_Vuetify_ToasterManager_Item.POS_TOP);
				}
				
				this.isLoading = false;
			},
			async on_action(model, which)
			{
				this.activeModel = model;
				
				if (which==="contactRequest")
				{
					//Check if we need to add an intermediate step
					if (this.activeModel_customCriteria) { this.contactRequestDialog_visible = true;              }
					else                                 { this.on_contactRequestDialog_action("contactRequest"); }
				}
				else if (which==="view")
				{
					//Annoy the user if he never did a contact request
					if (model.select("lead_status").val!=="contactRequest")
					{
						this.bRestToasterManager_instance.push(this._loc("card.actions.view.mustDoAContactRequestFirst"), "warning");
						return;
					}
					
					this.unNewModel(this.activeModel);
					this.view_visible = true;
				}
			},
			async on_filter()
			{
				//Init if not yet done
				if (!this.filters_rightDrawerContent) { this.filters_rightDrawerContent=new B_REST_Vuetify_RightDrawerContent(this._loc('filter.drawerTitle')); }
				
				if (this.filters_rightDrawerContent.isActive) { this.filters_rightDrawerContent.close({action:RIGHT_DRAWER_ACTION_TAGS_CANCEL}); }
				else
				{
					const rightDrawerOpenMethod = RIGHT_DRAWER_OPEN_SIDE_BREAKPOINTS.includes(this.currentBreakpoint_name) ? "open_side" : "open_modal";
					const closeData             = await this.filters_rightDrawerContent[rightDrawerOpenMethod]();
				}
			},
				//Ret the promise
				async filters_apply()
				{
					this._filters_done(RIGHT_DRAWER_ACTION_TAGS_APPLY);
					
					return this.modelList.reload();
				},
				//Ret the promise
				async filters_reset()
				{
					this._filters_done(RIGHT_DRAWER_ACTION_TAGS_RESET);
					
					this.modelList.searchOptions.filters_reset();
					
					return this.modelList.reload(); //Ret the promise
				},
					_filters_done(action)
					{
						if (this.filters_rightDrawerContent.isActive) { this.filters_rightDrawerContent.close({action}); }
					},
			async on_rating_change(model)
			{
				this._doCall(model.pk, {
					action: "rating",
					rating: model.select('lead_stars').val,
				}, () =>
				{
					this.unNewModel(model);
					this.bRestToasterManager_instance.push(this._loc("toaster.updated"), "info");
				});
			},
			async on_lead_hide_click(model)
			{
				this._doCall(model.pk, {
					action: "hide",
				}, () =>
				{
					this.unNewModel(model);
					this.modelList.destroy(model);
					this.bRestToasterManager_instance.push(this._loc("toaster.hidden"), "info");
				});
			},
			async on_contactRequestDialog_action(which)
			{
				if (which==="contactRequest")
				{
					const customCriteria = this.activeModel.select("lead_q_customCriteria").val || null;
					
					this._doCall(this.activeModel.pk, {
						action: "contactRequest",
						customCriteria,
					}, () =>
					{
						this.activeModel.select("lead_status").val = "contactRequest";
						this.activeModel.extraData_ui.contactRequestState = this.calcModelContactRequestState(this.activeModel);
						this.bRestToasterManager_instance.push(this._loc("toaster.applied"), "success");
						
						this.unNewModel(this.activeModel);
					});
				}
				
				this.contactRequestDialog_visible = false;
			},
			//Rets as {tag, compatOK, canDoContactRequest, icon, loc}
			calcModelContactRequestState(model)
			{
				const compatOK     = model.select("avg").val >= 70;
				const brand_status = model.select("brand_status").val;
				const lead_status  = model.select("lead_status").val;
				
				switch (brand_status)
				{
					case "privateSigned": case "publicSigned":  return {compatOK, canDoContactRequest:false, icon:"mdi-thumb-up-outline",   loc:"card.status.signed"};
					case "publicRejected_privateRejected":      return {compatOK, canDoContactRequest:false, icon:"mdi-thumb-down-outline", loc:"card.status.refusal"};
					case "preparing_hidden": case "privateWExpirationNew": case "privateWExpirationSeen": case "publicAnonymousNew": case "publicAnonymousSeen": case "publicUnlocked_privateExpired":
						switch (lead_status)
						{
							case "new": case "seen": return {compatOK, canDoContactRequest:compatOK, icon:null,         loc:"card.status.contactRequest"};
							case "contactRequest":   return {compatOK, canDoContactRequest:false,    icon:"mdi-update", loc:"card.status.pending"};
							default: this.$bREST.throwEx(`Unhandled lead_status: "${lead_status}"`);
						}
					break;
					default: this.$bREST.throwEx(`Unhandled brand_status: "${brand_status}"`);
				}
			},
			unNewModel(model)
			{
				if (model.extraData_ui.isNew)
				{
					model.extraData_ui.isNew = false;
					/*
					NOTE: Don't do the following anymore, because now calls trigger RouteParser_base::output_json_injectCore_user()
						this.$bREST.newCompatCount -= 1;
					*/
				}
			},
			on_hide_help()
			{
				this.$bREST.utils.localStorage_set("opportunities.hideHelp", true);
				this.showHelp = false;
			},
		},
	};
	
</script>