<template>
	<v-sheet>
			
		<!-- MAIN INFO -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>Main info</v-card-title>
			<v-card-text>
				<v-row dense>
					<v-col md="6"> <g-lazy-text-field v-model="model.tableName"      label="table_name"     placeholder="some_table" /> </v-col>
					<v-col md="6"> <g-lazy-text-field v-model="model.modelClassName" label="ModelClassName" placeholder="SomeModule" /> </v-col>
					<v-col md="12">
						<v-card>
							<v-card-subtitle>PKs</v-card-subtitle>
							<v-card-text>
								<v-checkbox v-model="model.isAutoInc" label="Is AUTO_INC" />
								<v-simple-table>
									<thead>
										<tr>
											<th>Name</th>
											<th style="width:100px">Type</th>
											<th style="width:50px">Loc</th>
											<th v-if="!model.isAutoInc" style="width:50px"> <v-btn icon color="black" @click="model.pks_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(loop_pk,loop_idx) in model.pks" :key="loop_idx">
											<td> <g-lazy-text-field v-model="loop_pk.fieldName" label="fieldName" placeholder="pk" /> </td>
											<td> <v-autocomplete    v-model="loop_pk.type"      label="type" :items="dataSets.dbFieldTypes" :readonly="model.isAutoInc" auto-select-first /> </td>
											<td> <v-btn tile x-small @click="locDialog_activeField=loop_pk" :color="pk_loc_filled(loop_pk)?'success':null">...</v-btn> </td>
											<td v-if="!model.isAutoInc"> <v-btn icon color="red" @click="model.pks_del(loop_pk)"><v-icon>mdi-delete</v-icon></v-btn> </td>
										</tr>
									</tbody>
								</v-simple-table>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col md="12">
						<v-card>
							<v-card-subtitle>API related</v-card-subtitle>
							<v-card-text>
								<g-lazy-text-field v-model="model.toLabel_fieldNamePaths" label="Auto toLabel() fields (optional)" placeholder="Ex: 'firstName|lastName|firstName+lastName|cieName'" />
								
								<v-checkbox        v-model="model.isSharedList" label="Is shared list ?" />
								<v-checkbox        v-model="model.routes_has" label="Has API routes ?" />
								<g-lazy-text-field v-if="model.routes_has" v-model="model.apiBaseUrl_list"             label="Base url" placeholder="Ex '/contacts/', ex for 'GET /contacts/' and 'GET /contacts/123'..." />
								<v-checkbox        v-if="model.routes_has" v-model="model.apiBaseUrl_needsAccessToken" label="Need access token ?" />
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- DB FIELDS (EXCEPT FKS) -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>DB fields (except FKs)</v-card-title>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Name</th>
							<th style="width:240px">Type</th>
							<th style="width:110px">Required</th>
							<th style="width:150px">Default !NULL val</th>
							<th style="width:140px">Range</th>
							<th style="width:50px">Set once</th>
							<th style="width:105px">Custom setter</th>
							<th style="width:90px">On changed do X</th>
							<th>Comments</th>
							<th style="width:50px">Loc</th>
							<th style="width:85px"> <v-btn icon color="black" @click="model.dbFields_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in model.dbFields" :key="loop_idx">
							<td> <g-lazy-text-field v-model="loop_field.fieldName" placeholder="firstName" /> </td>
							<td>
								<v-autocomplete v-model="loop_field.type" :items="dataSets.dbFieldTypes" class="d-inline-block mr-4" style="width:95px" auto-select-first />
								
								<g-lazy-text-field v-if="loop_field.can_range_asText"  v-model="loop_field.max"      type="number" placeholder="Empty = TEXT" label="Max length" :min="0" class="d-inline-block" style="width:115px" />
								<g-lazy-text-field v-else-if="loop_field.can_decimals" v-model="loop_field.decimals" type="number" placeholder="Empty = 2"    label="Decimals"   :min="1" class="d-inline-block" style="width:115px" />
								
								<v-btn v-if="loop_field.can_enumVals" class="d-inline-block" @click="enumDialog_activeField=loop_field" :color="loop_field.enumVals_filled?'success':null">Enum</v-btn>
							</td>
							<td> <v-autocomplete v-model="loop_field.reqType" :items="dataSets.reqTypes" auto-select-first /> </td>
							<td>
								<g-lazy-text-field v-if="loop_field.can_defaultVal_asNumber"       v-model="loop_field.optionalVal" type="number" />
								<g-lazy-text-field v-else-if="loop_field.can_defaultVal_asText"    v-model="loop_field.optionalVal" type="text" placeholder="Empty = ''" />
								<v-autocomplete    v-else-if="loop_field.can_defaultVal_asEnumVal" v-model="loop_field.optionalVal" :items="loop_field.enumVals" return-object item-text="tag" auto-select-first />
								<v-checkbox        v-else-if="loop_field.can_defaultVal_asBool"    v-model="loop_field.optionalVal" />
							</td>
							<td>
								<template v-if="loop_field.can_range_asNumber">
									<g-lazy-text-field v-model="loop_field.min" placeholder="Min" type="number" class="d-inline-block" style="width:50px" />
									<span class="mx-2">to</span>
									<g-lazy-text-field v-model="loop_field.max" placeholder="Max" type="number" class="d-inline-block" style="width:50px" />
								</template>
							</td>
							<td> <v-checkbox v-if="loop_field.setOnce_can" v-model="loop_field.setOnce" /> </td>
							<td> <v-autocomplete    v-model="loop_field.wCustomSetter" :items="dataSets.wCustomSetterVals" auto-select-first /> </td>
							<td> <v-checkbox        v-model="loop_field.onChanged" /> </td>
							<td> <g-lazy-text-field v-model="loop_field.comments" /> </td>
							<td> <v-btn tile x-small @click="locDialog_activeField=loop_field" :color="loop_field.loc_filled?'success':null">...</v-btn> </td>
							<td>
								<v-btn icon color="grey" @click="model.dbFields_up(loop_field)" class="d-inline-block"><v-icon>mdi-menu-up</v-icon></v-btn>
								<v-btn icon color="red"  @click="model.dbFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn>
								<v-btn icon color="grey" @click="model.dbFields_down(loop_field)" class="d-inline-block"><v-icon>mdi-menu-down</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
				<v-checkbox v-if="model.isAutoInc" v-model="model.hasSoftDel" label="Soft delete DT ?" class="d-inline-block mr-6" />
				<v-checkbox v-model="model.hasCreatedDT" label="Auto created DT ?" class="d-inline-block mr-6" />
				<v-checkbox v-model="model.hasUpdatedDT" label="Auto lastUpdated DT ?" class="d-inline-block mr-6" />
			</v-card-text>
		</v-card>
		
		<!-- LOOKUP FIELDS (+FKS) -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>Lookup fields (+FKs)</v-card-title>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Pointing on</th>
							<th>Own ptr field name</th>
							<th>Own FK field name</th>
							<th style="width:160px">Type</th>
							<th style="width:70px">Required ?</th>
							<th>Comments</th>
							<th style="width:50px">Loc</th>
							<th style="width:85px"> <v-btn icon color="black" @click="model.lookupFields_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in model.lookupFields" :key="loop_idx">
							<td> <v-autocomplete v-model="loop_field.modelClassName" label="Other model" :items="lookups_otherModels" item-value="modelClassName" item-text="formatted" auto-select-first /> </td>
							<td> <g-lazy-text-field v-model="loop_field.fieldName" placeholder="user" /> </td>
							<td> <g-lazy-text-field v-model="loop_field.fkFieldName" placeholder="user_fk" /> </td>
							<td> <v-autocomplete    v-model="loop_field.type" :items="dataSets.lookupTypes" auto-select-first /> </td>
							<td> <v-checkbox        v-model="loop_field.reqType" :true-value="dataSets.req_checkbox_true" :false-value="dataSets.req_checkbox_false" /> </td>
							<td> <g-lazy-text-field v-model="loop_field.comments" /> </td>
							<td> <v-btn tile x-small @click="locDialog_activeField=loop_field" :color="loop_field.loc_filled?'success':null">...</v-btn> </td>
							<td>
								<v-btn icon color="grey" @click="model.lookupFields_up(loop_field)"><v-icon>mdi-menu-up</v-icon></v-btn>
								<v-btn icon color="red"  @click="model.lookupFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn>
								<v-btn icon color="grey" @click="model.lookupFields_down(loop_field)"><v-icon>mdi-menu-down</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
		
		<!-- DB INDEXES -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>DB indexes (in priority order)</v-card-title>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Fields</th>
							<th style="width:50px">Unique</th>
							<th style="width:85px"> <v-btn icon color="black" @click="model.indexes_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_indexInfo,loop_idx) in model.indexes" :key="loop_idx">
							<td> <v-autocomplete v-model="loop_indexInfo.fields" chips multiple :items="model.indexableFields" return-object item-text="name_forIndex" placeholder="Selection order is relevant" auto-select-first /> </td>
							<td> <v-checkbox     v-model="loop_indexInfo.isUnique" /> </td>
							<td>
								<v-btn icon color="grey" @click="model.indexes_up(loop_indexInfo)" class="d-inline-block"><v-icon>mdi-menu-up</v-icon></v-btn>
								<v-btn icon color="red"  @click="model.indexes_del(loop_indexInfo)"><v-icon>mdi-delete</v-icon></v-btn>
								<v-btn icon color="grey" @click="model.indexes_down(loop_indexInfo)" class="d-inline-block"><v-icon>mdi-menu-down</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
		
		<!-- SUB MODEL FIELDS -->
		<v-card v-if="model.isAutoInc" :elevation="2" class="mx-2 my-4">
			<v-card-title>Sub model fields</v-card-title>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Field name</th>
							<th style="width:160px">Type</th>
							<th>Pointing on</th>
							<th>Comments</th>
							<th style="width:50px">Loc</th>
							<th style="width:85px"> <v-btn icon color="black" @click="model.subModelFields_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in model.subModelFields" :key="loop_idx">
							<td> <g-lazy-text-field v-model="loop_field.fieldName" placeholder="invoiceDetails" /> </td>
							<td> <v-autocomplete    v-model="loop_field.type" :items="dataSets.subModelTypes" auto-select-first /> </td>
							<td style="text-align:left">
								<v-autocomplete v-model="loop_field.subModel_info" :items="subModels_otherModels" return-object item-text="modelClassName" class="d-inline-block mr-4" style="width:48%" auto-select-first />
								<v-autocomplete v-if="loop_field.subModel_info" v-model="loop_field.subModel_fkFieldName" :items="loop_field.subModel_info.intFields" class="d-inline-block" style="width:48%" auto-select-first />
							</td>
							<td> <g-lazy-text-field v-model="loop_field.comments" /> </td>
							<td> <v-btn tile x-small @click="locDialog_activeField=loop_field" :color="loop_field.loc_filled?'success':null">...</v-btn> </td>
							<td>
								<v-btn icon color="grey" @click="model.subModelFields_up(loop_field)"><v-icon>mdi-menu-up</v-icon></v-btn>
								<v-btn icon color="red"  @click="model.subModelFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn>
								<v-btn icon color="grey" @click="model.subModelFields_down(loop_field)"><v-icon>mdi-menu-down</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
		
		<!-- FILE FIELDS -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>File fields</v-card-title>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Field name</th>
							<th style="width:160px">Type</th>
							<th style="width:80px">Max file count</th>
							<th style="width:80px">Max size per file (mb)</th>
							<th style="width:150px">Allow</th>
							<th style="width:50px">Soft del</th>
							<th style="width:50px">Custom file name func</th>
							<th>Comments</th>
							<th style="width:50px">Loc</th>
							<th style="width:85px"> <v-btn icon color="black" @click="model.fileFields_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in model.fileFields" :key="loop_idx">
							<td> <g-lazy-text-field v-model="loop_field.fieldName" placeholder="docs" /> </td>
							<td> <v-autocomplete    v-model="loop_field.type" :items="dataSets.fileTypes" auto-select-first /> </td>
							<td> <g-lazy-text-field v-if="loop_field.can_maxFileCount" v-model="loop_field.maxFileCount" type="number" :min="1" placeholder="No limit" /> </td>
							<td> <g-lazy-text-field v-model="loop_field.maxFileSize" type="number" :min="1" /> </td>
							<td> <v-autocomplete    v-model="loop_field.allowedTypes" :items="dataSets.fileAllowedTypes" auto-select-first /> </td>
							<td> <v-checkbox        v-model="loop_field.softDelete" /> </td>
							<td> <v-checkbox v-if="loop_field.can_customDisplayNameFunc" v-model="loop_field.customDisplayNameFunc" /> </td>
							<td> <g-lazy-text-field v-model="loop_field.comments" /> </td>
							<td> <v-btn tile x-small @click="locDialog_activeField=loop_field" :color="loop_field.loc_filled?'success':null">...</v-btn> </td>
							<td>
								<v-btn icon color="grey" @click="model.fileFields_up(loop_field)"><v-icon>mdi-menu-up</v-icon></v-btn>
								<v-btn icon color="red"  @click="model.fileFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn>
								<v-btn icon color="grey" @click="model.fileFields_down(loop_field)"><v-icon>mdi-menu-down</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
		
		<!-- OTHER FIELDS -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>Other fields</v-card-title>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Field name</th>
							<th style="width:95px">Load</th>
							<th style="width:95px">Has unsaved changes</th>
							<th style="width:95px">Save</th>
							<th style="width:95px">Can del</th>
							<th style="width:95px">Del</th>
							<th style="width:95px">From obj</th>
							<th style="width:95px">To obj</th>
							<th>Comments</th>
							<th style="width:50px">Loc</th>
							<th style="width:85px"> <v-btn icon color="black" @click="model.otherFields_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in model.otherFields" :key="loop_idx">
							<td> <g-lazy-text-field v-model="loop_field.fieldName" placeholder="complicatedStuff" /> </td>
							<td> <v-autocomplete    v-model="loop_field.func_load"               :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <v-autocomplete    v-model="loop_field.func_unsavedChanges_has" :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <v-autocomplete    v-model="loop_field.func_save"               :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <v-autocomplete    v-model="loop_field.func_delete_can_orMsg"   :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <v-autocomplete    v-model="loop_field.func_delete"             :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <v-autocomplete    v-model="loop_field.func_fromObj"            :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <v-autocomplete    v-model="loop_field.func_toObj"              :items="dataSets.otherFuncTypes" auto-select-first/> </td>
							<td> <g-lazy-text-field v-model="loop_field.comments" /> </td>
							<td> <v-btn tile x-small @click="locDialog_activeField=loop_field" :color="loop_field.loc_filled?'success':null">...</v-btn> </td>
							<td>
								<v-btn icon color="grey" @click="model.otherFields_up(loop_field)"><v-icon>mdi-menu-up</v-icon></v-btn>
								<v-btn icon color="red"  @click="model.otherFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn>
								<v-btn icon color="grey" @click="model.otherFields_down(loop_field)"><v-icon>mdi-menu-down</v-icon></v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
		
		<!-- HOOKS -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>Hooks</v-card-title>
			<v-card-text>
				<v-row dense no-gutters>
					<v-col md="3"> <v-checkbox v-model="model.hook_load_after" label="load_after()" /> </v-col>
					<v-col md="3"> <v-checkbox v-model="model.hook_save_before" label="save_before()" /> </v-col>
					<v-col md="3"> <v-checkbox v-model="model.hook_save_after" label="save_after()" /> </v-col>
				</v-row>
				<v-row dense no-gutters>
					<v-col md="3"> <v-checkbox v-model="model.hook_delete_can_orMsg" label="delete_can_orMsg()" /> </v-col>
					<v-col md="3"> <v-checkbox v-model="model.hook_delete_before" label="delete_before()" /> </v-col>
					<v-col md="3"> <v-checkbox v-model="model.hook_delete_after" label="delete_after()" /> </v-col>
				</v-row>
				<v-row dense no-gutters>
					<v-col md="3"> <v-checkbox v-model="model.hook_fromObj_before" label="fromObj_before()" /> </v-col>
					<v-col md="3"> <v-checkbox v-model="model.hook_fromObj_after" label="fromObj_after()" /> </v-col>
					<v-col md="3"> <v-checkbox v-model="model.hook_toObj_after" label="toObj_after()" /> </v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<v-dialog v-if="locDialog_activeField" v-model="locDialog_activeField" @close="locDialog_activeField=null">
			<v-card>
				<v-card-title>Loc for {{ locDialog_activeField.fieldName }}</v-card-title>
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th>What</th>
								<th v-for="loop_lang in appLangs" :key="loop_lang">{{ loop_lang }}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Label</td>
								<td v-for="loop_lang in appLangs" :key="loop_lang">
									<g-lazy-text-field v-model="locDialog_activeField.loc.label[loop_lang]" placeholder="Description" />
								</td>
							</tr>
							<tr>
								<td>Short version (opt)</td>
								<td v-for="loop_lang in appLangs" :key="loop_lang">
									<g-lazy-text-field v-model="locDialog_activeField.loc.shortLabel[loop_lang]" placeholder="Desc." />
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-dialog>
		
		<v-dialog v-if="enumDialog_activeField" v-model="enumDialog_activeField" @close="enumDialog_activeField=null">
			<v-card>
				<v-card-title>Enum for {{ enumDialog_activeField.fieldName }}</v-card-title>
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
								<th>Tag</th>
								<th v-for="loop_lang in appLangs" :key="loop_lang">{{ loop_lang }}</th>
								<th style="width:50px"> <v-btn icon color="black" @click="enumDialog_activeField.enumVals_add()"><v-icon>mdi-plus</v-icon></v-btn> </th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(loop_enumMember,loop_idx) in enumDialog_activeField.enumVals" :key="loop_idx">
								<td> <g-lazy-text-field v-model="loop_enumMember.tag" placeholder="female" /> </td>
								<td v-for="loop_lang in appLangs" :key="loop_lang">
									<g-lazy-text-field v-model="loop_enumMember.loc[loop_lang]" placeholder="Femme" />
								</td>
								<td> <v-btn icon color="red" @click="enumDialog_activeField.enumVals_del(loop_enumMember)"><v-icon>mdi-delete</v-icon></v-btn> </td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-dialog>
			
	</v-sheet>
</template>

<script>
	
	import { B_REST_Descriptor, B_REST_FieldDescriptors } from "../../../../../classes";
	import B_REST_VueApp_base from "../../../B_REST_VueApp_base.js";
	import GModel from "./GModel.js";
	import { GModel_XField_base, GModel_DBField, GModel_LookupField, GModel_SubModelField, GModel_FileField, GModel_OtherField } from "./GModel_XFields.js";
	
	
	
	export default {
		props: {
			model: {type:GModel, required:true},
		},
		data()
		{
			return {
				dataSets: {
					reqTypes:           GModel_XField_base.REQ_TYPES,
					req_checkbox_true:  GModel_XField_base.REQ_TYPE_REQ,
					req_checkbox_false: GModel_XField_base.REQ_TYPE_OPT_NULL,
					wCustomSetterVals:  GModel_XField_base.W_CUSTOM_SETTER_VALS,
					dbFieldTypes:       GModel_DBField.TYPES,
					lookupTypes:        GModel_LookupField.TYPES,
					subModelTypes:      GModel_SubModelField.TYPES,
					fileTypes:          GModel_FileField.TYPES,
					fileAllowedTypes:   GModel_FileField.ALLOWED_TYPES,
					otherFuncTypes:     GModel_OtherField.FUNC_TYPES,
				},
				locDialog_activeField:  null, //Instance of GModel_XField_base
				enumDialog_activeField: null, //Instance of GModel_DBField
			};
		},
		computed: {
			appLangs() { return B_REST_VueApp_base.instance.appLangs; },
			lookups_otherModels()
			{
				const models = [];
				
				for (const loop_descriptorName of B_REST_Descriptor.commonDefs_names)
				{
					const loop_descriptor = B_REST_Descriptor.commonDefs_get(loop_descriptorName);
					
					if (!loop_descriptor.isAutoInc) { continue; }
					
					const loop_pkFieldName = loop_descriptor.pks[0].name;
					
					models.push({
						modelClassName: loop_descriptorName,
						pkFieldName:    loop_pkFieldName,
						formatted:      `${loop_descriptorName}.${loop_pkFieldName}`,
					});
				}
				
				models.sort((a,b) => a.modelClassName<b.modelClassName?-1:1);
				
				return models;
			},
			subModels_otherModels()
			{
				const models = []; //Arr of {modelClassName, intFields}, where fields are just their names
				
				for (const loop_descriptorName in B_REST_Descriptor._commonDefs)
				{
					const loop_descriptor = B_REST_Descriptor._commonDefs[loop_descriptorName];
					const loop_intFields = [];
					
					for (const loop_fieldName in loop_descriptor.allFields)
					{
						const loop_fieldDescriptor = loop_descriptor.allFields[loop_fieldName];
						if (loop_fieldDescriptor instanceof B_REST_FieldDescriptors.DB && loop_fieldDescriptor.type_is_int) { loop_intFields.push(loop_fieldName); }
					}
					
					if (loop_intFields.length>0)
					{
						models.push({
							modelClassName: loop_descriptorName,
							intFields:      loop_intFields,
						});
					}
				}
				
				models.sort((a,b) => a.modelClassName<b.modelClassName?-1:1);
				
				return models;
			},
		},
		methods: {
			pk_loc_filled(pkField)
			{
				const loc_label = pkField.loc.label;
				
				for (const loop_lang in loc_label)
				{
					const loop_translation = loc_label[loop_lang];
					if (loop_translation===null || loop_translation==="") { return false; }
				}
				
				return true;
			},
		},
		components: {
			GLazyTextField: () => import("../utils/GLazyTextField.vue"),
		},
	};
	
</script>

<style scoped>
	
	th {
		text-align:    center !important;
		border-bottom: thin solid rgba(0,0,0, 0.12);
	}
	td, th {
		border-right: thin solid rgba(0,0,0, 0.12);
		padding:      4px !important;
	}
	td .v-input :deep(.v-input__slot) {
		justify-content: space-around;
	}
	
	.v-data-table {
		width:         100%;
		border-left:   thin solid rgba(0,0,0, 0.12);
		border-top:    thin solid rgba(0,0,0, 0.12);
		border-bottom: thin solid rgba(0,0,0, 0.12);
	}
	
</style>