<template>
	
	<v-container class="fill-height">
		
		<v-row>
			<v-col cols="12">
				
				<v-card class="mt-4 mx-auto" max-width="800">
					<v-sheet class="v-sheet--offset mx-auto" color="accent" elevation="12" max-width="calc(100% - 32px)">
						<v-sparkline type="trend" auto-draw :auto-draw-duration="500" :labels="registrationsEx.labels" :value="registrationsEx.value" color="white" line-width="2" padding="16"></v-sparkline>
					</v-sheet>
					<v-card-text class="pt-0">
						<div class="text-h6 font-weight-light mb-2">Inscriptions</div>
						<div class="subheading font-weight-light grey--text">Performance de la dernière campagne</div>
						<v-divider class="my-2" />
						<v-icon class="mr-2" small>mdi-clock</v-icon>
						<span class="text-caption grey--text font-weight-light">Dernière inscription, 26 minutes passées</span>
					</v-card-text>
				</v-card>
		
			</v-col>
			
			<v-col cols="12">
				
				<v-card class="mt-4 mx-auto" max-width="800">
					<v-sheet class="v-sheet--offset mx-auto" color="accent" elevation="12" max-width="calc(100% - 32px)">
						<!-- NOTE: Here, we need to specify color as prop + style, for bar charts -->
						<v-sparkline type="bar" auto-draw :auto-draw-duration="500" :labels="registrationsEx.labels" :value="registrationsEx.value" color="white" line-width="20" padding="32" style="color:white" />
					</v-sheet>
					<v-card-text class="pt-0">
						<div class="text-h6 font-weight-light mb-2">Inscriptions</div>
						<div class="subheading font-weight-light grey--text">Performance de la dernière campagne</div>
						<v-divider class="my-2" />
						<v-icon class="mr-2" small>mdi-clock</v-icon>
						<span class="text-caption grey--text font-weight-light">Dernière inscription, 26 minutes passées</span>
					</v-card-text>
				</v-card>
		
			</v-col>
			
			<v-col cols="12">
				<v-divider />
			</v-col>
			
			<v-col v-for="(loop_progress,i) in progressEx" :key="i" cols="12" md="3">
				<v-progress-circular :rotate="loop_progress.rotate" :value="loop_progress.value" :color="loop_progress.color" :size="100" :width="15">{{ loop_progress.label }}</v-progress-circular>
			</v-col>
		</v-row>
		
		
	</v-container>
	
</template>
<script>
	
	
	
	
	export default {
		data()
		{
			return {
				registrationsEx: {
					labels: ['12am', '3am', '6am', '9am', '12pm', '3pm', '6pm', '9pm'],
				    value: [200, 675, 410, 390, 310, 460, 250, 240],
				},
				progressEx: [
					{rotate:360, color:"primary", value:40, label:"40 k $"},
					{rotate:-90, color:"red", value:15, label:"15 k $"},
					{rotate:90, color:"purple", value:90, label:"90 k $"},
					{rotate:180, color:"green", value:60, label:"60 k $"},
				]
			};
		},
	};
	
</script>