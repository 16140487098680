<template>
	
	<v-container class="fill-height">
		
		<v-alert v-if="shareableLink" color="primary" border="left" elevation="2" colored-border icon="mdi-share-variant">
			<p>{{ _loc("desc") }}</p>
			<ol class="mb-4">
				<li>{{ _loc("hint1") }}</li>
				<li>{{ _loc("hint2") }}</li>
			</ol>
			<p>
				<span>{{ _loc("link") }}</span>
				<span class="blue--text ml-2">{{ shareableLink }}</span> <v-btn small class="ml-3" @click="copyShareableLink"> <v-icon>mdi-content-copy</v-icon> </v-btn>
			</p>
    	</v-alert>
		
		<v-row justify="end" class="mb-4">
			<v-col cols="auto"> <kb-credit-token-remaining /> </v-col>
		</v-row>
		
		<brand-match-list :from-loader="true" />
		
	</v-container>
	
</template>
<script>
	
	const locBasePath = "views.brandOpportunities.shareableLink";
	
	
	
	export default {
		components: {
			KbCreditTokenRemaining: () => import("@/custom/components/modules/KbCreditTokenRemaining.vue"),
			BrandMatchList:         () => import("./BrandMatchList.vue"),
		},
		computed: {
			shareableLink() { return this.$bREST.shareableLink; }, //Ex: "https://<domainName>/pwa/public-registration/lead?b=1287",
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			async copyShareableLink()
			{
				await this.$bREST.utils.clipboard_write(this.shareableLink);
				this.$bREST.notifs_tmp({msg:this._loc("copied"),color:"info"});
			},
		},
	};
	
</script>