
import { B_REST_Utils, B_REST_ModelList } from "@/bREST/core/classes";
import KbRegionTree from "./KbRegionTree.js";



export default class KbRegion
{
	static _types = Object.freeze({
		ROOT:             Symbol("root"),
		COUNTRY:          Symbol("country"),
		COUNTRY_DIVISION: Symbol("countryDivision"),
		AREA:             Symbol("area"),
		CITY:             Symbol("city"),
	});
	static get TYPES() { return KbRegion._types; }
	
	static get BREADCRUMBS_INCLUDE_SELF() { return true; }
	
	static _nextUid = 1;
	
	
	_uid              = null; //UUID to ease w creation
	_isNew            = null; //WARNING: Don't replace with model.isNew, because we alter it directly
	_parentRegion     = null; //Optional instance of KbRegion
	_type             = null; //One of KbRegion.TYPES.x
	_ifRoot_modelList = null; //Instance of B_REST_ModelList<Region>
	_ifNotRoot_model  = null; //Instance of B_REST_Model<Region>
	_children         = [];   //Arr of KbRegion instances
	_manager_self     = null;
	_partner_self     = null;
	
	
	constructor(parentRegion=null, modelOrModelList=null)
	{
		const isRoot = !parentRegion;
		
		this._uid              =  KbRegion._nextUid++;
		this._parentRegion     =  parentRegion;
		this._type             =  isRoot ? KbRegion.TYPES.ROOT : KbRegion._region_childType(parentRegion);
		this._ifRoot_modelList =  isRoot ? modelOrModelList    : null;
		this._ifNotRoot_model  = !isRoot ? modelOrModelList    : null;
		this._isNew            =  isRoot ? false               : this._ifNotRoot_model.isNew;
	}
	
	
	get uid() { return this._uid; }
	
	get pk() { return this.isRoot ? null : this._ifNotRoot_model.pk; }
	get hexTypedTag() { return this.isRoot ? null : this._ifNotRoot_model.select("hexTypedTag").val; }
	
	//Either rets some children nodes that are selected, or self if all of them are selected
	selectedRegions_toHexTypedTags(vuetifySelectedRegions, hexTypedTags)
	{
		if (this.isAllSelected(vuetifySelectedRegions)) { hexTypedTags.push(this.hexTypedTag); }
		else
		{
			for (const loop_child of this._children) { loop_child.selectedRegions_toHexTypedTags(vuetifySelectedRegions,hexTypedTags); }
		}
	}
	//Checks if either self as leaf node (any lvl) or all of its children are within the Vuetify selection
	isAllSelected(vuetifySelectedRegions)
	{
		if (this._children.length>0)
		{
			for (const loop_child of this._children)
			{
				const loop_isAllSelected = loop_child.isAllSelected(vuetifySelectedRegions);
				if (!loop_isAllSelected) { return false; }
			}
			
			return true;
		}
		else { return !!vuetifySelectedRegions.find(loop_selectedRegion=>loop_selectedRegion.hexTypedTag===this.hexTypedTag); }
	}
	
	get parentRegion() { return this._parentRegion; }
	
	get type() { return this._type; }
	get typeLabel() { return KbRegion._typeLabel(this._type); }
	get typeIcon()
	{
		switch (this._type)
		{
			case KbRegion.TYPES.ROOT:             return null; //Because it's not displayed
			case KbRegion.TYPES.COUNTRY:          return "mdi-earth";
			case KbRegion.TYPES.COUNTRY_DIVISION: return "mdi-map-legend";
			case KbRegion.TYPES.AREA:             return "mdi-map-marker";
			case KbRegion.TYPES.CITY:             return "mdi-city-variant-outline";
			default: B_REST_Utils.throwEx(`Unknown ${this._type}`);
		}
	}
	
	get isRoot() { return !this._parentRegion;   }
	get isCity() { return this._type===KbRegion.TYPES.CITY; }
	get model()  { return this._ifNotRoot_model; }
	
	get name() { return this.isRoot ? "<root>" : (this._ifNotRoot_model.toLabel()??"*"); }
	get debugName()
	{
		if (!KbRegionTree.USE_DEBUG_NAMES) { return this.name; }
		
		return this.name + (this.hexTypedTag ? ` - ${this.hexTypedTag}` : '');
	}
	
	get isNew() { return this._isNew; }
	set isNew(val) { this._isNew=val; }
	get newPlaceholder()
	{
		if (this.isRoot) { B_REST_Utils.throwEx("Can't do that on root region"); }
		
		if (this._parentRegion._type===KbRegion.TYPES.ROOT) { return "Nouveau pays"; }
		
		const parentTypeLabel = KbRegion._typeLabel(this._parentRegion._type);
		return `Nouvelle [${this.typeLabel}] dans [${parentTypeLabel}] ${this._parentRegion.debugName}`;
	}
	
	get children() { return this._children; }
	get children_has() { return this._children.length > 0; }
	get children_asVTreeviewNodes() { return this.children_has ? this._children : (this.isCity?undefined:[]); } // NOTE: Don't ret [] when has no children, or it won't understand it's a leaf
	
	
	
	getNodesWChanges(changes)
	{
		if (this._ifNotRoot_model)
		{
			const manager = this._ifNotRoot_model.select("manager");
			const partner = this._ifNotRoot_model.select("partner");
			
			let struct = null;
			
			if (this._isNew)
			{
				struct = {
					pk:        null,
					parent_fk: this._parentRegion?.pk??null,
					name:      this._ifNotRoot_model.select("name").val,
				};
			}
			else if (manager.unsavedChanges_has || partner.unsavedChanges_has)
			{
				struct = {pk:this._ifNotRoot_model.pk};
			}
			
			if (struct)
			{
				if (manager.unsavedChanges_has) { struct.manager=manager.select("entity_base_fk").val; }
				if (partner.unsavedChanges_has) { struct.partner=partner.select("entity_base_fk").val; }
				
				changes.push(struct);
			}
		}
		
		if (this._children)
		{
			for (const loop_child of this._children) { loop_child.getNodesWChanges(changes); }
		}
	}
	
	
	
	async children_tryLoad(isEditionMode)
	{
		if (this._isNew) { return; }
		
		const reqFields = isEditionMode ? "name|hexTypedTag|manager.manager.user.<dbOnly>|partner.partner.user.<dbOnly>" : "name|hexTypedTag"; //NOTE: For managers & partners, if we want to use cieName instead, we have to change the _def_sharedLookup_req_fixed() in the server to a Model_Consultant instead of Model_Entity_base
		
		const modelList = B_REST_ModelList.commonDefs_make("Region", /*useForLoading*/true, /*useCachedShare*/false);
		modelList.requiredFields.requiredFields_addFields(reqFields);
		modelList.apiBaseUrl                              = "/regionTree/{branch}";
		modelList.apiBaseUrl_path_vars                    = {branch:this._ifNotRoot_model.pk};
		modelList.apiBaseUrl_needsAccessToken             = false;
		modelList.searchOptions.paging_calcFoundRowsCount = false;
		modelList.searchOptions.paging_size_all();
		await modelList.reload();
		
		for (const loop_model of modelList.models) { this.children_add_existing(loop_model); }
	}
	
	children_add_existing(model)
	{
		const childRegion = new KbRegion(this, model);
		this._children.push(childRegion);
		
		return childRegion;
	}
	children_add_new()
	{
		const model = this.isRoot ? this._ifRoot_modelList.add() : this._ifNotRoot_model.select("subRegions").add();
		
		const childRegion = new KbRegion(this, model);
		this._children.unshift(childRegion);
		
		return childRegion;
	}
	
	children_add_new_cascaded()
	{
		this._children.forEach(loop_childRegion => loop_childRegion.children_add_new_cascaded());
		
		this.children_add_new();
	}
	
	get manager_self() { return this._manager_self; }
	set manager_self(val) { this._manager_self=val; }
	
	get partner_self() { return this._partner_self; }
	set partner_self(val) { this._partner_self=val; }
	
	get manager_closest() { return this._managerOrPartner_closest("_manager_self"); }
	get partner_closest() { return this._managerOrPartner_closest("_partner_self"); }
		_managerOrPartner_closest(fieldName)
		{
			if (this[fieldName]) { return this[fieldName]; }
			
			if (this._parentRegion) { return this._parentRegion._managerOrPartner_closest(fieldName); }
			
			return fieldName==="manager_self" ? "Responsable principal X" : "Partenaire principal Y";
		}
	get manager_canEdit() { return true; }
	get partner_canEdit() { return this._type===KbRegion.TYPES.COUNTRY; }
	
	
	
	get breadcrumbs()
	{
		const parts = [];
		
		if (KbRegion.BREADCRUMBS_INCLUDE_SELF)
		{
			let selfName = this.debugName;
			if (this._isNew) { selfName = selfName===null ? "* Nouveau" : `* ${selfName}`;  }
			
			parts.push(selfName);
		}
		
		let loop_region = this._parentRegion;
		while (loop_region._type!==KbRegion.TYPES.ROOT)
		{
			parts.push(loop_region.debugName);
			loop_region = loop_region._parentRegion;
		}
		
		return parts.reverse().join(" > ");
	}
	
	
	
	static _typeLabel(type)
	{
		switch (type)
		{
			case KbRegion.TYPES.ROOT:               return "monde"; //NOTE: Not used though
			case KbRegion.TYPES.COUNTRY:            return "pays";
			case KbRegion.TYPES.COUNTRY_DIVISION:   return "province";
			case KbRegion.TYPES.AREA:               return "région";
			case KbRegion.TYPES.CITY:               return "ville";
			default: B_REST_Utils.throwEx(`Unknown ${B_REST_Utils.symbolVal(type)}`);
		}
	}
	static _region_childType(parentRegion)
	{
		switch (parentRegion._type)
		{
			case KbRegion.TYPES.ROOT:             return KbRegion.TYPES.COUNTRY;
			case KbRegion.TYPES.COUNTRY:          return KbRegion.TYPES.COUNTRY_DIVISION;
			case KbRegion.TYPES.COUNTRY_DIVISION: return KbRegion.TYPES.AREA;
			case KbRegion.TYPES.AREA:             return KbRegion.TYPES.CITY;
			default: B_REST_Utils.throwEx(`Unsupported ${B_REST_Utils.symbolVal(parentRegion._type)}`);
		}
	}
};
