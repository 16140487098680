<template>
	<br-generic-list-base :derived-component="_self" />
</template>

<script>
	
	import { B_REST_Vuetify_GenericListBase_createMixin, B_REST_Vuetify_GenericList_Col, B_REST_Vuetify_GenericList_GlobalAction } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/list/BrGenericListBase.vue";
	
	
	
	export default {
		name: "adminUserList",
		mixins: B_REST_Vuetify_GenericListBase_createMixin({
			modelName: "User",
			icon: "mdi-account",
			fromLoader: {
				apiBaseUrl: "/admin/users/",
			},
			cols: {
				"firstName":     {fieldNamePaths:"firstName",     style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"lastName":      {fieldNamePaths:"lastName",      style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"recoveryEmail": {fieldNamePaths:"recoveryEmail", style:{fromBreakpoint:"sm", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
				"authStatus":    {fieldNamePaths:"authStatus",    style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
				"created_dt":    {fieldNamePaths:"created_dt",    style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
				"lastLogin_dt":  {fieldNamePaths:"lastLogin_dt",  style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
			},
			globalActions: {
				add: {
					click: {
						async hook(listComponent,action,selectedModels)
						{
							listComponent.$bREST.routes_go_moduleForm_new("adminForm"); //NOTE: In Flag we defined modules like "adminForm-form" & "adminForm-list" so don't blindly think we have to do "<moduleName>Form" here
						},
					},
					icon: "mdi-plus",
					selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0,
				},
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					edit: {
						click: {
							async hook(listComponent,action,model)
							{
								listComponent.$bREST.routes_go_moduleForm_pkTag("adminForm",model.pk); //NOTE: In Flag we defined modules like "adminForm-form" & "adminForm-list" so don't blindly think we have to do "<moduleName>Form" here
							},
						},
						icon: "mdi-pencil",
					},
					sudo: {
						click: {
							async hook(listComponent,action,model)
							{
								listComponent.$bREST.sudoIn(model.select("sudoHash").data);
							},
								isEnabled(listComponent,action,model)
								{
									const self_idUser     = listComponent.$bREST.user_pk;
									const target_idUser   = model.pk;
									const target_sudoHash = model.select("sudoHash").data;
									
									return target_sudoHash && self_idUser!==target_idUser;
								},
						},
						icon: "mdi-key-arrow-right",
					},
				},
			},
		}),
	};
			
</script>