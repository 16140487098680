<template>
	<g-edit-output-panels-app>
		<template #editor> <g-model  :model="model" /> </template>
		<template #output> <g-output :model="model" /> </template>
	</g-edit-output-panels-app>
</template>

<script>
	
	import GModel from "./GModel.js";
	
	
	
	export default {
		data()
		{
			return {
				model: null, //Instance of GModel
			};
		},
		async created()
		{
			this.model = new GModel();
		},
		components: {
			GEditOutputPanelsApp: () => import("../utils/GEditOutputPanelsApp.vue"),
			GModel: () => import("./GModel.vue"),
			GOutput: () => import("./output/GOutput.vue"),
		},
	};
	
</script>