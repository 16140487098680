
import B_REST_Utils             from "../B_REST_Utils.js";
import B_REST_App_RouteDef_base from "./B_REST_App_RouteDef_base.js";
import B_REST_App_base          from "./B_REST_App_base.js";



export default class B_REST_App_RouteInfo_base
{
	_fullPath = null; //Actual URL, ex "/clients/123?a=456#stuff". WARNING: Can actually be an unsupported URL, but we'll keep it as is. Doesn't include .env file's BASE_URL (ex "/pwa/"). To get it, use fullPath_wBaseUrl()
	_routeDef = null; //Instance of B_REST_App_RouteDef_base der. Can be NULL too (ex doesn't match w anything, but we still want the path vars etc. Check with isUn/Known())
	_lang     = null; //Tells it matches for which lang's URL in the routeDef. IMPORTANT: If multiple langs have the same URL, then lang will equal NULL
	_pathVars = null; //Ex if def was "/clients/:id", we'd get {id:123}
	_qsa      = null; //Against the above ex, we'd get {a:456}
	_hashTag  = null; //We'd get "stuff"
	
	
	
	constructor(fullPath, routeDef=null, pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		if (routeDef) { B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteDef_base,routeDef); }
		
		this._fullPath = fullPath;
		this._routeDef = routeDef;
		this._lang     = lang;
		this._pathVars = pathVars;
		this._qsa      = qsa;
		this._hashTag  = hashTag;
	}
	
	
	get fullPath()  { return this._fullPath;                                      }
	get routeDef()  { return this._routeDef;                                      }
	get lang()      { return this._lang;                                          }
	get pathVars()  { return this._pathVars;                                      }
	get qsa()       { return this._qsa;                                           }
	get hashTag()   { return this._hashTag;                                       }
	get isKnown()   { return !!this._routeDef;                                    }
	get isUnknown() { return !this._routeDef;                                     }
	get needsAuth() { return this._routeDef?.needsAuth ?? false;                  }
	get isRoot()    { return B_REST_Utils.url_getInfo(this._fullPath).path==="/"; } //Works whether we get "/" or ""
	
	get fullPath_wBaseUrl() { return `${process.env.BASE_URL}${this._fullPath}`.replace("//","/"); } // We rem double "//" because we join ex "/pwa/" w "/some/path"
	
	toObj()
	{
		const obj = {
			fullPath:     this._fullPath,
			routeDefName: this._routeDef?.name ?? null,
			lang:         this._lang,
			pathVars:     this._pathVars,
			qsa:          this._qsa,
			hashTag:      this._hashTag,
		};
		
		this._abstract_toObj(obj);
		
		return obj;
	}
		_abstract_toObj(obj) { B_REST_Utils.throwEx(`Must override base method`); }
};
