<template>
	<kb-admin-form reason="module" />
</template>

<script>

	export default
	{
		components: {
			KbAdminForm: () => import("@/custom/components/modules/entity_base/forms/KbAdminForm.vue"),
		}
	};
	
</script>