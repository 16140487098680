
import { B_REST_Utils, B_REST_FieldDescriptors } from "../../../../../classes";
import { AS_X_CONSTS,AS_AUTO,AS_INPUT,AS_AUTOCOMPLETE,AS_SELECT,AS_SWITCH, COMPONENTS_MULTIPLEABLE, PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT } from "../../modelField/BrFieldDb.vue";



export class GForm_XField_base
{
	_form                = null;  //Instance of GForm
	_fieldDescriptor     = null;  //Instance of B_REST_FieldDescriptor_X
	_selectFieldNamePath = null;  //Ex "something.user.firstName"
	_isReadOnly          = null;
	
	_cols_xs = null; //If specified, from 1-12 or "auto"
	_cols_sm = null; //If specified, from 1-12 or "auto"
	_cols_md = null; //If specified, from 1-12 or "auto"
	_cols_lg = null; //If specified, from 1-12 or "auto"
	_cols_xl = null; //If specified, from 1-12 or "auto"
	
	
	constructor(form, fieldDescriptor, selectFieldNamePath, isReadOnly)
	{
		this._form                = form;
		this._fieldDescriptor     = fieldDescriptor;
		this._selectFieldNamePath = selectFieldNamePath;
		this._isReadOnly          = isReadOnly;
		
		this._cols_xs = this._form.cols_xs;
		this._cols_sm = this._form.cols_sm;
		this._cols_md = this._form.cols_md;
		this._cols_lg = this._form.cols_lg;
		this._cols_xl = this._form.cols_xl;
	}
	
	
	get fieldDescriptor() { return this._fieldDescriptor;      }
	get name()            { return this._fieldDescriptor.name; }
	
	get selectFieldNamePath() { return this._selectFieldNamePath; }
	
	get isReadOnly() { return this._isReadOnly; }
	
	get cols_xs()    { return this._cols_xs; }
	set cols_xs(val) { this._cols_xs=val;    }
	
	get cols_sm()    { return this._cols_sm; }
	set cols_sm(val) { this._cols_sm=val;    }
	
	get cols_md()    { return this._cols_md; }
	set cols_md(val) { this._cols_md=val;    }
	
	get cols_lg()    { return this._cols_lg; }
	set cols_lg(val) { this._cols_lg=val;    }
	
	get cols_xl()    { return this._cols_xl; }
	set cols_xl(val) { this._cols_xl=val;    }
};



export class GForm_BrFieldDbField extends GForm_XField_base
{
	static get AS_X_CONSTS() { return AS_X_CONSTS; } //From BrFieldDb.vue
	static get AS_FAKE_CHOICE_DATA_TABLE() { return "dataTable"; }
	
	static get ITEMS_TYPE_NONE()              { return "none";             } //This choice is just to indicate we don't want to have dropdowns at all, when we could make another choice
	static get ITEMS_TYPE_AUTO_ENUM()         { return "auto_enum";        }
	static get ITEMS_TYPE_AUTO_BOOL()         { return "auto_bool";        }
	static get ITEMS_TYPE_SHARED_LIST()       { return "sharedList";       }
	static get ITEMS_TYPE_ARR_CUSTOM()        { return "arr_custom";       }
	static get ITEMS_TYPE_MODEL_LIST_COMMON() { return "modelList_common"; }
	static get ITEMS_TYPE_MODEL_LIST_CUSTOM() { return "modelList_custom"; }
	static get ITEMS_TYPE_DATA_TABLE_COMMON() { return "popup_common";     }
	static get ITEMS_TYPE_DATA_TABLE_CUSTOM() { return "popup_custom";     }
	
	
	_items_type          = null;   //One of ITEMS_TYPE_x, or NULL if nothing is possible at all
	_items_key           = null;
	_items_label         = null;
	_items_flatSearch    = null;
	_as                  = null;   //One of AS_X_CONSTS
	_isMultiple          = false;
	_sharedListName      = null;   //Only when ITEMS_TYPE_SHARED_LIST
	_commonModelListName = null;   //Only when ITEMS_TYPE_MODEL_LIST_COMMON
	_commonDataTableName = null;   //Only when ITEMS_TYPE_DATA_TABLE_COMMON
	
	
	constructor(form, fieldDescriptor, selectFieldNamePath, isReadOnly)
	{
		super(form, fieldDescriptor, selectFieldNamePath, isReadOnly);
		
		//Use setters
		this.items_type = this.items_can ? this.items_type_possibilities[0] : null;
		this.as         = AS_AUTO;
		this.isMultiple = false;
	}
	
	get items_type_possibilities()
	{
		//Helper vars
		const none       = GForm_BrFieldDbField.ITEMS_TYPE_NONE;                                                                   //This choice is just to indicate we don't want to have dropdowns at all
		const autoBool   = GForm_BrFieldDbField.ITEMS_TYPE_AUTO_BOOL;
		const autoEnum   = GForm_BrFieldDbField.ITEMS_TYPE_AUTO_ENUM;
		const sharedList = GForm_BrFieldDbField.ITEMS_TYPE_SHARED_LIST;
		const arr        = GForm_BrFieldDbField.ITEMS_TYPE_ARR_CUSTOM;
		const modelList  = [GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON,GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_CUSTOM];
		const dataTable  = [GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON,GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_CUSTOM];
		
		switch (this._fieldDescriptor.type)
		{
			case B_REST_FieldDescriptors.DB.TYPE_BOOL:    return [autoBool, arr];
			case B_REST_FieldDescriptors.DB.TYPE_ENUM:    return [autoEnum, arr];
			case B_REST_FieldDescriptors.DB.TYPE_INT:     return [none, sharedList, arr, ...modelList, ...dataTable];
			case B_REST_FieldDescriptors.DB.TYPE_JSON:    return [none, sharedList, arr, ...modelList, ...dataTable];
			case B_REST_FieldDescriptors.DB.TYPE_CUSTOM:  return [none, sharedList, arr, ...modelList, ...dataTable];
			case B_REST_FieldDescriptors.DB.TYPE_DECIMAL: return [none, sharedList, arr, ...modelList];
			case B_REST_FieldDescriptors.DB.TYPE_D:       return [none, sharedList, arr, ...modelList];
			case B_REST_FieldDescriptors.DB.TYPE_T:       return [none, sharedList, arr, ...modelList];
			case B_REST_FieldDescriptors.DB.TYPE_PHONE:   return [none, sharedList, arr, ...modelList];
			case B_REST_FieldDescriptors.DB.TYPE_EMAIL:   return [none, sharedList, arr, ...modelList];
			//NOTE: We could, but usualy we should do that another way. For ex with strings, should prolly use an enum or fk
			case B_REST_FieldDescriptors.DB.TYPE_DT:      return [];
			case B_REST_FieldDescriptors.DB.TYPE_STRING:  return [];
			case B_REST_FieldDescriptors.DB.TYPE_C_STAMP: return [];
			case B_REST_FieldDescriptors.DB.TYPE_U_STAMP: return [];
			case B_REST_FieldDescriptors.DB.TYPE_PWD:     return [];
			default: B_REST_Utils.throwEx(`Unknown field type "${this._fieldDescriptor.type}"`);
		}
	}
	get items_can() { return this.items_type_possibilities.length>0; }
	
	set items_type(val)
	{
		this._items_type          = val;
		this._items_key           = null;
		this._items_label         = null;
		this._items_flatSearch    = null;
		this._sharedListName      = null;
		this._commonModelListName = null;
		this._commonDataTableName = null;
		this.as                   = AS_AUTO;  //Use setter for multiple
	}
	get items_type()                   { return this._items_type; }
	get items_type_isSharedList()      { return this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_SHARED_LIST;       }
	get items_type_isCustomArr()       { return this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_ARR_CUSTOM;        }
	get items_type_isModelList()       { return this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_CUSTOM || this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON; }
	get items_type_isDataTable()       { return this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_CUSTOM || this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON; }
	get items_type_isCommonModelList() { return this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON; }
	get items_type_isCommonDataTable() { return this._items_type===GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON; }
	
	set items_key(val) { this._items_key=val;    }
	get items_key()    { return this._items_key; }
	get items_key_placeholder()
	{
		switch (this._items_type)
		{
			case null: case GForm_BrFieldDbField.ITEMS_TYPE_NONE: case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_BOOL: case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_ENUM:
				return null;
			case GForm_BrFieldDbField.ITEMS_TYPE_SHARED_LIST:
				return `model.pk / "a.b.c"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_ARR_CUSTOM:
				return `By default "key"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_CUSTOM:
				return `model.pk / "a.b.c"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_CUSTOM:
				return `model.pk / "a.b.c"`;
			default: B_REST_Utils.throwEx(`Unknown type "${this._items_type}"`);
		}
	}
	
	set items_label(val) { this._items_label=val;    }
	get items_label()    { return this._items_label; }
	get items_label_placeholder()
	{
		switch (this._items_type)
		{
			case null: case GForm_BrFieldDbField.ITEMS_TYPE_NONE: case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_BOOL: case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_ENUM:
				return null;
			case GForm_BrFieldDbField.ITEMS_TYPE_SHARED_LIST:
				return `model.toLabel() / "a.b.c"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_ARR_CUSTOM:
				return `By default "label"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_CUSTOM:
				return `model.toLabel() / "a.b.c"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_CUSTOM:
				return `model.toLabel() / "a.b.c"`;
			default: B_REST_Utils.throwEx(`Unknown type "${this._items_type}"`);
		}
	}
	
	set items_flatSearch(val) { this._items_flatSearch=val;    }
	get items_flatSearch()    { return this._items_flatSearch; }
	get items_flatSearch_placeholder()
	{
		switch (this._items_type)
		{
			case null: case GForm_BrFieldDbField.ITEMS_TYPE_NONE: case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_BOOL: case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_ENUM:
				return null;
			case GForm_BrFieldDbField.ITEMS_TYPE_SHARED_LIST:
				return `model.toLabel() / "a.b.c"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_ARR_CUSTOM:
				return `By default "flatSearch"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_CUSTOM:
				return `model.toLabel() / "a.b.c"`;
			case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_CUSTOM:
				return null; //Not enabled
			default: B_REST_Utils.throwEx(`Unknown type "${this._items_type}"`);
		}
	}
	
	set as(val)
	{
		this._as = val;
		if (!this.isMultiple_can) { this._isMultiple=false; }
	}
	get as()           { return this._as;           }
	get as_isNotAuto() { return this._as!==AS_AUTO; }
	get as_finalType()
	{
		if (this.as_isNotAuto) { return this._as; }
		
		switch (this._items_type)
		{
			case null: case GForm_BrFieldDbField.ITEMS_TYPE_NONE:
				return AS_INPUT;
			case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_ENUM:
				return this._fieldDescriptor.enum_members.length>=PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT ? AS_AUTOCOMPLETE : AS_SELECT;
			case GForm_BrFieldDbField.ITEMS_TYPE_AUTO_BOOL:
				return AS_SWITCH;
			case GForm_BrFieldDbField.ITEMS_TYPE_SHARED_LIST:
				return AS_AUTOCOMPLETE;
			case GForm_BrFieldDbField.ITEMS_TYPE_ARR_CUSTOM:
				return AS_AUTOCOMPLETE;
			case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_MODEL_LIST_CUSTOM:
				return AS_AUTOCOMPLETE;
			case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_COMMON: case GForm_BrFieldDbField.ITEMS_TYPE_DATA_TABLE_CUSTOM:
				return GForm_BrFieldDbField.AS_FAKE_CHOICE_DATA_TABLE;
			default: B_REST_Utils.throwEx(`Unknown type "${this._items_type}"`);
		}
	}
	set isMultiple(val) { this._isMultiple=val;    }
	get isMultiple()    { return this._isMultiple; }
	get isMultiple_can()
	{
		//NOTE: We could also allow if it's items_type_isDataTable, but doesn't seem to make sense unless it's a JSON for ex...
		if (!COMPONENTS_MULTIPLEABLE.includes(this.as_finalType)) { return false; }
		
		//NOTE: From a model's perspective, it only makes sense to hold multiple vals in a JSON field stored like "[...]", or maybe a custom field
		return this._fieldDescriptor.type_is_json || this._fieldDescriptor.type_is_custom;
	}
	
	get sharedListName()    { return this._sharedListName; }
	set sharedListName(val) { this._sharedListName=val;    }
	
	get commonModelListName()    { return this._commonModelListName; }
	set commonModelListName(val) { this._commonModelListName=val;    }
	
	get commonDataTableName()    { return this._commonDataTableName; }
	set commonDataTableName(val) { this._commonDataTableName=val;    }
};



export class GForm_SubModelListField extends GForm_XField_base
{
	constructor(form, fieldDescriptor, selectFieldNamePath, isReadOnly)
	{
		super(form, fieldDescriptor, selectFieldNamePath, isReadOnly);
	}
	
	//TODO: Should allow choosing between a loop of cards with auto add/del btns like in Flag's lead opportunities, or displaying a data table instead
};



export class GForm_FileField extends GForm_XField_base
{
	constructor(form, fieldDescriptor, selectFieldNamePath, isReadOnly)
	{
		super(form, fieldDescriptor, selectFieldNamePath, isReadOnly);
	}
};



export class GForm_OtherField extends GForm_XField_base
{
	constructor(form, fieldDescriptor, selectFieldNamePath, isReadOnly)
	{
		super(form, fieldDescriptor, selectFieldNamePath, isReadOnly);
	}
};
