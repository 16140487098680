import Vue from "vue";
import kebabCase from "lodash/kebabCase";

//Auto gets .vue files so we don't have to add them to the components node everywhere

parseDir(require.context("./", true,/\.vue$/));

function parseDir(context)
{
	for (const loop_file of context.keys())
	{
		const loop_componentConfig = context(loop_file);
		const loop_name = loop_file.split("/").pop().replace(/index.js|^\.\/|\.\w+$/g, "");
		const loop_componentName = kebabCase(loop_name);
		
		Vue.component(loop_componentName, loop_componentConfig.default || loop_componentConfig);
	}
}
