<!--
	Check usage docs in BrRightDrawer.vue
-->
<template>
	<portal-vue-item v-if="content.isActive" to="rightDrawerPortal"> <!-- Check BrRightDrawer's <portal-vue-dest name="rightDrawerPortal"> -->
		<div :key="uid">
			<slot />
		</div>
	</portal-vue-item>
</template>

<script>
	
	import { B_REST_Vuetify_RightDrawerContent } from "./BrRightDrawer.vue";
	
	
	
	export default {
		props: {
			content: {type:B_REST_Vuetify_RightDrawerContent, required:true},
		},
		computed: {
			uid() { return this._uid; }, //All Vue component instances have a unique id
		},
	};
	
</script>