
import GFile_base from "../../utils/GFile_base.js";



export default class GFile_PHPRouter extends GFile_base
{
	_model = null;
	
	constructor(model)
	{
		super();
		
		this._model = model;
	}
	
	
	get _abstract_title()    { return `PHP Route`; }
	get _abstract_filePath() { return `SERVER/custom/<projName>/routes/${this._model.modelClassName}.php`; }
	
	//Ex "some/module/stuff"
	get apiBaseUrl() { return this._model.apiBaseUrl_finalWOTrailingSlashes; }
	
	
	
	_abstract_output()
	{
		if (!this._model.routes_has) { return "// No need for this model"; }
		
		const checkAuth = this._model.apiBaseUrl_needsAccessToken ? '' : ', $checkAuth=false';
		
		return `<?

class ${this._model.RouteParser_ClassName} extends RouteParser_GenericModules_ListForm_base
{
	public function __construct()
	{
		parent::__construct('${this._model.Model_ClassName}', '${this.apiBaseUrl}'${checkAuth});
	}
	
	
	//Check base class docs
	protected function _abstract_parseRequest_customListFormRequests() { return false; }
	//Check base class docs
	protected function _abstract_hasPerms($action, ModelOptions_Load $loadOptions=null, Model_base $instance=null) { return true; }
	//Check base class docs
	protected function _abstract_trimOutput($action, &$obj) { }
};
`;
	}
};
