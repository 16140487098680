
import version_core              from "../../version.js";
import B_REST_Utils              from "../B_REST_Utils.js";
import B_REST_API                from "../api/B_REST_API.js";
import { B_REST_Request_base }   from "../api/B_REST_Request.js";
import B_REST_Response           from "../api/B_REST_Response.js";
import B_REST_Descriptor         from "../descriptors/B_REST_Descriptor.js";
import B_REST_Model              from "../models/B_REST_Model.js";
import B_REST_ModelList          from "../models/B_REST_ModelList.js";
import B_REST_App_SharedList     from "./B_REST_App_SharedList.js";
import B_REST_App_RouteDef_base  from "./B_REST_App_RouteDef_base.js";
import B_REST_App_RouteInfo_base from "./B_REST_App_RouteInfo_base.js";

//NOTE: If we need to add new langs later, will need to add for frontend & backend's core & custom locs + imports for Vuetify locale
import locMsgs_core_fr from "../../loc/fr.json";
import locMsgs_core_en from "../../loc/en.json";
import locMsgs_core_es from "../../loc/es.json";



/*
Singleton class req for descriptors & models to work
Extend it in usage and set singleton ptr so it gets used in those classes
Note that we can refer to it globally via window.bRESTApp
*/

export default class B_REST_App_base
{
	static get WINDOW_SINGLETON_PROP_NAME() { return 'bRESTApp'; } //Make it accessible in the console too, via window.bRESTApp
	
	static get BOOT_STATUS_IDLE()    { return 'idle';    }
	static get BOOT_STATUS_LOADING() { return 'loading'; }
	static get BOOT_STATUS_DONE()    { return 'done';    }
	
	static get REBOOT_REASONS_VERSION()          { return 'version';         } //Because was holding data about the app in a prev version
	static get REBOOT_REASONS_TOKEN_OR_USER()    { return 'tokenOrUser';     } //Because token expired, user got disabled etc
	static get REBOOT_REASONS_USER_LOGOUT()      { return 'userLogout';      } //From server's RouteParser_base::LOGOUT_REASONS_MANUAL
	static get REBOOT_REASONS_DIRECTIVE_LOGOUT() { return 'directiveLogout'; } //From server's RouteParser_base::LOGOUT_REASONS_DIRECTIVE
		//WARNING: If we rename, has impact in loc files. Check "app.booter.rebootReasons.x"
	
	static get ROUTES_TRAVEL_DIR_UNRELATED() { return 'unrelated'; }
	static get ROUTES_TRAVEL_DIR_TO_CHILD()  { return 'toChild';   }
	static get ROUTES_TRAVEL_DIR_TO_PARENT() { return 'toParent';  }
	
	static get ROUTES_PATH_VARS_PK_TAG() { return 'pkTag'; } //Ex for "/mesClients/{pkTag}/mesFactures". WARNING: Lots of impacts if we change this, ex routes_go_moduleForm_pkTag(). If we change this, also change B_REST_Model::API_PATH_VARS_PK_TAG + server's Model_base::API_PATH_VARS_PK_TAG
	
	static get WIFI_CHECK_INTERVAL_SECS()  { return 5;    }
	static get REJECT_UNSUCCESSFUL_CALLS() { return true; }
	static get MAX_PARALLEL_CONNECTIONS()  { return 4;    }
	
	static get TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_CORE_CALLS() { return [B_REST_App_base.API_CALL_BOOT,B_REST_App_base.API_CALL_LOGIN,B_REST_App_base.API_CALL_SUDO_IN,B_REST_App_base.API_CALL_SUDO_OUT]; }
	static get TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_ALWAYS()     { return true; } //If we add app info QSA to all calls, or only core ones like boot, login/out, sudoIn/out
	
	static get LOC_PATH_MODELS()      { return 'models';      } //As in "models.<modelName>.fields.<fieldName>"
	static get LOC_PATH_FIELDS()      { return 'fields';      } //As in "models.<modelName>.fields.<fieldName>"
	static get LOC_PATH_VALIDATION()  { return 'validation';  } //General purpose
	static get LOC_PATH_PLACEHOLDER() { return 'placeholder'; } //General purpose
	static get LOC_KEY_LABEL()        { return 'label';       } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_SHORT_LABEL()  { return 'shortLabel';  } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_ENUM_TAGS()    { return 'enum';        } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_BOOL_NULL()    { return 'bool_null';   } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_BOOL_TRUE()    { return 'bool_true';   } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	static get LOC_KEY_BOOL_FALSE()   { return 'bool_false';  } //As in {label, shortName, enum:{a,b,c}, bool_null, bool_true, bool_false}
	
	static get LS_KEY_BOOT_CALL_CACHE()         { return 'bootCallCache';   }
	static get LS_KEY_V_FRONTEND()              { return 'v_frontend';      }
	static get LS_KEY_V_BACKEND()               { return 'v_backend';       }
	static get LS_KEY_ACCESS_TOKEN()            { return 'accessToken';     }
	static get LS_KEY_USER()                    { return 'user';            }
	static get LS_KEY_LOCALE_LANG()             { return 'locale_lang';     }
	static get LS_KEY_LOCALE_TIME_ZONE()        { return 'locale_timeZone'; }
	static get LS_KEY_GDPR()                    { return 'gdpr';            }
	static get LS_KEY_PERMS()                   { return 'perms';           }
	static get LS_KEY_REBOOT_REASON()           { return 'rebootReason';    }
	
	static get API_CALL_BOOT()                 { return '/core/boot';                         }
	static get API_CALL_LOGIN()                { return '/core/login';                        }
	static get API_CALL_VERIFY_X_SEND_CODE()   { return '/core/verifyX_sendCode/{fieldName}'; }
	static get API_CALL_VERIFY_X_CONFIRM()     { return '/core/verifyX_confirm/{fieldName}';  }
	static get API_CALL_RESET_PWD_SEND_EMAIL() { return '/core/resetPwd_sendEmail';           }
	static get API_CALL_RESET_PWD_SAVE()       { return '/core/resetPwd_save';                }
	static get API_CALL_LOGOUT()               { return '/core/logout';                       }
	static get API_CALL_SUDO_IN()              { return '/core/sudoIn';                       }
	static get API_CALL_SUDO_OUT()             { return '/core/sudoOut';                      }
	static get API_CALL_HEARTBEAT()            { return '/core/heartbeat';                    }
	static get API_CALL_PENDING_UPLOADS()      { return "/core/files/pendingUploads/{type}";  } //Provides the base for something like "POST: /pendingUploads/lead-cv"
		static _BAD_AUTH_CALLS_TO_IGNORE = [B_REST_App_base.API_CALL_LOGIN, B_REST_App_base.API_CALL_SUDO_IN, B_REST_App_base.API_CALL_SUDO_OUT, B_REST_App_base.API_CALL_RESET_PWD_SEND_EMAIL, B_REST_App_base.API_CALL_RESET_PWD_SAVE]; //For tweakResponse_async_handler()
	
	//Must match w those in server
	static get AUTH_STATUS_ENABLED()       { return 'enabled';      }
	static get AUTH_STATUS_RESETTING_PWD() { return 'resettingPwd'; }
	static get AUTH_STATUS_DISABLED()      { return 'disabled';     }
	
	//Must be identical w server. Check bREST_base & RouteParser_base
	static get PENDING_UPLOADS_FIELDNAME_SINGLE()   { return "file";    }
	static get PENDING_UPLOADS_FIELDNAME_MULTIPLE() { return "files[]"; }
	
	//Must be identical w server. Check bREST_base & RouteParser_base
	static get REQUEST_QSA_FRONTEND_ROUTE_INFO() { return "_routeInfo"; }
	static get REQUEST_QSA_REFERRER_URL()        { return "_referrer";  }
	static get REQUEST_QSA_SUDO_HASH()           { return "_sh";        }
	static get FRONTEND_QSA_SUDO_HASH()          { return "_sh";        }
	
	static get RESPONSE_HEADERS_VERSION() { return 'x-b-rest-v'; }
	//Defined in server in RouteParser_base::CORE_INJECTION_x
	static get RESPONSE_CORE_PROPS_NODE()              { return '_core_';           }
	static get RESPONSE_CORE_PROP_MODEL_DEFS()         { return 'modelDefs';        }
	static get RESPONSE_CORE_PROP_SHARED_LIST_DEFS()   { return 'sharedListsDefs';  }
	static get RESPONSE_CORE_PROP_SHARED_LIST_ITEMS()  { return 'sharedListsItems'; }
	static get RESPONSE_CORE_PROP_ACCESS_TOKEN()       { return 'accessToken';      }
	static get RESPONSE_CORE_PROP_USER()               { return 'user';             }
	static get RESPONSE_CORE_PROP_PERMS()              { return 'perms';            }
	static get RESPONSE_CORE_PROP_REDIRECT()           { return 'redirect';         }
	static get RESPONSE_CORE_PROP_APP_DATA_CLEAR()     { return 'appDataClear';     }
	static get RESPONSE_CORE_PROP_REBOOT_REASONS_TAG() { return 'rebootReasonTag';  }
	static get RESPONSE_CORE_PROP_NOTIFS()             { return 'notifs';           }
	static get RESPONSE_CORE_PROP_CUSTOM_DATA()        { return 'customData';       }
	static get RESPONSE_CORE_PROP_LAST_QUERY_LOGS()    { return 'lastQueryLogs';    }
	static get RESPONSE_CORE_PROP_TODOS()              { return 'todos';            }
	static get RESPONSE_CORE_PROP_SCRIPT_DURATION()    { return 'scriptDuration';   }
	
	static _instance = null; //Global instance of a B_REST_App_base derived class
	
	
	
	_v_frontend               = null;                                         //Frontend version as "<custom app version>@<bREST core version>", ex "1234@1.2". Has same scheme in server (check bREST_base::version()). Ignoring package.json's version
	_api                      = null;                                         //Instance of B_REST_API
	_crypto_algo              = null;                                         //For B_REST_Utils::pwd_raw_toFrontendHash(). Must match what's in server's config.json, for "crypto.pwd_frontend_algo"
	_crypto_salt              = null;                                         //For B_REST_Utils::pwd_raw_toFrontendHash(). Must match what's in server's config.json, for "crypto.pwd_frontend_salt"
	_appLangs                 = [];                                           //Ex ["fr","en","es"]
	_routeDefs                = {};                                           //Map of routeName => B_REST_App_RouteDef_base der instances
	_routes_current_info      = null;                                         //Der instance of B_REST_App_RouteInfo_base, giving info about possibly matching B_REST_App_RouteDef_base, path vars, QSA, etc
	_routes_current_travelDir = B_REST_App_base.ROUTES_TRAVEL_DIR_UNRELATED;  //One of ROUTES_TRAVEL_DIR_x. Updated in _routes_updateCurrentTravelDirection(), ex to help with UI horizontal transitions between screens
	_boot_status              = B_REST_App_base.BOOT_STATUS_IDLE;             //One of BOOT_STATUS_x. Tells if boot_await() has yet to start / loading or done
	_boot_isUnbooting         = false;                                        //Flag to know when we ask for a reboot or reload app to a new URL, to help stop async stuff from happening + skip _abstract_beforeUnload_generalHook()
	_boot_promiseInfo         = null;                                         //As {promise, resolve, reject} For boot_await()
	_boot_sudoHash            = null;                                         //If we got a ?_sh=as98df0a8s in the URL, to try to quick connect or for other purposes
	_sharedLists              = null;                                         //Map of sharedListTag -> B_REST_App_SharedList instances
	_locale_lang              = null;                                         //Current lang in _appLangs
	_locale_timeZone          = null;                                         //User timeZone, for API calls
	_t_dicts                  = {};                                           //Map of {core:{<lang>:<jsonFile>}, custom:{<lang>:<jsonFile>}}
	_t_cache                  = null;                                         //For the current lang, map of {core, custom}, where each is also a map of locPath -> msg (before details tags are replaced), or false if not found
	_heartbeatInfo            = null;                                         //For contacting server from time to time, ex to get notifs. Either NULL or {freq, authOnly, setInterval_ptr:null, accessTokenSnapshot:null, isOngoing:false}
	_user_isSudoing           = false;                                        //If the current access token indicates that the user is sudoing as someone else and can revert to himself later
	_user                     = null;                                         //B_REST_Model instance of the user
	_perms                    = null;                                         //Perms info, like an arr or nest of custom tags
	_gdpr                     = null;                                         //Info about which cookies etc we accept (for communication w server & analytics, not for local storage)
	//Flags
	_defaultPagingSize    = 10;     //For B_REST_Model_Load_SearchOptions
	_debug_locPaths       = false;  //Flag to help testing - at the beginning of all successful translations, if we should display the translation's loc path. Check _t_x(). Displays like "<some.locPath>: Some translation"
	_debug_fieldNamePaths = false;  //Flag to help testing - for B_REST_FieldDescriptor_X etc, needing to display labels for model fields. Displays like "Some field [user.name]"
	_debug_responses      = false;  //Flag to help testing - dumps all received B_REST_Response. WARNING: Requires flag console_warn=true
	_debug_beforeReload   = false;  //Flag to help testing - sometimes it's hard to debug after an API call when page reloads right after; this adds a debugger JIT
	_debug_ignorePerms    = false;  //Flag to help testing
	_boot_cache           = false;  //If we want to cache the boot API call (will cause probs if modelDefs or sharedLists change on the server)
	
	
	
	//NOTE: For route defs, define using _routes_define() during der constructor
	constructor(options={})
	{
		B_REST_Utils.throwEx_setupGlobalErrorHandlers();
		
		B_REST_App_base._instance_assertNotCreatedYet();
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			version:      {accept:[String],   required:true}, //Custom app version; will get the core's version appended to it. Ignoring package.json's version
			api:          {accept:[Object],   required:true}, //Check below for docs
			crypto:       {accept:[Object],   required:true}, //Check below for docs
			flags:        {accept:[Object],   required:true}, //Check below for docs
			appLangs:     {accept:[Object],   required:true}, //Map of <lang> -> <jsonFile> of traductions
		}, "App");
			const options_crypto = B_REST_Utils.object_hasValidStruct_assert(options.crypto, {
				algo: {accept:[String], required:true},
				salt: {accept:[String], required:true},
			}, "Crypto");
			const options_flags = B_REST_Utils.object_hasValidStruct_assert(options.flags, {
				heartbeat_freq_secs:   {accept:[Number,false], required:true},                //For _heartbeatInfo - in secs, or false, if we don't want to have that. Used ex to check for new notifs, etc
				heartbeat_authOnly:    {accept:[Boolean],      required:true, default:true},  //For _heartbeatInfo - if heart beat should only happen if user is logged in
				defaultPagingSize:     {accept:[Number],       required:true, default:10},    //For B_REST_Model_Load_SearchOptions
				debug_locPaths:        {accept:[Boolean],      required:true, default:false}, //Flag to help testing - at the beginning of all successful translations, if we should display the translation's loc path. Check _t_x(). Displays like "<some.locPath>: Some translation"
				debug_fieldNamePaths:  {accept:[Boolean],      required:true, default:false}, //Flag to help testing - for B_REST_FieldDescriptor_X etc, needing to display labels for model fields. Displays like "Some field [user.name]"
				debug_responses:       {accept:[Boolean],      required:true, default:false}, //Flag to help testing - dumps all received B_REST_Response. WARNING: Requires flag console_warn=true
				debug_beforeReload:    {accept:[Boolean],      required:true, default:false}, //Flag to help testing - sometimes it's hard to debug after an API call when page reloads right after; this adds a debugger JIT
				debug_ignorePerms:     {accept:[Boolean],      required:true, default:false}, //To allow going to any route & have all perms request indicate we can, to help testing
				boot_cache:            {accept:[Boolean],      required:true, default:false}, //If we want to cache the boot API call (will cause probs if modelDefs or sharedLists change on the server)
				onErr_breakpoint:      {accept:[Boolean],      required:true, default:true},  //For B_REST_Utils::throwEx()
				onErr_showNativeAlert: {accept:[null,String],  required:true, default:true},  //For B_REST_Utils::throwEx(). One of B_REST_Utils::FLAGS_ON_ERR_SHOW_NATIVE_ALERT_x
				console_todo:          {accept:[Boolean],      required:true, default:true},  //For B_REST_Utils::console_todo()
				console_info:          {accept:[Boolean],      required:true, default:true},  //For B_REST_Utils::console_info()
				console_warn:          {accept:[Boolean],      required:true, default:true},  //For B_REST_Utils::console_warn()
				console_error:         {accept:[Boolean],      required:true, default:true},  //For B_REST_Utils::console_error()
			}, "Flags");
			const options_api = B_REST_Utils.object_hasValidStruct_assert(options.api, {
				baseURL:                 {accept:[String],   required:true},  //Ex "https://flagfranchise.keybook.com/api"
				mockCalls_async_handler: {accept:[Function], required:false}, //Ex async(request) { ... },
				mockCalls_enabled:       {accept:[Boolean],  default:false},
				wifi_checkInterval_secs: {accept:[Number],   default:B_REST_App_base.WIFI_CHECK_INTERVAL_SECS},
				rejectUnsuccessfulCalls: {accept:[Boolean],  default:B_REST_App_base.REJECT_UNSUCCESSFUL_CALLS},
				timeout_msecs:           {accept:[Number],   required:false},
				maxParallelConnections:  {accept:[Number],   default:B_REST_App_base.MAX_PARALLEL_CONNECTIONS},
			}, "API");
		
		this._crypto_algo = options_crypto.algo;
		this._crypto_salt = options_crypto.salt;
		
		this._defaultPagingSize    = options_flags.defaultPagingSize;
		this._debug_locPaths       = options_flags.debug_locPaths;
		this._debug_fieldNamePaths = options_flags.debug_fieldNamePaths;
		this._debug_responses      = options_flags.debug_responses;
		this._debug_beforeReload   = options_flags.debug_beforeReload;
		this._debug_ignorePerms    = options_flags.debug_ignorePerms;
		this._boot_cache           = options_flags.boot_cache;
		
		//Stuff for B_REST_Utils errs & logs management
		{
			B_REST_Utils.flags_onErr_breakpoint      = options_flags.onErr_breakpoint;
			B_REST_Utils.flags_onErr_showNativeAlert = options_flags.onErr_showNativeAlert;
			B_REST_Utils.flags_console_todo          = options_flags.console_todo;
			B_REST_Utils.flags_console_info          = options_flags.console_info;
			B_REST_Utils.flags_console_warn          = options_flags.console_warn;
			B_REST_Utils.flags_console_error         = options_flags.console_error;
		}
		
		//Check if we'll need heartbeat to query server from time to time, ex to get new info like notifs etc
		if (options_flags.heartbeat_freq_secs)
		{
			this._heartbeatInfo = {
				freq:                options_flags.heartbeat_freq_secs,
				authOnly:            options_flags.heartbeat_authOnly,
				setInterval_ptr:     null,
				accessTokenSnapshot: null,
				isOngoing:           false,
			};
		}
		
		//Prep a promise but don't fulfill it now - we'll do so in boot_await()
		{
			this._boot_promiseInfo = {promise:null, resolve:null, reject:null};
			
			this._boot_promiseInfo.promise = new Promise((s,f) =>
			{
				this._boot_promiseInfo.resolve = s;
				this._boot_promiseInfo.reject  = f;
			});
		}
		
		/*
		Check if frontend version is OK, otherwise clear LS
		Further backend version checks will be done in all call's tweakResponse_async_handler()
		*/
		{
			this._v_frontend = `${options.version}@${version_core}`;
			
			const ls_version = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_V_FRONTEND,/*throwIfNull*/false);
			if (ls_version)
			{
				if (ls_version!==this._v_frontend) { this.appData_clear(/*isWrongVersion*/true); } //NOTE: Will restore some keys like locale stuff
			}
			else { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_V_FRONTEND,this._v_frontend); }
		}
		
		//Setup API (must do before we call locale_lang setter for the 1st time
		{
			this._api = new B_REST_API({
				baseURL:                 options_api.baseURL,
				mockCalls_async_handler: options_api.mockCalls_async_handler,
				mockCalls_enabled:       options_api.mockCalls_enabled,
				wifi_checkInterval_secs: options_api.wifi_checkInterval_secs,
				rejectUnsuccessfulCalls: options_api.rejectUnsuccessfulCalls,
				timeout_msecs:           options_api.timeout_msecs,
				maxParallelConnections:  options_api.maxParallelConnections,
				wifi_onChange_handler: (has) =>
				{
					B_REST_Utils.console_todo([
						`Change icon + display toaster if it flips to false`
					]);
				},
				log_handler: (msg, isError, details=null) =>
				{
					if (isError)
					{
						if (details instanceof B_REST_Response && details.isBadAuth) { return; }
						
						B_REST_Utils.console_error(`B_REST_Custom::log_handler(): ${msg}`, details);
						
						this.notifs_error_locPath("app.log_handler.error");
					}
					else
					{
						B_REST_Utils.console_info(`B_REST_Custom::log_handler(): ${msg}`, details);
					}
				},
				/*
				Before certain calls, could add QSA and such, ex for analytics
				For now, we'll only do that for boot & login
				*/
				tweakRequest_async_handler: async(request) =>
				{
					const shouldAddQSA = B_REST_App_base.TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_ALWAYS || B_REST_App_base.TWEAK_REQUEST_ASYNC_HANDLER_ADD_QSA_CORE_CALLS.includes(request.path_raw);
					
					if (shouldAddQSA)
					{
						const routeInfoJSON = B_REST_Utils.json_encode(this._routes_current_info.toObj());
						
						request.qsa[B_REST_App_base.REQUEST_QSA_FRONTEND_ROUTE_INFO] = routeInfoJSON;
						request.qsa[B_REST_App_base.REQUEST_QSA_REFERRER_URL]        = B_REST_Utils.url_referrer||"";
					}
				},
				/*
				Happens before call resolves / rejects
				IMPORTANT:
					When we're trying to reboot, to prevent async stuff from happening for the wrong user etc, we'll force the async handler to never end
				*/
				tweakResponse_async_handler: async(response) =>
				{
					return new Promise(async(s,f) =>
					{
						if (this._debug_responses) { B_REST_Utils.console_warn(`Debugging response`,response); }
						
						//If we're trying to reboot, stop here wo resolving nor rejecting, to prevent async stuff from happening when it shouldn't. Check _boot_setUnbooting() docs
						if (this._boot_isUnbooting) { return; }
						
						//If response was in err
						if (!response.isSuccess)
						{
							//Maybe we should clear all and reboot
							{
								let mustReboot = false;
								
								//Bad / expired token
								if (response.errorType_isAuth_tokenNotFound || response.errorType_isAuth_tokenExpired)
								{
									B_REST_Utils.console_warn("Access token was deleted / not found, therefore rebooting");
									mustReboot = true;
								}
								/*
								Other auth probs, ex errorType_isAuth_disabledUser or errorType_isAuth_resettingPwd
								Only do so if we're not in login type calls, as we'd like to show it to the user instead of kicking it out
								NOTE:
									We mustn't reboot if we try to sudo in/out, so we stay connected as the current user
									If it happens during a heartbeat call, we must reboot
								*/
								else if (response.isBadAuth)
								{
									const handlesBadAuthCallsAnotherWay = B_REST_App_base._BAD_AUTH_CALLS_TO_IGNORE.includes(response.request?.path_raw);
									
									if (!handlesBadAuthCallsAnotherWay)
									{
										B_REST_Utils.console_warn("Access token was deleted / not found, therefore rebooting");
										mustReboot = true;
									}
								}
								
								if (mustReboot)
								{
									this.appData_clear(/*isWrongVersion*/true); //NOTE: Will restore some keys like locale stuff & frontend version
									this.reboot(B_REST_App_base.REBOOT_REASONS_TOKEN_OR_USER);
									return; //IMPORTANT: Don't resolve nor reject, to prevent async stuff from happening when it shouldn't
								}
							}
							
							//All other cases - we don't have to do anything more w it here; let usage decide. IMPORTANT: Don't ret as string, otherwise we can't do nothing w the response
							f(response); return;
						}
						
						/*
						If we're in a heartbeat call and the access token state changed from what we expected to have,
						then drop the call before we run into _calls_interceptCoreProps() & _abstract_calls_tweakResponse_hook(), to avoid hell
						*/
						if (response.request?.path_raw===B_REST_App_base.API_CALL_HEARTBEAT)
						{
							const snapshotAccessToken     = this._heartbeatInfo.accessTokenSnapshot;
							const apiAccessToken          = this._api.accessToken_public;
							const responseAccessTokenInfo = response.data[B_REST_App_base.RESPONSE_CORE_PROPS_NODE]?.[B_REST_App_base.RESPONSE_CORE_PROP_ACCESS_TOKEN] ?? null;
							
							if (snapshotAccessToken!==apiAccessToken || (responseAccessTokenInfo && snapshotAccessToken!==responseAccessTokenInfo.public))
							{
								B_REST_Utils.console_warn(`Ignoring an heartbeat call response, since access token don't match`, {snapshotAccessToken,apiAccessToken,responseAccessTokenInfo});
								s(); return;
							}
						}
						
						try
						{
							/*
							Before doing anything, check if backend app version matches. If set and not OK:
								-On boot call, clear session, but no need to reboot app
								-On all next calls (while navigating), means we might not have the right modelDefs anymore etc, so we should clear session and reboot app
							WARNING:
								-If we cache boot calls and version is now diff on server, we'll only know when we do another call later
							NOTE:
								-Frontend version check are done in constructor before boot
							*/
							{
								const v_backend = response.headers[B_REST_App_base.RESPONSE_HEADERS_VERSION];
								
								const ls_version = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_V_BACKEND,/*throwIfNull*/false);
								if (ls_version)
								{
									if (ls_version!==v_backend)
									{
										this.appData_clear(/*isWrongVersion*/true); //NOTE: Will restore some keys like locale stuff & frontend version
										
										//If it's a call that happens later while using the app (not at boot)
										if (this.boot_isBooted)
										{
											this.reboot(B_REST_App_base.REBOOT_REASONS_VERSION);
											return; //IMPORTANT: Don't resolve nor reject, to prevent async stuff from happening when it shouldn't
										}
									}
								}
								else { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_V_BACKEND,v_backend); }
							}
							
							//Check for changes to modelDefs, sharedLists, etc, but only if already booted, otherwise will be taken care of in boot_await()
							const corePropsThenDirectives = this.boot_isBooted ? await this._calls_interceptCoreProps(response) : {}; //Could throw
								/*
								IMPORTANT:
									If we realized that we have to reboot the app ex because we changed user, the above will have fired _calls_handleRedirection(),
									so we should also stop before we call _abstract_calls_tweakResponse_hook()
								*/
								if (this._boot_isUnbooting) { return; }
							
							//Let custom code tweak response
							await this._abstract_calls_tweakResponse_hook(response,corePropsThenDirectives); //Could throw
							
							//If we're trying to reboot, stop here wo resolving nor rejecting, to prevent async stuff from happening when it shouldn't. Check _boot_setUnbooting() docs
							if (this._boot_isUnbooting) { return; }
							
							/*
							If we must redirect; here, happens only if app is already booted, otherwise redirectInfo handled in boot_await()
							Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
							IMPORTANT:
								-Contrary to in boot_await(), should happen before resolving here
							*/
							if (corePropsThenDirectives?.redirectInfo)
							{
								this._calls_handleRedirection(corePropsThenDirectives.redirectInfo);
								
								//Re-check again
								if (this._boot_isUnbooting) { return; }
							}
							
							//Resolve
							s();
						}
						catch (e) { f(e); }
					});
				},
				afterCall_general_handler: (response) =>
				{
					this._calls_checkDebugProps(response);
					
					this._abstract_calls_afterCall_general_handler(response);
				},
			});
			
			//Check if we can get info about GDPR cookies etc, access token & perms (not requiring to get model defs yet)
			{
				this._gdpr_checkLoadFromLS();
				this._accessToken_checkLoadFromLS();
				this._perms_checkLoadFromLS();
			}
			
			//Check if we've got something like ?_sh=89sf908ad in URL, and quickly hide it
			{
				this._boot_sudoHash = B_REST_Utils.url_current_getQSA(B_REST_App_base.FRONTEND_QSA_SUDO_HASH);
				
				if (this._boot_sudoHash) { B_REST_Utils.url_current_removeQSA(B_REST_App_base.FRONTEND_QSA_SUDO_HASH); }
			}
		}
		
		//Create translation dicts for all supported langs
		{
			this._t_dicts = {
				core: {
					fr: locMsgs_core_fr,
					en: locMsgs_core_en,
					es: locMsgs_core_es,
				},
				custom: {},
			};
			
			for (const loop_appLang in options.appLangs)
			{
				this._appLangs.push(loop_appLang);
				
				const loop_appLangDict = options.appLangs[loop_appLang];
				this._t_dicts.custom[loop_appLang] = loop_appLangDict;
			}
		}
		
		//Hook here to define routeDefs
		this._abstract_routes_defineRoutes();
		
		//Figure out current route's info - note that we could be on a URL that doesn't make sense / should yield a 404
		{
			const fullPath = B_REST_Utils.url_current_getAbsPath(/*wQSA*/true);
			
			this._routes_current_info = this.routes_getRouteInfo_fromPath(fullPath);
		}
		
		/*
		To find lang to use, check in this order:
			-Did we land on a URL that is known and distinct than it in other langs ?
			-Did we have something in local storage ?
			-Take the fallback lang (1st defined one)
		Then, upon receiving boot call's response, we could have to change the lang against user / received link etc
		*/
		{
			const currentRoute_lang = this._routes_current_info.lang; //NOTE: NULL if URL is the same in multiple langs
			
			this.locale_lang = currentRoute_lang || B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_LOCALE_LANG,/*throwIfNull*/false) || this.locale_lang_fallback; //Use setter, to update LS
		}
		
		//Then der has to do its constructor, then later call boot_await() to get the modelDefs etc
	}
		static throwEx(msg, details=null,onlyForUINotifs=false) { B_REST_Utils.throwEx(`B_REST_App_base: ${msg}`,details,onlyForUINotifs); }
		       throwEx(msg, details=null,onlyForUINotifs=false) { B_REST_Utils.throwEx(`B_REST_App_base: ${msg}`,details,onlyForUINotifs); }
		static _throwEx_abstractMissing() { B_REST_App_base.throwEx(`Must override base method`); }
		       _throwEx_abstractMissing() { B_REST_App_base.throwEx(`Must override base method`); }
	
	
	
	//DERIVED SINGLETON RELATED & HELPERS
		static get instance()
		{
			return B_REST_App_base._instance || this.throwEx(`Global instance of B_REST_App_base derived not yet set up`);
		}
			static _instance_assertNotCreatedYet() { if(!!B_REST_App_base._instance){B_REST_App_base.throwEx("Singleton derived already instantiated");} }
		/*
		Instantiate a derived instance and sets it as the singleton. We must then call boot_await(), to fetch model defs, logged user etc
		Ex:
			<DerivedClass>.instance_init({...});
			await <DerivedClass>.instance.boot_await();
		*/
		static instance_init(options={})
		{
			B_REST_App_base._instance_assertNotCreatedYet();
			
			const DerivedClass = B_REST_Utils.class_ptr_fromBaseStaticFunc(this);
			B_REST_App_base._instance = new DerivedClass(options); //Throws if we already have an instance
			
			//Make it accessible in the console too
			window[B_REST_App_base.WINDOW_SINGLETON_PROP_NAME] = B_REST_App_base._instance;
			
			B_REST_App_base._instance.boot_await();
		}
	
	
	
	//MISC ACCESSORS
		get v_frontend()           { return this._v_frontend;           }
		get defaultPagingSize()    { return this._defaultPagingSize;    }
		get debug_locPaths()       { return this._debug_locPaths;       }
		get debug_fieldNamePaths() { return this._debug_fieldNamePaths; }
		get debug_responses()      { return this._debug_responses;      }
		get debug_beforeReload()   { return this._debug_beforeReload;   }
		get debug_ignorePerms()    { return this._debug_ignorePerms;    }
		//Prevents having to import B_REST_Utils everywhere
		get utils() { return B_REST_Utils; }
	
	
	
	//BOOT RELATED
		get boot_isBooting()   { return this._boot_status!==B_REST_App_base.BOOT_STATUS_DONE; }
		get boot_isBooted()    { return this._boot_status===B_REST_App_base.BOOT_STATUS_DONE; }
		get boot_isUnbooting() { return this._boot_isUnbooting;                               }
		get boot_sudoHash()    { return this._boot_sudoHash;                                  }
		/*
		For when we must reboot app after user login/out, lang change etc, or when we want to go to a new URL while reloading the app
		Allows to:
			-Leave wo triggering _abstract_beforeUnload_generalHook()
			-Stop ongoing API calls from returning, to prevent async code from continuing, so they hang in call's tweakResponse_async_handler()
		*/
		_boot_setUnbooting()
		{
			this._boot_isUnbooting = true;
				//WARNING: Ex in Vue, if we ever get probs when unbooting or setting user to NULL, could add an abstract func to call B_REST_VueApp_base::_vue_destroy()
			
			if (this._debug_beforeReload) { debugger; }
		}
		/*
		Fetchs model defs, shared lists, logged user etc
		Before this, constructor has taken care of loading what we could from local storage (lang, timeZone, accessToken)
		Call this at least once to start booting, and then multiple places can await this, to either wait until booted, or die when it failed
		Might abruptly end in a reboot, ex if LS' server version doesn't match with server's current version
		NOTE:
			Additionnal QSA will get added via tweakRequest_async_handler(), like for analytics, referrer URL, current URL, etc
		*/
		async boot_await()
		{
			//If already booting / done / failed
			if (this._boot_status!==B_REST_App_base.BOOT_STATUS_IDLE) { return this._boot_promiseInfo.promise; }
			
			this._boot_status = B_REST_App_base.BOOT_STATUS_LOADING;
			
			try
			{
				const request = new this.GET(B_REST_App_base.API_CALL_BOOT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
				request.needsAccessToken = false;
				/*
				Check if we've got sudo info to auto log user at the same time, but don't do so if it's for the resetPwd form
				NOTE: Don't check to ignore when !user_authStatus_enabled because we might not have the user yet so it'll never work
				*/
				if (this._boot_sudoHash)
				{
					if (this.routes_current_def?.type_isResetPwd) { B_REST_Utils.console_warn(`Ignoring received hash because we're on the resetPwd page`); }
					else
					{
						request.qsa_add(B_REST_App_base.REQUEST_QSA_SUDO_HASH,this._boot_sudoHash);
					}
				}
				
				const requestOptions = {};
				
				//Check if we want to cache/reuse a previous boot call's response
				if (this._boot_cache) { requestOptions.cache={key:B_REST_App_base.LS_KEY_BOOT_CALL_CACHE}; }
				
				const response = await this.call(request, requestOptions); //Might throw
				
				//Here we'll parse modelDefs, sharedList etc
				const corePropsThenDirectives = await this._calls_interceptCoreProps(response);
				
				//If response didn't contain a user, then check to fetch from LS or create a new one
				if (!this._user)
				{
					if (this.user_ls_has()) { this.user_ls_retrieve();                       }
					else                    { this.user_createFromObj({}, /*updateLS*/true); }
				}
				
				//Finalize booting custom code
				await this._abstract_boot_await(response, corePropsThenDirectives);
				
				/*
				Now that all is fully loaded and we know we don't need to go to another route, setup a general before unload event
				Will allow usage to call for ex user_ls_update() JIT if req
				Skipped if rebooting
				*/
				window.addEventListener('beforeunload', (event) =>
				{
					//If we should skip the check, to prevent hell. Check _boot_setUnbooting() docs
					if (this._boot_isUnbooting) { return true; }
					
					const trueOrConfirmMsg = this._abstract_beforeUnload_generalHook();
					
					if (trueOrConfirmMsg===true) { return; }
					else if (B_REST_Utils.string_is(trueOrConfirmMsg))
					{
						event.preventDefault();
						event.returnValue = trueOrConfirmMsg;
						return trueOrConfirmMsg;
					}
					else { this.throwEx(`_abstract_beforeUnload_generalHook() must ret true or confirm msg`); }
				});
				
				this._boot_status = B_REST_App_base.BOOT_STATUS_DONE;
				B_REST_Utils.console_info("Booted");
				this._boot_promiseInfo.resolve();
				
				/*
				Check to setup an interval to poll server from time to time, ex to get new notifs
				Since we want to handle maybe only having this when we have a user, and that it could be complicated to figure out when user went from NEW to EXISTING,
				to KISS we'll just let the interval go on wo stopping it, no matter if we login/out or sudoIn/Out, and we'll just check when we receive a response,
				if the access token's val stayed the same before and after calls
				NOTE:
					-First execution is not done at time of creation of the interval
					-Relevant code for handling notifs etc happens later in _calls_interceptCoreProps()
				WARNING:
					-For now, we never stop the interval once started, no matter what (read the above for why)
				*/
				if (this._heartbeatInfo)
				{
					this._heartbeatInfo.setInterval_ptr = setInterval(async() =>
					{
						//Ignore if we're already waiting for a response, or unbooting
						if (this._heartbeatInfo.isOngoing || this._boot_isUnbooting) { return; }
						//Ignore if we've got a pub user and we only want to do that when we're auth
						if (this._heartbeatInfo.authOnly && this.user_isPublic) { return; }
						
						//Note current state
						this._heartbeatInfo.isOngoing          = true;
						this._heartbeatInfo.accessTokenSnapshot = this._api.accessToken_public;  //Can be NULL
						
						const request = new this.POST(B_REST_App_base.API_CALL_HEARTBEAT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
						request.needsAccessToken = false;
						try
						{
							await this.call(request); //NOTE: If successful, likely to reboot and hang before finishing awaiting, to prevent hell
						}
						catch (response)
						{
							B_REST_Utils.console_error(`Caught err while doing heartbeat`,response); //WARNING: Could cause prob to switch to throwEx() - check code below
						}
						this._heartbeatInfo.isOngoing = false;
					}, this._heartbeatInfo.freq*1000);
				}
				
				/*
				We might have wanted to start somewhere but server ret that we should go away (by reloading app or continuing)
				IMPORTANT:
					-Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
					-Don't move this before resolving, as if redirect mentions we mustn't reload the app, then app must be ready in order for route change to work
				*/
				if (corePropsThenDirectives?.redirectInfo) { this._calls_handleRedirection(corePropsThenDirectives.redirectInfo); }
				
				//Check if we had a reboot reason to explain to the user
				{
					const rebootReason = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_REBOOT_REASON, /*throwIfNull*/false);
					
					if (rebootReason)
					{
						B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_REBOOT_REASON);
						
						//Just don't warn for the obvious
						if (rebootReason!==B_REST_App_base.REBOOT_REASONS_USER_LOGOUT)
						{
							const locPath = `app.booter.rebootReasons.${rebootReason}`;
							this.notifs_tmp({msg:this.t_custom_alt(locPath,locPath), color:"info"});
						}
					}
				}
			}
			catch (e)
			{
				const errorMsg = e instanceof B_REST_Response ? e.errorMsg : (e.message||e);
				
				this._boot_promiseInfo.reject(`${this.t_custom("app.booter.error")}:\n${errorMsg}`);
			}
		}
			//Stuff to do in boot, after user etc are all set up. Can specify extra directives to do next like redirect
			async _abstract_boot_await(response, corePropsThenDirectives) { this._throwEx_abstractMissing(); }
			//Must ret true or a confirm msg. IMPORTANT: Can't ret a promise to wait for async stuff to complete
			_abstract_beforeUnload_generalHook() { this._throwEx_abstractMissing(); }
			//Allow configuring global hooks on models, like for B_REST_Descriptor::validation_custom_fastFuncs() & B_REST_Descriptor::validation_custom_asyncFuncs()
			_commonDefs_setupDescriptorHooks()
			{
				//Setup things for Model_User, like unicity & email verification
				{
					const userDescriptor = B_REST_Descriptor.commonDefs_get("User");
					
					userDescriptor.validation_custom_fastFuncs_add((model) =>
					{
						const recoveryEmail            = model.select("recoveryEmail");
						const recoveryEmail_isVerified = model.select("recoveryEmail_isVerified");
						
						if (recoveryEmail.isEmpty || recoveryEmail_isVerified.val) //NOTE: Here isEmpty makes sense as being valid, unless we change so email is required (for now it's optional)
						{
							recoveryEmail.validation_custom_errorMsg = null;
						}
						else
						{
							const locPath = 'descriptors.User.recoveryEmail_notVerified';
							recoveryEmail.validation_custom_errorMsg = this.t_custom_alt(locPath,locPath);
						}
						
						B_REST_Utils.console_todo([`Not yet validating unicity for username, email & eventually phone`]);
					});
				}
				
				this._abstract_commonDefs_setupDescriptorHooks();
			}
				//Allow configuring global hooks on models, like for B_REST_Descriptor::validation_custom_fastFuncs() & B_REST_Descriptor::validation_custom_asyncFuncs()
				_abstract_commonDefs_setupDescriptorHooks() { this._throwEx_abstractMissing(); }
		/*
		To use ex to:
			-After user login, now that we know correct lang to refetch modelDefs etc
			-After changing lang, so we stay on the same route but in the new lang
		Alias to routes_reload(), except that we can specify a reason, ex because user got kicked out (one of REBOOT_REASONS_x)
			then we can after reboot explain what happened (at the end of boot_await())
		IMPORTANT:
			-Won't trigger _abstract_beforeUnload_generalHook()
			-Ongoing API calls won't end and get stuck in call's tweakResponse_async_handler()
			-Must use that when we must login/out, sudo in/out or get kicked out, to prevent async stuff from resuming code that would affect the wrong user
			-Check _boot_setUnbooting() docs
			-If we define custom reboot reasons, we'll have to define their translation in custom loc, under path "app.booter.rebootReasons"
		*/
		reboot(reason=null)
		{
			if (reason) { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_REBOOT_REASON,reason); } //Set this aside for next page reload in boot_await()
			
			this.routes_reload();
		}
	
	
	
	//USER RELATED
		/*
		Cookies (and such) related obj
		Can be NULL
		WARNING: Don't edit obj from outside, otherwise LS won't be notified of its changes. Use in RO here and for changes, use gdpr_apply()
		*/
		get gdpr() { return this._gdpr; }
		//Use this to update cookies related stuff
		gdpr_apply(gdprObjOrNULL)
		{
			this._gdpr_updateLS(gdprObjOrNULL);
			
			this._gdpr = gdprObjOrNULL;
			
			//Changing GDPR stuff should maybe trigger something
		}
			//Saves to LS wo doing nothing more
			_gdpr_updateLS(gdprObjOrNULL)
			{
				if (gdprObjOrNULL)
				{
					const gdprJSON = B_REST_Utils.json_encode(gdprObjOrNULL);
					B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_GDPR, gdprJSON);
				}
				else { B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_GDPR); }	
			}
			_gdpr_checkLoadFromLS()
			{
				const gdprJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_GDPR,/*throwIfNull*/false);
				
				if (gdprJSON) { this._gdpr=B_REST_Utils.json_decode(gdprJSON); }
			}
		
		//B_REST_API doesn't check if call rets access tokens, so it must be done in user code
		_accessToken_set(accessToken_public, accessToken_private, isSudoing)
		{
			const accessTokenJSON = B_REST_Utils.json_encode({public:accessToken_public, private:accessToken_private, isSudoing});
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_ACCESS_TOKEN, accessTokenJSON);
			
			this._api.accessToken_set(accessToken_public, accessToken_private);
			this._user_isSudoing = isSudoing;
		}
			_accessToken_checkLoadFromLS()
			{
				const accessTokenJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_ACCESS_TOKEN,/*throwIfNull*/false);
				
				if (accessTokenJSON)
				{
					const accessTokenInfo = B_REST_Utils.json_decode(accessTokenJSON);
					this._accessToken_set(accessTokenInfo.public, accessTokenInfo.private, accessTokenInfo.isSudoing);
				}
			}
		_accessToken_clear()
		{
			B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_ACCESS_TOKEN);
			
			this._api.accessToken_clear();
			this._user_isSudoing = false;
		}
		
		get user()                         { return this._user;                                                       }
		get user_has()                     { return !!this._user;                                                     } //WARNING: Avoid using; Even if a new user, we always instantiate one, so likely to be always NULL except before boot ends
		get user_pk()                      { return   this._user?.pk??null;                                           }
		get user_isNew()                   { return !(this._user?.pk??null);                                          } //NOTE: We could do !this.user_pk but slower
		get user_isPublic()                { return this._user && !this._api.accessToken_isValid;                     }
		get user_isAuth()                  { return this._user && this._api.accessToken_isValid;                      }
		get user_isSudoing()               { return this._user && this._user_isSudoing;                               }
		get user_type()                    { return this._user?.select("type")?.val ?? null;                          }
		get user_authStatus()              { return this._user?.select("authStatus")?.val ?? null;                    }
		get user_authStatus_enabled()      { return this.user_authStatus===B_REST_App_base.AUTH_STATUS_ENABLED;       }
		get user_authStatus_resettingPwd() { return this.user_authStatus===B_REST_App_base.AUTH_STATUS_RESETTING_PWD; }
		get user_authStatus_disabled()     { return this.user_authStatus===B_REST_App_base.AUTH_STATUS_DISABLED;      }
		get user_displayName()             { return this._abstract_user_displayName;                                  }
		user_type_is(type)                 { return this.user_type===type;                                            }
		user_type_isNot(type)              { return this.user_type!==type;                                            }
		get user_extraData_ui()            { return this._user?.extraData_ui  ?? null;                                }
		get user_extraData_api()           { return this._user?.extraData_api ?? null;                                }
			/*
			Must handle case when we don't have a user yet. Could ex ret only first name, last name, etc
			Ex:
				return this._user?.select_firstNonEmptyVal("firstName+lastName|firstName|lastName|userName|recoveryEmail") || "???";
			*/
			get _abstract_user_displayName() { return this._throwEx_abstractMissing(); }
		
		
		/*
		Perms arr / nest of tags and such
		Can be NULL
		IMPORTANT:
			Can't automate perms by model, as model could be used accross multiple modules w diff perms for each
			Also shouldn't need to know about diff perms between 2 diff PKs; if one PK shouldn't be viewable for ex, we should just not receive it from the API
		WARNING: Don't edit obj from outside, otherwise LS won't be notified of its changes. Use in RO here and for changes, use perms_set()
		*/
		get perms() { return this._perms; }
		//Use this to update perms
		perms_change(permsOrNULL)
		{
			this._perms_updateLS(permsOrNULL);
			
			this._perms = permsOrNULL;
			
			//Changing perms should maybe trigger something
		}
			//Saves to LS wo doing nothing more
			_perms_updateLS(permsOrNULL)
			{
				if (permsOrNULL)
				{
					const permsJSON = B_REST_Utils.json_encode(permsOrNULL);
					B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_PERMS, permsJSON);
				}
				else { B_REST_Utils.localStorage_remove(B_REST_App_base.LS_KEY_PERMS); }	
			}
			_perms_checkLoadFromLS()
			{
				const permsJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_PERMS,/*throwIfNull*/false);
				
				if (permsJSON) { this._perms=B_REST_Utils.json_decode(permsJSON); }
			}
		//Helpers to control action btn states, action dropdowns enabled choices etc
		perms_can( tag,details=null) { return this._debug_ignorePerms || this._abstract_perms_can(tag,details); }
		perms_cant(tag,details=null) { return !this.perms_can(tag,details); }
			//Must ret bool, using _perms (could be NULL)
			_abstract_perms_can(tag,details=null) { this._throwEx_abstractMissing(); }
		
		/*
		Re-create user model from passed fields data
		Optionnally puts the new data to LS
		Fires a custom hook to further manipulate the user, before it's actually "set" in the class
			Could also use that to update perms via perms_change()
		NOTE:
			-Will trigger to change locale (lang & timezone), however it won't cause refetching of modelDefs from server in new lang.
				In theory we should avoid that prob by forcing to reload app when user changes, so _calls_interceptCoreProps() receive both modelDefs & user for the same lang
		*/	
		user_createFromObj(userObj, updateLS)
		{
			/*
			Ex if we were user #123 and now we receive #456, then we should create a new instance (and access token would be diff)
			But if we had no PK yet and now we've got one, we -could- reuse the instance,
			it's just that for now it's hard to tell what happened:
				1) was creating own profile and now we've got a PK
				2) was not logged and now just logged
			So, for now, we'll never reuse the current user instance
			*/
			const reuseCurrentUserModel = false;
			
			const user = reuseCurrentUserModel ? this._user : B_REST_Model.commonDefs_make("User");
			
			user.fromObj(userObj, /*skipIllegalChanges*/false);
			this._abstract_user_createFromObj(user, userObj);
			
			//Check to change locale
			{
				const user_lang     = user.select("lang").val;
				const user_timeZone = user.select("timeZone").val;
				
				if (user_lang)     { this.locale_lang    =user_lang;     }
				if (user_timeZone) { this.locale_timeZone=user_timeZone; }
			}
			
			this._user = user;
			
			if (updateLS) { this.user_ls_update(); }
		}
			//NOTE: newUser is an instance of B_REST_Model, not yet put back to app's _user prop
			_abstract_user_createFromObj(userModel, userObj) { this._throwEx_abstractMissing(); }
		//Checks if we have a "copy" of the user's data in local storage
		user_ls_has() { return B_REST_Utils.localStorage_has(B_REST_App_base.LS_KEY_USER); }
		//Saves a snapshot of the user's data to local storage
		user_ls_update()
		{
			if (!this._user) { this.throwEx(`Can't update user to LS; user not set`); }
			
			const userObj  = this._user.toObj(/*onlyWithUnsavedChanges*/false, /*forAPICall*/false); //IMPORTANT: forAPICall must be false so we ret _extraData_ui_
			const userJSON = B_REST_Utils.json_encode(userObj);
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_USER, userJSON);
		}
		//Recreate user from local storage. Throws if not in LS
		user_ls_retrieve()
		{
			const userJSON = B_REST_Utils.localStorage_get(B_REST_App_base.LS_KEY_USER,/*throwIfNull*/true);
			const userObj  = B_REST_Utils.json_decode(userJSON);
			this.user_createFromObj(userObj, /*updateLS*/false);
		}
		
		/*
		NOTE:
			-Preserves some keys like frontend version and locale, but we can't preserve LS_KEY_GDPR since struct could change over time
			-Doesn't reload app; use reboot() after if needed
		IMPORTANT:
			-Don't change code so this calls user_createFromObj(), as it can be fired before boot_await()
		*/
		appData_clear(isWrongVersion)
		{
			this._user           = null; //WARNING: Ex in Vue, if we ever get probs when unbooting or setting user to NULL, could add an abstract func to call B_REST_VueApp_base::_vue_destroy()
			this._user_isSudoing = false;
			this._perms          = null;
			B_REST_Utils.localStorage_clear();
			
			if (this._api) { this._api.accessToken_clear(); }
			
			//Some stuff should remain anyways
			{
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_V_FRONTEND,       this._v_frontend);
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_LOCALE_LANG,      this._locale_lang);
				B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_LOCALE_TIME_ZONE, this._locale_timeZone);
				
				if (!isWrongVersion) { this._gdpr_updateLS(this._gdpr); }
			}
		}
		
		
		
	//CORE CALLS
		/*
		For the password, send it raw, so if using a B_REST_FieldDescriptor_DB, don't do pwdVal_toFrontendHash()
		Extra data for things like where we came from, redirect link, etc
		Check server's RouteParser_Core::_coreCalls_login() docs
		App will then redirect by itself after, via call's tweakResponse_async_handler()
		Always resolve w the B_REST_Response instance, no matter:
			-Successful login (check response.isSuccess; however if it triggers a reboot, func will never end)
			-Bad credentials, disabled user or user in pwd resetting status (check ex if response.isBadAuth, response.errorType_isAuth_disabledUser, ...)
				-> Code in tweakResponse_async_handler() will kickout any user in those status, except if we're in login / sudo
			-Other probs (check if !response.isSuccess)
		IMPORTANT:
			For now, a successful login should cause the app to reboot and this func to never end
		NOTE:
			Check boot_await() for how referrer URL, current URL etc are passed
		*/
		async login(userName, pwd_raw, extraData={})
		{
			if (!userName || !pwd_raw) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_LOGIN); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.needsAccessToken = B_REST_Request_base.NEEDS_ACCESS_TOKEN_DONT;
			request.data = {
				userName,
				pwd_frontendHash: this.pwd_raw_toFrontendHash(pwd_raw),
				extraData,
			};
			
			return this._login_sudoX(request);
		}
		//Variant where we do like sudoIn(), except we don't need to have an access token in advance
		async login_wSudoHash(sudoHash, extraData={})
		{
			if (!sudoHash) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_LOGIN); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.needsAccessToken = false;
			request.data = {sudoHash, extraData};
			
			return this._login_sudoX(request);
		}
			/*
			Common code for login(), sudoIn() & sudoOut()
			Changing of user, token, lang etc will happen via tweakResponse_async_handler() parsing of core props injections
			*/
			async _login_sudoX(request)
			{
				try
				{
					return await this.call(request); //NOTE: If successful, likely to reboot and hang before finishing awaiting, to prevent hell
				}
				catch (response) { return response; }
			}
		/*
		To verify a user's email
		Can be done by someone else, so not against own access token
		Will send an email w a code in it like "123 456", then we must call verifyRecoveryEmail_confirm() w that code
		Like other core calls, always resolve, no matter what
		*/
		async verifyRecoveryEmail_sendCode(recoveryEmail) { return this._verifyX_sendCode("recoveryEmail",recoveryEmail); }
		/*
		Use after calling verifyRecoveryEmail_sendEmail()
		If it's for a user that already exist, it'll update its verified status in DB, otherwise if it's not created yet it'll do nothing
		Can be done by someone else, so not against own access token
		Rets whether the code matches the address or not (by ret a 200 or a failure)
		*/
		async verifyRecoveryEmail_confirm(recoveryEmail,verificationCode) { return this._verifyX_confirm("recoveryEmail",recoveryEmail,verificationCode); }
		//NOTE: Not yet used, but same logic as for recoveryEmail, except it's to confirm via SMS
		async verifyPhone_sendCode(phone) { return this._verifyX_sendCode("phone",phone); }
		//NOTE: Not yet used, but same logic as for recoveryEmail, except it's to confirm via SMS
		async verifyPhone_confirm(phone,verificationCode) { return this._verifyX_confirm("phone",phone,verificationCode); }
			//To use w verifyRecoveryEmail_x() & verifyPhone_x()
			async _verifyX_sendCode(fieldName, fieldVal)
			{
				if (!fieldVal) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
				
				const request = new this.POST(B_REST_App_base.API_CALL_VERIFY_X_SEND_CODE, {fieldName}); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
				request.needsAccessToken = false;
				request.data             = {fieldVal};  //Either the val of the recoveryEmail or phone
				
				try
				{
					return await this.call(request);
				}
				catch (response) { return response; }
			}
			async _verifyX_confirm(fieldName, fieldVal, verificationCode)
			{
				if (!fieldVal || !verificationCode) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
				
				const request = new this.POST(B_REST_App_base.API_CALL_VERIFY_X_CONFIRM, {fieldName}); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
				request.needsAccessToken = false;
				request.data             = {fieldVal, verificationCode};  //Either the val of the recoveryEmail or phone
				
				try
				{
					return await this.call(request);
				}
				catch (response) { return response; }
			}
		/*
		Check server's RouteParser_Core::_coreCalls_resetPwd_sendEmail() docs
		Always resolve w the B_REST_Response instance, no matter:
			-Successful email sending (check response.isSuccess)
			-Unknown recovery email (check if response.isBadAuth)
			-Other probs, ex can't because user is disabled (check if !response.isSuccess)
		*/
		async resetPwd_sendEmail(recoveryEmail)
		{
			if (!recoveryEmail) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_RESET_PWD_SEND_EMAIL); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.needsAccessToken = false;
			request.data = {recoveryEmail};
			
			try
			{
				return await this.call(request);
			}
			catch (response) { return response; }
		}
		/*
		Check server's RouteParser_Core::_coreCalls_resetPwd_save() docs
		Always resolve w the B_REST_Response instance, no matter:
			-Successful updating (check response.isSuccess)
			-Unknown idUser / hash (check if response.isBadAuth)
			-Other probs, ex can't because user is disabled (check if !response.isSuccess)
		*/
		async resetPwd_save(hash, newPwd_raw)
		{
			if (!hash || !newPwd_raw) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_RESET_PWD_SAVE);
			request.needsAccessToken = false;
			request.data = {
				hash,
				newPwd_frontendHash: this.pwd_raw_toFrontendHash(newPwd_raw),
			};
			
			try
			{
				return await this.call(request);
			}
			catch (response) { return response; }
		}
		/*
		Extra data like where to go next
		Check server's RouteParser_Core::_coreCalls_logout() docs
		App will then redirect by itself after, via call's tweakResponse_async_handler()
		Rets if it could logout, or throw if we weren't even logged in
		NOTE:
			Check boot_await() for how referrer URL, current URL etc are passed
		*/
		async logout(extraData={})
		{
			if (!this.user_isAuth) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Not logged`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_LOGOUT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.data = {extraData};
			
			try
			{
				await this.call(request); //NOTE: If successful, likely to reboot and hang before finishing awaiting, to prevent hell
				return true;
			}
			catch (response) { return false; }
		}
		/*
		Expects a sudo hash giving info on which user to become next
		The user_isSudoing flag will flip to true
		Check login() docs, as both work and ret the same way
		Check server's RouteParser_Core::_coreCalls_sudoIn() docs
		*/
		async sudoIn(sudoHash)
		{
			if (!this.user_isAuth) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Must be logged to switch to another user`); }
			if (!sudoHash)         { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Empty info`);                               }
			
			const request = new this.POST(B_REST_App_base.API_CALL_SUDO_IN); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			request.data = {sudoHash};
			
			return this._login_sudoX(request);
		}
		/*
		The user_isSudoing flag will likely flip to false, unless we nested multiple sudo
		Check login() docs, as both work and ret the same way
		Check server's RouteParser_Core::_coreCalls_sudoOut() docs
		App will indicate where to go after
		App will then redirect by itself after, via call's tweakResponse_async_handler()
		*/
		async sudoOut()
		{
			if (!this._user_isSudoing) { return B_REST_Response.from_err(B_REST_Response.CODES_BAD_REQUEST,`Not sudoing`); }
			
			const request = new this.POST(B_REST_App_base.API_CALL_SUDO_OUT); //IMPORTANT: Check tweakRequest_async_handler() & tweakResponse_async_handler() in constructor
			
			return this._login_sudoX(request);
		}
		
		/*
		Helper to post a single file to the /pendingUploads/{type}/ path, saving it ex in /<projectDir>/data_secure/_pendingAPIUploads/,
			and returning its hash in that folder (via a B_REST_Response instance)
		Usage ex:
			const response = await pendingUploads_single("cv", new B_REST_DOMFilePtr(...));
			const response = await pendingUploads_single("cv", B_REST_DOMFilePtr.from_fileInput(<input>));
		NOTES:
			-Sets shortName as "pendingUploads-<type>"
			-If we need more control over the request, maybe it's better to implement on our own (ex adding QSA...)
		*/
		async pendingUploads_single(type, file, uploadProgressCallback=null, downloadProgressCallback=null)
		{
			return this._pendingUploads_x(type, "data_add_file_single",B_REST_App_base.PENDING_UPLOADS_FIELDNAME_SINGLE,file, uploadProgressCallback,downloadProgressCallback);
		}
		/*
		Like the above, but expects an arr of B_REST_DOMFilePtr instances, and B_REST_Response will yield an arr of hashes
		Usage ex:
			const response = await pendingUploads_multiple("docs", [new B_REST_DOMFilePtr(...),new B_REST_DOMFilePtr(...),new B_REST_DOMFilePtr(...)]);
			const response = await pendingUploads_multiple("docs", B_REST_DOMFilePtr.from_fileInput(<input multiple>))
		*/
		async pendingUploads_multiple(type, files, uploadProgressCallback=null, downloadProgressCallback=null)
		{
			return this._pendingUploads_x(type, "data_add_file_multiple",B_REST_App_base.PENDING_UPLOADS_FIELDNAME_MULTIPLE,files, uploadProgressCallback,downloadProgressCallback);
		}
			async _pendingUploads_x(type, methodName,postFieldName,fileOrFiles, uploadProgressCallback=null, downloadProgressCallback=null)
			{
				const request = new this.POST_Multipart(B_REST_App_base.API_CALL_PENDING_UPLOADS, {type});
				request.needsAccessToken = false;
				await request[methodName](postFieldName, fileOrFiles); //Will crash if param isn't OK
				request.shortName = `pendingUploads-${type}`;
				
				const requestOptions = {};
					if (uploadProgressCallback)   { requestOptions.uploadProgressCallback   = uploadProgressCallback;   }
					if (downloadProgressCallback) { requestOptions.downloadProgressCallback = downloadProgressCallback; }
				
				return this.call(request, requestOptions);
			}
	
	
	
	//API RELATED
		get api() { return this._api; }
		//Check B_REST_API funcs docs
			get GET()             { return this._api.GET;             }
			get GET_File()        { return this._api.GET_File;        }
			get POST()            { return this._api.POST;            }
			get POST_Multipart()  { return this._api.POST_Multipart;  }
			get PUT()             { return this._api.PUT;             }
			get PUT_Multipart()   { return this._api.PUT_Multipart;   }
			get PATCH()           { return this._api.PATCH;           }
			get PATCH_Multipart() { return this._api.PATCH_Multipart; }
			get DELETE()          { return this._api.DELETE;          }
			async call(request, requestOptions=null)                                            { return this._api.call(request,requestOptions);                         }
			async call_getObjectUrl(request)                                                    { return this._api.call_getObjectUrl(request);                           }
			async call_download(request, baseNameWExt=null, domContainer=null)                  { return this._api.call_download(request,baseNameWExt,domContainer);     }
			static async call_external(method, url, data=null, headers={}, resolveErrors=false) { return this._api.call_external(method,url,data,headers,resolveErrors); }
		/*
		We do [raw pwd] > [frontend to backend encryption] > [db encryption]
		So API calls never show raw pwd
		Ex "pwd" -> "<intermediate>6a4b49f07b599056dc1dc08d2c68afd8c2dd49af1c346fb51c7d8d56576354a6e2608e8e161151cb92886e4fbde45ac9e4c1a69bbcf0566cce108abc0200e60a"
		For more info, check server's CryptoUtils
		Expects an algo like "sha512"
		NOTE:
			There's also a new native API, but the prob is that it's async: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
		*/
		pwd_raw_toFrontendHash(pwd) { return B_REST_Utils.pwd_raw_toFrontendHash(pwd,this._crypto_algo,this._crypto_salt); }
		/*
		Check for changes to modelDefs, sharedLists, etc
		Rets an obj of directives to do next, like {redirectInfo}
		WARNING:
			For now, will break if we receive model defs twice in same boot, because it'll complain they're already defined
		*/
		async _calls_interceptCoreProps(response)
		{
			const thenDirectives = {}; //Might contain something like {redirectInfo}
			
			if (response.isSuccess && response.data_contentType_is_JSON)
			{
				//Check if the response contained a "_core_" prop containing stuff that could appear in any call
				const coreProps = response.data[B_REST_App_base.RESPONSE_CORE_PROPS_NODE];
				if (coreProps)
				{
					const appDataClear     = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_APP_DATA_CLEAR];
					const rebootReasonTag  = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_REBOOT_REASONS_TAG];
					const redirectInfo     = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_REDIRECT];
					const modelDefs        = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_MODEL_DEFS];
					const sharedListsDefs  = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_SHARED_LIST_DEFS];
					const sharedListsItems = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_SHARED_LIST_ITEMS];
					const accessTokenInfo  = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_ACCESS_TOKEN];
					const userObj          = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_USER];
					const permsObj         = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_PERMS];
					const notifs           = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_NOTIFS];
					const customData       = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_CUSTOM_DATA];
					const lastQueryLogs    = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_LAST_QUERY_LOGS];
					const todos            = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_TODOS];
					const scriptDuration   = coreProps[B_REST_App_base.RESPONSE_CORE_PROP_SCRIPT_DURATION];
					
					//Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
					if (redirectInfo) { thenDirectives.redirectInfo=redirectInfo; }
					
					if (appDataClear)
					{
						this.appData_clear(/*isWrongVersion*/false);
						
						if (rebootReasonTag) { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_REBOOT_REASON,rebootReasonTag); } //Check to display something after a reboot to the user. WARNING: Must be done after appData_clear(), otherwise it'll get cleared
						
						//Check if we wanted to reload the app AND also clear user. If so, we should instead not bother w user and just reboot earlier to prevent hell w frameworks reactivity
						if (redirectInfo?.reloadApp && accessTokenInfo===false && userObj===false)
						{
							B_REST_Utils.console_warn(`Terminating the app quickly because we lost our token and we have to reload`);
							this._calls_handleRedirection(redirectInfo);
							return;
						}
					}
					else if (rebootReasonTag) { B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_REBOOT_REASON,rebootReasonTag); } //Check to display something after a reboot to the user
					
					if (modelDefs)
					{
						B_REST_Descriptor.commonDefs_fetch_fromServerBootResponse(modelDefs);
						
						//Allow configuring global hooks on models, like for B_REST_Descriptor::validation_custom_fastFuncs() & B_REST_Descriptor::validation_custom_asyncFuncs()
						if (this.boot_isBooting) { this._commonDefs_setupDescriptorHooks(); }
					}
					
					//WARNING: Check warning in method docs
					if (sharedListsDefs) { this._sharedLists_defineFromAPICall(sharedListsDefs); }
					
					if (sharedListsItems) { this._sharedLists_updateFromAPICall(sharedListsItems); }
					
					if      (accessTokenInfo)         { this._accessToken_set(accessTokenInfo.public,accessTokenInfo.private,accessTokenInfo.isSudoing); }
					else if (accessTokenInfo===false) { this._accessToken_clear();                                                                       }
					
					if (userObj)              { this.user_createFromObj(userObj, /*updateLS*/true); }
					else if (userObj===false) { this.user_createFromObj({},      /*updateLS*/true); }
					
					if (permsObj) { this.perms_change(permsObj); }
					
					if (notifs) { this._notifs_appendFromAPICall(notifs); }
					
					if (customData) { await this._abstract_calls_interceptCoreProps_customDataNode(customData,thenDirectives); }
					
					//Debug stuff
					{
						if (lastQueryLogs)  { B_REST_Utils.console_info("Got query logs from server API call",          lastQueryLogs);  }
						if (todos)          { B_REST_Utils.console_info("Got todos from server API call",               todos);          }
						if (scriptDuration) { B_REST_Utils.console_info("Got script duration info from server API call",scriptDuration); }
					}
				}
			}
			
			return thenDirectives;
		}
			//Happens before API call ends
			async _abstract_calls_interceptCoreProps_customDataNode(customProps,thenDirectives) { this._throwEx_abstractMissing(); }
		//Checks if an API call ret some debug stuff like err msgs and dumps
		_calls_checkDebugProps(response)
		{
			if (response.debug_errorMsg) { B_REST_Utils.console_info(`Server response debug errorMsg: ${response.logMsg_debug_errorMsg}`); }
			
			if (response.debug_isDump)
			{
				const dump = `<div style="overflow-x:scroll; margin:16px; padding:16px; border:1px solid grey;">
								<h1 class="text-h1">Dump for call ${response.request.url_debug}</h1>
								${response.data}
							</div>`;
				B_REST_Utils.documentBody.innerHTML += dump;
			}
		}
		/*
		Check server's RouteParser_base::_output_json_injectCore_redirect_x() docs
		NOTE:
			If we reloadApp=true, check _boot_setUnbooting() docs
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		_calls_handleRedirection(redirectInfo)
		{
			switch (redirectInfo.type)
			{
				case "routeName":
					this.routes_go_name(redirectInfo.routeName, redirectInfo.pathVars, redirectInfo.qsa, redirectInfo.reloadApp);
				break;
				case "path":
					this.routes_go_path(redirectInfo.path, redirectInfo.reloadApp);
				break;
				case "external":
					this.routes_go_external(redirectInfo.url, redirectInfo.newWindow);
				break;
				default:
					this.throwEx(`Unknown redirect type "${redirectInfo.type}"`);
				break;
			}
		}
		//Called on each call, no matter successful or not, before it actually resolves/rejects, so we can change the response's data or next directives to run
		async _abstract_calls_tweakResponse_hook(response, corePropsThenDirectives) { this._throwEx_abstractMissing(); }
		//Same as _abstract_calls_tweakResponse_hook(), but after. Could intercept errs here like if errorType is a bad login or access token expired...
		_abstract_calls_afterCall_general_handler(response) { this._throwEx_abstractMissing(); }
	
	
	
	//LOCALE & TRANSLATION (t_) RELATED
		get appLangs() { return this._appLangs; }
		
		get locale_lang_fallback() { return this._appLangs[0]; }
		
		get locale_lang() { return this._locale_lang; }
		//IMPORTANT: Doesn't force app reload on change, so we must then manually call reboot(), to end up on the same route but w the url in the right lang + modelDefs etc in the right lang
		set locale_lang(val)
		{
			this._locale_lang = val;
			this._api.lang    = val;
			
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_LOCALE_LANG, val);
			
			this._t_cache = {
				core:   {},
				custom: {},
			};
		}
			_abstract_onLangChange() { this._throwEx_abstractMissing(); }
			
		//To use directly on an img's :src. Note that these apply to langs and not countries
		static locale_lang_getIcon(lang)
		{
			switch (lang)
			{
				case "fr": return require("../../assets/countries/fr.svg");
				case "en": return require("../../assets/countries/us.svg");
				case "es": return require("../../assets/countries/es.svg");
				case "ru": return require("../../assets/countries/ru.svg");
				case "jp": return require("../../assets/countries/jp.svg");
				default:   this.throwEx(`Unknown lang "${lang}"`);
			}
		}
		
		get locale_timeZone() { return this._locale_timeZone; }
		set locale_timeZone(val)
		{
			B_REST_Utils.localStorage_set(B_REST_App_base.LS_KEY_LOCALE_TIME_ZONE, val);
			this._api.timeZone    = val;
			this._locale_timeZone = val;
		}
		
		/*
		Translates something in @/bREST/core/<lang>.json
		Usage ex:
			{
				"some": {
					"path": "Field {fieldName} must have max {maxLength} chars"
				}
			}
			t_core("some.path", {fieldName:"firstName",maxLength:20})
				-> "Field firstName must have max 20 chars"
		If path doesn't exist, would yield "%some.path@core%" + a warning
		*/
		t_core(locPath, details=null, lang=null) { return this._t_x("core",locPath,details,/*onNotFoundRetAsPercent*/true,/*retSubTree*/false,lang); }
		//Helper for things like "models.fields.validation.db.maxLength"
		t_core_field_validation(tag, details=null, lang=null)
		{
			return this._t_x("core", `${B_REST_App_base.LOC_PATH_MODELS}.${B_REST_App_base.LOC_PATH_FIELDS}.${B_REST_App_base.LOC_PATH_VALIDATION}.${tag}`, details, /*onNotFoundRetAsPercent*/true, /*retSubTree*/false, lang);
		}
		//Helper for things like "models.fields.placeholder.db.number.between"
		t_core_field_placeholder(tag, details=null, lang=null)
		{
			return this._t_x("core", `${B_REST_App_base.LOC_PATH_MODELS}.${B_REST_App_base.LOC_PATH_FIELDS}.${B_REST_App_base.LOC_PATH_PLACEHOLDER}.${tag}`, details, /*onNotFoundRetAsPercent*/true, /*retSubTree*/false, lang);
		}
		//Variation to ret a sub tree / NULL
		t_core_subTree(locPath, details=null, lang=null) { return this._t_x("core",locPath,details,/*onNotFoundRetAsPercent*/false,/*retSubTree*/true,lang); }
			//Inner func used in all methods
			_t_x(coreOrCustom, locPath, detailsOrNULL, onNotFoundRetAsPercent, retSubTree, lang=null)
			{
				if (!locPath) { this.throwEx("Got no locPath"); }
				
				if (lang===null) {lang=this._locale_lang;}
				
				const langCache    = this._t_cache[coreOrCustom];
				const debugLocPath = `%${locPath}@${coreOrCustom}-${lang}%`;
				
				//Check if we have it in cache, and that it's not valid
				if (langCache[locPath]===false) { return onNotFoundRetAsPercent ? debugLocPath : null; }
				
				//If not in cache, try to find it
				if (langCache[locPath]===undefined)
				{
					let currentNode = this._t_dicts[coreOrCustom][lang]; //Root node of a lang, either in core.json or custom.json
					
					for (const loop_part of locPath.split("."))
					{
						if (!currentNode[loop_part])
						{
							langCache[locPath] = false; //Indicate to cache that it doesn't exist
							if (onNotFoundRetAsPercent)
							{
								this._t_x_warnNotFound(locPath, coreOrCustom, "not found", lang);
								return debugLocPath;
							}
							else { return null; }
						}
						currentNode = currentNode[loop_part];
					}
					
					if (retSubTree)
					{
						//NOTE: Here, no need to put to cache back a whole sub tree
						
						return currentNode;
					}
					
					if (!B_REST_Utils.string_is(currentNode))
					{
						langCache[locPath] = false; //Indicate to cache that it doesn't exist
						if (onNotFoundRetAsPercent)
						{
							this._t_x_warnNotFound(locPath, coreOrCustom, "found, but not ending on a string", lang);
							return debugLocPath;
						}
						else { return null; }
					}
					
					//Put in cache
					langCache[locPath] = currentNode;
				}
				
				let translation = langCache[locPath];
				
				//Now check to replace every occurrence of details stuff
				if (detailsOrNULL)
				{
					B_REST_Utils.object_assert(detailsOrNULL);
					
					for (const loop_detailKey in detailsOrNULL)
					{
						translation = translation.replace(new RegExp(`{${loop_detailKey}}`,"g"), detailsOrNULL[loop_detailKey]);
					}
				}
				
				//If we get here, we always have a string, so no subTree / NULL / false
				return this.debug_locPaths ? `<${debugLocPath}>: ${translation}` : translation;
			}
				_t_x_warnNotFound(locPath, coreOrCustom, msg, lang=null)
				{
					if (lang===null) {lang=this._locale_lang;}
					B_REST_Utils.console_warn(`Translation path "${locPath}" @ ${coreOrCustom}-${lang} ${msg}`);
				}
		
		//Same as the core one above, but for custom things in json files behind _appLangs
		t_custom(locPath, details=null, lang=null) { return this._t_x("custom",locPath,details,/*onNotFoundRetAsPercent*/true,/*retSubTree*/false,lang); }
		t_custom_field_label(modelName, fieldName, details=null, lang=null)
		{
			this.throwEx(`Shouldn't use this method, because it's not gonna work if the field loc comes from the server and isn't injected in the local json file. Check B_REST_Descriptor::field_loc_label()`);
			
			const baseLocPath = B_REST_App_base.t_custom_field_baseLocPath(modelName, fieldName);
			return this._t_x("custom", `${baseLocPath}.${B_REST_App_base.LOC_KEY_LABEL}`, details, /*onNotFoundRetAsPercent*/true, /*retSubTree*/false);
		}
		//Variation where we ret NULL when not found
		t_custom_orNULL(locPath, details=null, lang=null) { return this._t_x("custom",locPath,details,/*onNotFoundRetAsPercent*/false,/*retSubTree*/false,lang); }
		//Variation to ret a sub tree / NULL
		t_custom_subTree(locPath, details=null, lang=null) { return this._t_x("custom",locPath,details,/*onNotFoundRetAsPercent*/false,/*retSubTree*/true,lang); }
		//Helper to indicate that a loc path wasn't found
		t_custom_warnNotFound(locPath, lang=null) { return this._t_x_warnNotFound(locPath,"custom","not found",lang); }
		//Ex "models.User.fields.firstName"
		static t_custom_field_baseLocPath(modelName, fieldName) { return `${B_REST_App_base.LOC_PATH_MODELS}.${modelName}.${B_REST_App_base.LOC_PATH_FIELDS}.${fieldName}`; }
		//Tries to use our custom loc, and if not available, fallbacks to a core one. If even core one isn't found, we'll get its %%
		t_custom_alt(customLocPath,coreFallbackLocPath, details=null, lang=null)
		{
			//Where false/true,false is for onNotFoundRetAsPercent,retSubTree
			return this._t_x("custom",customLocPath,details,false,false,lang) ?? this._t_x("core",coreFallbackLocPath,details,true,false,lang);
		}
	
	
	
	//MODELS RELATED
		//Helper to create an instance of X. Check B_REST_Model::commonDefs_make() docs
		models_make(name,obj=null) { return B_REST_Model.commonDefs_make(name,obj); }
		//Helper to create a list of instances of X model. Check B_REST_ModelList::commonDefs_make() docs
		modelLists_make(name,useForLoading=true, useCachedShare=false) { return B_REST_ModelList.commonDefs_make(name,useForLoading,useCachedShare); }
	
	
	
	//SHARED LISTS RELATED
		/*
		From a call that yields something like:
			{
				'campaigns':       {type:'modelList', items:[], 'modelClassName'=>'Model_Campaign'},
				'activitySectors': {type:'modelList', items:[], 'modelClassName'=>'Model_ActivitySector'},
				'currencies':      {type:'custom',    items:[]},
			}
		*/
		_sharedLists_defineFromAPICall(sharedListDefs)
		{
			this._sharedLists = {};
			
			for (const loop_tag in sharedListDefs)
			{
				const loop_sharedListDef = sharedListDefs[loop_tag];
				
				this._sharedLists[loop_tag] = new B_REST_App_SharedList(loop_tag, loop_sharedListDef.type, loop_sharedListDef.items, loop_sharedListDef.modelClassName);
			}
		}
		/*
		From a call that yields something like the following:
			{
				'campaigns':       <items>,
				'activitySectors': <items>,
				'currencies':      <items>,
			]
		NOTE:
			We might only receive lists that got altered
		*/
		_sharedLists_updateFromAPICall(sharedLists)
		{
			for (const loop_tag in sharedLists)
			{
				const loop_items = sharedLists[loop_tag].items;
				
				this._sharedLists[loop_tag].updateData(loop_items);
			}
		}
		//Just rets the tags
		get sharedLists_tags() { return Object.keys(this._sharedLists); }
		//Rets a B_REST_ModelList instance, or custom arr
		sharedLists_getSrc(tag) { return this._sharedLists_get(tag).src; }
		//Rets the arr of B_REST_Model instances in a B_REST_ModelList, or custom arr
		sharedLists_getItems(tag) { return this._sharedLists_get(tag).items; }
			_sharedLists_get(tag)
			{
				if (!this._sharedLists[tag]) { this.throwEx(`Unknown shared list "${tag}"`); }
				return this._sharedLists[tag];
			}
	
	
	
	//ROUTES RELATED
		get routeDefs() { return this._routeDefs; }
		//Must call _routes_define() in there. Called in constructor
		_abstract_routes_defineRoutes() { this._throwEx_abstractMissing(); }
		//Can only be used while constructing
		_routes_define(routeDef)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteDef_base, routeDef);
			this._routes_define_x_assertCan();
			
			//Do some validations
			{
				if (this._routeDefs[routeDef.name]) { this.throwEx(`Already defined route with name "${routeDef.name}"`,routeDef); }
				
				for (const loop_lang of this._appLangs)
				{
					if (!B_REST_Utils.object_hasPropName(routeDef.langUrls,loop_lang)) { this.throwEx(`Route def must def URLs in all supported langs`,routeDef); }
				}
			}
			
			this._routeDefs[routeDef.name] = routeDef;
		}
			_routes_define_x_assertCan() { if(this._boot_status!==B_REST_App_base.BOOT_STATUS_IDLE){this.throwEx(`Can only define routes before booting app`);} }
		/*
		Rets the instance of B_REST_App_RouteDef_base, or throws if not found
		If we instead want to infer from a multilingual URL and return "more" than just the instance, use routes_getRouteInfo_fromPath_x()
		*/
		routeDefs_get(name)
		{
			return this._routeDefs[name] || this.throwEx(`Unknown routeDef "${name}"`);
		}
			//Helpers; yield NULL if not supported / defined for this app
			get routeDefs_landpage() { return this._routeDefs[B_REST_App_RouteDef_base.NAME_LANDPAGE] || null; }
			get routeDefs_login()    { return this._routeDefs[B_REST_App_RouteDef_base.NAME_LOGIN]    || null; }
			get routeDefs_resetPwd() { return this._routeDefs[B_REST_App_RouteDef_base.NAME_RESET_PWD]|| null; } //WARNING: URL must match server's RouteParser_x::_abstract_sendResettingPwdEmail_getFrontendRoutePaths() URLs
			get routeDefs_profile()  { return this._routeDefs[B_REST_App_RouteDef_base.NAME_PROFILE]  || null; }
			get routeDefs_404()      { return this._routeDefs[B_REST_App_RouteDef_base.NAME_404]      || null; }
			/*
			This one depends on if we're logged and what exists as gen pages
			Helps deciding what to do when we go to "/" but that it doesn't match a route def
			Rets NULL if we've got none
			*/
			get routeDefs_fallback()
			{
				if      (this.user_isAuth   && this._routeDefs[B_REST_App_RouteDef_base.NAME_PROFILE]) { return this._routeDefs[B_REST_App_RouteDef_base.NAME_PROFILE];  }
				else if (this.user_isPublic && this._routeDefs[B_REST_App_RouteDef_base.NAME_LOGIN])   { return this._routeDefs[B_REST_App_RouteDef_base.NAME_LOGIN];    }
				else if (this._routeDefs[B_REST_App_RouteDef_base.NAME_LANDPAGE])                      { return this._routeDefs[B_REST_App_RouteDef_base.NAME_LANDPAGE]; }
				return null;
			}
		/*
		Funcs giving info about the current route, some w instances of B_REST_App_RouteDef_base / B_REST_App_RouteInfo_base der
		Some can yield NULL if current route doesn't match anything
		*/
			get routes_current_info()                  { return this._routes_current_info;                                                    }
			get routes_current_def()                   { return this._routes_current_info.routeDef       ?? null;                             }
			get routes_current_name()                  { return this._routes_current_info.routeDef?.name ?? null;                             }
			get routes_current_path()                  { return this._routes_current_info.fullPath;                                           }
			get routes_current_pathVars()              { return this._routes_current_info.pathVars;                                           }
			get routes_current_qsa()                   { return this._routes_current_info.qsa;                                                }
			get routes_current_hashTag()               { return this._routes_current_info.hashTag;                                            }
			get routes_current_travelDir()             { return this._routes_current_travelDir;                                               }
			get routes_current_travelDir_isUnrelated() { return this._routes_current_travelDir===B_REST_App_base.ROUTES_TRAVEL_DIR_UNRELATED; }
			get routes_current_travelDir_isToChild()   { return this._routes_current_travelDir===B_REST_App_base.ROUTES_TRAVEL_DIR_TO_CHILD;  }
			get routes_current_travelDir_isToParent()  { return this._routes_current_travelDir===B_REST_App_base.ROUTES_TRAVEL_DIR_TO_PARENT; }
		/*
		Converts a full URL into an instance of B_REST_App_RouteInfo_base, with a routeDef prop (if URL is valid)
		URL may contain ?QSA & #hashTag
		Always ret an instance of B_REST_App_RouteInfo_base
		IMPORTANT:
			-If it doesn't point to a known route, we'll still ret a B_REST_App_RouteInfo_base, but leave the routeDef prop NULL,
				even if we have a catch-all routeDef (B_REST_App_RouteDef_base::NAME_404)
				We can use B_REST_App_RouteInfo_base::isUn/Known() to know
			-If multiple langs have the same URL, then lang will equal NULL
		WARNING: If we have a base path rel to domain's root, ex "//<domainName>/myApp/" instead of "//<domainName>/", we should trim "/myApp/"
		*/
		routes_getRouteInfo_fromPath(fullPath)
		{
			//Drop prefixed base URL, if any. Ex "/pwa/some/thing" -> "/some/thing"
			fullPath = fullPath.replace(new RegExp(`^${B_REST_Utils.string_escapeRegex(process.env.BASE_URL)}`), "/");
			
			const urlInfo      = B_REST_Utils.url_getInfo(fullPath);
			const splittedPath = B_REST_App_RouteDef_base.splitPath(urlInfo.path);
			
			for (const loop_routeDefName in this._routeDefs)
			{
				const loop_routeDef = this._routeDefs[loop_routeDefName];
				const loop_match    = loop_routeDef.checkPathMatch(splittedPath);
				
				if (loop_match) { return this._abstract_routes_getRouteInfo_fromPath_retFound(fullPath,loop_routeDef,loop_match.pathVars,urlInfo.qsa,urlInfo.hashTag,loop_match.lang); }
			}
			
			return this._abstract_routes_getRouteInfo_fromPath_retFound(fullPath,/*routeDef*/null,/*pathVars*/null,urlInfo.qsa,urlInfo.hashTag,/*lang*/null);
		}
			//Must just ret a der instance of B_REST_App_RouteInfo_base from the received data
			_abstract_routes_getRouteInfo_fromPath_retFound(fullPath,routeDef=null,pathVars=null,qsa=null,hashTag=null,lang=null) { this._throwEx_abstractMissing(); }
		//Alias to reboot(). Check its docs
		routes_reload()
		{
			this._boot_setUnbooting();
			window.location.reload();
		}
		/*
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		routes_go_back(reloadApp=false)
		{
			if (reloadApp)
			{
				this._boot_setUnbooting();
				window.history.back();
			}
			else
			{
				//If we don't want to reload app, then we need the app to be fully loaded before we can navigate
				if (this.boot_isBooting) { this.throwEx(`Can't navigate wo reloading app, if app isn't fully booted yet`); }
				
				//Then we can proceed with the framework's route handler
				this._abstract_routes_go_x_replace_back();
			}
		}
		/*
		Expects an instance of B_REST_App_routeInfo_base der
		If target lang is diff than the actual one, will get changed if reloadApp=true, otherwise we must change it in advance
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		Rets the same B_REST_App_RouteInfo_base der instance (before any navigation guard - see _abstract_routes_beforeNavigationChange())
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		routes_go_routeInfo(routeInfo_target, reloadApp=false, newWindow=false)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteInfo_base, routeInfo_target);
			
			if (newWindow)
			{
				window.open(routeInfo_target.fullPath_wBaseUrl, "_blank");
			}
			else if (reloadApp)
			{
				this._boot_setUnbooting();
				window.location.href = routeInfo_target.fullPath_wBaseUrl;
			}
			else
			{
				//If we don't want to reload app, then we need the app to be fully loaded before we can navigate
				if (this.boot_isBooting) { this.throwEx(`Can't navigate wo reloading app, if app isn't fully booted yet`); }
				
				//Ignore if path is the same. Don't just compare window.location.href to "path", in case trailing "/", QSA etc are arranged diff
				if (this._routes_current_info.fullPath===routeInfo_target.fullPath)
				{
					B_REST_Utils.console_warn(`Navigation skipped, because it's the exact same URL and we don't want to reload the page`,{routeInfo_current:this._routes_current_info,routeInfo_target});
				}
				else
				{
					//Then we can proceed with the framework's route handler
					this._abstract_routes_go_x_replace_path(routeInfo_target.fullPath);
				}
			}
			
			return routeInfo_target;
		}
			_abstract_routes_go_x_replace_back()     { this._throwEx_abstractMissing(); }
			_abstract_routes_go_x_replace_path(path) { this._throwEx_abstractMissing(); }
		/*
		Expects a url relative to app's base dir, optionnally w QSA
		If target lang is diff than the actual one, will get changed if reloadApp=true, otherwise we must change it in advance
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		Rets an instance of B_REST_App_RouteInfo_base der where we end up (before any navigation guard - see _abstract_routes_beforeNavigationChange())
		WARNING:
			Will throw, if app isn't done booting and we don't want to reload
		*/
		routes_go_path(path, reloadApp=false)
		{
			const routeInfo = this.routes_getRouteInfo_fromPath(path);
			
			return this.routes_go_routeInfo(routeInfo, reloadApp);
		}
		/*
		Expects a known route name (within the _routeDefs)
		Will use the URL in the actual lang, so change in advance if needed
		Might trigger a _abstract_beforeUnload_generalHook()
		If we reloadApp=true, check _boot_setUnbooting() docs
		Rets an instance of B_REST_App_RouteInfo_base der where we end up (before any navigation guard - see _abstract_routes_beforeNavigationChange())
		WARNING:
			-Will throw, if app isn't done booting and we don't want to reload
			-If implementing for Vue (B_REST_VueApp_base), <v-btn> and such "to" prop matches a path by default but not a route name, so we should do:
				<v-btn :to="{name:'someRouteName'}" />
		*/
		routes_go_name(routeName, pathVars={}, qsa={}, reloadApp=false)
		{
			const routeDef  = this.routeDefs_get(routeName);
			const routeInfo = routeDef.toRouteInfo(pathVars,qsa,/*hashTag*/null,/*lang*/null);
			
			return this.routes_go_routeInfo(routeInfo, reloadApp);
		}
			//Helpers
			routes_go_landpage(pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_LANDPAGE, pathVars,qsa,reloadApp); }
			routes_go_login(   pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_LOGIN,    pathVars,qsa,reloadApp); }
			routes_go_resetPwd(pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_RESET_PWD,pathVars,qsa,reloadApp); }
			routes_go_profile( pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_PROFILE,  pathVars,qsa,reloadApp); }
			routes_go_404(     pathVars={}, qsa={}, reloadApp=false) { return this.routes_go_name(B_REST_App_RouteDef_base.NAME_404,      pathVars,qsa,reloadApp); }
			//For now, especially if we implement Vue & use B_REST_VueApp_base::_routes_helper_makeRouteDefs_authenticatedModules()
			routes_go_moduleList(      moduleName,        qsa={}, reloadApp=false) { return this.routes_go_name(`${moduleName}-list`,{},         qsa,reloadApp); }
			routes_go_moduleForm_new(  moduleName,        qsa={}, reloadApp=false) { return this.routes_go_name(`${moduleName}-form`,{pkTag:"*"},qsa,reloadApp); }
			routes_go_moduleForm_pkTag(moduleName, pkTag, qsa={}, reloadApp=false) { return this.routes_go_name(`${moduleName}-form`,{pkTag},    qsa,reloadApp); } //NOTE: Use B_REST_Model's pk_tag getter
				//NOTE: If we change B_REST_App_base::ROUTES_PATH_VARS_PK_TAG, then we also have to change {pkTag} to something else
		/*
		Might trigger a _abstract_beforeUnload_generalHook()
		If we !newWindow, check _boot_setUnbooting() docs
		*/
		routes_go_external(url, newWindow=true)
		{
			if (newWindow) { window.open(url,"_blank"); }
			else
			{
				this._boot_setUnbooting();
				window.location.href = url;
			}
		}
		/*
		Tells if we have perms for that route, unless we want to ignore checks
		Receives an instance of B_REST_App_RouteInfo_base der (containing a B_REST_App_RouteDef_base der - or NULL)
		*/
		async routes_hasPerms(routeInfo)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_App_RouteInfo_base, routeInfo);
			
			if (this._debug_ignorePerms) { return true; }
			
			if (routeInfo.needsAuth && !this.user_isAuth) { return false; }
			
			const hasPerms = await this._abstract_routes_hasPerms(routeInfo);
			if (hasPerms!==true && hasPerms!==false) { this.throwEx(`_abstract_routes_hasPerms() must ret bool`,routeInfo); }
			return hasPerms;
		}
			//Check routes_hasPerms() docs. Only called if necessary, (not ignoring perms check + either are at least logged / pub user for a pub route)
			async _abstract_routes_hasPerms(routeInfo) { this._throwEx_abstractMissing(); }
		/*
		Called between each navigation (even at boot), to allow:
			-Confirming we can proceed on that route (validating perms)
			-Redirecting to another route (using B_REST_App_RouteDef_dev::toRouteInfo())
			-Deciding to redirect to a login route instead of a landpage
			-Allocating / releasing data etc
		Must ret either of:
			true                    -> OK to proceed
			false                   -> Don't go there
			B_REST_VueApp_RouteInfo -> Redirect somewhere else
		Receives instances of B_REST_App_RouteInfo_base der
		Usage ex:
			if (routeInfo_to.isRoot)    { ... }
			if (routeInfo_to.isUnknown) { ... }
			return this.routeDefs_get("clients").toRouteInfo({pk:123})
			return this.routeDefs_login.toRouteInfo({pk:123})
		NOTE:
			-On boot, routeInfo_from is NULL
		IMPORTANT:
			-User must validate perms with "await routes_hasPerms()", otherwise decided destination will be ignored
			-Check routes_hasPerms() docs
		*/
		async _abstract_routes_beforeNavigationChange(routeInfo_to, routeInfo_from=null) { this._throwEx_abstractMissing(); }
		//Call this at a successful route change. Ex to help figuring UI horizontal transitions between screens
		_routes_updateCurrentTravelDirection(routeInfo_to, routeInfo_from=null)
		{
			this._routes_current_travelDir = this._abstract_routes_evalTravelDirection(routeInfo_to,routeInfo_from);
		}
			//Must ret a const of ROUTES_TRAVEL_DIR_x
			_abstract_routes_evalTravelDirection(routeInfo_to, routeInfo_from=null) { this._throwEx_abstractMissing(); }
		//To allow doing stuff after a successful navigation change, ex if we want to deal with breadcrumbs, travel history, etc
		_abstract_routes_afterNavigationChange(routeInfo_to, routeInfo_from=null) { this._throwEx_abstractMissing(); }
	
	
	
	//NOTIFS RELATED
		_notifs_setupListener()
		{
			//TODO - Call server to get new notifs each X secs. Hook to <br-toaster-manager>
		}
		_notifs_appendFromAPICall(notifs)
		{
			//TODO - Check B_REST_App_Notif.js. Hook to <br-toaster-manager>
		}
		_notifs_actionHook(notif)
		{
			//TODO - Maybe tell server we've done an action or just dismissed it
		}
		_notifs_ls_x()
		{
			//TODO - Check to work with LS, and when we user_createFromObj() or kick out, we should make sure we don't see someone else's stuff. Use LS or local DB ?
		}
		//Helpers
		notifs_tmp({msg,color})
		{
			//NOTE: Used in some places
			
			alert(msg);
		}
		notifs_error_generic() {} //Red "An error happened"
		notifs_error_locPath(custom_locPath, details={}) {} //Red "An error happened while doing XYZ"
		notifs_saved_success_generic() {} //Green "record saved"
		notifs_saved_success_locPath(custom_locPath, details={}) {} //Green "Client #3 saved"
		notifs_saved_failure_generic() {} //Red "record couldn't be saved"
		notifs_saved_failure_locPath(custom_locPath, details={}) {} //Red "Client #3 couldn't be saved"	
};
