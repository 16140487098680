<template>
	
	<!--
		NOTE:
			We can't change declaration order to:
				<v-card>
					<v-fade-transition>
						<template v-if>
						<template v-else-if>
						<template v-else>
			Because the last case has multiple child nodes and Vue <transition>
				would require using <transition-group> and lots of CSS to fix it
	-->
	<v-fade-transition hide-on-leave>
		
		<v-card v-if="!derivedComponent.fromX_anyFilled" v-bind="card_attrs">
			<v-card-text>
				<v-alert type="error">{{ derivedComponent.t_alt("noSourceModel") }}</v-alert>
			</v-card-text>
		</v-card>
		
  		<v-card v-else-if="derivedComponent.model_isLoading" v-bind="card_attrs">
			<v-card-text>
				<v-skeleton-loader height="940" type="card, article@2, actions" />
			</v-card-text>
		</v-card>
		
		<v-card v-else v-bind="card_attrs" :loading="derivedComponent.model_hasAsyncTasks">
			<v-card-title v-if="derivedComponent.showTitle">
				<slot name="title">{{ derivedComponent.title }}</slot>
			</v-card-title>
			<v-card-text>
				<slot name="fields" />
			</v-card-text>
			<v-card-actions v-if="isSlotDefined_actions||derivedComponent.apiBaseUrl_has" class="justify-end">
				<slot name="actions">
					<v-btn @click="derivedComponent.save" :disabled="derivedComponent.shouldSaveBtnBeDisabled">
						{{ derivedComponent.t_alt("save") }}
					</v-btn>
				</slot>
			</v-card-actions>
		</v-card>
		
	</v-fade-transition>
	
</template>

<script>
	
	/*
	Usage ex (MyForm.vue):
		*** Check the IMPORTANT msg
		Template:
			<br-generic-form-base :derived-component="_self">
				<template #fields>
					<v-row>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="firstName" /> </v-col>
						<v-col cols="12" md="6"> <br-field-db :model="model" field="lastName" />  </v-col>
					</v-row>
				</template>
			</br-generic-form-base>
		Script:
			import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
			export default {
				name: "citizenForm",
				mixins: B_REST_Vuetify_GenericFormBase_createMixin({
					modelName: "TestSPADCitizen",
					apiBaseUrl: "/testSPADCitizens/",
					async afterLoad(response,models) { },
					async beforeSave(request,model) { },
					async afterSave(response,model,isSuccess,wasNew) { },
				}),
				data()
				{
					return { };
				},
			}
		Usage:
			<my-form :from-route-info="routeInfo" />  // Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
			<my-form :from-model="model" />
			<my-form from-pk-tag="*" />
			<my-form from-pk-tag="123" />
			<my-form from-new />
	IMPORTANT:
		Check B_REST_VueApp_base::_routes_define_genericListFormModule() for the viewTransferProps() prop, that passes down the req fromRouteInfo when we load the component directly
	About idea of nesting <router-view>:
		Ex for a case like in SPAD w a citizen form ("/citizens/123") where we can view his animals ("/citizens/123/animals/456")
		Would prevent losing data in main form, but don't, because:
			-Switching new/existing pks reuse components when -sometimes- we don't want that
				(going from new->created pk is ok, but existing pk to new one, or pk1 to pk2 should clear)
			-We'd need to put :key on the <router-view> instances, to control when component should refresh,
				but we have no easy way of indicating to which instance do a key++ to force refresh
			-Not ok that sub form knows that it's being used in a parent form
			-We can't do $bREST.routes_go_back() to "close" the sub form (going from /citizen/123/animals/justCreatedOne to /citizen/123)
				so closing the sub form would reload main form anyways
		-> Best is:
			-Define beforeNavigation() hook in problematic components
			-Just open sub form in a modal w/o screwing main form
	*/
	
	import { B_REST_Utils, B_REST_Model } from "../../../../../classes";
	import B_REST_VueApp_base             from "../../../B_REST_VueApp_base.js";
	import B_REST_VueApp_RouteInfo        from "../../../B_REST_VueApp_RouteInfo.js";
	import B_REST_VueApp_CreateCoreMixin  from "../../../B_REST_VueApp_CreateCoreMixin.js";
	
	const CORE_ALT_BASE_LOC_PATH = "app.components.BrGenericFormBase";
	
	
	
	B_REST_Utils.console_todo([
		`apiBaseUrl_pk must be changed to /pkTag`,
		`What about if it's a sub form where we need an FK from the parent ? Then the parent should have a ModelList and set field correctly`,
		`Simplify by merging back w BaseTest.vue`,
		`Some custom validation that might be distinct to a place`,
		`If we have pwd fields and other custom fields, where to define them`,
		`Sometimes maybe we want to pass common lists we don't want to recalc`,
		`Allow models to return their form's + api resource URLs ? For UI, maybe we shouldn't and it should be the model list itself that controls that, since we could have multiple views for the same thing / change w lang`,
	]);
	
	
	
	export default {
		props: {
			derivedComponent: {type:Object, required:true}, //Derived component instance using this base component
		},
		computed: {
			//Since we have 3 <v-card>, do this so styling is consistent in all 3 cases
			card_attrs()
			{
				return {
					elevation: 4,
					class: `${this.derivedComponent.cssClassBase} ma-4 pa-4`,
				};
			},
			isSlotDefined_title()   { return !!this.$slots.title;   },
			isSlotDefined_fields()  { return !!this.$slots.fields;  },
			isSlotDefined_actions() { return !!this.$slots.actions; },
		},
	};
	
	
	
	export function B_REST_Vuetify_GenericFormBase_createMixin(mixinOptions)
	{
		mixinOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions, {
			modelName:          {accept:[String],  required:true}, //Ex: "Citizen"
			apiBaseUrl:         {accept:[String],  required:true}, //Ex: "/citizens/". Will automatically yield stuff like "/citizens/{pkTag}" for load & patch
			needsAccessToken:   {accept:[Boolean], default:true},
			requiredFields:     {accept:[String]},                 //Ex: "firstName"
			afterLoad:          {accept:[Function]},               //Async hook as (response<B_REST_Response>, models<B_REST_Model arr>) - Check B_REST_Descriptor::load_x() docs
			beforeSave:         {accept:[Function]},               //Async hook as (request<B_REST_Request>,   model<B_REST_Model>) - Check B_REST_Model::save() docs
			afterSave:          {accept:[Function]},               //Async hook as (response<B_REST_Response>, model<B_REST_Model>, isSuccess, wasNew) - Check B_REST_Model::save() docs
			autoUpdateInterval: {accept:[Number]},                 //If when !isNew, we want to auto save changes when form is valid. WARNING: If model implements B_REST_Descriptor::validation_custom_asyncFuncs, we shouldn't do so
		}, "Form base");
		if (mixinOptions.formName) { B_REST_Utils.throwEx(`Tmp err msg: remove formName from mixin options and define component's name`); }
		
		return [
			//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
			//Our mixin
			{
				props: {
					showTitle:     {type:Boolean,                 required:false, default:true}, //Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
					//IMPORTANT: Check the usage ex in the docs above for how this works. One of these must be set
					fromModel:     {type:B_REST_Model,            required:false, default:null},
					fromPkTag:     {type:undefined,               required:false, default:null}, //Something like "*" (NEW), 123 or fr-123. Either we set it directly
					fromRouteInfo: {type:B_REST_VueApp_RouteInfo, required:false, default:null}, //Yields the same result as fromPkTag, except we get it from a routeInfo.pathVars.pkTag. Check B_REST_VueApp_base::_routes_define_genericListFormModule()
					fromNew:       {type:Boolean,                 required:false, default:null}, //Another way to express we want to create a new instance w/o having to pass a new one ourselves
				},
				data()
				{
					return {
						model: null, //Final B_REST_Model instance, either from a received instance, existing PK or "*" for new
						mixinOptions,
						autoUpdateInterval_ptr: null, //Ptr on a setInterval() handle
					};
				},
				watch: {
					fromModel()     { this._fromX_setLoadModel(); },
					fromPkTag()     { this._fromX_setLoadModel(); },
					fromRouteInfo() { this._fromX_setLoadModel(); },
					fromNew()       { this._fromX_setLoadModel(); },
				},
				created()
				{
					this._fromX_setLoadModel();
				},
				unmounted()
				{
					this._checkAutoUpdateInterval_needs(/*forceRemoval*/true);
				},
				computed: {
					quotedName()              { return `B_REST_Vuetify_GenericForm<${this.componentName}>`; },
					cssClassBase()            { return `generic-form--${this.componentName}`;               },
					apiBaseUrl_has()          { return !!this.mixinOptions.apiBaseUrl; },
					apiBaseUrl_list()         { return this.apiBaseUrl_has ?    this.mixinOptions.apiBaseUrl                                         : null; },
					apiBaseUrl_post()         { return this.apiBaseUrl_has ?    this.mixinOptions.apiBaseUrl                                         : null; },
					apiBaseUrl_pk()           { return this.apiBaseUrl_has ? `${this.mixinOptions.apiBaseUrl}{${B_REST_Model.API_PATH_VARS_PK_TAG}}` : null; }, //Ex "/clients/{pkTag}"
					fromX_anyFilled()         { return this.fromNew || !!this.fromModel || !!this.fromRouteInfo || (this.fromPkTag!==null && this.fromPkTag!==""); },
					model_isLoading()         { return !this.model && (this.fromPkTag||this.fromRouteInfo); }, //Only when model is still NULL and source is fromPkTag/fromRouteInfo
					model_has()               { return !!this.model; },
					model_hasUnsavedChanges() { return this.model?.unsavedChanges_has ?? false; },
					model_isValid()           { return this.model?.validation_isValid ?? false; },
					model_hasAsyncTasks()     { return this.model?.isSaving           ?? false; }, //When an allocated model is doing async stuff (ex saving, sending emails...)
					model_pkTag()             { return this.model?.pk_tag             ?? null;  },
					model_toLabel()           { return this.model?.toLabel("form")    ?? null;  },
					shouldSaveBtnBeDisabled() { return this.model_hasAsyncTasks || !this.model_hasUnsavedChanges || !this.model_isValid || !this.model?.descriptor?.isAutoInc; },
					model_isNew()
					{
						if (!this.model) { return true; }
						
						if (!this.model.descriptor.isAutoInc)
						{
							this.$bREST.utils.console_warn(`For now, can't tell if a non AUTO_INC record is new / existing. Check how it's done in server`); //WARNING: Impacts in shouldSaveBtnBeDisabled() & save() too
							return true;
						}
						
						return this.model.isNew;
					},
					/*
					Loc ex:
						{
							...
							"title": {"new":"New citizen", "existing":"Citizen #{pkTag} ({autoLabel})"}
							...
						}
					*/
					title()
					{
						return this.model_isNew ? this.t_alt("title.new") : this.t_alt("title.existing",{pkTag:this.model_pkTag,autoLabel:this.model_toLabel});
					},
				},
				/*beforeRouteEnter(to, from, next)
				{
					if (!B_REST_Vue.router_isIgnoringReplaceEvents)
					{
						//NOTE: "this" doesn't exist here, so we can't really do much
					}
					next();
				},*/
				beforeRouteUpdate(to, from, next)
				{
					//NOTE: We also have a beforeRouteEnter(), but not usable because "this" doesn't exist yet: https://router.vuejs.org/guide/advanced/navigation-guards.html#using-the-options-api
					this.$bREST.utils.console_todo([`Do something like in B_REST_VueApp_base::_abstract_routes_beforeNavigationChange() so we don't have to deal with Vue Router objs`]);
					
					if (!this.$bREST.router_isIgnoringReplaceEvents)
					{
						this._fromX_setLoadModel();
						//await other stuff
					}
					next();
				},
				beforeRouteLeave(to, from, next)
				{
					if (!this.$bREST.router_isIgnoringReplaceEvents)
					{
						this._fromX_setLoadModel();
						//await other stuff
					}
					next();
				},
				methods: {
					throwEx(msg,details=null) { this.$bREST.throwEx(`${this.quotedName}: ${msg}`, details); },
					async _fromX_setLoadModel()
					{
						if (this.$bREST.router_isIgnoringReplaceEvents) { return; }
						
						this.model = null; //NOTE: Not sure about this; when we switch between ents, we should prolly "cut" the link w the previous ent before waiting for a next one to load and crash halfway through
						this._checkAutoUpdateInterval_needs(/*forceRemoval*/true);
						
						let model = null;
						if (this.fromX_anyFilled)
						{
							if (this.fromModel)
							{
								const fromModelName = this.fromModel.descriptor.name;
								if (fromModelName!==this.mixinOptions.modelName) { this.throwEx(`Expected a B_REST_Model instance of ${this.mixinOptions.modelName}, got ${fromModelName}`); }
								
								model = this.fromModel;
							}
							else if (this.fromNew) { model=B_REST_Model.commonDefs_make(this.mixinOptions.modelName); }
							else
							{
								const fromPkTag = this.fromPkTag || this.fromRouteInfo.pathVars[B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG] || this.throwEx(`Couldn't figure out which fromPkTag to use`,{fromPkTag:this.fromPkTag,fromRouteInfo:this.fromRouteInfo});
								
								if (fromPkTag==="*") { model=B_REST_Model.commonDefs_make(this.mixinOptions.modelName); }
								else
								{
									model = await B_REST_Model.commonDefs_load_pk(this.mixinOptions.modelName, fromPkTag, {
										requiredFields:              this.mixinOptions.requiredFields,
										apiBaseUrl:                  this.apiBaseUrl_pk,
										apiBaseUrl_needsAccessToken: this.mixinOptions.needsAccessToken,
										afterLoad:                   this.mixinOptions.afterLoad,
									});
								}
							}
						}
						this.model = model;
						
						this._checkAutoUpdateInterval_needs();
					},
					_checkAutoUpdateInterval_needs(forceRemoval=false)
					{
						if (!this.mixinOptions.autoUpdateInterval) { return; }
						
						if (forceRemoval && this.autoUpdateInterval_ptr)
						{
							clearInterval(this.autoUpdateInterval_ptr);
							this.autoUpdateInterval_ptr = null;
						}
						
						if (this.model_has && !this.model_isNew)
						{
							setInterval(() =>
							{
								/*
								IMPORTANT:
									B_REST_Model::validation_isValid() doesn't wait for validation_custom_asyncFunc_x() to be ran,
									so it might say it's valid for now but might not be true in a few secs (ex validating user/email unicity)
									That means B_REST_Model::save() could break (or for other reasons too anyway)
								*/
								
								if (this.model.unsavedChanges_has && this.model.validation_isValid && !this.model.isSaving)
								{
									this.save();
								}
							}, this.mixinOptions.autoUpdateInterval);
						}
					},
					async save()
					{
						if (!this.apiBaseUrl_has)             { this.throwEx(`Model doesn't have an apiBaseUrl, so we can't save automatically`); }
						if (!this.model.descriptor.isAutoInc) { this.throwEx(`Not yet supporting models that have no AUTO_INC / multi-field PK`); } //WARNING: Impacts in model_isNew() & shouldSaveBtnBeDisabled() too
						if (!this.model_has)                  { this.throwEx(`Can't save because model isn't instantiated yet`);                  }
						
						const wasNew = this.model_isNew;
						
						await this.model.save({
							apiBaseUrl:                  wasNew ? this.apiBaseUrl_post : this.apiBaseUrl_pk,
							apiBaseUrl_needsAccessToken: this.needsAccessToken,
							beforeSave:                  this.mixinOptions.beforeSave,
							afterSave:                   this.mixinOptions.afterSave,
						});
						
						this.model.userTouch_toggleAllFields(false);
						
						if (wasNew)
						{
							this.afterSave_updateUrl();
							this._checkAutoUpdateInterval_needs();
						}
					},
						/*
						Ex we were on "/citizens/*" and we should switch to the 123 we've just created, as "/citizens/123"
						Only makes sense if module was created w B_REST_VueApp_base::helper_makeAuthenticatedModulesRoutes()
						*/
						async afterSave_updateUrl()
						{
							const vueRouterInfo = {params:{}};
							vueRouterInfo.params[B_REST_VueApp_base.ROUTES_PATH_VARS_PK_TAG] = this.model_pkTag; //Change this param only, keeping the same base route
							
							return this.$bREST.routes_silentRouteUpdate(vueRouterInfo);
						},
					async del() //NOTE: "delete" is a keyword
					{
						alert("genericDelete");
					},
				},
			},
		];
	};
	
</script>