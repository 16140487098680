<template>
	<v-sheet>
			
		<!-- MAIN INFO -->
		<v-card :elevation="2" class="mx-2 my-4">
			<v-card-title>Main info</v-card-title>
			<v-card-text>
				<v-row dense>
					<v-col md="6"> <v-autocomplete v-model="form.descriptor" label="Model" :items="allModels" item-text="modelClassName" item-value="descriptor" auto-select-first /> </v-col>
				</v-row>
				<v-row dense>
					<v-col md="6"> <g-lazy-text-field v-model="form.routerModuleName"             label="Router module name"                   placeholder="citizen" />                 </v-col>
					<v-col md="6"> <g-lazy-text-field v-model="form.routerPathLang"               label="Router URL"                           placeholder="/citoyens/" />              </v-col>
					<v-col md="6"> <g-lazy-text-field v-model="form.apiBaseUrl"                   label="API base URL"                         placeholder="/citizens/" />              </v-col>
					<v-col md="6"> <g-lazy-text-field v-model="form.reqFields" label="Required fields to load" placeholder="<off>|a.<dbOnly>|b.<dbAndSingleSub>|c.<dbAndBothSubs>|d.<all>|e.abc|f(a|b)" /> </v-col>
					<v-col md="4"> <g-lazy-text-field v-model="form.componentsDirPath"            label="Form + list component dir path"       placeholder="/views/modules/citizen/" /> </v-col>
					<v-col md="4"> <g-lazy-text-field v-model="form.componentFileNameWOExt_list"  label="Component - List file name (w/o ext)" placeholder="CitizenList" />             </v-col>
					<v-col md="4"> <g-lazy-text-field v-model="form.componentFileNameWOExt_form"  label="Component - Form file name (w/o ext)" placeholder="CitizenForm" />             </v-col>
				</v-row>
				<v-row dense>
					<v-col md="6"> <g-lazy-text-field v-model="form.formName" label="Form name (for loc)" placeholder="citizenForm" /> </v-col>
				</v-row>
				<v-row dense>
					<v-col md="12"> <v-checkbox v-model="form.autoSave" label="Auto save existing record changes, each X secs, if valid ?" /> </v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<div class="text-subtitle-1">Sizes - for new fields</div>
						<g-lazy-text-field v-model="form.cols_xs" label="xs" v-bind="sizesCommonAttrs" />
						<g-lazy-text-field v-model="form.cols_sm" label="sm" v-bind="sizesCommonAttrs" />
						<g-lazy-text-field v-model="form.cols_md" label="md" v-bind="sizesCommonAttrs" />
						<g-lazy-text-field v-model="form.cols_lg" label="lg" v-bind="sizesCommonAttrs" />
						<g-lazy-text-field v-model="form.cols_xl" label="xl" v-bind="sizesCommonAttrs" />
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
			
		<!-- FIELDS -->
		<v-card v-if="form.descriptor" :elevation="2" class="mx-2 my-4">
			<v-card-title class="mb-8">
				Fields
				<v-spacer /> <v-btn icon color="black" @click="on_add_click"><v-icon>mdi-plus</v-icon></v-btn>
			</v-card-title>
			<!-- DB FIELD FIELDS -->
			<v-card-subtitle class="text-subtitle-1 font-weight-bold"><code>&lt;br-field-db&gt;</code> fields</v-card-subtitle>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th rowspan="2">Name</th>
							<th colspan="4">Items</th>
							<th rowspan="2" style="width:140px">
								As<br />
								<span class="font-weight-regular">(autocomplete &gt; select if more than {{ PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT }} items)</span>
							</th>
							<th rowspan="2" style="width:60px">Multiple</th>
							<th rowspan="2" style="width:50px" />
						</tr>
						<tr>
							<th style="width:340px">Type</th>
							<th style="width:170px">Key</th>
							<th style="width:205px">Label</th>
							<th style="width:210px">
								Flat search<br />
								<span class="font-weight-regular">(ex for "Mtl|Montréal|Montreal")</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in form.fields_brFieldDbFields" :key="loop_idx">
							<td>{{ loop_field.selectFieldNamePath }} ({{ loop_field.fieldDescriptor.type }})</td>
							<td>
								<v-autocomplete v-if="loop_field.items_can"                    v-model="loop_field.items_type"          :items="loop_field.items_type_possibilities"                                             auto-select-first class="d-inline-block"      style="width:170px" />
								<v-autocomplete v-if="loop_field.items_type_isSharedList"      v-model="loop_field.sharedListName"      :items="allSharedLists"      placeholder="Which ?"                                       auto-select-first class="ml-2 d-inline-block" style="width:150px" />
								<v-autocomplete v-if="loop_field.items_type_isCommonModelList" v-model="loop_field.commonModelListName" :items="allCommonModelLists" placeholder="Which ?" messages="Or leave empty for snippet" auto-select-first class="ml-2 d-inline-block" style="width:150px" clearable />
								<v-autocomplete v-if="loop_field.items_type_isCommonDataTable" v-model="loop_field.commonDataTableName" :items="allCommonDataTables" placeholder="Which ?" messages="Or leave empty for snippet" auto-select-first class="ml-2 d-inline-block" style="width:150px" clearable />
							</td>
							<td> <g-lazy-text-field v-if="loop_field.items_key_placeholder"        v-model="loop_field.items_key"        :messages="loop_field.items_key_placeholder" />        </td>
							<td> <g-lazy-text-field v-if="loop_field.items_label_placeholder"      v-model="loop_field.items_label"      :messages="loop_field.items_label_placeholder" />      </td>
							<td> <g-lazy-text-field v-if="loop_field.items_flatSearch_placeholder" v-model="loop_field.items_flatSearch" :messages="loop_field.items_flatSearch_placeholder" /> </td>
							<td> <v-autocomplete    v-if="!loop_field.items_type_isDataTable"      v-model="loop_field.as"               :items="dataSets.db_asConsts" :messages="loop_field.as!==loop_field.as_finalType ? `-> ${loop_field.as_finalType}` : null" auto-select-first /> </td>
							<td> <v-checkbox        v-if="loop_field.isMultiple_can"               v-model="loop_field.isMultiple" /> </td>
							<td> <v-btn icon color="red" @click="form.fields_brFieldDbFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn> </td>
						</tr>
					</tbody>
				</v-simple-table>
				<v-btn small @click="help.extraFieldProps.show=!help.extraFieldProps.show" class="my-2 mx-auto d-block">More props</v-btn>
				<v-slide-y-transition>
					<v-alert v-if="help.extraFieldProps.show" type="info" border="left" colored-border :elevation="4" class="mx-16">
						<v-simple-table>
							<thead>
								<tr>
									<th style="width:225px;">Prop</th>
									<th>Description</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(loop_fieldProp,loop_idx) in help.extraFieldProps.props" :key="loop_idx">
									<td><code v-text="loop_fieldProp.snippet" /></td>
									<td v-text="loop_fieldProp.desc" />
								</tr>
							</tbody>
						</v-simple-table>
					</v-alert>
				</v-slide-y-transition>
			</v-card-text>
			<v-divider class="my-4" />
			<!-- SUB MODEL LIST FIELDS -->
			<v-card-subtitle class="text-subtitle-1 font-weight-bold">Sub model list fields (no support for now)</v-card-subtitle>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Name</th>
							<th style="width:50px" />
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in form.fields_subModelListFields" :key="loop_idx">
							<td>{{ loop_field.selectFieldNamePath }}</td>
							<td> <v-btn icon color="red" @click="form.fields_subModelListFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn> </td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
			<v-divider class="my-4" />
			<!-- FILE FIELDS -->
			<v-card-subtitle class="text-subtitle-1 font-weight-bold"><code>&lt;br-field-file&gt;</code> fields (no support for now)</v-card-subtitle>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Name</th>
							<th style="width:50px" />
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in form.fields_fileFields" :key="loop_idx">
							<td>{{ loop_field.selectFieldNamePath }}</td>
							<td> <v-btn icon color="red" @click="form.fields_fileFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn> </td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
			<v-divider class="my-4" />
			<!-- OTHER FIELDS -->
			<v-card-subtitle class="text-subtitle-1 font-weight-bold">Other fields</v-card-subtitle>
			<v-card-text>
				<v-simple-table dense>
					<thead>
						<tr>
							<th>Name</th>
							<th style="width:50px" />
						</tr>
					</thead>
					<tbody>
						<tr v-for="(loop_field,loop_idx) in form.fields_otherFields" :key="loop_idx">
							<td>{{ loop_field.selectFieldNamePath }}</td>
							<td> <v-btn icon color="red" @click="form.fields_otherFields_del(loop_field)"><v-icon>mdi-delete</v-icon></v-btn> </td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
		
		<!-- TREE VIEW FIELD SELECTOR -->
		<v-dialog v-model="fieldSelector.show" max-width="600">
			<v-card class="my-1 pb-2">
				<v-card-title>Select fields to add</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" style="height:400px; overflow-y:scroll">
							<v-treeview v-model="fieldSelector.selections" :items="form.possibleFields" selectable return-object />
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-row justify="end">
						<v-col cols="auto"> <v-btn @click="on_fieldSelector_apply">Add</v-btn> </v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
	</v-sheet>
</template>

<script>
	
	import { B_REST_Descriptor } from "../../../../../classes";
	import B_REST_VueApp_base from "../../../B_REST_VueApp_base.js";
	import GForm from "./GForm.js";
	import { GForm_BrFieldDbField } from "./GForm_XFields.js";
	import { PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT } from "../../modelField/BrFieldDb.vue";
	
	
	
	export default {
		props: {
			form: {type:GForm, required:true},
		},
		data()
		{
			return {
				PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT,
				dataSets: {
					flags_actionsBehaviors:     GForm.FLAG_ACTIONS_BEHAVIORS,
					flags_customValidationDefs: GForm.FLAG_CUSTOM_VALIDATION_DEFS,
					db_asConsts:                GForm_BrFieldDbField.AS_X_CONSTS,
				},
				sizesCommonAttrs: { //For xs,sm,md,lg,xl fields
					type:                  "number",
					min:                   1,
					max:                   12,
					clearable:             true,
					placeholder:           "Empty = auto",
					class:                 "d-inline-block mr-2",
					persistentPlaceholder: true,
					style:                 "width:115px",
				},
				fieldSelector: {
					show: false,
					selections: [],
				},
				help: {
					extraFieldProps: {
						show: false,
						props: [		
							{snippet:`id="..."`,                      desc:`Only use this if we want to do something like Vuetify.goTo("#...")`},
							{snippet:`label="Some translated thing"`, desc:`If we don't want the auto translation from server in /SERVER/custom/<projName>/loc/<lang>.json ex under json path "models.<ModelName>.fields.<fieldName>.label"`},
							{snippet:`no-label`,                      desc:`If we want to hide the label`},
							{snippet:`readonly`,                      desc:`Displays a 🔓 above the field. We can still focus it to copy its content. If the model or field is already readonly, this won't change anything. For data tables, we opt for this rather than disabled, otherwise btns aren't usable`},
							{snippet:`disabled`,                      desc:`Same as readonly, but not focusable and btns turn grey`},
							{snippet:`placeholder="..."`,             desc:`Only appears when field is empty`},
							{snippet:`prefix="CAD $"`,                desc:`Always appear to the left, even when filled`},
							{snippet:`suffix="CAD $"`,                desc:`Same as for prefix, but to the right`},
							{snippet:`tooltip="..."`,                 desc:`Display something on hover`},
							{snippet:`lazy`,                          desc:`By default, reactivity happens as soon as we type something (oninput) but we can use this so the change events only happen when we blur (onchange). Less CPU intensive and might freak out less for custom validation`},
							{snippet:`icon1="mdi-xxx|primary"`,       desc:`If we want to put/override the icon outside to the left of the input. Format like "mdi-xxx" or "mdi-xxx|<color>", ex "mdi-google-maps" or "mdi-account|primary"`},
							{snippet:`icon2="mdi-xxx|success"`,       desc:`Same as icon1, but inside the input, to the left`},
							{snippet:`icon3="mdi-xxx|red"`,           desc:`Same as icon2, but to the right`},
							{snippet:`icon4="mdi-xxx"`,               desc:`Same as icon1, but outside to the right`},
							{snippet:`:min="..."`,                    desc:`For numerical <v-text-field> & <v-slider>, and for date/time. By default takes what's defined in the model, but we can overwrite`},
							{snippet:`:max="..."`,                    desc:`Same as for min`},
							{snippet:`:step="..."`,                   desc:`Like for min & max, if we want to control the increment. Default is 1`},
							{snippet:`true-value="something"`,        desc:`For checkboxes and radio btns, if we want "true" to be something else`},
							{snippet:`false-value="somethingElse"`,   desc:`Same as for true-value`},
							{snippet:`counter`,                       desc:`If we want to show character counter in text fields`},
							{snippet:`:pwd-strength-bar="false"`,     desc:`For pwds, by default we display a strength bar, but we can hide it (ex for pwd confirmation extra field)`},
							{snippet:`:textarea-line-count="123"`,    desc:`5 lines by default for textareas`},
							{snippet:`radio-group-orientation="..."`, desc:`For radio btns, one of {auto|horizontal|vertical}. By default "auto", where orientation depends on if we're desktop vs mobile`},
						],
					},
				},
			};
		},
		computed: {
			appLangs() { return B_REST_VueApp_base.instance.appLangs; },
			allModels()
			{
				const models = [];
				
				for (const loop_descriptorName of B_REST_Descriptor.commonDefs_names)
				{
					const loop_descriptor = B_REST_Descriptor.commonDefs_get(loop_descriptorName);
					
					models.push({
						modelClassName: loop_descriptorName,
						descriptor:     loop_descriptor,
					});
				}
				
				models.sort((a,b) => a.modelClassName<b.modelClassName?-1:1);
				
				return models;
			},
			allSharedLists()      { return this.$bREST.sharedLists_tags;   },
			allCommonModelLists() { return ["TODO - Link back to app's sharedLists"]; },
			allCommonDataTables() { return []; },
		},
		methods: {
			on_add_click()
			{
				this.fieldSelector.selections = [];
				this.fieldSelector.show       = true;
			},
			on_fieldSelector_apply()
			{
				for (const loop_selection of this.fieldSelector.selections)
				{
					this.form.fields_addX(loop_selection);
				}
				
				this.fieldSelector.show       = false;
				this.fieldSelector.selections = [];
			},
		},
		components: {
			GLazyTextField: () => import("../utils/GLazyTextField.vue"),
		},
	};
	
</script>

<style scoped>
	
	th {
		text-align:    center !important;
		border-bottom: thin solid rgba(0,0,0, 0.12);
	}
	td, th {
		border-right: thin solid rgba(0,0,0, 0.12);
		padding:      4px !important;
	}
	td .v-input :deep(.v-input__slot) {
		justify-content: space-around;
	}
	
	.v-data-table {
		width:         100%;
		border-left:   thin solid rgba(0,0,0, 0.12);
		border-top:    thin solid rgba(0,0,0, 0.12);
		border-bottom: thin solid rgba(0,0,0, 0.12);
	}
	
</style>