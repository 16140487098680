
import { B_REST_Utils, B_REST_Model, B_REST_FieldDescriptors } from "../../../../../classes";
import B_REST_VueApp_base                                      from "../../../B_REST_VueApp_base.js";




export default class B_REST_Vuetify_GenericList_Col
{
	static get ALIGN_LEFT()   { return "start";  }
	static get ALIGN_CENTER() { return "center"; }
	static get ALIGN_RIGHT()  { return "end";    }
	static _ALIGNS = [
		B_REST_Vuetify_GenericList_Col.ALIGN_LEFT,
		B_REST_Vuetify_GenericList_Col.ALIGN_CENTER,
		B_REST_Vuetify_GenericList_Col.ALIGN_RIGHT,
	];
	static get DIVIDER_CLASS_NAME() { return "v-data-table__divider"; }
	
	_listComponent            = null;  //BrGenericListBase der this belongs to
	_name                     = null;  //Unique name of the col in the table. In most cases will match _fieldNamePaths (when we only need a single field). Specify multiple, when we want to concat info. We can use advanced notation like "firstName|coords.address|a.b.<dbOnly>". WARNING: Mustn't have special chars
	_fieldNamePaths           = null;  //Either NULL, a single fieldNamePath, or piped for multiple fields. Will ask server to load all req fields. If we have exactly 1, then it'll fetch model.select(<fieldNamePaths>).val, and help get translation
	_fieldDescriptor          = null;  //When _fieldNamePaths point on exactly 1 field, its B_REST_FieldDescriptor_x
	_click_isEnabled          = null;  //Either bool or func as (<BrGenericListBase der>listComponent,col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>). WARNING: PICKER_ENABLE_x consts in BrGenericListBase overrides this
	_click_hook               = null;  //Async func as (<BrGenericListBase der>listComponent,col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>) if we want to listen to when we click on cols. Must ret bool
	_isEditable               = false; //Bool or func as (<BrGenericListBase der>listComponent,col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>) that must ret bool. WARNING: PICKER_ENABLE_x consts in BrGenericListBase overrides this + user will have to handle save by himself
	_vBind                    = null;  //Props we want to pass to the underlying <br-field-db> or etc. Ex {placeholder:"bob",items:[],as:"timePicker"}. WARNING: Auto handled if we don't define a <template #item.xxx>, otherwise we must link it manually via <template #item.myCol="{ colInfo }">
	_vOn                      = null;  //Like vBind, but for events, like {change($event){}, input($event){}}, so we can be notified when stuff happens
	_extraData                = null;  //Optional obj
	_label                    = null;  //Usually the shortLabel of a B_REST_FieldDescriptor_x
	//CSS
	_style_fromBreakpoint = "xs";                                      //Show the col from X breakpoint and up. Vuetify breakpoint like xs|sm|md|lg|xl
	_style_align          = B_REST_Vuetify_GenericList_Col.ALIGN_LEFT; //One of ALIGN_x. Applies to both header + content. For content, only auto used when we don't define <template #item.xxx>, and it being RO and not in edit mode
	_style_hasRightBorder = false;                                     //If we want to put vertical lines between cols
	_style_width          = null;                                      //Number or string
	_style_tdClass        = null;                                      //As (<BrGenericListBase der>listComponent,col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>). Will go into a <td :class> NOTE: We have listComponent.$bREST.classProp_addTag() helper
	_style_tdStyle        = null;                                      //As (<BrGenericListBase der>listComponent,col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>). Will go into a <td :style> NOTE: We have listComponent.$bREST.classProp_addTag() helper
							
	//WARNING: If we add stuff here, also add in constructor() + clone()
	
	
	/*
	Options as
		{
			fieldNamePaths: NULL | piped field name paths like "firstName|coords.address|a.b.<dbOnly>"
			click: {
				isEnabled: bool | (col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>)
				hook:      async(col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>), //Must ret bool
			},
			isEditable: bool | (col<B_REST_Vuetify_GenericList_Col>,model<B_REST_Model>),
			style: {
				fromBreakpoint,
				align: ALIGN_x,
				hasRightBorder,
				width,
				tdClass: (col<B_REST_Vuetify_GenericList_Col,model<B_REST_Model>), //Will go into a <td :class> NOTE: We have listComponent.$bREST.classProp_addTag() helper
				tdStyle: (col<B_REST_Vuetify_GenericList_Col,model<B_REST_Model>), //Will go into a <td :style> NOTE: We have listComponent.$bREST.styleProp_addTag() helper
			},
			vBind:     {},
			vOn:       {},
			extraData: {},
		}
	*/
	constructor(listComponent, colName, options)
	{
		if (!colName.match(/^[a-z_]+$/i)) { B_REST_Vuetify_GenericList_Col._throwEx(`Col name mustn't contain special chars: "${colName}"`); }
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			fieldNamePaths: {accept:[String,null],      default:null},
			click:          {accept:[Object],           default:null},
			isEditable:     {accept:[Boolean,Function], default:false},
			style:          {accept:[Object],           default:null},
			vBind:          {accept:[Object],           default:null},
			vOn:            {accept:[Object],           default:null},
			extraData:      {accept:[Object],           default:null},
		}, "Generic list col");
		
		this._listComponent  = listComponent;
		this._name           = colName;
		this._fieldNamePaths = options.fieldNamePaths;  //Can be NULL
		
		//Loc
		{
			//For loc, check if we can find a custom override implementing {shortLabel}. Otherwise, if we have a fieldDescriptor, try to use its one
			const customLocBasePath = `${this._listComponent.t_baseLocPath}.cols.${this._name}.${B_REST_VueApp_base.LOC_KEY_SHORT_LABEL}`; //Ex "components.contactPicker.cols.firstName.shortLabel"
			this._label             = B_REST_VueApp_base.instance.t_custom_orNULL(customLocBasePath);
			
			//If fieldNamePaths points to a SINGLE field, now try to get its B_REST_FieldDescriptor instance
			if (this._fieldNamePaths?.match(/^[\w.\[\]]+$/i)) //Allow "a_b.c[3]" but not "a|b" nor "a.<dbOnly>"
			{
				this._fieldDescriptor = this._listComponent.modelList.descriptor.allFields_find_byFieldNamePath(this._fieldNamePaths); //Might throw if field name path doesn't make sense
				
				if (this._label===null) { this._label=this._fieldDescriptor.shortLabel; }
			}
			
			if (this._label===null)
			{
				this._label = `%${customLocBasePath}%`;
				B_REST_VueApp_base.instance.t_custom_warnNotFound(customLocBasePath);
			}
		}
		
		const clickOptions = options.click;
		if (clickOptions)
		{
			this._click_hook = clickOptions.hook || null;
			
			if (this._click_hook)
			{
				this._click_isEnabled = B_REST_Utils.object_hasPropName(clickOptions,"isEnabled") ? clickOptions.isEnabled : true;
			}
		}
		
		this._isEditable = options.isEditable;
		this._vBind      = options.vBind;
		this._vOn        = options.vOn;
		this._extraData  = options.extraData;
		
		if (options.style)
		{
			const styleOptions = B_REST_Utils.object_hasValidStruct_assert(options.style, {
				fromBreakpoint: {accept:[String],   default:"xs"},
				align:          {accept:[String],   default:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT},
				hasRightBorder: {accept:[Boolean],  default:false},
				width:          {accept:[Number],   default:null},
				tdClass:        {accept:[Function], default:null},
				tdStyle:        {accept:[Function], default:null},
			}, "Generic list col style");
			
			if (styleOptions.align && !B_REST_Vuetify_GenericList_Col._ALIGNS.includes(styleOptions.align)) { this._throwEx(`Expected align to be one of ALIGN_x. Got ${styleOptions.align}`); }
			
			this._style_fromBreakpoint = styleOptions.fromBreakpoint;
			this._style_align          = styleOptions.align;
			this._style_hasRightBorder = styleOptions.hasRightBorder;
			this._style_width          = styleOptions.width;
			this._style_tdClass        = styleOptions.tdClass;
			this._style_tdStyle        = styleOptions.tdStyle;
		}
	}
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg, details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`, details); }
	
	
	get listComponent() { return this._listComponent; }
	
	
	get name()                 { return this._name;                                                                           }
	get label()                { return this._label;                                                                          }
	get debugName()            { return `B_REST_Vuetify_GenericList_Col<${this._name}@${this._listComponent.componentName}>`; }
	get vDataTableSlotName()   { return `item.${this._name}`;                                                                 } //To do something like <template #item.xxx>
	get fieldNamePaths()       { return this._fieldNamePaths;                                                                 }
	get fieldDescriptor()      { return this._fieldDescriptor;                                                                }
	get fieldDescriptor_isDB() { return this._fieldDescriptor instanceof B_REST_FieldDescriptors.DB;                          } //Means we have only 1 field and it's a DB one
	get style_fromBreakpoint() { return this._style_fromBreakpoint;                                                           }
	get style_align()          { return this._style_align;                                                                    }
	get style_hasRightBorder() { return this._style_hasRightBorder;                                                           }
	get style_width()          { return this._style_width;                                                                    }
	get vBind()                { return this._vBind;                                                                          }
	get vOn()                  { return this._vOn;                                                                            }
	get extraData()            { return this._extraData;                                                                      }
	get cssClassBase()         { return `${this._listComponent.cssClassBase}--${this._name}`;                                 } //Ex "generic-list--brandTest--firstName"
	get cssClassBase_header()  { return `${this.cssClassBase}--header`;                                                       } //Ex "generic-list--brandTest--firstName--header"
	get cssClassBase_body()    { return `${this.cssClassBase}--body`;                                                         } //Ex "generic-list--brandTest--firstName--body"
	
	
	//Props we allow changing later
	set extraData(val) { this._extraData=val; }
	set vBind(val)     { this._vBind    =val; }
	set vOn(val)       { this._vOn      =val; }
	
	//WARNING: If we point to a lookup (!fieldDescriptor_isDB), we won't be able to do .val() then
	getModelField(model) { return this._fieldDescriptor ? model.select(this._fieldNamePaths) : null; }
	
	
	
	isEditable(model) { return this._isStatusable(model,"_isEditable"); }
		_isStatusable(model, varName)
		{
			if (!this[varName])       { return false; }
			if (this[varName]===true) { return true;  }
			
			B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
			
			try       { return this[varName](this._listComponent,this,model);                      }
			catch (e) { B_REST_Utils.throwEx(`${varName} hook failed, for ${this.debugName}: ${e}`); }
			return false;
		}
	click_isEnabled(model) { return this._isStatusable(model,"_click_isEnabled"); }
	//Must ret if hook went well
	async click_hook(model)
	{
		if (!this._click_hook) { return false; }
		
		B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
		
		try       { return await this._click_hook(this._listComponent,this,model);        }
		catch (e) { B_REST_Utils.throwEx(`click_hook failed, for ${this.debugName}: ${e}`); }
		return false;
	}
	
	style_tdClass(model)
	{
		let tdClass = this._style_tdClass ? this._style_tdClass(this._listComponent,this,model) : undefined;
		
		tdClass = B_REST_VueApp_base.instance.classProp_addTag(tdClass, this.cssClassBase_body); //Ex "generic-list--brandTest--firstName--body"
		tdClass = B_REST_VueApp_base.instance.classProp_addTag(tdClass, `text-${this._style_align}`); //Ex "text-start"
		if (this._style_hasRightBorder) { tdClass=B_REST_VueApp_base.instance.classProp_addTag(tdClass, B_REST_Vuetify_GenericList_Col.DIVIDER_CLASS_NAME); }
		
		return tdClass;
	}
	style_tdStyle(model)
	{
		if (!this._style_tdStyle) { return undefined; }
		
		B_REST_Utils.instance_isOfClass_assert(B_REST_Model,model);
		return this._style_tdStyle(this._listComponent,this,model);
	}
	
	
	/*
	Outputs according to https://vuetifyjs.com/en/api/v-data-table/#props-headers
	Check BrGenericListBase.vue::toVDataTableHeaderDef() docs for more info
	*/
	toVDataTableHeaderDef(isFirstCol)
	{
		return {
			value:      this._name,
			text:       this.label,
			align:      this._style_align,
			sortable:   this._fieldDescriptor && (!this._listComponent.useForLoading || this.fieldDescriptor_isDB), //We can server sort on any DB field. If static list, then we should always be able to sort (w/o calling server), as long as it's not a custom component that has no fieldNamePaths
			filterable: isFirstCol, //Check in BrGenericListBase.vue::toVDataTableHeaderDefs() docs for why we do this
			groupable:  false,
			divider:    this._style_hasRightBorder,
			class:      this.cssClassBase_header,
			cellClass:  null, //We'll handle it ourselves
			width:      this._style_width,
			filter:     null,
			sort:       null,
			extraData:  {self:this},
		};
	}
};
