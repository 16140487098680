<!--
	NOTE:
		Requires:
			npm install -D prismjs
			npm install -D vue-prism-editor
-->
<template>
	<v-card class="my-2">
		<v-card-title>
			<span class="text-h6">{{ file.title }}</span>
			<span v-if="file.filePath" class="text-caption ml-5">{{ file.filePath }}</span>
			<v-spacer />
			<v-btn small class="ml-3" @click="on_copy"> <v-icon>mdi-content-copy</v-icon> </v-btn>
		</v-card-title>
		<v-card-text>
			<prism-editor class="my-editor" :value="output" :highlight="highlighter" line-numbers readonly />
		</v-card-text>
	</v-card>
</template>

<script>
	
	import { PrismEditor } from 'vue-prism-editor';
	import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
	import { highlight, languages } from 'prismjs/components/prism-core';
	import 'prismjs/components/prism-clike';
	import 'prismjs/components/prism-javascript';
	import 'prismjs/themes/prism-tomorrow.css';
	// import 'prismjs/components/prism-php';  //NOTE: If we include this, it breaks, so for now we're stuck with js...
	// import 'prismjs/components/prism-json'; //NOTE: If we include this, it breaks, so for now we're stuck with js...
	import GFile_base from "./GFile_base.js";
	
	
	
	export default {
		props: {
			file: {type:GFile_base, required:true},
		},
		computed: {
			output() { return this.file.output(); },
		},
		methods: {
			highlighter(code) { return highlight(code,languages.js); },
			async on_copy()
			{
				await this.$bREST.utils.clipboard_write(this.output);
				this.$bREST.notifs_tmp({msg:"Copied to clipboard",color:"info"});
			},
		},
		components: {
			PrismEditor,
		},
	};
	
</script>

<style>
	
	.my-editor {
		background: #2d2d2d;
		color: #ccc;
		font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
		font-size: 14px;
		line-height: 1.5;
		padding: 5px;
	}
		.prism-editor__textarea:focus {
			outline: none;
		}
	
</style>