
import B_REST_Utils             from "../../classes/B_REST_Utils.js";
import B_REST_App_RouteDef_base from "../../classes/app/B_REST_App_RouteDef_base.js";
import B_REST_VueApp_RouteInfo  from "./B_REST_VueApp_RouteInfo.js";



export default class B_REST_VueApp_RouteDef extends B_REST_App_RouteDef_base
{
	_meta              = null;  //Obj of custom stuff; stuff will get added to it in the actual Vue Route instances
	_viewTransferProps = null;  //Callback as (vueRouterObj) to transfer props to the viewComponent
	
	
	
	constructor(options)
	{
		super(options);
		
		//Make sure that route urls pathVars are as "/something/:someVar/else" and not "/something/{someVar}/else"
		for (const loop_lang in this._langUrls)
		{
			const loop_langUrl = this._langUrls[loop_lang];
			if (loop_langUrl.indexOf("{")!==-1) { B_REST_Utils.throwEx(`Vue Router routes pathVars must be like :someVar and not {someVar}. Got "${loop_langUrl}"`); }
		}
		
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			meta:              {accept:[Object],   required:false},
			viewTransferProps: {accept:[Function], required:false},
		}, "Vue route def");
		
		this._meta              = options.meta;
		this._viewTransferProps = options.viewTransferProps;
	}
	
	
	
	get meta()              { return this._meta;              }
	get viewTransferProps() { return this._viewTransferProps; }
	
	
	_abstract_toRouteInfo(pathVars={}, qsa={}, hashTag=null, lang=null)
	{
		const fullPath = this.toFullPath(pathVars, qsa, hashTag, lang);
		
		return new B_REST_VueApp_RouteInfo(fullPath, this, pathVars, qsa, hashTag, lang);
	}
	
	/*
	For B_REST_VueApp_base's constructor
	WARNING:
		<v-btn> and such "to" prop matches a path by default but not a route name, so we should do:
			<v-btn :to="{name:'someRouteName'}" />
	*/
	convertToVueRouteDefObj(lang)
	{
		const langUrl = this._langUrls[lang];
		if (!langUrl) { B_REST_Utils.throwEx(`Not defining lang URL for lang "${lang}"`,this); }
		
		const alias = [];
		if      (this.type_isLandpage) { alias.push("/"); }
		else if (this.type_is404)      { alias.push("*"); }
		
		return {
			path:      langUrl,
			component: this._layoutComponent,
			children: [
				{
					name:       this._name,
					path:       "",
					component:  this._viewComponent,
					props:      this._viewTransferProps,
					alias,
					meta: {
						bREST_routeDef:this, //Add a way to go back to the B_REST_App_RouteDef_base from the Vue Route
						...this._meta        //Other usage stuff
					},
				},
			],
		};
	}
};
