<template>
	<v-dialog :value="pickerHandle.isPrompting" :persistent="pickerHandle.isModal" @input="pickerHandle.cancel()" :max-width="pickerHandle.maxWidth" :fullscreen="isFullscreen">
		<component :is="pickerHandle.def.component" :picker-handle="pickerHandle" v-bind="pickerHandle.vBind" v-on="pickerHandle.vOn" />
	</v-dialog>
</template>

<script>
	
	import B_REST_Vuetify_PickerHandle from "./B_REST_Vuetify_PickerHandle.js";
	
	export default {
		props: {
			pickerHandle: {type:B_REST_Vuetify_PickerHandle, required:true},
		},
		computed: {
			isFullscreen() { return this.$bREST.uiBreakpoint.mobile; },
		},
	};
	
</script>