<template>
	
	<v-container class="fill-height">
		
		<br-generic-list-base v-if="showInvoiceList" :derived-component="_self">
			<template #item.total="{ rowInfo, colInfo, modelField }">
				{{ modelField.val }} $
			</template>
			<template #item.paid_dt="{ rowInfo, colInfo, modelField }">
				<span :class="paid_dt_class(rowInfo.model)" v-text="paid_dt_text(rowInfo.model)" />
			</template>
		</br-generic-list-base>
		
		<v-alert v-else type="info" style="width:100%">
			<p>{{ cantPayText }}</p>
    	</v-alert>
		
	</v-container>
	
</template>

<script>
	
	import { B_REST_Vuetify_GenericListBase_createMixin, B_REST_Vuetify_GenericList_Col as Col } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/list/BrGenericListBase.vue";
	
	const locBasePath = "views.invoices";
	
	
	
	export default {
		name: "invoiceList",
		mixins: B_REST_Vuetify_GenericListBase_createMixin({
			modelName: "Invoice",
			icon: "mdi-wallet-membership",
			fromLoader: {
				apiBaseUrl: "/invoices/query/",
			},
			cols: {
				"number":    {fieldNamePaths:"number",                      style:{fromBreakpoint:"xs", align:Col.ALIGN_CENTER}},
				"billTo":    {fieldNamePaths:"entity_paying.calc_label",    style:{fromBreakpoint:"xs", align:Col.ALIGN_CENTER}},
				"recipient": {fieldNamePaths:"entity_recipient.calc_label", style:{fromBreakpoint:"xs", align:Col.ALIGN_CENTER}},
				"dt":        {fieldNamePaths:"dt",                          style:{fromBreakpoint:"xs", align:Col.ALIGN_CENTER}},
				"type":      {fieldNamePaths:"type",                        style:{fromBreakpoint:"md", align:Col.ALIGN_LEFT}},
				"total":     {fieldNamePaths:"total",                       style:{fromBreakpoint:"xs", align:Col.ALIGN_RIGHT}},
				"paid_dt":   {fieldNamePaths:"paid_dt",                     style:{fromBreakpoint:"xs", align:Col.ALIGN_RIGHT}},
			},
			row: {
				checkbox: {isEnabled:true},
				actions: {
					print: {
						click: {
							async hook(listComponent,action,model)
							{
								const $bREST = listComponent.$bREST;
								
								try
								{
									const request = new $bREST.GET_File(`/invoices/print/{pk}`, {pk:model.pk});
									await $bREST.call_download(request);
								}
								catch (e)
								{
									$bREST.notifs_tmp({msg:listComponent.t("rowActions.print.error"), color:"error"});
								}
							},
						},
						icon: "mdi-file-pdf-box",
					},
					admin_markAsPaid: {
						click: {
							isEnabled(listComponent,action,model)
							{
								return listComponent.$bREST.user_type_is("admin") && !model.select("paid_dt").val;
							},
							async hook(listComponent,action,model)
							{
								if (!confirm(listComponent.t("rowActions.admin_markAsPaid.confirm"))) { return; }
								
								const $bREST = listComponent.$bREST;
								
								try
								{
									const request = new $bREST.POST(`/invoices/markAsPaid`);
									request.data_set("pk", model.pk);
									request.expectsContentType_empty();
									
									await $bREST.call(request);
									
									model.select("paid_dt").val = $bREST.utils.dt_format($bREST.utils.dt_now(), true,false,false);
									
									$bREST.notifs_tmp({msg:listComponent.t("rowActions.admin_markAsPaid.success"), color:"success"});
								}
								catch (e)
								{
									$bREST.notifs_tmp({msg:listComponent.t("rowActions.admin_markAsPaid.error"), color:"error"});
								}
							},
						},
						icon: "mdi-credit-card-outline",
					},
				},
			},
		}),
		computed: {
			showInvoiceList() { return this.$bREST.user_type==="admin" || this.$bREST.paysInvoices; },
			cantPayText() { return this._loc(`cantPay.${this.$bREST.user_type}`); },
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			paid_dt_text(model)
			{
				switch (this._getPaymentStatus(model))
				{
					case "free":  return "N/A";
					case "toPay": return this._loc('notPaid');
					case "paid":  return model.select("paid_dt").val.substr(0,10);
				}
			},
			paid_dt_class(model)
			{
				switch (this._getPaymentStatus(model))
				{
					case "free":  return "";
					case "toPay": return "error--text";
					case "paid":  return "success--text";
				}
			},
				//Rets one of free | toPay | paid
				_getPaymentStatus(model)
				{
					const total   = model.select("total").val;
					const paid_dt = model.select("paid_dt").val;
					
					if (total==0) { return "free"; }
					return paid_dt ? "paid": "toPay";
				},	
		},
	};
			
</script>