
import { B_REST_Utils } from "../../../../../classes";
import B_REST_VueApp_base from "../../../B_REST_VueApp_base.js";
import { GModel_DBField, GModel_LookupField, GModel_SubModelField, GModel_FileField, GModel_OtherField } from "./GModel_XFields.js";



export default class GModel
{
	_tableName                   = "";
	_modelClassName              = "";
	_isAutoInc                   = null;
	_pks                         = []; //Arr of {fieldName,type,loc:{label:<lang>:<translation>, shortLabel:null}}
	_isSharedList                = false;
	_routes_has                  = false;
	_apiBaseUrl_list             = null;
	_apiBaseUrl_needsAccessToken = null;
	_toLabel_fieldNamePaths      = null;
	_hasSoftDel                  = false;
	_hasCreatedDT                = false;
	_hasUpdatedDT                = false;
	
	_dbFields        = []; //Arr of GModel_DBField
	_lookupFields    = []; //Arr of GModel_LookupField
	_subModelFields  = []; //Arr of GModel_SubModelField
	_fileFields      = []; //Arr of GModel_FileField
	_otherFields     = []; //Arr of GModel_OtherField
	_indexes         = []; //Arr of {fields,isUnique}, where fields are instances of GModel_DBField or GModel_LookupField
	_indexableFields = []; //Arr of GModel_DBField & GModel_LookupField
	
	_hook_load_after       = false;
	_hook_save_before      = false;
	_hook_save_after       = false;
	_hook_delete_can_orMsg = false;
	_hook_delete_before    = false;
	_hook_delete_after     = false;
	_hook_fromObj_before   = false;
	_hook_fromObj_after    = false;
	_hook_toObj_after      = false;
	
	constructor()
	{
		this.isAutoInc = true; //Use setter
	}
		static makeLocLangsObj(defaultVal=null)
		{
			const translations = {};
			B_REST_VueApp_base.instance.appLangs.forEach(loop_lang => translations[loop_lang]=defaultVal);
			return translations;
		}
	
	
	get tableName()    { return this._tableName; }
	set tableName(val) { this._tableName=val;    }
	
	get modelClassName()        { return this._modelClassName;                                       }
	set modelClassName(val)     { this._modelClassName=val;                                          }
	get Model_ClassName()       { return `Model_${this._modelClassName}`;                            }
	get RouteParser_ClassName() { return `RouteParser_${this._modelClassName}`;                      }
	get className_list()        { return `${B_REST_Utils.string_lcFirst(this._modelClassName)}List`; }
	
	get isAutoInc() { return this._isAutoInc; }
	set isAutoInc(val)
	{
		this._isAutoInc = val;
		
		if (this._isAutoInc)
		{
			this._pks = [];
			this.pks_add("pk", "#");
		}
		else { this._pks=[]; }
	}
	
	get hasLocSubModel() { return !!this._subModelFields.find(loop_field => loop_field.fieldName==="loc"); }
	
	get isSharedList()    { return this._isSharedList; }
	set isSharedList(val) { this._isSharedList=val;    }
	
	get pks() { return this._pks; }
	get pks_isFallbackAutoIncPK() { return this._isAutoInc && this._pks.length===1 && this._pks[0].fieldName==='pk' && this._pks[0].type===GModel_DBField.TYPE_INT; }
	pks_add(fieldName=null, defaultLabel=null)
	{
		const pk = {fieldName, type:GModel_DBField.TYPE_INT, loc:{label:GModel.makeLocLangsObj(defaultLabel), shortLabel:GModel.makeLocLangsObj()}};
		this._pks.push(pk);
		return pk;
	}
	pks_del(pk) { this._pks = this._pks.filter(loop_pk => loop_pk!==pk); }
	
	get routes_has() { return this._routes_has; }
	set routes_has(val)
	{
		this._routes_has=val;
		
		if (this._routes_has)
		{
			this._apiBaseUrl_needsAccessToken = true;
		}
		else
		{
			this._apiBaseUrl_list             = null;
			this._apiBaseUrl_needsAccessToken = null;
		}
	}
	
	get apiBaseUrl_list()    { return this._apiBaseUrl_list; }
	set apiBaseUrl_list(val) { this._apiBaseUrl_list=val;    }
	get apiBaseUrl_finalWOTrailingSlashes()
	{
		if (this._apiBaseUrl_list) { return this._apiBaseUrl_list.replaceAll(/^\/|\/$/g,""); }
		
		return B_REST_Utils.string_lcFirst(this._modelClassName);
	}
	
	get apiBaseUrl_needsAccessToken()    { return this._apiBaseUrl_needsAccessToken; }
	set apiBaseUrl_needsAccessToken(val) { this._apiBaseUrl_needsAccessToken=val;    }
	
	get toLabel_fieldNamePaths()    { return this._toLabel_fieldNamePaths; }
	set toLabel_fieldNamePaths(val) { this._toLabel_fieldNamePaths=val;    }
	
	get hasSoftDel()    { return this._hasSoftDel; }
	set hasSoftDel(val) { this._hasSoftDel=val;    }
	
	get hasCreatedDT()    { return this._hasCreatedDT; }
	set hasCreatedDT(val) { this._hasCreatedDT=val;    }
	
	get hasUpdatedDT()    { return this._hasUpdatedDT; }
	set hasUpdatedDT(val) { this._hasUpdatedDT=val;    }
	
	get hook_load_after()    { return this._hook_load_after; }
	set hook_load_after(val) { this._hook_load_after=val;    }
	
	get hook_save_before()    { return this._hook_save_before; }
	set hook_save_before(val) { this._hook_save_before=val;    }
	
	get hook_save_after()    { return this._hook_save_after; }
	set hook_save_after(val) { this._hook_save_after=val;    }
	
	get hook_delete_can_orMsg()    { return this._hook_delete_can_orMsg; }
	set hook_delete_can_orMsg(val) { this._hook_delete_can_orMsg=val;    }
	
	get hook_delete_before()    { return this._hook_delete_before; }
	set hook_delete_before(val) { this._hook_delete_before=val;    }
	
	get hook_delete_after()    { return this._hook_delete_after; }
	set hook_delete_after(val) { this._hook_delete_after=val;    }
	
	get hook_fromObj_before()    { return this._hook_fromObj_before; }
	set hook_fromObj_before(val) { this._hook_fromObj_before=val;    }
	
	get hook_fromObj_after()    { return this._hook_fromObj_after; }
	set hook_fromObj_after(val) { this._hook_fromObj_after=val;    }
	
	get hook_toObj_after()    { return this._hook_toObj_after; }
	set hook_toObj_after(val) { this._hook_toObj_after=val;    }
	
	
	get dbFields() { return this._dbFields; }
	dbFields_add()
	{
		const field = new GModel_DBField(this);
		this._dbFields.push(field);
		this._indexableFields.push(field);
		return field;
	}
	dbFields_up(field)   { this._xFields_up("_dbFields",  field); }
	dbFields_down(field) { this._xFields_down("_dbFields",field); }
	dbFields_del(field)  { this._xFields_del("_dbFields", field); }
	
	get lookupFields() { return this._lookupFields; }
	lookupFields_add()
	{
		const field = new GModel_LookupField(this);
		this._lookupFields.push(field);
		this._indexableFields.push(field);
		return field;
	}
	lookupFields_up(field)   { this._xFields_up("_lookupFields",  field); }
	lookupFields_down(field) { this._xFields_down("_lookupFields",field); }
	lookupFields_del(field)  { this._xFields_del("_lookupFields", field); }
	
	get subModelFields() { return this._subModelFields; }
	subModelFields_add()
	{
		const field = new GModel_SubModelField(this);
		this._subModelFields.push(field);
		return field;
	}
	subModelFields_up(field)   { this._xFields_up("_subModelFields",  field); }
	subModelFields_down(field) { this._xFields_down("_subModelFields",field); }
	subModelFields_del(field)  { this._xFields_del("_subModelFields", field); }
	
	get fileFields() { return this._fileFields; }
	fileFields_add()
	{
		const field = new GModel_FileField(this);
		this._fileFields.push(field);
		return field;
	}
	fileFields_up(field)   { this._xFields_up("_fileFields",  field); }
	fileFields_down(field) { this._xFields_down("_fileFields",field); }
	fileFields_del(field)  { this._xFields_del("_fileFields", field); }
	
	get otherFields() { return this._otherFields; }
	otherFields_add()
	{
		const field = new GModel_OtherField(this);
		this._otherFields.push(field);
		return field;
	}
	otherFields_up(field)   { this._xFields_up("_otherFields",  field); }
	otherFields_down(field) { this._xFields_down("_otherFields",field); }
	otherFields_del(field)  { this._xFields_del("_otherFields", field); }
	otherFields_filterFieldNamesWithManualXFlag_forPHPModelOutput(xFuncName) //Ex "func_load". A prop in GModel_OtherField
	{
		const fieldNames = [];
		
		for (const loop_otherField of this._otherFields)
		{
			if (loop_otherField[xFuncName]===GModel_OtherField.FUNC_TYPE_MANUAL) { fieldNames.push(loop_otherField.fieldName); }
		}
		
		const count = fieldNames.length;
		
		return count>0 ? `, ex for ->_def_other() field${count===1?'':'s'} "${fieldNames.join('", "')}"` : "";
	}
	
	get indexes()         { return this._indexes;         }
	get indexableFields() { return this._indexableFields; }
	indexes_add()
	{
		const index = {fields:[], isUnique:false};
		this._indexes.push(index);
		return index;
	}
	indexes_up(field)   { this._xFields_up("_indexes",  field); }
	indexes_down(field) { this._xFields_down("_indexes",field); }
	indexes_del(index)  { this._xFields_del("_indexes", index); }
	
	
	
	_xFields_up(which, field)
	{
		const fields = this[which];
		const idx    = fields.indexOf(field);
		
		if (idx>0)
		{
			fields.splice(idx, 1);
			fields.splice(idx-1, 0, field);
		}
	}
	_xFields_down(which, field)
	{
		const fields = this[which];
		const idx    = fields.indexOf(field);
		
		if (idx+1 < fields.length)
		{
			fields.splice(idx, 1);
			fields.splice(idx+1, 0, field);
		}
	}
	_xFields_del(which,field) { this[which] = this[which].filter(loop_field => loop_field!==field); }
};
