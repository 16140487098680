
import { B_REST_Utils, B_REST_DOMFilePtr, B_REST_ModelFileField_Control, B_REST_ModelFileField_ControlItem } from "../../../../classes";
import B_REST_VueApp_base from "../../B_REST_VueApp_base.js";



export default class B_REST_Vuetify_Field_File extends B_REST_ModelFileField_Control
{
	static get DISPLAY_MODE_TILES()  { return "tiles";  } //Rectangle padded container with each tiles being tiles inside
	static get DISPLAY_MODE_LIST()   { return "list";   } //Vertical listing of files
	static get DISPLAY_MODE_AVATAR() { return "avatar"; } //Single container with no padding, intended to display an img file in full width
	
	static get GROUPED_UPLOADS_IGNORE_INVALIDS() { return true; } //If we use items_prepare_grouped() and any failed, just don't care about the failed one(s) instead of throwing for all
	
	static get DEFAULT_FILE_PREVIEW_SIZE() { return 200; }
	
	static get FILES_MIME_PATTERNS_DANGEROUS() { return B_REST_Utils.FILES_MIME_PATTERNS_DANGEROUS; }
	static get FILES_MIME_PATTERNS_IMG()       { return B_REST_Utils.FILES_MIME_PATTERNS_IMG;       }
	static get FILES_MIME_PATTERNS_PDF()       { return B_REST_Utils.FILES_MIME_PATTERNS_PDF;       }
	static get FILES_MIME_PATTERNS_WORD()      { return B_REST_Utils.FILES_MIME_PATTERNS_WORD;      }
	static get FILES_MIME_PATTERNS_PDF_WORD()  { return B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD;  }
	static get FILES_MIME_PATTERNS_EXCEL()     { return B_REST_Utils.FILES_MIME_PATTERNS_EXCEL;     }
	static get FILES_MIME_PATTERNS_ANY()       { return B_REST_Utils.FILES_MIME_PATTERNS_ANY;       }
	
	
	
	//More config stuff
		_displayMode           = B_REST_Vuetify_Field_File.DISPLAY_MODE_TILES;        //One of DISPLAY_MODE_x
		_filePreview_width     = B_REST_Vuetify_Field_File.DEFAULT_FILE_PREVIEW_SIZE; //In tiles / list modes: for each item. In avatar mode: for the whole container
		_filePreview_height    = B_REST_Vuetify_Field_File.DEFAULT_FILE_PREVIEW_SIZE; //Same as the above
		_label                 = null;                                                //Translated label (place localization where it's being used)
		_prependIcon           = null;                                                //Ex "mdi-image"
		_uploadMultipleGrouped = false;                                               //When we select / drop multiple files at the same time, do we want to send them all at once in a single http call, or in parallel. Speed vs multiple connections
		_allowDownloads        = true;                                                //Especially for img files when we're in DISPLAY_MODE_AVATAR
		_savedModel_autoUpdate = true;                                                //If when model is saved, we want each add and del to cause an API call right away, instead of having to trigger it manually later
	//Logic
		_lastValidationResultErrorMsgs = []; //Arr of errs for last time we went in _parseSelectionEvent()
		_validation_dirty              = false;
	
	
	
	constructor(options={})
	{
		super(options);
		
		if (B_REST_Utils.object_hasPropName(options,"filePreview_width"))     { this._filePreview_width     = options.filePreview_width;     }
		if (B_REST_Utils.object_hasPropName(options,"filePreview_height"))    { this._filePreview_height    = options.filePreview_height;    }
		if (B_REST_Utils.object_hasPropName(options,"label"))                 { this._label                 = options.label;                 }
		if (B_REST_Utils.object_hasPropName(options,"prependIcon"))           { this._prependIcon           = options.prependIcon;           }
		if (B_REST_Utils.object_hasPropName(options,"uploadMultipleGrouped")) { this._uploadMultipleGrouped = options.uploadMultipleGrouped; }
		if (B_REST_Utils.object_hasPropName(options,"allowDownloads"))        { this._allowDownloads        = options.allowDownloads;        }
		if (B_REST_Utils.object_hasPropName(options,"savedModel_autoUpdate")) { this._savedModel_autoUpdate = options.savedModel_autoUpdate; }
		
		//Display mode, or use default
		{
			if (B_REST_Utils.object_hasPropName(options,"displayMode")) { this._displayMode_set(options.displayMode); }
			else                                                        { this._displayMode = this._isMultiple ? B_REST_Vuetify_Field_File.DISPLAY_MODE_TILES : B_REST_Vuetify_Field_File.DISPLAY_MODE_AVATAR; }
		}
		
		if (!this._filePreview_width)  { this._filePreview_width  = this._filePreview_height||B_REST_Vuetify_Field_File.DEFAULT_FILE_PREVIEW_SIZE; }
		if (!this._filePreview_height) { this._filePreview_height = this._filePreview_width ||B_REST_Vuetify_Field_File.DEFAULT_FILE_PREVIEW_SIZE; }
	}
		//Redefining base class method
		static async factory_fromUploadsType(uploadsType, extraOptions={})
		{
			const baseOptions = await B_REST_Vuetify_Field_File.uploadTypes_getOptions(uploadsType);
			
			const finalOptions = {};
			Object.assign(finalOptions, baseOptions, extraOptions);
			
			return new B_REST_Vuetify_Field_File(finalOptions);
		}
	//Not an actual destructor; call manually
	destroy()
	{
		super.destroy();
	}
	
	
	
	//Getters
		get displayMode()              { return this._displayMode;                                                                                                }
		get displayMode_isTiles()      { return this._displayMode===B_REST_Vuetify_Field_File.DISPLAY_MODE_TILES;                                                 }
		get displayMode_isList()       { return this._displayMode===B_REST_Vuetify_Field_File.DISPLAY_MODE_LIST;                                                  }
		get displayMode_isAvatar()     { return this._displayMode===B_REST_Vuetify_Field_File.DISPLAY_MODE_AVATAR;                                                }
		get filePreview_width()        { return this._filePreview_width;                                                                                          } //NOTE: Always set, with fallbacks
		get filePreview_height()       { return this._filePreview_height;                                                                                         } //NOTE: Always set, with fallbacks
		get filePreview_squareSize()   { return this._filePreview_width<=this._filePreview_height ? this._filePreview_width : this._filePreview_height;           } //Get the smallest of width & height
		get label()                    { return this._label;                                                                                                      }
		get labelWStar()               { return this._required ? `${this._label} *` : this._label;                                                                }
		get prependIcon()              { return this._prependIcon;                                                                                                }
		get uploadMultipleGrouped()    { return this._uploadMultipleGrouped;                                                                                      }
		get allowDownloads()           { return this._allowDownloads;                                                                                             }
		get savedModel_autoUpdate()    { return this._savedModel_autoUpdate;                                                                                      }
		get savedModel_canAutoUpdate() { return this.savedModel_isDefined && this._savedModel_autoUpdate;                                                         }
		get maxFileCount_progress()    { return (this.items_has ? `${this.items_count}/`              : "") + `${this._maxFileCount} ${this.translate("files")}`; } //Ex "1/3 files"
		get maxSize_progress()         { return (this.items_has ? `${this.items_size_humanReadable}/` : "") + this.maxSize_humanReadable;                         } //Ex "10.00 mb/20.00 mb"
		
		//Optionaly with something like " (max 2/3, 8mb/10mb)" to indicate we can add up to X file + within X byte size
		get placeholder()
		{
			const tag_can   = this.items_canPutMore ? "can"      : "cant";
			const tag_mode  = this._isMultiple      ? "multiple" : "single";
			let placeholder = this.translate(`placeholder.${tag_can}.${tag_mode}`);
			
			const limitations = [];
				if (this._maxFileCount && this._isMultiple) { limitations.push(this.maxFileCount_progress); }
				if (this._maxSize)                          { limitations.push(this.maxSize_progress);      }
			if (limitations.length>0) { placeholder += ` (max ${limitations.join(', ')})`; }
			
			return placeholder;
		}
		
		//Takes a combination of "blurry" general validation + last adds tentatives error msgs
		get errorMsgs()
		{
			if (this._lastValidationResultErrorMsgs.length>0) { return this._lastValidationResultErrorMsgs; }
			
			if (this._validation_dirty)
			{
				const validation_errorMsg = this.validation_errorMsg;
				if (validation_errorMsg) { return [validation_errorMsg]; }
			}
			
			return [];
		}
		get errorMsgs_has() { return this.errorMsgs.length>0; }
	
	
	
	//Things that don't matter if we alter later
		set filePreview_width(val)     { this._filePreview_width     = val; }
		set filePreview_height(val)    { this._filePreview_height    = val; }
		set label(val)                 { this._label                 = val; }
		set prependIcon(val)           { this._prependIcon           = val; }
		set uploadMultipleGrouped(val) { this._uploadMultipleGrouped = val; }
		set allowDownloads(val)        { this._allowDownloads        = val; }
		set savedModel_autoUpdate(val) { this._savedModel_autoUpdate = val; }
		
		set displayMode(val) { this._displayMode_set(val); }
			_displayMode_set(val)
			{
				const modes = [B_REST_Vuetify_Field_File.DISPLAY_MODE_TILES, B_REST_Vuetify_Field_File.DISPLAY_MODE_LIST, B_REST_Vuetify_Field_File.DISPLAY_MODE_AVATAR];
				if (!modes.includes(this._displayMode)) { B_REST_Utils.throwEx(`Expects displayMode to be one of DISPLAY_MODE_x`); }
				
				if (this._isMultiple && val===B_REST_Vuetify_Field_File.DISPLAY_MODE_AVATAR) { B_REST_Utils.throwEx(`DISPLAY_MODE_AVATAR can only be used if !isMultiple`); }
				
				this._displayMode = val;
			}
	
	
	
	/*
	Ex:
		translate("validationResults.readonly")
		translate("validationResults.sizeTotal", {current:900,max:1000})
	*/
	translate(msgPath, details=null) { return B_REST_VueApp_base.instance.t_core(`models.files_toRearrange.${B_REST_VueApp_base.instance.locale_lang}.${msgPath}`,details); }
	
	
	
	//BLURRABLE VALIDATION RELATED
		get validation_dirty()    { return this._validation_dirty; }
		get validation_errorMsg() { return this._required && !this.items_nonDeleted_has ? this.translate("isRequired") : null; }
		get validation_isValid()  { return this.validation_errorMsg===null; }
		validation_touch() { this._validation_dirty = true; }
		validation_reset()
		{
			this._validation_dirty              = false;
			this._lastValidationResultErrorMsgs = [];
		}
	
	
	
	//Redefining base method
	async savedModel_waitTransfers_saveChanges()
	{
		await super.savedModel_waitTransfers_saveChanges();
		
		this.validation_reset();
	}
	
	
	
	/*
	Event for when we change files in an <input type="file">
	Rets if we got valid files
	WARNING:
		Don't forget to do this after the call, if we want to react to next changes:
			domInputFile.value = null;
	*/
	parseSelectionEvent_onInputFileChange(domInputFile)
	{
		const domFilePtrOrArrOrNULL = B_REST_DOMFilePtr.from_fileInput(domInputFile);
		return this._parseSelectionEvent(domFilePtrOrArrOrNULL);
	}
	/*
	Event for when we drop anything (even buttons) over something. Ignores if we got no files in the end
	Rets if we got valid files
	*/
	parseSelectionEvent_onDrop(dropEvent)
	{
		const domFilePtrOrArrOrNULL = B_REST_DOMFilePtr.from_dropEvent(dropEvent, this._isMultiple);
		return this._parseSelectionEvent(domFilePtrOrArrOrNULL);
	}
		/*
		Takes B_REST_DOMFilePtr instance(s) and adds / replace items in the control
		Also takes care of updating an existing model, if we don't want to wait to save things manually later
		Does lots of async stuff, but doesn't wait for them to finish
		Rets if we got valid files
		*/
		_parseSelectionEvent(domFilePtrOrArrOrNULL)
		{
			if (domFilePtrOrArrOrNULL===null) { return false; }
			
			//Uniformize to always work with arrs
			const domFilePtrArr = B_REST_Utils.array_is(domFilePtrOrArrOrNULL) ? domFilePtrOrArrOrNULL : [domFilePtrOrArrOrNULL];
			
			let validationResultArr = null; //Arr of validationResult from items_prepare_grouped() or items_prepare()
			
			if (this._isMultiple)
			{
				if (this._uploadMultipleGrouped) { validationResultArr = this.items_prepare_grouped(domFilePtrArr,B_REST_Vuetify_Field_File.GROUPED_UPLOADS_IGNORE_INVALIDS).validationResultArr; } //Rets as {validationResultArr, uploadPromise:null}
				else                             { validationResultArr = domFilePtrArr.map(loop_domFilePtr => this.items_prepare(loop_domFilePtr).validationResult); } //Rets as {validationResult, uploadPromise=null}
			}
			else
			{
				if (domFilePtrArr.length>1) { this._verboseLog("_parseSelectionEvent","Got multiple files for a single file model field, so discarding extra ones",false); }
				
				const validationResult = this.items_prepare(domFilePtrArr[0]).validationResult; //Rets as {validationResult, uploadPromise=null}
				validationResultArr = [validationResult];
			}
			
			//Check if we've got any err to report
			const errorMsgs = [];
			let addedFiles  = false;
			validationResultArr.forEach((loop_validationResult,loop_idx) =>
			{
				if (loop_validationResult===B_REST_ModelFileField_Control.VALIDATION_RESULT_OK) { addedFiles=true; }
				else
				{
					if (!B_REST_Vuetify_Field_File.GROUPED_UPLOADS_IGNORE_INVALIDS) { addedFiles=false; }
					
					const loop_domFilePtr = domFilePtrArr[loop_idx];
					let   loop_errorMsg   = null;
					
					switch (loop_validationResult)
					{
						case B_REST_ModelFileField_Control.VALIDATION_RESULT_READONLY:
							loop_errorMsg = this.translate("validationResults.readonly");
						break;
						case B_REST_ModelFileField_Control.VALIDATION_RESULT_COUNT:
							loop_errorMsg = this.translate("validationResults.count", {maxFileCount:this._maxFileCount});
						break;
						case B_REST_ModelFileField_Control.VALIDATION_RESULT_SIZE_PER_FILE:
							loop_errorMsg = this.translate("validationResults.sizePerFile", {itemSize:loop_domFilePtr.size_humanReadable,maxSize:this.maxSize_perFile_humanReadable});
						break;
						case B_REST_ModelFileField_Control.VALIDATION_RESULT_SIZE_TOTAL:
							loop_errorMsg = this.translate("validationResults.sizeTotal", {currentSize:this.items_size_humanReadable,itemSize:loop_domFilePtr.size_humanReadable,maxSize:this.maxSize_humanReadable});
						break;
						case B_REST_ModelFileField_Control.VALIDATION_RESULT_DANGEROUS:
							loop_errorMsg = this.translate("validationResults.dangerous");
						break;
						case B_REST_ModelFileField_Control.VALIDATION_RESULT_MIME:
							loop_errorMsg = this.translate("validationResults.mime"); //We could display this._acceptMimePattern, but it's not really user friendly
						break;
						default:
							B_REST_Utils.throwEx(`Unexpected validation result "${loop_validationResult}"`);
						break;
					}
					
					errorMsgs.push(`${loop_domFilePtr.baseNameWExt}: ${loop_errorMsg}`);
				}
			});
			
			this._lastValidationResultErrorMsgs = errorMsgs;
			
			if (addedFiles)
			{
				this.validation_touch();
				
				//If we want to auto save changes to API calls
				if (this.savedModel_canAutoUpdate) { this.savedModel_waitTransfers_saveChanges(); } //Async, but we won't care here
			}
			
			return addedFiles;
		}
	
	
	
	//Careful: not to confuse with items_destroy_one(); here, we either destroy it or flag ifStored_toDelete + optionally save changes
	items_toggle_remove(item)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_ModelFileField_ControlItem, item);
		
		if (item.status_isStored)
		{
			//If we want to del it
			if (!item.ifStored_toDelete)
			{
				item.ifStored_toDelete = true;
				this.validation_touch();
				
				//If we want to auto save changes to API calls
				if (this.savedModel_canAutoUpdate) { this.savedModel_waitTransfers_saveChanges(); } //Async, but we won't care here
			}
			//If we were already deleting it and we want to revert, when we're not already commiting the fact that we previously wanted to del it
			else if (!item.asyncTaskBatchId)
			{
				item.ifStored_toDelete = false;
				this.validation_touch();
			}
		}
		else
		{
			this.items_destroy_one(item);
			this._lastValidationResultErrorMsgs = [];
		}
	}
};
