
import GFile_base from "../../utils/GFile_base.js";



function empty(val) { return val===null||val===''; }



export default class GFile_Custom extends GFile_base
{
	_form = null;
	
	constructor(form)
	{
		super();
		
		this._form = form;
	}
	
	
	get _abstract_title()    { return `bREST Custom (Routes)`;   }
	get _abstract_filePath() { return `@/main.js`;               }
	
	
	_abstract_output()
	{
		return `
...
B_REST_Vue.singleton_init({
	...
	routes: [
		...
		...B_REST_Vue.helper_makeAuthenticatedModulesRoutes({
			layoutComponent: ()=>import("@/layouts/modules/Index.vue"),
			modules: {
				...
				${this._form.routerModuleName}: {
					langBaseRoutes: {fr:"${this._form.routerPathLang}", en:"${this._form.routerPathLang}", es:"${this._form.routerPathLang}"},
					listComponent:  () => import("${this._form.componentFilePath_list}"),
					formComponent:  () => import("${this._form.componentFilePath_form}"),
					listMeta:       {public:false}, //Tmp for tests
					formMeta:       {public:false}, //Tmp for tests
				},
				...
			},
		}),
		...
	],
	...
});
...`;
	}
};
