
export default [
	{
		path: "/generator/model",
		component: () => import(/* webpackMode: "eager" */ "./model/Index.vue"),
	},
	{
		path: "/generator/form",
		component: () => import(/* webpackMode: "eager" */ "./form/Index.vue"),
	},
	{
		path: "/generator",
		component: () => import(/* webpackMode: "eager" */ "./Index.vue"),
	},
	{path:"*", redirect:"/generator"},
];
