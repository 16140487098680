
import B_REST_Utils      from "../B_REST_Utils.js";
import B_REST_Descriptor from "./B_REST_Descriptor.js";



export default class B_REST_CustomFilterDescriptor
{
	_name = null; //Name of the filter in the -host- model
	//Optional stuff, when it's a custom filter that does correspond to another (common) model's B_REST_FieldDescriptor_DB, instead of something completely custom
	_foreignModel_name                   = null; //Ex "User"
	_foreignModel_dbFieldName            = null; //Ex "firstName"
	_foreignModel_fieldDescriptor_cached = null; //Ptr to its B_REST_FieldDescriptor_DB
	
	
	constructor(name, foreignModel_name=null, foreignModel_dbFieldName=null)
	{
		if ((!!foreignModel_name) !== (!!foreignModel_dbFieldName)) { B_REST_Utils.throwEx(`Both foreignModel_x params must both be set or not at all`); }
		
		this._name                     = name;
		this._foreignModel_name        = foreignModel_name;
		this._foreignModel_dbFieldName = foreignModel_dbFieldName;
	}
	
	
	get name() { return this._name; }
	
	get foreignModel_has()         { return !!this._foreignModel_name;      }
	get foreignModel_name()        { return this._foreignModel_name;        }
	get foreignModel_dbFieldName() { return this._foreignModel_dbFieldName; }
	get foreignModel_fieldDescriptor()
	{
		if (!this._foreignModel_fieldDescriptor_cached)
		{
			if (!this._foreignModel_name) { B_REST_Utils.throwEx(`foreignModel_x params not specified for this custom field`); }
			
			const descriptor = B_REST_Descriptor.commonDefs_get(this._foreignModel_name);
			
			this._foreignModel_fieldDescriptor_cached = descriptor.dbFields_find(this._foreignModel_dbFieldName); //Throws
		}
		
		return this._foreignModel_fieldDescriptor_cached;
	}
};
