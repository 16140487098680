<template>
	<v-data-table :headers="listComponent.currentBreakpoint_vDataTableHeaderDefs"
					item-key="frontendUUID"
					:items="listComponent.final_items"
					:items-per-page="listComponent.final_paging_size"
					:server-items-length="listComponent.final_server_nbRecords"
					@[listComponent.useForLoading?`update:options`:null]="listComponent.on_paginationOrSort_change($event)"
					:footer-props="listComponent.final_footerProps"
					:search="listComponent.ctrlF3_val"
					v-model="listComponent.selectedItems"
					@input="listComponent.on_selectedItems_change()"
					selectable-key="isSelectable"
					:show-select="listComponent.final_checkboxes_show"
					:single-select="listComponent.final_isPicker_isSingle"
					class="elevation-1"
					:custom-filter="customFilterCallback"
					:[!listComponent.useForLoading?`custom-sort`:null]="customSortCallback"
					multi-sort
					:loading="listComponent.final_isLoading"
					:mobile-breakpoint="GENERIC_LIST_CONSTS.NEVER_SWITCH_TO_MOBILE_REPRESENTATION_BREAKPOINT"
					dense
	> <!-- Still need events like @update:items-per-page to know when paging changes -->
		
		<!-- Body rows -->
		<template #item="{ index, item:{rowInfo:loop_rowInfo}, isMobile, isSelected, select }">
			<tr :class="listComponent.row_evalClass(loop_rowInfo)" :style="listComponent.row_evalStyle(loop_rowInfo)" @click.stop="loop_rowInfo.isClickable ? listComponent.on_row_click(loop_rowInfo) : null">
				
				<!-- Checkbox cell -->
				<td class="text-start" v-if="listComponent.final_checkboxes_show">
					<template v-if="loop_rowInfo.isSelectable">
						<template v-if="listComponent.final_isPicker_isSingle">
							<v-icon color="grey" class="d-inline-block" @click.stop="select(true)">mdi-circle-outline</v-icon>
							<v-fab-transition> <v-icon v-if="isSelected" color="green" class="d-inline-block ml-n6" @click.stop="select(false)">mdi-checkbox-marked-circle</v-icon> </v-fab-transition>
						</template>
						<template v-else>
							<v-simple-checkbox :value="isSelected" @input="select(!isSelected)" :ripple="false" />
						</template>
					</template>
				</td>
				
				<!-- Normal cells -->
				<td v-for="(loop_col,loop_idx) in listComponent.currentBreakpoint_cols"
					:key="loop_idx"
					:class="loop_col.style_tdClass(loop_rowInfo.model)"
					:style="loop_col.style_tdStyle(loop_rowInfo.model)"
					@click.stop="loop_rowInfo.cols[loop_col.name].isClickable ? listComponent.on_cell_click(loop_col,loop_rowInfo) : null"
				>
					
					<!--
						If the usage passes a slot like <template #item.colName="{ rowInfo, colInfo, modelField:null }">
						Note that it should start with a <div>.
						Check toVDataTableItem() docs
						Row info:
							{
								model:        B_REST_Model,
								isClickable:  <bool>
								isSelectable: <bool>
								actions:      [{action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}],
								cols:         Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
							}
						Where:
							colInfo    = rowInfo.cols[<thisCol>]
							modelField = colInfo.modelField | NULL
						WARNING:
							modelField is NULL if !B_REST_Vuetify_GenericList_Col::fieldDescriptor_isDB() (ex we have no field, multiple fields, or point on a lookup)
					-->
					<template v-if="col_definesTDSlot(loop_col)">
						<slot :name="loop_col.vDataTableSlotName" v-bind="{ rowInfo:loop_rowInfo, colInfo:loop_rowInfo.cols[loop_col.name], modelField:loop_rowInfo.cols[loop_col.name].modelField }" />
					</template>
					
					<!-- If we get here, it's sure we have a SINGLE fieldNamePath & fieldDescriptor behind the col + that it's editable -->
					<br-field-db v-else-if="loop_rowInfo.cols[loop_col.name].isEditable" :field="loop_rowInfo.cols[loop_col.name].modelField" no-label v-bind="loop_col.vBind" v-on="loop_col.vOn" />
					
					<!-- Same again, we have a SINGLE fieldNamePath & fieldDescriptor, just it's RO -->
					<div v-else :key="loop_idx" v-text="col_valToText(loop_rowInfo,loop_col)" />
					
				</td>
				
				<!-- Actions cell -->
				<td v-if="listComponent.rowActions_uses" class="text-center">
					<v-tooltip v-for="(loop_actionInfo,loop_actionInfo_idx) in loop_rowInfo.actions" :key="`action-${loop_actionInfo_idx}`" bottom>
						{{ loop_actionInfo.action.label }}
						<template #activator="{ on, attrs }">
							<v-btn :disabled="!loop_actionInfo.isClickable" v-bind="loop_actionInfo.action.getBtnAttrs(loop_actionInfo.isClickable)" class="mx-1" @click.stop="loop_actionInfo.isClickable ? listComponent.on_rowAction_click(loop_actionInfo.action,loop_rowInfo) : null">
								<v-icon small v-bind="attrs" v-on="on" v-text="loop_actionInfo.action.icon" />
							</v-btn>
						</template>
					</v-tooltip>
				</td>
				
			</tr>
		</template>
		
	</v-data-table>
</template>

<script>
	
	import { GENERIC_LIST_CONSTS } from "./BrGenericListBase.vue";

	
	
	export default {
		props: {
			listComponent: {type:Object, required:true}, //Derived component instance using this base component
		},
		data()
		{
			return {
				GENERIC_LIST_CONSTS,
			};
		},
		//Prevent hell
		created()
		{
			for (const loop_col of this.listComponent.cols)
			{
				//NOTE: Not sure if we should check against !fieldDescriptor_isDB or !fieldDescriptor
				if (!loop_col.fieldDescriptor_isDB && !this.col_definesTDSlot(loop_col))
				{
					this.listComponent.throwEx(`Col ${loop_col.name} requires defining a <template #${loop_col.vDataTableSlotName}> because it's not exactly bound to 1 B_REST_FieldDescriptor_DB`);
				}
			}
		},
		methods: {
			col_definesTDSlot(col) { return !!this.$scopedSlots[col.vDataTableSlotName]; },
			customFilterCallback(loop_cellVal, nonEmptySearchString, loop_item)
			{
				return this.listComponent.on_ctrlF3_update(loop_cellVal, nonEmptySearchString, loop_item);
			},
			customSortCallback(filteredItems, sortedColList_names, sortedColList_isDesc, locale, customSorters)
			{
				return this.listComponent.on_sort_update(filteredItems, sortedColList_names, sortedColList_isDesc, locale, customSorters);
			},
			//NOTE: modelField could be NULL, ex if we forgot required fields (modelList.requiredFields.requiredFields_addFields())
			col_valToText(rowInfo, col)
			{
				const modelField = rowInfo.cols[col.name].modelField;
				
				return modelField ? modelField.valToText() : null;
			},
		},
	};
	
</script>

<style scoped>
	
	.br-generic-list--toRemoveOrDelete {
		background-color: #9e9e9e;
		pointer-events: none;
	}
	
</style>