
import { B_REST_Utils } from "../../../../../classes";



export default class GFile_base
{
	constructor() {}
	
	
	get _abstract_title()    { B_REST_Utils.throwEx(`Must override base method`); }
	get _abstract_filePath() { B_REST_Utils.throwEx(`Must override base method`); }
	_abstract_output()       { B_REST_Utils.throwEx(`Must override base method`); }
	
	
	get title()    { return this._abstract_title;    }
	get filePath() { return this._abstract_filePath; }
	output()       { return this._abstract_output(); }	
};
