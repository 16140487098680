
import B_REST_Utils                        from "../B_REST_Utils.js";
import B_REST_Descriptor                   from "../descriptors/B_REST_Descriptor.js";
import B_REST_Model                        from "./B_REST_Model.js";
import B_REST_Model_Load_RequiredFields    from "./B_REST_Model_Load_RequiredFields.js";
import { B_REST_Model_Load_SearchOptions } from "./B_REST_Model_Load_SearchOptions.js";



export default class B_REST_ModelList
{
	static get USE_FOR_LOADING_PAGING_CALC_FOUND_ROWS_COUNT_DEFAULT_VAL() { return true; } //Because if we don't do that, paging is useless. Helps ex BrGenericListBase.vue::final_server_nbRecords()
	
	_models     = [];   //Arr of B_REST_Model instances. Must be behind the specified B_REST_Descriptor instance
	_descriptor = null; //Instance of B_REST_Descriptor. Contained models will have to be of that type
	_extraData  = null; //Anything we would want to carry on the instance for user algo
	_hook_onAdd = null; //Callback as (modelList<B_REST_ModelList>, models<B_REST_Model arr>) that'll be called every time we add or done loading models (after it's added)
	
	//Things for when we want to use this to load (useForLoading=true), not just to dump existing models from outside
	_searchOptions               = null;  //Instance of B_REST_Model_Load_SearchOptions
	_requiredFields              = null;  //Instance of B_REST_Model_Load_RequiredFields
	_apiBaseUrl                  = null;  //By default we use descriptor's apiBaseUrl_list, but we can override here. WARNING: Check docs here in _load() and in B_REST_Descriptor::load_list()
	_apiBaseUrl_path_vars        = null;  //If we must specify extra path vars for the descriptor's apiBaseUrl_list, or the one we're trying to override here
	_apiBaseUrl_needsAccessToken = null;  //By default we use descriptor's apiBaseUrl_list, but we can override here. Check notes in B_REST_Request::_needsAccessToken var def. Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
	_hook_afterLoad              = null;  //Async hook as (response<B_REST_Response>, models<B_REST_Model arr>) (Can be defined in B_REST_Descriptor::hook_load_after too)
	_isLoading                   = null;  //If we're currently in any function that causes a re/load of models
	_useCachedShare              = false; //If models we load, we want to put them in a global cache (when these models are usually meant to be used in lookups)
	
	
	/*
	About useForLoading:
		Especially when we use that in a B_REST_ModelField_SubModelList, we want to let the parent model handle creating and adding sub models in the model list,
		and we don't want to be able to do reload(), nav_x() or loadMore(), because it'd get attached to the wrong apiUrl anyways,
		and maybe show data from other instances, and if we alt it and save, maybe we could migrate data between entities and cause hell,
		so in those cases, leave useForLoading to false
	About useCachedShare:
		Can only be true when useForLoading and should be to ease lookup fields
		Check B_REST_Descriptor::load_list()
		WARNING: When we do adds, we won't add them to the cache, because we might not have their PK yet, and we don't want to start listening to when we call save later
	*/
	constructor(descriptor, useForLoading=true, useCachedShare=false)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Descriptor, descriptor);
		
		this._descriptor = descriptor;
		
		if (useForLoading)
		{
			this._searchOptions  = new B_REST_Model_Load_SearchOptions(this._descriptor);
			this._requiredFields = new B_REST_Model_Load_RequiredFields(this._descriptor);
			
			/*
			NOTE:
				Maybe we could put that in BrGenericListBase's constructor options,
				however when we boot a data table with its commonDefs_make_providingModelList() or commonDefs_make_withNewName(),
				then we could be passing an instance of a model list that had paging_calcFoundRowsCount===false and get incoherent results
				or progs no figuring out why some data tables have paging and others don't, so it's maybe best to just always have them by default
			*/
			this._searchOptions.paging_calcFoundRowsCount = B_REST_ModelList.USE_FOR_LOADING_PAGING_CALC_FOUND_ROWS_COUNT_DEFAULT_VAL;
		}
		
		if (useCachedShare)
		{
			if (!useForLoading) { B_REST_ModelList._throwEx(`Can only set useCachedShare when useForLoading is also true`); }
			this._useCachedShare = useCachedShare;
		}
	}
		//Creates an instance, from a common B_REST_Descriptor (by name)
		static commonDefs_make(name, useForLoading=true, useCachedShare=false)
		{
			const descriptor = B_REST_Descriptor.commonDefs_get(name);
			return new B_REST_ModelList(descriptor, useForLoading, useCachedShare);
		}
		clone(useForLoading, reuseHooks)
		{
			const cloned = new B_REST_ModelList(this._descriptor, useForLoading);
			
			B_REST_Utils.console_todo([
				"Do a deep clone for search options"
			]);
			
			cloned._requiredFields = this._requiredFields.clone();
			cloned._searchOptions  = this._searchOptions.clone_shallow(); //For now; but we should do a deep clone
			
			cloned._apiBaseUrl                  = this._apiBaseUrl;
			cloned._apiBaseUrl_path_vars        = this._apiBaseUrl_path_vars;
			cloned._apiBaseUrl_needsAccessToken = this._apiBaseUrl_needsAccessToken;
			cloned._extraData                   = this._extraData;
			cloned._useCachedShare              = this._useCachedShare;
			if (reuseHooks)
			{
				cloned._hook_onAdd     = this._hook_onAdd;
				cloned._hook_afterLoad = this._hook_afterLoad;
			}
			
			return cloned;
		}
	
	
	
	static _throwEx(msg, details=null) { B_REST_Utils.throwEx(msg,details); }
	       _throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`,details); }
	
	
	
	get descriptor() { return this._descriptor;                               }
	get debugName()  { return `B_REST_ModelList<${this._descriptor.name}>`;   }
	get models()     { return this._models;                                   }
	get count()      { return this._models.length;                            }
	get has()        { return this._models.length>0;                          }
	get first()      { this._assert_has(); return this._models[0];            }
	get last()       { this._assert_has(); return this._models[this.count-1]; }
		_assert_has()
		{
			if (!this.has) { this._throwEx(`Can't do this, because it doesn't have any models`); }
		}
	
	get extraData()    { return this._extraData; }
	set extraData(val) { this._extraData = val;  }
	
	get hook_onAdd()    { return this._hook_onAdd; }
	set hook_onAdd(val) { this._hook_onAdd = val;  }
	
	get hook_afterLoad()    { return this._hook_afterLoad; }
	set hook_afterLoad(val) { this._hook_afterLoad = val;  }
	
	get useCachedShare() { return this._useCachedShare; }
	
	
	//Throws when out of bounds
	get_byIdx(idx)
	{
		if (idx >= this.count) { this._throwEx(`Idx #${idx}/${this.count} is out of bounds`); }
		return this._models[idx];
	}
	/*
	Pass as map of fieldName=>fieldVal when multi-field PK. Rets NULL when not found
	NOTE: For single field PKs, we can either pass a single val or field map
	*/
	get_byPK(pkValOrFieldMap)
	{
		const pkTag = this._descriptor.pkToTag_vals(pkValOrFieldMap);
		
		for (const loop_model of this._models)
		{
			if (loop_model.pk_tag===pkTag) { return loop_model; }
		}
		
		return null;
	}
	//Doesn't alloc fields if not req
	get_byFrontendUUID(frontendUUID)
	{
		for (const loop_model of this._models)
		{
			if (loop_model.frontendUUID===frontendUUID) { return loop_model; }
		}
		
		return null;
	}
	/*
	Rets the 1st match, or NULL. Doesn't alloc fields if not req
	WARNING: Will only work with B_REST_FieldDescriptor_DB, though no validation is done about that
	*/
	get_byFieldNamePathVal(fieldNamePath, val)
	{
		for (const loop_model of this._models)
		{
			if (loop_model.select_isUsed(fieldNamePath) && loop_model.select(fieldNamePath).val===val) { return loop_model; }
		}
		
		return null;
	}
	/*
	Same as the above, but we can specify multiple fieldNamePath => val at the same time
	WARNING: Will only work with B_REST_FieldDescriptor_DB, though no validation is done about that
	*/
	get_byFieldNamePathValMap(fieldNamePathValMap)
	{
		for (const loop_model of this._models)
		{
			let matches = true;
			
			for (const loop_fieldNamePath in fieldNamePathValMap)
			{
				if (!loop_model.select_isUsed(loop_fieldNamePath)) { matches=false; break; }
				
				if (loop_model.select(loop_fieldNamePath).val!==fieldNamePathValMap[loop_fieldNamePath]) { matches=false; break; }
			}
			
			if (matches) { return loop_model; }
		}
		
		return null;
	}
	
	/*
	Either adds from an existing model, or pops a new at the same time, so we don't have to import lots of classes and remember types
	WARNING:
		We don't make sure that fields we care about in requiredFields are actually loaded in instances we receive
		Check warnings about useCachedShare in constructor
	*/
	add(model=null)     { return this._addX(/*prepend*/false, model); }
	prepend(model=null) { return this._addX(/*prepend*/true,  model); }
		_addX(prepend, model=null)
		{
			if (model===null) { model = new B_REST_Model(this._descriptor); }
			//Else validate
			else
			{
				if (!(model instanceof B_REST_Model))    { this._throwEx(`Expected a B_REST_Model of descriptor "${this._descriptor.name}`); }
				if (model.descriptor!==this._descriptor) { this._throwEx(`Expected a B_REST_Model of descriptor "${this._descriptor.name}; got "${model.descriptor.name}"`); }
			}
			
			if (prepend) { this._models.unshift(model); }
			else         { this._models.push(model);    }
			
			//Check if we have to advise external code that we've added new models. Especially for B_REST_ModelField_SubModelList
			if (this._hook_onAdd)
			{
				try
				{
					this._hook_onAdd(this, [model]);
				}
				catch (e) { B_REST_Utils.console_error(`onAdd hook failed, for ${this.debugName}: ${e}`); } //WARNING: Could cause prob to switch to throwEx() - check code below
			}
			
			return model;
		}
	
	/*
	Removes from the arr, without deleting in server
	WARNING:
		This doesn't make sure the passed sub model actually is part of this instance
	*/
	destroy(model)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Model, model);
		
		B_REST_Utils.array_remove_byVal(this._models, model);
	}
	destroy_toRemoveOrDelete()
	{
		this._models = this._models.filter(loop_model => !loop_model.toRemoveOrDelete);
	}
	destroy_all() { this._models = []; }
	
	
	
	//UNSAVED CHANGES RELATED
		get unsavedChanges_has() { return this._unsavedChanges_getModels(/*onlyOne*/true,/*ignoreToRemoveToDelete*/false).length>0; } //WARNING: If we ignore those to toRemove / toDelete, then if we're in a B_REST_ModelField_SubModelList, parent model's toObj() will skip them
		//Like indicated in B_REST_Model::unsavedChanges_getFields(), doesn't unset toRemove / toDelete flag
		unsavedChanges_unflag_all(cleanupDeletions)
		{
			if (cleanupDeletions) { this.destroy_toRemoveOrDelete(); }
			
			for (const loop_model of this._models) { loop_model.unsavedChanges_unflagAllFields(cleanupDeletions); }
		}
		unsavedChanges_flag_all()
		{
			for (const loop_model of this._models) { loop_model.unsavedChanges_flagAllFields(); }
		}
		/*
		Same idea as in B_REST_Model::unsavedChanges_getFields()
		WARNING: Doesn't ret those to remove / delete
		*/
		unsavedChanges_getModels(ignoreToRemoveToDelete=true) { return this._unsavedChanges_getModels(/*onlyOne*/false,ignoreToRemoveToDelete); }
			/*
			Like indicated in B_REST_Model::unsavedChanges_getFields(), even if they don't have unsaved changes, if they're flagged as toRemove / toDelete, they'll count
			Ignore toRemove / toDelete ones
			*/
			_unsavedChanges_getModels(onlyOne, ignoreToRemoveToDelete)
			{
				const models = [];
				
				for (const loop_model of this._models)
				{
					if (ignoreToRemoveToDelete && loop_model.toRemoveOrDelete) { continue; }
					
					if (loop_model.unsavedChanges_has)
					{
						models.push(loop_model);
						if (onlyOne) { break; }
					}
				}
				
				return models;
			}
	
	
	
	//USER TOUCH RELATED
		get userTouch_has() { return this._userTouch_getModels(/*onlyOne*/true,/*ignoreToRemoveToDelete*/true).length>0; }
		//Ignore toRemove / toDelete ones
		userTouch_toggle_all(touched)
		{
			for (const loop_model of this.models) { loop_model.userTouch_toggleAllFields(touched); }
		}
		//Same idea as in B_REST_Model::userTouch_getFields()
		userTouch_getModels(ignoreToRemoveToDelete=true) { return this._userTouch_getModels(/*onlyOne*/false,ignoreToRemoveToDelete); }
			//Ignore toRemove / toDelete ones
			_userTouch_getModels(onlyOne, ignoreToRemoveToDelete)
			{
				const models = [];
				
				const whichArr = ignoreToRemoveToDelete ? this.models : this._models;
				for (const loop_model of whichArr)
				{
					if (ignoreToRemoveToDelete && loop_model.toRemoveOrDelete) { continue; }
					
					if (loop_model.userTouch_has)
					{
						models.push(loop_model);
						if (onlyOne) { break; }
					}
				}
				
				return models;
			}
	
	
	
	//FROM / TO OBJ RELATED
		fromObj(obj, destroyOldModels=true, skipIllegalChanges=false)
		{
			B_REST_Utils.array_assert(obj);
			
			if (destroyOldModels) { this.destroy_all(); }
			
			const isMultiFieldPK = this._descriptor.isMultiFieldPK;
			const pkNames        = [];
			
			//Get only the PK fields we care about. So if it has a parent model and we bind to it via a FK, then don't include that field
			for (const loop_fieldDescriptor of this._descriptor.pks) { pkNames.push(loop_fieldDescriptor.name); }
			const pkFieldCount = pkNames.length;
			
			for (const loop_obj of obj)
			{
				let loop_model = null;
				
				/*
				If we can find it back by "_apiUID_"
				WARNING:
					If we have 2 distinct lists in cached share referring the same models (ex we have a region tree, one list loads all lvls and another just the first 1~2)
					then they will all have distinct UUIDs that don't match, so code will think they are new ones, when we try to overwrite cache in
					B_REST_Model::cachedShare_put(..., overwriteIfMoreFieldsSet)
					To go around this, then we'll have to compare by PK anyways
				*/
				if (loop_obj[B_REST_Model.API_UID_FIELDNAME]) { loop_model = this.get_byFrontendUUID(loop_obj[B_REST_Model.API_UID_FIELDNAME]); }
				
				/*
				Else by PK. This happens if it's an existing record where we didn't have unsaved changes
				WARNING:
					Backend's Descriptor_base::toObj() doesn't expose PK-FKs, so if it's a fk-lang thing, we'll only get back the lang
				*/
				if (!loop_model)
				{
					const loop_pkFieldMap = {};
					for (const loop_pkName of pkNames)
					{
						if (B_REST_Utils.object_hasPropName(loop_obj,loop_pkName)) {loop_pkFieldMap[loop_pkName] = loop_obj[loop_pkName]; }
					}
					const loop_pkFieldFoundCount = Object.keys(loop_pkFieldMap).length;
					if (loop_pkFieldFoundCount>0) { loop_model = this.get_byFieldNamePathValMap(loop_pkFieldMap); } //Check warning about why we don't use get_byPK()
					else { B_REST_Utils.console_warn(`fromObj() caught a case where server ret a sub model that either exposed no PK fields, or unknown vals`,loop_obj); }
				}
				
				if (!loop_model) { loop_model = this.add(); }
				
				loop_model.fromObj(loop_obj,skipIllegalChanges);
			}
		}
		
		/*
		Each model can either output as an obj of all its used props, or obj like:
			{pk:null, _apiUID_:      123}
			{pk:456,  _apiDirective_:"<remove>"}
			{pk:789,  _apiDirective_:"<delete>"}
		For API directives, we also have the following, but it's annoying to use since they must all have the same tag:
			API_DIRECTIVE_REMOVE_ALL
			API_DIRECTIVE_DELETE_ALL
		If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
		*/
		toObj(onlyWithUnsavedChanges, forAPICall)
		{
			if (onlyWithUnsavedChanges===undefined||forAPICall===undefined) { this._throwEx(`Must specify onlyWithUnsavedChanges & forAPICall`); }
			
			const arr = [];
			
			for (const loop_model of this._models)
			{
				if (onlyWithUnsavedChanges && !loop_model.unsavedChanges_has) { continue; }
				
				const loop_objData = loop_model.toObj(onlyWithUnsavedChanges,forAPICall); //If we only want unsaved changes and we've got nothing, then this rets undefined, not NULL
				
				//If we have api directives / unsaved changes
				if (loop_objData!==undefined)
				{
					//Then add PK + frontendUUID, if possible
					loop_model.toObj_forSubModelList_addFrontendUUIDAndPKParts(loop_objData);
					
					arr.push(loop_objData);
				}
			}
			
			return onlyWithUnsavedChanges && arr.length===0 ? undefined : arr;
		}
	
	
	
	//LOAD & NAVIGATION RELATED
		get useForLoading() { return this._searchOptions!==null; }
		_assert_getSearchOptions()
		{
			if (!this.useForLoading) { this._throwEx(`Can't do that on a model list where we don't want to use loading features (check constructor)`); }
			return this._searchOptions;
		}
		get searchOptions()  { return this._assert_getSearchOptions();                       }
		get requiredFields() { this._assert_getSearchOptions(); return this._requiredFields; }
		get isLoading()      { this._assert_getSearchOptions(); return this._isLoading;      }
		
		get apiBaseUrl()    { this._assert_getSearchOptions(); return this._apiBaseUrl; }
		set apiBaseUrl(val) { this._assert_getSearchOptions(); this._apiBaseUrl = val;  }
		
		get apiBaseUrl_path_vars() { this._assert_getSearchOptions(); return this._apiBaseUrl_path_vars; }
		set apiBaseUrl_path_vars(val)
		{
			if (val!==null) { B_REST_Utils.object_assert(val); }
			this._assert_getSearchOptions();
			this._apiBaseUrl_path_vars = val;
		}
		
		get apiBaseUrl_needsAccessToken()    { this._assert_getSearchOptions(); return this._apiBaseUrl_needsAccessToken; }
		set apiBaseUrl_needsAccessToken(val) { this._assert_getSearchOptions(); this._apiBaseUrl_needsAccessToken = val;  }
		
		/*
		Will destroy old models
		IMPORTANT:
			-If we don't know the nb of pages we have, nav_last() will throw
			-For nav_next(), will throw depending on if we *know* we're getting too far
			-For nav_to(), can specify a page nb that might be out of bounds, only when we don't know the nb of pages we have
			-If we wanted to go to page X and during that time someone del stuff, maybe we'll end up on page X-1 because there are now less items.
			Search options's paging_index will have moved automatically accordingly
			-In order to figure out the nb of pages we have, in the search options:
				-Set paging_calcFoundRowsCount to true
				-After the API call, use paging_lastCall_updateNbRecords() (Done auto in B_REST_Descriptor::load_list())
		Also, checks if we have to advise external code that we've added new models. Especially for B_REST_ModelField_SubModelList
		While loading, sets isLoading to true. Might throw for network or server errs
		WARNING:
			Check warning docs in _load() below about custom apiBaseUrl
		*/
		async nav_first()  { this._assert_getSearchOptions().paging_first();    return this._load(true); }
		async nav_prev()   { this._assert_getSearchOptions().paging_prev();     return this._load(true); }
		async nav_next()   { this._assert_getSearchOptions().paging_next();     return this._load(true); }
		async nav_last()   { this._assert_getSearchOptions().paging_last();     return this._load(true); }
		async nav_to(page) { this._assert_getSearchOptions().paging_index=page; return this._load(true); }
		/*
		Reloads, in case data changed on the server. If page count is known and we'd now be out of range, it'll adjust paging to the last page
		WARNING:
			Check warning docs in _load() below about custom apiBaseUrl
		*/
		async reload() { this._assert_getSearchOptions(); return this._load(true); }
		/*
		There are cases where we want to load just a few at a time and we don't know the total nb of records we have.
			Just set the paging_size and keep on moving forward, without deleting the records we already got
			When we do a final call where we don't get no more records, we'll indicate that we now know the nb of records there is,
			so we won't be able to move forward anymore because search options's paging_isLastPage() will become true
		Also, checks if we have to advise external code that we've added new models. Especially for B_REST_ModelField_SubModelList
		While loading, sets isLoading to true. Might throw for network or server errs
		WARNING:
			Check warning docs in _load() below about custom apiBaseUrl
		*/
		async loadMore()
		{
			this._assert_getSearchOptions();
			
			//Check if nothing will happen
			const modelCountBefore = this._models.length;
			
			this._searchOptions.paging_next(); //Can throw if paging count is known and we go too far
			await this._load(/*destroyOldModels*/false);
			
			const modelCountAfter = this._models.length;
			
			//Check if we've reached the end
			if (modelCountBefore===modelCountAfter)
			{
				/*
				Indicate that we now know the nb of filtered records, without changing the "all" counterpart
				This will also flip search options's paging_isLastPage to true
				*/
				this._searchOptions.paging_lastCall_updateNbRecords(modelCountAfter, this._searchOptions.lastCall_nbRecords_all);
				
				/*
				NOTE: We could change paging_size to now be NULL and go back to page 0, but don't do that:
					if ever we change filters and want to start back from page 0 and do loadMore,
					it'd then load all in one shot instead of maybe 10 at a time
				*/
			}
		}
		
			/*
			Against current paging, filters etc
			Descriptor will take care of updating search options's nb of records, whether to say we now know or no longer
			Also, checks if we have to advise external code that we've added new models. Especially for B_REST_ModelField_SubModelList
			While loading, sets isLoading to true. Might throw for network or server errs
			WARNING:
				If we were on a page diff from the 1st one and we then changed filters, we'll force the user back to page zero to prevent:
					-When we have less records: to be redirected backwards to the last page, that is now less than where we were
					-When we have more records: to remain on the same page idx as before, but maybe listing diff records
				If we override the descriptor's default apiBaseUrl_list, we can't guarantee that:
					-Applied filters will make sense
					-Ret structure will fit with described fields and sub models
					-> Impacts in B_REST_Descriptor::load_list(), B_REST_Model::save() & B_REST_ModelList::_load()
				-> We could also do the API calls manually (in case ret struct doesn't match or we need to nest):
					-Just destroy / add items back in modelList by ourselves
					-Check to call paging_lastCall_updateNbRecords()
			*/
			async _load(destroyOldModels)
			{
				if (this._isLoading) { this._throwEx(`Already loading`); }
				this._isLoading = true;
				
				try
				{
					//No matter on which page we wanted to go, if we changed filters, we should go to page 0 instead
					if (this._searchOptions.filters_hasChanges)
					{
						this._searchOptions.paging_first();
						this._searchOptions.filters_unflagChanges();
					}
					
					//Note: we also have uploadProgressCallback & downloadProgressCallback props, that we could add as props in the B_REST_ModelList (not in this method as options)
					const ret = await this._descriptor.load_list({
						useCachedShare:              this.useCachedShare,
						searchOptions:               this._searchOptions,
						requiredFields:              this._requiredFields,
						apiBaseUrl:                  this._apiBaseUrl,                  //Leaving NULL = use descriptor's defaults
						apiBaseUrl_path_vars:        this._apiBaseUrl_path_vars,        //Leaving NULL = use descriptor's defaults
						apiBaseUrl_needsAccessToken: this._apiBaseUrl_needsAccessToken, //Leaving NULL = use descriptor's defaults
						afterLoad:                   this._hook_afterLoad,              //Leaving NULL = use descriptor's defaults
					});
					
					if (destroyOldModels) { this.destroy_all(); }
					
					//Check if we have to advise external code that we've added new models. Especially for B_REST_ModelField_SubModelList
					if (this._hook_onAdd)
					{
						try
						{
							this._hook_onAdd(this, ret.models);
						}
						catch (e) { B_REST_Utils.console_error(`onAdd hook failed, for ${this.debugName}: ${e}`); } //WARNING: Could cause prob to switch to throwEx() - check code below
					}
					
					//No need to make sure they have the same descriptor
					this._models.push(...ret.models);
					
					this._isLoading = false;
				}
				catch (e)
				{
					this._isLoading = false;
					throw e;
				}
			}
};
