
import B_REST_Utils                      from "../B_REST_Utils.js";
import B_REST_Model                      from "../models/B_REST_Model.js";
import B_REST_App_base                   from "../app/B_REST_App_base.js";
import B_REST_ModelFileField_ControlItem from "./B_REST_ModelFileField_ControlItem.js";
import B_REST_DOMFilePtr                 from "./B_REST_DOMFilePtr.js";
import B_REST_Response                   from "../api/B_REST_Response.js";
import { B_REST_Request_base, B_REST_Request_GET, B_REST_Request_GET_File, B_REST_Request_PATCH } from "../api/B_REST_Request.js";



export default class B_REST_ModelFileField_Control
{
	//For items_put_validate()
	static get VALIDATION_RESULT_OK()            { return true;          }
	static get VALIDATION_RESULT_READONLY()      { return "ro";          }
	static get VALIDATION_RESULT_COUNT()         { return "count";       }
	static get VALIDATION_RESULT_SIZE_PER_FILE() { return "sizePerFile"; }
	static get VALIDATION_RESULT_SIZE_TOTAL()    { return "sizeTotal";   }
	static get VALIDATION_RESULT_DANGEROUS()     { return "dangerous";   }
	static get VALIDATION_RESULT_MIME()          { return "mime";        }
	
	static get API_DIRECTIVES_DELETE_SINGLE()   { return "<delete>";    }
	static get API_DIRECTIVES_DELETE_MULTIPLE() { return "<deleteAll>"; }
	
	static get FILE_SIZE_KB() { return B_REST_Utils.FILE_SIZE_KB; }
	static get FILE_SIZE_MB() { return B_REST_Utils.FILE_SIZE_MB; }
	
	
	//Main config
		_isMultiple           = null;  //If we can only hold 0-1 file, or if it can be 0-N
		_acceptMimePattern    = null;  //One of B_REST_Utils.FILES_MIME_PATTERNS_x, custom string, or null for all
		_maxSize              = null;  //In bytes, or NULL. Sum of all files sizes. Can use FILE_SIZE_x helpers
		_maxSize_perFile      = null;  //In bytes, or NULL. For each file. Can use FILE_SIZE_x helpers
		_maxFileCount         = null;  //Must be 1 for single, and null-N for multiple
		_pendingUploadsType   = null;  //Required tag that must match something in server's bREST_Custom::_abstract_pendingUploads_getSpecs_byType(), ex "client-docs"
		_required             = false; //If we must at least have 1 file
		_isPublic             = false; //If anyone can access the files, or if we need an access token
		_verboseCallback      = null;  //Optional func to call for logging purposes, as (msg, isError, details=null)
		_dieOnFailedTransfers = false; //When we're waiting for uploads, at the end, do we want to break if any of the ongoing or previously finished uploads failed (STATUS_NEW_PREPARING_FAILED) ?
		_waitLateItems        = false; //If we're waiting in an async task and we're dropping multiple files one after the other, do we want to wait for all to be done before proceeding, or ignore late adds ?
		_isReadOnly           = false; //Can change
	//Things for when the control is for / becomes for a model that exists in DB (with a PK), so we can add & del files and call the model's PATCH url without having to re-save all
		_model_fieldNamePath   = null; //Ex "cv", "docs" or "subThing.logo", for a model like {firstName, lastName, cv:{...}, docs:[{...}], subThing:{logo:{...}}}. Check server side Model_base::_field_parseFieldNamePath() for dot notation; doesn't work with arr[x] notation though
		_savedModel_path_raw   = null; //Ex "/clients/{client}"
		_savedModel_path_vars  = null; //Ex {client:123}
		_savedModel_zip_apiUrl = null; //Ex "/brands/2/docs?h=89cdb0caf3b4c0f51b1330ddd0c4961e", for isMultiple file fields
	//Data / logic
		_items                   = [];    //Arr of B_REST_ModelFileField_ControlItem instances
		_currentAsyncTaskBatchId = 0;     //Incremental batch id to tell which items currently are "reserved" in an async task, so we don't have 2 calls trying to wait for the same item and save its changes
		_savedModel_loadedOnce   = false; //If we're using the control for a model that already exists, have we called savedModel_load() at least once so far ?
		parallelAsyncTasksCount  = 0;     //Nb of ongoing tasks. Used in B_REST_ModelFileField_ControlItem too
	
	

	constructor(options={})
	{
		if (!B_REST_Utils.object_hasPropName(options,"pendingUploadsType")) { B_REST_Utils.throwEx(`pendingUploadsType is required`); }
		this._pendingUploadsType = options.pendingUploadsType;
		
		if (!B_REST_Utils.object_hasPropName(options,"isMultiple")) { B_REST_Utils.throwEx(`isMultiple is required`); }
		this._isMultiple = options.isMultiple;
		
		if (!this._isMultiple)
		{
			options.maxFileCount = 1;
			
			//If we specify maxSize, make sure both total + perFile are the same
			if      (options.maxSize)         { options.maxSize_perFile=options.maxSize; }
			else if (options.maxSize_perFile) { options.maxSize=options.maxSize_perFile; }
		}
		
		if (B_REST_Utils.object_hasPropName(options,"acceptMimePattern"))    { this._acceptMimePattern    = options.acceptMimePattern;    }
		if (B_REST_Utils.object_hasPropName(options,"maxSize"))              { this._maxSize              = options.maxSize;              }
		if (B_REST_Utils.object_hasPropName(options,"maxSize_perFile"))      { this._maxSize_perFile      = options.maxSize_perFile;      }
		if (B_REST_Utils.object_hasPropName(options,"maxFileCount"))         { this._maxFileCount         = options.maxFileCount;         }
		if (B_REST_Utils.object_hasPropName(options,"required"))             { this._required             = options.required;             }
		if (B_REST_Utils.object_hasPropName(options,"isPublic"))             { this._isPublic             = options.isPublic;             }
		if (B_REST_Utils.object_hasPropName(options,"verboseCallback"))      { this._verboseCallback      = options.verboseCallback;      }
		if (B_REST_Utils.object_hasPropName(options,"dieOnFailedTransfers")) { this._dieOnFailedTransfers = options.dieOnFailedTransfers; }
		if (B_REST_Utils.object_hasPropName(options,"waitLateItems"))        { this._waitLateItems        = options.waitLateItems;        }
		if (B_REST_Utils.object_hasPropName(options,"isReadOnly"))           { this._isReadOnly           = options.isReadOnly;           }
		if (B_REST_Utils.object_hasPropName(options,"model_fieldNamePath"))  { this._model_fieldNamePath  = options.model_fieldNamePath;  }
		if (B_REST_Utils.object_hasPropName(options,"savedModel_path_raw"))  { this._savedModel_path_raw  = options.savedModel_path_raw;  }
		if (B_REST_Utils.object_hasPropName(options,"savedModel_path_vars")) { this._savedModel_path_vars = options.savedModel_path_vars; }
	};
		static async factory_fromUploadsType(uploadsType, extraOptions={})
		{
			const baseOptions = await B_REST_ModelFileField_Control.uploadTypes_getOptions(uploadsType);
			
			const finalOptions = {};
			Object.assign(finalOptions, baseOptions, extraOptions);
			
			return new B_REST_ModelFileField_Control(finalOptions);
		}
	//Not an actual destructor; call manually
	destroy()
	{
		this.items_destroy();
		
		this._verboseCallback = null;
	}
	
	
	
	//Getters
		get isMultiple()                    { return this._isMultiple;                                                                                             }
		get acceptMimePattern()             { return this._acceptMimePattern;                                                                                      }
		get maxSize()                       { return this._maxSize;                                                                                                }
		get maxSize_humanReadable()         { return this._maxSize!==null ? B_REST_Utils.files_humanReadableSize(this._maxSize) : null;                            }
		get maxSize_perFile()               { return this._maxSize_perFile;                                                                                        }
		get maxSize_perFile_humanReadable() { return this._maxSize_perFile!==null ? B_REST_Utils.files_humanReadableSize(this._maxSize_perFile) : null;            }
		get maxFileCount()                  { return this._maxFileCount;                                                                                           }
		get pendingUploadsType()            { return this._pendingUploadsType;                                                                                     }
		get required()                      { return this._required;                                                                                               }
		get isPublic()                      { return this._isPublic;                                                                                               }
		get verboseCallback()               { return this._verboseCallback;                                                                                        }
		get dieOnFailedTransfers()          { return this._dieOnFailedTransfers;                                                                                   }
		get waitLateItems()                 { return this._waitLateItems;                                                                                          }
		get isReadOnly()                    { return this._isReadOnly;                                                                                             }
		get model_fieldNamePath()           { return this._model_fieldNamePath;                                                                                    }
		get savedModel_path_raw()           { return this._savedModel_path_raw;                                                                                    }
		get savedModel_path_vars()          { return this._savedModel_path_vars;                                                                                   }
		get savedModel_isDefined()          { return this._savedModel_path_raw && this._model_fieldNamePath;                                                       }
		get savedModel_loadedOnce()         { return this._savedModel_loadedOnce;                                                                                  }
		get savedModel_zip_apiUrl()         { return this._savedModel_zip_apiUrl;                                                                                  }
		get savedModel_zip_can()            { return this._isMultiple && this._savedModel_zip_apiUrl && !this.hasOngoingAsyncTasks;                                }
		get items()                         { return this._items;                                                                                                  }
		get items_count()                   { return this._items.length;                                                                                           } //Includes items to delete
		get items_has()                     { return this._items.length>0;                                                                                         } //Includes items to delete
		get items_nonDeleted_has()          { return !!this._items.find(loop_item => !loop_item.ifStored_toDelete);                                                }
		get items_size()                    { return this._items.reduce((acc,loop_item)=>acc+loop_item.fileInfo.size,0);                                           } //Note that some might yield a size of NULL
		get items_size_humanReadable()      { return B_REST_Utils.files_humanReadableSize(this.items_size);                                                        }
		get items_canPutMore()              { return !this._isReadOnly && (!this._isMultiple || this._maxFileCount===null || this.items_count<this._maxFileCount); } //NOTE: Doesn't take maxSize into account
		get items_hasUnsavedChanges()       { return !!this._items.find(loop_item => !loop_item.status_isStored || loop_item.ifStored_toDelete);                   } //Includes ones with errs too
		get items_ongoingUploads()          { return this._items.filter(loop_item => loop_item.status_isNewPreparing);                                             }
		get items_ongoingUploads_has()      { return this.items_ongoingUploads.length>0;                                                                           }
		get items_failedUploads()           { return this._items.filter(loop_item => loop_item.status_isNewPreparingFailed);                                       }
		get items_failedUploads_has()       { return this.items_failedUploads.length>0;                                                                            }
		get hasOngoingAsyncTasks()          { return this.parallelAsyncTasksCount>0 || this.items_ongoingUploads_has;                                              }
	//Things we can edit
		set isReadOnly(val)            { this._isReadOnly           =val; }
		set savedModel_path_raw(val)   { this._savedModel_path_raw  =val; }
		set savedModel_path_vars(val)  { this._savedModel_path_vars =val; }
		set savedModel_zip_apiUrl(val) { this._savedModel_zip_apiUrl=val; }
	
	
	
	verboseLog(methodName, msg, isError, details=null)
	{
		msg = `B_REST_ModelFileField_Control::${methodName}(): ${msg}`;
		
		if (this._verboseCallback)
		{
			try
			{
				this._verboseCallback(msg,isError,details);
			}
			catch (e)
			{
				B_REST_Utils.console_error(`User defined handler for verboseCallback threw an exception:`, e);
			}
		}
	}
	
	
	//Removes all items from the control, also releasing objectURL buffers, if any
	items_destroy()
	{
		//Del all imgs tmp ObjectURL and stuff, if any
		for (const loop_item of this._items) { loop_item.ifNewPreparing_releaseMemory(); }
		
		this._items = [];
	}
		//Simply removes the item from the arr, not calling any API nor flagging as ifStored_toDelete=true
		items_destroy_one(item)
		{
			this.verboseLog("items_destroy_one", `Removing and releasing mem for item "${item.fileInfo.baseNameWExt}"`, false);
			
			B_REST_Utils.array_remove_byVal(this._items, item);
			
			item.ifNewPreparing_releaseMemory();
		}
	
	items_get_byIdx(idx)
	{
		if (idx >= this.items_count) { B_REST_Utils.throwEx(`Idx ${idx} out of bounds (${this.items_count})`); }
		return this._items[idx];
	}
	items_get_byFrontendUUID(frontendUUID)
	{
		const item = this._items.find(loop_item => loop_item.frontendUUID===frontendUUID);
		if (!item) { B_REST_Utils.throwEx(`UUID ${frontendUUID} not found`); }
		
		return item;
	}
	//Check B_REST_API::call_download() docs for params & what it returns
	async items_download_allZipped(baseNameWExt=null, domContainer=null)
	{
		this.verboseLog("items_download_allZipped", `Trying to download dir "${this._savedModel_zip_apiUrl}"`, false);
		
		if (!this.savedModel_zip_can) { B_REST_Utils.throwEx(`savedModel_zip_apiUrl must be defined in multiple mode, and we must have no ongoing tasks`); }
		
		try
		{
			this.parallelAsyncTasksCount++;
			
			const request = new B_REST_Request_GET_File(this._savedModel_zip_apiUrl);
			request.needsAccessToken = !this._isPublic; //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
			const { baseNameWExt:final_baseNameWExt, response } = await B_REST_App_base.instance.call_download(request, baseNameWExt, domContainer);
			
			this.parallelAsyncTasksCount--;
			
			return { baseNameWExt:final_baseNameWExt, response };
		}
		catch (e)
		{
			this.parallelAsyncTasksCount--;
			throw e;
		}
	}
	
	
	
	/*
	Against options, checks if it'd be ok to set / add the specified B_REST_DOMFilePtr instance
	Rets one of VALIDATION_RESULT_x, or throws if we don't receive an instance of B_REST_DOMFilePtr
	*/
	items_validateDOMFilePtr(domFilePtr)
	{
		return this._items_validateDOMFilePtrArr([domFilePtr])[0];
	}
		//NOTE: Throws if we don't receive instances of B_REST_DOMFilePtr
		_items_validateDOMFilePtrArr(domFilePtrArr)
		{
			//First validate that all is of the right type, and that we've got something
			B_REST_Utils.array_isOfClassInstances_assert(B_REST_DOMFilePtr, domFilePtrArr);
			if (domFilePtrArr.length===0) { B_REST_Utils.throwEx(`Got an empty B_REST_DOMFilePtr instances arr`); }
			
			let currentCount = this.items_count;
			let currentSize  = this.items_size;
			
			return domFilePtrArr.map(loop_domFilePtr =>
			{
				if (this._isReadOnly) { return B_REST_ModelFileField_Control.VALIDATION_RESULT_READONLY; }
				
				//For counts, always allow when we're in single mode, as we must be able to replace the previous one, without having to bother removing it first
				if (this._isMultiple && this._maxFileCount && currentCount>=this._maxFileCount) { return B_REST_ModelFileField_Control.VALIDATION_RESULT_COUNT; }
				
				//NOTE: Do this to avoid calculating file's size for no reason (mem intensive for non blobs)
				let loop_fileSize = 0;
				if (this._maxSize_perFile || this._maxSize)
				{
					loop_fileSize = loop_domFilePtr.size;
					
					if (this._maxSize_perFile && loop_fileSize             > this._maxSize_perFile) { return B_REST_ModelFileField_Control.VALIDATION_RESULT_SIZE_PER_FILE; }
					if (this._maxSize         && currentSize+loop_fileSize > this._maxSize)         { return B_REST_ModelFileField_Control.VALIDATION_RESULT_SIZE_TOTAL;    }
				}
				
				//Mime (or ext) validation
				{
					let loop_mimeOrExt = loop_domFilePtr.mime_from_bestGuess; //Can ret NULL
					if (!loop_mimeOrExt) { loop_mimeOrExt=loop_domFilePtr.ext; }
					
					if (loop_mimeOrExt && B_REST_Utils.files_mime_isDangerous(loop_mimeOrExt)) { return B_REST_ModelFileField_Control.VALIDATION_RESULT_DANGEROUS; } //Would throw if mime is empty
					
					if (this._acceptMimePattern)
					{
						if (!loop_mimeOrExt || !B_REST_Utils.files_mime_matchesPattern(loop_mimeOrExt,this._acceptMimePattern)) { return B_REST_ModelFileField_Control.VALIDATION_RESULT_MIME; } //Would throw if mime is empty
					}
				}
				
				//If it's ok to add it, then "pretend" we're adding it, to correctly validate the other files
				currentCount++;
				currentSize += loop_fileSize; //NOTE: This will +=0 if we didn't care about size restrictions
				
				return B_REST_ModelFileField_Control.VALIDATION_RESULT_OK;
			});
		}
	
	/*
	Intended to take a B_REST_DOMFilePtr (ex File input, drag n drop...) and send it to the pendingAPIUploads dir to get a hash, before actually using (saving) it in a future API call
	Validates first if it's ok to set/add, yielding a const of VALIDATION_RESULT_x, then upload if it's ok to do so
	Rets as {validationResult, uploadPromise=null}. Upload promise is NULL if no upload will be performed
	Throws if we don't receive a B_REST_DOMFilePtr instance
	*/
	items_prepare(domFilePtr)
	{
		const validationResult = this._items_validateDOMFilePtrArr([domFilePtr])[0]; //Throws if it wasn't a B_REST_DOMFilePtr instance
		this.verboseLog("items_prepare", `Got validation result for "${domFilePtr.baseNameWExt}": ${validationResult}`, false);
		if (validationResult!==B_REST_ModelFileField_Control.VALIDATION_RESULT_OK) { return {validationResult,uploadPromise:null}; }
		
		//In single mode, first start by removing any previous item, if any. Will work, even if the previous one was being referred in an ongoing async task
		if (!this._isMultiple && this.items_has)
		{
			this.verboseLog("items_prepare", `Releasing previous file's memory`, false);
			this.items_destroy();
		}
		
		const currentItems  = this._items_prepare_factorizeDOMFilePtrList([domFilePtr]); //Rets an arr of 1 instance
		const uploadPromise = this._items_prepare_reStartTransfer(currentItems);
		
		return {validationResult, uploadPromise};
	}
	/*
	Starts uploading all specified B_REST_DOMFilePtr instances in a single API call (not in parallel)
	We can choose to not send anything at all if any file doesn't pass validation specs, with ignoreInvalids=false
	Rets as {validationResultArr, uploadPromise:null}. Upload promise is NULL if no upload will be performed
	Throws if we don't receive an arr of B_REST_DOMFilePtr instances, or an empty one
	*/
	items_prepare_grouped(domFilePtrArr, ignoreInvalids)
	{
		const validationResultArr = this._items_validateDOMFilePtrArr(domFilePtrArr); //Throws if it wasn't an arr of B_REST_DOMFilePtr instances / empty
		const domFilePtrArr_valid = validationResultArr.filter(loop_validationResult => loop_validationResult===B_REST_ModelFileField_Control.VALIDATION_RESULT_OK);
		this.verboseLog("items_prepare_grouped", `Got validation results for multiple files: ${validationResultArr.join("|")}`, false);
		
		//If we've got nothing good at all, or if we don't want to keep anything when we've got some errors, just stop
		if (domFilePtrArr_valid.length===0 || (!ignoreInvalids && domFilePtrArr.length!==domFilePtrArr_valid.length))
		{
			this.verboseLog("items_prepare_grouped", `Not uploading anything, because of errors`, true);
			return {validationResultArr, uploadPromise:null};
		}
		
		const currentItems  = this._items_prepare_factorizeDOMFilePtrList(domFilePtrArr_valid);
		const uploadPromise = this._items_prepare_reStartTransfer(currentItems);
		
		return {validationResultArr, uploadPromise};
	}
		//Converts B_REST_DOMFilePtr into B_REST_ModelFileField_ControlItem instances
		_items_prepare_factorizeDOMFilePtrList(domFilePtrArr)
		{
			const currentItems = [];
			
			for (const loop_domFilePtr of domFilePtrArr)
			{
				const loop_item = B_REST_ModelFileField_ControlItem.factory_fromDOMFilePtr(loop_domFilePtr);
				loop_item._control = this;
				loop_item.setStatus_preparing(null);
				
				currentItems.push(loop_item);
				this._items.push(loop_item);
			}
			
			return currentItems;
		}
		//Rets an upload promise. If we received multiple items, we'll group them together in a single call
		async _items_prepare_reStartTransfer(currentItems)
		{
			if (currentItems.length===0) { B_REST_Utils.throwEx(`Got no items to (re)transfer`); } //Should never happen though
			
			//Do a hack to be able to make refs to uploadPromise, after the promise's definition
			let uploadPromise_s = null;
			let uploadPromise_f = null;
			const uploadPromise = new Promise((s,f) =>
			{
				uploadPromise_s = s;
				uploadPromise_f = f;
			});
			for (const loop_item of currentItems) { loop_item.setStatus_preparing(uploadPromise); }
			
			this.verboseLog("_items_prepare_reStartTransfer", `Starting transfer`, false);
			
			try
			{
				//Setup a callback that *might* get fired multiple times while uploading
				const uploadProgressCallback = (totalBytes,transferredBytes,percent) =>
				{
					this.verboseLog("uploadProgressCallback", `Updating progression: ${totalBytes} / ${transferredBytes} = ${percent}`, false);
					for (const loop_item of currentItems) { loop_item.ifNewPreparing_progression=percent; }
				};
				
				let hashList = null; //Arr of stuff like "f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf"
				if (currentItems.length===1)
				{
					const response = await B_REST_App_base.instance.pendingUploads_single(this._pendingUploadsType, currentItems[0].ifNewX_domFilePtr, uploadProgressCallback);
					hashList       = response.data.file ? [response.data.file] : [];
				}
				else
				{
					const domFilePtrList = currentItems.map(loop_item => loop_item.ifNewX_domFilePtr);
					const response       = await B_REST_App_base.instance.pendingUploads_multiple(this._pendingUploadsType, domFilePtrList, uploadProgressCallback);
					hashList             = response.data.files ? response.data.files : [];
				}
				this.verboseLog("_items_prepare_reStartTransfer", `Got hashList`, false, hashList);
				
				//Make sure we get the right nb of hashes
				if (currentItems.length!==hashList.length) { B_REST_Utils.throwEx(`Expected call to return ${currentItems.length} hashes; got ${hashList.length}`); }
				
				//Assign pending upload hashes
				currentItems.forEach((loop_item,loop_idx) => loop_item.setStatus_prepared(hashList[loop_idx]));
				
				uploadPromise_s();
			}
			catch (e) //Will contain either an Error or B_REST_Response instance
			{
				this.verboseLog("_items_prepare_reStartTransfer", `Caught exception in transfer`, true, e);
				
				for (const loop_item of currentItems) { loop_item.setStatus_preparingFailed(); }
				
				uploadPromise_f();
			}
			
			return uploadPromise;
		}
	
	//Rets an upload promise, like in items_prepare_x()
	async items_failedUploads_retry(item)
	{
		if (!item.status_isNewPreparingFailed) { B_REST_Utils.throwEx(`Item must be in STATUS_NEW_PREPARING_FAILED`); }
		
		return this._items_prepare_reStartTransfer([item]);
	}
	
	/*
	Resolves when all STATUS_NEW_PREPARING flipped to either:
		STATUS_NEW_PREPARING_FAILED
		STATUS_NEW_PREPARED
	Depending on waitLateItems, will either do only 1 pass or do it again over and over until we've got nothing more
	Depending on dieOnFailedTransfers, will break if any item (even for previous transfers) remains in STATUS_NEW_PREPARING_FAILED
	*/
	async items_waitOngoingUploads()
	{
		let waitedItemsCount = null;
		
		this.verboseLog("items_waitOngoingUploads", `items_waitOngoingUploads - Starting`, false);
		
		do
		{
			waitedItemsCount = await this._items_waitOngoingUploads(this._items);
			this.verboseLog("items_waitOngoingUploads", `items_waitOngoingUploads - Waited for ${waitedItemsCount} items`, false);
		}
		while (waitedItemsCount>0);
	}
		//Rets how many items we had to wait for, or throws
		async _items_waitOngoingUploads(whichItems)
		{
			const promisesToWaitList = [];
			for (const loop_item of whichItems)
			{
				if (loop_item.status_isNewPreparing) { promisesToWaitList.push(loop_item.ifNewPreparing_promise); }
			}
			
			if (promisesToWaitList.length>0)
			{
				this.verboseLog("_items_waitOngoingUploads", `Must wait for promises`, false);
				await Promise.allSettled(promisesToWaitList);
			} else { this.verboseLog("_items_waitOngoingUploads", `No promise to wait for`,false); }
			
			//If we want to die when any item remains in a failed state (not just the ones in whichItems)
			if (this._dieOnFailedTransfers && this._items.find(loop_item => loop_item.status_isNewPreparingFailed))
			{
				B_REST_Utils.throwEx(`Got a failed transfer and we want to die on failed transfers`);
			}
			
			return promisesToWaitList.length;
		}
	
	/*
	API calls expect to receive a model like such, so send adds & dels directives in diff ways, depending on if it's a single / multiple files field
		{
			"logo": "<delete>",
			"logo": {pendingAPIUpload_baseNameWExt_hashed:"f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf", _apiUID_:123},
			"pics": "<deleteAll>",
			"pics": {
				"add: [
					{pendingAPIUpload_baseNameWExt_hashed:"f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf",     _apiUID_:123},
					{pendingAPIUpload_baseNameWExt_hashed:"9a8sd0g8as09d9g80as8d09g80d8a0gds980ags9-bob-new.pdf", _apiUID_:456}
				],
				"delete": ["bob.pdf", "bob_1.docx"]
			}
		}
	This return such, based on which items we want to include (if not all)
	Among the items we specify (or all), we care about those in status:
		STATUS_NEW_PREPARED               -> Adds
		STATUS_STORED + ifStored_toDelete -> Dels
	All others will be ignored; we don't wait for uploads, and don't care about their asyncTaskBatchId flag
	NOTE: "<deleteAll>" can only happen here if we specify ALL items in multiple mode
	*/
	items_to_apiFieldData(limitToItems=null) //Optional arr of items to limit to
	{
		if (limitToItems===null) { limitToItems=this._items; }
		const forAllItems = limitToItems.length===this.items_count;
		
		if (this._isMultiple)
		{
			const adds        = [];
			const dels        = [];
			let   storedCount = 0; //Among the specified items to limit to
			
			for (const loop_item of limitToItems)
			{
				switch (loop_item.status)
				{
					case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING: case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING_FAILED:
						//Skip cases
					break;
					case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARED:
						adds.push(loop_item.ifNewPrepared_toApiFileObj());
					break;
					case B_REST_ModelFileField_ControlItem.STATUS_STORED:
						storedCount++;
						if (loop_item.ifStored_toDelete) { dels.push(loop_item.fileInfo.baseNameWExt); }
					break;
					default:
						B_REST_Utils.throwEx(`Got unexpected status "${loop_item.status}"`);
					break;
				}
			}
			
			const isDeletingAllItems = dels.length>0 && dels.length===this.items_count;
			
			if (adds.length===0)
			{
				//If we've got nothing to do
				if (dels.length===0) { return null; }
				
				//If we want to del all afterall, just use the easy directive
				if (isDeletingAllItems) { return B_REST_ModelFileField_Control.API_DIRECTIVES_DELETE_MULTIPLE; }
			}
			
			//Else ret some adds and some dels
			return {add:adds, delete:dels};
		}
		else
		{
			if (limitToItems.length>1) { B_REST_Utils.throwEx(`Not supposed to have more than 1 item in single mode`); }
			
			//If we've got no items at all, it means we never added anything in the past either. Otherwise, wanting to remove would become ifStored_toDelete=true
			if (limitToItems.length===0) { return null; }
			
			const item = limitToItems[0];
			
			switch (item.status)
			{
				case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING: case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING_FAILED:
					//Skip cases
				break;
				case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARED:
					return item.ifNewPrepared_toApiFileObj();
				break;
				case B_REST_ModelFileField_ControlItem.STATUS_STORED:
					if (item.ifStored_toDelete) { return B_REST_ModelFileField_Control.API_DIRECTIVES_DELETE_SINGLE; }
				break;
				default:
					B_REST_Utils.throwEx(`Got unexpected status "${item.status}"`);
				break;
			}
			
			//If nothing changed, or we got an upload that's still pending/failed at this time
			return null;
		}
	}
	/*
	Call this when loading an existing instance, and each time we save
	API calls can return models like such, so expect to receive either a {...} or [{...}], depending on if it's a single / multiple files field
		{
			"logo": {baseNameWExt:"logo.bmp", baseNameWOExt:"logo", ext:"bmp", size:<bytes>,mime:"image/bmp",width:null,height:null, apiUrl:"/contact/123/logo?h=asdg7g9", resizedVersion:{size,width,height,"/contact/123/logo?h=asdg7g9&small=1"}, _apiUID_:123},
			"docs": {
				zip_apiUrl: null | "/brands/2/docs?h=89cdb0caf3b4c0f51b1330ddd0c4961e", //NOTE: Null if list is empty
				list: [
					{baseNameWExt:"stuff.pdf", baseNameWOExt:"stuff", ext:"pdf", size:<bytes>,mime:"application/pdf",width:null,height:null, apiUrl:"/contact/123/patentes/stuff.pdf?h=asdg7g9", resizedVersion:null, _apiUID_:123},
					{baseNameWExt:"logo.bmp", baseNameWOExt:"logo", ext:"bmp", size:<bytes>,mime:"image/bmp",width:null,height:null, apiUrl:"/contact/123/patentes/logo.png?h=879sdf879", resizedVersion:{size,width,height,"/contact/123/patentes/logo.png?h=879sdf879&small=1"}, _apiUID_:456},
				}
			}
		}
	Checks if some items don't exist in the item list yet and adds them
	For STATUS_NEW_PREPARED, compares them by frontendUUID to assign final fileInfo and flip it to STATUS_STORED
	Doesn't cleanup items in the following status. Consider using items_destroy_one() or items_destroy()
		STATUS_NEW_PREPARING
		STATUS_NEW_PREPARING_FAILED
		STATUS_STORED + ifStored_toDelete
	In multiple mode, also sets dir zip apiUrl, if available
	Check savedModel_load() for an example of how to do from another API GET call
	*/
	items_from_apiFieldData(apiFieldData)
	{
		B_REST_Utils.object_assert(apiFieldData);
		
		if (this._isMultiple)
		{
			if (!B_REST_Utils.object_hasPropName(apiFieldData,"list")) { B_REST_Utils.throwEx(`Expected to receive a list:[]`); }
			
			for (const loop_apiFieldData of apiFieldData.list) { this._items_from_apiFieldData_from_parse(loop_apiFieldData); }
			
			//Check if we can get a zip url
			if (B_REST_Utils.object_hasPropName(apiFieldData,"zip_apiUrl")) { this._savedModel_zip_apiUrl = apiFieldData.zip_apiUrl; }
		}
		else
		{
			if (!B_REST_Utils.object_hasPropName(apiFieldData,"baseNameWExt")) { B_REST_Utils.throwEx(`Expected to receive an obj`); }
			
			this._items_from_apiFieldData_from_parse(apiFieldData);
		}
	}
		_items_from_apiFieldData_from_parse(apiFieldData)
		{
			let item = null;
			
			//Maybe it's an add we've just done and we got it back
			const frontendUUID = apiFieldData[B_REST_Model.API_UID_FIELDNAME];
			if (frontendUUID)
			{
				item = this.items_get_byFrontendUUID(frontendUUID);
				if (!item) { B_REST_Utils.throwEx(`Couldn't find back item with frontendUUID "${frontendUUID}"`); }
				
				item.setStatus_stored(apiFieldData);
			}
			//Else it's a file we already knew to be on the server, or that it was added from somewhere else and we don't know about it
			else
			{
				//First check if we already have it
				item = this._items.find(loop_item => loop_item.status_isStored && loop_item.fileInfo.baseNameWExt===apiFieldData.baseNameWExt);
				if (!item)
				{
					item = B_REST_ModelFileField_ControlItem.factory_fromAPIFieldFileData(apiFieldData);
					item._control = this;
					this._items.push(item);
				}
			}
		}
	/*
	Like items_from_apiFieldData, but receiving an instance of B_REST_Response and walking through it to find the apiFieldData.
	Rets whether the field was found in the struct or not.
	Can throw
	*/
	items_from_apiResponse(response)
	{
		const apiFieldData = this._apiFieldData_from_response(response);
		if (apiFieldData)
		{
			this.items_from_apiFieldData(apiFieldData); //Could throw
			return false;
		}
		
		return false;
	}
		_apiFieldData_from_response(response)
		{
			if (!this._model_fieldNamePath) { B_REST_Utils.throwEx(`model_fieldNamePath must be defined to do that`); }
			B_REST_Utils.instance_isOfClass_assert(B_REST_Response, response);
			
			return response.data_getFieldData(this._model_fieldNamePath); //Might be NULL (ex if single and empty)
		}
	
	/*
	If we have any changes, we'll create a postData.<fieldNamePath> with appropriate data, and add it to the passed postData obj (B_REST_Request)
	The goal is then to call the API (somewhere else) and then refer back to this method to indicate that the API call is over (whether successful or not) and assign back new data
	Avoid hell when we call this twice in parallel, by having each call create an async batch id and "reserve" items with changes to save (new adds or dels)
	Depending on dieOnFailedTransfers, we either die or ignore STATUS_NEW_PREPARING_FAILED items.
		We won't try to re-send them, so to do so manually, first use items_failedUploads_x getters and call items_failedUploads_retry() (no need to await)
	Also waits for late items, if we have to (waitLateItems)
	Rets NULL if we've got no changes to save, otherwise a callback to trigger after a successful or failed API call, as (responseOrNULLOnError,isSuccess)
	Usage ex:
		const control = new B_REST_ModelFileField_Control({..., model_fieldNamePath:"cv"});
		...
		const request           = new B_REST_Request_POST("clients/new");
		const hook_afterAPICall = await control.anyModel_waitOrphansWithChangesToSave_preparePostData(request); //May throw
		try
		{
			const response = await bREST.call(request);
			hook_afterAPICall(response, true);
		}
		catch (response)
		{
			hook_afterAPICall(null, false);
		}
	NOTE: savedModel_waitTransfers_saveChanges() is a full implementation of the above WHEN THE INSTANCE EXISTS IN THE DB, for simplicity
	*/
	async anyModel_waitOrphansWithChangesToSave_preparePostData(request)
	{
		B_REST_Utils.instance_isOfClass_assert(B_REST_Request_base, request);
		if (!this._model_fieldNamePath) { B_REST_Utils.throwEx(`Model's fieldNamePath is required to use this`); }
		
		const asyncTaskBatchId = this._asyncTaskBatchId++;
		this.verboseLog("anyModel_waitOrphansWithChangesToSave_preparePostData", `Starting async batch #${asyncTaskBatchId}`, false);
		
		this.parallelAsyncTasksCount++;
		
		try
		{
			const currentItems = await this._anyModel_waitOrphansWithChangesToSave_preparePostData_checkJoinItems(asyncTaskBatchId);
			
			//If we want to wait forever as long as we keep adding new stuff
			if (this._waitLateItems)
			{
				this.verboseLog("anyModel_waitOrphansWithChangesToSave_preparePostData", `Checking if we've got more items to wait for`, false);
				
				let moreItems = await this._anyModel_waitOrphansWithChangesToSave_preparePostData_checkJoinItems(asyncTaskBatchId);
				
				while (moreItems.length>0)
				{
					this.verboseLog("anyModel_waitOrphansWithChangesToSave_preparePostData", `Waited for more items`, false);
					
					//Append them to currentItems
					for (const loop_item of moreItems) { currentItems.push(loop_item); }
					
					//Checking again
					moreItems = await this._anyModel_waitOrphansWithChangesToSave_preparePostData_checkJoinItems(asyncTaskBatchId);
				}
			}
			
			//If we've got nothing to do afterall
			if (currentItems.length===0)
			{
				this.verboseLog("anyModel_waitOrphansWithChangesToSave_preparePostData", `Had nothing to do afterall`, false);
				
				this.parallelAsyncTasksCount--;
				return null;
			}
			
			const apiFieldData = this.items_to_apiFieldData(currentItems);
			this.verboseLog("anyModel_waitOrphansWithChangesToSave_preparePostData", `Made API field data`, false, apiFieldData);
			request.data_set(this._model_fieldNamePath, apiFieldData);
			
			//Check docs. This has to be called by the user after the API call
			const hook_afterAPICall = (responseOrNULLOnError,isSuccess) =>
			{
				this.verboseLog("hook_afterAPICall", `Entered hook_afterAPICall`, isSuccess, responseOrNULLOnError);
				
				if (isSuccess)
				{
					B_REST_Utils.instance_isOfClass_assert(B_REST_Response, responseOrNULLOnError);
					
					const apiFieldData = this._apiFieldData_from_response(responseOrNULLOnError);
					if (apiFieldData) { this.items_from_apiFieldData(apiFieldData); } //NOTE: It's possible we get NULL, if we have no more files
					
					//Check for dels to remove from the arr
					for (const loop_item of currentItems) { if(loop_item.ifStored_toDelete){this.items_destroy_one(loop_item);} }
				}
				else
				{
					for (const loop_item of currentItems) { loop_item.hasWarnings=true; }
				}
				
				//Remove task ids
				for (const loop_item of currentItems) { loop_item.asyncTaskBatchId=null; }
				
				this.verboseLog("hook_afterAPICall", `Left hook_afterAPICall`, false);
			};
			
			this.parallelAsyncTasksCount--;
			return hook_afterAPICall;
		}
		//On error, we must free these items from the asyncTaskBatchId and show that we've got warnings for all of them
		catch (e)
		{
			this.verboseLog("anyModel_waitOrphansWithChangesToSave_preparePostData", `Got error, so reverting`, true, e);
			
			//NOTE: Don't just use "currentItems", as if _anyModel_waitOrphansWithChangesToSave_preparePostData_checkJoinItems() failed, they won't have been added to currentItems
			const itemsToRevert = this._items.filter(loop_item => loop_item.asyncTaskBatchId===asyncTaskBatchId);
			
			for (const loop_item of itemsToRevert)
			{
				loop_item.hasWarnings      = true;
				loop_item.asyncTaskBatchId = null;
			}
			
			this.parallelAsyncTasksCount--;
			
			throw e;
		}
	}
		async _anyModel_waitOrphansWithChangesToSave_preparePostData_checkJoinItems(asyncTaskBatchId)
		{
			//Check if we have any items with things to save, that aren't linked in an API call yet
			const currentItems = this._items.filter(loop_item => loop_item.isOrphanWithChangesToSave);
			
			if (currentItems.length>0)
			{
				//Flag them all as part of this new task
				for (const loop_item of currentItems) { loop_item.asyncTaskBatchId=asyncTaskBatchId; }
				
				await this._items_waitOngoingUploads(currentItems);
			}
			
			return currentItems;
		}
	
	/*
	Intended to use when the model exists on the server and we want to populate with data, without starting from an API call we did earlier
	Resolves or rejects with a B_REST_Response, unless model wasn't even defined first
	*/
	async savedModel_load()
	{
		this._savedModel_assertDefined();
		
		this.verboseLog("savedModel_load", `About to start`, false);
		
		try
		{
			this._savedModel_loadedOnce = true;
			
			this.parallelAsyncTasksCount++;
			
			const request            = new B_REST_Request_GET(this._savedModel_path_raw, this._savedModel_path_vars);
			request.needsAccessToken = !this._isPublic; //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
			request.qsa_add("rf_fields", this._model_fieldNamePath); //Check B_REST_Model_Load_RequiredFields to see what rf_fields is
			
			const response = await B_REST_App_base.instance.call(request); //Can fail for many reasons (token, validation, server...)
			
			this.items_from_apiResponse(response);
			
			this.parallelAsyncTasksCount--;
		}
		catch (response)
		{
			this.verboseLog("savedModel_load", `Got error`, true, response);
			this.parallelAsyncTasksCount--;
			
			throw response;
		}
	}
		_savedModel_assertDefined()
		{
			if (!this.savedModel_isDefined) { B_REST_Utils.throwEx(`Model must exist in DB + file field must be known, to do this`); }
		}
		//Shortcut also setting the path vars at the same time
		async savedModel_load_wPathVars(path_vars={})
		{
			this._savedModel_path_vars = path_vars;
			return this.savedModel_load();
		}
	/*
	Full automation of the usage ex in anyModel_waitOrphansWithChangesToSave_preparePostData() docs
	Resolves to nothing, or rejects with a B_REST_Response
	*/
	async savedModel_waitTransfers_saveChanges()
	{
		this._savedModel_assertDefined();
		
		this.verboseLog("savedModel_waitTransfers_saveChanges", `About to start`, false);
		
		let hook_afterAPICall = null;
		
		try
		{
			this.parallelAsyncTasksCount++;
			
			const request            = new B_REST_Request_PATCH(this._savedModel_path_raw, this._savedModel_path_vars);
			request.needsAccessToken = !this._isPublic; //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
			
			this.verboseLog("savedModel_waitTransfers_saveChanges", `Preparing a PATCH request: "${request.url_parsed}"`, false);
			
			hook_afterAPICall = await this.anyModel_waitOrphansWithChangesToSave_preparePostData(request); //May throw
			
			if (hook_afterAPICall)
			{
				this.verboseLog("savedModel_waitTransfers_saveChanges", `Calling the API`, false);
				const response = await B_REST_App_base.instance.call(request); //Can fail for many reasons (token, validation, server...)
				this.verboseLog("savedModel_waitTransfers_saveChanges", `Firing hook_afterAPICall in success`, false);
				hook_afterAPICall(response, true);
			}
			else { this.verboseLog("savedModel_waitTransfers_saveChanges", `Had no changes to save`,false); }
			
			this.parallelAsyncTasksCount--;
		}
		catch (response)
		{
			this.verboseLog("savedModel_waitTransfers_saveChanges", `Firing hook_afterAPICall in error`, true);
			if (hook_afterAPICall) { hook_afterAPICall(null,false); }
			
			this.parallelAsyncTasksCount--;
			
			throw response;
		}
	}
	
	
	
	static _uploadTypes_getOptions_cache = {};
	/*
	Helper to get common constructor options from the server, instead of having to copy lots of config between frontend / backend and risking to forget stuff
	Does an API call to retrieve the options for a given uploads type (ex "lead-cv" or "brand-pics")
	WARNING: Careful not to alter what's returned; always do object copies
	*/
	static async uploadTypes_getOptions(uploadsType)
	{
		if (!B_REST_ModelFileField_Control._uploadTypes_getOptions_cache[uploadsType])
		{
			B_REST_ModelFileField_Control._uploadTypes_getOptions_cache[uploadsType] = await B_REST_ModelFileField_Control._uploadTypes_getOptions_fakeCall(uploadsType);
		}
		
		return B_REST_ModelFileField_Control._uploadTypes_getOptions_cache[uploadsType];
	}
		static async _uploadTypes_getOptions_fakeCall(uploadsType)
		{
			switch (uploadsType)
			{
				case "agency-logo":
					return {
						pendingUploadsType: uploadsType,
						savedModel_path_raw: "/agencies/{agency}",
						model_fieldNamePath: "logo",
						isMultiple: false,
						acceptMimePattern: B_REST_Utils.FILES_MIME_PATTERNS_IMG,
						maxSize: null,
						maxSize_perFile: null,
						maxFileCount: null,
					};
				case "franchisor-logo":
					return {
						pendingUploadsType: uploadsType,
						savedModel_path_raw: "/franchisors/{franchisor}",
						model_fieldNamePath: "logo",
						isMultiple: false,
						acceptMimePattern: B_REST_Utils.FILES_MIME_PATTERNS_IMG,
						maxSize: null,
						maxSize_perFile: null,
						maxFileCount: null,
					};
				case "brand-logo":
					return {
						pendingUploadsType: uploadsType,
						savedModel_path_raw: "/brands/{brand}",
						model_fieldNamePath: "logo",
						isMultiple: false,
						acceptMimePattern: B_REST_Utils.FILES_MIME_PATTERNS_IMG,
						maxSize: null,
						maxSize_perFile: null,
						maxFileCount: null,
					};
				case "brand-pics":
					return {
						pendingUploadsType: uploadsType,
						savedModel_path_raw: "/brands/{brand}",
						model_fieldNamePath: "pics",
						isMultiple: true,
						acceptMimePattern: B_REST_Utils.FILES_MIME_PATTERNS_IMG,
						maxSize: null,
						maxSize_perFile: null,
						maxFileCount: 6,
					};
				case "brand-docs":
					return {
						pendingUploadsType: uploadsType,
						savedModel_path_raw: "/brands/{brand}",
						model_fieldNamePath: "docs",
						isMultiple: true,
						acceptMimePattern: B_REST_Utils.FILES_MIME_PATTERNS_ANY,
						maxSize: null,
						maxSize_perFile: null,
						maxFileCount: 6,
					};
				case "lead-cv":
					return {
						pendingUploadsType: uploadsType,
						savedModel_path_raw: "/leads/{lead}",
						model_fieldNamePath: "cv",
						isMultiple: false,
						acceptMimePattern: B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD,
						maxSize: null,
						maxSize_perFile: null,
						maxFileCount: null,
					};
				default:
					B_REST_Utils.throwEx(`Unknown uploadsType "${uploadsType}"`);
				break;
			}
		}
};
