
export default class B_REST_CallStats
{
	initiated = 0;
	success   = 0;
	error     = 0;
	
	get pending_count() { return this.initiated - this.success - this.error; }
	get pending_has()   { return this.pending_count > 0; }
	get debug()         { return `${this.initiated} | ${this.success} | ${this.error}`; }
};
