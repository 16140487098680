<template>
	
	<kb-registration-flow :pages="pages" />
	
</template>

<script>

	import { KbRegistrationFlow_Page } from "@/custom/components/modules/KbRegistrationFlow.vue";
	
	
	const pages = [
		new KbRegistrationFlow_Page("general",   "mdi-account",          ["kb-brand-form"]), //<kb-brand-form>: Must specify reason prop in KbRegistrationFlow.ifQuestionComponentIskbEntityBaseForm_specifyReason()
		new KbRegistrationFlow_Page("support",   "mdi-thumb-up-outline", ["kb-compat-question-support"]),
		new KbRegistrationFlow_Page("skills",    "mdi-head-cog-outline", ["kb-compat-question-skill-sectors","kb-compat-question-skill-rate"]),
		new KbRegistrationFlow_Page("affinity",  "mdi-puzzle-check",     ["kb-compat-question-affinity-morality-sort","kb-compat-question-affinity-bi-polar"]),
		new KbRegistrationFlow_Page("territory", "mdi-earth",            ["kb-compat-question-territory"]),	
		new KbRegistrationFlow_Page("profile",   "mdi-bullseye-arrow",   ["kb-compat-question-profile-rate"]),
		new KbRegistrationFlow_Page("schedule",  "mdi-calendar-clock",   ["kb-compat-question-time-schedule","kb-compat-question-time-allot"]),
		new KbRegistrationFlow_Page("yearn",     "mdi-star-outline",     ["kb-compat-question-yearn"]),
		new KbRegistrationFlow_Page("budget",    "mdi-currency-usd",     ["kb-compat-question-brand-entry","kb-compat-question-brand-invest","kb-compat-question-brand-net-worth-min","kb-compat-question-revenue","kb-compat-question-brand-bankrupt"]),
		new KbRegistrationFlow_Page("custom",    "mdi-star-outline",     ["kb-compat-question-brand-custom"]),
	];
	
	pages[0].showPrivacyText = true;
	
	
	
	export default {
		data()
		{
			return {
				pages,
			};
		},
		components: {
			KbRegistrationFlow: () => import("@/custom/components/modules/KbRegistrationFlow.vue"),
		},
	};
	
</script>