<template>
	<v-dialog :value="instance.isActive" :persistent="instance.isModal || instance.isIntercepting" @input="instance.cancel()" max-width="500px">
		<v-card :loading="instance.isIntercepting" :disabled="instance.isIntercepting" :color="instance.color" :dark="!!instance.color">
			<v-card-title class="text-h5">{{ instance.title }}</v-card-title>
			<v-card-text>
				{{ instance.body }}
				
				<br-prompt v-if="instance.interceptor_retDialog" :instance="instance.interceptor_retDialog"></br-prompt>
				
			</v-card-text>
			<v-card-actions>
				<v-row justify="end" class="mb-1">
					<template v-for="(loop_action,i) in instance.actions">
						<v-btn    v-if="loop_action" :key="i" :color="loop_action.color" :disabled="!loop_action.enabled" text @click="instance.selectAction(loop_action.tag)">{{ loop_action.label }}</v-btn>
						<v-spacer v-else             :key="i" />
					</template>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	
	import { B_REST_Vuetify_Prompt } from "./B_REST_Vuetify_Prompt.js";
	
	
	export default {
		props: {
			instance: {type:B_REST_Vuetify_Prompt, required:true}
		},
	};
	
</script>