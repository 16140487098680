
import { B_REST_VueApp_base } from "@/bREST/core/implementations/vue";

//NOTE: If we need to add new langs later, will need to add for frontend & backend's core & custom locs + imports for Vuetify locale
import locMsgs_custom_fr from "./loc/fr.json";
import locMsgs_custom_en from "./loc/en.json";
import locMsgs_custom_es from "./loc/es.json";

import App from "./App.vue";


//WARNING: Weird that half setup is as obj in constructor and the rest is as abstract methods

const showErrors = process.env.VUE_APP_FLAG_SHOW_ERRORS==="1";



class MyApp extends B_REST_VueApp_base
{
	constructor()
	{
		super({
			version: "1.3", //NOTE: Must also update server's bREST_Custom::CUSTOM_VERSION
								/*
								NOTES:
									-Core version control:
										-Frontend: in /bREST/core/version.js
										-Server:   in /bREST/bREST_base::VERSION & /bREST/version.info
									-Custom version control:
										-Frontend: in App.js constructor props
										-Server:   in bREST_Custom::CUSTOM_VERSION
								*/
			api: {
				baseURL: process.env.VUE_APP_B_REST_BASE_URL,
				mockCalls_enabled: false,
			},
			flags: {
				heartbeat_freq_secs:   false, //NOTE: Could maybe read from server on boot (like server version), so even if apps are cached, server could force, but why would freq evolve ?
				heartbeat_authOnly:    false,
				defaultPagingSize:     8,
				debug_locPaths:        false,
				debug_fieldNamePaths:  false,
				debug_responses:       false,
				debug_beforeReload:    false,
				debug_ignorePerms:     false,
				onErr_breakpoint:      showErrors,
				onErr_showNativeAlert: null,
				console_todo:          false,
				console_info:          false,
				console_warn:          showErrors,
				console_error:         showErrors,
				boot_cache:            false,
			},
			crypto: {
				algo: process.env.VUE_APP_B_REST_CRYPTO_ALGO,
				salt: process.env.VUE_APP_B_REST_CRYPTO_SALT,
			},
			appLangs: {
				fr: locMsgs_custom_fr,
				en: locMsgs_custom_en,
				es: locMsgs_custom_es,
			},
			appComponent: App,
			globalCSSVars: {
				"--bREST-BrFieldDb_isDirty_color": "#82b1ff",
			},
			vuetifyThemeOptions: {
				dark: false,
				themes: {
					light: {
						primary: "#f44336",
					},
					dark: {}
				}
			},
			pickerDefs: {
				agencyList: {
					component: ()=>import("./views/modules/entity_base/agencies/list/Index.vue"),
					reuseMode: "ifNotUsed",
				},
				consultantList: {
					component: ()=>import("./views/modules/entity_base/consultants/list/Index.vue"),
					reuseMode: "ifNotUsed",
				},
				franchisorList: {
					component: ()=>import("./views/modules/entity_base/franchisors/list/Index.vue"),
					reuseMode: "ifNotUsed",
				},
				brandsList: {
					component: ()=>import("./views/modules/entity_base/brands/list/Index.vue"),
					reuseMode: "ifNotUsed",
				},
				leadsList: {
					component: ()=>import("./views/modules/entity_base/leads/list/Index.vue"),
					reuseMode: "ifNotUsed",
				},
			},
			routes_useAppGeneratorRoutesInstead: false,
		});
	}
	
	
	get entity_pk()               { return this.user_extraData_ui?.entity_pk               ?? null;                       }
	get entity_needsAdminReview() { return this.user_extraData_ui?.entity_needsAdminReview ?? (this.user_type!=="admin"); }
	get creditTokenCount()        { return this.user_extraData_ui?.creditTokenCount        ?? 0;                          }
	get newCompatCount()          { return this.user_extraData_ui?.newCompatCount          ?? 0;                          }
	get paysInvoices()            { return this.user_extraData_ui?.paysInvoices            ?? false;                      }
	get shareableLink()           { return this.user_extraData_ui?.shareableLink           ?? null;                       } //Ex: "https://<domainName>/pwa/public-registration/lead?b=1287",
	
	set creditTokenCount(val)
	{
		this.user_extraData_ui.creditTokenCount=val;
		this.user_ls_update();
	}
	set newCompatCount(val)
	{
		this.user_extraData_ui.newCompatCount=val;
		this.user_ls_update();
	}
	
	get route_entity_info()
	{
		const route = this.routes_current_info;
		
		const formName = route.isRoot ? "<root>" : (route.routeDef?.meta?.formName ?? null);
		
		let route_pk = route.pathVars?.pkTag ?? null;
		if (route_pk==="*" || route_pk==="new") { route_pk=null; }
		
		const state_type           = this.user_type;
		const state_user_pk        = this.user_pk;
		const state_entity_base_pk = this.entity_pk;
		
		switch (formName)
		{
			//Things about self
			case "leadReg":
				//When we want to see someone in particular
				if (route_pk) { return {type:"lead", user_pk:null, entity_base_pk:route_pk}; }
				//When we want to see self, whether new or existing
				if (state_type && state_type!=="lead") { this.throwEx(`Logged in as "${state_type}", but trying to go to a new lead reg`); }
				return {type:"lead", user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			break;
			
			case "brandReg":
				//When we want to see someone in particular
				if (route_pk) { return {type:"brand", user_pk:null, entity_base_pk:route_pk}; }
				//When we want to see self, whether new or existing
				if (state_type && state_type!=="brand") { this.throwEx(`Logged in as "${state_type}", but trying to go to a new brand reg`); }
				return {type:"brand", user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			break;
			
			case "franchisorReg":
				//When we want to see someone in particular
				if (route_pk) { return {type:"franchisor", route_pk:null, entity_base_pk:route_pk}; }
				//When we want to see self, whether new or existing
				if (state_type && state_type!=="franchisor") { this.throwEx(`Logged in as "${state_type}", but trying to go to a new franchisor reg`); }
				return {type:"franchisor", user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			break;
			
			case "consultantReg":
				//When we want to see someone in particular
				if (route_pk) { return {type:"consultant", route_pk:null, entity_base_pk:route_pk}; }
				//When we want to see self, whether new or existing
				if (state_type && state_type!=="consultant") { this.throwEx(`Logged in as "${state_type}", but trying to go to a new consultant reg`); }
				return {type:"consultant", user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			break;
			
			case "leadOpportunities":      return {type:"lead",     user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "brandOpportunities":     return {type:"brand",    user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "matchHeatmap":           return {type:"brand",    user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "myProfile":              return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "invoices":               return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "commissionReport":       return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "regions":                return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "commissionRepartitions": return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			case "packageTiers":           return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			//Things about others
			case "adminForm":      return {type:"admin",      user_pk:route_pk, entity_base_pk:null};
			case "referentForm":   return {type:"referent",   user_pk:null,     entity_base_pk:route_pk};
			case "agencyForm":     return {type:"agency",     user_pk:null,     entity_base_pk:route_pk};
			case "consultantForm": return {type:"consultant", user_pk:null,     entity_base_pk:route_pk};
			case "franchisorForm": return {type:"franchisor", user_pk:null,     entity_base_pk:route_pk};
			case "brandForm":      return {type:"brand",      user_pk:null,     entity_base_pk:route_pk};
			case "leadForm":       return {type:"lead",       user_pk:null,     entity_base_pk:route_pk};
			//Case that happens when we go to "/", OR as we're loading -any- page sometimes, if Vuex is ready before Router is. For now, pretend we're in myProfile
			case "<root>":          return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			//Maybe not having any form is less worst than having one that's not supported
			case null:              return {type:state_type, user_pk:state_user_pk, entity_base_pk:state_entity_base_pk};
			//Else throw
			default: this.throwEx(`Unknown formName "${formName}" for "${route.fullPath}"`);
		}
	}
	
	
	
	_abstract_routes_defineRoutes()
	{
		//NOTE: _abstract_routes_beforeNavigationChange() hook below defines a redirect to myProfile if nothing matches
		
		this._routes_define_x_setCurrentLayoutComponent(()=>import("./layouts/splashscreen/Index.vue"));
			this._routes_define_login(   "/login/", ()=>import("./views/loginForm/Index.vue"));
			this._routes_define_resetPwd({fr:"/reinitialisation/",en:"/reset/",es:"/reiniciar/"}, ()=>import("./views/resetForm/Index.vue")); //WARNING: URL must match server's RouteParser_x::_abstract_sendResettingPwdEmail_getFrontendRoutePaths() URLs
			//WARNING: If we change these routes names, will have impact in KbRegistrationFlow::isPublicReg()
			this._routes_defineRoutes_publicReg_one("lead");
			this._routes_defineRoutes_publicReg_one("brand");
			this._routes_defineRoutes_publicReg_one("franchisor");
			this._routes_defineRoutes_publicReg_one("consultant");
			
			this._routes_define_public("teleportTest", "/teleportTest/", ()=>import("./views/modules/sandbox/TeleportLayout.vue"));
		
		this._routes_define_x_setCurrentLayoutComponent(()=>import("./layouts/modules/Index.vue"));
			this._routes_define_profile("/profile/", ()=>import("./views/modules/profile/Index.vue"), {
				showInMenu:           false,
				onlyReviewedAccounts: null,
				userTypes:            "admin|referent|agency|consultant|franchisor|brand|lead",
				locPath:              "menus.myProfile",
				icon:                 "mdi-account",
				formName:             "myProfile"
			});
			this._routes_defineRoutes_authReg_one("lead");
			this._routes_defineRoutes_authReg_one("brand");
			this._routes_defineRoutes_authReg_one("consultant");
			this._routes_defineRoutes_listForm_one("referentForm",   "referents",     "entity_base/referents",   "menus.referents",   "mdi-share-variant-outline", "admin");
			this._routes_defineRoutes_listForm_one("agencyForm",     "agencies",      "entity_base/agencies",    "menus.agencies",    "mdi-domain",                "admin");
			this._routes_defineRoutes_listForm_one("consultantForm", "consultants",   "entity_base/consultants", "menus.consultants", "mdi-medal-outline",         "admin|agency");
			this._routes_defineRoutes_listForm_one("franchisorForm", "franchisors",   "entity_base/franchisors", "menus.franchisors", "mdi-domain",                "admin|agency|consultant");
			this._routes_defineRoutes_listForm_one("brandForm",      "brands",        "entity_base/brands",      "menus.brands",      "mdi-google-maps",           "admin|agency|consultant|franchisor");
			this._routes_defineRoutes_listForm_one("leadForm",       "leads",         "entity_base/leads",       "menus.leads",       "mdi-account",               "admin|agency|consultant|franchisor");
			this._routes_defineRoutes_listForm_one("adminForm",      "config/admins", "config/admins",           "menus.admins",      null,                        "admin");
			this._routes_defineRoutes_custom_one("leadOpportunities",      "opportunities/lead",            "opportunities/lead",            "menus.leadOpportunities",  "mdi-bell",              "lead");
			this._routes_defineRoutes_custom_one("brandOpportunities",     "opportunities/brand",           "opportunities/brand",           "menus.brandOpportunities", "mdi-bell",              "brand");
			this._routes_defineRoutes_custom_one("matchHeatmap",           "opportunities/matchHeatmap",    "opportunities/matchHeatmap",    "menus.matchHeatmap",       "mdi-bell",              "admin|agency|consultant|franchisor|brand",               false);
			this._routes_defineRoutes_custom_one("regions",                "config/regions",                "config/regions",                "menus.regions",            "mdi-earth",             "admin");
			this._routes_defineRoutes_custom_one("commissionRepartitions", "config/commissionRepartitions", "config/commissionRepartitions", "menus.commissions",        "mdi-cash-multiple",     "admin");
			this._routes_defineRoutes_custom_one("packageTiers",           "config/packageTiers",           "config/packageTiers",           "menus.packageTiers",       "mdi-wallet-membership", "admin");
			
			this._routes_defineRoutes_custom_one("invoices", "invoices", "invoices", "menus.invoices", "mdi-wallet-membership", "admin|agency|consultant|franchisor|brand", /*showInMenu*/true, (vueRouterObj) =>
			{
				const routeInfo = B_REST_VueApp_base.routes_getRouteInfo_fromVueRouterObj(vueRouterObj);
				
				return {
					showTitle: true,
					//Used for BrGenericListBase.vue::fromLoader (check its docs)
					fromLoader: {
						apiBaseUrl: null,
						reloader: null,
						routeInfo,
					},
				};
			});
			
			this._routes_define_auth("commissionReport", "/commissionReport/", () => import(/* webpackMode: "eager" */ "./views/modules/commissionReport/Index.vue"), {
				showInMenu:           true,
				onlyReviewedAccounts: null,
				userTypes:            "admin|agency|consultant|franchisor|brand",
				locPath:              "menus.commissionReport",
				icon:                 "mdi-finance",
				formName:             "commissionReport",
			});
		}
		_routes_defineRoutes_publicReg_one(which)
		{
			const viewComponent = () => import(/* webpackMode: "eager" */ "./views/modules/registration/"+which+"/Index.vue");
			const meta = {
				formName: `${which}Reg`,
			};
			
			this._routes_define_public(`public-registration-${which}`, `/public-registration/${which}/`, viewComponent, meta);
				//IMPORTANT: Don't ren route, otherwise has impacts in server's RouteParser_Flag_Entity_base::_entityX_create()
		}
		_routes_defineRoutes_authReg_one(which)
		{
			const viewComponent = () => import(/* webpackMode: "eager" */ "./views/modules/registration/"+which+"/Index.vue");
			const meta = {
				formName: `${which}Reg`,
				which,
				locPath: `menus.${which}Reg`,
				icon: `mdi-format-list-checks`,
				userTypes: which,
			};
			
			this._routes_define_auth(`reg-${which}-existing`, `/registration/${which}/:pk`, viewComponent, {...meta, showInMenu:false,onlyReviewedAccounts:null});
			this._routes_define_auth(`reg-${which}-new`,      `/registration/${which}/`,    viewComponent, {...meta, showInMenu:true, onlyReviewedAccounts:true});
		}
		_routes_defineRoutes_listForm_one(formName, routerPath, componentSubPath, locPath, icon, userTypes)
		{
			const viewComponent_list = () => import(/* webpackMode: "eager" */ "./views/modules/"+componentSubPath+"/list/Index.vue");
			const viewComponent_form = () => import(/* webpackMode: "eager" */ "./views/modules/"+componentSubPath+"/form/Index.vue");
			
			const meta_list = {showInMenu:true,  onlyReviewedAccounts:true, userTypes, locPath, icon, formName, labelAsParam:null};
			const meta_form = {showInMenu:false, onlyReviewedAccounts:true, userTypes, locPath, icon, formName, labelAsParam:"pk"};
			
			this._routes_define_genericListFormModule(formName, `/${routerPath}/`, viewComponent_list,viewComponent_form, meta_list,meta_form);
		}
		_routes_defineRoutes_custom_one(formName, routerPath, componentSubPath, locPath, icon, userTypes, showInMenu=true, viewTransferProps=undefined)
		{
			const viewComponent = () => import(/* webpackMode: "eager" */ "./views/modules/"+componentSubPath+"/Index.vue");
			const meta = {
				showInMenu,
				onlyReviewedAccounts: showInMenu?true:null,
				userTypes,
				locPath,
				icon,
				formName,
			};
			
			this._routes_define_auth(formName, `/${routerPath}/`, viewComponent, meta, viewTransferProps);
		}
	
	
	
	async _abstract_boot_await(response, corePropsThenDirectives)
	{
		
	}
	_abstract_commonDefs_setupDescriptorHooks()
	{
		
	}
	_abstract_beforeUnload_generalHook()
	{
		return true;
	}
	_abstract_perms_can(tag,details=null)
	{
		return !!this._perms[tag];
	}
	_abstract_user_createFromObj(userModel, userObj)
	{
		if (!userModel.select("type").val)
		{
			//Not sure if we should do something, like arbitrary making it a "lead"...
		}
	}
	get _abstract_user_displayName()
	{
		return this._user?.select_firstNonEmptyVal("firstName+lastName|firstName|lastName|userName|recoveryEmail") || "Anonyme";
	}
	async _abstract_calls_interceptCoreProps_customDataNode(customProps,thenDirectives)
	{
		
	}
	async _abstract_calls_tweakResponse_hook(response, corePropsThenDirectives)
	{
		
	}
	_abstract_calls_afterCall_general_handler(response)
	{
		
	}
	async _abstract_routes_hasPerms(routeInfo)
	{
		const routeDef = routeInfo.routeDef;
		if (!routeDef) { return true; }
		
		if (routeDef.needsAuth && !this.user_isAuth) { return false; }
		
		const allowedUserTypes = routeDef.meta.userTypes;
		if (allowedUserTypes && !allowedUserTypes.split("|").includes(this.user_type)) { return false; }
		
		return true;
	}
	async _abstract_routes_beforeNavigationChange(routeInfo_to, routeInfo_from=null)
	{
		//If we're trying to an unknown place, or "/"
		if (routeInfo_to.isUnknown)
		{
			// return (this.user_isAuth?this.routeDefs_profile:this.routeDefs_login).toRouteInfo();
		}
		
		return true;
	}
	_abstract_routes_evalTravelDirection(routeInfo_to, routeInfo_from=null)
	{
		//NOTE: Could build breadcrumbs too
		
		const name_from = routeInfo_from?.routeDef?.name ?? null;
		const name_to   = routeInfo_to.routeDef?.name    ?? null;
		
		if (!name_from || !name_to) { return MyApp.ROUTES_TRAVEL_DIR_UNRELATED; }
		
		//Check if we've got something like "<moduleName>-list" or "<moduleName>-form"
		const parts_from = name_from.split("-");
		const parts_to   = name_to.split("-");
		if (parts_from.length!==2 || parts_to.length!==2) { return MyApp.ROUTES_TRAVEL_DIR_UNRELATED; }
		
		const moduleName_from = parts_from[0];
		const moduleName_to   = parts_to[0];
		if (!moduleName_from || moduleName_from!==moduleName_to) { return MyApp.ROUTES_TRAVEL_DIR_UNRELATED; }
		
		const formOrList_from = parts_from[1];
		const formOrList_to   = parts_to[1];
		if (formOrList_from===formOrList_to) { return MyApp.ROUTES_TRAVEL_DIR_UNRELATED; }
		return formOrList_from==="list" ? MyApp.ROUTES_TRAVEL_DIR_TO_CHILD : MyApp.ROUTES_TRAVEL_DIR_TO_PARENT;
	}
	_abstract_routes_afterNavigationChange(routeInfo_to, routeInfo_from=null)
	{
		
	}
};




MyApp.instance_init();
