
import { B_REST_Utils, B_REST_Descriptor } from "../../../../../classes";
import GModel from "./GModel.js";



export class GModel_XField_base
{
	static get REQ_TYPE_REQ()       { return "req";      }
	static get REQ_TYPE_OPT_NULL()  { return "optNULL";  }
	static get REQ_TYPE_OPT_OTHER() { return "optOther"; }
	static get REQ_TYPES() { return [GModel_XField_base.REQ_TYPE_REQ, GModel_XField_base.REQ_TYPE_OPT_NULL, GModel_XField_base.REQ_TYPE_OPT_OTHER]; }
	
	static get W_CUSTOM_SETTER_OFF()       { return "off";      }
	static get W_CUSTOM_SETTER_TWEAK_VAL() { return "tweakVal"; }
	static get W_CUSTOM_SETTER_BLOCK_VAL() { return "blockVal"; }
	static get W_CUSTOM_SETTER_VALS() { return [GModel_XField_base.W_CUSTOM_SETTER_OFF, GModel_XField_base.W_CUSTOM_SETTER_TWEAK_VAL, GModel_XField_base.W_CUSTOM_SETTER_BLOCK_VAL]; }
	
	
	_model         = null; //GModel it belongs too
	_fieldName     = null;
	_comments      = null;
	_reqType       = null; //One of GModel_XField_base.REQ_TYPE_x
	_optionalVal   = null;
	_wCustomSetter = null; //One of GModel_XField_base.W_CUSTOM_SETTER_x
	_setOnce       = false;
	_loc           = null; //{label:{<lang>:<translation>}, shortLabel:{<lang>:<translation>}}
	
	constructor(model)
	{
		this._model        = model;
		this.reqType       = GModel_XField_base.REQ_TYPE_OPT_NULL;    //Use setter
		this.wCustomSetter = GModel_XField_base.W_CUSTOM_SETTER_OFF;  //Use setter
		
		this._loc = {
			label:      GModel.makeLocLangsObj(), //Ex {fr:null, en:null, es:null}
			shortLabel: GModel.makeLocLangsObj(),
		};
	}
	
	get model() { return this._model; }
	
	get fieldName()    { return this._fieldName; }
	set fieldName(val) { this._fieldName=val;    }
	
	get comments()    { return this._comments; }
	set comments(val) { this._comments=val;    }
	
	get reqType() { return this._reqType; }
	get reqType_isReq()      { return this._reqType===GModel_XField_base.REQ_TYPE_REQ;       }
	get reqType_isOptNULL()  { return this._reqType===GModel_XField_base.REQ_TYPE_OPT_NULL;  }
	get reqType_isOptOther() { return this._reqType===GModel_XField_base.REQ_TYPE_OPT_OTHER; }
	set reqType(val)
	{
		this._reqType = val;
		
		//Use setters
		this.optionalVal = null;
		if (!this.setOnce_can) { this.setOnce=false; }
		
		this._abstract_reqType_onChanged();
	}
		_abstract_reqType_onChanged() { B_REST_Utils.throwEx(`Must override base method`); }
	
	get optionalVal()    { return this._optionalVal; }
	set optionalVal(val) { this._optionalVal=val;    }
	
	get wCustomSetter()    { return this._wCustomSetter; }
	set wCustomSetter(val) { this._wCustomSetter=val;    }
	
	set setOnce(val) { this._setOnce=val;    }
	get setOnce()    { return this._setOnce; }
	
	get setOnce_can() { return !this.reqType_isOptOther; }
	
	get loc() { return this._loc; }
	get loc_filled()
	{
		const loc_label = this._loc.label;
		
		for (const loop_lang in loc_label)
		{
			const loop_translation = loc_label[loop_lang];
			if (loop_translation===null || loop_translation==="") { return false; }
		}
		
		return true;
	}
};



export class GModel_DBField extends GModel_XField_base
{
	static get DEFAULT_MAX_LENGTH_PWD()   { return 255; }
	static get DEFAULT_MAX_LENGTH_PHONE() { return  50; }
	static get DEFAULT_MAX_LENGTH_EMAIL() { return  75; }
	
	static get TYPE_STRING()  { return "string";  }
	static get TYPE_INT()     { return "int";     }
	static get TYPE_DECIMAL() { return "decimal"; }
	static get TYPE_BOOL()    { return "bool";    }
	static get TYPE_JSON()    { return "json";    }
	static get TYPE_DT()      { return "dt";      }
	static get TYPE_D()       { return "d";       }
	static get TYPE_T()       { return "t";       }
	static get TYPE_ENUM()    { return "enum";    }
	static get TYPE_PHONE()   { return "phone";   }
	static get TYPE_EMAIL()   { return "email";   }
	static get TYPE_PWD()     { return "pwd";     }
	static get TYPE_CUSTOM()  { return "custom";  }
	static get TYPES() {
							return [
								GModel_DBField.TYPE_STRING,
								GModel_DBField.TYPE_INT,
								GModel_DBField.TYPE_DECIMAL,
								GModel_DBField.TYPE_BOOL,
								GModel_DBField.TYPE_JSON,
								GModel_DBField.TYPE_DT,
								GModel_DBField.TYPE_D,
								GModel_DBField.TYPE_T,
								GModel_DBField.TYPE_ENUM,
								GModel_DBField.TYPE_PHONE,
								GModel_DBField.TYPE_EMAIL,
								GModel_DBField.TYPE_PWD,
								GModel_DBField.TYPE_CUSTOM,
							];
						}
	static get TYPES_CAN_RANGE_NUMBER() { return [GModel_DBField.TYPE_INT,GModel_DBField.TYPE_DECIMAL]; }
	static get TYPES_CAN_RANGE_TEXT() {
										return [
											GModel_DBField.TYPE_STRING,
											GModel_DBField.TYPE_PWD,
											GModel_DBField.TYPE_PHONE,
											GModel_DBField.TYPE_EMAIL,
											GModel_DBField.TYPE_CUSTOM,
										];
									}
	
	_type      = null;
	_min       = null; //For numbers, varchars, text & D/T types
	_max       = null; //For numbers, varchars, text & D/T types
	_decimals  = null; //Only for TYPE_DECIMAL
	_enumVals  = null; //Arr of {tag,loc:{<lang>:<translation>}}. NOTE: optionalVal will point on one of these enum members
	_onChanged = false;
	
	constructor(model)
	{
		super(model);
		
		this.type = GModel_DBField.TYPE_INT; //Use setter
	}
	
	_abstract_reqType_onChanged()
	{
		if (this.reqType_isOptOther && this.type_isBool) { this.optionalVal=false; }
	}
	
	set type(val)
	{
		this.reqType = GModel_XField_base.REQ_TYPE_OPT_NULL; //Use setter
		
		this._type     = val;
		this._min      = null;
		this._max      = null;
		this._decimals = null;
		this._enumVals = null;
		
		switch (this._type)
		{
			case GModel_DBField.TYPE_ENUM:
				this._enumVals = [];
			break;
			case GModel_DBField.TYPE_PWD:
				this._max = GModel_DBField.DEFAULT_MAX_LENGTH_PWD;
			break;
			case GModel_DBField.TYPE_PHONE:
				this._max = GModel_DBField.DEFAULT_MAX_LENGTH_PHONE;
			break;
			case GModel_DBField.TYPE_EMAIL:
				this._max = GModel_DBField.DEFAULT_MAX_LENGTH_EMAIL;
			break;
		}
	}
	get type() { return this._type; }
	get type_isString()  { return this._type===GModel_DBField.TYPE_STRING;  }
	get type_isInt()     { return this._type===GModel_DBField.TYPE_INT;     }
	get type_isDecimal() { return this._type===GModel_DBField.TYPE_DECIMAL; }
	get type_isBool()    { return this._type===GModel_DBField.TYPE_BOOL;    }
	get type_isJson()    { return this._type===GModel_DBField.TYPE_JSON;    }
	get type_isDt()      { return this._type===GModel_DBField.TYPE_DT;      }
	get type_isD()       { return this._type===GModel_DBField.TYPE_D;       }
	get type_isT()       { return this._type===GModel_DBField.TYPE_T;       }
	get type_isEnum()    { return this._type===GModel_DBField.TYPE_ENUM;    }
	get type_isPhone()   { return this._type===GModel_DBField.TYPE_PHONE;   }
	get type_isEmail()   { return this._type===GModel_DBField.TYPE_EMAIL;   }
	get type_isPwd()     { return this._type===GModel_DBField.TYPE_PWD;     }
	get type_isCustom()  { return this._type===GModel_DBField.TYPE_CUSTOM;  }
	
	get min()    { return this._min; }
	set min(val) { this._min=val;    }
	
	get max()    { return this._max; }
	set max(val) { this._max=val;    }
	
	get decimals()    { return this._decimals; }
	set decimals(val) { this._decimals=val;    }
	
	get enumVals() { return this._enumVals; }
	enumVals_add()
	{
		const enumMember = {tag:null, loc:GModel.makeLocLangsObj()}; //Ex {fr:null, en:null, es:null}
		this._enumVals.push(enumMember);
		return enumMember;
	}
	enumVals_del(enumMember) { this._enumVals = this._enumVals.filter(loop_enumMember => loop_enumMember!==enumMember); }
	get enumVals_filled()
	{
		if (!this.can_enumVals) { return null; }
		
		let tagCount = 0;
		for (const loop_enumMember of this._enumVals)
		{
			if (loop_enumMember.tag===null || loop_enumMember.tag==="") { return false; }
			
			for (const loop_lang in loop_enumMember.loc)
			{
				const loop_translation = loop_enumMember.loc[loop_lang];
				if (loop_translation===null || loop_translation==="") { return false; }
			}
			tagCount++;
		}
		
		return tagCount>0;
	}
	
	get onChanged()    { return this._onChanged; }
	set onChanged(val) { this._onChanged=val;    }
	
	get can_range_asNumber()       { return GModel_DBField.TYPES_CAN_RANGE_NUMBER.includes(this._type);       }
	get can_range_asText()         { return GModel_DBField.TYPES_CAN_RANGE_TEXT.includes(this._type);         }
	get can_range_asDT()           { return false;                                                            } //Not really used for now
	get can_decimals()             { return this.type_isDecimal;                                              }
	get can_enumVals()             { return this.type_isEnum;                                                 }
	get can_defaultVal_asNumber()  { return this.reqType_isOptOther && this.can_range_asNumber;               }
	get can_defaultVal_asText()    { return this.reqType_isOptOther && this.can_range_asText;                 }
	get can_defaultVal_asDT()      { return this.reqType_isOptOther && this.can_range_asDT;                   }
	get can_defaultVal_asEnumVal() { return this.reqType_isOptOther && this.can_enumVals;                     }
	get can_defaultVal_asBool()    { return this.reqType_isOptOther && this.type_isBool;                      }
	
	get name_forIndex() { return this._fieldName; } //For when it's used in GModel::indexes
};



export class GModel_LookupField extends GModel_XField_base
{
	static get TYPE_PRIVATE()         { return "private (1-1)";       }
	static get TYPE_SHARED()          { return "shared (1-N)";        }
	static get TYPE_SHARED_SET_ONCE() { return "sharedSetOnce (1-N)"; }
	static get TYPES() { return [GModel_LookupField.TYPE_PRIVATE, GModel_LookupField.TYPE_SHARED, GModel_LookupField.TYPE_SHARED_SET_ONCE]; }
	
	_fkFieldName    = null;
	_modelClassName = null;
	_type           = null; //One of GModel_LookupField.TYPE_x
	
	constructor(model)
	{
		super(model);
		
		this.type = GModel_LookupField.TYPE_PRIVATE; //Use setter
	}
	
	get fkFieldName()    { return this._fkFieldName; }
	set fkFieldName(val) { this._fkFieldName=val;    }
	
	get modelClassName()    { return this._modelClassName; }
	set modelClassName(val)
	{
		this._modelClassName = val;
		
		const descriptor  = B_REST_Descriptor._commonDefs[this._modelClassName];
		const pkFieldName = descriptor.pks[0].name;
		
		this._fieldName   = B_REST_Utils.string_lcFirst(this._modelClassName);
		this._fkFieldName = `${this._fieldName}_fk`;
	}
	
	get type()    { return this._type; }
	set type(val) { this._type=val;    }
	
	get name_forIndex() { return this._fkFieldName; } //For when it's used in GModel::indexes
};



export class GModel_SubModelField extends GModel_XField_base
{
	static get TYPE_SINGLE_REQ() { return "singleReq"; }
	static get TYPE_SINGLE_OPT() { return "singleOpt"; }
	static get TYPE_LIST_OPT()   { return "listOpt";   }
	static get TYPES() { return [GModel_SubModelField.TYPE_SINGLE_REQ, GModel_SubModelField.TYPE_SINGLE_OPT, GModel_SubModelField.TYPE_LIST_OPT]; }
	
	_subModel_info        = null; //Must point on a {modelClassName, intFields}, where fields are just their names
	_subModel_fkFieldName = null; //Must be a field name in the above
	_type                 = null; //One of GModel_SubModelField.TYPE_x
	
	constructor(model)
	{
		super(model);
		
		this.type = GModel_SubModelField.TYPE_SINGLE_REQ; //Use setter
	}
	
	get subModel_info() { return this._subModel_info; }
	set subModel_info(val)
	{
		this._subModel_info        = val;
		this._subModel_fkFieldName = null;
	}
	
	get subModel_fkFieldName()    { return this._subModel_fkFieldName; }
	set subModel_fkFieldName(val) { this._subModel_fkFieldName=val;    }
	
	get type()    { return this._type; }
	set type(val) { this._type=val;    }
};



export class GModel_FileField extends GModel_XField_base
{
	static get DEFAULT_MAX_FILE_SIZE_MB() { return 20; } //Arbitrary
	
	static get TYPE_SINGLE_REQ() { return "singleReq"; }
	static get TYPE_SINGLE_OPT() { return "singleOpt"; }
	static get TYPE_LIST()       { return "list";      }
	static get TYPES() { return [GModel_FileField.TYPE_SINGLE_REQ, GModel_FileField.TYPE_SINGLE_OPT, GModel_FileField.TYPE_LIST]; }
	
	static get ALLOWED_TYPES_ALL()          { return "all";         }
	static get ALLOWED_TYPES_IMGS_ONLY()    { return "imgsOnly";    }
	static get ALLOWED_TYPES_PDF_ONLY()     { return "pdfOnly";     }
	static get ALLOWED_TYPES_WORD_ONLY()    { return "wordOnly";    }
	static get ALLOWED_TYPES_PDFWORD_ONLY() { return "pdfWordOnly"; }
	static get ALLOWED_TYPES() { return [GModel_FileField.ALLOWED_TYPES_ALL, GModel_FileField.ALLOWED_TYPES_IMGS_ONLY, GModel_FileField.ALLOWED_TYPES_PDF_ONLY, GModel_FileField.ALLOWED_TYPES_WORD_ONLY, GModel_FileField.ALLOWED_TYPES_PDFWORD_ONLY]; }
	
	_type                  = null;   //One of GModel_FileField.TYPE_x
	_maxFileCount          = null;
	_maxFileSize           = null;   //Per file
	_allowedTypes          = null;   //NULL or one of GModel_FileField.ALLOWED_TYPES_x
	_softDelete            = false;
	_customDisplayNameFunc = null;
	
	constructor(model)
	{
		super(model);
		
		this.type = GModel_FileField.TYPE_SINGLE_REQ; //Use setter
		
		this._maxFileSize = GModel_FileField.DEFAULT_MAX_FILE_SIZE_MB;
	}
	
	get type() { return this._type; }
	set type(val)
	{
		this._type = val;
		if (!this.can_maxFileCount)          { this._maxFileCount=null;          }
		if (!this.can_customDisplayNameFunc) { this._customDisplayNameFunc=null; }
	}
	get type_isSingle()   { return this._type!==GModel_FileField.TYPE_LIST; }
	get type_isMultiple() { return this._type===GModel_FileField.TYPE_LIST; }
	
	get maxFileCount()    { return this._maxFileCount; }
	set maxFileCount(val) { this._maxFileCount=val;    }
	
	get maxFileSize()    { return this._maxFileSize; }
	set maxFileSize(val) { this._maxFileSize=val;    }
	
	get allowedTypes()    { return this._allowedTypes; }
	set allowedTypes(val) { this._allowedTypes=val;    }
	
	get softDelete()    { return this._softDelete; }
	set softDelete(val) { this._softDelete=val;    }
	
	get customDisplayNameFunc()    { return this._customDisplayNameFunc; }
	set customDisplayNameFunc(val) { this._customDisplayNameFunc=val;    }
	
	get can_customDisplayNameFunc() { return this.type_isSingle;   }
	get can_maxFileCount()          { return this.type_isMultiple; }
};



export class GModel_OtherField extends GModel_XField_base
{
	static get FUNC_TYPE_OFF()    { return "off";    }
	static get FUNC_TYPE_MANUAL() { return "manual"; }
	static get FUNC_TYPE_FUNC()   { return "func";   }
	static get FUNC_TYPES() { return [GModel_OtherField.FUNC_TYPE_OFF,GModel_OtherField.FUNC_TYPE_FUNC,GModel_OtherField.FUNC_TYPE_MANUAL]; }
	
	_func_load               = GModel_OtherField.FUNC_TYPE_FUNC;
	_func_save               = GModel_OtherField.FUNC_TYPE_OFF;
	_func_toObj              = GModel_OtherField.FUNC_TYPE_OFF;
	_func_fromObj            = GModel_OtherField.FUNC_TYPE_OFF;
	_func_unsavedChanges_has = GModel_OtherField.FUNC_TYPE_OFF;
	_func_delete_can_orMsg   = GModel_OtherField.FUNC_TYPE_OFF;
	_func_delete             = GModel_OtherField.FUNC_TYPE_OFF;
	
	constructor(model)
	{
		super(model);
	}
	
	get func_load() { return this._func_load; }
	set func_load(val)
	{
		this._func_load = val;
		if (this._func_load===GModel_OtherField.FUNC_TYPE_MANUAL) { this._model.hook_load_after=true; }
	}
	
	get func_save() { return this._func_save; }
	set func_save(val)
	{
		this._func_save = val;
		if (this._func_save===GModel_OtherField.FUNC_TYPE_MANUAL)
		{
			this._model.hook_save_before = true;
			this._model.hook_save_after  = true;
		}
	}
	
	get func_toObj() { return this._func_toObj; }
	set func_toObj(val)
	{
		this._func_toObj = val;
		if (this._func_toObj===GModel_OtherField.FUNC_TYPE_MANUAL) { this._model.hook_toObj_after=true; }
	}
	
	get func_fromObj() { return this._func_fromObj; }
	set func_fromObj(val)
	{
		this._func_fromObj = val;
		if (this._func_fromObj===GModel_OtherField.FUNC_TYPE_MANUAL)
		{
			this._model.hook_fromObj_before = true;
			this._model.hook_fromObj_after  = true;
		}
	}
	
	get func_unsavedChanges_has() { return this._func_unsavedChanges_has; }
	set func_unsavedChanges_has(val)
	{
		this._func_unsavedChanges_has = val;
	}
	
	get func_delete_can_orMsg() { return this._func_delete_can_orMsg; }
	set func_delete_can_orMsg(val)
	{
		this._func_delete_can_orMsg = val;
		if (this._func_delete_can_orMsg===GModel_OtherField.FUNC_TYPE_MANUAL)
		{
			this._model.hook_delete_can_orMsg = true;
			this._model.hook_delete_before    = true;
			this._model.hook_delete_after     = true;
		}
	}
	
	get func_delete() { return this._func_delete; }
	set func_delete(val)
	{
		this._func_delete = val;
		if (this._func_delete===GModel_OtherField.FUNC_TYPE_MANUAL)
		{
			this._model.hook_delete_can_orMsg = true;
			this._model.hook_delete_before    = true;
			this._model.hook_delete_after     = true;
		}
	}
};
