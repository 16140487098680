<template>
	<v-container v-if="control" ref="container" class="br-field-file" :class="container_classes">
		
		<v-row dense>
			<v-col v-if="control.label" cols="12" class="base-file-input__label text-uppercase">
				<v-label> <v-icon v-if="control.prependIcon" v-text="control.prependIcon" /> {{ control.labelWStar }}</v-label>
			</v-col>
			
			<v-col cols="12">		
				<v-card :loading="control.hasOngoingAsyncTasks" @dragover.prevent="drag_on_enter" @dragenter.prevent="drag_on_enter" @dragleave.stop.prevent="drag_on_leave" @drop.prevent="drag_on_drop" :color="drag_is?'grey lighten-2':null" elevation="4" :style="card_style">
					<v-btn v-if="control.savedModel_zip_can" absolute icon top right text @click="items_download_allZipped"><v-icon>mdi-download-outline</v-icon></v-btn>
					
					<v-card-text v-if="!control.isReadOnly && !(control.displayMode_isAvatar && control.items_has)">
						<v-sheet width="100%" class="d-flex flex-column align-center justify-center" color="transparent" @click="openFilePicker" :style="control.items_canPutMore?'cursor:pointer':null">
							<v-icon :color="cloud_color" size="60">mdi-cloud-upload</v-icon>
							<p v-if="placeholder_show" class="text-center">{{ control.placeholder }}</p> <!-- In DISPLAY_MODE_AVATAR we don't have enough space to write labels -->
						</v-sheet>
					</v-card-text>
					
					<v-card-text v-if="control.items_has" :style="cardText_style">
						
						<template v-if="control.displayMode_isList">
							<v-list subheader two-line class="pb-4">
								<template v-for="(loop_item,loop_item_idx) in control.items">
									<v-divider :key="`divider-${loop_item.frontendUUID}`" v-if="!control.isReadOnly || loop_item_idx>0" class="my-2" />
									<br-field-file-item-progressbar :key="`progress-${loop_item.frontendUUID}`" :item="loop_item" />
									<v-list-item :key="`item-${loop_item.frontendUUID}`" :ripple="false">
										<v-list-item-avatar tile :size="control.filePreview_squareSize">
											<br-field-file-item-preview :item="loop_item" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title    v-text="loop_item.fileInfo.baseNameWExt" />
											<v-list-item-subtitle v-text="loop_item.fileInfo.size_humanReadable" />
										</v-list-item-content>
										<v-list-item-action>
											<br-field-file-item-toolbar :item="loop_item" :small-icons="false" @click:delete="items_toggle_remove_prompt(loop_item)" />
										</v-list-item-action>
									</v-list-item>
								</template>
							</v-list>
						</template>
						
						<template v-if="control.displayMode_isTiles">
							<v-row justify="start" align="stretch" class="mb-2">
								<v-col v-for="(loop_item,loop_item_idx) in control.items" :key="`item-${loop_item.frontendUUID}`" cols="auto">
									<v-sheet height="100%" dark rounded class="pt-2" style="position:relative;" :style="{width:`${tiles_sheet_width}px`}">
										<div class="br-field-file--tiles--toolbar-wrapper">
											<br-field-file-item-toolbar :item="loop_item" :small-icons="tiles_toolbar_smallIcons" @click:delete="items_toggle_remove_prompt(loop_item)" dark class="mt-3 mx-1" />
										</div>
										<br-field-file-item-progressbar :item="loop_item" absolute />
										<br-field-file-item-preview :item="loop_item" class="mx-auto mt-4" />
										<div class="white--text text-center px-3 pb-3">
											<div class="text-body-2 my-2" v-text="loop_item.fileInfo.baseNameWExt" />
											<div class="text-caption"     v-text="loop_item.fileInfo.size_humanReadable" />
										</div>
									</v-sheet>
								</v-col>
							</v-row>
						</template>
						
						<template v-if="control.displayMode_isAvatar && firstItem">
							<v-sheet :key="`item-${firstItem.frontendUUID}`" height="100%" dark rounded class="pt-2" style="position:relative;" @click="openFilePicker">
								<!-- IMPORTANT: Keep the :key on the <v-sheet>, for when we change items, otherwise previews won't change -->
								<div class="br-field-file--tiles--toolbar-wrapper">
									<br-field-file-item-toolbar :item="firstItem" :small-icons="avatar_toolbar_smallIcons" @click:delete="items_toggle_remove_prompt(firstItem)" dark class="mt-3 mx-1" />
								</div>
								<br-field-file-item-progressbar :item="firstItem" absolute />
								<br-field-file-item-preview :item="firstItem" />
							</v-sheet>
						</template>
						
					</v-card-text>
					
					<v-card-text v-else-if="!control.displayMode_isAvatar && placeholder_show" class="text-center">
						<v-label>{{ translation_noFiles }}</v-label>
					</v-card-text>
				</v-card>
			</v-col>
			
			<v-col cols="12">
				<v-slide-y-transition>
					<v-messages v-if="control.errorMsgs_has" class="error--text" :value="control.errorMsgs" /> <!-- As per /node_modules/vuetify/src/components/VMessages/VMessages.ts, must be an arr -->
				</v-slide-y-transition>
			</v-col>
			
		</v-row>
		
		<input type="file" class="d-none" ref="hiddenFilePicker" :multiple="control.isMultiple" :accept="control.acceptMimePattern" />
		
		<br-prompt v-if="confirmDelPrompt" :instance="confirmDelPrompt" />
		
	</v-container>
</template>

<script>
	
	import B_REST_Vuetify_Field_File                               from "./B_REST_Vuetify_Field_File.js";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action } from "../prompt/B_REST_Vuetify_Prompt.js";
	
	
	const DRAG_STATE_OFF     = "off";
	const DRAG_STATE_ENTER   = "enter";
	const DRAG_STATE_LEAVING = "leaving";
	
	const CONFIRM_DEL_PROMPT_ACTIONS_CANCEL  = null;
	const CONFIRM_DEL_PROMPT_ACTIONS_PROCEED = "proceed";
	
	const ITEM_TOOLBAR_MIN_WIDTH = 120;
	
	const TILES_MIN_WIDTH = 150;
	
	const PLACEHOLDER_MIN_AREA = 170 * 150; //Of course, not really reliable if we have 150 * 170 instead...
	
	
	export default {
		components: {
			BrPrompt:                   () => import("../prompt/BrPrompt.vue"),
			BrFieldFileItemToolbar:     () => import("./BrFieldFileItemToolbar.vue"),
			BrFieldFileItemProgressbar: () => import("./BrFieldFileItemProgressbar.vue"),
			BrFieldFileItemPreview:     () => import("./BrFieldFileItemPreview.vue"),
		},
		props: {
			control:     {type:B_REST_Vuetify_Field_File, required:false, default:null}, //Default NULL so we can init it later if we need to
			displayMode: {type:String,                    required:false, default:null}, //Can rewrite control.displayMode later
			label:       {type:String,                    required:false, default:null}, //Can rewrite control.label later
			icon:        {type:String,                    required:false, default:null}, //Can rewrite control.prependIcon later
			previewSize: {type:Number,                    required:false, default:null}, //Can rewrite control.filePreview_width & control.filePreview_height later
		},
		data()
		{
			return {
				confirmDelPrompt: null, //Optional instance of B_REST_Vuetify_Prompt
				drag: {
					state:                        DRAG_STATE_OFF, //One of DRAG_STATE_x -> Check drag_flickerHelper_start() docs
					flickerHelper_interval_ptr:   null,           //setInterval ptr
					flickerHelper_interval_delay: 100,
				},
			};
		},
		watch: {
			control: {
				immediate: true,
				async handler(newVal, oldVal)
				{
					if (newVal) //NOTE: Used to do (oldVal===undefined && newVal!==null), but broke because usage places made us go from undefined -> null -> instance, we'd never get there
					{
						//Check to pass other options the user could have set via props instead of the control's constructor config
						if (this.displayMode!==null) { newVal.displayMode                                   = this.displayMode; }
						if (this.label      !==null) { newVal.label                                         = this.label;       }
						if (this.icon       !==null) { newVal.prependIcon                                   = this.icon;        }
						if (this.previewSize!==null) { newVal.filePreview_width = newVal.filePreview_height = this.previewSize; }
						
						await this.$bREST.utils.sleep(0); //Do this, otherwise $refs won't have time to be set
						this.init();
					}
				},
			},
			displayMode(newVal,oldVal) { if(newVal!==null && this.control){ this.control.displayMode                                      =newVal; } },
			label(newVal,oldVal)       { if(newVal!==null && this.control){ this.control.label                                            =newVal; } },
			icon(newVal,oldVal)        { if(newVal!==null && this.control){ this.control.prependIcon                                      =newVal; } },
			previewSize(newVal,oldVal) { if(newVal!==null && this.control){ this.control.filePreview_width=this.control.filePreview_height=newVal; } },
		},
		//IMPORTANT: Setup stuff even if we start in RO, as it can change state during use
		mounted() //WARNING: Don't switch to created(), because we need to use $refs
		{
			if (this.control) { this.init(); } //Otherwise do it later when we get the control
		},
		beforeDestroy()
		{
			if (this.control)
			{
				this.drag_flickerHelper_stop();
				this.control.destroy();
			}
		},
		computed: {
			container_classes()
			{
				return {
					"br-field-file--error": this.control.errMsgs_has,
					"br-field-file--dirty": this.control.validation_dirty,
				};
			},
			card_style()
			{
				if (this.control.displayMode_isAvatar)
				{
					return {
						width:    `${this.control.filePreview_width}px`,
						height:   `${this.control.filePreview_height}px`,
						overflow: "hidden"
					};
				}
				
				return {};
			},
			cardText_style()
			{
				if (this.control.displayMode_isAvatar)
				{
					return {
						padding:0,
					};
				}
				
				return {};
			},
			cloud_color()
			{
				if (this.drag_is) { return "primary"; }
				return this.control.items_canPutMore ? "secondary" : "grey";
			},
			drag_is() { return this.drag.state!==DRAG_STATE_OFF; },
			translation_noFiles() { return this.translate(`noFiles.${this.control.isMultiple?"multiple":"single"}`); },
			firstItem() { return this.control.items_has ? this.control.items[0] : null; },
			placeholder_show()
			{
				if (!this.control.displayMode_isAvatar) { return true; }
				return this.control.filePreview_width*this.control.filePreview_height >= PLACEHOLDER_MIN_AREA;
			},
			tiles_sheet_width() { return this.control.filePreview_width>TILES_MIN_WIDTH ? this.control.filePreview_width : TILES_MIN_WIDTH; },
			tiles_toolbar_smallIcons() { return this.tiles_sheet_width<=ITEM_TOOLBAR_MIN_WIDTH; },
			avatar_toolbar_smallIcons() { return this.control.filePreview_width<=ITEM_TOOLBAR_MIN_WIDTH; },
		},
		methods: {
			//GENERAL
				//IMPORTANT: Setup stuff even if we start in RO, as it can change state during use
				init()
				{
					//Setup drag flickering prevention
					this.drag_flickerHelper_start();
					
					//Setup del confirm prompt
					{
						this.confirmDelPrompt = new B_REST_Vuetify_Prompt(this.translate("confirmDeletion.title"), false/*isModal*/);
						
						this.confirmDelPrompt.actions = [
							new B_REST_Vuetify_Prompt_Action(CONFIRM_DEL_PROMPT_ACTIONS_CANCEL, this.translate("confirmDeletion.cancel"), null),
							null,
							new B_REST_Vuetify_Prompt_Action(CONFIRM_DEL_PROMPT_ACTIONS_PROCEED, this.translate("confirmDeletion.proceed"), "error"),
						];
					}
					
					//Setup an event listener for when set files. For drag n drop, check drag_on_drop()
					this.$refs.hiddenFilePicker.addEventListener("change", async() =>
					{
						this.control.parseSelectionEvent_onInputFileChange(this.$refs.hiddenFilePicker); //Rets if we got valid files. Does async stuff, but doesn't wait for uploads
						
						/*
						After the hidden file picker is used, set it back to empty right away, because if we later remove files and try to select them again,
						the file picker won't know it has changed (became empty) and the event won't ever be called again
						*/
						this.$refs.hiddenFilePicker.value = null;
		    		});
				},
				translate(msgPath, details=null) { return this.control.translate(msgPath,details); },
				openFilePicker()
				{
					if (this.control.items_canPutMore) { this.$refs.hiddenFilePicker.click(); }
				},
				items_download_allZipped()
				{
					this.control.items_download_allZipped(null/*baseNameWExt*/, this.$refs.container); //Async
				},
				async items_toggle_remove_prompt(item) //Instance of B_REST_ModelFileField_ControlItem
				{
					//Check if we should confirm first
					if (item.status_isStored && !item.ifStored_toDelete)
					{
						this.confirmDelPrompt.body = this.translate("confirmDeletion.body", {baseNameWExt:item.fileInfo.baseNameWExt});
						
						const selectedActionOrNull = await this.confirmDelPrompt.show();
						if (selectedActionOrNull!==CONFIRM_DEL_PROMPT_ACTIONS_PROCEED) { return; }
					}
					
					this.control.items_toggle_remove(item);
				},
			//DRAG RELATED
				/*
				Drag state is tristate, because for some reason, @dragleave gets called everytime we move over child elems.
				Tried with "pointer-events:none" on child elems, but was worse
				*/
				drag_flickerHelper_start()
				{
					this.drag.flickerHelper_interval_ptr = setInterval(() =>
					{
						if (this.drag.state===DRAG_STATE_LEAVING) { this.drag.state=DRAG_STATE_OFF; }
					}, this.drag.flickerHelper_interval_delay);
				},
				drag_flickerHelper_stop()
				{
					clearInterval(this.drag.flickerHelper_interval_ptr);
					this.drag.flickerHelper_interval_ptr = null;
				},
				drag_on_enter() { if(this.control.items_canPutMore){this.drag.state=DRAG_STATE_ENTER;  } },
				drag_on_leave() { if(this.control.items_canPutMore){this.drag.state=DRAG_STATE_LEAVING;} },
				drag_on_drop($event)
				{
					if (this.control.items_canPutMore && this.control.parseSelectionEvent_onDrop($event)) //Rets if we got valid files. Does async stuff, but doesn't wait for uploads
					{
						this.drag.state = DRAG_STATE_OFF;
					}
				},
		},
	};
	
</script>

<style scoped>
	
	.v-list-item :deep(.v-list-item__action) {
		flex-direction: unset !important;
		align-items: center;
	}
	
	.br-field-file {}
		.br-field-file--dirty {}
			.br-field-file--dirty .base-file-input__label .v-label {
				color: blue;
			}
			.br-field-file--dirty .v-card {
				border: 2px solid blue;
			}
		
		.br-field-file--error {}
			.br-field-file--error .base-file-input__label .v-label {
				color: #ff5252;
			}
			.br-field-file--error .v-card {
				border: 2px solid #ff5252;
			}
		
		.br-field-file--tiles--toolbar-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: 1;
			text-align: right;
			background: linear-gradient(180deg,black,transparent);
		}
	
</style>