
import GFile_base from "../../utils/GFile_base.js";



function empty(val) { return val===null||val===''; }



export default class GFile_Form extends GFile_base
{
	_form = null;
	
	constructor(form)
	{
		super();
		
		this._form = form;
	}
	
	
	get _abstract_title()    { return `${this._form.componentFileNameWOExt_form}.vue`; }
	get _abstract_filePath() { return `${this._form.componentFilePath_form}`;          }
	
	
	static _output_colSizes(xField)
	{
		const sizes = [];
		if (xField.cols_xs) { sizes.push(`cols="${xField.cols_xs}"`); }
		if (xField.cols_sm) { sizes.push(`sm="${xField.cols_sm}"`);   }
		if (xField.cols_md) { sizes.push(`md="${xField.cols_md}"`);   }
		if (xField.cols_lg) { sizes.push(`lg="${xField.cols_lg}"`);   }
		if (xField.cols_xl) { sizes.push(`xl="${xField.cols_xl}"`);   }
		
		return sizes.join(" ");
	}
	
	output_template_fields_brFieldDbFields()
	{
		const lines = [];
		
		for (const loop_field of this._form.fields_brFieldDbFields)
		{
			const loop_colSizes = GFile_Form._output_colSizes(loop_field);
			
			let loop_colContents = null;
			
			if (loop_field.isReadOnly) { loop_colContents = `{{ model.select("${loop_field.selectFieldNamePath}").val }}`; }
			else
			{
				const loop_attrs = [];
				
				loop_attrs.push(`field="${loop_field.selectFieldNamePath}"`);
				
				if (loop_field.as_isNotAuto)            { loop_attrs.push(`as="${loop_field.as}"`);                            }
				if (loop_field.isMultiple)              { loop_attrs.push(`multiple`);                                         }
				if (loop_field.items_type_isSharedList) { loop_attrs.push(`items="${loop_field.sharedListName}"`);             }
				if (loop_field.items_type_isCustomArr)  { loop_attrs.push(`:items="dataSets.${loop_field.name}_customItems"`); }
				if (loop_field.items_type_isModelList)  { loop_attrs.push(`:items="dataSets.${loop_field.name}_modelList"`);   }
				
				if (loop_field.items_type_isDataTable)
				{
					if (loop_field.items_type_isCommonDataTable)
					{
						const loop_dataTableName = loop_field.commonDataTableName ? loop_field.commonDataTableName : `newDataTableName_${loop_field.name}`;
						
						loop_attrs.push(`data-table="${loop_dataTableName}"`);
					}
					else
					{
						loop_attrs.push(`:data-table="dataSets.${loop_field.name}_dataTable"`);
					}
				}
				
				if (loop_field.items_key!==null)        { loop_attrs.push(`items-key-name="${loop_field.items_key}"`);                }
				if (loop_field.items_label!==null)      { loop_attrs.push(`items-label-name="${loop_field.items_label}"`);            }
				if (loop_field.items_flatSearch!==null) { loop_attrs.push(`items-flat-search-name="${loop_field.items_flatSearch}"`); }
				
				loop_colContents = `<br-field-db :model="model" ${loop_attrs.join(" ")} />`;
			}
			
			lines.push(`<v-col ${loop_colSizes}> ${loop_colContents} </v-col>`);
		}
		
		return lines;
	}
	
	output_template_fields_subModelListFields()
	{
		const lines = [];
		
		for (const loop_field of this._form.fields_subModelListFields)
		{
			const loop_colSizes = GFile_Form._output_colSizes(loop_field);
			
			lines.push(`<v-col ${loop_colSizes}> <!-- TODO --> </v-col>`);
		}
		
		return lines;
	}
	
	output_template_fields_fileFields()
	{
		const lines = [];
		
		for (const loop_field of this._form.fields_fileFields)
		{
			const loop_colSizes = GFile_Form._output_colSizes(loop_field);
			
			lines.push(`<v-col ${loop_colSizes}> <!-- Not yet handling <br-field-file> for now --> </v-col>`);
		}
		
		return lines;
	}
	
	output_template_fields_otherFields()
	{
		const lines = [];
		
		for (const loop_field of this._form.fields_otherFields)
		{
			const loop_colSizes = GFile_Form._output_colSizes(loop_field);
			
			lines.push(`<v-col ${loop_colSizes}> {{ model.select("${loop_field.selectFieldNamePath}").data }} </v-col>`);
		}
		
		return lines;
	}
	
	output_data_fields_brFieldDbFields()
	{
		const stuff = {
			import_commonModelLists: false,
			import_dataTables: false,
			dataSetupLines: [],
			dataSetsLines:  [],
		};
		
		for (const loop_field of this._form.fields_brFieldDbFields)
		{
			if (loop_field.isReadOnly) { continue; }
			
			if (loop_field.items_type_isCustomArr)
			{
				const loop_items_key        = loop_field.items_key        ?? "key";
				const loop_items_label      = loop_field.items_label      ?? "label";
				const loop_items_flatSearch = loop_field.items_flatSearch ?? "flatSearch";
				
				const loop_dataSetLine = `${loop_field.name}_customItems: [ //NOTE: Consider translating labels with this._loc()\n`
				                       + `\t\t\t\t\t\t{${loop_items_key}:1, ${loop_items_label}:"Mtl",   ${loop_items_flatSearch}:"Mtl|Montréal|Montreal"},\n`
				                       + `\t\t\t\t\t\t{${loop_items_key}:2, ${loop_items_label}:"Sherb", ${loop_items_flatSearch}:"Sherb"},\n`
				                       + `\t\t\t\t\t\t{${loop_items_key}:3, ${loop_items_label}:"QC",    ${loop_items_flatSearch}:"QC|Québec|Quebec"},\n`
				                       + `\t\t\t\t\t],`;
				stuff.dataSetsLines.push(loop_dataSetLine);
			}
			else if (loop_field.items_type_isModelList)
			{
				if (loop_field.items_type_isCommonModelList)
				{
					stuff.import_commonModelLists = true;
					
					if (loop_field.commonModelListName)
					{
						stuff.dataSetsLines.push(`${loop_field.name}_modelList: commonModelLists.${loop_field.commonModelListName},`);
					}
					else
					{
						//Should maybe add a GFile_X to create the common model list
						
						stuff.dataSetsLines.push(`${loop_field.name}_modelList: commonModelLists.todo_for_${loop_field.name},`);
					}
				}
				else
				{
					const loop_varName_modelList = `${loop_field.name}_modelList`;
					
					const loop_dataSetupLine = `const ${loop_varName_modelList} = B_REST_ModelList.commonDefs_make("SomeModelName", /*useForLoading*/true, /*useCachedShare*/false);\n`
					                         + `\t\t\t${loop_varName_modelList}.requiredFields.requiredFields_addFields("some|required|fields");\n`
					                         + `\t\t\t${loop_varName_modelList}.apiBaseUrl                              = "/some/api/url/";\n`
					                         + `\t\t\t${loop_varName_modelList}.apiBaseUrl_needsAccessToken             = true;\n`
					                         + `\t\t\t${loop_varName_modelList}.searchOptions.paging_calcFoundRowsCount = false;\n`
					                         + `\t\t\t${loop_varName_modelList}.searchOptions.paging_size_all();`;
					
					stuff.dataSetupLines.push(loop_dataSetupLine);
					stuff.dataSetsLines.push(`${loop_field.name}_modelList: ${loop_varName_modelList},`); //NOTE: We could simplify by removing what's after the ":" if it's identical
				}
			}
			else if (loop_field.items_type_isDataTable)
			{
				if (loop_field.items_type_isCommonDataTable)
				{
					if (loop_field.commonDataTableName)
					{
						//Nothing to do
					}
					else
					{
						stuff.import_dataTables = true;
						
						const loop_varName_modelList = `${loop_field.name}_modelList`;
						
						const loop_dataSetupLine = `const ${loop_varName_modelList} = B_REST_ModelList.commonDefs_make("SomeModelName");\n`
						                         + `\t\t\t${loop_varName_modelList}.apiBaseUrl_needsAccessToken = true;\n`
						                         + `\t\t\tDataTables.DataTable.commonDefs_add(new DataTables.DataTable("newDataTableName_${loop_field.name}", ${loop_varName_modelList}, {...}));`;
						stuff.dataSetupLines.push(loop_dataSetupLine);
					}
				}
				else
				{
					stuff.import_dataTables = true;
					
					const loop_varName_modelList = `${loop_field.name}_modelList`;
					const loop_varName_dataTable = `${loop_field.name}_dataTable`;
					
					const loop_dataSetupLine = `const ${loop_varName_modelList} = B_REST_ModelList.commonDefs_make("SomeModelName");\n`
					                         + `\t\t\t${loop_varName_modelList}.apiBaseUrl_needsAccessToken = true;\n`
					                         + `\t\t\t${loop_varName_dataTable} = new DataTables.DataTable("newDataTableName_${loop_field.name}", ${loop_varName_modelList}, {...}));`;
					stuff.dataSetupLines.push(loop_dataSetupLine);
					
					stuff.dataSetsLines.push(`${loop_field.name}_dataTable: ${loop_varName_dataTable},`); //NOTE: We could simplify by removing what's after the ":" if it's identical
				}
			}
		}
		
		return stuff;
	}
	
	_abstract_output()
	{
		const dataStuff = this.output_data_fields_brFieldDbFields();
		
		const xTemplateFieldLines = [
			...this.output_template_fields_brFieldDbFields(),
			...this.output_template_fields_subModelListFields(),
			...this.output_template_fields_fileFields(),
			...this.output_template_fields_otherFields(),
		];
		
		return `<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				${xTemplateFieldLines.join("\n\t\t\t\t")}
			</v-row>
		</template>
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericFormBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/form/BrGenericFormBase.vue";
	
	
	
	export default {
		mixins: B_REST_Vuetify_GenericFormBase_createMixin({
			formName:           "${this._form.formName}",
			modelName:          "${this._form.modelClassName}",
			apiBaseUrl:         "${this._form.apiBaseUrl}",
			needsAccessToken:   false,
		${this._form._autoSave?'\t':' // '}autoUpdateInterval: 2000,
			requiredFields:     "${this._form.reqFields}",
			afterLoad(response,models)                 { },
			beforeSave(request,model)                  { },
			afterSave(response,model,isSuccess,wasNew) { },
		}),
		data()
		{
			return { };
		},
	}
	
</script>`;
	}
};
