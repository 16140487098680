<template>
	
	<v-container class="fill-height">
		<v-row>
			<v-col cols="12">
				<v-card :loading="isSpinning" :disabled="isSpinning">
					<v-row v-if="regionTree" class="pa-4" justify="space-between">
						
						<v-col cols="12" md="6">
							<kb-region-tree ref="regionTree" :instance="regionTree" />
						</v-col>
						
						<v-divider vertical class="hidden-sm-and-down" />
						
						<v-col class="text-center hidden-sm-and-down">
								<v-row align="start" class="fill-height">
									<v-fade-transition mode="out-in">
										
										<v-col v-if="!regionTree.activeRegion" key="nothingSelected" cols="12" class="text-h4 px-6 text-md-h3 grey--text text--lighten-1 font-weight-light align-self-center">
											{{ _loc("activeRegion.select") }}
										</v-col>
										
										<v-col v-else :key="regionTree.activeRegion.uid" cols="12">
											<v-card class="pt-6 mx-auto" flat max-width="400">
												<v-card-text>
													<v-avatar v-if="regionTree.activeRegion.typeIcon" size="88"> <v-icon v-text="regionTree.activeRegion.typeIcon" class="mb-6" x-large /> </v-avatar>
													<h3 class="text-h5 mb-2">{{ regionTree.activeRegion.name }}</h3>
													<div class="blue--text subheading font-weight-bold text-capitalize">{{ regionTree.activeRegion.typeLabel }}</div>
													<div class="blue--text mb-2">{{ regionTree.activeRegion.breadcrumbs }}</div>
												</v-card-text>
												
												<v-divider />
												
												<v-card-text>
													<v-row>
														<v-col cols="12">
															<br-field-db :model="regionTree.activeRegion.model" field="manager.entity_base_fk" picker="consultantList" :disabled="!regionTree.activeRegion.manager_canEdit" />
														</v-col>
														<v-col cols="12">
															<br-field-db :model="regionTree.activeRegion.model" field="partner.entity_base_fk" picker="consultantList" :disabled="!regionTree.activeRegion.partner_canEdit" />
														</v-col>
													</v-row>
												</v-card-text>
												
												
												<v-divider />
												
												<v-card-actions class="justify-center py-4">
													<v-btn v-if="regionTree.activeRegion && !regionTree.activeRegion.isNew && !regionTree.activeRegion.isCity" large color="primary" @click="on_add_click"> <v-icon>mdi-plus</v-icon> </v-btn>
												</v-card-actions>
												
											</v-card>
										</v-col>
										
									</v-fade-transition>
								</v-row>
						</v-col>
						
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row justify="end">
			<v-col cols="auto">
				<v-btn color="primary" @click="on_save_click" class="ml-4">
					{{ _loc("actions.save.label") }} <v-icon>mdi-content-save</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		
	</v-container>
	
</template>
<script>
	
	import KbRegionTree from "@/custom/components/general/regionTree/KbRegionTree.js";
	
	
	
	const locBasePath = "views.regions";
	
	
	
	export default {
		components: {
			KbRegionTree: () => import("@/custom/components/general/regionTree/KbRegionTree.vue"),
		},
		data()
		{
			return {
				regionTree: null,
				isSpinning: true,
			};
		},
		async beforeMount()
		{
			this.$bREST.utils.console_todo([	
				`Delete`,
				`Bug: Once saved, field's touches get toggled off in JS, but <br-field-db> still shows as having some. Disappears if we add a sub tree to -that- branch later though...`,
				`Bug: Once saved, if tries to add new things, now it doesn't know there are new unsaved changes`,
				"Load real data",
				"Be able to save",
			]);
			
			const regionTree = new KbRegionTree(KbRegionTree.MODES_EDITION);
			await regionTree.load();
			
			this.regionTree = regionTree;
			
			this.isSpinning = false;
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			on_add_click()
			{
				this.$refs.regionTree.activeRegion_addNew();
			},
			async on_save_click()
			{
				this.isSpinning = true;
				
				try
				{
					await this.regionTree.save();
				}
				catch (e)
				{
					this.$bREST.utils.console_error(e);
				}
				
				//KISS. Check func's docs for why we should do that
				this.$bREST.reboot();
			},
		},
	};
	
</script>