<template>
	<v-progress-linear height="6" v-bind="attrs" :absolute="absolute" :top="absolute" />
</template>

<script>
	
	import { B_REST_ModelFileField_ControlItem } from "../../../../classes";
	
	export default {
		props: {
			item:     {type:B_REST_ModelFileField_ControlItem, required:true},
			absolute: {type:Boolean,                           required:false, default:false},
		},
		computed: {
			attrs()
			{
				let color           = "red";
				let value           = 100;
				let striped         = false;
				const indeterminate = !!this.item.asyncTaskBatchId;
				
				switch (this.item.status)
				{
					case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING:
						//Red + progression
						value = this.item.ifNewPreparing_progression;
					break;
					case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING_FAILED:
						//Red 100%
					break;
					case B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARED:
						//Green + special style, depending on if we're doing an API call or not
						color = "success";
						if (!this.item.asyncTaskBatchId) { striped = true; }
					break;
					case B_REST_ModelFileField_ControlItem.STATUS_STORED:
						if (!this.item.ifStored_toDelete)
						{
							//Success 100%
							color = "success";
						}
						//Else red + special style, depending on if we're doing an API call or not
						else if (!this.item.asyncTaskBatchId) { striped = true; }
					break;
					default:
						this.$bREST.utils.throwEx(`Unhandled item status "${this.item.status}"`);
					break;
				}
				
				return {color, value, indeterminate, striped};
			}
		},
	};
	
</script>