
import B_REST_Utils                from "../B_REST_Utils.js";
import B_REST_Model                from "../models/B_REST_Model.js";
import B_REST_App_base             from "../app/B_REST_App_base.js";
import B_REST_DOMFilePtr           from "./B_REST_DOMFilePtr.js";
import { B_REST_Request_GET_File } from "../api/B_REST_Request.js";



export default class B_REST_ModelFileField_ControlItem
{
	static get STATUS_NEW_PREPARING()        { return "newPreparing";       } //When we first create this instance, we'll try to send it to the pending uploads dir. This shows it's transferring
	static get STATUS_NEW_PREPARING_FAILED() { return "newPreparingFailed"; } //Couldn't upload completely for server validation reasons or etc
	static get STATUS_NEW_PREPARED()         { return "newPrepared";        } //Now we have a pending upload hash, and we're ready to move it in an API call
	static get STATUS_STORED()               { return "stored";             } //Now safe in its final location on the server, ex in a /clients/123/docs/ dir
	
	static _next_frontendUUID = 1;
	
	_control          = null;  //Link to its bound B_REST_ModelFileField_Control
	_frontendUUID     = null;  //Unique ID to help identifying a file before & after an API call, ex when we started with a pendingUploads hash and end up with a file in a "real" place with changed name
	_fileInfo         = null;  //Instance of B_REST_ModelFileField_ControlItem_FileInfo
	_status           = null;  //One of STATUS_x
	_hasWarnings      = false; //Helper to know if something went wrong a while ago
	_asyncTaskBatchId = null;  //Check B_REST_ModelFileField_Control::_currentAsyncTaskBatchId docs
	//Status dependent
	_ifNewX_domFilePtr                = null;  //Instance of B_REST_DOMFilePtr
	_ifNewPreparing_promise           = null;  //Instance of Promise, if we want to wait for upload to be done
	_ifNewPreparing_progression       = null;  //When transferring, 0 to 100
	_ifNewPrepared_hash               = null;  //Something like "f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf"
	_ifStored_toDelete                = false; //Bool
	_ifStored_fileInfo_resizedVersion = null;  //Optional instance of B_REST_ModelFileField_ControlItem_FileInfo, if img
	
	
	
	constructor()
	{
		this._frontendUUID = B_REST_ModelFileField_ControlItem._next_frontendUUID++;
		this._fileInfo     = new B_REST_ModelFileField_ControlItem_FileInfo();
	}
		//Make an instance from infos we find in a B_REST_DOMFilePtr instance
		static factory_fromDOMFilePtr(domFilePtr)
		{
			B_REST_Utils.instance_isOfClass_assert(B_REST_DOMFilePtr, domFilePtr);
			
			const item = new B_REST_ModelFileField_ControlItem();
			
			item._ifNewX_domFilePtr = domFilePtr;
			
			const fileInfo = item._fileInfo;
				fileInfo.baseNameWExt  = domFilePtr.baseNameWExt;
				fileInfo.baseNameWOExt = domFilePtr.baseNameWOExt;
				fileInfo.ext           = domFilePtr.ext;
				fileInfo.mime          = domFilePtr.mime_from_bestGuess; //Can ret NULL
				fileInfo.size          = domFilePtr.size;
				fileInfo.width         = domFilePtr.width;
				fileInfo.height        = domFilePtr.height;
				fileInfo.apiUrl        = null;
			
			return item;
		}
		/*
		Make an instance from a field we get in an API call like:
			{baseNameWExt:"logo.bmp", baseNameWOExt:"logo", ext:"bmp", size:<bytes>,mime:"image/bmp",width:null,height:null, apiUrl:"/contact/123/logo?h=asdg7g9", resizedVersion:{size,width,height,"/contact/123/logo?h=asdg7g9&small=1"}, _apiUID_:123},
		*/
		static factory_fromAPIFieldFileData(apiFieldFileData)
		{
			const item = new B_REST_ModelFileField_ControlItem();
			item.setStatus_stored(apiFieldFileData);
			return item;
		}
	
	
	
	static _throwEx(msg) { B_REST_Utils.throwEx(msg); }
	       _throwEx(msg) { B_REST_Utils.throwEx(`Got err for "${this._fileInfo.baseNameWExt}": ${msg}`); }
	
	
	
	get control()                          { return this._control;                                                                                               }
	get frontendUUID()                     { return this._frontendUUID;                                                                                          }
	get fileInfo()                         { return this._fileInfo;                                                                                              }
	get status()                           { return this._status;                                                                                                }
	get status_isNewPreparing()            { return this._status===B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING;                                       }
	get status_isNewPreparingFailed()      { return this._status===B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING_FAILED;                                }
	get status_isNewPrepared()             { return this._status===B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARED;                                        }
	get status_isStored()                  { return this._status===B_REST_ModelFileField_ControlItem.STATUS_STORED;                                              }
	get hasWarnings()                      { return this._hasWarnings;                                                                                           }
	get asyncTaskBatchId()                 { return this._asyncTaskBatchId;                                                                                      }
	get ifNewX_domFilePtr()                { return this._ifNewX_domFilePtr;                                                                                     }
	get ifNewPreparing_promise()           { return this._ifNewPreparing_promise;                                                                                }
	get ifNewPreparing_progression()       { return this._ifNewPreparing_progression;                                                                            }
	get ifNewPrepared_hash()               { return this._ifNewPrepared_hash;                                                                                    }
	get ifStored_toDelete()                { return this._ifStored_toDelete;                                                                                     }
	get ifStored_fileInfo_resizedVersion() { return this._ifStored_fileInfo_resizedVersion;                                                                      }
	get isOrphanWithChangesToSave()        { return !this._asyncTaskBatchId && (this.status_isNewPreparing||this.status_isNewPrepared||this._ifStored_toDelete); }
	get isImg()                            { return this._fileInfo.isImg;                                                                                        }
	get canDownload()                      { return this._fileInfo.apiUrl || (this._ifNewX_domFilePtr && !this._ifNewX_domFilePtr.isRevokedObjectURL);           }
	
	set hasWarnings(val) { this._hasWarnings=val; }
	set asyncTaskBatchId(valOrNull)
	{
		if (this._asyncTaskBatchId && valOrNull) { this._throwEx(`Can't assign to a batch id; already bound`); }
		this._asyncTaskBatchId = valOrNull;
	}
	set ifNewPreparing_progression(val)
	{
		if (this._status!==B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING) { this._throwEx(`Status must be STATUS_NEW_PREPARING to change progression`); }
		this._ifNewPreparing_progression = val;
	}
	//Can only flag if it already exists in server at the "final" dir, otherwise just remove the item with B_REST_ModelFileField_Control::items_remove()
	set ifStored_toDelete(val)
	{
		if (this._status!==B_REST_ModelFileField_ControlItem.STATUS_STORED) { this._throwEx(`Status must be STATUS_STORED to change ifStored_toDelete flag`); }
		this._ifStored_toDelete = val;
	}
	
	
	
	setStatus_preparing(uploadPromise)
	{
		this._hasWarnings                = false;
		this._ifNewPreparing_promise     = uploadPromise;
		this._ifNewPreparing_progression = 0;
		this._status                     = B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING;
	}
	setStatus_preparingFailed()
	{
		this._hasWarnings                = true;
		this._ifNewPreparing_progression = 0;
		this._status                     = B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARING_FAILED;
		this._ifNewPreparing_promise     = null;
	}
	setStatus_prepared(pendingUploadHash)
	{
		this._hasWarnings                = false;
		this._ifNewPrepared_hash         = pendingUploadHash;
		this._ifNewPreparing_progression = 100;
		this._status                     = B_REST_ModelFileField_ControlItem.STATUS_NEW_PREPARED;
		this._ifNewPreparing_promise     = null;
	}
	/*
	Check factory_fromAPIFieldFileData() docs. Will set status to STATUS_STORED + release the B_REST_DOMFilePtr instance, if any
	If we also received data from server, sets it in its B_REST_ModelFileField_ControlItem_FileInfo instances
	*/
	setStatus_stored(apiFieldFileData=null)
	{
		this._status = B_REST_ModelFileField_ControlItem.STATUS_STORED;
		
		if (apiFieldFileData)
		{
			this._fileInfo.fromAPIFieldFileData(apiFieldFileData);
			
			if (apiFieldFileData.resizedVersion)
			{
				this._ifStored_fileInfo_resizedVersion = new B_REST_ModelFileField_ControlItem_FileInfo();
				this._ifStored_fileInfo_resizedVersion.fromAPIFieldFileData(apiFieldFileData.resizedVersion);
			}
			
			this.ifNewPreparing_releaseMemory(); //NOTE: Do this only when we've got data, otherwise we'd end up in a non-downloadable state
		}
	}
	
	
	
	//If we had ObjectURL and such mem buffers taking lots of space, free it. Otherwise, will be freed upon killing page
	ifNewPreparing_releaseMemory()
	{
		if (this._ifNewX_domFilePtr?.typeTag_is_objectURL) { this._ifNewX_domFilePtr.objectURL_revoke(); }
		
		this._ifNewX_domFilePtr = null;
	}
	
	async ifNewPreparingFailed_retry()
	{
		return this._control.items_failedUploads_retry(this);
	}
	
	/*
	When it's destined to be a new file to be saved in model in an API post
	Yields something like:
		{pendingAPIUpload_baseNameWExt_hashed:"f170603f56adbe285dfa26b1c734d4f8e3726a75-bob.pdf", _apiUID_:123}
	*/
	ifNewPrepared_toApiFileObj()
	{
		const obj = {};
		
		obj.pendingAPIUpload_baseNameWExt_hashed = this._ifNewPrepared_hash;
		obj[B_REST_Model.API_UID_FIELDNAME]      = this._frontendUUID;
		
		return obj;
	}
	
	
	
	/*
	Yields an objectURL or throws on err. Either uses the resized version or the original apiUrl
	WARNING:
		After the objectURL is used, we must do B_REST_Utils.files_objectURL_revoke(objectURL) to prevent bloating memory
	*/
	async getPreviewImg()
	{
		if (!this.isImg) { B_REST_ModelFileField_ControlItem._throwEx(`Can't do this on an item that isn't an img`); }
		
		this._control.verboseLog("items_getPreviewImg", `About to yield an object URL for "${this._fileInfo.baseNameWExt}"`, false);
		
		const apiUrl = this._ifStored_fileInfo_resizedVersion?.apiUrl || this._fileInfo.apiUrl;
		
		const request = new B_REST_Request_GET_File(apiUrl);
		request.needsAccessToken = !this._control.isPublic; //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
		request.expectsContentType_image();
		
		const { objectURL } = await B_REST_App_base.instance.call_getObjectUrl(request);
		return objectURL;
	}
	
	/*
	If we have it as a local file, we'll download it using B_REST_DOMFilePtr::download()
	Otherwise, if it's on the server, we'll get it via an API call
	In all other cases, throw
	Check B_REST_DOMFilePtr::download() & B_REST_API::call_download() docs for params
	Rets nothing on success
	Throws a B_REST_Response or other kinds of Error
	*/
	async download(baseNameWExt=null, domContainer=null)
	{
		this._control.verboseLog("download", `About to download "${this._fileInfo.baseNameWExt}"`, false);
		
		if (!baseNameWExt) { baseNameWExt=this._fileInfo.baseNameWExt; }
		
		if (!this.canDownload) { this._throwEx(`File is unavailable, either because it's local and revoked, or supposed to be on server but we don't know its apiUrl`); }
		
		try
		{
			this._control.parallelAsyncTasksCount++;
			
			//If file is still available locally (+ not revoked)
			if (this._ifNewX_domFilePtr && !this._ifNewX_domFilePtr.isRevokedObjectURL)
			{
				await this._ifNewX_domFilePtr.download(baseNameWExt, domContainer);
			}
			//Otherwise if we know where to find it on server (with private hash)
			else if (this._fileInfo.apiUrl)
			{
				const request = new B_REST_Request_GET_File(this._fileInfo.apiUrl);
				request.needsAccessToken = !this._isPublic; //Either bool or B_REST_Request_base::NEEDS_ACCESS_TOKEN_DONT (ex for login calls)
				await B_REST_App_base.instance.call_download(request, baseNameWExt, domContainer);
			}
			else { B_REST_ModelFileField_ControlItem._throwEx(`Got unhandled case`); } //Should never happen, because of throw
			
			this._control.parallelAsyncTasksCount--;
		}
		catch (e)
		{
			this._control.parallelAsyncTasksCount--;
			throw e;
		}
	}
};






class B_REST_ModelFileField_ControlItem_FileInfo
{
	baseNameWExt  = null; //Ex "logo.png"
	baseNameWOExt = null; //Ex "logo"
	ext           = null; //Ex "png"
	size          = null; //In bytes
	mime          = null; //Ex "image/png"
	width         = null; //Only if img
	height        = null; //Only if img
	apiUrl        = null; //Ex /contact/123/logo?h=asdg7g9 or /contact/123/logo?h=asdg7g9&small=1. WARNING: Might be NULL, even when SERVER_STATUS_STORED
	
	
	get size_humanReadable() { return B_REST_Utils.files_humanReadableSize(this.size); }
	get isImg()              { return this.mime?.indexOf("image/")===0;                }
	
	/*
	Ex:
		{baseNameWExt:"logo.bmp", baseNameWOExt:"logo", ext:"bmp", size:<bytes>,mime:"image/bmp",width:null,height:null, apiUrl:"/contact/123/logo?h=asdg7g9", resizedVersion:{size,width,height,"/contact/123/logo?h=asdg7g9&small=1"}, _apiUID_:123},
	*/
	fromAPIFieldFileData(apiFieldFileData)
	{
		this.baseNameWExt  = apiFieldFileData.baseNameWExt;
		this.baseNameWOExt = apiFieldFileData.baseNameWOExt;
		this.ext           = apiFieldFileData.ext;
		this.size          = apiFieldFileData.size;
		this.mime          = apiFieldFileData.mime;
		this.width         = apiFieldFileData.width;
		this.height        = apiFieldFileData.height;
		this.apiUrl        = apiFieldFileData.apiUrl;
	}
};
