
export default class B_REST_Error extends Error
{
	constructor(message)
	{
		super(message);
		this.name = "B_REST_Error";
	}
	
	static isBRESTError(e) { return e instanceof B_REST_Error; }
};
