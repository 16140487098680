
import { B_REST_Utils, B_REST_App_base, B_REST_ModelList } from "@/bREST/core/classes";
import KbRegion from "./KbRegion.js";



export default class KbRegionTree
{
	static get MODES_EDITION()   { return "edition";     }
	static get MODES_SELECTION() { return "selectedion"; }
	static get USE_DEBUG_NAMES() { return false; }
	
	
	_mode            = null; //One of KbRegionTree.MODES_x
	_modelList       = null; //Instance of B_REST_ModelList<Region>
	_rootRegion      = null; //Instance of KbRegion
	_activeRegion    = null; //Instance of KbRegion in the tree
	_selectedRegions = [];   //Arr of KbRegion instances
	
	
	constructor(mode)
	{
		this._mode = mode;
	}
	
	
	get mode()             { return this._mode; }
	get mode_isEdition()   { return this._mode===KbRegionTree.MODES_EDITION;   }
	get mode_isSelection() { return this._mode===KbRegionTree.MODES_SELECTION; }
	
	get rootRegion() { return this._rootRegion; }
	
	get activeRegion() { return this._activeRegion; }
	set activeRegion(val) { this._activeRegion=val; }
	
	get selectedRegions() { return this._selectedRegions; }
	set selectedRegions(val) { this._selectedRegions=val; }
	
	selectedRegions_toHexTypedTags()
	{
		const hexTypedTags = [];
		this._rootRegion.selectedRegions_toHexTypedTags(this._selectedRegions, hexTypedTags);
		return hexTypedTags;
	}
	async selectedRegions_fromHexTypedTags(hexTypedTags_selected, hexTypedTags_inferred)
	{
		const selectedRegions = [];
		await this._selectedRegions_fromHexTypedTags(this._rootRegion, hexTypedTags_selected, hexTypedTags_inferred, selectedRegions);
		
		this._selectedRegions = selectedRegions;
	}
		async _selectedRegions_fromHexTypedTags(currentRegion, hexTypedTags_selected, hexTypedTags_inferred, selectedRegions)
		{
			if (currentRegion!==this._rootRegion)
			{
				if      (hexTypedTags_selected.includes(currentRegion.hexTypedTag)) { selectedRegions.push(currentRegion);                          }
				else if (hexTypedTags_inferred.includes(currentRegion.hexTypedTag)) { await currentRegion.children_tryLoad(/*isEditionMode*/false); }
			}
			
			for (const loop_child of currentRegion.children) { await this._selectedRegions_fromHexTypedTags(loop_child,hexTypedTags_selected,hexTypedTags_inferred,selectedRegions); }
		}
	
	
	async load()
	{
		const reqFields = this._mode===KbRegionTree.MODES_EDITION ? "name|hexTypedTag|manager.manager.user.<dbOnly>|partner.partner.user.<dbOnly>" : "name|hexTypedTag"; //NOTE: For managers & partners, if we want to use cieName instead, we have to change the _def_sharedLookup_req_fixed() in the server to a Model_Consultant instead of Model_Entity_base
		
		const modelList = B_REST_ModelList.commonDefs_make("Region", /*useForLoading*/true, /*useCachedShare*/false);
		modelList.requiredFields.requiredFields_addFields(reqFields);
		modelList.apiBaseUrl                              = "/regionTree/{branch}";
		modelList.apiBaseUrl_path_vars                    = {branch:"root"};
		modelList.apiBaseUrl_needsAccessToken             = false;
		modelList.searchOptions.paging_calcFoundRowsCount = false;
		modelList.searchOptions.paging_size_all();
		await modelList.reload();
		
		this._modelList = modelList;
		
		this._rootRegion = new KbRegion(null, this._modelList);
		this._load_parseNode(this._rootRegion, this._modelList);
	}
		_load_parseNode(currentRegion, currentModelList)
		{
			for (const loop_model of currentModelList.models)
			{
				const loop_subRegion = currentRegion.children_add_existing(loop_model);
				
				if (loop_model.select_isUsed("subRegions")) { this._load_parseNode(loop_subRegion,loop_model.select("subRegions").modelList); }
			}
		}
	
	/*
	Rets as an arr of:
		New nodes:      {pk:null, parent_fk, name [,manager] [,partner]}
		Existing nodes: {pk [,manager] [,partner]}
	*/
	getNodesWChanges()
	{
		const changes = [];
		
		this._rootRegion.getNodesWChanges(changes);
		
		return changes;
	}
	
	/*
	NOTE:
		To KISS, we don't allow creating multiple child nodes at the same time,
		so if we wanted to create a state then its areas and cities, we'll need to reload the page multiple times
	*/
	async save()
	{
		if (!this.mode_isEdition) { B_REST_Utils.throwEx("Can't save read only region tree"); }
		
		const changes = this.getNodesWChanges();
		
		if (changes.length===0) { return; }
		
		const request = new B_REST_App_base.instance.PUT("/config/regions");
		request.data = changes;
		request.expectsContentType_empty();
		request.needsAccessToken = true;
		
		const response = await B_REST_App_base.instance.call(request);
		
		/*
		IMPORTANT:
			Usage should then do: this.$bREST.reboot();
		*/
	}
};
