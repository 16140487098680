
import { B_REST_Utils } from "../../../../../../classes";
import GFile_base from "../../utils/GFile_base.js";
import { GModel_DBField } from "../GModel_XFields.js";



function empty(val) { return val===null||val===''; }



export default class GFile_SQL extends GFile_base
{
	static get COLLATION() { return " CHARACTER SET utf8 COLLATE utf8_general_ci"; }
	
	
	_model = null;
	
	constructor(model)
	{
		super();
		
		this._model = model;
	}
	
	
	get _abstract_title()    { return `SQL`; }
	get _abstract_filePath() { return null; }
	
	
	get Model_ModelClassName() { return `Model_${this._model.modelClassName}`; }
	
	
	output_field(fieldName, type, isNullable, hasDefaultVal=false, defaultVal=null, comments=null, maxLength=null, decimals=null, enumTags=null, isAutoInc=false)
	{
		let sqlType = null;
		
		switch (type)
		{
			case GModel_DBField.TYPE_INT:
				sqlType = `INT(11)`;
			break;
			case GModel_DBField.TYPE_DECIMAL:
				decimals = !empty(decimals) ? parseInt(decimals) : 2;
				const maxDigits = 8 + decimals;
				
				sqlType = `DECIMAL(${maxDigits},${decimals})`;
			break;
			case GModel_DBField.TYPE_BOOL:
				sqlType = `TINYINT(1)`;
			break;
			case GModel_DBField.TYPE_JSON: sqlType=`TEXT`;     break;
			case GModel_DBField.TYPE_DT:   sqlType=`DATETIME`; break;
			case GModel_DBField.TYPE_D:    sqlType=`DATE`;     break;
			case GModel_DBField.TYPE_T:    sqlType=`TIME`;     break;
			case GModel_DBField.TYPE_ENUM:
				sqlType = `ENUM('${enumTags.join("','")}')`;
			break;
			case GModel_DBField.TYPE_STRING:
				sqlType  = !empty(maxLength) ? `VARCHAR(${maxLength})` : `TEXT`;
				sqlType += GFile_SQL.COLLATION;
			break;
			case GModel_DBField.TYPE_PHONE:
				maxLength = !empty(maxLength) ? maxLength : GModel_DBField.DEFAULT_MAX_LENGTH_PHONE;
				sqlType   = `VARCHAR(${maxLength})${GFile_SQL.COLLATION}`;
			break;
			case GModel_DBField.TYPE_EMAIL:
				maxLength = !empty(maxLength) ? maxLength : GModel_DBField.DEFAULT_MAX_LENGTH_EMAIL;
				sqlType   = `VARCHAR(${maxLength})${GFile_SQL.COLLATION}`;
			break;
			case GModel_DBField.TYPE_PWD:
				maxLength = !empty(maxLength) ? maxLength : GModel_DBField.DEFAULT_MAX_LENGTH_PWD;
				sqlType   = `VARCHAR(${maxLength})${GFile_SQL.COLLATION}`;
			break;
			case GModel_DBField.TYPE_CUSTOM:
				sqlType  = !empty(maxLength) ? `VARCHAR(${maxLength})` : `TEXT`;
				sqlType += GFile_SQL.COLLATION;
			break;
			default: B_REST_Utils.throwEx(`Unknown type "${type}"`); break;
		}
		
		let definition = `\`${fieldName}\` ${sqlType} ${isNullable?"NULL":"NOT NULL"}`;
		
		if (hasDefaultVal)
		{
			if      (defaultVal===null)  { defaultVal="NULL"; }
			else if (defaultVal===false) { defaultVal=0;      }
			else if (defaultVal===true)  { defaultVal=1;      }
			else                         { defaultVal=`"${GFile_SQL._escapeDQuotes(defaultVal)}"`; }
			
			definition += ` DEFAULT ${defaultVal}`;
		}
		
		if (isAutoInc) { definition += ` AUTO_INCREMENT`; }
		
		if (comments) { definition += ` COMMENT "${GFile_SQL._escapeDQuotes(comments)}"`; }
		
		return definition;
	}
		static _escapeDQuotes(val) { return val.replaceAll('"', '""'); }
	
	
	_abstract_output()
	{
		const lines = [];
		
		for (const loop_pk of this._model.pks)
		{
			const loop_maxLength = loop_pk.type==="string" ? 2 : null; //NOTE: Not good, assumes we're trying to build a loc table like "fk-lang"
			
			lines.push(this.output_field(loop_pk.fieldName, loop_pk.type, false, false, null, null, loop_maxLength, null, null, this._model.isAutoInc));
		}
		
		for (const loop_dbField of this._model.dbFields)
		{
			const loop_isNullable    = loop_dbField.reqType_isOptNULL;
			const loop_hasDefaultVal = !loop_dbField.reqType_isReq;
			let   loop_defaultVal    = null;
			const loop_comments      = loop_dbField.comments;
			const loop_maxLength     = loop_dbField.max;
			const loop_decimals      = loop_dbField.decimals;
			const loop_enumTags      = loop_dbField.type_isEnum ? loop_dbField.enumVals.map(loop_enumMember=>loop_enumMember.tag) : null;
			
			if (loop_dbField.reqType_isOptOther)
			{
				if      (loop_dbField.type_isBool) { loop_defaultVal = !!loop_dbField.optionalVal;                                   }
				else if (loop_dbField.type_isEnum) { loop_defaultVal = loop_dbField.optionalVal?.tag ?? null;                        }
				else                               { loop_defaultVal = !empty(loop_dbField.optionalVal)?loop_dbField.optionalVal:""; }
			}
			
			lines.push(this.output_field(loop_dbField.fieldName, loop_dbField.type, loop_isNullable,loop_hasDefaultVal,loop_defaultVal,loop_comments,loop_maxLength,loop_decimals,loop_enumTags));
		}
		
		for (const loop_lookupField of this._model.lookupFields)
		{
			lines.push(this.output_field(loop_lookupField.fkFieldName, GModel_DBField.TYPE_INT, !loop_lookupField.reqType_isReq, false, null, loop_lookupField.comments));
		}
		
		if (this._model.hasCreatedDT) { lines.push(`\`createdDT\` TIMESTAMP DEFAULT CURRENT_TIMESTAMP NOT NULL`); }
		if (this._model.hasUpdatedDT) { lines.push(`\`updatedDT\` DATETIME COMMENT "Set on create & updates"`);   }
		if (this._model.hasSoftDel)   { lines.push(`\`deletedDT\` DATETIME NULL DEFAULT NULL`);                   }
		
		//PK index
		{
			const pkFieldNames = this._model.pks.map(loop_field=>loop_field.fieldName);
			
			lines.push(`PRIMARY KEY \`primary\` (\`${pkFieldNames.join("\`,\`")}\`)`);
		}
		
		for (const loop_index of this._model.indexes)
		{
			const loop_fieldNames = loop_index.fields.map(loop_field=>loop_field.fkFieldName||loop_field.fieldName);
			const loop_keyName    = loop_fieldNames.join("_");
			
			lines.push(`${loop_index.isUnique?"UNIQUE":"INDEX"} \`${loop_keyName}\` (\`${loop_fieldNames.join("\`,\`")}\`)`);
		}
		
		return `CREATE TABLE \`${this._model.tableName}\` (\n\t${lines.join(",\n\t")}\n) ENGINE = InnoDB`;
	}
};
