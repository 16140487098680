
import B_REST_Utils       from "../../classes/B_REST_Utils.js";
import B_REST_VueApp_base from "./B_REST_VueApp_base.js";



/*
Allows implementing multiple things that would be redundant to code in all components:
	-$bREST.t_x() loc funcs w a given base locPath, wo having to re-code loc funcs in all components, w diff base loc path
	-Helper for component's name, alias of $options.name
	-More to come later maybe, ex error management / notifs..
Usage ex:
	MyComponent.vue:
		export default {
			name: "myComponent",
			mixins: [
				B_REST_VueApp_CreateCoreMixin({
					coreAltBaseLocPath: "app.someCoreFeatureNode", //Only if we want to use t_alt()
				}),
			],
			methods: {
				test()
				{
					this.t("some.thing");     //Instead of doing this.$bREST.t_custom("components.myComponent.some.thing")
					this.t_alt("some.thing"); //Instead of doing this.$bREST.t_custom_alt("components.myComponent.some.thing", "app.someCoreFeatureNode.some.thing")
				},
			},
		};
	<lang>.json:
		{
			components: {
				myComponent: {
					some: {thing:"hey"}
				}
			}
		}
IMPORTANT:
	-Must define component's name w the "name" Vue prop
	-If we want to use t_alt(), we must define {coreAltBaseLocPath} in the mixin options, and custom + core loc sub trees struct must be identical
		In the ex above, we'd need to have identical sub trees like:
			/core/<lang>.js:
				app.someCoreFeatureNode.some.thing
			/custom/<lang>.js:
				components.myComponent.some.thing
NOTE:
	-If multiple components should reuse the same base locPath, then they could have the same component name, or we could later implement mixin options for a baseLocPath
*/

export default function B_REST_VueApp_CreateCoreMixin(mixinOptions={})
{
	mixinOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions, {
		coreAltBaseLocPath: {accept:[String], required:false}, //For if we use t_alt()
	}, "Mixin");
	
	return {
		created()
		{
			if (!this.$options.name) { B_REST_Utils.throwEx(`Component's name must be defined to use bREST core mixin`,this); }
		},
		computed: {
			componentName() { return this.$options.name; },
			t_baseLocPath() { return `components.${this.$options.name}`; }, //NOTE: We do repetitive code here, but it's just to reduce overhead
		},
		methods: {
			//For these methods docs, check the funcs w the same name in B_REST_App_base
			t(subLocPath, details=null, lang=null)
			{
				//NOTE: We do repetitive code here, but it's just to reduce overhead
				const customLocPath = `components.${this.$options.name}.${subLocPath}`;
				return B_REST_VueApp_base.instance.t_custom(customLocPath, details, lang);
			},
			t_alt(subLocPath, details=null, lang=null)
			{
				if (!mixinOptions.coreAltBaseLocPath) { B_REST_Utils.throwEx(`To use bREST core mixin's t_alt(), must define mixin's coreAltBaseLocPath prop`,this); }
				
				//NOTE: We do repetitive code here, but it's just to reduce overhead
				const customLocPath  = `components.${this.$options.name}.${subLocPath}`;
				const coreAltLocPath = `${mixinOptions.coreAltBaseLocPath}.${subLocPath}`;
				return B_REST_VueApp_base.instance.t_custom_alt(customLocPath, coreAltLocPath, details, lang);
			},
		},
	};
};
