<template>
	
	<div :id="id" class="br-field-db" @[final_tooltip?`mouseover`:null]="ifTooltip_show=true" @[final_tooltip?`mouseleave`:null]="ifTooltip_show=false"> <!-- Req wrapper for tooltips -->
		
		<!-- For radio group, we're stuck because it doesn't have a label prop, so we need to make a fork -->
		<div v-if="final_componentName==='v-radio-group'" class="br-field-db-component text-uppercase" style="position:relative;">
			
			<label v-if="final_label" class="br-field-db-component--radio-group--label v-label" :class="`${ifRadioGroup_theme} ${final_class_isDirty}`">{{ final_label }}</label>
			<v-radio-group :value="final_field_value"
			               :error-messages="final_errorMsgs"
			               :class="final_class_isDirty"
			               class="br-field-db-component--radio-group--radio"
			               :row="final_ifRadioGroup_isHorizontal"
			               :readonly="final_readonly"
			               :disabled="final_disabled"
			               v-bind="$attrs"
				           @[final_field?`change`:null]="on_change"
			>
    
								<!-- WARNING: Don't do v-on="$listeners", or it will hide our own $emit() --->
								<!-- README STRANGE @[cond?`eventName`:null]="eventHandler" syntax: https://stackoverflow.com/questions/48042274/conditional-event-binding-vuejs -->
				<v-radio v-for="(loop_item,loop_item_idx) in final_items_usable" :key="loop_item_idx" :value="loop_item.key" :label="loop_item.label" class="text-uppercase" />
			</v-radio-group>
		</div>
		
		<component v-else
		           :is="final_componentName"
		           :value="final_field_value"
		           :label="final_label"
		           :placeholder="final_placeholder"
		           :prefix="final_prefix"
		           :suffix="final_suffix"
		           :clearable="final_clearable"
		           :error-messages="final_errorMsgs"
		           :loading="final_loading"
		           class="br-field-db-component text-uppercase"
		           :class="`${final_class_isDirty} ${final_class_usesPicker}`"
		           
		           :min="final_min"
		           :max="final_max"
		           :counter="final_counter"
		           :step="final_step"
		           :readonly="final_readonly"
		           :disabled="final_disabled"
		           
		           :items="final_items"
		           item-value="key"
		           item-text="label"
		           :multiple="final_multiple"
		           :chips="final_chips"
		           :deletable-chips="final_chips && final_clearable"
		           :filter="ifAutocomplete_filterFunc"
		           
		           :type="final_ifInputOrDatePicker_type"
		           :true-value="trueValue"
		           :false-value="falseValue"
		           :input-value="final_ifSwitch_inputValue"
		           :rows="final_ifTextarea_lineCount"
		           :row="final_ifRadioGroup_isHorizontal"
		           :tick-labels="final_ifSlider_tickLabels"
		           :ticks="final_ifSlider_ticks"
		           :tick-size="final_ifSlider_tickSize"
		           :thumb-label="final_ifSlider_thumbLabel"
		           :use-seconds="final_ifTimePicker_useSeconds"
				   auto-select-first
		           
		           v-bind="$attrs"
		           @[final_field&&!lazy?`input`:null]="on_input"
		           @[final_field?`change`:null]="on_change"
		           @[final_field?`click:clear`:null]="on_clear_click"
		>
						<!-- WARNING: Don't do v-on="$listeners", or it will hide our own $emit() --->
						<!-- README STRANGE @[cond?`eventName`:null]="eventHandler" syntax: https://stackoverflow.com/questions/48042274/conditional-event-binding-vuejs -->
			
			<!-- Prepend & append slots -->
				<template v-if="final_slotConfig_prepend" #prepend>
					<slot v-if="final_slotConfig_prepend.useSlot" name="prepend" />
					<v-btn v-else-if="final_slotConfig_prepend.click" icon @click="final_slotConfig_prepend.click()">
						<v-icon :color="final_slotConfig_prepend.color" v-text="final_slotConfig_prepend.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_prepend.color" v-text="final_slotConfig_prepend.icon" />
				</template>
				
				<template v-if="final_slotConfig_prependInner" #prepend-inner> 
					<slot v-if="final_slotConfig_prependInner.useSlot" name="prepend-inner" />
					<v-btn v-else-if="final_slotConfig_prependInner.click" icon @click="final_slotConfig_prependInner.click()">
						<v-icon :color="final_slotConfig_prependInner.color" v-text="final_slotConfig_prependInner.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_prependInner.color" v-text="final_slotConfig_prependInner.icon" />
				</template>
				
				<template v-if="final_slotConfig_append" #append> 
					<slot v-if="final_slotConfig_append.useSlot" name="append" />
					<v-btn v-else-if="final_slotConfig_append.click" icon @click="final_slotConfig_append.click()">
						<v-icon :color="final_slotConfig_append.color" v-text="final_slotConfig_append.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_append.color" v-text="final_slotConfig_append.icon" />
				</template>
				
				<template v-if="final_slotConfig_appendOuter" #append-outer>
					<slot v-if="final_slotConfig_appendOuter.useSlot" name="append-outer" />
					<v-btn v-else-if="final_slotConfig_appendOuter.click" icon @click="final_slotConfig_appendOuter.click()">
						<v-icon :color="final_slotConfig_appendOuter.color" v-text="final_slotConfig_appendOuter.icon" />
					</v-btn>
					<v-icon v-else :color="final_slotConfig_appendOuter.color" v-text="final_slotConfig_appendOuter.icon" />
				</template>
			
			<!-- Progress bar (for pwd) / loader -->
				<template v-if="!!$slots['progress'] || final_ifPwd_showProgress" #progress>
					<slot              v-if="!!$slots['progress']" name="progress" />
					<v-progress-linear v-else-if="final_ifPwd_showProgress" :color="ifPwd.strengthColor" :value="ifPwd.strengthValue" absolute />
				</template>
			
			<!-- NOTE: For slots like "message" that pass down data, we'll have to use $scopedSlots['message'] instead of $slots['message'], and transfer with <slot name="message" v-bind="stuff" /> -->
		</component>
		
		<!-- If we need a tooltip -->
		<div v-if="final_tooltip" class="br-field-db-tooltip--positionner-1">
			<div class="br-field-db-tooltip--positionner-2">
				<div class="br-field-db-tooltip--positionner-3">
					<v-scale-transition> <div v-show="ifTooltip_show" class="br-field-db-tooltip--style" v-text="final_tooltip" /> </v-scale-transition>
				</div>
			</div>
		</div>
		
	</div>
	
</template>

<script>
	
	import { B_REST_FieldDescriptors, B_REST_Utils, B_REST_Model, B_REST_ModelFields, B_REST_ModelList } from "../../../../classes";
	const FieldDescriptor_DB   = B_REST_FieldDescriptors.DB;
	const B_REST_ModelField_DB = B_REST_ModelFields.DB;
	
	import { VTextField, VTextarea, VAutocomplete, VSelect, VRadioGroup, VSlider, VSwitch, VCheckbox, VDatePicker, VTimePicker, VColorPicker } from "vuetify/lib";
	
	
	const DEFAULT_LAZY = false;
	
	const SLIDER_TICK_SIZE = 4;
	
	const PWD_STRENGHT_COLORS = ["error","error", "warning", "success","success"];
	
	export const AS_AUTO         = "auto";
	export const AS_INPUT        = "input"; //Meaning <v-text-field>
	export const AS_TEXTAREA     = "textarea";
	export const AS_AUTOCOMPLETE = "autocomplete";
	export const AS_SELECT       = "select";
	export const AS_RADIO_GROUP  = "radioGroup";
	export const AS_SLIDER       = "slider";
	export const AS_SWITCH       = "switch";
	export const AS_CHECKBOX     = "checkbox";
	export const AS_DATE_PICKER  = "datePicker";  //WARNING: Not as popup + check notes in final_componentName()
	export const AS_MONTH_PICKER = "monthPicker"; //WARNING: Not as popup + check notes in final_componentName()
	export const AS_TIME_PICKER  = "timePicker";  //WARNING: Not as popup + check notes in final_componentName()
	export const AS_COLOR_PICKER = "colorPicker"; //WARNING: Not as popup + check notes in final_componentName()
	export const AS_X_CONSTS = [
		AS_AUTO,
		AS_INPUT, //Meaning <v-text-field>
		AS_TEXTAREA,
		AS_AUTOCOMPLETE,
		AS_SELECT,
		AS_RADIO_GROUP,
		AS_SLIDER,
		AS_SWITCH,
		AS_CHECKBOX,
		AS_DATE_PICKER,
		AS_MONTH_PICKER,
		AS_TIME_PICKER,
		AS_COLOR_PICKER,
	];
	
	
	const AS_X_COMPONENT_MAP = {};
		//Doesn't include AS_AUTO
		AS_X_COMPONENT_MAP[AS_INPUT]        = "v-text-field";
		AS_X_COMPONENT_MAP[AS_TEXTAREA]     = "v-textarea";
		AS_X_COMPONENT_MAP[AS_AUTOCOMPLETE] = "v-autocomplete";
		AS_X_COMPONENT_MAP[AS_SELECT]       = "v-select";
		AS_X_COMPONENT_MAP[AS_RADIO_GROUP]  = "v-radio-group";
		AS_X_COMPONENT_MAP[AS_SLIDER]       = "v-slider";
		AS_X_COMPONENT_MAP[AS_SWITCH]       = "v-switch";
		AS_X_COMPONENT_MAP[AS_CHECKBOX]     = "v-checkbox";
		AS_X_COMPONENT_MAP[AS_DATE_PICKER]  = "v-date-picker";
		AS_X_COMPONENT_MAP[AS_MONTH_PICKER] = "v-date-picker";
		AS_X_COMPONENT_MAP[AS_TIME_PICKER]  = "v-time-picker";
		AS_X_COMPONENT_MAP[AS_COLOR_PICKER] = "v-color-picker";
	
	const COMPONENTS_NEEDING_ITEMS = [
		//NOTE: To make code easier, provide both prog friendly names + actual component names
		AS_AUTOCOMPLETE, AS_X_COMPONENT_MAP[AS_AUTOCOMPLETE],
		AS_SELECT,       AS_X_COMPONENT_MAP[AS_SELECT],
		AS_RADIO_GROUP,  AS_X_COMPONENT_MAP[AS_RADIO_GROUP],
		AS_SLIDER,       AS_X_COMPONENT_MAP[AS_SLIDER],
	];
	
	export const COMPONENTS_MULTIPLEABLE = [
		//NOTE: To make code easier, provide both prog friendly names + actual component names
		AS_AUTO,
		AS_AUTOCOMPLETE, AS_X_COMPONENT_MAP[AS_AUTOCOMPLETE],
		AS_SELECT,       AS_X_COMPONENT_MAP[AS_SELECT],
	 // AS_CHECKBOX,     AS_X_COMPONENT_MAP[AS_CHECKBOX], //Check todo below
	 // Also, if it's being used as a picker, we'll be using a <v-text-field>, but we won't need to validate that case
	];
	
	const PICKER_COMPONENT_SINGLE   = "v-text-field";
	const PICKER_COMPONENT_MULTIPLE = "v-select";
		B_REST_Utils.console_todo([
			`Fix prob w textarea having strange border around the field`,
			`To support multiple checkboxes, we'd need multiple-array & multiple-obj, so we either select :items like ["a","c"] vs {a:true, b:false, c:true}`,
			`Allow using a <br-field-db multiple> to select contacts, that'd be lookups to contact_fk in a sub model list like Model_Client{pk,clientcontacts} with Model_ClientContact{pk,client_fk,contact_fk}`,
			`Allow not passing a model & fieldNamePath, where we'd just use a v-model, and inside the component create fake standalone B_REST_FieldDescriptor_DB & B_REST_ModelField_DB. Would be useful for uniformizing look of other controls not related to models and maybe needing a list of items anyways`,
		]);
	
	export const PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT = 5;
	
	const SLIDER_NUMERIC_ALWAYS_SHOW_THUMB_LABEL = true; //true:only while dragging, "always":even when not dragging, but might hide elements in the UI above a bit, so true seems better
	const SLIDER_MAX_SHOWN_TICK_COUNT            = 8;    //Actually it should maybe depend on an arr.join().length to simulate them being too close to each other, but it's meaningless if we don't know the input's width
	
	const RADIO_GROUP_ORIENTATION_AUTO       = "auto"; //Vertical in mobile and horizontal in desktop
	const RADIO_GROUP_ORIENTATION_HORIZONTAL = "horizontal"
	const RADIO_GROUP_ORIENTATION_VERTICAL   = "vertical";
	const RADIO_GROUP_ALL_CONSTS = [
		RADIO_GROUP_ORIENTATION_AUTO,
		RADIO_GROUP_ORIENTATION_HORIZONTAL,
		RADIO_GROUP_ORIENTATION_VERTICAL,
	];
	
	const TO_LABEL_FLAT_SEARCH_SUFFIX = "@flatSearch"; //To yield stuff like <br-field field="firstName">@flatSearch
	
	
	
	
	
	
	export default {
		props: {
			/*
			Required props; either field as B_REST_ModelField_DB alone, or a combination of model.select(<fieldNamePath>), where field is used as the fieldNamePath. Can be defined later
			Check _checkReinit_field() docs for more info
			*/
			field:                 {type:undefined,    required:false, default:null, validator(val){ return val===null || val instanceof B_REST_ModelField_DB || B_REST_Utils.string_is(val); }}, //Either NULL, an instance of B_REST_ModelField_DB, or a fieldNamePath for model.select(<fieldNamePath>)
			model:                 {type:B_REST_Model, required:false, default:null}, //Either NULL or a B_REST_Model instance, to get the final field via model.select(<fieldNamePath>)
			//For if we want to have choices
			items:                 {type:undefined,    required:false, default:null, validator:validator_items}, //Can be either an arr, ModelList, or a shared list tag (Check B_REST_VueApp_base::sharedLists_x()). For arr of objs, default is to use {key,label,flatSearch}, but prop names can be specified
			itemsKeyName:          {type:String,       required:false, default:null},  //When not set, will default to "key".                                                    Check final_items() for more info
			itemsLabelName:        {type:undefined,    required:false, default:null},  //To completely remove feature, set to false. When not set, will default to "label".      Check final_items() for more info
			itemsFlatSearchName:   {type:undefined,    required:false, default:null},  //To completely remove feature, set to false. When not set, will default to "flatSearch". Check final_items() for more info
			multiple:              {type:Boolean,      required:false, default:false},
			//Other stuff
			id:                    {type:String,       required:false, default:null},  //Intercept this, because ex if we want to use this to do Vuetify.goTo() and we're using a <v-slider>, it won't work if it ends up in its hidden input
			label:                 {type:String,       required:false, default:null},  //Otherwise uses auto label (we need this in case against user type the label isn't the same
			noLabel:               {type:Boolean,      required:false, default:false}, //Sometimes, we don't want to see the auto label
			counter:               {type:Boolean,      required:false, default:false}, //By default, we don't show chars counters in final_counter(), because it's annoying when we have many fields
			disabled:              {type:Boolean,      required:false, default:null},
			readonly:              {type:Boolean,      required:false, default:null},
			placeholder:           {type:String,       required:false, default:null},
			prefix:                {type:String,       required:false, default:null}, //Similar to the placeholder, but pads a text to the left
			suffix:                {type:String,       required:false, default:null}, //Similar to the placeholder, but pads a text to the right
			tooltip:               {type:String,       required:false, default:null},
			lazy:                  {type:Boolean,      required:false, default:DEFAULT_LAZY}, //Whether we want input val to change "oninput" or "onchange", to reduce reactivity in input mode
			as:                    {type:String,       required:false, default:AS_AUTO, validator:validator_as},
			min:                   {type:Number,       required:false, default:null}, //For numerical <v-text-field> & <v-slider>, and for date/time
			step:                  {type:Number,       required:false, default:null}, //For numerical <v-text-field> & <v-slider>, and for date/time
			max:                   {type:Number,       required:false, default:null}, //For numerical <v-text-field> & <v-slider>, and for date/time
			pwdStrengthBar:        {type:Boolean,      required:false, default:true}, //For pwd, do we show the strength progress bar ?
			textareaLineCount:     {type:Number,       required:false, default:5},
			radioGroupOrientation: {type:String,       required:false, default:RADIO_GROUP_ORIENTATION_AUTO, validator:validator_radioGroupOrientation},
			trueValue:             {type:undefined,    required:false, default:true},  //For <v-checkbox> & <v-switch>
			falseValue:            {type:undefined,    required:false, default:false}, //For <v-checkbox> & <v-switch>
			picker:                {type:String,       required:false, default:null},  //If to be used as a model picker, must point on a valid B_REST_Vuetify_PickerDef name that we can refer to via B_REST_VueApp_base::pickers_prompt_x(). WARNING: If we rename this prop, has impacts in B_REST_Vuetify_GenericList_Filter::constructor()
			//Icons & their colors. Rather than annoying prepend|prepend-inner|append|append-outer, just go 1,2,3,4, and also pipe optionnal color. Ex "mdi-check|success" or animated "mdi-loading mdi-spin|error"
			icon1:                 {type:String,       required:false, default:null},
			icon2:                 {type:String,       required:false, default:null},
			icon3:                 {type:String,       required:false, default:null},
			icon4:                 {type:String,       required:false, default:null},
			//Anything else that we try to pass, will fall inside v-bind="$attrs"
		},
		data()
		{
			return {
				final_field:                 null, //NULL, or actual B_REST_ModelField_base instance to use
				ifPwd:                       null, //NULL, or as {revealed:false, strengthColor:null, strengthValue:0}
				ifTooltip_show:              false,
				ifPicker_single_label:       null, //When we're using a picker in !multiple mode, we'll put the label of the model behind the FK we currently point to, inside the readonly <v-text-field>
				ifPicker_multiple_fakeItems: null, //When in multiple mode though, we'll need to create fake <v-select> items in order for these to appear
				ifRadioGroup_theme:          null, //Either theme--light or theme--dark
			};
		},
		watch: {
			field()  { this._checkReinit_field();  },
			model()  { this._checkReinit_field();  },
			picker() { this._checkReinit_picker(); },
		},
		beforeMount() { this._checkReinit_field(); },
		mounted()
		{
			if (this.final_componentName==="v-radio-group")
			{
				const theme = this.$bREST.dom_getClosestTheme_component(this);
				this.ifRadioGroup_theme = `theme--${theme}`;
			}
		},
		computed: {
			uid() { return this._uid; }, //All Vue component instances have a unique id
			//Field & field descriptor related
			final_field_descriptor() { return this.final_field?.fieldDescriptor;                },
			final_field_name()       { return this.final_field_descriptor?.name || "[noname]";  },
			final_field_quotedName() { return `<br-field-db field="${this.final_field_name}">`; }, //Just for _throwField() and B_REST_Model::toLabel()
			final_field_isLookup()   { return this.final_field_descriptor?.lookup_is;           },
			final_field_isMutable()
			{
				if (!this.final_field || !this.final_field.isMutable) { return false; }
				
				return !(this.final_field_descriptor.isPKField && this.final_field_descriptor.descriptor.isAutoInc);
			},
			//Gen stuff
			final_counter()          { return this.counter && !!(this.final_min||this.final_max);                          }, //Used for <v-text-field> & <v-textarea>
			final_readonly()         { return this.readonly || this.final_picker_asText || !this.final_field_isMutable;    }, //Can put focus, but can't edit. For pickers, we opt for this rather than disabled, otherwise btns aren't usable
			final_disabled()         { return this.disabled;                                                               }, //Can't even focus, and turns grey
			final_picker_needs()     { return this.picker && !this.final_disabled && this.final_field_isMutable;           },
			final_picker_asText()    { return this.picker && !this.multiple;                                               },
			final_picker_asChips()   { return this.picker &&  this.multiple;                                               },
			final_class_isDirty()    { return this.final_field?.userTouch_has ? "br-field-db-component--is-dirty"    : ""; },
			final_class_usesPicker() { return this.final_picker_needs         ? "br-field-db-component--uses-picker" : ""; },
			final_tooltip()          { return this.tooltip;                                                                }, //We could change later to put placeholder etc depending on final_componentName
			final_placeholder()      { return this.placeholder || this.final_field_descriptor?.placeholder;                }, //Can yield undefined
			final_prefix()           { return this.prefix;                                                                 },
			final_suffix()           { return this.suffix;                                                                 },
			final_clearable()
			{
				const fieldDescriptor = this.final_field_descriptor;
				if (fieldDescriptor)
				{
					if (fieldDescriptor.isPKField || fieldDescriptor.isRequired) { return false; } //NOTE: For PK fields, we should use them only when it's non AUTO_INC and prolly being a multi-field PK
				}
				if (this.final_disabled) { return false; }
				
				return this.final_picker_needs || !this.final_readonly; //NOTE: For final_picker_needs, we "fake" that it's readonly. Check final_readonly notes
			},
			//Used for <v-text-field>, <v-textarea>, <v-slider>, <v-date-picker> & <v-time-picker>
			final_min() { return this._final_minMax("min"); },
			//Used for <v-text-field>, <v-textarea>, <v-slider>, <v-date-picker> & <v-time-picker>
			final_max() { return this._final_minMax("max"); },
			/*
			Used for numerical <v-text-field> & <v-slider>, and for date/time. In ints, *tries* to disallow floats
			BUG:
				Even if we put a parseFloat() around this.step when defined, it'll complain that it expected a number,
				prolly because v-bind="$attrs" goes first before it can be evaluated. If we put a debugger here, it breaks before...
			*/
			final_step()
			{
				if (!this.final_field) { return null; }
				
				if (this.final_componentName==="v-slider" && this.final_items_usable.length>0)
				{
					if (this.step) { this._throwField(`Shouldn't specify step prop when we use <v-slider> with items / enum members`); }
					return 1;
				}
				
				if (this.step) { return parseFloat(this.step); } //Check note above
				
				switch (this.final_field_descriptor.type)
				{
					case FieldDescriptor_DB.TYPE_INT:     return 1;
					case FieldDescriptor_DB.TYPE_DECIMAL: return 1/Math.pow(10,this.final_field_descriptor.decimals);
					case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: return FieldDescriptor_DB.DT_CARE_ABOUT_SECONDS ? 1 : 60;
					//NOTE: No case for TYPE_D, as they don't display time
					case FieldDescriptor_DB.TYPE_T: return FieldDescriptor_DB.T_CARE_ABOUT_SECONDS  ? 1 : 60;
				}
				
				return null;
			},
			//NOTE: If it's a lookup, we will NOT use the field's val, and instead show the label, but mods won't be possible
			final_field_value()
			{
				if (!this.final_field) { return null; }
				if (this.picker)       { return this.multiple ? this.final_field.val : this.ifPicker_single_label; }
				
				const val = this.final_field.val;
				
				//For <v-slider> on final_items (not number ranges), convert selected key to idx. Check notes inside the _final_minMax() method
				if (this.final_componentName==="v-slider" && this.final_items_usable.length>0)
				{
					if (val===null) { return 0; } //When null, we should always point on the 1st item
					
					const idx = this.final_items_usable.findIndex(loop_item => loop_item.key===val);
					if (idx===-1)
					{
						this.$bREST.utils.console_warn(`${this.final_field_quotedName}: Got prob because we've previously selected key "${val}", but it's not in the known items. Fallbacking to "selecting first idx"`);
						return 0; //Don't throw now, otherwise the component won't appear at all
					}
					return idx;
				}
				
				if (val===null) { return null; }
				
				switch (this.final_field_descriptor.type)
				{
					case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: case FieldDescriptor_DB.TYPE_D: case FieldDescriptor_DB.TYPE_T: return this.final_field.dtVal_asHTML5InputVal;
					default: return val;
				}
			},
			//Only display them when we have touches
			final_errorMsgs()
			{
				//NOTE: We also have this.final_field.validation_isValid getter
				
				if (!this.final_field) { return null; }
				
				return this.final_field.userTouch_has ? this.final_field.validation_getErrors(/*detailed*/false) : null;
			},
			//Especially for pwds strength progress bar, or simply for when we don't have the final_field, because at that time we won't even have a label, so let the user know something will happen soon
			final_loading()
			{
				if (this.$bREST.utils.object_hasPropName(this.$attrs,"loading")) { return this.$attrs.loading; }
				
				if (!this.final_field) { return true; }
				
				return this.final_ifPwd_showProgress && this.final_field.userTouch_has ? true : false; //Need this for pwd strength progress bar. Also use ternary to convert nulls to bool, otherwise we'll get an endless spinner
			},
			final_label()
			{
				const debugFieldNamePath = this.$bREST.debug_fieldNamePaths ? `[${this._final_debugFieldNamePath}]` : null;
				
				if (this.noLabel)      { return debugFieldNamePath; }
				if (this.label)        { return debugFieldNamePath ? `${this.label} ${debugFieldNamePath}` : this.label; }
				if (!this.final_field) { return debugFieldNamePath; }
				
				let label = this.final_field.label;
				if (label===null) { return debugFieldNamePath; }
				
				if (!this.final_field_isMutable)            { label  = `🔓 ${label}`;            } //For setOnce things
				if (debugFieldNamePath)                     { label += ` ${debugFieldNamePath}`; }
				if (this.final_field_descriptor.isRequired) { label += " *";                     }
				
				return label;
			},
				//Tries to ret a fieldNamePath for the field
				_final_debugFieldNamePath()
				{
					if (this.field===null) { return "<unnamed>"; }
					if (this.field instanceof B_REST_ModelField_DB) { return this.field.debugFieldNamePath(); }
					return this.field; //If we get here, is a fieldNamePath and we don't have the model yet
				},
			final_slotConfig_prepend()
			{
				const config = this._final_slotConfig_x("prepend", "icon1");
				if (config || !this.ifPwd) { return config; }
				
				return {
					useSlot: false,
					icon:    "mdi-lock",
					color:   null,
					click:   null,
				};
			},
			final_slotConfig_prependInner()
			{
				return this._final_slotConfig_x("prepend-inner", "icon2");
			},
			final_slotConfig_append()
			{
				return this._final_slotConfig_x("append", "icon3");
			},
			final_slotConfig_appendOuter()
			{
				const config = this._final_slotConfig_x("append-outer", "icon4");
				if (config) { return config; }
				
				if (this.final_picker_needs) //False if RO / disabled
				{
					return {
						useSlot: false,
						icon:    "mdi-magnify",
						color:   null,
						click:   async() =>
						{
							const methodName = this.multiple ? "pickers_prompt_multiple" : "pickers_prompt_single";
							const pickerOptions = {
								vBind: {fromLoader:true}, //WARNING: Assumes that the picker is a component that's a der of BrGenericListBase. If not good, could refactor defining pickers in B_REST_VueApp_base constructor, so do the same as for routes like viewTransferProps(vueRouterObj){}
							};
							const selection  = await this.$bREST[methodName](this.picker, pickerOptions);
							
							if (selection) { this._picker_changeVal(this.multiple ? selection : [selection]); }
						},
					};
				}
				
				if (this.ifPwd)
				{
					return {
						useSlot: false,
						icon:    this.ifPwd.revealed ? "mdi-eye-off" : "mdi-eye",
						color:   null,
						click:   () => this.on_pwdEyeReveal_click(),
					};
				}
				
				return null;
			},
			/*
			If it's for an FK bound on a lookup, then we'll always force to use a <v-text-field type="text">
			If we chose AS_AUTO:
				-If we have items / enum members, will either choose <v-autocomplete> or <v-select>, depending on nb of items
				-If it's a TYPE_BOOL, will choose a <v-switch>
				-Otherwise all will fallback to <v-text-field>
			Otherwise, we'll blindly use the one specified:
				-Some funcs will throw errs depending on the type of component used
				-AS_DATE_PICKER shouldn't be used for a date+time field, so will throw with TYPE_DT or TYPE_X_STAMP
				-AS_MONTH_PICKER isn't yet implemented anywhere in frontend/backend, and we should prolly implement a TYPE_D_MONTH or TYPE_MONTH as a DB field, but require to use a VARCHAR
			About pickers (color, date, time, etc):
				-Decided they won't open in popups, since all of them have HTML5 equivalents via <input type="x" />
				-However, they don't show label nor validation err msgs; not sure if it's ok
			*/
			final_componentName()
			{
				if (this.picker) { return this.multiple ? PICKER_COMPONENT_MULTIPLE : PICKER_COMPONENT_SINGLE; }
				
				let componentName = null;
				
				if (this.as!==AS_AUTO)
				{
					if (this.as===AS_DATE_PICKER)
					{
						switch (this.final_field_descriptor?.type)
						{
							case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP:
								this._throwField(`Can only use field as datePicker with TYPE_D; not suitable for TYPE_DT or time stamps, for ex`);
							break;
							//Allow with TYPE_STRING, TYPE_D & TYPE_CUSTOM at least...
						}
					}
					else if (this.as===AS_MONTH_PICKER)
					{
						switch (this.final_field_descriptor?.type)
						{
							case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_D: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP:
								this._throwField(`Should only use monthPicker with TYPE_STRING, TYPE_CUSTOM etc. Also in server we should implement a TYPE_D_MONTH or something`);
							break;
							//Allow with TYPE_STRING & TYPE_CUSTOM at least...
						}
					}
					
					/*
					NOTE: If we want to use either of <v-autocomplete>, <v-select>, <v-radio-group>, <v-slider>, we should also define the items prop,
						but we won't throw even if we don't, in case we get them later
					*/
					componentName = AS_X_COMPONENT_MAP[this.as] || this._throwField(`No matching component when using as "${this.as}"`);
				}
				//NOTE: We add the 2nd clause for enums, that have no "items" but still yield non-empty final_items
				else if (this.items || this.final_items_usable.length>0) { componentName = this.final_items_usable.length>=PREFER_AUTOCOMPLETE_OVER_SELECT_ITEMS_COUNT ? "v-autocomplete" : "v-select"; } 
				else if (this.final_field_descriptor?.type_is_bool)      { componentName = "v-switch";     }
				else                                                     { componentName = "v-text-field"; }
				
				//NOTE: Notice we don't do this check when it has a picker (at the beginning of the func), because having a picker it should be possible
				if (this.multiple && !COMPONENTS_MULTIPLEABLE.includes(componentName)) { this._throwField(`Can't end up using a <${componentName}> when we have the multiple attr on`); }
				
				return componentName;
			},
			/*
			For <v-autocomplete>, <v-select>, <v-radio-group>, <v-slider> (for slider will require being sent as final_ifSlider_tickLabels though)
			Yields an arr of either {key,label,flatSearch,disabled}, {divider:true} or {header:<string>}
			NOTES ON DIVIDER & HEADER:
				Those which we set either divider / header props will NOT render an item, but just a separator that's either a line or grey text,
				so don't include other props for them
			Flat search is for ifAutocomplete_filterFunc(), to do partial search like "Mtl|Montréal|Montreal"...
			The item prop may either contain an arr of such obj, or a ModelList instance.
				If arr:
					Use the items-key-name prop to specify which prop to use for the key field, if it isn't called "key" in the obj.
					Same thing with items-label-name, if the label prop isn't called "label" in the obj.
					Same thing with items-flat-search-name, defaults to "flatSearch"
					Remember we can have props like {disabled, divider, header} as well
				If ModelList:
					Use the items-key-name prop to specify which field (can be a sub field in the model) to use for the key. If not set, will use the 1st PK field
					Same thing with the items-label-name prop, and if not specified, will use the Model_base::toLabel(final_field_quotedName) method of the model
					Same thing with the items-flat-search-name prop, and if not specified, will use the Model_base::toLabel(final_field_quotedName-flatSearch) method of the model
				If enum members:
					We allow defining tag via an arr, in case it's something recurring like yes/no
					If nothing is define, then we check the B_REST_FieldDescriptor_DB_EnumMember vals behind the B_REST_FieldDescriptor_DB
			Note that we expect data to change so the items should be reactive
			WARNINGS:
				-It's not possible to have a NULL key, otherwise we can't make the distinction between having nothing selected vs selecting an item
				-Avoid manually setting .val to a key that's currently disabled
				-To make things simpler, no matter the dynamic prop names we pass, they'll get converted to "key", "label" & "flatSearch"
			*/
			final_items()
			{
				if (this.ifPicker_multiple_fakeItems) { return this.ifPicker_multiple_fakeItems; }
				
				const final_items = [];
				
				const isEnabled_label      = this.itemsLabelName!==false; //NOTE: null or string is OK
				const isEnabled_flatSearch = this.itemsFlatSearchName!==false;
				
				//When it's a shared list tag, it'll either become a B_REST_ModelList or arr
				const items = this.$bREST.utils.string_is(this.items) ? this.$bREST.sharedLists_getSrc(this.items) : this.items;
				
				if (items instanceof B_REST_ModelList)
				{
					const modelList                      = items;
					const ifUsePK_canUsePK               = this.itemsKeyName                               ? null : !modelList.descriptor.isMultiFieldPK;
					const ifUseToLabel_reason            = this.itemsLabelName     ||!isEnabled_label      ? null : this.final_field_quotedName;                                    //Ex <br-field field="firstName">
					const ifUseToLabel_reason_flatSearch = this.itemsFlatSearchName||!isEnabled_flatSearch ? null : `${this.final_field_quotedName}${TO_LABEL_FLAT_SEARCH_SUFFIX}`; //Ex <br-field field="firstName">@flatSearch
					
					for (const loop_model of modelList.models)
					{
						let loop_label      = null;
						let loop_flatSearch = null;
						
						if (isEnabled_label)
						{
							loop_label = this.itemsLabelName ? loop_model.select(this.itemsLabelName).val : loop_model.toLabel(ifUseToLabel_reason);
							if (loop_label===undefined || loop_label===null) { loop_label=""; } //NOTE: We need the undefined checks, because if the toLabel funcs got intermediate custom funcs that don't ret anything, we'll get undefined
						}
						
						if (isEnabled_flatSearch)
						{
							loop_flatSearch = this.itemsFlatSearchName ? loop_model.select(this.itemsFlatSearchName).val : loop_model.toLabel(ifUseToLabel_reason_flatSearch);
							if (loop_flatSearch===undefined || loop_flatSearch===null) { loop_flatSearch=loop_label; } //NOTE: We need the undefined checks, because if the toLabel funcs got intermediate custom funcs that don't ret anything, we'll get undefined
						}
						
						final_items.push({
							key:        this.itemsKeyName ? loop_model.select(this.itemsKeyName).val : (ifUsePK_canUsePK&&loop_model.pk_isSet?loop_model.pk:null),
							label:      loop_label?.toString()         ?? null,
							flatSearch: loop_flatSearch?.toLowerCase() ?? null,
							disabled:   false,
							//NOTE: Don't add divider & header props
						});
					}
				}
				else if (this.$bREST.utils.array_is(items))
				{
					const itemsKeyName        = this.itemsKeyName        || "key";
					const itemsLabelName      = this.itemsLabelName      || "label";
					const itemsFlatSearchName = this.itemsFlatSearchName || "flatSearch";
					
					for (const loop_item of items)
					{
						//Is it a normal item or divider / header ?
						if (this.$bREST.utils.object_hasPropName(loop_item,itemsKeyName))
						{
							const loop_key = loop_item[itemsKeyName];
							
							//Prevent hell
							if (loop_item.divider || loop_item.header) { this._throwField(`Can't put a divider or header directive on a normal item`,loop_item);              }
							if (loop_key===null)                       { this._throwField(`Can't have items with a NULL key val. Check method docs for more info`,loop_item); }
							
							let loop_label      = null;
							let loop_flatSearch = null;
							
							if (isEnabled_label)      { loop_label      = this.$bREST.utils.object_hasPropName(loop_item,itemsLabelName)      ? loop_item[itemsLabelName]      : null; }
							if (isEnabled_flatSearch) { loop_flatSearch = this.$bREST.utils.object_hasPropName(loop_item,itemsFlatSearchName) ? loop_item[itemsFlatSearchName] : null; }
							
							final_items.push({
								key:        loop_key,
								label:      loop_label?.toString()         ?? null,
								flatSearch: loop_flatSearch?.toLowerCase() ?? null,
								disabled:   loop_item.disabled, //May yield undefined
								//NOTE: Don't add divider & header props
							});
						}
						else if (loop_item.divider) { final_items.push({divider:true});            }
						else if (loop_item.header)  { final_items.push({header:loop_item.header}); }
						else { this._throwField(`Didn't know what to do with this item`,loop_item); }
					}
				}
				else if (this.final_field_descriptor?.enum_members) //IMPORTANT: Don't check by type_is_enum, because in multiple mode, we need it to be a type_is_json
				{
					/*
					To prevent trying to calc lots of translation for things that might not actually need to display items in the end
					and causing lots of warning in console, only add the enum members to the items, if we don't know what component we want,
					or if we know it and it can have some.
					IMPORTANT:
						Don't do the following, or we'd get an infinite loop:
							final_items_needs() { return COMPONENTS_NEEDING_ITEMS.includes(this.final_componentName); }
					*/
					
					if (this.as===AS_AUTO || COMPONENTS_NEEDING_ITEMS.includes(this.as))
					{
						for (const loop_enumMember of this.final_field_descriptor.enum_members)
						{
							final_items.push({
								key:        loop_enumMember.tag,
								label:      isEnabled_label ? loop_enumMember.label : null,
								flatSearch: null, //What about isEnabled_flatSearch ?
								disabled:   false,
								//NOTE: Don't add divider & header props
							});
						}
					}
				}
				else if (this.final_field_descriptor?.type_is_bool)
				{
					if (this.final_field_descriptor.isNullable) { final_items.push({key:null,label:this.final_field_descriptor.loc_bool_null}); }
					
					final_items.push({key:true,  label:this.final_field_descriptor.loc_bool_true});
					final_items.push({key:false, label:this.final_field_descriptor.loc_bool_false});
				}
				
				return final_items; //Always ret an arr, otherwise if we figure out we have to use a <v-select>, <v-autocomplete> etc and we don't have the required items prop, Vuetify will throw
			},
			//Like final_items, but removing disabled stuff and UI dividers/headers
			final_items_usable()       { return this.final_items.filter(loop_item => !loop_item.disabled&&!loop_item.divider&&!loop_item.header); },
			final_multiple()           { return this.multiple; },
			final_chips()              { return this.multiple; }, //For now, we want this to be the default behavior for <v-select>, even when it's not for a picker / final_picker_asChips
			final_ifPwd_showProgress() { return this.ifPwd && this.final_field_descriptor?.pwd_evalStrength; },
			final_ifInputOrDatePicker_type()
			{
				if (!this.final_field) { return null; }
				
				if (this.final_componentName==="v-text-field")
				{
					if (this.picker)          { return "text"; }
					if (this.ifPwd?.revealed) { return "text"; }
					
					const fallback = "text"; //We just don't know what to put for these yet
					switch (this.final_field_descriptor.type)
					{
						case FieldDescriptor_DB.TYPE_STRING:  return "text";
						case FieldDescriptor_DB.TYPE_INT:     return "number";
						case FieldDescriptor_DB.TYPE_DECIMAL: return "number";
						case FieldDescriptor_DB.TYPE_BOOL:    return fallback;
						case FieldDescriptor_DB.TYPE_JSON:    return fallback;
						case FieldDescriptor_DB.TYPE_DT:      return "datetime-local";
						case FieldDescriptor_DB.TYPE_D:       return "date";
						case FieldDescriptor_DB.TYPE_T:       return "time";
						case FieldDescriptor_DB.TYPE_C_STAMP: return "datetime-local";
						case FieldDescriptor_DB.TYPE_U_STAMP: return "datetime-local";
						case FieldDescriptor_DB.TYPE_ENUM:    return fallback;
						case FieldDescriptor_DB.TYPE_PHONE:   return "tel";
						case FieldDescriptor_DB.TYPE_EMAIL:   return "email";
						case FieldDescriptor_DB.TYPE_PWD:     return "password";
						case FieldDescriptor_DB.TYPE_CUSTOM:  return fallback;
						default: this._throwField(`Unknown DB field type "${this.final_field_descriptor.type}"`);
					}
				}
				else if (this.final_componentName==="v-date-picker") { return this.as===AS_MONTH_PICKER ? "month" : "date"; }
				
				return null;
			},
			final_ifTextarea_lineCount() { return this.final_componentName==="v-textarea" ? this.textareaLineCount : null; },
			final_ifRadioGroup_isHorizontal()
			{
				if (this.final_componentName!=="v-radio-group") { return null; }
				
				switch (this.radioGroupOrientation)
				{
					case RADIO_GROUP_ORIENTATION_AUTO:       return !this.$bREST.uiBreakpoint.mobile;
					case RADIO_GROUP_ORIENTATION_HORIZONTAL: return true;
					case RADIO_GROUP_ORIENTATION_VERTICAL:   return false;
					default:                                 this._throwField(`Unknown radio group orientation "${this.radioGroupOrientation}"`);
				}
				
				return null;
			},
			//If we don't do this, when it's set to true and we first load the component, it'll appear unchecked...
			final_ifSwitch_inputValue() { return this.final_field_value; },
			final_ifSlider_ticks()    { return this.final_componentName==="v-slider" ? "always"         : null; },
			final_ifSlider_tickSize() { return this.final_componentName==="v-slider" ? SLIDER_TICK_SIZE : null; },
			final_ifSlider_tickLabels()
			{
				if (!this.final_field || this.final_componentName!=="v-slider") { return null; }
				
				let tickLabels = null;
				
				//If we have predefined items, use their labels
				if (this.final_items_usable.length>0)
				{
					tickLabels = this.final_items_usable.map(loop_item=>loop_item.label);
				}
				//Otherwise, we're prolly using it with min/max vals for a TYPE_INT/TYPE_DECIMAL, so just dump all numbers between the interval, if possible
				else
				{
					const min = this.final_min;
					const max = this.final_max;
					if (min===null || max===null || min>=max) { this._throwField(`Can't figure out <v-slider> tick labels because min/max don't make sense: "${min}" vs "${max}"`); }
					const step = this.final_step || 1;
					
					tickLabels = [];
					for (let i=min; i<max; i+=step) { tickLabels.push(i); }
					tickLabels.push(max);
				}
				
				//Use them, depending on if we wouldn't be bloating the screen or not
				return tickLabels.length <= SLIDER_MAX_SHOWN_TICK_COUNT ? tickLabels : undefined;
			},
			final_ifSlider_thumbLabel()
			{
				if (this.final_componentName!=="v-slider") { return null; }
				return this.final_field_descriptor?.type_is_int || this.final_field_descriptor?.type_is_decimal ? SLIDER_NUMERIC_ALWAYS_SHOW_THUMB_LABEL : false; //Instead of "always", we could use true, which only displays while dragging
			},
			final_ifTimePicker_useSeconds() { return FieldDescriptor_DB.T_CARE_ABOUT_SECONDS; },
		},
		methods: {
			_throwField(msg) { this.$bREST.utils.throwEx(`${this.final_field_quotedName}: ${msg}`); },
			/*
			It's possible that the UI is ready before the data is, so this component is meant to work anyways.
			We can init it multiple ways. Check B_REST_Model::select() docs for more info
				<br-field-db :field="someStandaloneModelField_db" />
				<br-field-db :field="someModel.select('someFieldName')" />
				<br-field-db :field="someModel.select('coords.address')" />
				<br-field-db :field="someModel.select('coords').select('address')" />
				<br-field-db :model="someModel" field="someFieldName" />
				<br-field-db :model="someModel" field="coords.address" />
				<br-field-db :model="someModel" field="lines[3].qty" />
				<br-field-db :model="null"      field="someFieldThatWillSpinForAWhile" />
				<br-field-db :field="null" />
			*/
			_checkReinit_field()
			{
				let final_field = null; //The B_REST_ModelField_x we'll use in the end, if possible
				
				if (this.field)
				{
					//Easy way
					if (this.field instanceof B_REST_ModelField_DB)
					{
						if (this.model) { this.final_field=final_field; this._throwField(`Not supposed to pass the B_REST_Model itself, when we already know the field as a B_REST_ModelField_DB instance`); }
						
						final_field = this.field;
					}
					//Else it's already validated as being a string, so we can move on if we know the B_REST_Model in which it resides
					else if (this.model)
					{
						final_field = this.model.select(this.field); //Might throw
						
						//Make sure it didn't point on a field that's not a B_REST_ModelField_DB
						if (!final_field && B_REST_Utils.string_is(this.field)) { this._throwField(`BrModelField got no field / field "${this.field}" not found on that model`); }
						if (!(final_field instanceof B_REST_ModelField_DB)) { this.final_field=final_field; this._throwField(`BrModelField can only work with B_REST_ModelField_DB type fields`); }
					}
					//Else we have a string in field, but we need a model and we don't have one yet
					
					if (final_field)
					{
						//For now, multiple mode is only for TYPE_JSON, and TYPE_JSON can only work in multiple mode
						if (this.multiple!==final_field.fieldDescriptor.type_is_json) { this.final_field=final_field; this._throwField(`If either of being in multiple mode or a DB TYPE_JSON field, both have to happen at the same time`); }
						
						if (this.final_picker_asChips)
						{
							const pks       = final_field.val;
							const fakeItems = []; //Arr of {key,label} for final_items(), otherwise selected items won't appear
							
							if (pks!==null)
							{
								for (const loop_pk of pks)
								{
									fakeItems.push({
										key:   loop_pk,
										label: this._picker_x_getModelLabel(loop_pk,/*model*/null),
									});
								}
							}
							
							this.ifPicker_multiple_fakeItems = fakeItems;
						}
					}
				}
				
				//Set or flush previous pwd related stuff
				this.ifPwd = final_field?.fieldDescriptor.type_is_pwd ? {revealed:false,strengthColor:null,strengthValue:0} : null;
				
				//Then either keep the component not working with a NULL val, or a final ptr to a B_REST_ModelField_DB
				this.final_field = final_field;
				
				//Check to setup picker too
				this._checkReinit_picker();
			},
			//Similar to _checkReinit_field(), so check its docs
			_checkReinit_picker()
			{
				if (!this.picker || !this.final_field) { return; }
				
				if (!this.final_field_isLookup && !this.multiple) { this._throwField(`Can only use the picker prop on lookup fields or in multiple mode`); }
				
				/*
				For lookups (only in single mode):
					When we select a new val, we can always put the lookup's label in "this.ifPicker_label" via _picker_changeVal(),
					but during boot, if we already had a val before, we'll have to check elsewhere for what val it could have
				*/
				if (!this.multiple && this.final_field.isSet)
				{
					const boundModel = this.final_field.lookup_getModel(); //Can ret NULL if not in cached shared models
					this._picker_single_updateLabel(boundModel);
				}
			},
				_picker_changeVal(models=null)
				{
					if (models===null)
					{					
						this.final_field.val             = null;
						this.ifPicker_single_label       = null;
						this.ifPicker_multiple_fakeItems = this.multiple ? [] : null;
					}
					else if (this.multiple)
					{
						const pks       = [];
						const fakeItems = []; //Arr of {key,label} for final_items(), otherwise selected items won't appear
						
						for (const loop_model of models)
						{
							const loop_pk = loop_model.pk;
							
							pks.push(loop_pk);
							fakeItems.push({
								key:   loop_pk,
								label: this._picker_x_getModelLabel(loop_pk,loop_model)
							});
						}
						
						//NOTE: The order of ops is important
						this.ifPicker_multiple_fakeItems = fakeItems;
						this.final_field.val             = pks;
					}
					else
					{
						const model = models[0];
						
						//NOTE: The order of ops is important
						this.final_field.val = model.pk;
						this._picker_single_updateLabel(model);
					}
					
					this.final_field.userTouch_toggle(true);
				},
				_picker_single_updateLabel(model=null)
				{
					const pk = this.final_field.val; //Don't take the one of the passed model, because we don't necessarily have it
					
					this.ifPicker_single_label = pk ? this._picker_x_getModelLabel(pk,model) : null; //NOTE: _picker_x_getModelLabel() works even if model is NULL
				},
				_picker_x_getModelLabel(pk, model=null)
				{
					let toLabel = null;
					
					if (model)
					{
						const reason = `${this.final_field_quotedName}@picker`; //WARNING: If we want to change this, has impacts in B_REST_Model::toLabel()
						
						toLabel = model.toLabel(reason);
					}
					
					return toLabel===null ? `#${pk}` : `${toLabel} - #${pk}`; //If we don't know, at least display its PK
				},
			//Check usages in final_slotConfig_x() computeds
			_final_slotConfig_x(slotName, iconPropName)
			{
				if (!!this.$slots[slotName]) { return {useSlot:true}; }
				
				const iconProp = this[iconPropName];
				if (!iconProp) { return null; }
				
				const parts      = iconProp.split("|");
				const clickEvent = this.$listeners[`click:${iconPropName}`]; //Ex "click:icon3"
				return {
					useSlot: false,
					icon:    parts[0],
					color:   parts.length===2 ? parts[1] : null,
					click:   clickEvent || null,
				};
			},
			/*
			Behavior depends on type / component we want to use:
				-Can mean number range
				-Can mean string length range
				-Can mean date/time range (not yet supported)
				-Can mean v-slider range span, either based on the actual passed min/max, or final_items count (not keys related, since there could be gaps and not being numeric)
			*/
			_final_minMax(which)
			{
				if (this[which]!==null) { return this[which]; }
				
				if (!this.final_field) { return null; }
				
				const val = this.final_field[which]!==null ? this.final_field[which] : this.final_field_descriptor[which];
				if (val!==null)
				{
					if (this.final_componentName==="v-slider" && this.final_items_usable.length>0)
					{
						this._throwField(`When we want to display as <v-slider> with items / enum members, we must not pass min/max props`);
					}
					
					switch (this.final_field_descriptor.type)
					{
						case FieldDescriptor_DB.TYPE_DT: case FieldDescriptor_DB.TYPE_C_STAMP: case FieldDescriptor_DB.TYPE_U_STAMP: case FieldDescriptor_DB.TYPE_D: case FieldDescriptor_DB.TYPE_T:
							this._throwField(`Not yet handling min/max for TYPE_D/T. Has to work when AS_INPUT + AS_DATE/MONTH/TIME_PICKER`);
						break;
						default: return val;
					}
				}
				//For sliders, we just want to know how many items we have and transpose idx with actual keys
				else if (this.final_componentName==="v-slider")
				{
					if (this.final_items_usable.length===0) { this._throwField(`We have a prob because we want to use <v-slider> but we provided no min/max and we don't have items / enum members to base range on`); }
					
					return which==="min" ? 0 : this.final_items_usable.length-1; //NOTE: We don't do Math.min/max(...arr); not keys related, since there could be gaps and not being numeric
				}
				
				return null;
			},
			//Helper for flat search
			ifAutocomplete_filterFunc(loop_item, search, unused_loop_item_text)
			{
				//Skip dividers & headers
				if (loop_item.divider || loop_item.header) { return false; }
				
				search = search.toLowerCase();
				
				if (loop_item.label!==null && loop_item.label.toLowerCase().indexOf(search)!==-1) { return true; }
				
				//Ex "Montreal" could have a "Mtl|Montréal|Montreal"...
				if (loop_item.flatSearch!==null && loop_item.flatSearch.indexOf(search)!==-1) { return true; } //NOTE: Pre lower-cased
				
				return false;
			},
			on_pwdEyeReveal_click() { this.ifPwd.revealed = !this.ifPwd.revealed; },
			//NOTE: final_field is always set if we an fire this
			on_clear_click() { this.final_field.clear(); },
			/*
			For input/change events, we also re-emit them as @input & @change, where the param will be the B_REST_ModelField_DB instance itself
			NOTE:
				We don't use v-model anymore, because we can't conditionnally apply .lazy on v-model
			*/
			on_input($event)  { this._on_inputOrChange($event,"input");  },
			on_change($event) { this._on_inputOrChange($event,"change"); },
				_on_inputOrChange($event, which)
				{
					if (!this.final_field) { return; }
					let newVal = $event;
					
					if (this.picker)
					{
						//If we click the clear btn
						if (newVal===null) { this._picker_changeVal(null); }
						
						//Otherwise we don't want to do anything more anyways
						if (!this.multiple) { return; }
					}
					
					//For sliders, we convert slider offset to item keys. Check notes inside the _final_minMax() method
					if (this.final_componentName==="v-slider")
					{
						//Cases to ignore; ex when we recalc range
						if (newVal===null) { return; }
						
						const itemCount = this.final_items_usable.length;
						if (itemCount > 0)
						{
							if (newVal >= itemCount) { this._throwField(`Idx out of range vs nb of usable items`); }
							
							newVal = this.final_items_usable[newVal].key;
						}
					}
					
					this.final_field.val = newVal;
					this.final_field.userTouch_toggle(true);
					
					if (this.ifPwd)
					{
						const strengthLvl = this.final_field.validation_pwdStrengthLvl;
						
						this.ifPwd.strengthColor = PWD_STRENGHT_COLORS[strengthLvl];
						this.ifPwd.strengthValue = (strengthLvl+0) * 25;
					}
					
					this.$emit(which, this.final_field);
				},
		},
		components: { VTextField, VTextarea, VAutocomplete, VSelect, VRadioGroup, VSlider, VSwitch, VCheckbox, VDatePicker, VTimePicker, VColorPicker },
	};
	
	
	
	
	/*
	For the items prop, it can be an arr like what Vuetify normally expects:
	{
		text:     string | number | object,
		value:    string | number | object,
		disabled: boolean,
		divider:  boolean,
		header:   string
	}
	Or:
		A ModelList, where we'll usually refer to pks and use the toLabel() as text
		A shared list tag like "countryAndSubRegionList". Check B_REST_VueApp_base::sharedLists_x()
	*/
	function validator_items(val)
	{
		if (val===null || val instanceof B_REST_ModelList || B_REST_Utils.string_is(val) || B_REST_Utils.array_is(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "items" to be NULL, an instance of B_REST_ModelList, an arr of objs like {text,value,disabled,divider,header}, or shared list tag`);
		return false;
	}
	function validator_as(val)
	{
		if (AS_X_CONSTS.includes(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "as" to be one of "${AS_X_CONSTS.join('", "')}"`);
		return false;
	}
	function validator_radioGroupOrientation(val)
	{
		if (RADIO_GROUP_ALL_CONSTS.includes(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "radio-group-orientation" to be one of "${RADIO_GROUP_ALL_CONSTS.join('", "')}`);
		return false;
	}
	
</script>

<style scoped>
	
	.br-field-db {
		position: relative; /* Req for tooltips */
	}
	
		.br-field-db-component {}
			.br-field-db-component--is-dirty {}
				.br-field-db-component--is-dirty:not(.error--text).v-label,
				.br-field-db-component--is-dirty:not(.error--text) :deep(.v-text-field__slot .v-label) {
					color: var(--bREST-BrFieldDb_isDirty_color); /* Check B_REST_VueApp_base::globalCSSVars docs */
				}
				.br-field-db-component--is-dirty:not(.error--text) :deep(.v-input__slot:before),
				.br-field-db-component--is-dirty.v-textarea:not(.error--text) :deep(.v-input__slot) {
					border-color: var(--bREST-BrFieldDb_isDirty_color); /* Check B_REST_VueApp_base::globalCSSVars docs */
					border-width: thin !important;
					border-style: solid !important;
				}
			.br-field-db-component--uses-picker {}
				.br-field-db-component--uses-picker :deep(.v-input__slot) {
					pointer-events: none;
				}
				.br-field-db-component--uses-picker :deep(.v-chip--select),
				.br-field-db-component--uses-picker :deep(.v-input__icon--clear) {
					pointer-events: auto;
				}
				.br-field-db-component--uses-picker :deep(.v-input__icon--append) {
					display: none;
				}
		
		.br-field-db-component--radio-group {}
			.br-field-db-component--radio-group--label {
				/*
				NOTE:
					Don't remember why had put the following, but if we do this, seems to cause more probs everywhere...
					Was maybe for when it's in a <v-col cols="6"> or smaller...
					
					position:  absolute;
					top:       -18px;
					left:      -4px;
					transform: scale(0.75);
				*/
			}
		
		/* Must optimize */
		.br-field-db-tooltip {}
			/* Do something to position at the bottom of any type of field, of any height */
			.br-field-db-tooltip--positionner-1 {
				position: absolute;
				left:     0;
				right:    0;
				bottom:   0px;
			}
				/* Forcing a height of 0 seems like a req hack, otherwise when tooltip is multi-line, it's gonna get vertically centered above "this" */
				.br-field-db-tooltip--positionner-2 {
					position: relative;
					width:    100%;
					height:   0;
				}
					/* Then since with the last one we have no more height, we need to do this */
					.br-field-db-tooltip--positionner-3 {
						position:   absolute;
						top:        0;
						left:       0;
						right:      0;
						text-align: center;
					}
						/* The actual tooltip */
						.br-field-db-tooltip--style {
							margin:         auto;
							display:        inline-block;
							background:     rgba(97,97,97,0.9);
							color:          #FFFFFF;
							border-radius:  4px;
							font-size:      14px;
							line-height:    22px;
							padding:        5px 16px;
							text-transform: initial;
							pointer-events: none;
						}
	
</style>