<template>
	
	<component :is="componentName" reason="myProfile" />
	
</template>

<script>
	
	export default
	{
		components: {
			KbReferentForm:   () => import("@/custom/components/modules/entity_base/forms/KbReferentForm.vue"),
			KbAgencyForm:     () => import("@/custom/components/modules/entity_base/forms/KbAgencyForm.vue"),
			KbConsultantForm: () => import("@/custom/components/modules/entity_base/forms/KbConsultantForm.vue"),
			KbFranchisorForm: () => import("@/custom/components/modules/entity_base/forms/KbFranchisorForm.vue"),
			KbBrandForm:      () => import("@/custom/components/modules/entity_base/forms/KbBrandForm.vue"),
			KbLeadForm:       () => import("@/custom/components/modules/entity_base/forms/KbLeadForm.vue"),
			KbAdminForm:      () => import("@/custom/components/modules/entity_base/forms/KbAdminForm.vue"),
		},
		data()
		{
			return { };
		},
		computed: {
			componentName()
			{
				//NOTE: We could do `kb-${userType}-form` but don't, so we can handle throwing if we get unknown type
				switch (this.$bREST.user_type)
				{
					case "referent":   return "kb-referent-form";
					case "agency":     return "kb-agency-form";
					case "consultant": return "kb-consultant-form";
					case "franchisor": return "kb-franchisor-form";
					case "brand":      return "kb-brand-form";
					case "lead":       return "kb-lead-form";
					case "admin":      return "kb-admin-form";
					default:           throw this.$bREST.utils.throwEx(`Unknown user type "${this.$bREST.user_type}"`);
				}
			}
		},
	};
	
</script>