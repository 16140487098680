
import GFile_base from "../../utils/GFile_base.js";



function empty(val) { return val===null||val===''; }



export default class GFile_Form extends GFile_base
{
	_form = null;
	
	constructor(form)
	{
		super();
		
		this._form = form;
	}
	
	
	get _abstract_title()    { return `${this._form.componentFileNameWOExt_list}.vue`; }
	get _abstract_filePath() { return `${this._form.componentFilePath_list}`;          }
	
	_abstract_output()
	{
		return `<template>
	<div>TODO</div>
</template>

<script>
	
	export default {
		
	};
	
</script>`;
	}
};
