<!--
	Usage ex (MyList.vue):
		*** Check the IMPORTANT msg
		Template:
			<br-generic-list-base :derived-component="_self">
				<template #quick-add-form="{ model }">
					<br-field-db :model="model" field="user.firstName" />
				</template>
				
				<template #item.firstName="{ rowInfo, colInfo, modelField }">
					<div>Yea: {{ modelField.val }}</div>
				</template>
				
				<template #filter.created_dt="{ filter }">
					<div>
						<br-field-db :field="filter.modelField_x" no-label />
						<br-field-db :field="filter.modelField_y" no-label />
					</div>
				</template>
			</br-generic-list-base>
		Script:
			import { B_REST_Vuetify_GenericListBase_createMixin } from "@/bREST/core/implementations/vue/vuetifyComponents/genericModules/list/BrGenericListBase.vue";
			export default {
				name: "TestLeadList",
				mixins: B_REST_Vuetify_GenericListBase_createMixin({
					modelName: "Lead",
					icon: "mdi-account",
					fromLoader: {
						apiBaseUrl: "/leads/",
					},
					cols: {
						"firstName":     {fieldNamePaths:"user.firstName",    style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
						"lastName":      {fieldNamePaths:"user.lastName",     style:{fromBreakpoint:"xs", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
						"recoveryEmail": {fieldNamePaths:"user.recoveryEmail",style:{fromBreakpoint:"sm", align:B_REST_Vuetify_GenericList_Col.ALIGN_LEFT}},
						"created_dt":    {fieldNamePaths:"created_dt",        style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
						"lastLogin_dt":  {fieldNamePaths:"user.lastLogin_dt", style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
						"isActive":      {fieldNamePaths:"isActive",          style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_CENTER}},
						"calc_nbSent":   {fieldNamePaths:"calc_nbSent",       style:{fromBreakpoint:"lg", align:B_REST_Vuetify_GenericList_Col.ALIGN_RIGHT}},
					},
					globalActions: {
						add: {
							click: {
								async hook(listComponent,action,selectedModels) { listComponent.$bREST.routes_go_moduleForm_new("lead"); },
							},
							icon: "mdi-plus",
							selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0,
						},
						dropAll: {
							click: {
								async hook(listComponent,action,selectedModels) {},
							},
							icon: "mdi-close",
							selectionType: B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N,
						},
					},
					row: {
						checkbox: {isEnabled:true},
						actions: {
							edit: {
								click: {
									async hook(listComponent,action,model) { listComponent.$bREST.routes_go_moduleForm_pkTag("lead",model.pk_tag); },
								},
								icon: "mdi-pencil",
							},
							sudo: {
								click: {
									async hook(listComponent,action,model) { listComponent.$bREST.sudoIn(model.select("user.sudoHash").data); },
									isEnabled: true,
								},
								isEnabled(listComponent,action,model)
								{
									const self_idUser     = listComponent.$bREST.user_pk;
									const target_idUser   = model.select("user").pk;
									const target_sudoHash = model.select("user.sudoHash").data;
									
									return target_sudoHash && self_idUser!==target_idUser;
								},
								icon: "mdi-key-arrow-right",
							},
							delete: {
								click: {
									async hook(listComponent,action,model) { return false; },
								},
								icon: "mdi-delete",
								mustConfirm: true,
								displayResult: true,
							},
						},
					},
					filters: {
						regions:            {op:"eq_in", multiple:true},
						created_dt:         {},
						campaign_fk:        {multiple:true, componentAttrs:{items:"campaignList"}},
						currency:           {multiple:true, componentAttrs:{items:"currencyList"}},
						calc_likeSearch:    {},
						coords_address:     {},
						dues_referring_fk:  {multiple:true, componentAttrs:{dataTable:"consultantList"}},
						isProfileCompleted: {componentAttrs:{as:"select"}},
						gender:             {}, //Is a custom one
					},
					quickAddForm: {
						async hook(listComponent, modelList, model)
						{
							await model.save();
							modelList.prepend(model);
							return true;
						},
						mustConfirm:   true,
						displayResult: true,
					},
				}),
			};
		Usage:
			<my-list :from-route-info="routeInfo" />  // Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
			<my-list :from-model-list="modelList" />
	IMPORTANT:
		Check B_REST_VueApp_base::_routes_define_genericListFormModule() for the viewTransferProps() prop, that passes down the req fromRouteInfo when we load the list directly
-->

<template>
	<v-card :loading="derivedComponent.final_isLoading" :disabled="derivedComponent.final_isDisabled" elevation="8" class="br-generic-list" :class="derivedComponent.cssClassBase" width="100%">
		
		<v-card-title class="text-h5" v-if="derivedComponent.showTitle">
			<v-icon v-if="derivedComponent.icon" v-text="derivedComponent.icon" />{{ derivedComponent.title }}
		</v-card-title>
		
		<v-card-text>
			
			<!-- Quick add form, either directly in the list, or in a popup -->
				<v-card v-if="derivedComponent.quickAddForm_uses_inline" color="accent" dark class="mb-4" elevation="2">
					<v-card-title v-text="derivedComponent.quickAddForm_title" />
					<v-card-text> <slot name="quick-add-form" v-bind="{model:derivedComponent.quickAddForm_model}" /> </v-card-text>
					<v-card-actions>
						<v-spacer /> <v-btn @click="derivedComponent.on_quickAddForm_submit()" v-text="derivedComponent.t_alt('quickAddForm.submit.normal')" />
					</v-card-actions>
				</v-card>
				<v-dialog v-else-if="derivedComponent.quickAddForm_uses_dialog" :value="derivedComponent.quickAddForm_dialogOpenState" :persistent="false" @input="derivedComponent.quickAddForm_dialogOpenState=false">
					<v-card class="pb-2">
						<v-card-title v-text="derivedComponent.quickAddForm_title" />
						<v-card-text> <slot name="quick-add-form" v-bind="{model:derivedComponent.quickAddForm_model}" /> </v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn @click="derivedComponent.quickAddForm_dialogOpenState=false" v-text="derivedComponent.t_alt('quickAddForm.closeDialog')"   color="error" text />
							<v-btn @click="derivedComponent.on_quickAddForm_submit()"           v-text="derivedComponent.t_alt('quickAddForm.submit.picker')" color="secondary"  />
						</v-card-actions>
					</v-card>
				</v-dialog>
			
			<!-- Toolbar OUTSIDE the <v-data-table> -->
			<v-toolbar flat class="mb-1">
				<!-- ctrlF3 NOTE: Appears in the list + filters panel -->
					<v-text-field v-if="derivedComponent.final_ctrlF3_show" v-model="derivedComponent.ctrlF3_val" :label="derivedComponent.t_alt('ctrlF3.label')" clearable append-icon="mdi-magnify" />
					<v-divider    v-if="derivedComponent.final_ctrlF3_show" class="mx-4" inset vertical />
					<v-spacer/>
				<!-- Quick add form btn, if we don't want it inline -->
					<v-btn v-if="derivedComponent.quickAddForm_uses_dialog" class="ml-2" @click="derivedComponent.quickAddForm_dialogOpenState=true">
						<span class="hidden-sm-and-down" v-text="derivedComponent.quickAddForm_title" />
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				<!-- Filters -->
					<v-btn v-if="derivedComponent.filters_uses" color="secondary" @click="derivedComponent.filters_togglePanel()" class="ml-2">
						<v-icon v-if="derivedComponent.filters_anySet" color="success" class="mr-2">mdi-check</v-icon>
						<span class="hidden-sm-and-down" v-text="derivedComponent.t_alt('filters.label')" />
						<v-icon>mdi-filter-menu</v-icon>
					</v-btn>
				<!-- Global actions -->
					<template v-if="derivedComponent.globalActions_uses">
						<v-menu v-if="derivedComponent.final_globalActions_show_grouped" offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn color="primary" v-bind="attrs" v-on="on" class="ml-2">
									<span class="hidden-sm-and-down" v-text="derivedComponent.t_alt('globalActions.label')" />
									<v-icon>mdi-menu</v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item v-for="(loop_globalActionInfo, loop_idx) in derivedComponent.final_globalActionInfoList"
											:key="loop_idx"
											:disabled="!loop_globalActionInfo.isClickable"
											@click.stop="loop_globalActionInfo.isClickable ? derivedComponent.on_globalAction_click(loop_globalActionInfo.action) : null"
								>
									<v-list-item-icon>
										<v-icon v-text="loop_globalActionInfo.action.icon" :color="loop_globalActionInfo.isClickable ? loop_globalActionInfo.action.color : null" />
									</v-list-item-icon>
									<v-list-item-content> <v-list-item-title v-text="loop_globalActionInfo.action.label" /> </v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
						<template v-else>
							<v-btn v-for="(loop_globalActionInfo, loop_idx) in derivedComponent.final_globalActionInfoList"
								:key="loop_idx"
								:disabled="!loop_globalActionInfo.isClickable"
								v-bind="loop_globalActionInfo.action.getBtnAttrs(loop_globalActionInfo.isClickable)"
								@click.stop="loop_globalActionInfo.isClickable ? derivedComponent.on_globalAction_click(loop_globalActionInfo.action) : null"
								class="ml-2"
							>
								<span class="hidden-sm-and-down" v-text="loop_globalActionInfo.action.label" />
								<v-icon v-text="loop_globalActionInfo.action.icon" :color="loop_globalActionInfo.isClickable ? loop_globalActionInfo.action.color : null" />
							</v-btn>
						</template>
					</template>
			</v-toolbar>
			
			<!-- Here is where we should put the data table, but we could override to put other things in the way -->
			<slot name="data-table-area">
				<br-generic-list-base-data-table :list-component="derivedComponent">
					<template v-for="(loop_col,loop_idx) in definedSlots_colTD" #[loop_col.vDataTableSlotName]="{ rowInfo, colInfo, modelField }">
						<slot :name="loop_col.vDataTableSlotName" v-bind="{ rowInfo, colInfo, modelField }" />
					</template>
				</br-generic-list-base-data-table>
			</slot>
			
			<!-- For multiple pickers -->
			<v-card v-if="derivedComponent.final_isPicker_isMultiple && derivedComponent.selectedItems_has" color="accent" dark class="mt-4" elevation="2">
				<v-card-text>
					<!-- NOTE: Don't wrap in a <v-chip-group>, or they'll become selectable -->
					<v-chip v-for="(loop_selectedItem,loop_idx) in derivedComponent.selectedItems" :key="loop_idx" outlined class="ma-1" close @click:close="derivedComponent.on_multiplePickerFooter_removeChip(loop_selectedItem)">
						{{ loop_selectedItem.rowInfo.model.toLabel(derivedComponent.quotedName) }}
					</v-chip>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn :disabled="!derivedComponent.selectedItems_has" @click.stop="derivedComponent.on_picker_submit()" v-text="derivedComponent.t_alt('picker.submitLabel')" />
				</v-card-actions>
			</v-card>
			
			<!-- Especially for global & row actions req confirmation -->
			<br-prompt v-if="derivedComponent.xPrompt" :instance="derivedComponent.xPrompt" />
			
		</v-card-text>
		
		<!-- NOTE: Decided that pickers cancel btn be always at the end of the form, even if we're in multiple mode and made selections appear in the "accent" sub-card -->
		<v-card-actions v-if="derivedComponent.pickerHandle">
			<v-row justify="end" class="mr-0 mb-1">
				<v-col cols="auto"> <v-btn @click.stop="derivedComponent.on_picker_cancel()" v-text="derivedComponent.t_alt('picker.cancelLabel')" text color="error" /> </v-col>
			</v-row>
		</v-card-actions>
		
		<!--
			If we have filters to display, either display them inline, or "teleport" them in the <BrRightDrawer>
			WARNING: If we alter theme here, should check to also alter how it works in <BrRightDrawer>
		-->
			<v-navigation-drawer v-if="derivedComponent.filters_uses_inline" v-model="derivedComponent.filters_inlinePanelOpenState" absolute right temporary dark disable-resize-watcher :width="derivedComponent.filters_inlineWidth">
				<v-toolbar flat color="primary" dark>
					<v-btn icon @click="derivedComponent.filters_inlinePanelOpenState=false"><v-icon>mdi-close</v-icon></v-btn>
					<v-toolbar-title>{{ derivedComponent.t_alt('filters.label') }}</v-toolbar-title>
				</v-toolbar>
				<br-generic-list-base-filters :list-component="derivedComponent" :show-title="false" :card-color="null" :card-theme="null" :filters-theme="null" class="mb-2">
					<template v-for="(loop_filter,loop_idx) in definedSlots_filters" #[loop_filter.slotName]>
						<slot :name="loop_filter.slotName" v-bind="{ filter:loop_filter }" />
					</template>
				</br-generic-list-base-filters>
			</v-navigation-drawer>
			<br-right-drawer-content v-else-if="derivedComponent.filters_uses_external && derivedComponent.filters_rightDrawerContent" :content="derivedComponent.filters_rightDrawerContent">
				<br-generic-list-base-filters :list-component="derivedComponent" :show-title="true" :card-color="null" :card-theme="null" :filters-theme="null">
					<template v-for="(loop_filter,loop_idx) in definedSlots_filters" #[loop_filter.slotName]>
						<slot :name="loop_filter.slotName" v-bind="{ filter:loop_filter }" />
					</template>
				</br-generic-list-base-filters>
			</br-right-drawer-content>
	</v-card>
</template>

<script>
	
	import { B_REST_Utils, B_REST_Model, B_REST_ModelList, B_REST_ModelFields, B_REST_Model_Load_SearchOptions } from "../../../../../classes";
	import B_REST_VueApp_RouteInfo                                                                               from "../../../B_REST_VueApp_RouteInfo.js";
	import B_REST_VueApp_CreateCoreMixin                                                                         from "../../../B_REST_VueApp_CreateCoreMixin.js";
	import B_REST_Vuetify_GenericList_Col                                                                        from "./B_REST_Vuetify_GenericList_Col.js";
	import { B_REST_Vuetify_GenericList_GlobalAction, B_REST_Vuetify_GenericList_RowAction }                     from "./B_REST_Vuetify_GenericList_Action.js";
	import B_REST_Vuetify_GenericList_Filter                                                                     from "./B_REST_Vuetify_GenericList_Filter.js";
	import { B_REST_Vuetify_RightDrawerContent, DESKTOP_WIDTH as RIGHT_DRAWER_DESKTOP_WIDTH }                    from "../../rightDrawer/BrRightDrawer.vue";
	import B_REST_Vuetify_PickerHandle                                                                           from "../../picker/B_REST_Vuetify_PickerHandle.js";
	import { B_REST_Vuetify_Prompt, B_REST_Vuetify_Prompt_Action }                                               from "../../prompt/B_REST_Vuetify_Prompt.js";
	
	export { B_REST_Vuetify_GenericList_Col, B_REST_Vuetify_GenericList_GlobalAction, B_REST_Vuetify_GenericList_RowAction };

	
	
	export const GENERIC_LIST_CONSTS = {
		ROW_ACTIONS_COL_NAME:                             "_actions_",
		ROW_ACTIONS_BTN_REQ_SIZE:                         60, //NOTE: If we change that, will maybe have impacts in B_REST_Vuetify_GenericList_Action_base::ROW_ACTIONS_BTN_REQ_SIZE
		ROW_ACTIONS_INCLUDE_DISABLED:                     true,
		PICKER_ENABLE_ROW_AND_CELL_CLICKS:                false,
		PICKER_ENABLE_CELL_EDITS:                         false,
		PICKER_ENABLE_ROW_ACTIONS:                        false,
		PICKER_SINGLE_ANIMATION_CLOSE_DELAY:              250, //For on_selectedItems_change()
		X_ACTION_MODELS_STRING_MAX_LABEL_COUNT:           5, //For on_xAction_click()
		GLOBAL_ACTIONS_INCLUDE_DISABLED:                  true,
		RIGHT_DRAWER_OPEN_SIDE_BREAKPOINTS:               ["lg","xl"],
		RIGHT_DRAWER_ALWAYS_CLOSE_AFTER:                  false, //If false, will depend on if it's opened "aboveAll"
		RIGHT_DRAWER_ACTION_TAGS_APPLY:                   "apply",
		RIGHT_DRAWER_ACTION_TAGS_RESET:                   "reset",
		RIGHT_DRAWER_ACTION_TAGS_CANCEL:                  "cancel",
		RELOAD_ON_RESET_FILTERS:                          true,
		PAGING_SIZE_ALL:                                  -1,           //WARNING: Our B_REST_Model_Load_SearchOptions.PAGING_SIZE_ALL is NULL, but Vuetify's -1
		PAGING_SIZE_DEFAULT_CHOICES:                      [5,10,30,50], //We need to add the B_REST_Model_Load_SearchOptions's val too, or we'll have a prob
		PAGING_IGNORE:                                    -1,           //Check final_server_nbRecords()'s docs
		PAGING_UNKNOWN_SERVER_NB_RECORDS_TMP_FALLBACK:    1000,         //Check final_server_nbRecords()'s docs
		NEVER_SWITCH_TO_MOBILE_REPRESENTATION_BREAKPOINT: 0,            //We tell that the orientation of the data table shouldn't flip to vertical, until the width of the viewport is <0px
		FROM_LOADER_BOOT_STATES_IDLE:                     'idle',
		FROM_LOADER_BOOT_STATES_BOOTING:                  'booting',
		FROM_LOADER_BOOT_STATES_BOOTED:                   'booted',
		CLASS_ROW_TO_REM_OR_TO_DEL:                       'br-generic-list--toRemoveOrDelete',
		SLOT_NAME_QUICK_ADD_FORM:                         'quick-add-form',
		SLOT_NAME_DATA_TABLE_AREA:                        'data-table-area',
	};
	
	const CORE_ALT_BASE_LOC_PATH = "app.components.BrGenericListBase";
	
	
	
	export default {
		props: {
			derivedComponent: {type:Object, required:true}, //Derived component instance using this base component
		},
		data()
		{
			return {
				GENERIC_LIST_CONSTS,
			};
		},
		//Avoid hell
		created()
		{
			if      ( this.derivedComponent.quickAddForm_use && !this.definedSlots_quickAddForm) { this.derivedComponent.throwEx(`Received quickAddForm:{} stuff in options, but not defining <template #${GENERIC_LIST_CONSTS.SLOT_NAME_QUICK_ADD_FORM}>`);  }
			else if (!this.derivedComponent.quickAddForm_use &&  this.definedSlots_quickAddForm) { this.derivedComponent.throwEx(`Defining <template #${GENERIC_LIST_CONSTS.SLOT_NAME_QUICK_ADD_FORM}> but didn't receive quickAddForm:{} stuff in options`); }
		},
		computed: {
			/*
			Mandatory if quickAddForm_use
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #quick-add-form="{ model }">
						<br-field-db :model="model" field="user.firstName" />
					</template>
				</br-generic-list-base>
			*/
			definedSlots_quickAddForm() { return !!this.$scopedSlots[GENERIC_LIST_CONSTS.SLOT_NAME_QUICK_ADD_FORM]; },
			/*
			Slot underneath the toolbar, where a <br-generic-list-base-data-table> should go
			Use that if we want to put other things before, after, or toggle between display styles (ex show a google map instead)
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #data-table-area>
						<br-generic-list-base-data-table :list-component="_self">
							<template #item.firstName="{ rowInfo, colInfo, modelField }">
								<div>Yea: {{ modelField.val }}</div>
							</template>
						</br-generic-list-base-data-table>
					</template>
					<template #filter.created_dt="{ filter }">
				</br-generic-list-base>
			WARNING:
				Careful that filter slots go out of <template #data-table-area>
			*/
			definedSlots_dataTable() { return !!this.$scopedSlots[GENERIC_LIST_CONSTS.SLOT_NAME_DATA_TABLE_AREA]; },
			/*
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #item.firstName="{ rowInfo, colInfo, modelField }">
						<div>Yea: {{ modelField.val }}</div>
					</template>
				</br-generic-list-base>
			*/
			definedSlots_colTD()
			{
				const cols = [];
				
				for (const loop_col of this.derivedComponent.cols)
				{
					if (!!this.$scopedSlots[loop_col.vDataTableSlotName]) { cols.push(loop_col); }
				}
				
				return cols;
			},
			/*
			Ex:
				<br-generic-list-base :derived-component="_self">
					<template #filter.created_dt="{ filter }">
						<div>
							<br-field-db :field="filter.modelField_x" no-label />
							<br-field-db :field="filter.modelField_y" no-label />
						</div>
					</template>
				</br-generic-list-base>
			*/
			definedSlots_filters()
			{
				const filters = [];
				
				for (const loop_filter of this.derivedComponent.filters)
				{
					if (!!this.$scopedSlots[loop_filter.slotName]) { filters.push(loop_filter); }
				}
				
				return filters;
			},
		},
		methods: {
			col_definesTDSlot(col) { return !!this.$scopedSlots[col.vDataTableSlotName]; },
		},
	};
	
	
	
	
	/*
	Options as:
		{
			icon:          string,
			cols:          {<colName>:<B_REST_Vuetify_GenericList_Col constructor options - check docs>},
			globalActions: {<actionName>:<B_REST_Vuetify_GenericList_GlobalAction constructor options - check docs>],
			row: {
				actions: {<actionName>:<B_REST_Vuetify_GenericList_RowAction constructor options - check docs>],
				click: {
					isEnabled: bool | (<BrGenericListBase der>listComponent,model<B_REST_Model>)
					hook:      async(<BrGenericListBase der>listComponent,model<B_REST_Model>), //Must ret bool
				},
				checkbox: {
					isEnabled: bool | (<BrGenericListBase der>listComponent,model<B_REST_Model>),
				},
				style: {
					class: (<BrGenericListBase der>listComponent,model<B_REST_Model>), //NOTE: We have listComponent.$bREST.classProp_addTag() helper
					style: (<BrGenericListBase der>listComponent,model<B_REST_Model>), //NOTE: We have listComponent.$bREST.styleProp_addTag() helper
				},
			},
			ctrlF3: {
				hook: (<BrGenericListBase der>listComponent,model<B_REST_Model>,nonEmptySearchString),
			},
			filters: {<filterName>:<B_REST_Vuetify_GenericList_Filter constructor options - check docs>},
			quickAddForm: {
				hook:          async(<BrGenericListBase der>listComponent,modelList<B_REST_ModelList>,model<B_REST_Model>), //Used to validate + add a model to the modelList, optionnally saving it to DB right away. Must ret bool. Check on_quickAddForm_submit() docs
				mustConfirm:   bool,
				displayResult: bool,
			},
			extraData,
		}
	*/
	export function B_REST_Vuetify_GenericListBase_createMixin(mixinOptions)
	{
		//For docs on these, check top of this file & created() docs
		mixinOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions, {
			modelName:     {accept:[String], required:true}, //Ex: "Citizen"
			fromLoader:    {accept:[Object], default:null},  //Optional - check docs. As {apiBaseUrl<string>:null, reloader<async(<BrGenericListBase der>listComponent,modelList,routeInfo)>:null}. Can fill partly. Notice it's a diff obj struct to the prop w the same name
			icon:          {accept:[String], default:null},
			cols:          {accept:[Object], required:true},
			globalActions: {accept:[Object], default:null},
			row:           {accept:[Object], default:null},
			ctrlF3:        {accept:[Object], default:null},
			filters:       {accept:[Object], default:null},
			paging:        {accept:[Object], default:null},
			quickAddForm:  {accept:[Object], default:null},
			extraData:     {accept:[Object], default:null},
		}, "Generic list");
		
		return [
			//This creates funcs like t(), and requires that component defines its name Vue prop. WARNING: Must define component's name too
			B_REST_VueApp_CreateCoreMixin({
				coreAltBaseLocPath: CORE_ALT_BASE_LOC_PATH,
			}),
			//Our mixin
			{
				props: {
					showTitle: {type:Boolean, required:false, default:true}, //Check B_REST_VueApp_base::_routes_define_genericListFormModule() & B_REST_VueApp_RouteDef::convertToVueRouteDefObj() docs; Vue Router route obj needs a props() func
					//IMPORTANT: Check the usage ex in the docs above for how this works. One of these must be set
					fromModelList: {type:B_REST_Model,                required:false, default:null},                                 //If providing a modelList with models prefetched & controlled independently
					fromLoader:    {type:undefined,                   required:false, default:null, validator:validator_fromLoader}, //If otherwise wanting to depend on API calls. Either true, or {apiBaseUrl<string>:null, apiBaseUrl_path_vars<string>:null, reloader<async(<BrGenericListBase der>listComponent,modelList,routeInfo)>:null, routeInfo<B_REST_VueApp_RouteInfo>:null}. Can fill partly. Check docs above for how it works
					pickerHandle:  {type:B_REST_Vuetify_PickerHandle, required:false, default:null},                                 //If used w B_REST_VueApp_base::pickers_prompt_x()
				},
				data()
				{
					return {
						//Misc
						modelName:                              mixinOptions.modelName, //Ex "Citizen"
						modelList:                              null,                   //Final B_REST_ModelList instance, either from a received instance, or created for the wanted route
						icon:                                   mixinOptions.icon,      //Ex "mdi-account"
						cols:                                   [],                     //Arr of B_REST_Vuetify_GenericList_Col instances
						globalActions:                          [],                     //Arr of B_REST_Vuetify_GenericList_GlobalAction instances. WARNING: PICKER_ENABLE_x consts in BrGenericListFrame.vue might prevent their usage
						paging_choices:                         [],                     //Ex [5,10,20]
						extraData:                              null,                   //Optional obj
						selectedItems:                          [],                     //For now, an arr of what toVDataTableItem() provides. Notice that in picker mode, we'll remove selection after the popup is closed
						xPrompt:                                null,                   //Optional instance of B_REST_Vuetify_Prompt, for various dialogs
						isDoingUserHook:                        false,                  //To cause the control to spin until user ends hooks & answers prompts
						//Stuff for when not providing a modelList with models prefetched & controlled independently (therefore we expect it's a list that we might have to load & reload)
						final_fromLoader_use:                   false,                  //If fromLoader was set
						final_fromLoader_apiBaseUrl:            null,                   //Either mixin.fromLoader.apiBaseUrl, $props.fromLoader.apiBaseUrl           or NULL (where we'd need to use final_fromLoader_reloader instead)
						final_fromLoader_apiBaseUrl_path_vars:  null,                   //Either                              $props.fromLoader.apiBaseUrl_path_vars or NULL (where we'd need to use final_fromLoader_reloader instead)
						final_fromLoader_routeInfo:             null,                   //If we receive an instance of B_REST_VueApp_RouteInfo, it could give us info on paging etc
						final_fromLoader_reloader:              null,                   //Optionnally, user could provide a func that takes care of reloading the modelList when something changes, as async(<BrGenericListBase der>listComponent,modelList,routeInfo)>:null, routeInfo<B_REST_VueApp_RouteInfo)
						final_fromLoader_bootState:             null,                   //One of FROM_LOADER_BOOT_STATES_x
						//Stuff per row
						rowActions:                             [],                     //Arr of B_REST_Vuetify_GenericList_RowAction instances. WARNING: PICKER_ENABLE_x consts in BrGenericListFrame.vue might prevent their usage
						rowClick_isEnabled:                     null,                   //Either bool or func as (<BrGenericListBase der>listComponent,model<B_REST_Model>). WARNING: PICKER_ENABLE_x consts in BrGenericListFrame.vue overrides this
						rowClick_hook:                          null,                   //Async func as (<BrGenericListBase der>listComponent,model<B_REST_Model>) if we want to listen to when we click on rows. We also have the same per cell. Must ret bool
						rowCheckbox_isEnabled:                  null,                   //Either bool or func as (<BrGenericListBase der>listComponent,model<B_REST_Model>)
						rowStyle_class:                         null,                   //As (<BrGenericListBase der>listComponent,model<B_REST_Model>). Will go into a <tr :class>
						rowStyle_style:                         null,                   //As (<BrGenericListBase der>listComponent,model<B_REST_Model>). Will go into a <tr :style>
						//If we want to have an input w autocomplete / calls to server to filter down the list
						ctrlF3_use:                             true,                   //If we want to show a field for CTRL+F3, that's not related to filters and won't cause reloading of records. NOTE: For now, there's a prob in Vuetify and it doesn't work when useForLoading... Check TODO.txt
						ctrlF3_val:                             null,                   //Current search val. When changed, doesn't auto reload modelList
						ctrlF3_hook:                            null,                   //By default, checks against the model::toLabel('<generic-list data-table="...">'), but we can use a custom func that must ret true for if search matches, as (<BrGenericListBase der>listComponent,model<B_REST_Model>,nonEmptySearchString)
						//Server filters that should open in a panel
						filters:                                [],                     //Arr of B_REST_Vuetify_GenericList_Filter instances, that we'll place in a <br-model-list-filters>
						filters_inlinePanelOpenState:           false,                  //Bool for panel open state, If filters_uses_inline
						filters_rightDrawerContent:             null,                   //Instance of B_REST_Vuetify_RightDrawerContent, if filters_uses_external
						//If we implement a helper form to create new models and auto add them to the modelList
						quickAddForm_use:                       false,                  //If we show the quick add form (not as a popup)
						quickAddForm_dialogOpenState:           false,                  //When quickAddForm_uses_dialog
						quickAddForm_model:                     null,                   //When we show the form, we must ALWAYS have an allocated B_REST_Model of the B_REST_Descriptor found in modelList.descriptor
						quickAddForm_hook:                      null,                   //Async as (<BrGenericListBase der>listComponent,modelList<B_REST_ModelList>,model<B_REST_Model>). Used to validate + add a model to the modelList, optionnally saving it to DB right away. Must ret bool. Check on_quickAddForm_submit() docs
						quickAddForm_mustConfirm:               null,                   //If we want an automatic dialog to prompt for confirmation. Loc path will be "<custom/coreBaseLocPath>.quickAddForm.confirm"
						quickAddForm_displayResult:             null,                   //If after the hook, we want msgs "<custom/coreBaseLocPath>.quickAddForm.success" or "<custom/coreBaseLocPath>.quickAddForm.failure" to appear automatically
						//Stuff for how it should appear against viewport width
						currentBreakpoint_name:                 null,                   //One of Vuetify's breakpoints (xs|sm|md|lg|xl), via Vuetify.breakpoint.name
						currentBreakpoint_vDataTableHeaderDefs: [],                     //Also including the action header, if required
						currentBreakpoint_cols:                 [],                     //Filtered version of our B_REST_Vuetify_GenericList_Col instances, against currentBreakpoint_name
					};
				},
				watch: {
					fromModelList(newVal,oldVal) { this.throwEx(`Mustn't be able to change fromModelList prop`); },
					fromLoader: {
						handler(newVal,oldVal)
						{
							if (!this.final_fromLoader_use) { this.throwEx(`Can't change fromLoader if we weren't using it`); }
							
							this.$bREST.utils.console_warn(`Possible that BrGenericListBase's fromLoader watch gets fired for no reason, ex if we structured an obj for :from-loader like <my-list :from-loader="{a,b,c}"> and other elems in UI change around it. If so, seems to be a bug and solution is to make a computed like abc(){return{a,b,c};} and do <my-list :from-loader="abc"> instead, for ${this.quotedName}`);
							
							if (this.final_fromLoader_bootState===GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTED) { this.reloadList(); }
						},
						deep: true,
					},
					pickerHandle: {
						handler(newVal,oldVal) { this.picker_setup(); },
						deep: true, //NOTE: Must do this since we must react to changing selection / isMultiple etc
					},
					"$bREST.uiBreakpoint.name"(newVal,oldVal) { this.currentBreakpoint_reEval(); }, //Causes data table's currentBreakpoint_recalcHeadersAndColsToUse() to be fired, thus calculating headers & cols defs
				},
				created()
				{
					this.fromX_setLoadModelList();
					
					//Cols
					{
						for (const loop_colName in mixinOptions.cols)
						{
							if (this.cols_getByName(loop_colName)) { this.throwEx(`Col "${loop_colName}" already defined`); }
							
							const loop_col = new B_REST_Vuetify_GenericList_Col(this, loop_colName, mixinOptions.cols[loop_colName]);
							this.cols.push(loop_col);
						}
						if (this.cols.length===0) { this.throwEx(`Expected to receive cols`); }
					}
					
					//Global actions
					if (mixinOptions.globalActions)
					{
						for (const loop_globalActionName in mixinOptions.globalActions)
						{
							if (this.globalActions_getByName(loop_globalActionName)) { this.throwEx(`Global action "${loop_globalActionName}" already defined`); }
							
							const loop_globalAction = new B_REST_Vuetify_GenericList_GlobalAction(this, loop_globalActionName, mixinOptions.globalActions[loop_globalActionName]);
							this.globalActions.push(loop_globalAction);
						}
					}
					
					//Row stuff
					if (mixinOptions.row)
					{
						const rowOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.row, {
							actions:  {accept:[Object], default:null},
							click:    {accept:[Object], default:null},
							checkbox: {accept:[Object], default:null},
							style:    {accept:[Object], default:null},
						}, "Generic list row");
						
						if (rowOptions.actions)
						{
							for (const loop_rowActionName in rowOptions.actions)
							{
								if (this.rowActions_getByName(loop_rowActionName)) { this.throwEx(`Row action "${loop_rowActionName}" already defined`); }
								
								const loop_rowAction = new B_REST_Vuetify_GenericList_RowAction(this, loop_rowActionName, rowOptions.actions[loop_rowActionName]);
								this.rowActions.push(loop_rowAction);
							}
						}
						
						if (rowOptions.click)
						{
							const rowClickOptions = B_REST_Utils.object_hasValidStruct_assert(rowOptions.click, {
								isEnabled: {accept:[Boolean,Function], default:true},
								hook:      {accept:undefined,          required:true},
							}, "Generic list row click");
							
							this.rowClick_isEnabled = rowClickOptions.isEnabled;
							this.rowClick_hook      = rowClickOptions.hook;
						}
						
						if (rowOptions.checkbox)
						{
							const rowCheckboxOptions = B_REST_Utils.object_hasValidStruct_assert(rowOptions.checkbox, {
								isEnabled: {accept:[Boolean,Function], required:true},
							}, "Generic list row checkbox");
							
							this.rowCheckbox_isEnabled = rowCheckboxOptions.isEnabled;
						}
						
						if (rowOptions.style)
						{
							const rowStyleOptions = B_REST_Utils.object_hasValidStruct_assert(rowOptions.style, {
								class: {accept:[Function], default:null},
								style: {accept:[Function], default:null},
							}, "Generic list row style");
							
							this.rowStyle_class = rowStyleOptions.class;
							this.rowStyle_style = rowStyleOptions.style;
						}
					}
					
					//CTRL+F3 options
					if (mixinOptions.ctrlF3)
					{
						const ctrlF3Options = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.ctrlF3, {
							hook: {accept:[Function], required:true},
						}, "Generic list ctrlF3");
						
						this.ctrlF3_hook = ctrlF3Options.hook;
					}
					
					//Filters
					if (mixinOptions.filters)
					{
						for (const loop_filterName in mixinOptions.filters)
						{
							if (this.filters_getByName(loop_filterName)) { this.throwEx(`Filter "${loop_filterName}" already defined`); }
							
							const loop_filter = new B_REST_Vuetify_GenericList_Filter(this, loop_filterName, mixinOptions.filters[loop_filterName]);
							this.filters.push(loop_filter);
						}
					}
					
					//Paging
					if (mixinOptions.paging)
					{
						const pagingOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.paging, {
							choices: {accept:[Array], default:GENERIC_LIST_CONSTS.PAGING_SIZE_DEFAULT_CHOICES},
						}, "Generic list paging");
						
						this.paging_choices = pagingOptions.choices;
					}
					else { this.paging_choices=GENERIC_LIST_CONSTS.PAGING_SIZE_DEFAULT_CHOICES; }
					
					//Quick add form
					if (mixinOptions.quickAddForm)
					{
						const quickAddFormOptions = B_REST_Utils.object_hasValidStruct_assert(mixinOptions.quickAddForm, {
							hook:          {accept:undefined, required:true},
							mustConfirm:   {accept:[Boolean], default:false},
							displayResult: {accept:[Boolean], default:false},
						}, "Generic list quickAddForm");
						
						this.quickAddForm_use           = true;
						this.quickAddForm_hook          = quickAddFormOptions.hook;
						this.quickAddForm_mustConfirm   = quickAddFormOptions.mustConfirm;
						this.quickAddForm_displayResult = quickAddFormOptions.displayResult;
						
						this.quickAddForm_renewModel();
					}
					
					this.extraData = mixinOptions.extraData;
					
					this.currentBreakpoint_reEval(); //Recalc headers & cols to use in the bound <v-data-table>
				},
				beforeMount()
				{
					this.currentBreakpoint_reEval(); //Causes data table's currentBreakpoint_recalcHeadersAndColsToUse() to be fired, thus calculating headers & cols defs
				},
				beforeRouteUpdate(to, from, next)
				{
					this.$bREST.utils.console_warn(`Check BrGenericFormBase::beforeRouteUpdate() + maybe uncomment the fromX_setLoadModelList() below`);
					
					if (!this.$bREST.router_isIgnoringReplaceEvents)
					{
						// this.fromX_setLoadModelList();
						//await other stuff
					}
					next();
				},
				beforeRouteLeave(to, from, next)
				{
					this.$bREST.utils.console_warn(`Check BrGenericFormBase::beforeRouteLeave() + maybe uncomment the fromX_setLoadModelList() below`);
					
					if (!this.$bREST.router_isIgnoringReplaceEvents)
					{
						// this.fromX_setLoadModelList();
						//await other stuff
					}
					next();
				},
				computed: {
					uid()                              { return this._uid;                                                                                                                    }, //All Vue component instances have a unique id
					quotedName()                       { return `B_REST_Vuetify_GenericList<${this.componentName}>`;                                                                          },
					cssClassBase()                     { return `generic-list--${this.componentName}`;                                                                                        },
					title()                            { return this.t("title");                                                                                                              },
					quickAddForm_title()               { return this.pickerHandle ? this.t_alt("quickAddForm.title.picker") : this.t_alt("quickAddForm.title.normal");                        },
					quickAddForm_uses_inline()         { return this.quickAddForm_use &&  !this.pickerHandle;                                                                                 }, //Check similar logic for filters_uses_x()
					quickAddForm_uses_dialog()         { return this.quickAddForm_use && !!this.pickerHandle;                                                                                 }, //Check similar logic for filters_uses_x()
					descriptor()                       { return this.modelList.descriptor;                                                                                                    },
					useForLoading()                    { return this.modelList.useForLoading;                                                                                                 },
					globalActions_uses()               { return this.globalActions.length>0 && this.mode_allowsActions;                                                                       },
					rowActions_uses()                  { return this.rowActions.length>0 && this.mode_allowsActions;                                                                          },
					filters_uses()                     { return this.filters.length>0;                                                                                                        },
					filters_uses_external()            { return this.filters.length>0 &&  !this.pickerHandle;                                                                                 }, //When not in a popup already, it's ok to have the filters open via the global right drawer externally
					filters_uses_inline()              { return this.filters.length>0 && !!this.pickerHandle;                                                                                 }, //In picker popup mode, we want to open the filters inside the picker popup
					filters_inlineWidth()              { return this.$bREST.uiBreakpoint.mobile ? "100%" : RIGHT_DRAWER_DESKTOP_WIDTH;                                                        }, //Same logic as <BrRightDrawer>
					filters_shouldClosePanel()         { return GENERIC_LIST_CONSTS.RIGHT_DRAWER_ALWAYS_CLOSE_AFTER || this.filters_uses_inline || this.filters_rightDrawerContent?.aboveAll; },
					filters_anySet()                   { return !!this.filters.find(loop_filter => loop_filter.isSet);                                                                        },
					mode_allowsClicks()                { return !this.pickerHandle || (this.pickerHandle&&GENERIC_LIST_CONSTS.PICKER_ENABLE_ROW_AND_CELL_CLICKS);                             },
					mode_allowsEdits()                 { return !this.pickerHandle || (this.pickerHandle&&GENERIC_LIST_CONSTS.PICKER_ENABLE_CELL_EDITS);                                      },
					mode_allowsActions()               { return !this.pickerHandle || (this.pickerHandle&&GENERIC_LIST_CONSTS.PICKER_ENABLE_ROW_ACTIONS);                                     },
					selectedItems_has()                { return this.selectedItems.length>0;                                                                                                  },
					final_isDisabled()                 { return this.isDoingUserHook || (this.useForLoading&&this.modelList.isLoading);                                                       },
					final_isLoading()                  { return this.isDoingUserHook || (this.useForLoading&&this.modelList.isLoading);                                                       },
					final_globalActions_show_grouped() { return this.globalActions.length>1;                                                                                                  },
					final_ctrlF3_show()                { return this.ctrlF3_use && !this.useForLoading;                                                                                       }, //WARNING: Not working when useForLoading (server-items-length set): Tried putting debugger in on_ctrlF3_update(), but not being called. Alt would be to remove search & custom-filter props, and in final_items() drop items by ourselves, however it might cause Vuetify to think we had less items back from the server than supposed and fuck paging...
					final_checkboxes_show()            { return !!this.pickerHandle || !!this.globalActions.find(loop_globalAction=>!loop_globalAction.selectionType_is_0);                   }, //Picker or global actions req selections
					final_isPicker_isSingle()          { return this.pickerHandle && !this.pickerHandle.isMultiple;                                                                           },
					final_isPicker_isMultiple()        { return this.pickerHandle &&  this.pickerHandle.isMultiple;                                                                           },
					//When for static list display all, otherwise go against the B_REST_ModelList's B_REST_Model_Load_SearchOptions's paging size
					final_paging_size()
					{
						if (!this.useForLoading) { return GENERIC_LIST_CONSTS.PAGING_SIZE_ALL; } //For now, otherwise we should add a prop for default static paging
						
						const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
						
						return searchOptions.paging_size_isAll ? GENERIC_LIST_CONSTS.PAGING_SIZE_ALL : searchOptions.paging_size; //WARNING: Convert PAGING_SIZE_ALL from NULL to -1 if we must
					},
					//When for static list, we just use normal choices, but if we load on server, we'll also include what the B_REST_Model_Load_SearchOptions instance currently holds, to prevent hell
					final_paging_sizeChoices()
					{
						if (!this.useForLoading) { return [...this.paging_choices, GENERIC_LIST_CONSTS.PAGING_SIZE_ALL] ; }
						
						const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
						
						const choices = [...this.paging_choices];
						
						//Make sure current server paging size is included, if it's not all too. WARNING: Convert PAGING_SIZE_ALL from NULL to -1 if we must
						if (!searchOptions.paging_size_isAll && !choices.includes(searchOptions.paging_size))
						{
							choices.push(searchOptions.paging_size);
							choices.sort((a,b) =>
							{
								if (a===b) { return 0; }
								return a-b;
							});
						}
						choices.push(GENERIC_LIST_CONSTS.PAGING_SIZE_ALL);
						
						return choices;
					},
					/*
					WARNING:
						1) Make sure the list's current page size matches what is returned, otherwise vuetify will be confused and ignore final_server_nbRecords as well
							Ex: Page size indicates 10, final_server_nbRecords indicates 100, but we return 20 items.
								Vuetify will show that we have 2 pages (1-10 and 11-20), and not understand that we should have up to 100 records.
								So if page size is 10, we must return 10 (or less if last page).
						2) For now not handling APIs where we don't know how many records get returned.
							Vuetify will think we only have 1 page and show the nav arrows disabled (even if we ret -1 here), but we'd need to be able to do modelList.loadMore().
							"Rebuild the whole <template #footer> :'("
					NOTE:
						This gets called as soon as we boot the list, even before it has time to load its initial data, so nbRecords_filtered will most likely be null at that time
					*/
					final_server_nbRecords()
					{
						if (!this.useForLoading) { return GENERIC_LIST_CONSTS.PAGING_IGNORE; }
						
						const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
						
						return searchOptions.lastCall_nbRecords_filtered ?? GENERIC_LIST_CONSTS.PAGING_UNKNOWN_SERVER_NB_RECORDS_TMP_FALLBACK;
					},
					final_footerProps()
					{
						return {
							'items-per-page-options': this.final_paging_sizeChoices,
						};
					},
					//Rets an arr of {action<B_REST_Vuetify_GenericList_GlobalAction>, isClickable}
					final_globalActionInfoList()
					{
						const arr = [];
						
						const selectedItems_count = this.selectedItems.length;
						
						for (const loop_action of this.globalActions)
						{
							let loop_isClickable = null;
							
							switch (loop_action.selectionType)
							{
								case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0:   loop_isClickable = true;                    break;
								case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1:   loop_isClickable = selectedItems_count===1; break;
								case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_0_N: loop_isClickable = true;                    break;
								case B_REST_Vuetify_GenericList_GlobalAction.SELECTION_TYPE_1_N: loop_isClickable = selectedItems_count>0;   break;
								default: this.throwEx(`Unexpected selectionType "${loop_action.selectionType}"`);
							}
							
							if (loop_isClickable || GENERIC_LIST_CONSTS.GLOBAL_ACTIONS_INCLUDE_DISABLED)
							{
								arr.push({
									action:      loop_action,
									isClickable: loop_isClickable,
								});
							}
						}
						
						return arr;
					},
					/*
					We don't need to pass vals for all fields we define in datatable's currentBreakpoint_vDataTableHeaderDefs; as long as we pass only 1 field it's ok (or filters will throw).
					We add an rowInfo extra data to each item, to be able to properly handle when stuff is clickable and get easy ptrs to simplify template:
						{
							model:        B_REST_Model,
							isClickable:  <bool>
							isSelectable: <bool>
							actions:      [{action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}],
							cols:         Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
						}
					*/
					final_items()
					{
						const items = [];
						for (const loop_model of this.modelList.models) { items.push(this.toVDataTableItem(loop_model)); }
						return items;
					},
				},
				methods: {
					throwEx(msg,details=null)     { this.$bREST.throwEx(    `${this.quotedName}: ${msg}`,details);                      },
					logError(msg,details=null)    { this.$bREST.utils.console_error(`${this.quotedName}: ${msg}`,details);                      },
					cols_getByName(name)          { return this.cols.find(loop_col                   => loop_col.name         ===name); }, //Can ret NULL
					globalActions_getByName(name) { return this.globalActions.find(loop_globalAction => loop_globalAction.name===name); }, //Can ret NULL
					rowActions_getByName(name)    { return this.rowActions.find(loop_rowAction       => loop_rowAction.name   ===name); }, //Can ret NULL
					//Should only be called once
					async fromX_setLoadModelList()
					{
						if (this.modelList)                        { this.throwEx(`Can't change modelList once set`);                             }
						if (this.fromModelList && this.fromLoader) { this.throwEx(`Can't set fromModelList & fromLoader props at the same time`); }
						
						if (this.$bREST.router_isIgnoringReplaceEvents)
						{
							this.throwEx(`To check: in generic form, we have this w a "return", but for lists, for now we don't want to handle going here multiple times, so should that happen ?`);
							return;
						}
						
						//If providing a modelList with models prefetched & controlled independently
						if (this.fromModelList)
						{
							//Do some validation
							const fromModelListName = this.fromModelList.descriptor.name;
							if (fromModelListName!==mixinOptions.modelName) { this.throwEx(`Expected a B_REST_ModelList instance of ${mixinOptions.modelName}, got ${fromModelListName}`); }
							
							this.modelList = this.fromModelList;
							
							if (this.pickerHandle) { this.picker_setup(); }
						}
						//If otherwise wanting to depend on API calls (either true or an obj)
						else if (this.fromLoader)
						{
							const propAsObj = this.fromLoader!==true;
							
							//First check if we've got a reloader func (optional)
							if      (propAsObj && this.fromLoader.reloader) { this.final_fromLoader_reloader=this.fromLoader.reloader;         }
							else if (mixinOptions.fromLoader.reloader)      { this.final_fromLoader_reloader=mixinOptions.fromLoader.reloader; }
							
							//Then an API base URL, which is req if we don't have a reloader func
							if      (propAsObj && this.fromLoader.apiBaseUrl) { this.final_fromLoader_apiBaseUrl=this.fromLoader.apiBaseUrl;                                         }
							else if (mixinOptions.fromLoader.apiBaseUrl)      { this.final_fromLoader_apiBaseUrl=mixinOptions.fromLoader.apiBaseUrl;                                 }
							else if (!this.final_fromLoader_reloader)         { this.throwEx(`Must define either the apiBaseUrl or reloader prop in the mixin or component $props`); }
							
							//Then optional path vars. Note that the mixinOptions shouldn't provide that, so we won't check for a mixinOptions.fromLoader.apiBaseUrl_path_vars
							if (propAsObj && this.fromLoader.apiBaseUrl_path_vars) { this.final_fromLoader_apiBaseUrl_path_vars=this.fromLoader.apiBaseUrl_path_vars; }
								//WARNING: Model list will throw if we end up w a final_fromLoader_apiBaseUrl containing path vars to replace, but that we don't provide them
									
							//Also we might get an instance of B_REST_VueApp_RouteInfo, to help w paging etc
							if (propAsObj && this.fromLoader.routeInfo)
							{
								B_REST_Utils.instance_isOfClass_assert(B_REST_VueApp_RouteInfo, this.fromLoader.routeInfo);
								this.final_fromLoader_routeInfo = this.fromLoader.routeInfo;
							}
							
							const modelList = B_REST_ModelList.commonDefs_make(mixinOptions.modelName, /*useForLoading*/true, /*useCachedShare*/false);
							modelList.apiBaseUrl           = this.final_fromLoader_apiBaseUrl;
							modelList.apiBaseUrl_path_vars = this.final_fromLoader_apiBaseUrl_path_vars; //NOTE: Ok to be NULL if api base url doesn't contain path vars to define
								//NOTE: We'll do modelList.requiredFields.requiredFields_addFields() first time we load the list, in reloadList()
							
							this.modelList                  = modelList;
							this.final_fromLoader_bootState = GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_IDLE;
							this.final_fromLoader_use       = true;
							//NOTE: We also have useForLoading(), where we could receive a fromModelList, but that that one would be used for loading too
						}
						else { this.throwEx(`Expected either fromModelList or fromLoader props to be set. If used in a picker, consider maybe doing $bREST.pickers_prompt_x(<name>,{vBind:{fromLoader:true}})`); }
					},
					//Wrapper for reloading list, where we either do it ourselves or let usage use a custom func to do it
					async reloadList()
					{
						//WARNING: Not sure if we should check w userForLoading() or final_fromLoader_use()
						
						if (!this.useForLoading) { this.throwEx(`Can only use reloadList() when for loading`); }
						
						try
						{
							//If usage wants to take care of reloading the list against various things like B_REST_VueApp_RouteInfo changes, etc
							if (this.final_fromLoader_reloader) { await this.final_fromLoader_reloader(this,this.modelList,this.final_fromLoader_routeInfo); }
							//Else if we must take care of it
							else
							{
								//Make sure we'll get all the info we need in order to put data in cols. Setup this only once
								if (this.final_fromLoader_bootState===GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTING)
								{
									for (const loop_col of this.cols)
									{
										if (loop_col.fieldNamePaths) { this.modelList.requiredFields.requiredFields_addFields(loop_col.fieldNamePaths); }
									}
								}
								
								await this.modelList.reload();
							}
						}
						catch (e)
						{
							this.$bREST.utils.console_error(`${this.quotedName}: reloadList failed: ${e}`);
							return false;
						}
						
						if (this.final_fromLoader_bootState!==GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTED)
						{
							this.final_fromLoader_bootState = GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTED;
							
							if (this.pickerHandle) { this.picker_setup(); }
						}
						
						return true;
					},
					
					//QUICK ADD FORM RELATED
						//Do this at the beginning and once after every save, so we can start with a fresh new model
						quickAddForm_renewModel()
						{
							this.quickAddForm_model = B_REST_Model.commonDefs_make(this.modelName);
						},
					
					//VUETIFY RELATED
						//Each time Vuetify's breakpoint changes, we should recalc headers & cols to use in the bound <v-data-table>
						currentBreakpoint_reEval()
						{
							const newBreakpoint = this.$bREST.uiBreakpoint.name;
							
							if (this.currentBreakpoint_name!==newBreakpoint)
							{
								this.currentBreakpoint_name = newBreakpoint;
								this.currentBreakpoint_recalcHeadersAndColsToUse();
							}
						},
							/*
							Outputs according to https://vuetifyjs.com/en/api/v-data-table/#props-headers
							To prevent bugs in Vuetify:
								-We'll make sure exactly 1 col has its prop "filterable" to true:
									-Otherwise it throws lots of errs when we all are not filterable
									-Otherwise if we set true on multiple, the filter func will get called as many time as we have filterable col per row for nothing
								-We'll allow sort:
									-On all cols, when it's meant to be a table of static data (for now we check against B_REST_ModelList::useForLoading())
									-On specified cols, when it's for loadable data where the server takes care of sorting
							*/
							currentBreakpoint_recalcHeadersAndColsToUse()
							{
								const currentBreakpoint_number = this.vuetifyBreakpointToNumber(this.currentBreakpoint_name);
								
								const headers = [];
								const cols    = [];
								
								for (const loop_col of this.cols)
								{
									const loop_fromBreakpointNumber = this.vuetifyBreakpointToNumber(loop_col.style_fromBreakpoint);
									if (loop_fromBreakpointNumber > currentBreakpoint_number) { continue; }
									
									headers.push(loop_col.toVDataTableHeaderDef(/*isFirstCol*/headers.length===0));
									cols.push(loop_col);
								}
								
								if (cols.length===0)
								{
									this.throwEx(`We don't have cols for the current breakpoint "${this.currentBreakpoint_name}", out of ${this.cols.length} in total. Check that we have some:\n\tnew Col(..., {style:{fromBreakpoint:"${this.currentBreakpoint_name}"}}) //Tip: put "xs" so it's always there`);
								}
								
								//If we should add the extra action cell
								if (this.rowActions_uses)
								{
									headers.push({
										value:      GENERIC_LIST_CONSTS.ROW_ACTIONS_COL_NAME,
										text:       this.t_alt("cols.actions.label"),
										align:      "center",
										sortable:   false,
										filterable: false,
										groupable:  false,
										divider:    false,
										class:      null,
										cellClass:  null,
										width:      this.rowActions.length * GENERIC_LIST_CONSTS.ROW_ACTIONS_BTN_REQ_SIZE,
										filter:     null,
										sort:       null,
									});
								}
								
								this.currentBreakpoint_vDataTableHeaderDefs = headers;
								this.currentBreakpoint_cols                 = cols;
							},
								//One of xs|sm|md|lg|xl, or nothing which equals xs
								vuetifyBreakpointToNumber(breakpoint)
								{
									switch (breakpoint)
									{
										case "xs": return 0;
										case "sm": return 1;
										case "md": return 2;
										case "lg": return 3;
										case "xl": return 4;
										case null: return 0;
									}
									
									this.throwEx(`Unknow Vuetify breakpoint "${breakpoint}"`);
								},
						/*
						For component's final_items() and on_quickAddForm_submit()
						We don't need to pass vals for all _currentBreakpoint_cols; as long as we pass only 1 field it's ok (or filters will throw).
						We add an rowInfo extra data to each item, to be able to properly handle when stuff is clickable and get easy ptrs to simplify template:
							{
								model:        B_REST_Model,
								isClickable:  <bool>
								isSelectable: <bool>
								actions:      [{action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}],
								cols:         Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
							}
						*/
						toVDataTableItem(model)
						{
							if (!(model instanceof B_REST_Model)) { this.throwEx(`Expected an instance of B_REST_Model`); }
							
							//Setup general state for that model
							const row_isClickable  = this.mode_allowsClicks && this.toVDataTableItem_evalModel_row_isClickable(model);
							const row_isSelectable = this.toVDataTableItem_evalModel_row_isSelectable(model);
							const row_actions      = this.mode_allowsActions && this.toVDataTableItem_evalModel_row_actions(model, GENERIC_LIST_CONSTS.ROW_ACTIONS_INCLUDE_DISABLED); //Arr of {action<B_REST_Vuetify_GenericList_RowAction>, isClickable:<bool>}
							
							//Setup state per col for that model
							const cols_extraData = {}; //Map of col name => {col<B_REST_Vuetify_GenericList_Col>, modelField<B_REST_ModelField_x>:null, isEditable, isClickable}
							for (const loop_col of this.currentBreakpoint_cols)
							{
								const loop_col_isClickable = this.mode_allowsClicks && loop_col.click_isEnabled(model); //Rets false on exception
								const loop_col_isEditable  = this.mode_allowsEdits  && loop_col.isEditable(model);
								
								cols_extraData[loop_col.name] = {
									col:         loop_col,
									modelField:  loop_col.getModelField(model), //Rets NULL if we don't have a SINGLE req fieldNamePath, or if we didn't pass required fields (modelList.requiredFields.requiredFields_addFields())
									isClickable: loop_col_isClickable,
									isEditable:  loop_col_isEditable,
								};
							}
							
							//IMPORTANT: Don't change the fact that we do need to pass model twice. Check method docs
							const item = {
								frontendUUID: model.frontendUUID,
								isSelectable: row_isSelectable, //Do this so Vuetify knows not to try to check non-existent checkboxes
								rowInfo: {
									model:        model,       //Pass this anyways to keep template simple
									isClickable:  row_isClickable,
									isSelectable: row_isSelectable, //Repeat usage of row_isClickable here, so we can also add the checkboxes at the right time
									actions:      row_actions,
									cols:         cols_extraData,
								},
							};
							
							if (this.currentBreakpoint_cols.length===0) { this.throwEx(`We don't have cols for the current breakpoint "${this.currentBreakpoint_name}"`); } //Shouldn't happen; already throws in currentBreakpoint_recalcHeadersAndColsToUse()
							item[this.currentBreakpoint_cols[0].name] = model; //IMPORTANT: We need to do this or filters will break. Check method docs
							
							return item;
						},
							toVDataTableItem_evalModel_row_isClickable(model)
							{
								if (!this.rowClick_isEnabled || !this.rowClick_hook) { return false; } //NULL or false
								
								//Can be a bool or a func
								if (this.rowClick_isEnabled===true) { return true; }
								
								//Otherwise it's a func
								try
								{
									return this.rowClick_isEnabled(this,model);
								}
								catch (e) { this.throwEx(`rowClick_isEnabled hook failed: ${e}`); }
								return false;
							},
							toVDataTableItem_evalModel_row_isSelectable(model)
							{
								if (!this.rowCheckbox_isEnabled) { return false; } //NULL or false
								
								//Can be a bool or a func
								if (this.rowCheckbox_isEnabled===true) { return true; }
								
								//Otherwise it's a func
								try
								{
									return this.rowCheckbox_isEnabled(this,model);
								}
								catch (e) { this.throwEx(`rowCheckbox_isEnabled hook failed: ${e}`); }
								return false;
							},
							//Rets as an arr of {action<B_REST_Vuetify_GenericList_RowAction>, isClickable}
							toVDataTableItem_evalModel_row_actions(model, includeDisabled)
							{
								const rowActions = [];
								
								for (const loop_rowAction of this.rowActions)
								{
									const loop_isClickable = loop_rowAction.click_isEnabled(model); //Rets false on exception
									
									if (!loop_isClickable && !includeDisabled) { continue; }
									
									rowActions.push({
										action:      loop_rowAction,
										isClickable: loop_isClickable,
									});
								}
								
								return rowActions;
							},
					//GEN
						row_evalClass(rowInfo)
						{
							let rowClass = this.rowStyle_class ? this.rowStyle_class(this,rowInfo.model) : undefined;
							
							if (rowInfo.model.toRemoveOrDelete) { rowClass=this.$bREST.classProp_addTag(rowClass,GENERIC_LIST_CONSTS.CLASS_ROW_TO_REM_OR_TO_DEL); }
							
							return rowClass;
						},
						row_evalStyle(rowInfo) { return this.rowStyle_style ? this.rowStyle_style(this,rowInfo.model) : undefined; },
						async on_row_click(rowInfo)
						{
							this.isDoingUserHook = true;
							await this.rowClick_hook(this,rowInfo.model); //NOTE: Rets false on exception, but we won't care for now
							this.isDoingUserHook = false;
						},
						async on_cell_click(col, rowInfo)
						{
							this.isDoingUserHook = true;
							await col.click_hook(rowInfo.model); //NOTE: Rets false on exception, but we won't care for now
							this.isDoingUserHook = false;
						},
						async on_globalAction_click(action)       { return this.on_xAction_click(action,this.selectedItems.map(loop_item=>loop_item.rowInfo.model)); },
						async on_rowAction_click(action, rowInfo) { return this.on_xAction_click(action,rowInfo.model);                                              },
							/*
							WARNING:
								-Doesn't cause a reload of the modelList, so if required, the user should do listComponent.reloadList() via the hook
							*/
							async on_xAction_click(xAction, modelOrModelsOrNULL)
							{
								this.isDoingUserHook = true;
								
								let modelCount   = null;
								let modelsString = null;
								
								if (xAction.mustConfirm || xAction.displayResult)
								{
									if (modelOrModelsOrNULL===null)
									{
										modelCount   = 0;
										modelsString = "";
									}
									else if (modelOrModelsOrNULL instanceof B_REST_Model)
									{
										modelCount   = 1;
										modelsString = modelOrModelsOrNULL.toLabel(this.quotedName);
									}
									//When we have multiple, displays something like "<label1>, <label2>, <label3>... +123"
									else
									{
										modelCount = modelOrModelsOrNULL.length;
										
										const smallerLimit = Math.min(modelCount, GENERIC_LIST_CONSTS.X_ACTION_MODELS_STRING_MAX_LABEL_COUNT);
										
										const fewModelLabels = [];
										for (let i=0; i<smallerLimit; i++) { fewModelLabels.push(modelOrModelsOrNULL[i].toLabel(this.quotedName)); }
										modelsString = fewModelLabels.join(", ");
										
										if (modelCount>smallerLimit) { modelsString += `... +${modelCount-smallerLimit}`; }
									}
									
									this.xPrompt = new B_REST_Vuetify_Prompt(xAction.label, true/*isModal*/);
								}
								
								//If we must freeze and ask user for confirmation
								let canContinue = true;
								if (xAction.mustConfirm)
								{
									this.xPrompt.actions = [
										new B_REST_Vuetify_Prompt_Action("cancel", this.t_alt("prompt.cancel"), null), //NOTE: We have "prompt.no" too...
										null,
										new B_REST_Vuetify_Prompt_Action("yes", this.t_alt("prompt.yes"), "error"),
									];
									
									this.xPrompt.body = xAction.getMsg_confirm(modelCount, modelsString);
									
									const selectedActionOrNull = await this.xPrompt.show();
									if (selectedActionOrNull!=="yes") { canContinue=false; }
								}
								
								//Then do the action hook
								if (canContinue)
								{
									const success = await xAction.click_hook(modelOrModelsOrNULL); //Rets false on exception
									
									//If we want to display whether it worked or not
									if (xAction.displayResult)
									{
										this.xPrompt.actions = [
											null,
											new B_REST_Vuetify_Prompt_Action("ok", this.t_alt("prompt.ok")), //NOTE: We also have "prompt.close"
										];
										
										this.xPrompt.body  = success ? xAction.getMsg_success(modelCount,modelsString) : xAction.getMsg_failure(modelCount,modelsString);
										this.xPrompt.color = success ? "success"                                       : "error";
										
										await this.xPrompt.show(); //No matter what the user do, we won't care. Not supposed to throw
									}
								}
								
								this.xPrompt         = null;
								this.isDoingUserHook = false;
							},
					//SERVER
						/*
						WARNING:
							-For now, only called when used for server loading
							-Check warnings in final_server_nbRecords()
						*/
						on_paginationOrSort_change({page:paging_index, itemsPerPage:pagingSize, sortBy:sortedColList_names, sortDesc:sortedColList_isDesc})
						{
							//WARNING: Not sure if we should check w userForLoading() or final_fromLoader_use()
							if (this.final_fromLoader_use)
							{
								switch (this.final_fromLoader_bootState)
								{
									case GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_IDLE:
										this.final_fromLoader_bootState = GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTING;
									break;
									case GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTING:
										return; //Ignore event
									break;
									case GENERIC_LIST_CONSTS.FROM_LOADER_BOOT_STATES_BOOTED:
										//OK to continue
									break;
									default:
										this.throwEx(`Unhandled bootState "${this.final_fromLoader_bootState}"`);
									break;
								}
							}
							
							const searchOptions = this.assertGetModelListServerSearchOptions(); //Shouldn't throw if useForLoading, but JIC
							
							searchOptions.paging_size  = pagingSize===GENERIC_LIST_CONSTS.PAGING_SIZE_ALL ? B_REST_Model_Load_SearchOptions.PAGING_SIZE_ALL : pagingSize;
							searchOptions.paging_index = paging_index-1; //Vuetify isn't zero-based
							
							/*
							For sort, keep simple by just flushing our own sort params all the time
							NOTE:
								When we boot the list or when the code alts the sorts from outside the list,
								we will NOT set them correctly via <v-data-table :options="{page,itemsPerPage,sortBy,sortDesc}" /> because it'd be a pain,
								so we'll just do that next time user sorts via the list, we'll overwrite the search options and that's it
							*/
							searchOptions.orderByList_reset();
							for (let i=0; i<sortedColList_names.length; i++)
							{
								//NOTE: B_REST_Vuetify_GenericList_Col::toVDataTableHeaderDef() puts sortable=true only on cols w exactly 1 field
								
								const loop_fieldNamePath = this.cols_getByName(sortedColList_names[i]).fieldNamePaths;
								
								searchOptions.orderByList_add(loop_fieldNamePath, !sortedColList_isDesc[i]);
							}
							
							this.reloadList();
						},
						/*
						When we change sort, will call this once, and we have to ret a new arr sorted a diff way
						WARNING:
							-Not called when useForLoading
							-Don't implement a custom sort func in ModelList, because what we get here is a skimmed of arr of things after filters are applied
						*/
						on_sort_update(filteredItems, sortedColList_names, sortedColList_isDesc, locale, customSorters)
						{
							if (sortedColList_names.length===0) { return filteredItems; }
							
							const sortedItems = [...filteredItems];
							
							const sortFields = []; //Arr of {fieldNamePath, isASC}
							for (let i=0; i<sortedColList_names.length; i++)
							{
								/*
								NOTE: B_REST_Vuetify_GenericList_Col::toVDataTableHeaderDef() puts sortable=true only on cols w exactly 1 field
								WARNING: Could also point on a lookup, but for now we'll break if it happens; todo later
								*/
								
								const loop_col = this.cols_getByName(sortedColList_names[i]);
								if (!loop_col.fieldDescriptor_isDB) { this.throwEx(`For now, we don't support sorting by non DB cols, for "${loop_col.name}"`); }
								
								sortFields.push({
									fieldNamePath: loop_col.fieldNamePaths,
									isASC:         !sortedColList_isDesc[i],
									whenANotSet:   sortedColList_isDesc[i] ?  1 : -1, //NOTE: We might want to change that later, if we want NULLs at beginning or end
									whenBNotSet:   sortedColList_isDesc[i] ? -1 :  1, //NOTE: Same as the above
									whenABefore:   sortedColList_isDesc[i] ?  1 : -1,
									whenBBefore:   sortedColList_isDesc[i] ? -1 :  1,
								});
							}
							
							//-1:A first, 0:Skip, 1:B first
							sortedItems.sort((loop_a,loop_b) =>
							{
								for (const loop_sortField of sortFields)
								{
									const loop_a_field = loop_a.rowInfo.model.select(loop_sortField.fieldNamePath);
									const loop_b_field = loop_b.rowInfo.model.select(loop_sortField.fieldNamePath);
									
									if (loop_a_field.val===loop_b_field.val) { continue;                          }
									if (!loop_a_field.isSet)                 { return loop_sortField.whenANotSet; }
									if (!loop_b_field.isSet)                 { return loop_sortField.whenBNotSet; }
									return loop_a_field.val<loop_b_field.val ? loop_sortField.whenABefore : loop_sortField.whenBBefore;
								}
								
								return 0;
							});
							
							return sortedItems;
						},
					//CTRL+F3
						/*
						On each search term change, will call this for every cell of every row. However, since in final_items we only pass 1 cell, then it'll be fine
						WARNING:
							-Not called when useForLoading() :(
						*/
						on_ctrlF3_update(loop_cellVal, nonEmptySearchString, loop_item)
						{
							const loop_model = loop_item.rowInfo.model;
							
							//Either use the custom hook, or model's toLabel func
							if (this.ctrlF3_hook)
							{
								try
								{
									return this.ctrlF3_hook(this,loop_model,nonEmptySearchString);
								}
								catch (e) { this.logError(`CTRLF3 hook failed`,e); }
							}
							
							const toLabel = loop_model.toLabel(this.quotedName); //Can yield NULL
							if (toLabel!==null) { return toLabel.indexOf(nonEmptySearchString)!==-1; }
							
							//If model's toLabel() yield NULL, then check in all cols, one by one
							{
								const cols = loop_item.rowInfo.cols;
								
								for (const loop_colName in cols)
								{
									const loop_col        = cols[loop_colName];
									const loop_modelField = loop_col.modelField;
									if (!(loop_modelField instanceof B_REST_ModelFields.DB)) { continue; } //NOTE: Can also be NULL
									const loop_text = loop_modelField.valToText();
									if (loop_text!==null && loop_text.toString().indexOf(nonEmptySearchString)!==-1) { return true; }
								}
							}
							
							return false;
						},
					//FILTERS
						assertGetModelListServerSearchOptions()
						{
							return this.modelList.searchOptions || this.throwEx(`Needed B_REST_Model_Load_SearchOptions but we don't have`);
						},
						/*
						Opens & close the <BrGenericListBaseFilters>
						NOTES:
							Check filters_apply() & filters_reset() to see that when panel closes, we get a result obj like {action:<apply|reset>}
						IMPORTANT:
							We won't care about what happens when the panel closes, so if we want the modelList to reload w new filters, usage must take care of it, ex:
								Applying filters:  reloadList()
								Resetting filters: modelList.searchOptions.filters_reset() (not reloading)
						*/
						async filters_togglePanel()
						{
							if (this.filters_uses_inline) { this.filters_inlinePanelOpenState=!this.filters_inlinePanelOpenState; }
							//Otherwise when filters_uses_external, "teleport" the <BrGenericListBaseFilters> in the <BrRightDrawer>
							else
							{
								const rightDrawerOpenMethod = GENERIC_LIST_CONSTS.RIGHT_DRAWER_OPEN_SIDE_BREAKPOINTS.includes(this.currentBreakpoint_name) ? "open_side" : "open_modal";
								
								//Init if not yet done
								if (!this.filters_rightDrawerContent) { this.filters_rightDrawerContent=new B_REST_Vuetify_RightDrawerContent(this.t_alt('filters.label')); }
								
								if (this.filters_rightDrawerContent.isActive) { this.filters_rightDrawerContent.close({action:GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_CANCEL}); }
								else
								{
									const closeData = await this.filters_rightDrawerContent[rightDrawerOpenMethod]();
									//NOTE: Read method docs about why we do nothing w closeData
								}
							}
						},
						filters_getByName(name) { return this.filters.find(loop_col => loop_col.name===name); }, //Can ret NULL
						//Ret the promise
						async filters_apply()
						{
							if (this.filters_shouldClosePanel) { this._filters_done(GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_APPLY); }
							
							return this.modelList.reload();
						},
						//Ret the promise
						async filters_reset()
						{
							if (this.filters_shouldClosePanel) { this._filters_done(GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_RESET); }
							
							this.modelList.searchOptions.filters_reset();
							
							if (GENERIC_LIST_CONSTS.RELOAD_ON_RESET_FILTERS) { return this.modelList.reload(); } //Ret the promise
						},
							_filters_done(action)
							{
								if      (this.filters_uses_inline)                                               { this.filters_inlinePanelOpenState=false;         }
								else if (this.filters_uses_external && this.filters_rightDrawerContent.isActive) { this.filters_rightDrawerContent.close({action}); }
							},
					async on_selectedItems_change()
					{
						//If we're in single picker mode, as soon as we check something we should close - just do it delayed so we have time to see the animation
						if (this.selectedItems_has && this.final_isPicker_isSingle)
						{
							await this.$bREST.utils.sleep(GENERIC_LIST_CONSTS.PICKER_SINGLE_ANIMATION_CLOSE_DELAY);
							this.on_picker_submit();
						}
					},
					on_multiplePickerFooter_removeChip(item) { this.$bREST.utils.array_remove_byVal(this.selectedItems,item); },
					//PICKER
						/*
						Call this each time pickerHandle changes (ideally once data is loaded in the modelList)
						When we assign a new picker handle, we might want to show the list w some rows pre-selected, so do this here
						Will throw if the picker's selection isn't a single / multiple B_REST_Model instances
						*/
						picker_setup()
						{
							/*
							Ignore pickerHandle watch if we're not in picking mode (check B_REST_Vuetify_PickerHandle::_select() docs for why)
							Also in filters_uses_external mode, prevents embarrassingly leaving filters panel opened when we close the popup
							*/
							if (!this.pickerHandle?.isPrompting)
							{
								this._filters_done(GENERIC_LIST_CONSTS.RIGHT_DRAWER_ACTION_TAGS_CANCEL);
								return;
							}
							
							const selectedItems = [];
							
							if (this.pickerHandle && this.pickerHandle.selection_has)
							{
								const pickerSelectedItems = this.pickerHandle.isMultiple ? this.pickerHandle.selection : [this.pickerHandle.selection]; //Simplify as arr
								
								for (const loop_pickerSelectedItem of pickerSelectedItems)
								{
									const loop_fakeItem = this.toVDataTableItem(loop_pickerSelectedItem); //Expects that selected items be instances of B_REST_Model
									selectedItems.push(loop_fakeItem);
								}
							}
							
							this.selectedItems = selectedItems;
						},
						on_picker_cancel() { this.pickerHandle.cancel(); }, //Closes the picker
						on_picker_submit()
						{
							const selectedModels = [];
							for (const loop_selectedItem of this.selectedItems) { selectedModels.push(loop_selectedItem.rowInfo.model); }
							
							this.pickerHandle.submit(this.pickerHandle.isMultiple ? selectedModels : selectedModels[0]); //Closes the picker
						},
					//QUICK ADD FORM
						//Clears up the quick add form, so we can add another one, or next time we reuse the list it'll still be ok
						quickAddForm_cleanup() { this.quickAddForm_renewModel(); },
						/*
						Calls hook, potentially confirming first
						Depending on usage, hook must do some of:
							-Validating if we should add w model.validation_isValid
							-Doing model.save()
							-Doing modelList.prepend(model)
							-Doing modelList.add(model)
						For ex:
							-If it's for adding an invoiceDetail to an invoice, we prolly just want to add it and save later
							-If we're in a general list, we prolly want to always save the model right away,
								however maybe not add it to the actual modelList if it was pre-sorted or paginated and wouldn't make visual sense to see it there
						If hook resolves, will call quickAddForm_renewModel() so we can add again
						WARNING:
							If we decide to save the model in the hook and that it should have an FK to something, don't forget to do model.select("someFK").val=123
						*/
						async on_quickAddForm_submit()
						{
							this.isDoingUserHook = true;
							
							if (this.quickAddForm_mustConfirm || this.quickAddForm_displayResult)
							{
								this.xPrompt = new B_REST_Vuetify_Prompt(this.quickAddForm_title, true/*isModal*/);
							}
							
							//If we must freeze and ask user for confirmation
							let canContinue = true;
							if (this.quickAddForm_mustConfirm)
							{
								this.xPrompt.actions = [
									new B_REST_Vuetify_Prompt_Action("cancel", this.t_alt("prompt.cancel"), null), //NOTE: We have "prompt.no" too...
									null,
									new B_REST_Vuetify_Prompt_Action("yes", this.t_alt("prompt.yes"), "error"),
								];
								
								this.xPrompt.body = this.pickerHandle ? this.t_alt('quickAddForm.confirm.picker') : this.t_alt('quickAddForm.confirm.normal');
								
								const selectedActionOrNull = await this.xPrompt.show();
								if (selectedActionOrNull!=="yes") { canContinue=false; }
							}
							
							//Then do the action hook
							if (canContinue)
							{
								let success = false;
								
								try
								{
									//We count throwing errs and ret false as non success
									success = await this.quickAddForm_hook(this,this.modelList,this.quickAddForm_model);
								}
								catch (e) { this.logError(`Quick add form submit failed`,e); }
								
								//If we want to display whether it worked or not
								if (this.quickAddForm_displayResult)
								{
									this.xPrompt.actions = [
										null,
										new B_REST_Vuetify_Prompt_Action("ok", this.t_alt("prompt.ok")), //NOTE: We also have "prompt.close"
									];
									
									this.xPrompt.body  = success ? this.t_alt('quickAddForm.success') : this.t_alt('quickAddForm.failure');
									this.xPrompt.color = success ? "success"                          : "error";
									
									await this.xPrompt.show(); //No matter what the user do, we won't care. Not supposed to throw
									this.xPrompt = null;
								}
								
								if (success)
								{
									//If we're in picker mode, we'll also auto put it in selection
									if (this.pickerHandle)
									{
										const fakeItem = this.toVDataTableItem(this.quickAddForm_model);
										
										if (this.pickerHandle.isMultiple) { this.selectedItems.push(fakeItem); }
										else
										{
											this.selectedItems = [fakeItem];
											//WARNING: Don't do on_picker_submit() here (as long as we only have selectedItems to control selection and not 2 vars), because Vuetify will auto call it via on_selectedItems_change()
										}
									}
									
									//Always clear up the quick add form, so we can add another one, or next time we reuse the list it'll still be ok
									this.quickAddForm_cleanup();
								}
							}
							
							this.isDoingUserHook = false;
						},
				},
			},
		];
	};
	
	
	
	
	
	
	function validator_fromLoader(val)
	{
		if (val===undefined || val===true || B_REST_Utils.object_is(val)) { return true; }
		
		B_REST_Utils.throwEx(`Expected "fromLoader" to be not set, true, or an obj like {apiBaseUrl<string>:null, apiBaseUrl_path_vars<string>:null, reloader<async(<BrGenericListBase der>listComponent,modelList,routeInfo)>:null, routeInfo<B_REST_VueApp_RouteInfo>:null}`);
		return false;
	}
	
</script>

<style scoped>
	
	.br-generic-list {
		overflow-x: hidden; /* Req if filters_uses_inline, otherwise when inline filter drawer is closed, its translateX transform causes a huge gap to be scrollable to the right */
	}
	
</style>