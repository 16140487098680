
import { B_REST_Utils, B_REST_FieldDescriptors, B_REST_ModelFields, B_REST_Model_Load_SearchOptions_Filters } from "../../../../../classes";
import B_REST_VueApp_base                                                                                     from "../../../B_REST_VueApp_base.js";



const FILTER_OPS      = B_REST_Model_Load_SearchOptions_Filters.base; //Not actually a list of const, just a ptr to the B_REST_Model_Load_SearchOptions_Filter_base class
const FILTER_OPS_BTWS = [FILTER_OPS.OP_BTW, FILTER_OPS.OP_N_BTW];

const DB_TYPE_OP_MAP = {};
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_STRING]  = FILTER_OPS.OP_P_LIKE_P;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_INT]     = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_DECIMAL] = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_BOOL]    = FILTER_OPS.OP_EQ_IN;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_JSON]    = FILTER_OPS.OP_EQ_IN; //Must be OP_EQ_IN, because we'll send whatever as an arr of ints or tags
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_DT]      = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_D]       = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_T]       = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_C_STAMP] = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_U_STAMP] = FILTER_OPS.OP_BTW;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_ENUM]    = FILTER_OPS.OP_EQ_IN;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_PHONE]   = FILTER_OPS.OP_EQ_IN;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_EMAIL]   = FILTER_OPS.OP_EQ_IN;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_PWD]     = FILTER_OPS.OP_EQ_IN;
	DB_TYPE_OP_MAP[B_REST_FieldDescriptors.DB.TYPE_CUSTOM]  = FILTER_OPS.OP_EQ_IN;



/*
About having extra "IS NULL" or "IS NOT NULL" in filter :items:
	-A DB field w a NULL val is just "undefined", so if we want to search for something that's NULL, we should either:
		-Add a calc in DB saying if it's NULL or not, and search by that w a diff filter
		-Activate a second bool B_REST_CustomFilterDescriptor just for that
	-To search for things that aren't NULL, we should either:
		-Tick all :items for a massive IN()
		-Activate a second bool B_REST_CustomFilterDescriptor just for that
*/

export default class B_REST_Vuetify_GenericList_Filter
{
	_listComponent   = null; //BrGenericListBase der this belongs to
	_name            = null; //Name of a direct B_REST_FieldDescriptor_DB or B_REST_CustomFilterDescriptor in the modelList (doesn't work w subModels and lookups)
	_modelFieldOrArr = null; //B_REST_ModelField_x instances we'll CREATE just for the <BrGenericListBaseFilters>, based on a fieldDescriptor. Either a single instance or arr[2], depending on the wanted operator (ex a OP_BTW)
	_fieldDescriptor = null; //To make the _modelFieldOrArr works, they must be attached to a B_REST_FieldDescriptor_DB we'll ALWAYS create here. IMPORTANT: Even if _name is a known B_REST_FieldDescriptor_DB, we'll create a copy of it, since we might need diff {required, min, max, label, etc}
	_op_is_between   = null; //Helper to know if we wanted to have a OP_BTW or OP_N_BTW
	_componentAttrs  = null; //Required map of optional attrs like {items, multiple:<bool>, as:<string>, ...} (check BrFieldDb docs), to pass down to a <br-field-db>
	_label           = null; //Either the one of the B_REST_FieldDescriptor_DB we're refering to, or something else custom to the generic list
	_soFilter        = null; //Ptr on a B_REST_Model_Load_SearchOptions_Filter_x instance within the B_REST_ModelList of the generic list, where we'll put its val
		/*
		IMPORTANT:
			-Check notes above class about IS NULL / IS NOT NULL
			-If we add stuff, also add in toConstructorOptions()
		*/
	
	
	/*
	Options as:
		{
			name:                          Name of a direct B_REST_FieldDescriptor_DB or B_REST_CustomFilterDescriptor in the modelList (doesn't work w subModels and lookups)
			op:                            Auto calculated against field descriptor, or OP_AUTO by default. Specify when we want to force it to one of FILTER_OPS.OP_x
			optionalVal:                   For edge cases like when we've got a TYPE_BOOL and we want it to be always "filled" as false when not checked for ex
			componentAttrs:                Optional map of optional attrs like {items, multiple:<bool>, as:<string>, ...} (check BrFieldDb docs), to pass down to a <br-field-db>
			multiple:                      Notice: We can define multiple in componentAttrs, but also here too, which might make more sense when we're doing something custom; both will be taken in together
			ifCustomFilterWNoForeignModel: Options for when it's a custom filter, where !B_REST_CustomFilterDescriptor::foreignModel_has
			{
				fieldDescriptor: We must either provide our own fake B_REST_FieldDescriptor_DB instance
				type:            Or at least specify a B_REST_FieldDescriptor_DB::TYPE_x
			}
		}
	IMPORTANT:
		-Check notes above class about IS NULL / IS NOT NULL
		-If we add more props, we'll need to add them in toConstructorOptions() below too
	*/
	constructor(listComponent, name, options={})
	{
		options = B_REST_Utils.object_hasValidStruct_assert(options, {
			op:                            {accept:[String],  default:undefined},
			optionalVal:                   {accept:undefined, default:null},
			componentAttrs:                {accept:[Object],  default:{}}, //Things to pass to a <br-field-db>
			multiple:                      {accept:[Boolean], default:false},
			ifCustomFilterWNoForeignModel: {accept:[Object],  default:null},
		}, "Generic list filter");
		
		this._listComponent  = listComponent;
		this._name           = name;
		this._componentAttrs = options.componentAttrs;
		
		const customLocBasePath = `${this._listComponent.t_baseLocPath}.filters.${this._name}.${B_REST_VueApp_base.LOC_KEY_SHORT_LABEL}`; //Ex "components.contactPicker.filteres.firstName.shortLabel"
		const customLabel       = B_REST_VueApp_base.instance.t_custom_orNULL(customLocBasePath);
		const descriptor        = this._listComponent.descriptor;
		
		/*
		Since we can pass "multiple" directly in the options + in componentAttrs, make sure it's always around in the componentAttrs, or <br-field-db> won't know.
		We need this for B_REST_CustomFilterDescriptor, but if it produces side effects, we should prolly not...
		*/
		this._componentAttrs.multiple = this._componentAttrs.multiple || options.multiple || false;
		
		/*
		To make the _modelFieldOrArr works, they must be attached to a B_REST_FieldDescriptor_DB we'll ALWAYS create here
		IMPORTANT:
			Even if _name is a known B_REST_FieldDescriptor_DB, we'll create a copy of it, since we might need diff {required, min, max, label, etc}
		*/ 
		{
			let fieldDescriptor = null;
			let mustClone       = null;
			
			if (options.ifCustomFilterWNoForeignModel)
			{
				//Quick check to make sure it's an actual real custom filter
				if (!descriptor.customFilters_find(this._name,/*throwIfNotFound*/false)) { this._throwEx(`Must match a DB field or B_REST_CustomFilterDescriptor directly in "${descriptor.name}"`); }
					
				//NOTE: Only exactly one of these must be passed
				const ifCustomFilterWNoForeignModelOptions = B_REST_Utils.object_hasValidStruct_assert(options.ifCustomFilterWNoForeignModel, {
					fieldDescriptor: {accept:[B_REST_FieldDescriptors.DB], default:null},
					type:            {accept:[String],                     default:null}, //A const of B_REST_FieldDescriptor_DB.TYPE_x
				}, "Generic list filter custom");
				
				if (ifCustomFilterWNoForeignModelOptions.fieldDescriptor)
				{
					fieldDescriptor = ifCustomFilterWNoForeignModelOptions.fieldDescriptor;
					mustClone       = true; //Because we could get something where validation specs (req, min, max, etc) doesn't fit w what we want for the filter
				}
				else if (ifCustomFilterWNoForeignModelOptions.type)
				{
					fieldDescriptor = new B_REST_FieldDescriptors.DB(this._name, ifCustomFilterWNoForeignModelOptions.type, {
							isRequired:    false,
							isNullable:    true,
							wCustomSetter: false,
							setOnce:       B_REST_FieldDescriptors.DB.SET_ONCE_OFF,
							locBasePath:   customLocBasePath,
							optionalVal:   options.optionalVal,
						});
					mustClone = false;
				}
				else { this._throwEx(`Mentionned that it's a custom filter where !B_REST_CustomFilterDescriptor::foreignModel_has, but provided no fieldDescriptor nor type hint`); }
			}
			//Else _name must match a direct B_REST_FieldDescriptor_DB, or B_REST_CustomFilterDescriptor bound to a known foreign model field. Doesn't work w subModels and lookups
			else
			{
				const ownFieldDescriptor = descriptor.allFields_find(this._name,/*throwIfNotFound*/false); //NOTE: Don't use dbFields_x() because it doesn't contain special fields
				
				//If own DB field
				if (ownFieldDescriptor)
				{
					fieldDescriptor = ownFieldDescriptor;
				}
				//Then it must be a custom filter bound to a known foreign model field
				else
				{
					const customFilter = descriptor.customFilters_find(this._name, /*throwIfNotFound*/false);
					if (!customFilter) { this._throwEx(`Must match a DB field or B_REST_CustomFilterDescriptor directly in "${descriptor.name}"`); }
					
					if (!customFilter.foreignModel_has) { this._throwEx(`When matching a !B_REST_CustomFilterDescriptor::foreignModel_has, must use the "ifCustomFilterWNoForeignModel" prop in the options`); }
					
					fieldDescriptor = customFilter.foreignModel_fieldDescriptor;
				}
				
				mustClone = true; //IMPORTANT: Even if _name is a known B_REST_FieldDescriptor_DB or custom filter's foreign model's B_REST_FieldDescriptor_DB, we'll create a copy of it, since we might need diff {required, min, max, label, etc}
			}
			
			//Now finalize assignation of a B_REST_FieldDescriptor_DB
			{
				//Catchall code to make sure that all paths end up w a valid B_REST_FieldDescriptor_DB
				if (!(fieldDescriptor instanceof B_REST_FieldDescriptors.DB)) { this._throwEx(`For now, can only use B_REST_FieldDescriptor_DB instances`); }
				
				//For now, it doesn't make sense to want to have a multiple on something that only has 2 choices + since we'd convert to TYPE_JSON, final_items() will not go in the branch for TYPE_BOOL, so we'd also need to provide :items ourselves
				if (this._componentAttrs.multiple && fieldDescriptor.type_is_bool) { this._throwEx(`For now, can't put a TYPE_BOOL multiple`); }
				
				//Then clone, or validate that the one we don't need to clone is a TYPE_JSON if we want multiple (for now)
				if (mustClone)
				{
					const finalType = this._componentAttrs.multiple ? B_REST_FieldDescriptors.DB.TYPE_JSON : fieldDescriptor.type;
					
					//When we're reusing field descriptors, it's better not to take their optionalVal prop, otherwise ex int BTW will have 0z all the time
					const optionalVal = options.optionalVal; //NOTE: We could also go furthermore with: !this._componentAttrs.multiple&&fieldDescriptor.type_is_bool ? fieldDescriptor.optionalVal : null
					
					fieldDescriptor = new B_REST_FieldDescriptors.DB(this._name, finalType, {
						isRequired:    false,
						isNullable:    true, //Should leave this to false, otherwise we can't clear inputs
						wCustomSetter: fieldDescriptor.wCustomSetter,
						setOnce:       fieldDescriptor.setOnce,
						loc:           fieldDescriptor.loc,
						isPKField:     fieldDescriptor.isPKField,
						optionalVal,
						min:           !this._componentAttrs.multiple ? fieldDescriptor.min      : null,
						max:           !this._componentAttrs.multiple ? fieldDescriptor.max      : null,
						decimals:      !this._componentAttrs.multiple ? fieldDescriptor.decimals : null,
						enum_members:  fieldDescriptor.enum_members,
						lookupInfo:    !this._componentAttrs.multiple && fieldDescriptor.lookup_is ? {modelName:fieldDescriptor.lookup_modelName,fieldName:fieldDescriptor.lookup_fieldName} : null,
					});
				}
				else if (this._componentAttrs.multiple && !fieldDescriptor.type_is_json) { this._throwEx(`For now, can only work in multiple mode w TYPE_JSON DB fields`); }
				
				this._fieldDescriptor = fieldDescriptor;
			}
		}
		
		/*
		Even if fieldDescriptor usually holds a label, have one ready here so we can decide whether we want to use a custom one or either of fieldDescriptor's label vs shortLabel
		We could even check if the componentAttrs specified one
		Then, we can pass this down to <br-field-db :label="...">
		*/
		{
			this._label = this._componentAttrs.label ?? customLabel ?? this._fieldDescriptor.shortLabel;
			
			//If still NULL or reported as not found via the field descriptor (prolly because we've created a fake one)
			if (this._label===null || this._label.match(/^%.+%$/))
			{
				this._label = `%${customLocBasePath}%`;
				B_REST_VueApp_base.instance.t_custom_warnNotFound(customLocBasePath);
			}
		}
		
		//Figure out the op to use
		let op = null;
		{
			//If we specified it. Note that OP_AUTO=null, so we must check against undefined
			if (options.op!==undefined) { op=options.op; }
			//If we want a picker opening a generic list, we'll have to use OP_EQ_IN
			else if (B_REST_Utils.object_hasPropName(this._componentAttrs,"picker")) { op=FILTER_OPS.OP_EQ_IN; }
			//Same for when we define :items to use
			else if (B_REST_Utils.object_hasPropName(this._componentAttrs,"items")) { op=FILTER_OPS.OP_EQ_IN; }
			//Otherwise, guess against the field's type
			else
			{
				const type = this._fieldDescriptor.type;
				
				if (!B_REST_Utils.object_hasPropName(DB_TYPE_OP_MAP,type)) { this._throwEx(`Unhandled DB field type "${type}"`); }
				
				op = DB_TYPE_OP_MAP[type];
			}
			
			//Setup a helper
			this._op_is_between = FILTER_OPS_BTWS.includes(op);
		}
		
		//Now we can create the modelfield(s) based on the fieldDescriptor and wanted op
		{
			if (this._op_is_between) { this._modelFieldOrArr = [new B_REST_ModelFields.DB(this._fieldDescriptor),new B_REST_ModelFields.DB(this._fieldDescriptor)]; }
			else                     { this._modelFieldOrArr =  new B_REST_ModelFields.DB(this._fieldDescriptor);                                                   }	
		}
		
		//Finaly make or reuse the filter entry in the generic list's modelList's B_REST_Model_Load_SearchOptions_Filter_x filters
		{
			const soFilter = this._listComponent.modelList.searchOptions.f_specifyOp(this._name, op, /*throwIfExists*/false);
			
			soFilter.modelFieldOrArr_reassign(this._modelFieldOrArr, /*keepPreviousVals*/false);
			
			this._soFilter = soFilter;
		}
	}
	
	_throwEx(msg, details=null) { B_REST_Utils.throwEx(`${this.debugName}: ${msg}`,details); }
	
	
	get listComponent()   { return this._listComponent;                                                                     }
	get debugName()       { return `B_REST_Vuetify_GenericList_Filter<${this._name}@${this._listComponent.componentName}>`; }
	get name()            { return this._name;                                                                              }
	get slotName()        { return `filter.${this._name}`;                                                                  } //To do something like <template #filter.xxx>
	get fieldDescriptor() { return this._fieldDescriptor;                                                                   }
	get componentAttrs()  { return this._componentAttrs;                                                                    }
	get label()           { return this._label;                                                                             }
	get soFilter()        { return this._soFilter;                                                                          }
	get isSet()           { return this._soFilter.isSet;                                                                    }
	get op()              { return this._soFilter.op;                                                                       }
	get op_is_between()   { return this._op_is_between;                                                                     }
	
	get modelField()
	{
		if (this._modelFieldOrArr===null) { this._throwEx(`Not using model fields`);                                  }
		if (this._op_is_between)          { this._throwEx(`For OP_BTW & OP_N_BTW, use modelField_x/y props instead`); }
		
		return this._modelFieldOrArr;
	}
	get modelField_x() { return this._modelField_xy(0); }
	get modelField_y() { return this._modelField_xy(1); }
		_modelField_xy(idx)
		{
			if (this._modelFieldOrArr===null)  { this._throwEx(`Not using model fields`);                                                   }
			if (!this._op_is_between)          { this._throwEx(`For non OP_BTW & OP_N_BTW, use modelField prop instead of modelField_x/y`); }
			
			return this._modelFieldOrArr[idx];
		}
};
