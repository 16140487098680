<template>
	<br-app-booter text-color="white" background-color="#0b3d46" :logo="logo" :logo-width="200" :background-image="backgroundImage">
		<!--
			NOTE: We can override the default slot like this: Check BrAppBooter.vue for more info
				<template #default="{isSpinning, errorMsg, reboot}">...</template>
		-->
	</br-app-booter>
</template>
<script>
	
	export default {
		data()
		{
			return {
				logo: require("@/custom/assets/logo-icon.svg"),
				backgroundImage: require("@/custom/assets/jungle-bg--vertical.png"),
			};
		},
	};
	
</script>
<style> @import './App.css'; </style>
