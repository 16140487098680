<template>
	<kb-brand-form reason="module" />
</template>

<script>

	export default
	{
		components: {
			KbBrandForm: () => import("@/custom/components/modules/entity_base/forms/KbBrandForm.vue"),
		}
	};
	
</script>