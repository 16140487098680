<template>
	
	<v-container class="fill-height" style="max-width: 600px">
		
		<v-row>
			<v-col cols="auto"> <br-field-db :field="currentCountry" :items="dataSets.countries" items-flat-search-name="name" @change="on_country_change" /> </v-col>
		</v-row>
		<v-row>
			
			<v-col cols="12" v-if="currentCountry.val">
				<v-card :loading="isLoadingOrSaving" :disabled="isLoadingOrSaving">
					<v-tabs slider-color="primary" slider-size="4" background-color="secondary" right fixed-tabs show-arrows dark>
						
						<template v-for="loop_type in types">
							
							<v-tab :key="`tab-${loop_type.tag}`">{{ loc_shortTitle(loop_type) }}</v-tab>
							<v-tab-item :key="`tabItem-${loop_type.tag}`">
								<v-container fluid>
									
									<v-alert type="info" dense text border="left">
										<h3 class="text-subtitle-1"  v-text="loc_longTitle(loop_type)" />
										<div class="text-caption"    v-text="loc_desc(loop_type)" />
										<v-divider class="my-1 info" style="opacity: 0.22" /> <!-- Need these classes, otherwise it'll be the same color as the bg of the alert -->
										<div class="text-caption"    v-text="loc_example(loop_type)" />
									</v-alert>
									
									<package-tier-item-list v-if="loop_type.model" :from-model-list="loop_type.modelList" />
									<v-skeleton-loader v-else type="article" />
									
								</v-container>
							</v-tab-item>
							
						</template>
						
					</v-tabs>
				</v-card>
			</v-col>
			
			<v-col cols="12" class="text-right">
				<v-btn color="primary" @click="on_save_click">{{ _loc("actions.save.label") }}<v-icon>mdi-content-save</v-icon></v-btn>
			</v-col>
		</v-row>
		
	</v-container>
	
</template>
<script>
	
	import { B_REST_FieldDescriptors, B_REST_Model } from "@/bREST/core/classes";
	
	const locBasePath = "views.packageTiers";
	
	
	
	
	export default {
		components: {
			PackageTierItemList: () => import("./PackageTierItemList.vue"),
		},
		data()
		{
			return {
				currentCountry: B_REST_FieldDescriptors.DB.create_type_int("currentCountry", {locBasePath:`${locBasePath}.currentCountry`, isRequired:true}).factory_modelField(),
				types: [
					{tag:"franchisorCreditToken", model:null, modelList:null},
					{tag:"brandMembership",       model:null, modelList:null},
					{tag:"consultantMembership",  model:null, modelList:null},
				],
				isLoadingOrSaving: false,
				dataSets: {
					countries: this.$bREST.sharedLists_getSrc("countryAndSubRegionList"), //Instance of B_REST_ModelList
				},
			};
		},
		created()
		{
			this.currentCountry.val = this.dataSets.countries.first.pk;
			this.reLoadCountryDataTables();
		},
		methods: {
			_loc(tag,details=null)   { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			_loc_type_x(type,subTag) { return this._loc(`types.${type.tag}.${subTag}`); },
			loc_shortTitle(type)     { return this._loc_type_x(type,"shortTitle");      },
			loc_longTitle(type)      { return this._loc_type_x(type,"longTitle");       },
			loc_desc(type)           { return this._loc_type_x(type,"desc");            },
			loc_example(type)        { return this._loc_type_x(type,"example");         },
			on_country_change()
			{
				this.reLoadCountryDataTables();
			},
			async reLoadCountryDataTables()
			{
				this.isLoadingOrSaving = true;
				
				const country_region_fk = this.currentCountry.val;
				
				try
				{
					for (const loop_type of this.types)
					{
						//Do this, so form appears to be loading
						loop_type.model     = null;
						loop_type.modelList = null;
						
						const loop_model     = await loadOnePackageTier(country_region_fk, loop_type.tag);
						const loop_modelList = loop_model.select("items").modelList;
						
						loop_type.modelList = loop_modelList;
						loop_type.model     = loop_model;
					}
				}
				catch (e)
				{
					this.$bREST.utils.console_error(`Got err while loading country data tables: ${e}`);
				}
				
				this.isLoadingOrSaving = false;
			},
			async on_save_click()
			{
				this.isLoadingOrSaving = true;
				
				const country_region_fk = this.currentCountry.val;
				
				try
				{
					for (const loop_type of this.types)
					{
						await loop_type.model.save({
							apiBaseUrl: "/config/regions/{region}/packageTiers/{type}/",
							apiBaseUrl_path_vars: {
								region:          country_region_fk,
								type:            loop_type.tag,
							},
						});
						
						loop_type.model.userTouch_toggleAllFields(false);
					}
				}
				catch (e)
				{
					this.$bREST.utils.console_error(`Got err while loading country data tables: ${e}`);
				}
				
				this.isLoadingOrSaving = false;
			},
		},
	};
	
	
	
	//Rets a B_REST_Model
	async function loadOnePackageTier(country_region_fk, type)
	{
		const model = await B_REST_Model.commonDefs_load_one("PackageTier", {
			apiBaseUrl: "/config/regions/{region}/packageTiers/{type}/",
			apiBaseUrl_path_vars: {
				region:          country_region_fk,
				type:            type,
			},
			throwOnNotFound: false,
			requiredFields: "<all>",
		});
		
		if (!model) { this.$bREST.utils.throwEx(`Couldn't find package tier "${country_region_fk}-${type}"`); }
		
		return model;
	}
	
</script>