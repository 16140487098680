<template>
	<v-text-field :value="value" @change="$emit('input',$event)" v-bind="$attrs" />
</template>

<script>
	
	export default {
		props: {
			value: {type:null, required:false, default:null},
		},
	};
	
</script>