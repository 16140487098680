
import GFile_base from "../../utils/GFile_base.js";



export default class GFile_PHPCustom extends GFile_base
{
	_model = null;
	
	constructor(model)
	{
		super();
		
		this._model = model;
	}
	
	
	get _abstract_title()    { return `bREST_Custom for routes`;                   }
	get _abstract_filePath() { return `SERVER/custom/<projName>/bREST_Custom.php`; }
	
	//Ex if we have "/some/module/stuff/", then just get "some"
	get apiTopContext() { return this._model.apiBaseUrl_finalWOTrailingSlashes.split("/")[0]; }
	
	
	
	output_sharedListsStuff()
	{
		if (!this._model.isSharedList) { return null; }
		
		let loadOptionsString = null;
		if (this._model.hasLocSubModel)
		{
			loadOptionsString = `$loadOptions = new ModelOptions_Load('loc');\n\t\t\t\t$loadOptions->subOptions_add('loc')->field_filters_static()->f_equalOrIN('lang',$lang);`;
		}
		else
		{
			loadOptionsString = `$loadOptions = new ModelOptions_Load('<dbOnly>');`;
		}
		
		return `
	protected function _abstract_sharedLists_getTags()
	{
		return array
		(
			...
			'${this._model.className_list}' => array('type'=>self::SHARED_LIST_TYPES_MODEL_LIST, 'modelClassName'=>'${this._model.Model_ClassName}'),
			...
		);
	}
	protected function _abstract_sharedLists_load($sharedListTag, $lang)
	{
		switch ($sharedListTag)
		{
			...
			case '${this._model.className_list}':
				${loadOptionsString}
				return ${this._model.Model_ClassName}::load_list($loadOptions);
			...
		}
	}`;
	}
	
	output_makeRouteParser()
	{
		if (!this._model.routes_has) { return null; }
		
		return `
	protected function _abstract_parseRoute_makeRouteParser($method, $path, $path_topContext, $qsa)
	{
		...
		switch ($path_topContext)
		{
			...
			case '${this.apiTopContext}': self::routeParser_include_once("${this._model.modelClassName}.php"); return new ${this._model.RouteParser_ClassName}();
			...
		}
		...
	}`;
	}
	
	
	
	_abstract_output()
	{
		const output_sharedListsStuff = this.output_sharedListsStuff();
		const output_makeRouteParser  = this.output_makeRouteParser();
		
		if (!output_sharedListsStuff && !output_makeRouteParser) { return "// No need for this model"; }
		
		return `<?

class bREST_Custom extends bREST_base
{
	...
	
	${output_sharedListsStuff}
	${output_makeRouteParser}
	
	...
};
		`;
	}
};
