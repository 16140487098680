
import GFile_base from "../../utils/GFile_base.js";



export default class GFile_UILoc extends GFile_base
{
	_form = null;
	_lang = null;
	
	constructor(form, lang)
	{
		super();
		
		this._form = form;
		this._lang = lang;
	}
	
	get _abstract_title()    { return `Frontend custom loc - ${this._lang}`;   }
	get _abstract_filePath() { return `<custom>/loc/${this._lang}.json`; }
	
	
	static _makeOpeningBraces(locBasePath)
	{
		if (!locBasePath) { return ""; }
		
		const parts = locBasePath.split(".");
		
		let opening = "";
		
		for (let i=0; i<parts.length; i++)
		{
			const loop_tabs = "\t".repeat(i+1);
			
			opening += `${loop_tabs}...\n${loop_tabs}"${parts[i]}": {\n`;
		}
		
		return opening;
	}
	static _makeClosingBraces(locBasePath)
	{
		if (!locBasePath) { return ""; }
		
		const parts = locBasePath.split(".");
		
		let closing = "";
		
		for (let i=parts.length; i>0; i--)
		{
			const loop_tabs = "\t".repeat(i);
			
			closing += `${loop_tabs}}\n${loop_tabs}...\n`;
		}
		
		return closing;
	}
	
	_abstract_output()
	{
		const form_openingBraces = GFile_UILoc._makeOpeningBraces(this._form.formName_locBasePath);
		const form_closingBraces = GFile_UILoc._makeClosingBraces(this._form.formName_locBasePath);
		
		return `{
	... IMPORTANT: Don't forget to add "," at the right places
${form_openingBraces}\t\t\t"title": {"new":"${this._form.modelClassName} - Nouveau", "existing":"${this._form.modelClassName} #{pkTag} ({autoLabel})"}
${form_closingBraces}
}`;
	}
};
