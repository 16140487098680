<template>
	<v-container class="text-left reg-container align-start justify-center" :class="[isPublicReg?'reg-container--is-public-reg':null, $bREST.uiBreakpoint.mobile?null:'fill-height']" v-scroll="directive_onScroll" v-resize="directive_onResize">
		
		<div v-if="showAlreadyHaveAccountSuggestion" class="text-center mt-n6 mb-4">
			<v-btn to="/login" color="primary"><v-icon>mdi-account</v-icon> {{ _loc("login") }}</v-btn>
		</div>
		
		<v-skeleton-loader v-if="!isMounted" type="card, article@2, actions" width="100%" />
		
		<v-stepper v-else-if="layoutMode==='mobileStepper'" :value="current_page_idx+1" @change="mobileStepper_onChange" non-linear class="mobileStepper">
			<v-stepper-header class="white">
				<v-stepper-step v-for="(loop_page, loop_page_idx) in pages" :key="loop_page.tag"
				                :step="mobileStepper_page_stepNumber(loop_page)"
								:editable="!(loop_page_idx>0 && model.isNew)"
				                v-bind="mobileStepper_page_iconProps(loop_page)"
				                class="px-3"
				                @click="scrollToTop(flagFranchiseBanner_bottomPageYOffset)"
				>
					{{ loop_page.label }}
				</v-stepper-step>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content v-for="(loop_page, loop_page_idx) in pages" :key="loop_page.tag" :step="mobileStepper_page_stepNumber(loop_page)" class="px-0 px-md-6">
					
					<v-row> <v-col cols="12" class="text-center"> <h2>{{ loop_page.label }}</h2> </v-col> </v-row>
					
					<v-sheet class="ma-4">
						<component v-for="(loop_questionComponent, loop_question_idx) in loop_page.questionComponents"
						           :key="loop_questionComponent"
						           :is="loop_questionComponent"
						           :class="loop_question_idx!==0 ? 'mt-10' : null"
						           :reason="ifQuestionComponentIskbEntityBaseForm_specifyReason(loop_questionComponent)"
						           :with-model="model"
						/>
						
						<kb-accept-terms-conds v-if="loop_page_idx===pages.length-1" class="my-10" @change="on_acceptTerms_change" :show-checkbox="true" /> <!-- WARNING: Must do mods here at 2 places in file -->
					</v-sheet>
					<v-row justify="center" class="mb-4">
						<v-col cols="auto">
							<v-btn v-if="navigate_back_can" color="primary" class="navigation-btn" @click="navigate_back"> <v-icon v-if="savesOnNavigate">mdi-content-save</v-icon> {{ _loc("navigate_back") }}</v-btn>
						</v-col>
						<v-col cols="auto">
							<v-btn v-if="navigate_next_can" color="primary" class="navigation-btn"        @click="navigate_next"> <v-icon v-if="savesOnNavigate">mdi-content-save</v-icon> {{ _loc("navigate_next") }}</v-btn>
							<v-btn v-else                   color="primary" :disabled="!navigate_out_can" @click="navigate_out">  <v-icon v-if="savesOnNavigate">mdi-content-save</v-icon> {{ navigate_out_label }} </v-btn>
						</v-col>
					</v-row>
					
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		
		<v-card v-else-if="layoutMode==='desktopTabs'" width="100%">
			<v-toolbar flat color="primary" dark>
				<v-toolbar-title>{{ _loc("title") }}</v-toolbar-title>
			</v-toolbar>
			
			<!-- Transition glitch: Could try to wrap the <v-tab-item> loop in a <v-tab-items continuous> -->
			
			<v-tabs vertical slider-size="4" v-model="current_page_tag" @change="desktopTabs_onChange" class="desktopTabs" :class="desktopTabs_stickHeader ? 'v-tabs--is-sticky' : null">
				
				<template v-for="(loop_page, loop_page_idx) in pages">
					<v-tab :key="loop_page.tag" :href="'#'+loop_page.tag" :disabled="loop_page_idx>0 && model.isNew" @click="scrollToTop()" :class="loop_page.isFilled ? 'success--text' : 'secondary--text'">
						<v-icon left :color="loop_page.isFilled ? 'success' : 'secondary'">{{ loop_page.icon }}</v-icon>{{ loop_page.label }}
					</v-tab>
					<v-tab-item :key="loop_page.tag" :value="loop_page.tag" :eager="loop_page_idx===0"> <!-- https://vuetifyjs.com/en/api/v-tab-item/#eager -->
						
						<v-sheet class="ma-4">
							<component v-for="(loop_questionComponent, loop_question_idx) in loop_page.questionComponents"
							           :key="loop_questionComponent"
							           :is="loop_questionComponent"
							           :class="loop_question_idx!==0 ? 'mt-10' : null"
							           :reason="ifQuestionComponentIskbEntityBaseForm_specifyReason(loop_questionComponent)"
							           :with-model="model"
							/>
							
							<kb-accept-terms-conds v-if="loop_page_idx===pages.length-1" class="my-10" @change="on_acceptTerms_change" :show-checkbox="true" /> <!-- WARNING: Must do mods here at 2 places in file -->
						</v-sheet>
					<v-row justify="center" class="mb-4">
						<v-col cols="auto">
							<v-btn v-if="navigate_back_can" color="primary" class="navigation-btn" @click="navigate_back"> <v-icon v-if="savesOnNavigate">mdi-content-save</v-icon> {{ _loc("navigate_back") }}</v-btn>
						</v-col>
						<v-col cols="auto">
							<v-btn v-if="navigate_next_can" color="primary" class="navigation-btn"        @click="navigate_next"> <v-icon v-if="savesOnNavigate">mdi-content-save</v-icon> {{ _loc("navigate_next") }}</v-btn>
							<v-btn v-else                   color="primary" :disabled="!navigate_out_can" @click="navigate_out">  <v-icon v-if="savesOnNavigate">mdi-content-save</v-icon> {{ navigate_out_label }} </v-btn>
						</v-col>
					</v-row>
						
					</v-tab-item>
				</template>
				
			</v-tabs>
		</v-card>
		
		
		
		<div v-if="isMounted" class="go-to-btns" :class="hasDesktopBottomNavigation ? 'go-to-btns--has-desktop-bottom-navigation' : null">
			<v-fab-transition>
				<v-btn v-show="scrollToBtn_top_show" fab dark fixed bottom right class="go-to-btn--top" color="primary" @click="scrollToTop()">
					<v-icon>mdi-chevron-up</v-icon>
				</v-btn>
			</v-fab-transition>
			<!--
				Now, we display that btn only if we're not on the navigate_out page. If we want to add it for the last page, we'd also need to do this:
					@click="navigate_next_can ? save() : navigate_out()"
			-->
			<v-btn v-if="model.isMutable && navigate_next_can" fab dark fixed bottom right class="go-to-btn--middle" color="primary" @click="save">
				<v-icon>mdi-content-save</v-icon>
			</v-btn>
			<v-fab-transition>
				<v-btn v-show="scrollToBtn_bottom_show" fab dark fixed bottom right class="go-to-btn--bottom" color="primary" @click="scrollToBottom()">
					<v-icon>mdi-chevron-down</v-icon>
				</v-btn>
			</v-fab-transition>
		</div>
		
		<v-overlay v-if="isSaving"> <v-progress-circular indeterminate size="64" /> </v-overlay>
		
	</v-container>
</template>

<script>
	
	import { B_REST_App_base, B_REST_Model } from "@/bREST/core/classes";
	
	const locBasePath = "views.registrationForm";
	

	
	
	export class KbRegistrationFlow_Page
	{
		tag                = null;
		label              = null;
		icon               = null;
		questionComponents = []; //Arr of component names, ex "kb-compat-question-skill-sectors"
		showPrivacyText    = false;
		isFilled           = false;
		
		constructor(tag, icon, questionComponents)
		{
			this.tag                = tag;
			this.label              = B_REST_App_base.instance.t_custom(`${locBasePath}.steps.${tag}`);
			this.icon               = icon;
			this.questionComponents = questionComponents;
		}
	};
	
	
	
	//To wait until transitions & loading of sub components are done (should use callbacks instead, but...)
	const NAV_BTN_DELAY_MSECS = 500;
	const SCROLL_TO_BTN_RECALC_DELAY_MSECS = 500;
	const SAVE_ON_NAVIGATE = true;
	
	
	export default {
		props: {
			pages: {type:Array, required:true}, //Arr of KbRegistrationFlow_Page instances
		},
		data()
		{
			return {
				model: null,
				acceptTerms: false,
				isMounted: false,
				isSaving: false,
				current_page_tag:  this.pages[0].tag,
				previous_page_tag: this.pages[0].tag, //We need this because desktop & mobile tabs/steps controls don't have an onbefore event
				scrollToBtn_top_show: false,
				scrollToBtn_bottom_show: true,
				layoutMode: this.$bREST.uiBreakpoint.mobile ? "mobileStepper" : "desktopTabs", //IMPORTANT: Don't change this when we resize screen, or user will lose everything inputed in sub components
				//Things for when we use <v-tabs>
				desktopTabs_stickHeader: false,
			};
		},
		async created()
		{
			const route_entity_info = this.$bREST.route_entity_info;
			const type              = route_entity_info.type;
			const pk                = route_entity_info.entity_base_pk;
			const isNew             = pk===null;
			const canEdit           = isNew || this.$bREST.user_type==="admin" || this.$bREST.entity_pk==pk;
			
			let defName        = null; //Lead|Brand|Franchisor|Consultant
			let requiredFields = null;
			
			switch (type)
			{
				case "lead":
					defName        = "Lead";
					requiredFields = "<all>|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName|compatQuestions.common.<dbOnly>|compatQuestions.<dbOnly>";
				break;
				case "brand":
					defName        = "Brand";
					requiredFields = "<all>|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName|franchisor.cieName|franchisor.administrators|compatQuestions.common.<dbOnly>|compatQuestions.<dbOnly>";
				break;
				case "franchisor":
					defName        = "Franchisor";
					requiredFields = "<all>|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName";
				break;
				case "consultant":
					defName        = "Consultant";
					requiredFields = "<all>|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName|dues_referring.calc_label|dues_consultant.calc_label|dues_referring.user.firstName|dues_consultant.user.firstName";
				break;
				default:
					this.$bREST.utils.throwEx(`Unexpected user type "${type}"`);
				break;
			}
			
			this.model = isNew ? B_REST_Model.commonDefs_make(defName) : await B_REST_Model.commonDefs_load_pk(defName,pk,{requiredFields});
			
			if (!canEdit) { this.model.isReadOnly=true; }
			
			this.isMounted = true;
		},
		computed: {
			pageCount() { return this.pages.length; },
			savesOnNavigate() { return this.model.isNew || SAVE_ON_NAVIGATE; },
			current_page_idx()
			{
				if (this.current_page_tag===null) { return null; }
				
				return this.pages.findIndex(loop_page => loop_page.tag===this.current_page_tag);
			},
			navigate_back_can()  { return this.current_page_idx > 0;                                       },
			navigate_next_can()  { return this.current_page_idx < this.pageCount-1;                        },
			navigate_out_label() { return this._loc(`navigate_out.${this.$bREST.route_entity_info.type}`); },
			navigate_out_can()   { return !!this.acceptTerms;                                              },
			flagFranchiseBanner_bottomPageYOffset()
			{
				if (this.isPublicReg) { return 90; }
				
				const domHeader = document.querySelector('header.v-sheet');
				return domHeader.offsetTop+domHeader.offsetHeight + 50;
			},
			isPublicReg() { return this.$bREST.routes_current_name?.indexOf("public-registration-")!==-1 || false; }, //if we're in public regs with diff banner and footer
			showAlreadyHaveAccountSuggestion() { return this.isPublicReg && this.$bREST.user_isNew; },
			hasDesktopBottomNavigation() { return this.isPublicReg && this.$bREST.uiBreakpoint.mdAndUp; },
		},
		methods: {
			_loc(tag,details=null) { return this.$bREST.t_custom(`${locBasePath}.${tag}`,details); },
			//Questions stuff
				ifQuestionComponentIskbEntityBaseForm_specifyReason(questionComponent)
				{
					return ["kb-lead-form","kb-brand-form","kb-franchisor-form","kb-consultant-form"].includes(questionComponent) ? "reg" : null; //Check these components "reason" prop
				},
			//Pages stuff
				page_get_idx(page) { return this.pages.indexOf(page); },
			//Navigation stuff
				async _checkSave()
				{
					if (!this.model.isMutable) { return true; }
					
					let canNavigate = true;
					
					try
					{
						//NOTE: model.extraData_ui.x for files is built in KbXForm.vue::created()
						
						if (this.model.isNew)
						{
							let hook_afterAPICall_cv   = null;
							let hook_afterAPICall_logo = null;
							let hook_afterAPICall_docs = null;
							let hook_afterAPICall_pics = null;
							
							if (!this.model.validation_isValid)
							{
								const errors = this.model.validation_getErrors(/*detailed*/false).join("\n\t-");
								this.$bREST.notifs_tmp({msg:this._loc("navigate_save.validationError",{errors}), color:"error"});
								return;
							}
							
							this.isSaving = true;
							
							await this.model.save({
								apiBaseUrl_needsAccessToken: false,
								beforeSave: async(request, model) =>
								{
									if (model.extraData_ui.pwd.isNotEmpty) { request.data_set("pwd_frontendHash",model.extraData_ui.pwd.pwdVal_toFrontendHash()); }
									
									if(model.extraData_ui.cv_modelFileFieldControl)   { hook_afterAPICall_cv  =await model.extraData_ui.cv_modelFileFieldControl.anyModel_waitOrphansWithChangesToSave_preparePostData(request);   }
									if(model.extraData_ui.logo_modelFileFieldControl) { hook_afterAPICall_logo=await model.extraData_ui.logo_modelFileFieldControl.anyModel_waitOrphansWithChangesToSave_preparePostData(request); }
									if(model.extraData_ui.docs_modelFileFieldControl) { hook_afterAPICall_docs=await model.extraData_ui.docs_modelFileFieldControl.anyModel_waitOrphansWithChangesToSave_preparePostData(request); }
									if(model.extraData_ui.pics_modelFileFieldControl) { hook_afterAPICall_pics=await model.extraData_ui.pics_modelFileFieldControl.anyModel_waitOrphansWithChangesToSave_preparePostData(request); }
								},
								afterSave: async(response, model, isSuccess, wasNew) =>
								{
									//Stuff for files
									{
										const xFiles_pathVars = {};
										{
											const route_entity_info = this.$bREST.route_entity_info;
											const type              = route_entity_info.type;
											xFiles_pathVars[type] = model.pk;
										}
										
										if (hook_afterAPICall_cv)
										{
											hook_afterAPICall_cv(response,/*isSuccess*/true);
											model.extraData_ui.cv_modelFileFieldControl.savedModel_path_vars  = xFiles_pathVars;
											model.extraData_ui.cv_modelFileFieldControl.savedModel_autoUpdate = true;
										}
										if (hook_afterAPICall_logo)
										{
											hook_afterAPICall_logo(response,/*isSuccess*/true);
											model.extraData_ui.logo_modelFileFieldControl.savedModel_path_vars  = xFiles_pathVars;
											model.extraData_ui.logo_modelFileFieldControl.savedModel_autoUpdate = true;
										}
										if (hook_afterAPICall_docs)
										{
											hook_afterAPICall_docs(response,/*isSuccess*/true);
											model.extraData_ui.docs_modelFileFieldControl.savedModel_path_vars  = xFiles_pathVars;
											model.extraData_ui.docs_modelFileFieldControl.savedModel_autoUpdate = true;
										}
										if (hook_afterAPICall_pics)
										{
											hook_afterAPICall_pics(response,/*isSuccess*/true);
											model.extraData_ui.pics_modelFileFieldControl.savedModel_path_vars  = xFiles_pathVars;
											model.extraData_ui.pics_modelFileFieldControl.savedModel_autoUpdate = true;
										}
									}
								},
							});
							this.model.userTouch_toggleAllFields(false);
							this.$bREST.notifs_tmp({msg:this._loc("navigate_save.success"), color:"success"});
						}
						else if (SAVE_ON_NAVIGATE)
						{
							if (!this.model.validation_isValid)
							{
								const errors = this.model.validation_getErrors(/*detailed*/false).join("\n\t-");
								this.$bREST.notifs_tmp({msg:this._loc("navigate_save.validationError",{errors}), color:"error"});
								return;
							}
							
							this.isSaving = true;
							
							await this.model.save({
								beforeSave: async(request, model) =>
								{
									const xFiles_pathVars = {};
									{
										const route_entity_info = this.$bREST.route_entity_info;
										const type              = route_entity_info.type;
										xFiles_pathVars[type] = model.pk;
									}
									
									if (model.extraData_ui.cv_modelFileFieldControl)
									{
										model.extraData_ui.cv_modelFileFieldControl.savedModel_path_vars = xFiles_pathVars;
										await model.extraData_ui.cv_modelFileFieldControl.savedModel_waitTransfers_saveChanges();
									}
									
									if (model.extraData_ui.logo_modelFileFieldControl)
									{
										model.extraData_ui.logo_modelFileFieldControl.savedModel_path_vars = xFiles_pathVars;
										await model.extraData_ui.logo_modelFileFieldControl.savedModel_waitTransfers_saveChanges();
									}
									
									if (model.extraData_ui.docs_modelFileFieldControl)
									{
										model.extraData_ui.docs_modelFileFieldControl.savedModel_path_vars = xFiles_pathVars;
										await model.extraData_ui.docs_modelFileFieldControl.savedModel_waitTransfers_saveChanges();
									}
									
									if (model.extraData_ui.pics_modelFileFieldControl)
									{
										model.extraData_ui.pics_modelFileFieldControl.savedModel_path_vars = xFiles_pathVars;
										await model.extraData_ui.pics_modelFileFieldControl.savedModel_waitTransfers_saveChanges();
									}
								},
							});
							this.model.userTouch_toggleAllFields(false);
							this.$bREST.notifs_tmp({msg:this._loc("navigate_save.success"), color:"success"});
						}
					}
					catch (e)
					{
						this.$bREST.utils.console_error(`Got err after save`,e);
						this.$bREST.notifs_tmp({msg:this._loc("navigate_save.failure"), color:"error"});
						canNavigate = false;
					}
					
					this.isSaving = false;
					
					return canNavigate;
				},
				on_acceptTerms_change(accepted)
				{
					this.acceptTerms = accepted;
				},
				//Called even when it's not ok to do so (for validation)
				async navigate(page, gotAutoSentToTargetPage, scrollToTop, delayed)
				{
					const canNavigate = await this._checkSave(); //Doesn't throw
					
					if (canNavigate)
					{
						this.current_page_tag = page.tag;
						
						if (delayed) { await this.$bREST.utils.sleep(NAV_BTN_DELAY_MSECS); }
						if (window.scrollY>180) { this.scrollToTop(0); }
						
						this.scrollToBtn_recalcVisibility(true);
					}
					else if (gotAutoSentToTargetPage)
					{
						this.current_page_tag = page.tag;
						// await this.$bREST.utils.sleep(NAV_BTN_DELAY_MSECS);
						this.current_page_tag = this.previous_page_tag;
					}
					
					this.previous_page_tag = this.current_page_tag;
				},
				navigate_back() { this._navigate_delta(-1); },
				navigate_next() { this._navigate_delta( 1); },
				_navigate_delta(delta) { this.navigate(this.pages[this.current_page_idx+delta], /*gotAutoSentToTargetPage*/false, /*delayed*/true); },
				async navigate_out()
				{
					this.model.select("isProfileCompleted").val = true;
					
					const canNavigate = await this._checkSave(); //Doesn't throw
					
					if (canNavigate)
					{
						let destination = null;
						switch (this.$bREST.user_type)
						{
							case "lead": destination="/opportunities/lead"; break;
							default:     destination="/profile";            break;
						}
						
						this.$bREST.routes_go_path(destination, /*reloadApp*/true);
					}
				},
			//Scroll btn stuff
				directive_onScroll() { this.scrollToBtn_recalcVisibility(false); },
				directive_onResize() { this.scrollToBtn_recalcVisibility(false); },
				async scrollToBtn_recalcVisibility(delayed)
				{
					//NOTE: To make it better, could consider using @hook:lifecycleHookName on child components, to know when they're ready
					if (!this.isMounted) { return; }
					
					const THRESHOLD = this.isPublicReg ? 200 : 50;
					
					//NOTE: Probably a better way to do this with some animation ended hooks
					if (delayed) { await this.$bREST.utils.sleep(SCROLL_TO_BTN_RECALC_DELAY_MSECS); }
					
					const pageYOffset_top    = window?.pageYOffset ?? 0;
					const pageYOffset_bottom = pageYOffset_top + this.$bREST.uiBreakpoint.height;
					
					this.scrollToBtn_top_show    = pageYOffset_top    > THRESHOLD;
					this.scrollToBtn_bottom_show = pageYOffset_bottom < document.body.scrollHeight - THRESHOLD;
					this.desktopTabs_stickHeader = pageYOffset_top    > this.flagFranchiseBanner_bottomPageYOffset;
				},
				async save() { return this._checkSave(); },
				scrollToTop(offset=0)       { this.$bREST.scrollTo_offset(offset);                               },
				scrollToBottom(negOffset=0) { this.$bREST.scrollTo_offset(document.body.scrollHeight-negOffset); },
			//Stuff only for when we use a v-tabs
				async desktopTabs_onChange($event)
				{
					const page = this.pages.find(loop_page => loop_page.tag===$event);
					this.navigate(page, /*gotAutoSentToTargetPage*/true, /*delayed*/true);
				},
			//Stuff only for when we use a v-stepper
				async mobileStepper_onChange($event)
				{
					const page = this.pages[$event-1];
					this.navigate(page, /*gotAutoSentToTargetPage*/true, /*delayed*/true);
				},
				mobileStepper_page_stepNumber(page) { return this.page_get_idx(page)+1; },
				mobileStepper_page_iconProps(page)
				{
					//Do like that, otherwise if we start putting only one as complete / editable, it'll become hell
					return page.isFilled ? {complete:true, editable:true, completeIcon:page.icon, editIcon:page.icon, color:"success"}
					                     : {complete:true, editable:true, completeIcon:page.icon, editIcon:page.icon, color:"secondary"};
				},
		},
		components: {
			//NOTE: Not good to load all, should do dynamically against required components in pages
				KbAcceptTermsConds:                   () => import("@/custom/components/modules/KbAcceptTermsConds.vue"),
			//Common stuff
				KbCompatQuestionSupport:              () => import("@/custom/components/compatQuestions/common/KbCompatQuestionSupport.vue"),
				KbCompatQuestionSkillSectors:         () => import("@/custom/components/compatQuestions/common/KbCompatQuestionSkillSectors.vue"),
				KbCompatQuestionSkillRate:            () => import("@/custom/components/compatQuestions/common/KbCompatQuestionSkillRate.vue"),
				KbCompatQuestionAffinityMoralitySort: () => import("@/custom/components/compatQuestions/common/KbCompatQuestionAffinityMoralitySort.vue"),
				KbCompatQuestionAffinityBiPolar:      () => import("@/custom/components/compatQuestions/common/KbCompatQuestionAffinityBiPolar.vue"),
				KbCompatQuestionTerritory:            () => import("@/custom/components/compatQuestions/common/KbCompatQuestionTerritory.vue"),
				KbCompatQuestionProfileRate:          () => import("@/custom/components/compatQuestions/common/KbCompatQuestionProfileRate.vue"),
				KbCompatQuestionTimeSchedule:         () => import("@/custom/components/compatQuestions/common/KbCompatQuestionTimeSchedule.vue"),
				KbCompatQuestionTimeAllot:            () => import("@/custom/components/compatQuestions/common/KbCompatQuestionTimeAllot.vue"),
				KbCompatQuestionYearn:                () => import("@/custom/components/compatQuestions/common/KbCompatQuestionYearn.vue"),
				KbCompatQuestionRevenue:              () => import("@/custom/components/compatQuestions/common/KbCompatQuestionRevenue.vue"),
			//Lead related
				KbLeadForm:                           () => import("@/custom/components/modules/entity_base/forms/KbLeadForm.vue"),
				KbCompatQuestionLeadInvest:           () => import("@/custom/components/compatQuestions/lead/KbCompatQuestionLeadInvest.vue"),
				KbCompatQuestionLeadNetWorth:         () => import("@/custom/components/compatQuestions/lead/KbCompatQuestionLeadNetWorth.vue"),
				KbCompatQuestionLeadBankrupt:         () => import("@/custom/components/compatQuestions/lead/KbCompatQuestionLeadBankrupt.vue"),
			//Brand related
				KbBrandForm:                          () => import("@/custom/components/modules/entity_base/forms/KbBrandForm.vue"),
				KbCompatQuestionBrandEntry:           () => import("@/custom/components/compatQuestions/brand/KbCompatQuestionBrandEntry.vue"),
				KbCompatQuestionBrandInvest:          () => import("@/custom/components/compatQuestions/brand/KbCompatQuestionBrandInvest.vue"),
				KbCompatQuestionBrandNetWorthMin:     () => import("@/custom/components/compatQuestions/brand/KbCompatQuestionBrandNetWorthMin.vue"),
				KbCompatQuestionBrandCustom:          () => import("@/custom/components/compatQuestions/brand/KbCompatQuestionBrandCustom.vue"),
				KbCompatQuestionBrandBankrupt:        () => import("@/custom/components/compatQuestions/brand/KbCompatQuestionBrandBankrupt.vue"),
			//Franchisor related
				KbFranchisorForm:                     () => import("@/custom/components/modules/entity_base/forms/KbFranchisorForm.vue"),
			//Consultant related
				KbConsultantForm:                     () => import("@/custom/components/modules/entity_base/forms/KbConsultantForm.vue"),
		},
	};
	
</script>

<style scoped>
	
	.reg-container { }
		.reg-container--is-public-reg { }
			.reg-container--is-public-reg .v-tabs.v-tabs--is-sticky > :deep(.v-tabs-bar) {/*
				.reg-container--is-public-reg .v-tabs.v-tabs--is-sticky >>>    > .v-tabs-bar { top: 24px !important; }
				.reg-container--is-public-reg .v-tabs.v-tabs--is-sticky /deep/ > .v-tabs-bar { top: 24px !important; }*/
				top: 24px !important; /* NOTE: CSS :root vars don't work in <style scoped>, should use SASS / SCSS */
			}
			.reg-container--is-public-reg .v-stepper__header { top: 24px !important; } /* NOTE: CSS :root vars don't work in <style scoped>, should use SASS / SCSS */
	
	.navigation-btn {
		width: 125px;
	}
	
	.go-to-btns { }
		.go-to-btn--top {
			margin-bottom: 128px;
		}
			.go-to-btns--has-desktop-bottom-navigation .go-to-btn--top {
				margin-bottom: 236px;
			}
		.go-to-btn--middle {
			margin-bottom: 172px;
			margin-right: 64px;
		}
		.go-to-btn--bottom {
			margin-bottom: 64px;
		}
			.go-to-btns--has-desktop-bottom-navigation .go-to-btn--bottom {
				margin-bottom: 172px;
			}
	
	/* When we use v-tabs. NOTE: ">>> >" or "/deep/ >" hell, is because if we've got v-tabs in sub components, ex <kb-field-loc>, they'll all stick to the top :D otherwise */
		.desktopTabs.v-tabs { }
			.desktopTabs.v-tabs > :deep(.v-tabs-bar) {/*
				.desktopTabs.v-tabs >>>    > .v-tabs-bar {
				.desktopTabs.v-tabs /deep/ > .v-tabs-bar {*/
				position: absolute;
				top: 80px; /* NOTE: CSS :root vars don't work in <style scoped>, should use SASS / SCSS */
				z-index: 1;
			}
			.desktopTabs.v-tabs.v-tabs--is-sticky > :deep(.v-tabs-bar) {/*
				.desktopTabs.v-tabs.v-tabs--is-sticky >>>    > .v-tabs-bar {
				.desktopTabs.v-tabs.v-tabs--is-sticky /deep/ > .v-tabs-bar {*/
				position: fixed;
			}
				.desktopTabs.v-tabs > :deep(.v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab) {/*
					.desktopTabs.v-tabs >>>    > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab {
					.desktopTabs.v-tabs /deep/ > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab {*/
					justify-content: left;
				}
			.desktopTabs.v-tabs > :deep(.v-tabs-items) {/*
				.desktopTabs.v-tabs >>>    > .v-tabs-items {
				.desktopTabs.v-tabs /deep/ > .v-tabs-items {*/
				padding-left: 180px;
				min-height: 500px;
			}
			.desktopTabs.v-tabs > :deep(.v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active:hover::before),
			.desktopTabs.v-tabs > :deep(.v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active::before) {/*
				.desktopTabs.v-tabs >>>    > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active:hover::before,
				.desktopTabs.v-tabs >>>    > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active::before {
				.desktopTabs.v-tabs /deep/ > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active:hover::before,
				.desktopTabs.v-tabs /deep/ > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab--active::before {*/
				opacity: 0.12;
			}
			.desktopTabs.v-tabs > :deep(.v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab:not(.v-tab--active)) {/*
				.desktopTabs.v-tabs >>>    > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab:not(.v-tab--active) {
				.desktopTabs.v-tabs /deep/ > .v-slide-group > .v-slide-group__wrapper > .v-slide-group__content > .v-tab:not(.v-tab--active) {*/
				opacity: 0.75;
			}
	
	/* When we use v-stepper */
		.mobileStepper.v-stepper {
			overflow: visible;
			width: 100%;
		}
			.mobileStepper .v-stepper__header {
				position: sticky;
				top: 80px; /* NOTE: CSS :root vars don't work in <style scoped>, should use SASS / SCSS */
				z-index: 1;
				flex-wrap: unset;
			}
			.mobileStepper .v-stepper__step--active {
			    background: rgba(0, 0, 0, 0.06);
			}
			.mobileStepper .v-stepper__step:not(.v-stepper__step--active) {
				opacity: 0.75;
			}

</style>
