
import B_REST_VueApp_base from "../../../../B_REST_VueApp_base.js";
import GFile_base from "../../utils/GFile_base.js";



export default class GFile_ServerLoc extends GFile_base
{
	_model = null;
	_lang  = null;
	
	constructor(model, lang)
	{
		super();
		
		this._model = model;
		this._lang  = lang;
	}
	
	
	get _abstract_title()    { return `PHP Loc - ${this._lang}`;                         }
	get _abstract_filePath() { return `SERVER/custom/<projName>/loc/${this._lang}.json`; }
	
	getCoreLocSubTree(locPath) { return B_REST_VueApp_base.instance.t_core_subTree(locPath,/*details*/null,this._lang); }
	
	_abstract_output()
	{
		const fieldStructs = [];
		
		for (const loop_pk of this._model.pks)
		{
			fieldStructs.push({
				fieldName: loop_pk.fieldName,
				struct:    {label:loop_pk.loc.label[this._lang], shortLabel:loop_pk.loc.shortLabel[this._lang]},
			});
		}
		
		if (this._model.hasCreatedDT) { fieldStructs.push({fieldName:"createdDT", struct:this.getCoreLocSubTree("models.fields.createdDT_defaultLoc")}); }
		if (this._model.hasUpdatedDT) { fieldStructs.push({fieldName:"updatedDT", struct:this.getCoreLocSubTree("models.fields.updatedDT_defaultLoc")}); }
		
		for (const loop_dbField of this._model.dbFields)
		{
			const loop_struct = {
				label:      loop_dbField.loc.label[this._lang],
				shortLabel: loop_dbField.loc.shortLabel[this._lang],
			};
			
			if (loop_dbField.type_isEnum)
			{
				loop_struct.enum = {};
				for (const loop_enumMember of loop_dbField.enumVals) { loop_struct.enum[loop_enumMember.tag]=loop_enumMember.loc[this._lang]; }
			}
			
			fieldStructs.push({
				fieldName: loop_dbField.fieldName,
				struct:    loop_struct,
			});
		}
		
		for (const loop_lookupField of this._model.lookupFields)
		{
			const loop_label      = loop_lookupField.loc.label[this._lang];
			const loop_shortLabel = loop_lookupField.loc.shortLabel[this._lang];
			
			fieldStructs.push({
				fieldName: loop_lookupField.fieldName,
				struct:    {label:loop_label, shortLabel:loop_shortLabel},
			});
			
			fieldStructs.push({
				fieldName: loop_lookupField.fkFieldName,
				struct:    {label:loop_label, shortLabel:loop_shortLabel},
			});
		}
		
		for (const loop_subModelField of this._model.subModelFields)
		{
			fieldStructs.push({
				fieldName: loop_subModelField.fieldName,
				struct:    {label:loop_subModelField.loc.label[this._lang], shortLabel:loop_subModelField.loc.shortLabel[this._lang]},
			});
		}
		
		for (const loop_fileField of this._model.fileFields)
		{
			fieldStructs.push({
				fieldName: loop_fileField.fieldName,
				struct:    {label:loop_fileField.loc.label[this._lang], shortLabel:loop_fileField.loc.shortLabel[this._lang]},
			});
		}
		
		for (const loop_otherField of this._model.otherFields)
		{
			fieldStructs.push({
				fieldName: loop_otherField.fieldName,
				struct:    {label:loop_otherField.loc.label[this._lang], shortLabel:loop_otherField.loc.shortLabel[this._lang]},
			});
		}
		
		const fieldLines = [];
		for (const loop_fieldStruct of fieldStructs)
		{
			const loop_fieldLine = `"${loop_fieldStruct.fieldName}": ${JSON.stringify(loop_fieldStruct.struct)}`;
			fieldLines.push(loop_fieldLine);
		}
		
		return `{
	"models": {
		... IMPORTANT: Don't forget to add a "," before
		"${this._model.modelClassName}": {
			"fields": {
				${fieldLines.join(",\n\t\t\t\t")}
			}
		}
	}
}`;
	}
};
